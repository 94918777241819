<template>
  <div>
    <div v-if="user" id="database">
      <nav class="navbar sticky-top navbar-light bg-light">
        <div class="input-group my-2">
          <!-- Search UI Group -->
          <div
            v-if="
              !showConstructs && !showGenes && !showMembers && !showProjects
            "
            class="row mr-0"
          >
            <div class="selection-group-btn">
              <button
                type="button"
                class="ml-3 btn btn-info dropdown-toggle"
                data-flip="false"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <font-awesome-icon icon="hand-pointer" />
                <span class="caret"></span>
              </button>
              <div class="dropdown-menu">
                <a
                  class="dropdown-item"
                  href="#"
                  id="project"
                  v-on:click.prevent="requestTypeChange('project')"
                >
                  Project
                  <span v-if="selectedType === 'project'">&#x2713;</span>
                </a>
                <a
                  class="dropdown-item"
                  href="#"
                  id="cart"
                  v-on:click.prevent="requestTypeChange('cart')"
                >
                  Cart
                  <span v-if="selectedType === 'cart'">&#x2713;</span>
                </a>
                <a
                  class="dropdown-item"
                  href="#"
                  id="cart"
                  v-on:click.prevent="requestTypeChange('construct')"
                >
                  Construct
                  <span v-if="selectedType === 'construct'">&#x2713;</span>
                </a>
                <a
                  class="dropdown-item"
                  href="#"
                  id="design"
                  v-on:click.prevent="requestTypeChange('design')"
                >
                  Design
                  <span v-if="selectedType === 'design'">&#x2713;</span>
                </a>
                <a
                  class="dropdown-item"
                  href="#"
                  id="package"
                  v-on:click.prevent="requestTypeChange('package')"
                >
                  Package
                  <span v-if="selectedType === 'package'">&#x2713;</span>
                </a>
                <a
                  class="dropdown-item"
                  href="#"
                  id="plasmid"
                  v-on:click.prevent="requestTypeChange('plasmid')"
                >
                  Plasmid
                  <span v-if="selectedType === 'plasmid'">&#x2713;</span>
                </a>
                <a
                  class="dropdown-item"
                  href="#"
                  id="primer"
                  v-on:click.prevent="requestTypeChange('primer')"
                >
                  Primer
                  <span v-if="selectedType === 'primer'">&#x2713;</span>
                </a>
                <a
                  class="dropdown-item"
                  href="#"
                  id="strain"
                  v-on:click.prevent="requestTypeChange('strain')"
                >
                  Strain
                  <span v-if="selectedType === 'strain'">&#x2713;</span>
                </a>
                <a
                  class="dropdown-item"
                  href="#"
                  id="library_sequence"
                  v-on:click.prevent="requestTypeChange('library_sequence')"
                >
                  Sequence
                  <span v-if="selectedType === 'library_sequence'"
                    >&#x2713;</span
                  >
                </a>
                <a
                  class="dropdown-item"
                  href="#"
                  id="user"
                  v-on:click.prevent="requestTypeChange('user')"
                >
                  User
                  <span v-if="selectedType === 'user'">&#x2713;</span>
                </a>
              </div>
              <!-- .dropdown-menu -->
            </div>
            <!-- .selection-group-btn -->

            <div
              v-if="
                (selectedType === 'user' ||
                  selectedType === 'plasmid' ||
                  selectedType === 'strain') &&
                  user.role === 'admin'
              "
              class="add-btn ml-1"
            >
              <button
                type="button"
                class="btn btn-info"
                data-toggle="modal"
                data-target="#addNewItem"
                v-on:click="prepAddItem(item)"
                data-backdrop="static"
                data-keyboard="false"
              >
                <font-awesome-icon icon="plus" />
              </button>
            </div>
          </div>
          <input
            id="searchTerm"
            placeholder="Search"
            type="text"
            class="form-control ml-1"
            aria-label="Search"
            v-model="searchTerm"
          />

          <div class="search-group-btn">
            <button
              type="button"
              class="ml-1 btn btn-info dropdown-toggle"
              data-flip="false"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <font-awesome-icon icon="search" />
              <span class="small mt-2 ml-1">{{
                filterKey | searchFilter
              }}</span>
              <span class="caret"></span>
            </button>

            <div class="dropdown-menu dropdown-menu-right">
              <a
                class="dropdown-item"
                href="#"
                id="merge"
                v-on:click.prevent="requestKeyChange('merge')"
              >
                Merge
                <span v-if="filterKey === 'merge'">&#x2713;</span>
              </a>

              <a
                class="dropdown-item"
                href="#"
                id="olab_id"
                v-on:click.prevent="requestKeyChange('olab_id')"
              >
                OLAB ID
                <span v-if="filterKey === 'olab_id'">&#x2713;</span>
              </a>

              <a
                class="dropdown-item"
                href="#"
                id="name"
                v-on:click.prevent="requestKeyChange('name')"
              >
                Name
                <span v-if="filterKey === 'name'">&#x2713;</span>
              </a>

              <a
                v-if="showGenes === false && showConstructs === false"
                class="dropdown-item"
                href="#"
                id="creator"
                v-on:click.prevent="requestKeyChange('creator')"
              >
                Creator
                <span v-if="filterKey === 'creator'">&#x2713;</span>
              </a>

              <a
                v-if="showGenes === true"
                class="dropdown-item"
                href="#"
                id="locus_tag"
                v-on:click.prevent="requestKeyChange('locus_tag')"
              >
                Locus Tag
                <span v-if="filterKey === 'locus_tag'">&#x2713;</span>
              </a>

              <a
                v-if="showGenes === true"
                class="dropdown-item"
                href="#"
                id="old_locus_tag"
                v-on:click.prevent="requestKeyChange('old_locus_tag')"
              >
                Old Locus Tag
                <span v-if="filterKey === 'old_locus_tag'">&#x2713;</span>
              </a>

              <a
                v-if="
                  selectedType === 'library_sequence' ||
                    showGenes === true ||
                    showConstructs === true
                "
                class="dropdown-item"
                href="#"
                id="seq"
                v-on:click.prevent="requestKeyChange('seq')"
              >
                Sequence
                <span v-if="filterKey === 'seq'">&#x2713;</span>
              </a>
            </div>
            <!-- .dropdown-menu -->
          </div>
          <!-- search-group-btn -->
        </div>
        <!-- input-group my-2 -->

        <div class="navbar-brand text-truncate">
          <!-- Placeholder for user profile-->
          <button
            type="button"
            class="btn btn-outline-danger ml-1"
            data-toggle="modal"
            data-target="#userProfile"
            data-backdrop="static"
            data-keyboard="false"
          >
            <font-awesome-icon icon="id-badge" />
          </button>
          <button
            type="button"
            class="btn btn-outline-success ml-1"
            data-toggle="modal"
            data-target="#infoProfile"
            data-backdrop="static"
            data-keyboard="false"
          >
            <font-awesome-icon icon="info" />
          </button>
          <span class="h4 text-success text-capitalize ml-2"
            >{{ selectedTypeName }} Database System</span
          >
        </div>
        <!-- Disable admin announcement support -->
        <!-- Waiting to be moved to Home page instead -->
        <div class="nav navbar-nav navbar-right">
          <button
            v-if="false && user && user.role === 'admin'"
            type="button"
            class="btn btn-warning float-right"
            data-toggle="modal"
            data-target="#adminAnnouncement"
            data-backdrop="static"
            data-keyboard="false"
          >
            <font-awesome-icon icon="bullhorn" />
          </button>
        </div>
        <!-- navbar-brand text-truncate -->

        <div v-if="showConstructs" class="navbar-text ml-auto d-flex">
          <button
            type="button"
            class="btn btn-info ml-1"
            v-on:click="doSwitchFromConstruct()"
          >
            Exit Constructs <font-awesome-icon icon="sign-out-alt" />
          </button>
        </div>
        <div v-if="showGenes" class="navbar-text ml-auto d-flex">
          <button
            type="button"
            class="btn btn-info ml-1"
            v-on:click="doSwitchFromGene()"
          >
            Exit Genes <font-awesome-icon icon="sign-out-alt" />
          </button>
        </div>
        <div v-if="showMembers" class="navbar-text ml-auto d-flex">
          <button
            type="button"
            class="btn btn-info ml-1"
            v-on:click="doSwitchFromMember()"
          >
            Exit Members <font-awesome-icon icon="sign-out-alt" />
          </button>
        </div>
        <div v-if="showProjects" class="navbar-text ml-auto d-flex">
          <button
            type="button"
            class="btn btn-info ml-1"
            v-on:click="doSwitchFromProject()"
          >
            Exit Projects <font-awesome-icon icon="sign-out-alt" />
          </button>
        </div>
        <!-- .navbar-text ml-auto d-flex -->

        <div
          v-if="errorObj.message"
          class="col-12 alert alert-danger text-center px-3"
        >
          {{ errorObj.message }}
          <div class="text-center mt-2">
            <button
              type="button"
              class="btn btn-danger"
              v-on:click="closeError()"
            >
              OK
            </button>
          </div>
        </div>
        <div
          v-if="statusObj.message"
          class="col-12 alert alert-info fade show px-3"
        >
          <span>{{ statusObj.message }}</span>
          <span
            v-if="statusObj.progress"
            class="mx-2 spinner-grow"
            role="status"
          >
            <span class="sr-only">Status information</span>
          </span>
          <div class="text-center mt-2">
            <button
              type="button"
              class="btn btn-primary"
              v-on:click="closeStatus()"
            >
              OK
            </button>
          </div>
        </div>
      </nav>
      <!-- sticky-top -->

      <!-- userProfile -->
      <OlabUserProfile :user="user" />

      <!-- infoProfile -->
      <OlabInfoProfile :user="user" :selectedTypeName="selectedTypeName" />

      <!-- adminAnnouncement -->
      <div class="modal fade" id="adminAnnouncement">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">
                Announcement
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <!-- .modal-header -->
            <div class="modal-body">
              <form v-on:submit.prevent="onSubmit">
                <fieldset class="form-group">
                  <!-- <legend>{{ selectedType }} Info</legend> -->
                  <div class="form-group">
                    <label for="ip_msg" class="form-control-label">
                      <span>Message:</span>
                    </label>
                    <input
                      id="ip_msg"
                      class="form-control"
                      type="text"
                      placeholder="Announcement Message"
                      v-model="adminMessage"
                    />
                  </div>
                </fieldset>
                <!-- .form-group -->
              </form>
            </div>
            <!-- .modal-body -->

            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-primary"
                v-on:click="handleAdminMessage()"
              >
                <span
                  v-if="statusObj.progress"
                  class="spinner-border spinner-border-sm text-light"
                ></span>
                Send
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
            <!-- .modal-footer -->
          </div>
          <!-- .modal-content -->
        </div>
        <!-- .modal-dialog .modal-lg-->
      </div>
      <!-- .modal fade adminAnnouncement -->

      <!-- addNewItem -->
      <div class="modal fade" id="addNewItem">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">
                Add:
                <span class="text-dark text-truncate">
                  {{ selectedTypeName }}
                </span>
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <!-- .modal-header -->
            <div class="modal-body">
              <form v-on:submit.prevent="onSubmit">
                <fieldset class="form-group">
                  <!-- <legend>{{ selectedType }} Info</legend> -->
                  <div class="form-group">
                    <label for="ip_name" class="form-control-label">
                      <span>Name:</span>
                    </label>
                    <input
                      id="ip_name"
                      class="form-control"
                      v-bind:class="item.getValidState(item.name)"
                      type="text"
                      placeholder="Name"
                      v-model="item.name"
                    />
                    <!-- <div class="invalid-feedback">{{ invalidFB.name }}</div> -->
                  </div>
                  <div v-if="selectedType === 'user'" class="form-group">
                    <label for="ip_email" class="form-control-label">
                      <span>Email:</span>
                    </label>
                    <input
                      id="ip_email"
                      class="form-control"
                      v-bind:class="validEmailState(item.email)"
                      type="email"
                      placeholder="Email Address"
                      v-model="item.email"
                    />
                    <!-- <div class="invalid-feedback">{{ invalidFB.email }}</div> -->
                  </div>
                  <div v-if="selectedType === 'user'" class="form-group">
                    <label for="ip_systemRole" class="form-control-label">
                      <span>System Role:</span>
                    </label>
                    <select
                      id="ip_systemRole"
                      class="form-control"
                      v-bind:class="item.getValidState(item.role)"
                      v-model="item.role"
                    >
                      <option disabled value="null">Please select one</option>
                      <option value="admin">Admin</option>
                      <option value="user">User</option>
                    </select>
                    <!-- <span>selected = {{ item.role }}</span> -->
                  </div>
                  <div v-if="selectedType === 'user'" class="form-group">
                    <label for="ip_cjEmployeeID" class="form-control-label">
                      <span>Employee ID:</span>
                    </label>
                    <input
                      id="ip_cjEmployeeID"
                      class="form-control"
                      v-bind:class="item.getValidState(item.user_id)"
                      type="text"
                      placeholder="Employee ID"
                      v-model="item.user_id"
                    />
                  </div>
                  <div v-if="selectedType === 'user'" class="form-group">
                    <label for="ip_cjPassword" class="form-control-label">
                      <span>Password:</span>
                    </label>
                    <div class="form-row" id="ip_cjPassword">
                      <section class="col-sm-6">
                        <input
                          class="form-control"
                          v-bind:class="item.getValidPWState(false)"
                          type="password"
                          required
                          placeholder="Password"
                          v-model="item.passwd"
                        />
                        <div class="invalid-feedback">
                          {{ invalidFB.password }}
                        </div>
                      </section>
                      <section class="col-sm-6">
                        <input
                          class="form-control"
                          v-bind:class="item.getValidCPWState(false)"
                          type="password"
                          required
                          placeholder="Repeat Password"
                          v-model="item.confirmed_passwd"
                        />
                        <div class="invalid-feedback">
                          {{ invalidFB.password_confirmed }}
                        </div>
                      </section>
                    </div>
                  </div>
                  <div v-if="selectedType === 'strain'" class="form-group">
                    <label for="ip_microbe" class="form-control-label">
                      <span>Microbe:</span>
                    </label>
                    <select
                      id="ip_microbe"
                      class="form-control"
                      v-bind:class="item.getValidState(selectedMicrobe)"
                      v-model="selectedMicrobe"
                    >
                      <option disabled value="">Please select one</option>
                      <option v-for="mcbe in microbes" :key="mcbe.olab_id"
                        >{{ mcbe.olab_id }} - {{ mcbe.name }}</option
                      >
                    </select>
                    <!-- <span>selected = {{ selectedMicrobe }}</span> -->
                  </div>
                  <div
                    v-if="
                      selectedType === 'plasmid' &&
                        dbInfo.db_configs &&
                        dbInfo.db_configs.plasmid_oris
                    "
                    class="form-group"
                  >
                    <label for="ip_ori" class="form-control-label">
                      <span>Ori:</span>
                    </label>
                    <select
                      id="ip_ori"
                      class="form-control"
                      v-bind:class="item.getValidState(item.plasmid_ori)"
                      v-model="item.plasmid_ori"
                    >
                      <option disabled value="null">Please select one</option>
                      <option
                        v-for="ori in dbInfo.db_configs.plasmid_oris"
                        :key="ori.olab_id"
                        >{{ ori.name }}</option
                      >
                    </select>
                    <!-- <span>selected = {{ item.plasmid_ori }}</span> -->
                  </div>
                  <div
                    v-if="
                      selectedType === 'plasmid' || selectedType === 'strain'
                    "
                    class="form-group"
                  >
                    <label for="ip_source" class="form-control-label"
                      >Source:</label
                    >
                    <select
                      id="ip_source"
                      class="form-control"
                      v-bind:class="item.getValidState(item.source)"
                      v-model="item.source"
                    >
                      <option disabled value="null">Please select one</option>
                      <option value="inhouse">inhouse</option>
                      <option value="public">public</option>
                    </select>
                    <!-- <span>selected = {{ item.source }}</span> -->
                  </div>
                  <div class="row">
                    <div v-if="selectedType === 'strain'" class="col-lg-4">
                      <div class="form-group">
                        <label class="form-control-label">Contig:</label>
                        <div class="form-check form-check-inline mx-2">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            v-model="item.contig"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="
                        selectedType === 'plasmid' || selectedType === 'strain'
                      "
                      class="col-lg-4"
                    >
                      <div class="form-group">
                        <label class="form-control-label">WGS Confirmed:</label>
                        <div class="form-check form-check-inline mx-2">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            v-model="item.wgs_confirmed"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="
                        (selectedType === 'plasmid' ||
                          selectedType === 'strain') &&
                          item.ra_locks
                      "
                      class="col-lg-4"
                    >
                      <div class="form-group">
                        <label class="form-control-label">
                          <span>Public Access:</span>
                        </label>
                        <div class="form-check form-check-inline mx-2">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            v-model="item.ra_locks.public"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="selectedType === 'plasmid'">
                    <div class="form-group card">
                      <div class="card-header text-dark">
                        {{ uploaderHeaderStr }}
                      </div>
                      <div class="card-body">
                        <div class="my-2">
                          <label class="form-control-label mr-2"
                            >Plasmid (genbank):</label
                          >
                          <input
                            id="inputGenBankFile"
                            type="file"
                            accept=".gb"
                            v-on:change="changeFile($event, item, 'GENBANK')"
                            :disabled="fastaOrCsvFileSelected"
                          />
                        </div>
                        <h5 class="text-center my-2">
                          OR
                        </h5>
                        <div class="mb-2">
                          <label class="form-control-label mr-2"
                            >{{ uploaderFastaStr }}:</label
                          >
                          <input
                            id="inputFastaFile"
                            type="file"
                            accept=".fasta"
                            v-on:change="changeFile($event, item, 'FASTA')"
                            :disabled="genbankFileSelected"
                          />
                        </div>
                        <div>
                          <label class="form-control-label mr-2"
                            >Annotation data (csv):</label
                          >
                          <input
                            id="inputCSVFile"
                            type="file"
                            accept=".csv"
                            v-on:change="changeFile($event, item, 'CSV')"
                            :disabled="genbankFileSelected"
                          />
                        </div>
                      </div>
                      <!-- .card-body -->
                      <div class="card-footer text-center">
                        <span class="px-1">Download CSV template:</span>
                        <span class="px-1">
                          <a
                            href="templates/genes_template.csv"
                            target="_blank"
                            class="card-link"
                            download
                            >genes</a
                          >
                        </span>
                        <span>or</span>
                        <span class="px-1">
                          <a
                            href="templates/contig_genes_template.csv"
                            target="_blank"
                            class="card-link"
                            download
                            >genes(contig)</a
                          >
                        </span>
                      </div>
                    </div>
                    <!-- .card -->
                  </div>
                  <div v-if="selectedType === 'strain'">
                    <div class="form-group card">
                      <div class="card-header text-dark">
                        {{ uploaderHeaderStr }}
                      </div>
                      <div class="card-body">
                        <div class="mb-2">
                          <label class="form-control-label mr-2"
                            >{{ uploaderFastaStr }}:</label
                          >
                          <input
                            id="inputFastaFile"
                            type="file"
                            accept=".fasta"
                            v-on:change="changeFile($event, item, 'FASTA')"
                          />
                        </div>
                        <div>
                          <label class="form-control-label mr-2"
                            >Annotation data (csv):</label
                          >
                          <input
                            id="inputCSVFile"
                            type="file"
                            accept=".csv"
                            v-on:change="changeFile($event, item, 'CSV')"
                          />
                        </div>
                      </div>
                      <!-- .card-body -->
                      <div class="card-footer text-center">
                        <span class="px-1">Download CSV template:</span>
                        <span class="px-1">
                          <a
                            href="templates/genes_template.csv"
                            target="_blank"
                            class="card-link"
                            download
                            >genes</a
                          >
                        </span>
                        <span>or</span>
                        <span class="px-1">
                          <a
                            href="templates/contig_genes_template.csv"
                            target="_blank"
                            class="card-link"
                            download
                            >genes(contig)</a
                          >
                        </span>
                      </div>
                    </div>
                    <!-- .card -->
                  </div>
                  <div v-if="selectedType !== 'user'" class="form-group">
                    <label class="form-control-label">Creator:</label>
                    <span class="ml-1">{{ item.creator }}</span>
                  </div>
                  <div v-if="selectedType !== 'user'" class="form-group">
                    <label for="ip_description" class="form-control-label"
                      >Description:</label
                    >
                    <textarea
                      id="ip_description"
                      class="form-control"
                      v-model="item.desc"
                    ></textarea>
                  </div>
                </fieldset>
                <!-- .form-group -->
              </form>
            </div>
            <!-- .modal-body -->

            <div class="modal-footer">
              <button
                type="button"
                v-bind:disabled="itemAddIsDisabled === true"
                class="btn btn-primary"
                v-on:click="handleAddItem(item)"
              >
                <span
                  v-if="statusObj.progress"
                  class="spinner-border spinner-border-sm text-light"
                ></span>
                Add
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
            <!-- .modal-footer -->
          </div>
          <!-- .modal-content -->
        </div>
        <!-- .modal-dialog .modal-lg-->
      </div>
      <!-- .modal fade addNewItem -->

      <div v-if="showGenes">
        <DatabaseGeneList
          v-if="user"
          :user="user"
          :genes="selected.genes"
          :statusObj="statusObj"
          :errorObj="errorObj"
          :selectedType="selectedType"
        />
      </div>
      <div v-else-if="showConstructs">
        <DatabaseConstructList
          v-if="user"
          :user="user"
          :dsgn="selectedItem"
          :constructs="selected.items"
          :statusObj="statusObj"
          :errorObj="errorObj"
          :selectedType="selectedType"
        />
      </div>
      <div v-else-if="showMembers">
        <DatabaseProjUserList
          v-if="user"
          :user="user"
          :selectedProject="selectedItem"
          :usrs="selected.items"
          :statusObj="statusObj"
          :errorObj="errorObj"
          :selectedType="selectedType"
          @doSaveProjectRole="doSaveProjectRole"
        />
      </div>
      <div v-else-if="showProjects">
        <DatabaseStrainProjList
          v-if="user"
          :user="user"
          :selectedStrain="selectedItem"
          :projs="selected.items"
          :statusObj="statusObj"
          :errorObj="errorObj"
          :selectedType="selectedType"
          @doSaveStrainRole="doSaveStrainRole"
        />
      </div>
      <div v-else>
        <DatabaseItemList
          v-if="user"
          :user="user"
          :dbInfo="dbInfo"
          :microbes="microbes"
          :statusObj="statusObj"
          :errorObj="errorObj"
          :selectedItems="selected.items"
          :selectedType="selectedType"
          :item="item"
          @doSaveItem="doSaveItem"
          @doDeleteItem="doDeleteItem"
          @doSwitchToGene="doSwitchToGene"
          @doSwitchToConstruct="doSwitchToConstruct"
          @doSwitchToMember="doSwitchToMember"
          @doSwitchToProject="doSwitchToProject"
        />
      </div>
      <div class="my-5">
        <nav aria-label="Page navigation">
          <ul class="pagination justify-content-center">
            <li class="page-item">
              <button
                type="button"
                v-bind:disabled="selected.prevIsDisabled == true"
                class="btn btn-primary"
                v-on:click="handlePrev()"
              >
                &lt;
              </button>
            </li>
            <li class="mx-3">{{ selected.pageNumber }}</li>
            <li class="page-item">
              <button
                type="button"
                v-bind:disabled="selected.nextIsDisabled == true"
                class="btn btn-primary"
                v-on:click="handleNext()"
              >
                &gt;
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div v-else class="mt-5">
      <div class="text-secondary text-center">
        <div class="h2">
          Inactivity Time Out
        </div>
        <router-link
          class="btn btn-outline-primary mt-2"
          to="/login"
          v-if="!user"
          >Log in</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import DatabaseItemList from "@/components/DatabaseItemList";
import DatabaseConstructList from "@/components/DatabaseConstructList";
import DatabaseGeneList from "@/components/DatabaseGeneList";
import DatabaseProjUserList from "@/components/DatabaseProjUserList";
import DatabaseStrainProjList from "@/components/DatabaseStrainProjList";
import OlabUserProfile from "@/components/OlabUserProfile";
import OlabInfoProfile from "@/components/OlabInfoProfile";
import { OlabAxios } from "../olab/olabaxios.js";
import { OlabItem } from "../olab/olabitem.js";
import { OlabUtils } from "../olab/olabutils.js";
import { OlabSearch } from "../olab/olabsearch.js";
import $ from "jquery";

export default {
  name: "database",
  data: function() {
    return {
      title: "Database",
      searchTerm: "",
      filterKey: "merge",
      filterDir: "asc",
      selectedType: "project",
      lastSelectedType: null,
      selectedTypeName: "Project",
      selectedItem: null,
      selectedMicrobe: "",
      item: new OlabItem(),
      invalidFB: OlabItem.getInvalidFeedbacks(),
      showConstructs: false,
      showGenes: false,
      showMembers: false,
      showProjects: false,
      geneCollectionStr: null,
      includeDeleted: true,
      uploaderHeaderStr: "Chromosome Data - C01",
      uploaderFastaStr: "Chromosome sequence (fasta)",
      selected: {
        genes: null,
        items: null,
        sortBy: "olab_id",
        pageNumber: 1,
        nextIsDisabled: true,
        prevIsDisabled: true
      },
      adminMessage: ""
    };
  },
  components: {
    FontAwesomeIcon,
    DatabaseItemList,
    DatabaseConstructList,
    DatabaseGeneList,
    DatabaseProjUserList,
    DatabaseStrainProjList,
    OlabUserProfile,
    OlabInfoProfile
  },
  props: ["user", "dbInfo", "microbes", "statusObj", "errorObj"],
  mounted: async function() {
    OlabUtils.infoLog("Database mounted ...");
    // Reset status and error
    this.statusObj.message = null;
    this.statusObj.progress = false;
    this.errorObj.message = null;
    this.adminMessage =
      this.dbInfo && this.dbInfo.message ? this.dbInfo.message : "";

    try {
      const searchBody = OlabSearch.createItemSearchBody(
        this.selectedType,
        "",
        "olab_id",
        this.includeDeleted,
        null,
        1,
        OlabSearch.cntPerPage
      );
      const items = await OlabUtils.getProjects(searchBody);
      if (items.length >= OlabSearch.cntPerPage) {
        this.selected.sortBy = "olab_id";
        this.selected.nextIsDisabled = false;
      }
      this.selected.items = items;
    } catch (err) {
      OlabUtils.infoLog(err);
      this.errorObj.message = OlabUtils.getErrorMessage(err);
    }
  },
  computed: {
    itemAddIsDisabled: function() {
      return !this.item.isItemValid(this.selectedType, true);
    },
    genbankFileSelected: function() {
      return this.item && this.item.genbank_file !== null;
    },
    fastaOrCsvFileSelected: function() {
      return (
        this.item &&
        (this.item.csv_file !== null || this.item.fasta_file !== null)
      );
    }
  },
  filters: {
    searchFilter: function(value) {
      switch (value) {
        case "merge":
          return "Merge";
        case "olab_id":
          return "OLAB ID";
        case "name":
          return "Name";
        case "creator":
          return "Creator";
        case "locus_tag":
          return "Locus";
        case "old_locus_tag":
          return "Old Locus";
        case "seq":
          return "Sequence";
      }
    }
  },
  methods: {
    // doComputedItems: function(items) {
    //   return _.orderBy(
    //     items,
    //     function(item) {
    //       return item[this.filterKey] == null
    //         ? null
    //         : item[this.filterKey].toLowerCase();
    //     }.bind(this),
    //     this.filterDir
    //   );
    // },
    changeFile: function(e, item, fileType) {
      const selectedFile = e.target.files[0];
      if (fileType === "CSV") {
        item.csv_file = selectedFile ? selectedFile : null;
      } else if (fileType === "FASTA") {
        item.fasta_file = selectedFile ? selectedFile : null;
      } else if (fileType === "GENBANK") {
        item.genbank_file = selectedFile ? selectedFile : null;
      }
      // console.log(
      //   "changeFile - selectedFile =",
      //   selectedFile,
      //   ", fileType =",
      //   fileType,
      //   ", item =",
      //   item
      // );
    },
    handlePrev: function() {
      let sType = this.selectedType;
      if (this.showGenes) {
        sType = "genome";
      }
      const idRef =
        this.selectedItem && sType === "construct"
          ? this.selectedItem.olab_id
          : this.geneCollectionStr;
      OlabUtils.infoLog("handlePrev ... sType = " + sType);
      OlabSearch.doPrev(
        this.showGenes, // searchGenes = this.showGenes
        sType,
        this.searchTerm,
        this.includeDeleted,
        idRef,
        this.selected
      );
    },
    handleNext: function() {
      let sType = this.selectedType;
      if (this.showGenes) {
        sType = "genome";
      }
      const idRef =
        this.selectedItem && sType === "construct"
          ? this.selectedItem.olab_id
          : this.geneCollectionStr;
      OlabUtils.infoLog("handleNext ... sType = " + sType);
      OlabSearch.doNext(
        this.showGenes, // searchGenes = this.showGenes
        sType,
        this.searchTerm,
        this.includeDeleted,
        idRef,
        this.selected
      );
    },
    requestTypeChange: function(value) {
      this.selectedType = value;
      // console.log("requestTypeChange " + this.selectedType);
      if (this.selectedType !== this.lastSelectedType) {
        // Reset searchTerm when type changed
        this.searchTerm = "";

        // console.log("Watch - selectedType = " + this.selectedType);

        // Reload collection in app
        this.$emit("reloadCollection", this.selectedType);
        switch (this.selectedType) {
          case "project":
            this.selectedTypeName = "Project";
            this.searchTerm = "";
            this.doSearchTerm(this.searchTerm, this.selectedType);
            break;
          case "cart":
            this.selectedTypeName = "Cart";
            this.searchTerm = "";
            this.doSearchTerm(this.searchTerm, this.selectedType);
            break;
          case "construct":
            this.selectedTypeName = "Construct";
            this.searchTerm = "";
            this.doSearchTerm(this.searchTerm, this.selectedType);
            break;
          case "design":
            this.selectedTypeName = "Design";
            this.searchTerm = "";
            this.doSearchTerm(this.searchTerm, this.selectedType);
            break;
          case "package":
            this.selectedTypeName = "Package";
            this.searchTerm = "";
            this.doSearchTerm(this.searchTerm, this.selectedType);
            break;
          case "plasmid":
            this.selectedTypeName = "Plasmid";
            this.searchTerm = "";
            this.uploaderHeaderStr = "Plasmid Data";
            this.uploaderFastaStr = "Plasmid sequence (fasta)";
            this.doSearchTerm(this.searchTerm, this.selectedType);
            break;
          case "primer":
            this.selectedTypeName = "Primer";
            this.searchTerm = "";
            this.doSearchTerm(this.searchTerm, this.selectedType);
            break;
          case "strain":
            this.selectedTypeName = "Strain";
            this.searchTerm = "";
            this.uploaderHeaderStr = "Chromosome Data - C01";
            this.uploaderFastaStr = "Chromosome sequence (fasta)";
            this.doSearchTerm(this.searchTerm, this.selectedType);
            break;
          case "library_sequence":
            this.selectedTypeName = "Sequence";
            this.searchTerm = "";
            this.doSearchTerm(this.searchTerm, this.selectedType);
            break;
          case "user":
            this.selectedTypeName = "User";
            this.searchTerm = "";
            this.doSearchTerm(this.searchTerm, this.selectedType);
            break;
        }
        this.lastSelectedType = this.selectedType;
      }
    },
    requestKeyChange: function(value) {
      this.filterKey = value;
      this.doSearchTerm(this.searchTerm, this.selectedType);
    },
    doDeleteItem: function({ item, items }) {
      this.$emit("deleteItem", {
        item: item,
        items: items
      });
    },
    doSaveItem: function({ item, items }) {
      this.$emit("saveItem", {
        item: item,
        items: items
      });
    },
    doSaveProjectRole: function({ usr, addMem, proj }) {
      this.$emit("saveProjectRole", {
        usr: usr,
        addMem: addMem,
        proj: proj
      });
    },
    doSaveStrainRole: function({ proj, addMem, strain }) {
      this.$emit("saveStrainRole", {
        proj: proj,
        addMem: addMem,
        strain: strain
      });
    },
    doSwitchToGene: function(item) {
      // console.log("doSwitchToGene: item: " + item.olab_id);
      this.searchTerm = "";
      this.geneCollectionStr = item.olab_id;
      // console.log(
      //   "doSwitchToGene: geneCollectionStr: " + this.geneCollectionStr
      // );
      this.showGenes = true;
      this.doSearchTerm(this.searchTerm, "genome");
    },
    doSwitchFromGene: function() {
      // console.log("doSwitchFromGene ...");
      this.searchTerm = "";
      this.geneCollectionStr = null;
      this.showGenes = false;
      this.doSearchTerm(this.searchTerm, this.selectedType);
    },
    doSwitchToConstruct: function(item) {
      // console.log("doSwitchToConstruct: item.olab_id ", item.olab_id);
      // item is a design object
      this.selectedItem = item;
      this.searchTerm = "";
      this.showConstructs = true;
      this.selectedType = "construct";
      this.doSearchTerm(this.searchTerm, this.selectedType);
    },
    doSwitchFromConstruct: function() {
      // console.log("doSwitchFromConstruct ...");
      this.selectedItem = null;
      this.searchTerm = "";
      this.showConstructs = false;
      this.selectedType = "design";
      this.doSearchTerm(this.searchTerm, this.selectedType);
    },
    doSwitchToMember: function(item) {
      OlabUtils.infoLog("doSwitchToMember: item: " + item.olab_id);
      this.selectedItem = item;
      this.searchTerm = "";
      this.showMembers = true;
      this.selectedType = "user";
      this.doSearchTerm(this.searchTerm, this.selectedType);
    },
    doSwitchFromMember: function() {
      OlabUtils.infoLog("doSwitchFromMember ...");
      this.selectedItem = null;
      this.searchTerm = "";
      this.showMembers = false;
      this.selectedType = "project";
      this.doSearchTerm(this.searchTerm, this.selectedType);
    },
    doSwitchToProject: function(item) {
      OlabUtils.infoLog("doSwitchToProject: item: " + item.olab_id);
      this.selectedItem = item;
      this.searchTerm = "";
      this.showProjects = true;
      this.selectedType = "project";
      this.doSearchTerm(this.searchTerm, this.selectedType);
    },
    doSwitchFromProject: function() {
      OlabUtils.infoLog("doSwitchFromProject ...");
      this.selectedItem = null;
      this.searchTerm = "";
      this.showProjects = false;
      if (this.lastSelectedType === "plasmid") {
        this.selectedType = "plasmid";
      } else {
        // Default case
        this.selectedType = "strain";
      }
      this.doSearchTerm(this.searchTerm, this.selectedType);
    },
    prepAddItem: function(item) {
      OlabUtils.infoLog("prepAddItem");
      item.initItem();

      if (this.selectedType !== "user") {
        // initialize creator with user's email
        item.creator = this.user.email;
      }

      if (this.selectedType === "plasmid" || this.selectedType === "strain") {
        // Reset values
        this.selectedMicrobe = "";
        document.getElementById("inputFastaFile").value = "";
        document.getElementById("inputCSVFile").value = "";
        // TODO: Only use for plasmid for now
        if (this.selectedType === "plasmid") {
          document.getElementById("inputGenBankFile").value = "";
        }

        // the default for strain is managed access
        item.ra_locks.public = false;
      } else if (this.selectedType === "project") {
        // the default for project is publicly accessible
        item.ra_locks.public = true;
      }
    },
    handleAddItem: async function(item) {
      // console.log(
      //   "Add item = " + item + ", selectedType = " + this.selectedType
      // );
      // Set progress message and status indicator
      this.statusObj.progress = true;
      await OlabAxios.addItem(
        item,
        this.selectedType,
        this.selected.items,
        this.statusObj,
        this.errorObj
      );
      $("#addNewItem").modal("hide");
    },
    handleAdminMessage: async function() {
      // console.log("Send admin message =", this.adminMessage);
      if (this.dbInfo) {
        this.dbInfo.message = this.adminMessage;
        // Set progress message and status indicator
        this.statusObj.progress = true;
        await OlabAxios.saveAnnouncement(
          this.dbInfo,
          this.statusObj,
          this.errorObj
        );
      }
      $("#adminAnnouncement").modal("hide");
    },
    doSearchTerm: function(sTerm, sType) {
      if (this.showGenes) {
        sType = "genome";
      }
      // console.log("doSearchTerm: selectedItem =", this.selectedItem);
      const idRef =
        this.selectedItem && sType === "construct"
          ? this.selectedItem.olab_id
          : this.geneCollectionStr;

      OlabSearch.doSearchTerm(
        this.showGenes, // searchGenes = this.showGenes
        sTerm,
        sType,
        this.includeDeleted,
        this.filterKey,
        idRef,
        this.selected
      );
    },
    validEmailState: function(em) {
      // OlabUtils.infoLog("validEmailState: email = " + em);
      return OlabUtils.validateEmail(em) ? "is-valid" : "is-invalid";
    },
    closeStatus: function() {
      // console.log("Close Status Alert ...");
      this.statusObj.message = null;
      this.statusObj.progress = false;
    },
    closeError: function() {
      // console.log("Close Error Alert ...");
      this.errorObj.message = null;
    },
    onSubmit() {
      // This is a NOP to prevent page reload on return key
      // console.log("After Submit");
    }
  },
  watch: {
    searchTerm: function() {
      // console.log("Watch - searchTerm = ", this.searchTerm);
      this.searchTerm = OlabUtils.cleanSearchTem(this.searchTerm);
      this.doSearchTerm(this.searchTerm, this.selectedType);
    },
    selectedMicrobe: function() {
      OlabUtils.infoLog("Watch - selectedMicrobe = " + this.selectedMicrobe);
      this.item.microbe_id = OlabUtils.subStrB4Space(this.selectedMicrobe);
      // Only allow C01 chromosome update at strain creation
      this.item.parent_id = "C01";
    }
  }
};
</script>

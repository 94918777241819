import * as d3 from "d3";
import { ColorTable } from "@/olab/d3/colorTable.js";
import { ResEnzMap } from "@/olab/d3/resEnzMap.js";
import { MetaMap } from "@/olab/d3/metaMap.js";
import { BackboneMap } from "@/olab/d3/backboneMap.js";

class OlabCircularMap {
  static TAG_FRAME = 0;
  static MAX_FRAMES = 7;

  constructor(d3CMap, tooltip) {
    this.d3CMap = d3CMap;
    this.tooltip = tooltip;
    this._init();
  }

  _init() {
    this.svg = null;
    this.ctr = null;
    this.arcGroup = null;
    this.labelGroup = null;
    this.frameBackbone = null; // backbone frame
    this.frameEnzRing = null; // An outer ring enzyme cutsite frame
    this.frameEnzLabel = null; // An outer ring enzyme label frame
    this.frameShapeArr = null; // An array of shape frames
    this.frameLabelArr = null; // An array of label frames
    this.dimensions = {
      // Default dimension
      width: 500,
      height: 500,
      margins: 45,
      ctrWidth: 0,
      ctrHeight: 0
    };
    this.colorArc = new ColorTable("Arc");
    this.colorEnzyme = new ColorTable("Enzyme");
    this.arcCollection = null; // arcs of this circular map (all frames)
    this.numOfTagFrames = 0; // Number of tag frames
  }

  getGeneArcs() {
    return this.arcCollection;
  }

  getGeneArc(geneID) {
    return MetaMap.getGeneArc(this.arcCollection, geneID);
  }

  setDimensions(dims) {
    this.dimensions = dims;
  }

  reset() {
    // this.svg = d3.selectAll(`#${this.d3CMap}.svg`).remove();
    this._init();
  }

  setup() {
    // console.log("setup circularMap =", this);
    this.svg = d3
      .select(`#${this.d3CMap}`)
      .append("svg")
      .attr("width", this.dimensions.width)
      .attr("height", this.dimensions.height);
    const dimensions = this.dimensions;
    dimensions.ctrWidth = dimensions.width - dimensions.margins * 2;
    dimensions.ctrHeight = dimensions.height - dimensions.margins * 2;
    this.ctr = this.svg
      .append("g") // <g>
      .attr(
        "transform",
        `translate(${dimensions.margins}, ${dimensions.margins})`
      );
    // Shapes
    this.arcGroup = this.ctr
      .append("g")
      .attr(
        "transform",
        `translate(${dimensions.ctrHeight / 2}, ${dimensions.ctrWidth / 2})`
      );
    // Add backbone part region
    this.frameBackbone = this.arcGroup.append("g");
    // Add frameEnzRing to arcGroup
    this.frameEnzRing = this.arcGroup.append("g");
    // Add frameShapeArr to arcGroup
    this.frameShapeArr = [];
    for (let i = 0; i < OlabCircularMap.MAX_FRAMES; i++) {
      this.frameShapeArr[i] = this.arcGroup.append("g");
    }
    // Labels
    this.labelGroup = this.ctr
      .append("g")
      .attr(
        "transform",
        `translate(${dimensions.ctrHeight / 2}, ${dimensions.ctrWidth / 2})`
      )
      .classed("labels", true);
    // Add frameEnzLabel to arcGroup
    this.frameEnzLabel = this.arcGroup.append("g");
    // Add frameLabelArr to arcGroup
    this.frameLabelArr = [];
    for (let i = 0; i < OlabCircularMap.MAX_FRAMES; i++) {
      this.frameLabelArr[i] = this.labelGroup.append("g");
    }
    // console.log("Done: setup circularMap =", this);
  }

  isTagFrame(frameCnt) {
    return frameCnt < this.numOfTagFrames;
  }

  // TODO: Bug - Need to handle crossover and intron cases (check locations).
  compute(chrmGnArr, chrmLen) {
    return MetaMap.compute(chrmGnArr, chrmLen);
  }

  // TODO: Bug - Need to handle crossover and intron cases (check locations).
  computeTagFrames(chrmTagArr, chrmLen) {
    const tagFrames = MetaMap.computeTagFrames(chrmTagArr, chrmLen);
    this.numOfTagFrames = tagFrames ? tagFrames.length : 0;
    // console.log("Number of tagFrames =", this.numOfTagFrames);
    return tagFrames;
  }

  draw(genesPieArr, vueThis) {
    // remove all previous shapes and labels
    for (let i = 0; i < OlabCircularMap.MAX_FRAMES; i++) {
      this.frameShapeArr[i].selectAll("path").remove();
      this.frameLabelArr[i].selectAll("text").remove();
    }

    // console.log("** Number of tagFrames =", this.numOfTagFrames);

    // Dimensions
    let radius = this.dimensions.ctrWidth / 2;

    // Tooltip setup
    const tooltip = d3.select(`#${this.tooltip}`);
    let el;
    let elFill;
    let elStroke;
    let elStrokeWidth;

    // Scales
    const lenPie = d3
      .pie()
      .value(d => {
        // console.log("XXXX len =", d.len, " ,start =", d.start);
        return d.len;
      })
      .sort((a, b) => a.start - b.start);

    // Loop thro. the frames
    let frameCnt = 0;
    let deltaRadius = 15;
    let thickness = 10;
    this.arcCollection = [];
    genesPieArr.forEach(ele => {
      // console.log("ele =", ele);
      const isTagFrame = this.isTagFrame(frameCnt);
      let frameArcs = MetaMap.createPieArcs(
        ele,
        frameCnt,
        isTagFrame,
        this.colorArc,
        vueThis
      );
      let slices = lenPie(frameArcs);
      // console.log("slices = ", slices);
      this.arcCollection = this.arcCollection.concat(frameArcs);
      // console.log("*** arcCollection = ", this.arcCollection);

      if (isTagFrame) {
        deltaRadius = 8;
        thickness = 6;
      } else {
        deltaRadius = 15;
        thickness = 10;
      }

      const arc = d3
        .arc()
        .outerRadius(radius)
        .innerRadius(radius - thickness);

      // Draw Shape
      this.frameShapeArr[frameCnt]
        .selectAll("path")
        .data(slices)
        .join(
          enter => enter.append("path"),
          update => update,
          exit => exit.remove()
        )
        .attr("d", arc)
        .attr("fill", d => d.data.geneFillColor)
        .on("mouseover", function(event, d) {
          if (d.data.name === "_NotAGene") return;
          // const mousePos = d3.pointer(event, this);
          // console.log("mouse over", mousePos);
          // console.log("d =", d);

          el = d3.select(this);
          // console.log("el =", el);

          el.style("cursor", "pointer");
          elFill = el.attr("fill");
          elStroke = el.style("stroke");
          elStrokeWidth = el.style("stroke-width");
          // console.log(
          //   "elFill =",
          //   elFill,
          //   "elStroke =",
          //   elStroke,
          //   ", elStrokeWidth =",
          //   elStrokeWidth
          // );
          el.attr("fill", "#5bc0de");
          el.style("stroke", "#ececfc");
          el.style("stroke-width", "2px");

          tooltip.style("display", "block");
          tooltip.select(".display-name").text(d.data.name);
          tooltip.select(".display-start span").text(d.data.geneFrom);
          tooltip.select(".display-end span").text(d.data.geneTo);
          tooltip.select(".display-length span").text(d.data.len);
          tooltip.select(".display-strand span").text(d.data.comp ? "-" : "+");
        })
        .on("mouseout", function(event, d) {
          if (d.data.name === "_NotAGene") return;
          // const mousePos = d3.pointer(event, this);
          // console.log("mouse out", mousePos);

          el = d3.select(this);
          el.style("cursor", "default");
          el.attr("fill", elFill);
          el.style("stroke", elStroke);
          el.style("stroke-width", elStrokeWidth);
          tooltip.style("display", "none");
        })
        .on("mouse click", function(event, d) {
          const gnData =
            isTagFrame || d.data.name === "_NotAGene" ? null : d.data;
          console.log("Mouse click: this =", this);
          // console.log("d =", d, ", gnData =", gnData);
          // console.log("vueHandler =", d.data.vueHandler);
          // register selected gene data for Vue
          d.data.vueHandler.selectedD3GeneData = gnData;
        });

      const fontSize = isTagFrame ? "8px" : "12px";
      this.frameLabelArr[frameCnt]
        .selectAll("text")
        .data(slices)
        .join(
          enter => enter.append("text"),
          update => update,
          exit => exit.remove()
        )
        .attr("transform", d => `translate(${arc.centroid(d)})`)
        // .style("font-weigh", "bold")
        .style("font-size", fontSize)
        .text(d =>
          d.data.name !== "_NotAGene" &&
          (d.endAngle - d.startAngle > 0.2 || isTagFrame)
            ? d.data.name
            : ""
        );

      radius -= deltaRadius;
      frameCnt += 1;
    });
  }

  computeEnzymeCS(resEnzymeNames, cutSites, chrmLen) {
    return ResEnzMap.computeEnzymeCS(this, resEnzymeNames, cutSites, chrmLen);
  }

  drawEnzymeCS(resEnzCSFrame) {
    // console.log("drawEnzymeCS resEnzCSFrame =", resEnzCSFrame);
    // Start render resEnzCSFrame
    // remove all previous enzyme arcs and labels
    this.frameEnzRing.selectAll("path").remove();
    this.frameEnzLabel.selectAll("text").remove();

    // Dimensions
    let radius = this.dimensions.ctrWidth / 2 + 20;

    // Tooltip setup
    const tooltip = d3.select("#enzymeTooltip");
    let el;
    let elFill;
    let elStroke;
    let elStrokeWidth;

    // Scales
    const lenPie = d3
      .pie()
      .value(d => {
        return d.len;
      })
      .sort((a, b) => a.start - b.start);

    const ePie = ResEnzMap.createEnzArcs(resEnzCSFrame);
    const slices = lenPie(ePie);
    // console.log("slices = ", slices);

    const arc = d3
      .arc()
      .outerRadius(radius)
      .innerRadius(radius - 15);

    // Draw Shape
    this.frameEnzRing
      .selectAll("path")
      .data(slices)
      .join(
        enter => enter.append("path"),
        update => update,
        exit => exit.remove()
      )
      .attr("d", arc)
      .attr("fill", d => d.data.fill)
      .style("stroke", d => d.data.fill)
      .style("stroke-width", d => {
        return d.data.name === "_NotAGene" ? "0px" : "1px";
      })
      .style("stroke-linecap", "butt")
      .on("mouseover", function(event, d) {
        if (d.data.name === "_NotAGene") return;
        // const mousePos = d3.pointer(event, this);
        // console.log("mouse over", mousePos);
        // console.log("d =", d);

        el = d3.select(this);
        // console.log("el =", el);

        el.style("cursor", "pointer");
        elFill = el.attr("fill");
        elStroke = el.style("stroke");
        elStrokeWidth = el.style("stroke-width");
        el.attr("fill", "#5bc0de");
        el.style("stroke", "#ececfc");
        el.style("stroke-width", "1px");

        tooltip.style("display", "block");
        tooltip.select(".display-name").text(d.data.name);
        tooltip.select(".display-cutsite span").text(d.data.start);
      })
      .on("mouseout", function(event, d) {
        if (d.data.name === "_NotAGene") return;
        // const mousePos = d3.pointer(event, this);
        // console.log("mouse out", mousePos);

        el = d3.select(this);
        el.style("cursor", "default");
        el.attr("fill", elFill);
        el.style("stroke", elStroke);
        el.style("stroke-width", elStrokeWidth);
        tooltip.style("display", "none");
      })
      .on("mouse click", function(event, d) {
        if (d.data.name === "_NotAGene") return;
        console.log("d =", d);
      });

    // console.log("**** slices =", slices);
    // Note: Don't show labels if we have too many enzyme cut sites.
    if (slices && slices.length > 100) return;

    // Show labels of cut sites
    this.frameEnzLabel
      .selectAll("text")
      .data(slices)
      .join(
        enter => enter.append("text"),
        update => update,
        exit => exit.remove()
      )
      .attr("transform", d => `translate(${arc.centroid(d)})`)
      .style("font-size", "12px")
      .text(d => (d.data.name !== "_NotAGene" ? d.data.name : ""))
      .on("mouseover", function(event, d) {
        if (d.data.name === "_NotAGene") return;
        // const mousePos = d3.pointer(event, this);
        // console.log("mouse over", mousePos);
        // console.log("d =", d);

        el = d3.select(this);
        // console.log("el =", el);

        el.style("cursor", "pointer");
        elFill = el.attr("fill");
        elStroke = el.style("stroke");
        elStrokeWidth = el.style("stroke-width");
        el.attr("fill", "#5bc0de");
        el.style("stroke", "#ececfc");
        el.style("stroke-width", "1px");

        tooltip.style("display", "block");
        tooltip.select(".display-name").text(d.data.name);
        tooltip.select(".display-cutsite span").text(d.data.start);
      })
      .on("mouseout", function(event, d) {
        if (d.data.name === "_NotAGene") return;
        // const mousePos = d3.pointer(event, this);
        // console.log("mouse out", mousePos);

        el = d3.select(this);
        el.style("cursor", "default");
        el.attr("fill", elFill);
        el.style("stroke", elStroke);
        el.style("stroke-width", elStrokeWidth);
        tooltip.style("display", "none");
      })
      .on("mouse click", function(event, d) {
        if (d.data.name === "_NotAGene") return;
        console.log("d =", d);
      });
  }

  removeFrameEnzRing() {
    ResEnzMap.removeFrameEnzRing(this);
  }

  removeFrameEnzLabel() {
    ResEnzMap.removeFrameEnzLabel(this);
  }

  computeBBRegion(resEnz1, resEnz2, dir, chrmLen) {
    return BackboneMap.computeBBRegion(this, resEnz1, resEnz2, dir, chrmLen);
  }

  drawBBRegion(bbRegionFrame) {
    // console.log("drawBBRegion bbRegionFrame =", bbRegionFrame);
    // Start render bbRegionFrame
    // remove all previous backbone arcs
    this.frameBackbone.selectAll("path").remove();

    if (bbRegionFrame.length < 1) return;

    // Dimensions
    let radius = this.dimensions.ctrWidth / 2 + 30;

    // Scales
    const lenPie = d3
      .pie()
      .value(d => {
        return d.len;
      })
      .sort((a, b) => a.start - b.start);

    const ePie = BackboneMap.createBBRegionArcs(bbRegionFrame);
    const slices = lenPie(ePie);
    // console.log("slices = ", slices);

    const arc = d3
      .arc()
      .outerRadius(radius)
      .innerRadius(radius - 10);

    // Draw Shape
    this.frameBackbone
      .selectAll("path")
      .data(slices)
      .join(
        enter => enter.append("path"),
        update => update,
        exit => exit.remove()
      )
      .attr("d", arc)
      .attr("fill", d => d.data.fill)
      .style("stroke", d => d.data.fill)
      .style("stroke-width", d => {
        return d.data.name === "_NotAGene" ? "0px" : "1px";
      })
      .style("stroke-linecap", "butt");
  }
}

export { OlabCircularMap };

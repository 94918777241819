<template>
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <g transform="translate(150,150)">
        <path
          fill="#f80"
          transform="scale(15)"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.90604 10.7206L2.82835 14.1099L5.93537 16.1504L9.1299 14.2623L9.19807 11.2884L11.0289 10.5641L12.9409 11.8162L12.9419 13.217L11.8411 14.0729L12.3513 15.6165L13.985 15.5974L14.4966 14.0439L13.3918 13.2343L13.3908 11.8468L15.6691 10.5055L15.7308 7.81336L13.3091 6.22754L10.8143 7.69102L10.7573 10.1763L9.12789 10.8209L6.26769 8.94796L6.25288 7.69967L8.59588 6.32031L8.65759 3.62813L6.23817 2.04385L6.23296 2.04691L3.92927 3.40314L2.81202 2.56231L2.63593 1.18427L1.02059 0.894226L0.269226 2.33004L1.39646 3.54518L2.60965 2.98291L3.74083 3.83422L3.68656 6.20187L5.80162 7.58685V9.01592L2.90604 10.7206ZM3.34977 10.992L3.28403 13.8602L5.94662 15.6037L8.68618 13.9909L8.75192 11.1227L6.08932 9.37922L3.34977 10.992ZM4.192 3.78114L4.14223 5.95219L6.11836 7.2462L8.15215 6.04886L8.20192 3.87781L6.22579 2.58381L4.192 3.78114ZM11.258 7.96246L11.2083 10.1335L13.1909 11.4318L15.2254 10.2341L15.2752 8.06304L13.2925 6.76474L11.258 7.96246ZM12.2653 14.2067L12.6081 15.2437L13.7259 15.2307L14.0701 14.1855L13.1551 13.515L12.2653 14.2067ZM0.709744 2.269L1.21452 1.3044L2.31355 1.50173L2.45986 2.64671L1.48098 3.10039L0.709744 2.269Z"
        />
      </g>
      <text
        :x="textPosX"
        :y="textPosY"
        fill="#77b"
        style="
          font-size: 32px;
          font-weight: bold;
        "
      >
        {{ name }}
      </text>
    </svg>
  </div>
</template>

<script>
export default {
  name: "olabmolaritysvggen",
  props: ["name", "textPosX", "textPosY"]
};
</script>

import { OlabUtils } from "./olabutils.js";

class OlabCart {
  constructor(cart) {
    if (cart == null) {
      this.initCart();
    } else {
      let hp = Object.prototype.hasOwnProperty;
      this.olab_id = hp.call(cart, "olab_id") ? cart.olab_id : null;
      this.olab_type = hp.call(cart, "olab_type") ? cart.olab_type : null;
      this.deleted = hp.call(cart, "deleted") ? cart.deleted : false;
      this.name = hp.call(cart, "name") ? cart.name : null;
      this.creator = hp.call(cart, "creator") ? cart.creator : null;
      this.project_id = hp.call(cart, "project_id") ? cart.project_id : null;
      this.desc = hp.call(cart, "desc") ? cart.desc : null;
      // TODO: Make comps as an array of a cart's comps (OlabComp) class
      this.comps = hp.call(cart, "comps") ? cart.comps : [];
      this.cstbs = hp.call(cart, "cstbs") ? cart.cstbs : [];
    }
  }

  initCart() {
    OlabUtils.infoLog("OlabCart.initCart() ...");
    // Common fields: internal use
    this.olab_id = null;
    this.olab_type = null;
    this.deleted = false;

    // Common fields: User settable
    this.name = null;
    this.creator = null;
    this.project_id = null;
    this.desc = null;
    this.comps = [];
    this.cstbs = [];
  }

  set(cart) {
    this.olab_id = cart.olab_id;
    this.olab_type = cart.olab_type;
    this.deleted = cart.deleted;
    this.name = cart.name;
    this.creator = cart.creator;
    this.project_id = cart.project_id;
    this.desc = cart.desc;
    this.comps = cart.comps;
    this.cstbs = cart.cstbs;
  }

  resetUsedComps() {
    // Clear all comps used_count to 0 and set availabe_count to qty
    if (this.comps && this.comps.length > 0) {
      this.comps.forEach(c => {
        c.availabe_count = c.qty;
        c.used_count = 0;
      });
    }
  }

  static infoLog(cart) {
    OlabUtils.infoLog("cart.olab_id = " + cart.olab_id);
    OlabUtils.infoLog("cart.olab_type = " + cart.olab_type);
    OlabUtils.infoLog("cart.name = " + cart.name);
    OlabUtils.infoLog("cart.creator = " + cart.creator);
    OlabUtils.infoLog("cart.project_id = " + cart.project_id);
    OlabUtils.infoLog("cart.desc = " + cart.desc);
    OlabUtils.infoLog("cart.comps = " + cart.comps);
    if (cart.comps !== null) {
      OlabUtils.infoLog("cart.comps = [");
      cart.comps.forEach(element => {
        OlabUtils.infoLog(
          "   olab_id = " + element.olab_id + ", qty = " + element.qty
        );
      });
      OlabUtils.infoLog("]");
    }
    OlabUtils.infoLog("cart.cstbs = " + cart.cstbs);
    if (cart.cstbs !== null) {
      OlabUtils.infoLog("cart.cstbs = [");
      cart.cstbs.forEach(element => {
        OlabUtils.infoLog(
          "   olab_id = " +
            element.olab_id +
            ", use_seq_as = " +
            element.use_seq_as
        );
      });
      OlabUtils.infoLog("]");
    }
  }
}

export { OlabCart };

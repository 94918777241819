<template>
  <div
    v-if="(selectedType === 'cart' || selectedType === 'package') && item.comps"
    class="card"
  >
    <div class="card-header text-dark">Components</div>
    <div class="card-body">
      <div v-if="selectedType === 'cart'">
        <div class="table-responsive">
          <table class="table table-striped table-sm">
            <thead>
              <tr>
                <th class="small" scope="col">#</th>
                <th class="small" scope="col">OLAB ID</th>
                <th class="small" scope="col">Name</th>
                <th class="small" scope="col">Locus Tag</th>
                <th class="small" scope="col">
                  Old Locus Tag
                </th>
                <th class="small" scope="col">Use As</th>
                <th class="small" scope="col">Length</th>
                <th class="small" scope="col">Quantity</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(comp, index) in item.comps" :key="index">
                <th class="small" scope="row">{{ index + 1 }}</th>
                <td class="small">{{ comp.olab_id }}</td>
                <td class="small">{{ comp.name }}</td>
                <td class="small">{{ comp.locus_tag }}</td>
                <td class="small">
                  {{ comp.old_locus_tag }}
                </td>
                <td class="small">{{ comp.use_seq_as }}</td>
                <td class="small">{{ comp.length }}</td>
                <td class="small">{{ comp.qty }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div
        v-else-if="
          selectedType === 'package' && item.package_seq_type === 'genome'
        "
      >
        <div class="table-responsive">
          <table class="table table-striped table-sm">
            <thead>
              <tr>
                <th class="small" scope="col">#</th>
                <th class="small" scope="col">OLAB ID</th>
                <th class="small" scope="col">Name</th>
                <th class="small" scope="col">Locus Tag</th>
                <th class="small" scope="col">
                  Old Locus Tag
                </th>
                <th class="small" scope="col">Use As</th>
                <th class="small" scope="col">Length</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(comp, index) in item.comps" :key="index">
                <th class="small" scope="row">{{ index + 1 }}</th>
                <td class="small">{{ comp.olab_id }}</td>
                <td class="small">{{ comp.name }}</td>
                <td class="small">{{ comp.locus_tag }}</td>
                <td class="small">
                  {{ comp.old_locus_tag }}
                </td>
                <td class="small">{{ comp.use_seq_as }}</td>
                <td class="small">{{ comp.length }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-else>
        <div class="table-responsive">
          <table class="table table-striped table-sm">
            <thead>
              <tr>
                <th class="small" scope="col">#</th>
                <th class="small" scope="col">OLAB ID</th>
                <th class="small" scope="col">Name</th>
                <th class="small" scope="col">Note</th>
                <th class="small" scope="col">Use As</th>
                <th class="small" scope="col">Length</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(comp, index) in item.comps" :key="index">
                <th class="small" scope="row">{{ index + 1 }}</th>
                <td class="small">{{ comp.olab_id }}</td>
                <td class="small">{{ comp.name }}</td>
                <td v-if="comp.note" class="small">{{ comp.note }}</td>
                <td v-else class="small">--</td>
                <td class="small">{{ comp.use_seq_as }}</td>
                <td class="small">{{ comp.length }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- .card-body -->
  </div>
</template>

<script>
export default {
  name: "olabcomptable",
  props: ["selectedType", "item"]
};
</script>

<template>
  <div>
    <div class="card-body">
      <div class="form-row justify-content-center">
        <div class="col col-md-8 mb-3">
          <treeselect
            class="vue-treeselect"
            v-model="selectedUserID"
            :multiple="false"
            :disableFuzzyMatching="true"
            :disable-branch-nodes="true"
            :clear-on-select="true"
            :close-on-select="true"
            :async="true"
            :load-options="loadUserOptions"
            placeholder="Select a user ..."
          />
        </div>
        <!-- <span>selected = {{ selectedUserID }}</span> -->
      </div>
      <div v-if="selectedUser && selectedUser.user" class="mt-3">
        <div class="form-group mb-5">
          <div class="form-row">
            <div class="col-md-6 mt-3">
              <label class="h5 form-control-label">
                From Date
              </label>
              <input class="form-control" type="date" v-model="userFromDate" />
            </div>
            <div class="col-md-6 mt-3">
              <label class="h5 form-control-label">To Date</label>
              <input class="form-control" type="date" v-model="userToDate" />
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <span class="mr-1">OLAB ID:</span>
            <span class="ml-1">{{ selectedUser.user.olab_id }}</span>
          </div>
          <div class="form-group col-md-6">
            <span class="mr-1">Total Memory:</span>
            <span class="ml-1">{{
              formatMemorySize(selectedUser.total_memory)
            }}</span>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-12">
            <span class="mr-1">Name:</span>
            <span class="ml-1">{{ selectedUser.user.name }}</span>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <span class="mr-1">Email:</span>
            <span class="ml-1">{{ selectedUser.user.email }}</span>
          </div>
          <div class="form-group col-md-6">
            <span class="mr-1">Role:</span>
            <span class="ml-1">{{ selectedUser.user.role }}</span>
          </div>
        </div>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Collection</th>
              <th scope="col">Memory</th>
              <th scope="col">Count</th>
              <th scope="col">Deleted</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(collection, index) in selectedUser.collections"
              :key="index"
            >
              <th scope="row">
                {{ collection.name }}
              </th>
              <td>{{ formatMemorySize(collection.memory) }}</td>
              <td>{{ collection.count }}</td>
              <td>{{ collection.deleted }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { OlabAxios } from "@/olab/olabaxios.js";
import { OlabUtils } from "@/olab/olabutils.js";

// Access treeselect component
import Treeselect from "@riophae/vue-treeselect";
import { ASYNC_SEARCH } from "@riophae/vue-treeselect";

// Access treeselect CSS styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  name: "OlabUserTab",
  data() {
    return {
      selectedUser: null,
      selectedUserID: null,
      userFromDate: null,
      userToDate: null,
      users: null
    };
  },
  components: {
    Treeselect
  },
  props: ["user", "dbInfo", "statusObj", "errorObj"],
  methods: {
    formatMemorySize(size) {
      return OlabUtils.formatMemorySize(size);
    },
    async loadUserOptions({ action, searchQuery, callback }) {
      if (action === ASYNC_SEARCH) {
        // console.log("ASYNC_SEARCH: searchQuery =", searchQuery);

        const ITEMS_PER_PAGE = 10;
        const searchBody = {
          searchString: searchQuery,
          searchField: "merge",
          includeDeleted: false,
          auxID: null,
          page: 1,
          limit: ITEMS_PER_PAGE
        };
        this.users = await OlabUtils.getUsers(searchBody);
        // console.log("(1) users =", this.users);
        // Add new fields to users to match selecttree data format
        this.users.forEach(usr => {
          usr.id = usr.olab_id;
          usr.label = `${usr.olab_id} - ${usr.name}`;
        });
        callback(null, this.users);
      }
    },
    async loadUserStats() {
      // console.log("loadUserStats");
      this.statusObj.message =
        "Processing memory usage may take a while. Please wait ...";
      this.statusObj.progress = true;
      this.selectedUser = await OlabAxios.getUserStats(
        this.selectedUserID,
        this.userFromDate,
        this.userToDate,
        this.statusObj,
        this.errorObj
      );
      this.statusObj.message = null;
      this.statusObj.progress = false;
    }
  },
  watch: {
    selectedUserID() {
      // console.log("selectedUserID =", this.selectedUserID);
      if (this.selectedUserID && this.users) {
        for (let i = 0; i < this.users.length; i++) {
          const tmpPd = this.users[i];
          if (tmpPd.olab_id === this.selectedUserID) {
            this.selectedUser = this.users[i];
            break;
          }
        }
        if (this.selectedUser) {
          this.loadUserStats();
        }
      }
    },
    userFromDate() {
      // console.log("userFromDate =", this.userFromDate);
      if (this.selectedUser) {
        this.loadUserStats();
      }
    },
    userToDate() {
      // console.log("userToDate =", this.userToDate);
      if (this.selectedUser) {
        this.loadUserStats();
      }
    }
  }
};
</script>

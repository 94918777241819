import { OlabUtils } from "./olabutils.js";
import { OlabSearch } from "./olabsearch.js";

// TODO: Redesign this class
//  Rename to OlabBoxComp and use OlabComp for cart/package's comps

class OlabComp {
  static availBoxType = {
    gene: false,
    promoter: false,
    rbs: false,
    pgst: false,
    terminator: false,
    us_ds: false
  };

  constructor(cartComp) {
    if (cartComp == null) {
      this.initComp();
    } else {
      // cartComp is the item from the cart's comp array
      this.comp = cartComp;
      this.display_name = this.getDisplayName();
      // This is only use in Design to select items in the cart
      this.ct_id_name = null;
      // this.seq will be loaded in this.loadSeq()
      this.seq = null;
    }
  }

  initComp() {
    // OlabUtils.infoLog("OlabComp.initComp() ...");
    this.comp = null;
    this.display_name = null;
    this.ct_id_name = null;
    this.seq = null;
  }

  getDisplayName() {
    let dName = null;
    if (this.comp.name) {
      dName = this.comp.name;
    } else if (this.comp.old_locus_tag) {
      dName = this.comp.old_locus_tag;
    } else if (this.comp.locus_tag) {
      dName = this.comp.locus_tag;
    } else {
      dName = this.comp.olab_id;
    }
    return dName;
  }

  getSeqLength() {
    let len = 0;
    if (this.comp) {
      if (this.comp.olab_type == "strain") {
        if (this.seq && this.seq.gene_stats) {
          len = this.seq.gene_stats.len;
        }
      } else if (this.comp.olab_type == "library_sequence") {
        if (this.seq && this.seq.seq) {
          len = this.seq.seq.length;
        }
      } else if (this.comp.olab_type == "package") {
        len = 0;
      } else {
        OlabUtils.infoLog(
          "getSeqLength: Warning - comp's type unknown. Return 0 length"
        );
      }
    } else {
      OlabUtils.infoLog(
        "getSeqLength: Warning - comp is null. Return 0 length"
      );
    }
    return len;
  }

  static restoreSeqGene(seq) {
    if (seq && seq.comps) {
      const comps = seq.comps;
      for (let i = 0; i < comps.length; i++) {
        OlabUtils.infoLog("comps[" + i + "].olab_id = " + comps[i].olab_id);
        const comp = comps[i];
        if (comp.olab_id && comp.olab_type == "strain") {
          if (comp.seqGene) {
            comp.seq = comp.seqGene.sequence.seq;
          }
        }
      }
    }
  }

  static async loadSeqGene(seq) {
    if (seq && seq.comps) {
      const comps = seq.comps;
      for (let i = 0; i < comps.length; i++) {
        // console.log("comps[" + i + "].olab_id = " + comps[i].olab_id);
        const comp = comps[i];
        if (comp.olab_id && comp.olab_type == "strain") {
          const searchBody = OlabSearch.createGeneSearchBody(
            comp.olab_id,
            "olab_id",
            null,
            1,
            1
          );
          const geneArr = await OlabUtils.getGenes(searchBody);
          // console.log("geneArr len = " + geneArr.length);
          if (geneArr.length > 0) {
            comp.seqGene = geneArr[0];
          } else {
            OlabUtils.infoLog(
              "Error!!! Gene not found: olab_id = " + comp.olab_id
            );
          }
        }
      }
    }
  }

  async loadSeq() {
    // console.log("loadSeq: olab_id = " + this.comp.olab_id);

    if (this.comp.olab_id) {
      if (this.comp.olab_type == "strain") {
        const searchBody = OlabSearch.createGeneSearchBody(
          this.comp.olab_id,
          "olab_id",
          null,
          1,
          1
        );
        const geneArr = await OlabUtils.getGenes(searchBody);
        // console.log("geneArr len = " + geneArr.length);
        if (geneArr.length > 0) {
          this.seq = geneArr[0];
        } else {
          OlabUtils.infoLog(
            "Error!!! Gene not found: olab_id = " + this.comp.olab_id
          );
        }
      } else if (this.comp.olab_type == "library_sequence") {
        const searchBody = OlabSearch.createItemSearchBody(
          "libseq",
          this.comp.olab_id,
          "olab_id",
          false,
          null,
          1,
          1
        );
        const libseqArr = await OlabUtils.getLibseqs(searchBody);
        OlabUtils.infoLog("libseqArr len = " + libseqArr.length);
        if (libseqArr.length > 0) {
          // Convert Item class to Library Sequence class
          this.seq = {
            name: libseqArr[0].name,
            olab_id: libseqArr[0].olab_id,
            desc: libseqArr[0].desc,
            creator: libseqArr[0].creator,
            project_id: libseqArr[0].project_id,
            library_id: libseqArr[0].library_id,
            olab_type: libseqArr[0].olab_type,
            gene_stat: libseqArr[0].gene_stat,
            seq: libseqArr[0].seq,
            seq_origin: libseqArr[0].seq_origin,
            source: libseqArr[0].source
          };
        } else {
          OlabUtils.infoLog(
            "Error!!! Library Sequence not found: olab_id = " +
              this.comp.olab_id
          );
        }
      } else if (this.comp.olab_type == "package") {
        const searchBody = OlabSearch.createItemSearchBody(
          "package",
          this.comp.olab_id,
          "olab_id",
          false,
          null,
          1,
          1
        );
        const packageArr = await OlabUtils.getPackages(searchBody);
        // console.log("packageArr len = ", packageArr.length);
        if (packageArr.length > 0) {
          // Convert Item class to Package class
          this.seq = {
            name: packageArr[0].name,
            olab_id: packageArr[0].olab_id,
            desc: packageArr[0].desc,
            creator: packageArr[0].creator,
            project_id: packageArr[0].project_id,
            library_id: packageArr[0].library_id,
            package_type: packageArr[0].package_type,
            package_seq_type: packageArr[0].package_seq_type,
            olab_type: packageArr[0].olab_type,
            comps: packageArr[0].comps
          };
          this.seq.seq = this.seq.library_id + " Package";
          // load comp's seqGenes if it is genome package
          if (this.seq.package_seq_type == "genome") {
            OlabUtils.infoLog(
              this.seq.package_seq_type + " - loading seqGenes ..."
            );
            await OlabComp.loadSeqGene(this.seq);
          }
        } else {
          OlabUtils.infoLog(
            "Error!!! Package not found: olab_id = " + this.comp.olab_id
          );
        }
      } else {
        OlabUtils.infoLog(
          "Error!!! Unknown olab_type = " + this.comp.olab_type
        );
      }
    }
  }

  static computeAvailBoxType(currentBoxType, cartItems) {
    // OlabUtils.infoLog("computeAvailBoxType ...");
    // Reset flags
    OlabComp.availBoxType.gene = false;
    OlabComp.availBoxType.promoter = false;
    OlabComp.availBoxType.rbs = false;
    OlabComp.availBoxType.pgst = false;
    OlabComp.availBoxType.terminator = false;
    OlabComp.availBoxType.us_ds = false;

    // Take current box type into account
    switch (currentBoxType) {
      case "up_stream":
      case "down_stream":
        OlabComp.availBoxType.us_ds = true;
        break;
      case "gene":
        OlabComp.availBoxType.gene = true;
        break;
      case "promoter":
        OlabComp.availBoxType.promoter = true;
        break;
      case "rbs":
        OlabComp.availBoxType.rbs = true;
        break;
      case "pgst":
        OlabComp.availBoxType.pgst = true;
        break;
      case "terminator":
        OlabComp.availBoxType.terminator = true;
        break;
    }

    // OlabUtils.infoLog(
    //   "computeAvailBoxType : cartItems.length = " + cartItems.length
    // );
    for (let i = 0; i < cartItems.length; i++) {
      const comp = cartItems[i].comp;
      if (comp.available_count > 0) {
        switch (comp.use_seq_as) {
          case "arm":
            // arm sequence is to be used as US or DS
            OlabComp.availBoxType.us_ds = true;
            break;
          case "gene":
            OlabComp.availBoxType.gene = true;
            break;
          case "promoter":
            OlabComp.availBoxType.promoter = true;
            break;
          case "rbs":
            OlabComp.availBoxType.rbs = true;
            break;
          case "pgst":
            OlabComp.availBoxType.pgst = true;
            break;
          case "terminator":
            OlabComp.availBoxType.terminator = true;
            break;
        }
        if (comp.olab_type == "strain") {
          OlabComp.availBoxType.us_ds = true;
        }
      }
    }
  }

  static evalToAdd(ci, boxType, ciArr) {
    switch (boxType) {
      case "down_stream":
      case "up_stream":
        // Beside strain type we also want to include arm or gene packages
        if (
          ci.comp.olab_type == "strain" ||
          ci.comp.use_seq_as == "arm" ||
          ci.comp.use_seq_as == "gene"
        ) {
          ciArr.push(ci);
        }
        break;
      case "gene":
        if (ci.comp.use_seq_as == "gene") {
          ciArr.push(ci);
        }
        break;
      case "promoter":
        if (ci.comp.use_seq_as == "promoter") {
          ciArr.push(ci);
        }
        break;
      case "rbs":
        if (ci.comp.use_seq_as == "rbs") {
          ciArr.push(ci);
        }
        break;
      case "pgst":
        if (ci.comp.use_seq_as == "pgst") {
          ciArr.push(ci);
        }
        break;
      case "terminator":
        if (ci.comp.use_seq_as == "terminator") {
          ciArr.push(ci);
        }
        break;
    }
  }

  static filterCartItems(currentItem, boxType, cartItems) {
    let ciArr = null;
    if (boxType != "") {
      ciArr = [];

      // Add currentItem into filter for selection
      if (currentItem) {
        OlabComp.evalToAdd(currentItem, boxType, ciArr);
      }

      for (let i = 0; i < cartItems.length; i++) {
        const ci = cartItems[i];
        if (ci.comp.available_count > 0) {
          OlabComp.evalToAdd(ci, boxType, ciArr);
        }
      }
    }
    return ciArr;
  }
}

export { OlabComp };

<template>
  <div>
    <form class="mt-3" @submit.prevent="reset">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <div class="card bg-light">
              <div class="card-body">
                <h3 class="font-weight-light mb-3">Reset Password</h3>
                <section class="form-group">
                  <div
                    v-if="errorObj.message"
                    class="col-12 alert alert-danger text-center px-3"
                  >
                    {{ errorObj.message }}
                    <div class="text-center mt-2">
                      <button
                        type="button"
                        class="btn btn-danger"
                        v-on:click="closeError()"
                      >
                        OK
                      </button>
                    </div>
                  </div>
                  <div class="col-12 alert alert-success px-3" v-if="message">
                    {{ message }}
                  </div>
                  <input
                    class="form-control"
                    v-bind:class="validPassOneState()"
                    type="password"
                    required
                    placeholder="Password"
                    v-model="passOne"
                  />
                  <div class="invalid-feedback">{{ invalidFB.passOne }}</div>
                </section>
                <section class="form-group">
                  <input
                    class="form-control"
                    v-bind:class="validPassTwoState()"
                    type="password"
                    required
                    placeholder="Repeat Password"
                    v-model="passTwo"
                  />
                  <div class="invalid-feedback">{{ invalidFB.passTwo }}</div>
                </section>
                <div class="form-group text-right mb-0">
                  <button
                    v-bind:disabled="submitDisabled == true"
                    class="btn btn-primary"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import axios from "axios";
import { OlabUtils } from "../olab/olabutils";

export default {
  data: function() {
    return {
      passOne: null,
      passTwo: null,
      invalidFB: {
        passOne: "password has to be 8 or more characters",
        passTwo: "passwords must match"
      },
      submitDisabled: false,
      message: null
    };
  },
  props: ["errorObj"],
  methods: {
    validPassOneState: function() {
      console.log("validPassOneState: passOne = " + this.passOne);
      let result = null;
      if (this.passOne !== null) {
        if (this.passOne.length < 8) {
          result = "is-invalid";
          this.submitDisabled = this.passTwo !== null ? true : false;
        } else {
          result = "is-valid";
        }
      }
      return result;
    },
    validPassTwoState: function() {
      console.log("validPassTwoState: passTwo = " + this.passTwo);
      let result = null;
      if (this.passOne !== null && this.passTwo !== null) {
        if (this.passOne !== this.passTwo) {
          result = "is-invalid";
          this.submitDisabled = true;
        } else {
          result = "is-valid";
          this.submitDisabled = this.passOne.length < 8 ? true : false;
        }
      }
      return result;
    },
    reset: async function() {
      const info = {
        password: this.passOne,
        passwordConfirm: this.passTwo
      };

      console.log("ResetPassword: Submit password and passwordConfirm", info);
      console.log("param:", this.$route.params.token);
      try {
        const res = await axios({
          method: "PATCH",
          url: `/api/v1/users/resetpassword/${this.$route.params.token}`,
          data: {
            password: info.password,
            passwordConfirm: info.passwordConfirm
          }
        });
        if (res.data.status === "success") {
          console.log("Password reset completed.");
          this.message = "Password reset completed.";
          setTimeout(() => {
            this.$router.push("/");
          }, 3000);
        }
      } catch (err) {
        // OlabUtils.errorLog(err.response.data.message, err);
        this.errorObj.message = OlabUtils.getErrorMessage(err);
      }
    }
  },
  watch: {
    passOne: function() {
      console.log("passOne = " + this.passOne);
    },
    closeError: function() {
      // console.log("Close Error Alert ...");
      this.errorObj.message = null;
    }
  }
};
</script>

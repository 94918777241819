import { OlabUtils } from "./olabutils.js";

class OlabPrimer {
  constructor(olab_id) {
    // OlabUtils.infoLog("OlabPrimer.constructor() ...");
    this.olab_id = olab_id;
    this.name = null;
    this.pmr_3p_len = 0;
    this.pmr_5p_len = 0;
    this.reverse = false;
    this.seq = null;
    this.tm = 0;
    this.tm3p = 0;

    // Add padding support for SNPSwap case
    this.pad = null;
    this.seqWithPad = null;
    this.tmWithPad = 0;
  }

  set(name, pmr_3p_len, pmr_5p_len, reverse, seq) {
    this.name = name;
    this.pmr_3p_len = pmr_3p_len;
    this.pmr_5p_len = pmr_5p_len;
    this.reverse = reverse;
    // console.log("OlabPrimer.set(): this = ", this);
    if (!seq || seq.length < 1) return;

    const str3p = seq.substr(pmr_5p_len, seq.length - pmr_5p_len);

    // Convert 5 prime segment to lowercase for easy viewing of primer
    if (pmr_5p_len > 0) {
      const str5p = seq.substr(0, pmr_5p_len);
      // OlabUtils.infoLog("str5p = " + str5p + ", str3p = " + str3p);
      const finalpmr = str5p.toLowerCase() + str3p;
      // OlabUtils.infoLog("finalpmr = " + finalpmr);
      this.seq = finalpmr;
    } else {
      this.seq = seq;
    }
    if (seq) {
      // Compute melting temperature
      this.tm = OlabPrimer.computeMT(seq);
      this.tm3p = OlabPrimer.computeMT(str3p);
    }

    // Compute with padding info if there is padding
    this.setPmrPad(this.pad);
  }

  setPmrPad(pad) {
    // Note: padding is treated as 5' therefore no need to recompute tm3p
    // console.log(" setPmrPad: pad = " + pad);
    this.pad = pad;
    if (this.pad) {
      // Set pad to lowercase for better readability
      this.seqWithPad = this.pad.toLowerCase() + this.seq;
      this.tmWithPad = OlabPrimer.computeMT(this.seqWithPad);
    } else {
      this.seqWithPad = this.seq;
      this.tmWithPad = this.tm;
    }
  }

  static reverseSeq(seq) {
    let reversed = "";
    for (let char of seq) {
      reversed = char + reversed;
    }
    return reversed;
  }

  static complementSeq(seq) {
    let tmpSeq = "";
    for (let i = 0; i < seq.length; i++) {
      switch (seq[i]) {
        case "A":
          tmpSeq += "T";
          break;
        case "T":
          tmpSeq += "A";
          break;
        case "C":
          tmpSeq += "G";
          break;
        case "G":
          tmpSeq += "C";
          break;
        default:
          OlabUtils.infoLog("ERR: seq not in ACGT form. " + seq);
      }
    }
    return tmpSeq;
  }

  static extractSeq(seq, extractLen, front, complement) {
    // OlabUtils.infoLog("extractSeq: extractLen = " + extractLen + ", front = " + front + ", complement = " + complement);
    // OlabUtils.infoLog("extractSeq: seq = " + seq);
    if (seq == null) return;

    let index = 0;
    if (front == false) {
      index = seq.length - extractLen;
    }

    let subSeq = seq.substr(index, extractLen);
    if (complement) {
      subSeq = OlabPrimer.complementSeq(subSeq);
      // OlabUtils.infoLog("Complemented subSeq = " + subSeq);

      subSeq = OlabPrimer.reverseSeq(subSeq);
      // OlabUtils.infoLog("reversed = " + subSeq);
    }
    return subSeq;
  }

  static computeMT(pmrSeq) {
    // http://insilico.ehu.es/tm.php?formula=basic
    let temp = 0;
    let aCnt = 0,
      cCnt = 0,
      gCnt = 0,
      tCnt = 0;
    for (let i = 0; i < pmrSeq.length; i++) {
      const seq = pmrSeq[i];
      if (seq == "a" || seq == "A") {
        aCnt++;
      } else if (seq == "c" || seq == "C") {
        cCnt++;
      } else if (seq == "g" || seq == "G") {
        gCnt++;
      } else if (seq == "t" || seq == "T") {
        tCnt++;
      } else {
        OlabUtils.infoLog("Error is primer sequence => " + pmrSeq);
      }
    }
    if (pmrSeq.length < 14) {
      // Tm= (wA+xT) * 2 + (yG+zC) * 4
      temp = (aCnt + tCnt) * 2 + (gCnt + cCnt) * 4;
    } else {
      // Tm= 64.9 +41*(yG+zC-16.4)/(wA+xT+yG+zC)
      temp = 64.9 + (41 * (gCnt + cCnt - 16.4)) / (aCnt + tCnt + gCnt + cCnt);
    }
    // OlabUtils.infoLog("temperature = " + temp);
    return temp;
  }

  static isLTForwardDir(cstbs, index) {
    let fwdDir = false;
    const cstbPrev = index > 0 ? cstbs[index - 1] : null;
    if (cstbPrev && cstbPrev.seq) {
      if (cstbPrev.seq.length <= 60) {
        // Do LT alg. for foward primer
        fwdDir = true;
      }
    }
    return fwdDir;
  }

  static isLTRevDir(cstbs, index) {
    let revDir = false;
    const cstbNext = index + 1 < cstbs.length ? cstbs[index + 1] : null;
    if (cstbNext && cstbNext.seq) {
      if (cstbNext.seq.length <= 60) {
        revDir = true;
      }
    }
    return revDir;
  }

  static computeLT20FWD(pmrObj, index, cstID, pmr3pLen, pmr5pLen, pmrCnt) {
    // console.log("computeLT20FWD: pmrObj = ", pmrObj, ", index = ", index);
    const fps = pmrObj.forwards;
    const cstbs = pmrObj.cstbs;
    const cstb = cstbs[index];
    const cstbPrev = index > 0 ? cstbs[index - 1] : null;
    const cstbPrev2 = index > 1 ? cstbs[index - 2] : null;

    // Assert
    if (!cstbPrev || !cstbPrev.seq) {
      OlabUtils.infoLog(
        "computeLT20FWD - Error: cstbPrev or cstbPrev.seq is null"
      );
    }
    let cstbPrevLen = cstbPrev.seq.length;
    let cstbPrev2CutLen = 0;
    let pmrSeqPrev2 = "";
    let name = null;

    // compute required 5' len for the short box sequence (cstbPrev.seq)
    // to have a minimum length of 20 bps
    const pmr5pOffsetLen = pmr5pLen > 20 ? pmr5pLen : 20;

    if (cstbPrev2 && cstbPrev2.seq) {
      name = cstbPrev2.display_name;
      const cstbPrev2Len = cstbPrev2.seq.length;
      // Case where this is true LT20 neighbor
      if (cstbPrevLen <= 20) {
        cstbPrev2CutLen = pmr5pOffsetLen - cstbPrevLen;
        if (cstbPrev2Len < cstbPrev2CutLen) {
          cstbPrev2CutLen = cstbPrev2Len;
        }
        pmrSeqPrev2 = OlabPrimer.extractSeq(
          cstbPrev2.seq,
          cstbPrev2CutLen,
          false,
          false
        );
      } else {
        // No need to use prev2 but need to configure cstbPrevLen
        name = null;
        cstbPrev2CutLen = 0;
        if (cstbPrevLen > pmr5pOffsetLen) {
          cstbPrevLen = pmr5pOffsetLen;
        }
      }
    }

    let pmrSeqPrev = OlabPrimer.extractSeq(
      cstbPrev.seq,
      cstbPrevLen,
      false,
      false
    );
    let pmrSeq = OlabPrimer.extractSeq(cstb.seq, pmr3pLen, true, false);
    cstID = cstID + pmrCnt.toString().padStart(2, "0");
    const pmr = new OlabPrimer(cstID);
    if (name) {
      name =
        name + "-" + cstbPrev.display_name + "-" + cstb.display_name + "-FWD";
    } else {
      name = cstbPrev.display_name + "-" + cstb.display_name + "-FWD";
    }
    pmr.set(
      name,
      pmr3pLen,
      cstbPrevLen + cstbPrev2CutLen,
      false,
      pmrSeqPrev2 + pmrSeqPrev + pmrSeq
    );

    fps.push(pmr);
    pmrCnt++;
    return pmrCnt;
  }

  static computeLT20REV(pmrObj, index, cstID, pmr3pLen, pmr5pLen, pmrCnt) {
    // console.log("computeLT20REV: pmrObj = ", pmrObj, ", index = ", index);
    const bps = pmrObj.backwards;
    const cstbs = pmrObj.cstbs;
    const cstb = cstbs[index];
    const cstbNext = index + 1 < cstbs.length ? cstbs[index + 1] : null;
    const cstbNext2 = index + 2 < cstbs.length ? cstbs[index + 2] : null;

    // Assert
    if (!cstbNext || !cstbNext.seq) {
      OlabUtils.infoLog(
        "computeLT20REV - Error: cstbNext or cstbNext.seq is null"
      );
    }
    let cstbNextLen = cstbNext.seq.length;
    let cstbNext2CutLen = 0;
    let pmrSeqNext2 = "";
    let name = null;

    // compute required 5' len for the short box sequence (cstbPrev.seq)
    // to have a minimum length of 20 bps
    const pmr5pOffsetLen = pmr5pLen > 20 ? pmr5pLen : 20;

    if (cstbNext2 && cstbNext2.seq) {
      name = cstbNext2.display_name;
      const cstbNext2Len = cstbNext2.seq.length;
      // Case where this is true LT20 neighbor
      if (cstbNextLen <= 20) {
        cstbNext2CutLen = pmr5pOffsetLen - cstbNextLen;
        if (cstbNext2Len < cstbNext2CutLen) {
          cstbNext2CutLen = cstbNext2Len;
        }
        pmrSeqNext2 = OlabPrimer.extractSeq(
          cstbNext2.seq,
          cstbNext2CutLen,
          true,
          true
        );
      } else {
        // No need to use next2 but need to configure cstbNextLen
        name = null;
        cstbNext2CutLen = 0;
        if (cstbNextLen > pmr5pOffsetLen) {
          cstbNextLen = pmr5pOffsetLen;
        }
      }
    }

    let pmrSeqNext = OlabPrimer.extractSeq(
      cstbNext.seq,
      cstbNextLen,
      true,
      true
    );
    let pmrSeq = OlabPrimer.extractSeq(cstb.seq, pmr3pLen, false, true);
    cstID = cstID + pmrCnt.toString().padStart(2, "0");
    const pmr = new OlabPrimer(cstID);
    if (name) {
      name =
        cstb.display_name + "-" + cstbNext.display_name + "-" + name + "-REV";
    } else {
      name = cstb.display_name + "-" + cstbNext.display_name + "-REV";
    }
    pmr.set(
      name,
      pmr3pLen,
      cstbNextLen + cstbNext2CutLen,
      true,
      // Complement: pmrSeqNext2, pmrSeqNext then pmrSeq
      pmrSeqNext2 + pmrSeqNext + pmrSeq
    );

    bps.push(pmr);
    pmrCnt++;
    return pmrCnt;
  }

  static handleLT20Primer(
    pmrObj,
    index,
    cstID,
    pmr3pLen,
    pmr5pLen,
    pmrSetting,
    pmrCnt
  ) {
    // OlabUtils.infoLog("handleLT20Primer:");
    const cstbs = pmrObj.cstbs;

    // Determine LT20 for Fwd or Rev
    const fwdLT20 = OlabPrimer.isLTForwardDir(cstbs, index);
    const revLT20 = OlabPrimer.isLTRevDir(cstbs, index);

    // Handle Fwd primer
    if (fwdLT20) {
      // OlabUtils.infoLog("handleLT20Primer: fwdLT20 case");
      pmrCnt = OlabPrimer.computeLT20FWD(
        pmrObj,
        index,
        cstID,
        pmr3pLen,
        pmr5pLen,
        pmrCnt
      );
    } else {
      pmrCnt = OlabPrimer.computeFwdPrimer(
        pmrObj,
        index,
        cstID,
        pmr3pLen,
        pmr5pLen,
        pmrSetting,
        pmrCnt
      );
    }

    // Handle Rev (backward) primer
    if (revLT20) {
      // OlabUtils.infoLog("handleLT20Primer: revLT20 case");
      pmrCnt = OlabPrimer.computeLT20REV(
        pmrObj,
        index,
        cstID,
        pmr3pLen,
        pmr5pLen,
        pmrCnt
      );
    } else {
      pmrCnt = OlabPrimer.computeRevPrimer(
        pmrObj,
        index,
        cstID,
        pmr3pLen,
        pmr5pLen,
        pmrSetting,
        pmrCnt
      );
    }

    return pmrCnt;
  }

  static computeLT60FWD(pmrObj, index, cstID, pmr3pLen, pmr5pLen, pmrCnt) {
    // console.log("computeLT60FWD: pmrObj = ", pmrObj, ", index = ", index);
    const fps = pmrObj.forwards;
    const cstbs = pmrObj.cstbs;
    const cstb = cstbs[index];
    const cstbPrev = index > 0 ? cstbs[index - 1] : null;

    // Assert
    if (!cstbPrev || !cstbPrev.seq) {
      OlabUtils.infoLog(
        "computeLT60FWD - Error: cstbPrev or cstbPrev.seq is null"
      );
    }
    const cstbPrevLen = cstbPrev.seq.length;

    // compute required 5' len for the short box sequence (cstbPrev.seq)
    // to have an overlap of 20 bps (more depending on pmr5pLen)
    const offset = pmr5pLen > 20 ? pmr5pLen - 10 : 10;
    const pmr5pOffsetLen = Math.ceil(cstbPrevLen / 2) + offset;
    let cstbPrevCutLen = pmr5pOffsetLen;
    // Special case for if this is the second box (start case)
    if ((index === 1 && cstbPrevLen <= 60) || cstbPrevLen < pmr5pOffsetLen) {
      cstbPrevCutLen = cstbPrevLen;
    }

    let pmrSeqPrev = OlabPrimer.extractSeq(
      cstbPrev.seq,
      cstbPrevCutLen,
      false,
      false
    );
    let pmrSeq = OlabPrimer.extractSeq(cstb.seq, pmr3pLen, true, false);
    cstID = cstID + pmrCnt.toString().padStart(2, "0");
    const pmr = new OlabPrimer(cstID);
    const name = cstbPrev.display_name + "-" + cstb.display_name + "-FWD";
    pmr.set(name, pmr3pLen, cstbPrevCutLen, false, pmrSeqPrev + pmrSeq);

    fps.push(pmr);
    pmrCnt++;
    return pmrCnt;
  }

  static computeLT60REV(pmrObj, index, cstID, pmr3pLen, pmr5pLen, pmrCnt) {
    // console.log("computeLT60REV: pmrObj = ", pmrObj, ", index = ", index);
    const bps = pmrObj.backwards;
    const cstbs = pmrObj.cstbs;
    const cstb = cstbs[index];
    const cstbNext = index + 1 < cstbs.length ? cstbs[index + 1] : null;

    // Assert
    if (!cstbNext || !cstbNext.seq) {
      OlabUtils.infoLog(
        "computeLT60REV - Error: cstbNext or cstbNext.seq is null"
      );
    }
    const cstbNextLen = cstbNext.seq.length;

    // compute required 5' len for the short box sequence (cstbNext.seq)
    // to have an overlap of 20 bps (more depending on pmr5pLen)
    const offset = pmr5pLen > 20 ? pmr5pLen - 10 : 10;
    const pmr5pOffsetLen = Math.ceil(cstbNextLen / 2) + offset;
    let cstbNextCutLen = pmr5pOffsetLen;
    // Special case if this is the second last box (end case)
    if (
      (index + 2 === cstbs.length && cstbNextLen <= 60) ||
      cstbNextLen < pmr5pOffsetLen
    ) {
      cstbNextCutLen = cstbNextLen;
    }

    let pmrSeqNext = OlabPrimer.extractSeq(
      cstbNext.seq,
      cstbNextCutLen,
      true,
      true
    );
    let pmrSeq = OlabPrimer.extractSeq(cstb.seq, pmr3pLen, false, true);
    cstID = cstID + pmrCnt.toString().padStart(2, "0");
    const pmr = new OlabPrimer(cstID);
    const name = cstb.display_name + "-" + cstbNext.display_name + "-REV";
    pmr.set(
      name,
      pmr3pLen,
      cstbNextCutLen,
      true,
      // Complement: pmrSeqNext then pmrSeq
      pmrSeqNext + pmrSeq
    );

    bps.push(pmr);
    pmrCnt++;
    return pmrCnt;
  }

  static handleLT60Primer(
    pmrObj,
    index,
    cstID,
    pmr3pLen,
    pmr5pLen,
    pmrSetting,
    pmrCnt
  ) {
    // OlabUtils.infoLog("handleLT60Primer:");
    const cstbs = pmrObj.cstbs;

    // Determine LT60 for Fwd or Rev
    const fwdLT60 = OlabPrimer.isLTForwardDir(cstbs, index);
    const revLT60 = OlabPrimer.isLTRevDir(cstbs, index);
    // console.log("fwdLT60 =", fwdLT60, " revLT60 =", revLT60);

    // Handle Fwd primer
    if (fwdLT60) {
      // console.log("handleLT60Primer: fwdLT60 case");
      pmrCnt = OlabPrimer.computeLT60FWD(
        pmrObj,
        index,
        cstID,
        pmr3pLen,
        pmr5pLen,
        pmrCnt
      );
    } else {
      pmrCnt = OlabPrimer.computeFwdPrimer(
        pmrObj,
        index,
        cstID,
        pmr3pLen,
        pmr5pLen,
        pmrSetting,
        pmrCnt
      );
    }

    // Handle Rev (backward) primer
    if (revLT60) {
      // console.log("handleLT60Primer: revLT60 case");
      pmrCnt = OlabPrimer.computeLT60REV(
        pmrObj,
        index,
        cstID,
        pmr3pLen,
        pmr5pLen,
        pmrCnt
      );
    } else {
      pmrCnt = OlabPrimer.computeRevPrimer(
        pmrObj,
        index,
        cstID,
        pmr3pLen,
        pmr5pLen,
        pmrSetting,
        pmrCnt
      );
    }
    // console.log("pmrCnt =", pmrCnt);
    return pmrCnt;
  }

  static createRevHalf(cstb, cstID, pmr3pLen) {
    let tmpSeq = OlabPrimer.extractSeq(cstb.seq, pmr3pLen, false, true);
    const pmr = new OlabPrimer(cstID);
    pmr.set(cstb.display_name + "-REV", pmr3pLen, 0, true, tmpSeq);
    return pmr;
  }

  static createRevFull(cstb, cstbNext, cstID, pmr3pLen, pmr5pLen) {
    // FULL case with pmr3pLen on box and pmr5pLen on other box
    let tmpSeq = OlabPrimer.extractSeq(cstb.seq, pmr3pLen, false, true);
    let tmp2Seq = OlabPrimer.extractSeq(cstbNext.seq, pmr5pLen, true, true);
    const pmr = new OlabPrimer(cstID);
    const name = cstb.display_name + "-" + cstbNext.display_name + "-REV";
    pmr.set(
      name,
      pmr3pLen,
      pmr5pLen,
      true,
      // Complement: tmp2Seq then tmpSeq
      tmp2Seq + tmpSeq
    );
    return pmr;
  }

  static createFwdHalf(cstb, cstID, pmr3pLen) {
    let tmpSeq = OlabPrimer.extractSeq(cstb.seq, pmr3pLen, true, false);
    const pmr = new OlabPrimer(cstID);
    pmr.set(cstb.display_name + "-FWD", pmr3pLen, 0, false, tmpSeq);
    return pmr;
  }

  static createFwdFull(cstb, cstbPrev, cstID, pmr3pLen, pmr5pLen) {
    // FULL case with pmr3pLen on box and pmr5pLen on the other box
    let tmpSeq = OlabPrimer.extractSeq(cstbPrev.seq, pmr5pLen, false, false);
    let tmp2Seq = OlabPrimer.extractSeq(cstb.seq, pmr3pLen, true, false);
    const pmr = new OlabPrimer(cstID);
    const name = cstbPrev.display_name + "-" + cstb.display_name + "-FWD";
    pmr.set(name, pmr3pLen, pmr5pLen, false, tmpSeq + tmp2Seq);
    return pmr;
  }

  static computeFwdPrimer(
    pmrObj,
    index,
    cstID,
    pmr3pLen,
    pmr5pLen,
    pmrSetting,
    pmrCnt
  ) {
    // console.log("computeFwdPrimer: pmrObj = ", pmrObj, ", index = ", index);
    const fps = pmrObj.forwards;
    const cstbs = pmrObj.cstbs;
    const cstb = cstbs[index];
    cstID = cstID + pmrCnt.toString().padStart(2, "0");
    pmrCnt++;

    if (index == 0) {
      const pmr = OlabPrimer.createFwdHalf(cstb, cstID, pmr3pLen);
      fps.push(pmr);
    } else {
      if (pmrSetting == "FWD_HALF") {
        const pmr = OlabPrimer.createFwdHalf(cstb, cstID, pmr3pLen);
        fps.push(pmr);
      } else {
        // FULL case with pmr3pLen on first half and pmr5pLen on second half
        const pmr = OlabPrimer.createFwdFull(
          cstb,
          cstbs[index - 1],
          cstID,
          pmr3pLen,
          pmr5pLen
        );
        fps.push(pmr);
      }
    }
    return pmrCnt;
  }

  static computeRevPrimer(
    pmrObj,
    index,
    cstID,
    pmr3pLen,
    pmr5pLen,
    pmrSetting,
    pmrCnt
  ) {
    // console.log("computeRevPrimer: pmrObj = ", pmrObj, ", index = ", index);
    const bps = pmrObj.backwards;
    const cstbs = pmrObj.cstbs;
    const cstb = cstbs[index];
    cstID = cstID + pmrCnt.toString().padStart(2, "0");
    pmrCnt++;

    if (index == cstbs.length - 1 || cstbs[index + 1].configured == false) {
      const pmr = OlabPrimer.createRevHalf(cstb, cstID, pmr3pLen);
      bps.push(pmr);
    } else {
      if (pmrSetting == "REV_HALF") {
        const pmr = OlabPrimer.createRevHalf(cstb, cstID, pmr3pLen);
        bps.push(pmr);
      } else {
        // FULL case with pmr3pLen on first half and pmr5pLen on second half
        const pmr = OlabPrimer.createRevFull(
          cstb,
          cstbs[index + 1],
          cstID,
          pmr3pLen,
          pmr5pLen
        );
        bps.push(pmr);
      }
    }
    return pmrCnt;
  }

  static handleDefaultPrimer(
    pmrObj,
    index,
    cstID,
    pmr3pLen,
    pmr5pLen,
    pmrSetting,
    pmrCnt
  ) {
    // console.log("handleDefaultPrimer: pmrObj = ", pmrObj, ", index = ", index);

    pmrCnt = OlabPrimer.computeFwdPrimer(
      pmrObj,
      index,
      cstID,
      pmr3pLen,
      pmr5pLen,
      pmrSetting,
      pmrCnt
    );

    pmrCnt = OlabPrimer.computeRevPrimer(
      pmrObj,
      index,
      cstID,
      pmr3pLen,
      pmr5pLen,
      pmrSetting,
      pmrCnt
    );

    return pmrCnt;
  }

  static handleNonePrimer(pmrObj, index) {
    // console.log("handleNonePrime: pmrObj = ", pmrObj, ", index = ", index);
    const fps = pmrObj.forwards;
    const bps = pmrObj.backwards;
    const cstbs = pmrObj.cstbs;
    const cstb = cstbs[index];
    if (cstb.primer_type == "none") {
      const pmr = new OlabPrimer(null);
      pmr.set(null, 0, 0, false, null);
      fps.push(pmr);
      bps.push(pmr);
    } else {
      OlabUtils.infoLog(
        "Error - handleNonePrimer: prime_type = " + cstb.primer_type
      );
    }
  }

  static computePrimers(pmrObj, pmr3pLen, pmr5pLen, pmrSetting) {
    // console.log(
    //   "computePrimers:pmrObj = ",
    //   pmrObj,
    //   ", pmr3pLen = ",
    //   pmr3pLen,
    //   ", pmr5pLen = ",
    //   pmr5pLen,
    //   ", pmrSetting = ",
    //   pmrSetting
    // );
    const cstbs = pmrObj.cstbs;

    // Append a "-" to add in primer count
    let cstID = pmrObj.olab_id + "-";
    // Primer ID has to start from 1
    let pmrCnt = 1;
    for (let ii = 0; ii < cstbs.length; ii++) {
      const cstb = cstbs[ii];
      if (cstb.configured == false) {
        break;
      }

      // Support box primer config override
      let localPmr3pLen = pmr3pLen;
      let localPmr5pLen = pmr5pLen;
      let localPmrSetting = pmrSetting;

      if (cstb._pmr_config_override && cstb._pmr_config) {
        localPmr3pLen = cstb._pmr_config._pmr_3p_len;
        localPmr5pLen = cstb._pmr_config._pmr_5p_len;
        localPmrSetting = cstb._pmr_config._pmr_setting;
      } else {
        localPmr3pLen = pmr3pLen;
        localPmr5pLen = pmr5pLen;
        localPmrSetting = pmrSetting;
      }

      if (cstb.primer_type == "default") {
        // console.log("primer_type == default");
        pmrCnt = OlabPrimer.handleDefaultPrimer(
          pmrObj,
          ii,
          cstID,
          localPmr3pLen,
          localPmr5pLen,
          localPmrSetting,
          pmrCnt
        );
      } else if (cstb.primer_type == "lt60") {
        // console.log("primer_type == lt60");
        pmrCnt = OlabPrimer.handleLT60Primer(
          pmrObj,
          ii,
          cstID,
          localPmr3pLen,
          localPmr5pLen,
          localPmrSetting,
          pmrCnt
        );
      } else if (cstb.primer_type == "lt20") {
        // console.log("primer_type == lt20");
        pmrCnt = OlabPrimer.handleLT20Primer(
          pmrObj,
          ii,
          cstID,
          localPmr3pLen,
          localPmr5pLen,
          localPmrSetting,
          pmrCnt
        );
      } else if (cstb.primer_type == "none") {
        // console.log("primer_type == none");
        OlabPrimer.handleNonePrimer(pmrObj, ii);
      }
    }
  }

  // 4 possible types
  // default, lt60, lt20, none
  static classifyPrimers(pmrObj, errorObj) {
    // console.log("classifyPrimers ...");
    const cstbs = pmrObj.cstbs;
    // Reset primer type
    for (let ii = 0; ii < cstbs.length; ii++) {
      const cstb = cstbs[ii];
      // console.log("* cstb = ", cstb, ", cstb.primer_type =", cstb.primer_type);
      if (cstb.configured == false) {
        break;
      }
      cstb.primer_type = null;
      // console.log("** cstb.primer_type =", cstb.primer_type);
    }

    for (let ii = 0; ii < cstbs.length; ii++) {
      const cstb = cstbs[ii];
      // console.log("*** cstb.primer_type =", cstb.primer_type);
      if (cstb.configured == false) {
        break;
      }
      if (cstb.seq.length > 60) {
        // Use handleDefaultPrimer
        if (!cstb.primer_type) {
          cstb.primer_type = "default";
        } else {
          // OK. It is possible if previous box is a short seq.
          // console.log("cstb.primer_type has been assigned: ", cstb.primer_type);
        }
      } else if (cstb.seq.length <= 20) {
        // handle LT20
        if (!cstb.primer_type) {
          cstb.primer_type = "none";
        } else {
          // console.log(
          //   "Problem! Can't handle consecutive non default boxes.(pt 1)"
          // );
          errorObj.message = `Design cannot handle consecutive short sequence boxes (${ii} and ${ii +
            1})`;
          return;
        }
        if (ii > 0) {
          if (
            cstbs[ii - 1].primer_type != "default" &&
            cstbs[ii - 1].seq.length <= 60
          ) {
            // console.log(
            //   "Problem! Can't handle consecutive non default boxes.(pt 2)"
            // );
            errorObj.message = `Design cannot handle consecutive short sequence boxes (${ii} and ${ii +
              1})`;
            return;
          } else {
            cstbs[ii - 1].primer_type = "lt20";
          }
        }
        if (ii + 1 < cstbs.length) {
          cstbs[ii + 1].primer_type = "lt20";
        }
      } else if (cstb.seq.length <= 60) {
        // handleLT60
        if (!cstb.primer_type) {
          cstb.primer_type = "none";
        } else {
          // console.log(
          //   "Problem! Can't handle consecutive non default boxes.(pt 3)"
          // );
          errorObj.message = `Design cannot handle consecutive short sequence boxes (${ii} and ${ii +
            1})`;
          return;
        }
        if (ii > 0) {
          if (
            cstbs[ii - 1].primer_type != "default" &&
            cstbs[ii - 1].seq.length <= 60
          ) {
            // console.log(
            //   "Problem! Can't handle consecutive non default boxes.(pt 4)"
            // );
            errorObj.message = `Design cannot handle consecutive short sequence boxes (${ii} and ${ii +
              1})`;
            return;
          } else {
            cstbs[ii - 1].primer_type = "lt60";
          }
        }
        if (ii + 1 < cstbs.length) {
          cstbs[ii + 1].primer_type = "lt60";
        }
      } else {
        // Unknown case
        errorObj.message = "Error - classifyPrimers: Unknown case";
        // console.log(errorObj.message);
      }
    }
  }
}

export { OlabPrimer };

<template>
  <div>
    <div class="card">
      <div class="card-header" id="heading-decision">
        <h2 class="mb-0">
          <button
            class="btn btn-info collapsed"
            v-bind:disabled="
              resetMessage || disabledState.decision === 'disabled'
            "
            type="button"
            data-toggle="collapse"
            data-target="#collapse-decision"
            aria-expanded="false"
            aria-controls="collapse-decision"
            v-on:click="doDecision()"
          >
            <span
              v-if="dsgn && processing === 'decision'"
              class="spinner-border spinner-border-sm text-light"
            ></span>
            Decision
          </button>
        </h2>
      </div>
      <div
        id="collapse-decision"
        class="collapse"
        aria-labelledby="heading-decision"
        data-parent="#accordion-design"
      >
        <div v-if="dsgn && dsgn.constructs" class="card-body">
          <div class="decision-design">
            <div class="card mb-5">
              <div class="card-header text-dark h3">Design</div>
              <div class="card-body mt-2">
                <div class="h5 pt-2">OLAB ID: {{ dsgn.olab_id }}</div>
                <form class="pt-3 container">
                  <div class="row justify-content-center">
                    <div v-if="dsgnPlasmid && dsgnPlasmid.diag_pmr_exists">
                      <div class="alert alert-info" role="alert">
                        Backbone Plasmid supports diagnostic primers generation.
                        generation. Diagnostic sequencing and cPCR (End Fix)
                        will be generated within the following temperature
                        range.
                        <div class="pt-2 form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            v-model="skipDiagPmr"
                            id="skipDiagPmrCheckbox"
                          />
                          <label
                            class="form-check-label"
                            for="skipDiagPmrCheckbox"
                          >
                            Skip Diagnostic Primer
                          </label>
                        </div>
                      </div>
                      <!-- {{ backbone }} -->
                      <!-- UI for Input Diagnostic configs -->
                      <div v-if="dsgn && !skipDiagPmr" class="row my-2">
                        <div class="container">
                          <div class="row justify-content-center">
                            <div class="col-12 mb-3">
                              <div class="card">
                                <div class="card-body">
                                  <div class="row justify-content-center">
                                    <h5>Tm Range (&deg;C)</h5>
                                  </div>
                                  <div
                                    class="form-group row justify-content-center"
                                  >
                                    <label class="col-form-label">Low</label>
                                    <div class="px-2">
                                      <input
                                        class="form-control"
                                        type="number"
                                        min="50"
                                        max="60"
                                        step="1"
                                        :class="tmLowValidState"
                                        v-model="tmLow"
                                      />
                                      <div class="invalid-feedback">
                                        {{ invalidTmMessage }}
                                      </div>
                                    </div>
                                    <label class="col-form-label pl-5"
                                      >High</label
                                    >
                                    <div class="px-2">
                                      <input
                                        class="form-control"
                                        type="number"
                                        min="50"
                                        max="60"
                                        step="1"
                                        :class="tmHighValidState"
                                        v-model="tmHigh"
                                      />
                                      <div class="invalid-feedback">
                                        {{ invalidTmMessage }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-else-if="dsgnPlasmid && !dsgnPlasmid.diag_pmr_exists"
                    >
                      <div class="alert alert-warning" role="alert">
                        Backbone Plasmid has no diagnostic primer tags. Please
                        use Diagnostic module to generate diagnostic primers
                        separately after saving this design.
                      </div>
                    </div>
                  </div>
                  <fieldset class="form-group">
                    <div class="form-group row">
                      <label class="pt-1 form-control-label">
                        Name:
                      </label>
                      <input
                        class="form-control"
                        v-bind:class="dsgn.getValidState(dsgn.name)"
                        type="text"
                        v-model="dsgn.name"
                        v-bind:disabled="showConfirmSave == true"
                      />
                    </div>
                    <div class="form-group row">
                      <label class="pt-1 form-control-label">
                        Description:
                      </label>
                      <textarea
                        class="form-control"
                        v-model="dsgn.desc"
                        v-bind:disabled="showConfirmSave == true"
                      ></textarea>
                    </div>
                  </fieldset>
                </form>
                <div class="pt-1">Project ID: {{ dsgn.project_id }}</div>
                <div class="pt-1">Cart ID: {{ dsgn.cart_id }}</div>
                <div class="justify-content-center">
                  <legend class="pt-3">Generated</legend>
                  <div class="py-1">
                    <span class="mr-1">{{ printNumCsts }}</span>
                  </div>
                  <div class="py-1">
                    <span class="mr-1"
                      >{{ dsgn && dsgn.comb_pmrs.length }} primers</span
                    >
                  </div>
                  <div class="py-1 small">
                    of which
                  </div>
                  <div class="py-1">
                    <span class="mr-1"
                      >{{ dsgn && dsgn.uniq_pmrs.length }} unique primers</span
                    >
                  </div>
                  <div v-if="dsgnDiagPmrStats" class="pt-3">
                    <div class="h5">
                      Diagnostic
                    </div>
                    <div>{{ dsgnDiagPmrStats.cpcr_primers }} cPCR primers</div>
                    <div>
                      {{ dsgnDiagPmrStats.seq_primers }} sequencing primers
                    </div>
                  </div>
                  <div
                    v-if="dsgnPlasmid"
                    class="mt-2 alert alert-primary"
                    role="alert"
                  >
                    <div class="h5">
                      Plasmid
                    </div>
                    <section>
                      <p>
                        This design generates new plasmid.
                      </p>
                      <p>{{ printNumPlds }}</p>
                      <span>
                        Do you like to save the new plasmid(s) into the Plasmid
                        Database?
                      </span>
                      <span class="mx-2">
                        <input
                          type="checkbox"
                          id="inp-yes-check"
                          v-model="saveDsgnPlasmid"
                        />
                        <label class="ml-1" for="inp-yes-check">Yes</label>
                      </span>
                      <div>
                        <OlabPlasmidCircularMap
                          :selectedType="'plasmid'"
                          :item="previewFirstPlasmid"
                          :numCsts="numCsts"
                        />
                      </div>
                    </section>
                  </div>
                  <div v-if="showConfirmSave" id="confirm-yes-no">
                    <div class="alert alert-danger" role="alert">
                      <h4 class="text-dark">Confirm</h4>
                      <div class="card">
                        <div class="card-body">
                          Do you really want to save {{ dsgn.olab_id }}?
                          <br />
                          {{ dsgn.name }} <br />
                          All previously saved constructs and primers of this
                          design will be overwritten.
                          <br />
                          <br />
                          <div class="text-center">
                            <button
                              type="button"
                              class="btn btn-danger mx-2"
                              v-on:click="toggleSaveDesign(true)"
                            >
                              Yes
                            </button>
                            <button
                              type="button"
                              class="btn btn-secondary"
                              v-on:click="toggleSaveDesign(false)"
                            >
                              No
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="
                      dsgnPlasmid &&
                        dsgn &&
                        dsgn.constructs.length > 1 &&
                        saveDsgnPlasmid
                    "
                    class="mt-2 alert alert-danger"
                    role="alert"
                  >
                    Note: Saving large number of plasmids can take significant
                    time and memory. Please consider this action carefully.
                  </div>
                </div>
              </div>
              <div class="mt-3 form-group">
                <div class="mb-5 row justify-content-center">
                  <div class="col-1"></div>
                  <div class="col-10">
                    <button
                      class="mx-2 my-2 btn btn-info"
                      v-bind:disabled="disabledState.save === 'disabled'"
                      type="button"
                      v-on:click="preSaveDesign()"
                    >
                      <span
                        v-if="dsgn && processing === 'save'"
                        class="spinner-border spinner-border-sm text-light"
                      ></span>
                      Save
                    </button>
                    <button
                      type="button"
                      class="btn btn-info"
                      v-bind:disabled="disabledState.download === 'disabled'"
                      data-toggle="modal"
                      data-target="#downloadItem"
                      data-backdrop="static"
                      data-keyboard="false"
                    >
                      Download
                    </button>

                    <!-- Modal: DownloadItem -->
                    <div
                      v-if="dsgn != null"
                      class="modal fade"
                      id="downloadItem"
                    >
                      <OlabDownloadItem
                        :user="user"
                        :dbInfo="dbInfo"
                        :selectedType="'design'"
                        :item="dsgn"
                        :itemProjDetail="
                          `${selectedProject.olab_id} - ${selectedProject.name}`
                        "
                        :cstPrimers="dsgnDiagPmrStats !== null"
                        :statusObj="statusObj"
                        :errorObj="errorObj"
                      />
                    </div>
                    <!-- .modal fade #downloadItem -->
                  </div>
                  <div class="col-1"></div>
                </div>
              </div>
              <!-- .form-group -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import OlabDownloadItem from "@/components/OlabDownloadItem";
// import { OlabAxios } from "@/olab/olabaxios.js";
// import { OlabDesign } from "@/olab/olabdesign.js";
import OlabPlasmidCircularMap from "@/components/OlabPlasmidCircularMap";
import { OlabUtils } from "@/olab/olabutils.js";
import $ from "jquery";

export default {
  name: "designdecision",
  data() {
    return {
      dsgnPlasmid: null,
      showConfirmSave: false,
      // Backbone Temperature Setting UI
      skipDiagPmr: false,
      tmLow: 54,
      tmHigh: 56,
      tmLowValid: true,
      tmHighValid: true,
      invalidTmMessage: "Tm has to be between 50 and 60 and Low < High",
      dsgnDiagPmrStats: null,
      lastDsgnDesc: "",
      dsgnPlasmidStats: null,
      saveDsgnPlasmid: false,
      lastSaveDsgnPlasmid: false
    };
  },
  components: {
    // FontAwesomeIcon,
    OlabPlasmidCircularMap,
    OlabDownloadItem
  },
  props: [
    "user",
    "dbInfo",
    "selectedType",
    "selectedProject",
    "selectedCart",
    "dsgn",
    "dsgnBoxes",
    "primerObjArr",
    "backbone",
    "previewFirstPlasmid",
    "processing",
    "resetMessage",
    "disabledState",
    "statusObj",
    "errorObj"
  ],
  computed: {
    printNumCsts() {
      return this.numCsts === 1 ? "1 construct" : `${this.numCsts} constructs`;
    },
    printNumPlds() {
      return this.numCsts === 1 ? "1 plasmid" : `${this.numCsts} plasmids`;
    },
    numCsts() {
      let nCsts = 1;
      if (this.dsgn && this.dsgn.constructs.length > 1) {
        nCsts = this.dsgn.constructs.length;
      }
      return nCsts;
    },
    tmLowValidState() {
      if (this.dsgn == null) {
        return "";
      }
      return this.tmLowValid ? "is-valid" : "is-invalid";
    },
    tmHighValidState() {
      if (this.dsgn == null) {
        return "";
      }
      return this.tmHighValid ? "is-valid" : "is-invalid";
    }
  },
  methods: {
    setProcessingStatus(status) {
      this.$emit("doProcessingStatus", status);
    },
    doDecision: async function() {
      console.log("doDecision");
      const isVisible = $("#collapse-decision").is(":visible");
      console.log("doDecision: isVisible = ", isVisible);
      if (isVisible) {
        // No computation is needed since it is already visible
        return;
      }
      // Reset cached data
      this.dsgnDiagPmrStats = null;
      this.dsgnPlasmid = null;
      this.lastDsgnDesc = "";

      this.setProcessingStatus("decision");
      if (this.backbone && this.backbone.olab_id) {
        const searchBody = {
          searchString: this.backbone.olab_id,
          searchField: "olab_id",
          includeDeleted: false,
          auxID: null,
          page: 1,
          limit: 1
        };
        const plasmidArr = await OlabUtils.getPlasmids(searchBody);
        if (plasmidArr && plasmidArr[0]) {
          this.dsgnPlasmid = plasmidArr[0];
          // console.log("plasmid: olab_id =", plasmidArr[0].olab_id);
          // console.log("diag_pmr_exists =", plasmidArr[0].diag_pmr_exists);
          // console.log("meta_map =", plasmidArr[0].meta_map);
        } else {
          // console.log("plasmid not found!");
          this.dsgnPlasmid = null;
        }
      }
      // TODO: REMOVE THIS BLOCK - We do not need to compute at the frontend
      // once all constructs and primers compute alg. are moved to backend
      setTimeout(() => {
        console.log("setTimeout to 10ms");
        this.dsgn.computeDesignPrimers(this.primerObjArr);
        this.setProcessingStatus("");
      }, 10);
    },
    async doSaveDesign() {
      console.log("doSaveDesign: dsgnBoxes =", this.dsgnBoxes);
      this.setProcessingStatus("save");
      // Save design configuration boxes
      this.dsgn.cstbs = this.selectedCart.cstbs;
      // Mark design has been configured
      this.dsgn.configured = true;
      // Save design to design database
      await this.dsgn.saveDesign(this.dsgnBoxes, this.statusObj, this.errorObj);

      // Generate diagnostic primers (if requested) and if save design is successful
      if (
        this.errorObj &&
        !this.errorObj.message &&
        this.dsgnPlasmid &&
        this.dsgnPlasmid.diag_pmr_exists &&
        !this.skipDiagPmr
      ) {
        const res = await this.dsgn.generateDiagPmrs(
          this.tmLow,
          this.tmHigh,
          this.statusObj,
          this.errorObj
        );
        // console.log("Design: res =", res);
        this.dsgnDiagPmrStats = res === null ? null : res.stats;
      }

      if (
        this.errorObj &&
        !this.errorObj.message &&
        this.dsgnPlasmid &&
        this.saveDsgnPlasmid
      ) {
        // console.log("TODO: Implement saveDsgnPlasmid =", this.saveDsgnPlasmid);
        const res = await this.dsgn.saveDsgnPlasmids(
          this.statusObj,
          this.errorObj
        );
        // console.log("res =", res);
        this.dsgnPlasmidStats = res === null ? null : res;
      }
      // console.log("dsgnPlasmidStats =", this.dsgnPlasmidStats);
      // FOR DEBUGGING ONLY
      // if (this.dsgnPlasmidStats) {
      //   this.dsgnPlasmidStats.data.data.plasmid_array.forEach(plmd => {
      //     console.log("New olab_id =", plmd.new_olab_id);
      //     console.log("genes count =", plmd.genes_cnt);
      //   });
      // }

      this.disabledState.save = "disabled";
      this.disabledState.download = "";
      this.lastDsgnDesc = this.dsgn.desc;
      this.lastSaveDsgnPlasmid = this.saveDsgnPlasmid;
      this.setProcessingStatus("");
    },
    preSaveDesign: function() {
      this.showConfirmSave = true;
    },
    toggleSaveDesign: function(res) {
      if (res) {
        this.doSaveDesign();
      }
      this.showConfirmSave = false;
    }
  },
  watch: {
    "dsgn.name": function() {
      // console.log("dsgn.name = " + this.dsgn.name);
      // Design's name can't be an empty String
      this.disabledState.save = this.dsgn.name === "" ? "disabled" : "";
      // console.log("this.disabledState.save =", this.disabledState.save);
    },
    "dsgn.desc": function() {
      // console.log("dsgn.desc = " + this.dsgn.desc);
      this.disabledState.save =
        this.dsgn.desc === this.lastDsgnDesc ? this.disabledState.save : "";
      // console.log("this.disabledState.save =", this.disabledState.save);
    },
    saveDsgnPlasmid() {
      // console.log("watch: saveDsgnPlasmid =", this.saveDsgnPlasmid);
      this.disabledState.save =
        this.saveDsgnPlasmid === this.lastSaveDsgnPlasmid
          ? this.disabledState.save
          : "";
    },
    tmLow: function() {
      this.tmLow = parseInt(this.tmLow);
      // console.log("tmLow = ", this.tmLow);
      this.tmLowValid = this.tmLow > 49;
      this.tmLowValid = this.tmLowValid
        ? this.tmLow < this.tmHigh
        : this.tmLowValid;
    },
    tmHigh: function() {
      this.tmHigh = parseInt(this.tmHigh);
      // console.log("tmHigh =", this.tmHigh);
      this.tmHighValid = this.tmHigh <= 60;
      this.tmHighValid = this.tmHighValid
        ? this.tmLow < this.tmHigh
        : this.tmHighValid;
    }
  }
};
</script>

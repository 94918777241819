<template>
  <div>
    <div class="list-group list-group-flush">
      <div
        class="list-group-item d-flex align-items-center"
        v-for="cst in constructs"
        :key="cst.olab_id"
      >
        <div class="container">
          <div class="row">
            <div class="col-6 col-sm-4 align-self-center">
              <OlabDnaSVGGen name="Construct" textPosX="230" textPosY="395" />
            </div>

            <div class="col-12 col-sm-8 mt-2 align-self-center text-wrap">
              <div>
                <span>
                  <a
                    class="h5 text-info"
                    data-toggle="modal"
                    href="#constructInfo"
                    v-on:click="registerCst(cst)"
                    data-backdrop="static"
                    data-keyboard="false"
                  >
                    {{ cst.olab_id }}</a
                  >
                </span>
                <span v-if="cst.deleted" class="text-light bg-danger ml-2"
                  >DELETED</span
                >
              </div>
              <div class="py-4">
                <div>
                  <span v-for="(cstb, index) in cst.cstbs" :key="index">
                    <span v-bind:class="cstb.use_seq_as_color">
                      {{ cstb.display_name }}
                    </span>
                  </span>
                </div>
              </div>
              <!-- Buttons -->
              <div v-if="selectedType === 'construct'">
                <div class="dropdown">
                  <button
                    class="btn btn-info dropdown-toggle"
                    type="button"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span
                      v-if="
                        cst &&
                          selectedCst &&
                          cst.olab_id === selectedCst.olab_id &&
                          statusObj.progress
                      "
                      class="spinner-border spinner-border-sm text-light"
                    ></span>
                    Download
                  </button>
                  <div class="dropdown-menu">
                    <button
                      class="dropdown-item"
                      type="button"
                      v-on:click="downloadFile(cst, 'fasta')"
                    >
                      Fasta
                    </button>
                    <button
                      class="dropdown-item"
                      type="button"
                      v-on:click="downloadFile(cst, 'genbank')"
                    >
                      Genbank
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!-- Modal: constructInfo -->
            <div
              v-if="selectedCst != null"
              class="modal fade"
              id="constructInfo"
            >
              <div class="modal-dialog modal-lg">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">
                      OLAB ID:
                      <span class="h5 text-dark">{{
                        selectedCst.olab_id
                      }}</span>
                    </h5>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <!-- .modal-header -->
                  <div class="modal-body">
                    <!-- construct -->
                    <OlabConstruct :cstObj="selectedCst" />
                  </div>
                  <!-- .modal-body -->
                  <div class="modal-footer">
                    <div class="dropdown">
                      <button
                        class="btn btn-info dropdown-toggle"
                        type="button"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span
                          v-if="statusObj.progress"
                          class="spinner-border spinner-border-sm text-light"
                        ></span>
                        Download
                      </button>
                      <div class="dropdown-menu">
                        <button
                          class="dropdown-item"
                          type="button"
                          v-on:click="downloadFile(cst, 'fasta')"
                        >
                          Fasta
                        </button>
                        <button
                          class="dropdown-item"
                          type="button"
                          v-on:click="downloadFile(cst, 'genbank')"
                        >
                          Genbank
                        </button>
                      </div>
                    </div>
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                  <!-- .modal-footer -->
                </div>
                <!-- .modal-content -->
              </div>
              <!-- .modal-dialog .modal-lg-->
            </div>
            <!-- .modal fade #geneDetailInfo -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OlabConstruct from "@/components/OlabConstruct";
import OlabDnaSVGGen from "@/components/OlabDnaSVGGen";
import { OlabCst } from "../olab/olabcst.js";
import { OlabAxios } from "../olab/olabaxios.js";

export default {
  name: "databaseconstructlist",
  data: function() {
    return {
      selectedCst: null,
      cst: new OlabCst()
    };
  },
  props: [
    "user",
    "dsgn",
    "constructs",
    "statusObj",
    "errorObj",
    "selectedType"
  ],
  components: {
    // FontAwesomeIcon,
    OlabConstruct,
    OlabDnaSVGGen
  },
  methods: {
    registerCst: function(cst) {
      // console.log("registerCst: cst = ", cst);
      this.cst.set(cst);
      this.selectedCst = this.cst;
      // console.log("registerCst: selectedCst = ", this.selectedCst);
    },
    async downloadFile(cst, fileType) {
      // console.log("downloadFile: cst =", cst, ", fileType =", fileType);
      this.registerCst(cst);
      this.statusObj.progress = true;
      // this.statusObj.message = `Generating ${fileType} file`;
      // await new Promise(resolve => setTimeout(resolve, 5000));
      const cstFile = await OlabAxios.downloadCstFile(
        this.dsgn,
        this.cst,
        fileType,
        this.statusObj,
        this.errorObj
      );
      // download file to client machine
      // console.log("downloadFile: cstFile =", cstFile);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.style.visibility = "hidden";

      // https://developer.mozilla.org/en-US/docs/Glossary/MIME_type
      // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
      const blob = new Blob([cstFile], {
        type: "application/octet-stream"
      });
      const url = window.URL.createObjectURL(blob);
      const fileExt = fileType === "genbank" ? ".gb" : ".fasta";
      a.href = url;
      a.download = this.cst.olab_id + fileExt;
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }
  }
};
</script>

<template>
  <div>
    <div class="card-body">
      <div class="form-row justify-content-center">
        <div class="col col-md-8 mb-3">
          <treeselect
            class="vue-treeselect"
            v-model="selectedProjectID"
            :multiple="false"
            :disableFuzzyMatching="true"
            :disable-branch-nodes="true"
            :clear-on-select="true"
            :close-on-select="true"
            :async="true"
            :load-options="loadProjectOptions"
            placeholder="Select a project ..."
          />
        </div>
        <!-- <span>selected = {{ selectedProjectID }}</span> -->
      </div>
      <div v-if="selectedProject && selectedProject.project" class="mt-3">
        <div class="form-group mb-5">
          <div class="form-row">
            <div class="col-md-6 mt-3">
              <label class="h5 form-control-label">
                From Date
              </label>
              <input class="form-control" type="date" v-model="projFromDate" />
            </div>
            <div class="col-md-6 mt-3">
              <label class="h5 form-control-label">To Date</label>
              <input class="form-control" type="date" v-model="projToDate" />
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <span class="mr-1">OLAB ID:</span>
            <span class="ml-1">{{ selectedProject.project.olab_id }}</span>
          </div>
          <div class="form-group col-md-6">
            <span class="mr-1">Total Memory:</span>
            <span class="ml-1">{{
              formatMemorySize(selectedProject.total_memory)
            }}</span>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-12">
            <span class="mr-1">Name:</span>
            <span class="ml-1">{{ selectedProject.project.name }}</span>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <span class="mr-1">Start Date:</span>
            <span class="ml-1">{{ selectedProject.project.start_date }}</span>
          </div>
          <div class="form-group col-md-6">
            <span class="mr-1">End Date:</span>
            <span class="ml-1">{{ selectedProject.project.end_date }}</span>
          </div>
        </div>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Collection</th>
              <th scope="col">Memory</th>
              <th scope="col">Count</th>
              <th scope="col">Deleted</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(collection, index) in selectedProject.collections"
              :key="index"
            >
              <th scope="row">
                {{ collection.name }}
              </th>
              <td>{{ formatMemorySize(collection.memory) }}</td>
              <td>{{ collection.count }}</td>
              <td>{{ collection.deleted }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { OlabAxios } from "@/olab/olabaxios.js";
import { OlabUtils } from "@/olab/olabutils.js";

// Access treeselect component
import Treeselect from "@riophae/vue-treeselect";
import { ASYNC_SEARCH } from "@riophae/vue-treeselect";

// Access treeselect CSS styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  name: "OlabProjectTab",
  data() {
    return {
      selectedProject: null,
      selectedProjectID: null,
      projects: null,
      projFromDate: null,
      projToDate: null
    };
  },
  components: {
    Treeselect
  },
  props: ["user", "dbInfo", "statusObj", "errorObj"],
  methods: {
    formatMemorySize(size) {
      return OlabUtils.formatMemorySize(size);
    },
    async loadProjectOptions({ action, searchQuery, callback }) {
      if (action === ASYNC_SEARCH) {
        // console.log("ASYNC_SEARCH: searchQuery =", searchQuery);

        const ITEMS_PER_PAGE = 10;
        const searchBody = {
          searchString: searchQuery,
          searchField: "merge",
          includeDeleted: false,
          auxID: null,
          page: 1,
          limit: ITEMS_PER_PAGE
        };
        this.projects = await OlabUtils.getProjects(searchBody);
        // console.log("(1) projects =", this.projects);
        // Add new fields to projects to match selecttree data format
        this.projects.forEach(prj => {
          prj.id = prj.olab_id;
          prj.label = `${prj.olab_id} - ${prj.name}`;
        });
        callback(null, this.projects);
      }
    },
    async loadProjectStats() {
      // console.log("loadProjectStats");
      this.statusObj.message =
        "Processing memory usage may take a while. Please wait ...";
      this.statusObj.progress = true;
      this.selectedProject = await OlabAxios.getProjectStats(
        this.selectedProjectID,
        this.projFromDate,
        this.projToDate,
        this.statusObj,
        this.errorObj
      );
      this.statusObj.message = null;
      this.statusObj.progress = false;
    }
  },
  watch: {
    selectedProjectID() {
      // console.log("selectedProjectID =", this.selectedProjectID);
      if (this.selectedProjectID && this.projects) {
        for (let i = 0; i < this.projects.length; i++) {
          const tmpPd = this.projects[i];
          if (tmpPd.olab_id === this.selectedProjectID) {
            this.selectedProject = this.projects[i];
            break;
          }
        }
        if (this.selectedProject) {
          this.loadProjectStats();
        }
      }
    },
    projFromDate() {
      // console.log("projFromDate =", this.projFromDate);
      if (this.selectedProject) {
        this.loadProjectStats();
      }
    },
    projToDate() {
      // console.log("projToDate =", this.projToDate);
      if (this.selectedProject) {
        this.loadProjectStats();
      }
    }
  }
};
</script>

// This is color table class is controller for color assignment
class ColorTable {
  // Use selected dark colors from
  // https://www.rapidtables.com/web/color/RGB_Color.html
  static COLOR_TABLE = [
    "maroon",
    "teal",
    "mediumslateblue",
    "mediumvioletred",
    "olivedrab",
    "crimson",
    "red",
    "blue",
    "rosybrown",
    "green",
    "orangered",
    "purple",
    "brown",
    "sienna",
    "darkgreen",
    "peru",
    "dimgray"
  ];

  constructor(ctName) {
    // console.log("ColorTable.constructor() ...");
    this.name = "Color Table: " + ctName;
    this.colorIndex = 0;
  }

  getColor() {
    const clrIndex = this.colorIndex % ColorTable.COLOR_TABLE.length;
    this.colorIndex++;
    return ColorTable.COLOR_TABLE[clrIndex];
  }

  getNumColor(num) {
    const clrIndex = num % ColorTable.COLOR_TABLE.length;
    return ColorTable.COLOR_TABLE[clrIndex];
  }

  getItemColor(item) {
    let color;

    if (item) {
      let hp = Object.prototype.hasOwnProperty;
      const id = hp.call(item, "olab_id") ? item.olab_id : "";
      const num = parseInt(id.substr(id.length - 2));
      color = isNaN(num) ? this.getColor() : this.getNumColor(num);
    } else {
      color = this.getColor();
    }
    return color;
  }
}

export { ColorTable };

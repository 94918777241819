<template>
  <!-- <div v-if="selectedItem != null && (selectedCart || selectedPackage)" class="modal fade" id="addSelectItem"> -->
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          OLAB ID:
          <span class="text-dark">{{ selectedItem.olab_id }}</span>
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          v-on:click="clearNewItem()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <!-- .modal-header -->
      <div class="modal-body">
        <div v-if="selectedType === 'library_sequence'">
          <div class="text-wrap">
            <span class="mr-1">Name:</span>
            <span class="h5 text-dark">{{ selectedItem.name }}</span>
          </div>
          <div class="py-1 text-wrap">
            <span class="mr-1">Project:</span>
            <span class="h5 text-dark">{{ selectedItem.project_id }}</span>
          </div>
          <div class="py-1 text-wrap">
            <span class="mr-1">Sequence Origin:</span>
            <span class="h5 text-dark">{{ selectedItem.seq_origin }}</span>
          </div>
          <div class="py-1 text-wrap">
            <span class="mr-1">Source:</span>
            <span class="h5 text-dark">{{ selectedItem.source }}</span>
          </div>
          <div class="py-1 text-wrap">
            <span class="mr-1">Creator:</span>
            <span class="h5 text-dark">{{ selectedItem.creator }}</span>
          </div>
          <div class="py-1 text-wrap">
            <span class="mr-1">Library ID:</span>
            <span class="h5 text-dark">{{ selectedItem.library_id }}</span>
          </div>
          <div class="py-2 form-check">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="createNewSeq"
            />
            <label class="form-check-label">
              Create New Sequence
            </label>
          </div>
          <div v-if="createNewSeq && newItem" class="py-1">
            <div class="card">
              <div class="card-header text-dark">Create New Sequence</div>
              <div class="card-body">
                <div class="py-1 text-wrap">
                  <span class="mr-1">Project:</span>
                  <span class="h5 text-dark">{{ newItem.project_id }}</span>
                </div>
                <div class="form-group mt-1">
                  <label class="form-control-label">
                    <span>Name:</span>
                  </label>
                  <input
                    class="form-control"
                    v-bind:class="newItem.getValidState(newItem.name)"
                    type="text"
                    v-model="newItem.name"
                  />
                </div>
                <div class="row justify-content-center mx-2">
                  <div class="col-10 col-md-5">
                    <div class="form-group">
                      <div class="mr-1">Start</div>
                      <input
                        class="form-control"
                        type="number"
                        min="0"
                        step="1"
                        v-bind:class="newStartValidState"
                        v-model="newStart"
                      />
                      <div class="invalid-feedback">
                        {{ newStartInvalidFeedback }}
                      </div>
                    </div>
                    <!-- .form-group -->
                  </div>
                  <div class="col-10 col-md-5">
                    <div class="form-group">
                      <div class="mr-1">Range</div>
                      <input
                        class="form-control"
                        type="number"
                        min="1"
                        step="1"
                        v-bind:class="newRangeValidState"
                        v-model="newRange"
                      />
                      <div class="invalid-feedback">
                        {{ newRangeInvalidFeedback }}
                      </div>
                    </div>
                    <!-- .form-group -->
                  </div>
                </div>
                <div class="py-1 bg-light small text-monospace text-dark">
                  <span>{{ beforeSeq }}</span>
                  <span class="text-light bg-warning">{{ newItem.seq }}</span>
                  <span>{{ afterSeq }}</span>
                </div>
                <div class="py-1 text-dark">
                  {{ newItem.seq.length }}
                </div>
                <div class="form-group">
                  <label class="form-control-label">Description:</label>
                  <textarea
                    class="form-control"
                    v-model="newItem.desc"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div v-if="selectedItem.seq" class="py-1">
              <div class="card">
                <div class="card-header text-dark">Sequence</div>
                <div class="card-body">
                  <div class="py-1 small text-monospace text-dark">
                    {{ selectedItem.seq }}
                  </div>
                  <div class="py-1 text-dark">
                    {{ selectedItem.seq.length }}
                  </div>
                </div>
                <!-- .card-body -->
              </div>
              <!-- .card -->
            </div>
            <div class="py-1">
              <div class="card">
                <div class="card-header text-dark">Description</div>
                <div class="card-body">
                  <div class="py-1 small text-dark">
                    {{ selectedItem.desc }}
                  </div>
                </div>
                <!-- .card-body -->
              </div>
              <!-- .card -->
            </div>
          </div>
        </div>
        <div v-else>
          <div class="text-wrap">
            <span class="mr-1">Name:</span>
            <span class="h5 text-dark">{{ selectedItem.name }}</span>
          </div>
          <div class="py-1 text-wrap">
            <span class="mr-1">Project:</span>
            <span class="h5 text-dark">{{ selectedItem.project_id }}</span>
          </div>
          <div class="py-1 text-wrap">
            <span class="mr-1">Creator:</span>
            <span class="h5 text-dark">{{ selectedItem.creator }}</span>
          </div>
          <div v-if="selectedType === 'package'" class="py-1 text-wrap">
            <span class="mr-1">Package Type:</span>
            <span class="h5 text-dark"
              >{{ selectedItem.package_type }} -
              {{ selectedItem.package_seq_type }}</span
            >
          </div>
          <div class="py-1">
            <div class="card">
              <div class="card-header text-dark">Description</div>
              <div class="card-body">
                <div class="py-1 small text-dark">
                  {{ selectedItem.desc }}
                </div>
              </div>
              <!-- .card-body -->
            </div>
            <!-- .card -->
          </div>
        </div>
        <div v-if="selectedType === 'package'" class="py-1">
          <OlabCompTable :selectedType="selectedType" :item="selectedItem" />
        </div>
      </div>
      <!-- .modal-body -->
      <div class="modal-footer">
        <button
          type="button"
          v-bind:disabled="itemAddIsDisabled == true"
          class="btn btn-primary"
          data-dismiss="modal"
          v-on:click="callAddToComps()"
        >
          Add
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          data-dismiss="modal"
          v-on:click="clearNewItem()"
        >
          Back
        </button>
      </div>
      <!-- .modal-footer -->
    </div>
    <!-- .modal-content -->
  </div>
  <!-- </div> -->
</template>

<script>
import OlabCompTable from "@/components/OlabCompTable";
import { OlabAxios } from "../olab/olabaxios.js";
import { OlabUtils } from "../olab/olabutils.js";
import { OlabItem } from "../olab/olabitem.js";

export default {
  name: "olabaddselectitem",
  components: {
    OlabCompTable
  },
  data: function() {
    return {
      createNewSeq: false,
      newItem: null,
      newStart: "0",
      numStart: 0,
      newStartValid: true,
      newStartDisabledState: "disabled",
      newStartInvalidFeedback: "Start has to be a positive integer",
      newRange: "1",
      numRange: 1,
      newRangeValid: true,
      newRangeDisabledState: "disabled",
      newRangeInvalidFeedback: "Range has to be a positive integer (>0)",
      beforeSeq: "",
      afterSeq: ""
    };
  },
  props: [
    "selectedType",
    "selectedProject",
    "selectedCart",
    "selectedPackage",
    "selectedItem",
    "selectedItems",
    "statusObj",
    "errorObj"
  ],
  computed: {
    itemAddIsDisabled: function() {
      if (this.createNewSeq) {
        if (
          this.newItem &&
          this.newItem.name &&
          this.newStartValid &&
          this.newRangeValid
        ) {
          return false;
        }
        return true;
      }
      return false;
    },
    newStartValidState: function() {
      return this.newStartValid ? "is-valid" : "is-invalid";
    },
    newRangeValidState: function() {
      return this.newRangeValid ? "is-valid" : "is-invalid";
    }
  },
  methods: {
    callAddToComps: async function() {
      if (this.createNewSeq) {
        // Set progress message and status indicator
        this.statusObj.progress = true;
        let res = null;
        if (this.selectedCart) {
          // console.log("New Add Comp to Cart - newItem =", this.newItem);
          res = await OlabAxios.addCompToCart(
            this.newItem,
            this.selectedType,
            this.selectedCart,
            this.statusObj,
            this.errorObj
          );
        } else if (this.selectedPackage) {
          // console.log("New Add Comp to Package - newItem =", this.newItem);
          res = await OlabAxios.addCompToPackage(
            this.newItem,
            this.selectedType,
            this.selectedPackage,
            this.statusObj,
            this.errorObj
          );
        }

        if (res && res.status === "success") {
          // console.log(res.message);
        } else {
          console.log(
            "Unexpect status =",
            res.status,
            ", message =",
            res.message
          );
        }
        this.clearNewItem();
      } else {
        // console.log("Add selectedItem.olab_id =", this.selectedItem.olab_id);
        this.$emit("handleAddToComps", {
          item: this.selectedItem,
          type: this.selectedType
        });
      }
    },
    clearNewItem: function() {
      this.createNewSeq = false;
      this.newItem = null;
    },
    computeNewSeq: function(start, range) {
      // NOP is this.newItem is null
      if (!this.newItem) return;

      let end = start + range;
      const refSeq = this.selectedItem.seq;
      if (refSeq.length < end) {
        end = refSeq.length;
      }
      this.beforeSeq = refSeq.substring(0, start);
      this.afterSeq = refSeq.substring(end, refSeq.length);
      this.newItem.seq = refSeq.substring(start, end);
    },
    setStart: function(newStart) {
      this.newStartValid = newStart.toString().match(/^(0|[1-9]\d*)$/) != null;
      if (this.newStartValid) {
        const seqLen = this.selectedItem.seq.length;
        const tmpStart = parseInt(newStart);
        if (tmpStart >= seqLen) {
          this.newStartValid = false;
          this.newStartInvalidFeedback = `Start has to be between [0,${seqLen})`;
        } else {
          this.numStart = tmpStart;
          if (this.numStart + this.numRange > seqLen) {
            // Adjust range to fit
            const tmpRange = seqLen - this.numStart;
            this.newRange = `${tmpRange}`;
          }
        }
      } else {
        this.newStartInvalidFeedback = "Start has to be a positive integer";
      }
    },
    setRange: function(newRange) {
      this.newRangeValid = newRange.toString().match(/^[1-9]\d*$/) != null;
      if (this.newRangeValid) {
        const seqLen = this.selectedItem.seq.length;
        const tmpRange = parseInt(newRange);
        if (seqLen < this.numStart + tmpRange) {
          this.newRangeValid = false;
          this.newRangeInvalidFeedback = `Range can not be greater than ${seqLen} - Start`;
        } else {
          this.numRange = tmpRange;
        }
      } else {
        this.newRangeInvalidFeedback =
          "Range has to be a positive integer (>0)";
      }
    }
  },
  watch: {
    createNewSeq: function() {
      OlabUtils.infoLog("createNewSeq = " + this.createNewSeq);
      if (this.createNewSeq) {
        this.newItem = new OlabItem(this.selectedItem);
        this.newItem.olab_id = "";
        this.newItem.name = "";
        this.newItem.project_id = this.selectedProject
          ? this.selectedProject.olab_id
          : this.newItem.project_id;
        this.newStart = 0;
        this.newRange = this.newItem.seq.length;
      } else {
        this.newItem = null;
        this.newStart = 0;
        this.newRange = 1;
      }
      this.setStart(this.newStart);
      this.setRange(this.newRange);
    },
    newStart: function() {
      this.setStart(this.newStart);
      this.computeNewSeq(this.numStart, this.numRange);
    },
    newRange: function() {
      this.setRange(this.newRange);
      this.computeNewSeq(this.numStart, this.numRange);
    }
  }
};
</script>

<template>
  <div class="mt-3">
    <div
      v-if="errorObj.message"
      class="col-12 alert alert-danger text-center px-3"
    >
      {{ errorObj.message }}
      <div class="text-center mt-2">
        <button type="button" class="btn btn-danger" v-on:click="closeError()">
          OK
        </button>
      </div>
    </div>
    <div class="text-secondary text-center mt-3">
      <img alt="OLAB Bioscience logo" src="../assets/OLAB.svg" />
      <div v-if="user" class="text-center">
        <div v-if="olabServerInfo && dbInfo">
          <h6 class="font-weight-bold text-brand-color my-2">
            {{ dbInfo.owner }}
          </h6>
          <h5 class="mb-2">
            <span class="text-success">{{ dbInfo.name }} Server</span>
            <span class="text-primary mx-2"
              >version: {{ olabServerInfo.version }}</span
            >
          </h5>
          <h5 class="mb-2">
            <span class="text-success">DB Schema</span>
            <span class="text-primary mx-2">version: {{ dbInfo.version }}</span>
          </h5>
        </div>
        <div v-else class="alert alert-danger" role="alert">
          <h5 class="mb-2">
            <span>OLAB Server: Connection refused</span>
          </h5>
        </div>
        <div v-if="bioServerInfo" class="alert alert-warning" role="alert">
          <h5 class="mb-2">
            <span>Bio Server</span>
            <span class="mx-2">version: {{ bioServerInfo.version }}</span>
          </h5>
        </div>
        <div v-else class="alert alert-danger" role="alert">
          <h5 class="mb-2">
            <span>Bio Server: Connection refused</span>
          </h5>
        </div>
        <div class="mb-2 h4">
          <span>
            Welcome:
          </span>
          <span class="font-weight-bold text-info">{{ user.name }}</span>
        </div>
      </div>
    </div>
    <div v-if="olabServerInfo" class="container text-center">
      <div class="row justify-content-center">
        <div class="col-10 col-md-10 col-lg-8 col-xl-7">
          <p class="lead text-danger mt-2" v-if="!user">
            Members Only Site
          </p>

          <!-- <router-link
            class="btn btn-outline-primary mr-2"
            to="/register"
            v-if="!user"
            >Register</router-link
          > -->
          <router-link
            class="btn btn-outline-primary mr-2"
            to="/login"
            v-if="!user"
            >Login</router-link
          >
          <!-- <router-link
            class="btn btn-outline-primary mb-2 mr-2"
            to="/database"
            v-if="user"
            >Database</router-link
          >
          <router-link
            class="btn btn-outline-primary mb-2 mr-2"
            to="/compdb"
            v-if="user"
            >Component</router-link
          >
          <router-link
            class="btn btn-outline-primary mb-2 mr-2"
            to="/design"
            v-if="user"
            >Design</router-link
          >
          <router-link
            class="btn btn-outline-primary mb-2 mr-2"
            to="/snpswap"
            v-if="user"
            >SNPSwap</router-link
          >
          <router-link
            class="btn btn-outline-primary mb-2 mr-2"
            to="/diagnostic"
            v-if="user"
            >Diagnostic</router-link
          > -->
        </div>
      </div>
    </div>
    <div
      v-if="
        dbInfo &&
          olabServerInfo &&
          olabServerInfo.show_progress_status === 'false'
      "
      class="alert alert-light"
      role="alert"
    >
      <h3 class="text-center mb-2">{{ dbInfo.name }} Private Cloud</h3>
    </div>
    <div v-else-if="user" class="mt-2 container text-center">
      <h1>Report</h1>
      <ul class="nav nav-tabs" id="reportTab" role="tablist">
        <li class="nav-item">
          <a
            class="nav-link active"
            id="olab-status-tab"
            data-toggle="tab"
            href="#olab-status"
            role="tab"
            aria-controls="olab-status"
            aria-selected="true"
            >Status
          </a>
        </li>
        <li v-if="user.role === 'admin'" class="nav-item">
          <a
            class="nav-link"
            id="olab-stats-tab"
            data-toggle="tab"
            href="#olab-stats"
            role="tab"
            aria-controls="olab-stats"
            aria-selected="false"
            >Stats
          </a>
        </li>
      </ul>
      <div class="tab-content" id="reportTabContent">
        <div
          class="tab-pane fade show active"
          id="olab-status"
          role="tabpanel"
          aria-labelledby="olab-status-tab"
        >
          <div class="form-group mt-3">
            <OlabStatus />
          </div>
        </div>
        <div
          v-if="user.role === 'admin'"
          class="tab-pane fade"
          id="olab-stats"
          role="tabpanel"
          aria-labelledby="olab-stats-tab"
        >
          <div class="form-group mt-3">
            <OlabStats
              :user="user"
              :dbInfo="dbInfo"
              :statusObj="statusObj"
              :errorObj="errorObj"
            />
          </div>
        </div>
      </div>
      <div class="my-2">
        <p>
          Please submit your questions, bugs, feature requests, and
          contributions at
          <a href="#" v-on:click="mailTo">contact@olabbio.com</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import OlabStatus from "@/components/status/OlabStatus";
import OlabStats from "@/components/stats/OlabStats";

export default {
  name: "home",
  props: [
    "user",
    "olabServerInfo",
    "bioServerInfo",
    "dbInfo",
    "statusObj",
    "errorObj"
  ],
  components: {
    // FontAwesomeIcon
    OlabStatus,
    OlabStats
  },
  methods: {
    mailTo: function() {
      // console.log("process.env =", process.env);
      window.location.href = `mailto:contact@olabbio.com?subject=Required Action: ${process.env.VUE_APP_PROJECT} Project`;
    },
    closeError: function() {
      // console.log("Close Error Alert ...");
      this.errorObj.message = null;
    }
  }
};
</script>

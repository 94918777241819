import { OlabUtils } from "./olabutils.js";

class OlabPackage {
  constructor(pkg) {
    if (pkg == null) {
      this.initPackage();
    } else {
      let hp = Object.prototype.hasOwnProperty;
      this.olab_id = hp.call(pkg, "olab_id") ? pkg.olab_id : null;
      this.olab_type = hp.call(pkg, "olab_type") ? pkg.olab_type : "package";
      this.deleted = hp.call(pkg, "deleted") ? pkg.deleted : false;
      this.name = hp.call(pkg, "name") ? pkg.name : null;
      this.creator = hp.call(pkg, "creator") ? pkg.creator : null;
      this.desc = hp.call(pkg, "desc") ? pkg.desc : null;
      this.package_seq_type = hp.call(pkg, "package_seq_type")
        ? pkg.package_seq_type
        : "library_sequence";
      this.package_type = hp.call(pkg, "package_type")
        ? pkg.package_type
        : null;
      this.library_id =
        this.package_type != null
          ? OlabPackage.pkgTypeToLibraryID(pkg.package_type)
          : null;
      // TODO: Make comps as an array of a package's comps (OlabComp) class
      this.comps = hp.call(pkg, "comps") ? pkg.comps : [];
    }
  }

  static pkgTypeToLibraryID(pkgType) {
    let libraryID = "unknown";
    switch (pkgType) {
      case "gene":
        libraryID = "LG";
        break;
      case "arm":
        libraryID = "LH";
        break;
      case "promoter":
        libraryID = "LP";
        break;
      case "rbs":
        libraryID = "LR";
        break;
      case "terminator":
        libraryID = "LT";
        break;
      case "pgst":
        libraryID = "LW";
        break;
      default:
        libraryID = "unknown";
        break;
    }
    return libraryID;
  }

  initPackage() {
    OlabUtils.infoLog("OlabPackage.initPackage() ...");
    // Common fields: internal use
    this.olab_id = null;
    this.olab_type = "package";
    this.library_id = null;
    this.deleted = false;

    // Common fields: User settable
    this.name = null;
    this.creator = null;
    this.desc = null;
    this.package_seq_type = "library_sequence";
    this.package_type = null;
    this.comps = [];
  }

  set(pkg) {
    this.olab_id = pkg.olab_id;
    this.olab_type = pkg.olab_type;
    this.deleted = pkg.deleted;
    this.name = pkg.name;
    this.creator = pkg.creator;
    this.desc = pkg.desc;
    this.package_seq_type = pkg.package_seq_type;
    this.package_type = pkg.package_type;
    this.library_id = OlabPackage.pkgTypeToLibraryID(pkg.package_type);
    this.comps = pkg.comps;
  }
}

export { OlabPackage };

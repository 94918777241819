<template>
  <div>
    <div v-if="selectedType === 'plasmid'">
      <div v-if="filteredGenes" class="my-3 overflow-auto bg-white">
        <div id="d3svgCircularMapOPCM">
          <div id="tooltipOPCM" class="text-info text-left">
            <div class="display-name"></div>
            <div class="display-start">Start: <span></span></div>
            <div class="display-end">End: <span></span></div>
            <div class="display-length">Length: <span></span></div>
            <div class="display-strand">Strand: <span></span></div>
          </div>
        </div>
        <div v-if="numCsts > 1" class="h5">
          Only the first plasmid is displayed as a preview
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MetaMap } from "@/olab/d3/metaMap.js";
import { OlabCircularMap } from "@/olab/d3/olabCircularMap";

export default {
  name: "olabplasmidmap",
  data() {
    return {
      plasmid: null,
      filteredGenes: null,
      filteredTags: null,
      circularMap: new OlabCircularMap("d3svgCircularMapOPCM", "tooltipOPCM"),
      geneNotFound: false
    };
  },
  components: {},
  props: ["selectedType", "item", "numCsts"],
  emits: ["resetStates"],
  async mounted() {
    // Set plasmid to item so that it will be reference correctly
    // in OlabLinearMap
    this.plasmid = this.item;

    // this.filteredGenes will contain the return genes
    // this.filteredTags will contain the return tags
    await this.processMetaMap();

    this.circularMap.setup();
    // console.log("this.filteredGenes =", this.filteredGenes);
    // console.log("this.filteredTags =", this.filteredTags);

    const frameArr = this.circularMap.compute(
      this.filteredGenes,
      this.plasmid.stats.total_seq_len
    );
    // console.log("(1) frameArr =", frameArr);

    if (this.filteredTags && this.filteredTags.length > 0) {
      const tagFrameArr = this.circularMap.computeTagFrames(
        this.filteredTags,
        this.plasmid.stats.total_seq_len
      );
      console.log("tagFrameArr =", tagFrameArr);
      // Insert tagFrameArr's array(s) to the front of frameArr
      frameArr.unshift(...tagFrameArr);
      // console.log("(2) frameArr = ", frameArr);
    }
    this.circularMap.draw(frameArr, this);
  },
  methods: {
    // Note: Split metaMap into filteredGenes and filteredTags
    processMetaMap() {
      if (this.plasmid) {
        let plasmGArr = this.plasmid.meta_map;

        // console.log("plasmGArr =", plasmGArr);
        const gArr = [];
        const tArr = [];
        MetaMap.processMetaMap(plasmGArr, gArr, tArr, true);
        // console.log("gArr =", gArr, ", tArr =", tArr);
        this.filteredGenes = gArr;
        this.filteredTags = tArr;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#d3svgCircularMapOPCM {
  margin: 15px auto;
  width: 500px;
  position: relative;
}

.labels {
  font-family: sans-serif;
  font-size: 12px;
  text-anchor: middle;
}

#tooltipOPCM {
  top: 200px;
  left: 210px;
  text-align: left;
  font-size: 12px;
  // border: 1px dashed #5bc0de;
  position: absolute;
  padding: 10px;
  background-color: #efc;
  display: none;
}

#tooltipOPCM .display-name {
  text-decoration: underline;
}
</style>

import axios from "axios";
import { OlabItem } from "./olabitem";
import { OlabGene } from "./olabgene";
import { OlabBlock } from "./olabblock";
import { OlabSearch } from "./olabsearch";

class OlabUtils {
  static production = true;

  // Limit to 200 items per package
  static MAX_ITEMS_PER_PACKAGE = 200;
  // Limit to 20 items per cart
  static MAX_ITEMS_PER_CART = 20;

  constructor(type) {
    this.stats = {
      count: 0
    };
    this.type = type;
  }

  addCount(cnt) {
    this.stats.count += cnt;
  }

  static fixFloatTo(value, digit) {
    return parseFloat(value).toFixed(digit);
  }

  static getErrorMessage(err) {
    return `${err.response.statusText}: ${err.response.data.message}`;
  }

  static printLog(type, str) {
    if (OlabUtils.production) {
      return;
    }
    if (type === "info") {
      console.info("Info: ", str);
    } else if (type === "warn") {
      console.warn("Warn: ", str);
    } else {
      // Assume type === "error"
      console.error("Error: ", str);
    }
  }

  static infoLog(str) {
    OlabUtils.printLog("info", str);
  }

  static warnLog(str) {
    OlabUtils.printLog("warn", str);
  }

  static errorLog(str, err) {
    OlabUtils.printLog("error", str);
    // For debugging
    alert(OlabUtils.getErrorMessage(err));
  }

  static getIndicesOf(searchStr, str, caseSensitive) {
    const searchStrLen = searchStr.length;
    if (searchStrLen === 0) {
      return [];
    }
    if (!caseSensitive) {
      str = str.toLowerCase();
      searchStr = searchStr.toLowerCase();
    }
    let startIndex = 0,
      index,
      indices = [];
    while ((index = str.indexOf(searchStr, startIndex)) > -1) {
      indices.push(index);
      startIndex = index + searchStrLen;
    }
    // console.log("indices =", indices);
    return indices;
  }

  static getStrainID(geneOlabID) {
    return geneOlabID.substring(0, 10);
  }

  static getLibraryType(useSeqAs) {
    let ldType = null;
    switch (useSeqAs) {
      case "gene":
        ldType = "LG";
        break;
      case "arm":
        ldType = "LH";
        break;
      case "promoter":
        ldType = "LP";
        break;
      case "rbs":
        ldType = "LR";
        break;
      case "terminator":
        ldType = "LT";
        break;
      case "plasmid":
        ldType = "LV";
        break;
      case "pgst":
        ldType = "LW";
        break;
      default:
        // Set default as "PGsT"
        ldType = "LW";
        break;
    }
    return ldType;
  }

  static cleanSearchTem(searchTerm) {
    // Remove all space characters from searchTerm
    // if searchTerm is longer than 7 characters,
    //  a sequence and about half are spaces
    if (searchTerm && searchTerm.length > 7) {
      const tempStr = searchTerm.replace(/\s/g, "");
      const tsLen = tempStr.length * 2;
      const diffLen = Math.abs(searchTerm.length - tsLen);
      if (!tempStr.match(/[^acgt]/gi) && diffLen < 2) {
        searchTerm = tempStr;
      }
    }
    return searchTerm;
  }

  static subStrB4Space(str) {
    return !str || str === "" ? "" : str.substring(0, str.indexOf(" ")).trim();
  }

  static validateEmail(em) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(em).toLowerCase());
  }

  static getStrainRole(proj, strain) {
    let role = "blocked";
    if (
      strain.ra_locks &&
      strain.ra_locks.members &&
      strain.ra_locks.members.indexOf(proj.olab_id) !== -1
    ) {
      role = "member";
    }
    return role;
  }

  // Note: This method will return both strains and plasmids
  // Call should use the return element.olab_type to separate strains
  // from plasmids.
  static async getProjStrains(projID) {
    try {
      OlabUtils.infoLog("OlabUtils.getProjStrains: projID =", projID);
      let strains = [];
      const res = await axios.get(`/api/v1/projects/getStrains/${projID}`);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.strains
      ) {
        strains = res.data.data.strains;
        // console.log("strains: strains =", strains);
      } else {
        OlabUtils.infoLog("strains is null");
      }
      return strains;
    } catch (err) {
      OlabUtils.errorLog("getProjStrains - Error:", err);
      return [];
    }
  }

  static async getStrainMembers(strainID) {
    try {
      OlabUtils.infoLog("OlabUtils.getStrainMembers: strainID =", strainID);
      let members = [];
      const res = await axios.get(`/api/v1/strains/getMembers/${strainID}`);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.members
      ) {
        members = res.data.data.members;
        // console.log("members: members =", members);
      } else {
        OlabUtils.infoLog("members is null");
      }
      return members;
    } catch (err) {
      OlabUtils.errorLog("getStrainMembers - Error:", err);
      return [];
    }
  }

  static async getPlasmidMembers(plasmidID) {
    try {
      OlabUtils.infoLog("OlabUtils.getPlasmidMembers: plasmidID =", plasmidID);
      // console.log("OlabUtils.getPlasmidMembers: plasmidID =", plasmidID);
      let members = [];
      const res = await axios.get(`/api/v1/plasmids/getMembers/${plasmidID}`);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.members
      ) {
        members = res.data.data.members;
        // console.log("members: members =", members);
      } else {
        OlabUtils.infoLog("members is null");
      }
      return members;
    } catch (err) {
      // console.log("getPlasmidMembers - Error:", err);
      OlabUtils.errorLog("getPlasmidMembers - Error:", err);
      return [];
    }
  }

  static getProjectRole(user, project) {
    let role = "blocked";
    if (project.creator === user.email) {
      role = "owner";
    } else if (
      project.ra_locks &&
      project.ra_locks.members &&
      project.ra_locks.members.indexOf(user.email) !== -1
    ) {
      role = "member";
    }
    return role;
  }

  static async getUserProjects(userEmail) {
    try {
      OlabUtils.infoLog("OlabUtils.getUserProjects: userEmail =", userEmail);
      let projects = [];
      const res = await axios.get(`/api/v1/users/getProjects/${userEmail}`);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.projects
      ) {
        projects = res.data.data.projects;
        // console.log("projects: projects =", projects);
      } else {
        OlabUtils.infoLog("projects is null");
      }
      return projects;
    } catch (err) {
      OlabUtils.errorLog("getUserProjects - Error:", err);
      return [];
    }
  }

  static async getProjectMembers(projectID) {
    try {
      OlabUtils.infoLog("OlabUtils.getProjectMembers: projectID =", projectID);
      let members = [];
      const res = await axios.get(`/api/v1/projects/getMembers/${projectID}`);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.members
      ) {
        members = res.data.data.members;
        // console.log("members: members =", members);
      } else {
        OlabUtils.infoLog("members is null");
      }
      return members;
    } catch (err) {
      OlabUtils.errorLog("getProjectMembers - Error:", err);
      return [];
    }
  }

  static async getGenes(searchBody) {
    // console.log("getGenes' body =", searchBody);
    try {
      // Load genes
      const genes = [];

      // Compute sortBy based on searchField
      const searchField = searchBody.searchField
        ? searchBody.searchField
        : "olab_id";
      const sortBy =
        searchField === "merge"
          ? "olab_id"
          : searchField === "name"
          ? "name synonym_name synonym_name2"
          : searchField;

      // POST because we are sending a body with this request
      const res = await axios({
        method: "POST",
        url: "/api/v1/genes/mergeFind",
        data: {
          search_type: "gene",
          search_string: searchBody.searchString ? searchBody.searchString : "",
          search_field: searchField,
          sort_by: sortBy,
          genes_ref: searchBody.genesRef ? searchBody.genesRef : null,
          page: searchBody.page ? searchBody.page : 1,
          limit: searchBody.limit ? searchBody.limit : OlabSearch.cntPerPage
        }
      });

      // console.log("genes: status = ", res.data.status);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.genes
      ) {
        OlabUtils.infoLog("genes: results = " + res.data.results);
        const gns = res.data.data.genes;
        // console.log("genes: gns =", gns);
        gns.forEach(gn => {
          genes.push(new OlabGene(gn));
        });
      } else {
        OlabUtils.infoLog("genes is null");
      }
      return genes;
    } catch (err) {
      OlabUtils.errorLog("** getGenes - Error:", err);
      return [];
    }
  }

  static async getGeneBlocks(searchOp, statusObj, errorObj) {
    try {
      // Load geneblocks
      const geneblocks = [];

      if (!searchOp) {
        statusObj.message = "Get gene blocks with null searchOp";
        statusObj.progress = false;
        return geneblocks;
      }

      const res = await axios.get("/api/v1/geneblocks", searchOp);
      OlabUtils.infoLog("geneblocks: status = " + res.data.status);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.geneBlocks
      ) {
        OlabUtils.infoLog("geneBlocks: results = " + res.data.results);
        const gnBlks = res.data.data.geneBlocks;
        // console.log("geneBlocks: gnBlks =", gnBlks);
        gnBlks.forEach(gnBlk => {
          geneblocks.push(new OlabBlock(gnBlk));
        });
      } else {
        OlabUtils.infoLog("geneBlocks is null");
        statusObj.message = `Get gene blocks but found none, status =${res.data.status}`;
      }
      statusObj.progress = false;
      return geneblocks;
    } catch (err) {
      OlabUtils.errorLog("** getGeneBlocks - Error:", err);
      errorObj.message = OlabUtils.getErrorMessage(err);
      return [];
    }
  }

  static async getItems(sType, searchBody) {
    try {
      let items = [];
      switch (sType) {
        case "cart":
          items = await OlabUtils.getCarts(searchBody);
          break;
        case "construct":
          items = await OlabUtils.getConstructs(searchBody);
          break;
        case "design":
          items = await OlabUtils.getDesigns(searchBody);
          break;
        case "library_sequence":
          items = await OlabUtils.getLibseqs(searchBody);
          break;
        // case "libseq_origin":
        //   return "/api/v1/libseqorigins";
        case "package":
          items = await OlabUtils.getPackages(searchBody);
          break;
        case "plasmid":
          items = await OlabUtils.getPlasmids(searchBody);
          break;
        case "primer":
          items = await OlabUtils.getPrimers(searchBody);
          break;
        case "project":
          items = await OlabUtils.getProjects(searchBody);
          break;
        case "strain":
          items = await OlabUtils.getStrains(searchBody);
          break;
        case "user":
          items = await OlabUtils.getUsers(searchBody);
          break;
        default:
          OlabUtils.infoLog("getItems: Unknown sType = " + sType);
      }
      return items;
    } catch (err) {
      OlabUtils.errorLog("** getItems - Error:", err);
    }
  }

  static async getProjects(searchBody) {
    // console.log("getProjects' body =", searchBody);
    try {
      // Load projects
      const projects = [];

      // Compute sortBy based on searchField
      const searchField = searchBody.searchField
        ? searchBody.searchField
        : "olab_id";
      const sortBy = searchField === "merge" ? "olab_id" : searchField;

      // POST because we are sending a body with this request
      const res = await axios({
        method: "POST",
        url: "/api/v1/projects/mergeFind",
        data: {
          search_type: "project",
          search_string: searchBody.searchString ? searchBody.searchString : "",
          search_field: searchField,
          sort_by: sortBy,
          aux_id: searchBody.auxID ? searchBody.auxID : null,
          page: searchBody.page ? searchBody.page : 1,
          limit: searchBody.limit ? searchBody.limit : OlabSearch.cntPerPage
        }
      });

      // console.log("projects: status = ", res.data.status);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.projects
      ) {
        OlabUtils.infoLog("projects: results = " + res.data.results);
        const projs = res.data.data.projects;
        // console.log("projects: projs =", projs);
        projs.forEach(proj => {
          if (proj.start_date) {
            // datepicker ONLY accept YYYY-MM-DD part of string
            proj.start_date = proj.start_date.substring(0, 10);
          }
          if (proj.end_date) {
            // datepicker ONLY accept YYYY-MM-DD part of string
            proj.end_date = proj.end_date.substring(0, 10);
          }
          projects.push(new OlabItem(proj));
        });
      } else {
        OlabUtils.infoLog("projects is null");
      }
      return projects;
    } catch (err) {
      OlabUtils.errorLog("** getProjects - Error:", err);
      return [];
    }
  }

  static async getMicrobes(searchOp) {
    try {
      // Load microbes
      const microbes = [];

      if (!searchOp) {
        searchOp = {
          params: {
            sort: "olab_id"
          }
        };
      }
      const res = await axios.get("/api/v1/microbes", searchOp);
      OlabUtils.infoLog("microbes: status = " + res.data.status);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.microbes
      ) {
        OlabUtils.infoLog("microbes: results = " + res.data.results);
        const mcbes = res.data.data.microbes;
        // console.log("microbes: mcbes =", mcbes);
        mcbes.forEach(mcbe => {
          // console.log("mcbe =", mcbe);
          microbes.push(new OlabItem(mcbe));
          // console.log("Pushed microbes =", microbes[microbes.length - 1]);
        });
      } else {
        OlabUtils.infoLog("microbes is null");
      }
      return microbes;
    } catch (err) {
      OlabUtils.errorLog("** getMicrobes - Error:", err);
      return [];
    }
  }

  static async getStrains(searchBody) {
    // console.log("getStrains' body =", searchBody);
    try {
      // Load strains
      const strains = [];

      // Compute sortBy based on searchField
      const searchField = searchBody.searchField
        ? searchBody.searchField
        : "olab_id";
      const sortBy = searchField === "merge" ? "olab_id" : searchField;

      // POST because we are sending a body with this request
      const res = await axios({
        method: "POST",
        url: "/api/v1/strains/mergeFind",
        data: {
          search_type: "strain",
          search_string: searchBody.searchString ? searchBody.searchString : "",
          search_field: searchField,
          sort_by: sortBy,
          aux_id: searchBody.auxID ? searchBody.auxID : null,
          page: searchBody.page ? searchBody.page : 1,
          limit: searchBody.limit ? searchBody.limit : OlabSearch.cntPerPage
        }
      });

      // console.log("strains: status = ", res.data.status);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.strains
      ) {
        // console.log("strains: results = ", res.data.results);
        const strns = res.data.data.strains;
        // console.log("strains: strns =", strns);
        strns.forEach(strn => {
          strains.push(new OlabItem(strn));
        });
      } else {
        // console.log("strains is null");
      }
      return strains;
    } catch (err) {
      OlabUtils.errorLog("** getStrains - Error:", err);
      return [];
    }
  }

  static async getLibseqs(searchBody) {
    // console.log("getLibseqs' body =", searchBody);
    try {
      // Load libseqs
      const libseqs = [];

      // Compute sortBy based on searchField
      const searchField = searchBody.searchField
        ? searchBody.searchField
        : "olab_id";
      const sortBy = searchField === "merge" ? "olab_id" : searchField;

      // POST because we are sending a body with this request
      const res = await axios({
        method: "POST",
        url: "/api/v1/libseqs/mergeFind",
        data: {
          search_type: "libseq",
          search_string: searchBody.searchString ? searchBody.searchString : "",
          search_field: searchField,
          sort_by: sortBy,
          include_deleted: searchBody.includeDeleted
            ? searchBody.includeDeleted
            : false,
          aux_id: searchBody.auxID ? searchBody.auxID : null,
          page: searchBody.page ? searchBody.page : 1,
          limit: searchBody.limit ? searchBody.limit : OlabSearch.cntPerPage
        }
      });

      OlabUtils.infoLog("libseqs: status = " + res.data.status);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.libseqs
      ) {
        OlabUtils.infoLog("libseqs: results = " + res.data.results);
        const lbseqs = res.data.data.libseqs;
        // console.log("libseqs: lbseqs =", lbseqs);
        lbseqs.forEach(lbseq => {
          libseqs.push(new OlabItem(lbseq));
        });
      } else {
        OlabUtils.infoLog("libseqs is null");
      }
      return libseqs;
    } catch (err) {
      OlabUtils.errorLog("** getLibseqs - Error:", err);
      return [];
    }
  }

  static async getCarts(searchBody) {
    // console.log("getCarts' body =", searchBody);
    try {
      // Load carts
      const carts = [];

      // Compute sortBy based on searchField
      const searchField = searchBody.searchField
        ? searchBody.searchField
        : "olab_id";
      const sortBy = searchField === "merge" ? "olab_id" : searchField;

      // POST because we are sending a body with this request
      const res = await axios({
        method: "POST",
        url: "/api/v1/carts/mergeFind",
        data: {
          search_type: "cart",
          search_string: searchBody.searchString ? searchBody.searchString : "",
          search_field: searchField,
          sort_by: sortBy,
          include_deleted: searchBody.includeDeleted
            ? searchBody.includeDeleted
            : false,
          aux_id: searchBody.auxID ? searchBody.auxID : null,
          page: searchBody.page ? searchBody.page : 1,
          limit: searchBody.limit ? searchBody.limit : OlabSearch.cntPerPage
        }
      });

      OlabUtils.infoLog("carts: status = " + res.data.status);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.carts
      ) {
        OlabUtils.infoLog("carts: results = " + res.data.results);
        const cts = res.data.data.carts;
        // console.log("carts: cts =", cts);
        cts.forEach(ct => {
          carts.push(new OlabItem(ct));
        });
      } else {
        OlabUtils.infoLog("carts is null");
      }
      return carts;
    } catch (err) {
      OlabUtils.errorLog("** getCarts - Error:", err);
      return [];
    }
  }

  static async getConstructs(searchBody) {
    // console.log("getConstructs' body =", searchBody);
    try {
      // Load constructs
      const constructs = [];

      // Compute sortBy based on searchField
      const searchField = searchBody.searchField
        ? searchBody.searchField
        : "olab_id";
      const sortBy = searchField === "merge" ? "olab_id" : searchField;

      // POST because we are sending a body with this request
      const res = await axios({
        method: "POST",
        url: "/api/v1/constructs/mergeFind",
        data: {
          search_type: "construct",
          search_string: searchBody.searchString ? searchBody.searchString : "",
          search_field: searchField,
          sort_by: sortBy,
          include_deleted: searchBody.includeDeleted
            ? searchBody.includeDeleted
            : false,
          aux_id: searchBody.auxID ? searchBody.auxID : null,
          page: searchBody.page ? searchBody.page : 1,
          limit: searchBody.limit ? searchBody.limit : OlabSearch.cntPerPage
        }
      });

      // console.log("constructs: status = ", res.data.status);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.constructs
      ) {
        // console.log("constructs: results = ", res.data.results);
        const csts = res.data.data.constructs;
        // console.log("constructs: csts =", csts);
        csts.forEach(cst => {
          constructs.push(new OlabItem(cst));
        });
      } else {
        // console.log("constructs is null");
      }
      return constructs;
    } catch (err) {
      OlabUtils.errorLog("** getConstructs - Error:", err);
      return [];
    }
  }

  // Search (configured === true) designs only
  static async getDesigns(searchBody) {
    // console.log("getDesigns' body =", searchBody);
    try {
      // Load designs
      const designs = [];

      // Compute sortBy based on searchField
      const searchField = searchBody.searchField
        ? searchBody.searchField
        : "olab_id";
      const sortBy = searchField === "merge" ? "olab_id" : searchField;

      // Note: Restrict search to configured designs ONLY.
      const configured = true;

      // POST because we are sending a body with this request
      const res = await axios({
        method: "POST",
        url: "/api/v1/designs/mergeFind",
        data: {
          search_type: "design",
          search_string: searchBody.searchString ? searchBody.searchString : "",
          search_field: searchField,
          sort_by: sortBy,
          include_deleted: searchBody.includeDeleted
            ? searchBody.includeDeleted
            : false,
          aux_id: searchBody.auxID ? searchBody.auxID : null,
          configured: configured,
          page: searchBody.page ? searchBody.page : 1,
          limit: searchBody.limit ? searchBody.limit : OlabSearch.cntPerPage
        }
      });

      OlabUtils.infoLog("designs: status = " + res.data.status);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.designs
      ) {
        OlabUtils.infoLog("designs: results = " + res.data.results);
        const dsgns = res.data.data.designs;
        // console.log("designs: dsgns =", dsgns);
        dsgns.forEach(dsgn => {
          designs.push(new OlabItem(dsgn));
        });
      } else {
        OlabUtils.infoLog("designs is null");
      }
      return designs;
    } catch (err) {
      OlabUtils.errorLog("** getDesigns - Error:", err);
      return [];
    }
  }

  static async getPackages(searchBody) {
    // console.log("getPackages' body =", searchBody);
    try {
      // Load packages
      const packages = [];

      // Compute sortBy based on searchField
      const searchField = searchBody.searchField
        ? searchBody.searchField
        : "olab_id";
      const sortBy = searchField === "merge" ? "olab_id" : searchField;

      // POST because we are sending a body with this request
      const res = await axios({
        method: "POST",
        url: "/api/v1/packages/mergeFind",
        data: {
          search_type: "package",
          search_string: searchBody.searchString ? searchBody.searchString : "",
          search_field: searchField,
          sort_by: sortBy,
          include_deleted: searchBody.includeDeleted
            ? searchBody.includeDeleted
            : false,
          aux_id: searchBody.auxID ? searchBody.auxID : null,
          page: searchBody.page ? searchBody.page : 1,
          limit: searchBody.limit ? searchBody.limit : OlabSearch.cntPerPage
        }
      });

      OlabUtils.infoLog("packages: status = " + res.data.status);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.packages
      ) {
        OlabUtils.infoLog("packages: results = " + res.data.results);
        const pkgs = res.data.data.packages;
        // console.log("packages: pkgs =", pkgs);
        pkgs.forEach(pkg => {
          packages.push(new OlabItem(pkg));
        });
      } else {
        OlabUtils.infoLog("packages is null");
      }
      return packages;
    } catch (err) {
      OlabUtils.errorLog("** getPackages - Error:", err);
      return [];
    }
  }

  static async getPlasmids(searchBody) {
    // console.log("getPlasmids' body =", searchBody);
    try {
      // Load plasmids
      const plasmids = [];

      // Compute sortBy based on searchField
      const searchField = searchBody.searchField
        ? searchBody.searchField
        : "olab_id";
      const sortBy = searchField === "merge" ? "olab_id" : searchField;

      // POST because we are sending a body with this request
      const res = await axios({
        method: "POST",
        url: "/api/v1/plasmids/mergeFind",
        data: {
          search_type: "plasmid",
          search_string: searchBody.searchString ? searchBody.searchString : "",
          search_field: searchField,
          sort_by: sortBy,
          aux_id: searchBody.auxID ? searchBody.auxID : null,
          page: searchBody.page ? searchBody.page : 1,
          limit: searchBody.limit ? searchBody.limit : OlabSearch.cntPerPage
        }
      });

      OlabUtils.infoLog("plasmids: status = " + res.data.status);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.plasmids
      ) {
        OlabUtils.infoLog("plasmids: results = " + res.data.results);
        const plads = res.data.data.plasmids;
        // console.log("plasmids: plads =", plads);
        plads.forEach(plad => {
          plasmids.push(new OlabItem(plad));
        });
      } else {
        OlabUtils.infoLog("plasmids is null");
      }
      return plasmids;
    } catch (err) {
      OlabUtils.errorLog("** getPlasmids - Error:", err);
      return [];
    }
  }

  static async getPrimers(searchBody) {
    // console.log("getPrimers' body =", searchBody);
    try {
      // Load primers
      const primers = [];

      // Compute sortBy based on searchField
      const searchField = searchBody.searchField
        ? searchBody.searchField
        : "olab_id";
      const sortBy = searchField === "merge" ? "olab_id" : searchField;

      // POST because we are sending a body with this request
      const res = await axios({
        method: "POST",
        url: "/api/v1/primers/mergeFind",
        data: {
          search_type: "primer",
          search_string: searchBody.searchString ? searchBody.searchString : "",
          search_field: searchField,
          sort_by: sortBy,
          aux_id: searchBody.auxID ? searchBody.auxID : null,
          page: searchBody.page ? searchBody.page : 1,
          limit: searchBody.limit ? searchBody.limit : OlabSearch.cntPerPage
        }
      });

      OlabUtils.infoLog("primers: status = " + res.data.status);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.primers
      ) {
        OlabUtils.infoLog("primers: results = " + res.data.results);
        const pmrs = res.data.data.primers;
        // console.log("primers: pmrs =", pmrs);
        pmrs.forEach(pmr => {
          primers.push(new OlabItem(pmr));
        });
      } else {
        OlabUtils.infoLog("primers is null");
      }
      return primers;
    } catch (err) {
      OlabUtils.errorLog("** getPrimers - Error:", err);
      return [];
    }
  }

  static async getUsers(searchBody) {
    // console.log("getUsers' body =", searchBody);
    try {
      // Load users
      const users = [];

      // Compute sortBy based on searchField
      const searchField = searchBody.searchField
        ? searchBody.searchField
        : "olab_id";
      const sortBy = searchField === "merge" ? "olab_id" : searchField;

      // POST because we are sending a body with this request
      const res = await axios({
        method: "POST",
        url: "/api/v1/users/mergeFind",
        data: {
          search_type: "user",
          search_string: searchBody.searchString ? searchBody.searchString : "",
          search_field: searchField,
          sort_by: sortBy,
          aux_id: searchBody.auxID ? searchBody.auxID : null,
          page: searchBody.page ? searchBody.page : 1,
          limit: searchBody.limit ? searchBody.limit : OlabSearch.cntPerPage
        }
      });

      OlabUtils.infoLog("users: status = " + res.data.status);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.users
      ) {
        OlabUtils.infoLog("users: results = " + res.data.results);
        const usrs = res.data.data.users;
        // console.log("users: usrs =", usrs);
        usrs.forEach(usr => {
          users.push(new OlabItem(usr));
        });
      } else {
        OlabUtils.infoLog("users is null");
      }
      return users;
    } catch (err) {
      OlabUtils.errorLog("** getUsers - Error:", err);
      return [];
    }
  }

  static async getGeneBlockConfig(id, errorObj) {
    try {
      // console.log("OlabUtils.getGeneBlockConfig: id =", id);
      let geneBlockConfig = null;
      const res = await axios.get(`/api/v1/geneblockconfigs/${id}`);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.geneBlockConfig
      ) {
        geneBlockConfig = res.data.data.geneBlockConfig;
        // console.log("geneBlockConfig =", geneBlockConfig);
      } else {
        // console.log("geneBlockConfig is null");
      }
      return geneBlockConfig;
    } catch (err) {
      // console.log("geneBlockConfig - Error:", err);
      errorObj.message = OlabUtils.getErrorMessage(err);
      return null;
    }
  }

  static async getDBConfigs() {
    try {
      // Setup db_configs data
      const dbConfigs = {};

      // Load seq_origin to dbConfigs (sorted by name)
      // TODO: Should be done in dynamic query and not in a preload fashion in the future
      // Limit to 100 is good for now.
      let res = await axios.get("/api/v1/libseqorigins?sort=name&limit=100");
      OlabUtils.infoLog(
        "libseqorigins: libseqorigins.status = " + res.data.status
      );
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.libseqOrigins
      ) {
        OlabUtils.infoLog(
          "libseqOrigins: libseqOrigins.results = " + res.data.results
        );
        const libseqOrigins = res.data.data.libseqOrigins;
        // console.log("libseqOrigins: libseqOrigins =", libseqOrigins);
        // Add new fields to libseqOrigins to match selecttree data format
        libseqOrigins.forEach(so => {
          so.id = so.name;
          so.label = `${so.id} - ${so.desc}`;
        });
        // console.log("libseqOrigins: libseqOrigins =", libseqOrigins);
        dbConfigs.seq_origins = libseqOrigins;
      } else {
        OlabUtils.infoLog("libseqOrigins is null");
      }

      // Load plasmid_ori to dbConfigs (sorted by name)
      // TODO: Should be done in dynamic query and not in a preload fashion in the future
      // Limit to 100 is good for now.
      res = await axios.get("/api/v1/plasmidoris?sort=name&limit=100");
      OlabUtils.infoLog("plasmidoris: plasmidoris.status = " + res.data.status);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.plasmidOris
      ) {
        OlabUtils.infoLog(
          "plasmidOris: plasmidOris.results = " + res.data.results
        );
        const plasmidOris = res.data.data.plasmidOris;
        // console.log("plasmidOris: plasmidOris =", plasmidOris);
        dbConfigs.plasmid_oris = plasmidOris;
      } else {
        OlabUtils.infoLog("plasmidOris is null");
      }

      // Load restriction enzymes to dbConfigs (sorted by name)
      // TODO: Should be done in dynamic query and not in a preload fashion in the future
      // Limit to 1000 is good for now.
      res = await axios.get("/api/v1/resenzymes?sort=name&limit=1000");
      // console.log("resenzymes: resenzymes.status = " + res.data.status);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.resEnzymes
      ) {
        // console.log("resEnzymes: resEnzymes.results = " + res.data.results);
        const resEnzymes = res.data.data.resEnzymes;
        // console.log("(1) resEnzymes: resEnzymes =", resEnzymes);
        // Add new fields to resEnzymes to match selecttree data format
        resEnzymes.forEach(re => {
          re.id = re.name;
          re.label = re.name;
        });
        // console.log("(2) resEnzymes: resEnzymes =", resEnzymes);
        dbConfigs.res_enzymes = resEnzymes;
      } else {
        console.log("resEnzymes is null");
      }

      // Load libraries to dbConfigs (sorted by name)
      res = await axios.get("/api/v1/libs?sort=name");
      OlabUtils.infoLog("libraries: libs.status = " + res.data.status);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.libs
      ) {
        OlabUtils.infoLog("libraries: libs.results = " + res.data.results);
        const libs = res.data.data.libs;
        // console.log("libs: libs =", libs);
        dbConfigs.libraries = libs;
      } else {
        OlabUtils.infoLog("libs is null");
      }
      return dbConfigs;
    } catch (err) {
      OlabUtils.errorLog("** getDBConfigs - Error:", err);
    }
  }

  static formatMemorySize(size) {
    let formatSz = "";
    const kiloBytes = 1024;
    const megaBytes = kiloBytes * 1024;
    const gigaBytes = megaBytes * 1024;
    if (size >= gigaBytes) {
      const cSz = parseFloat(size / gigaBytes).toPrecision(4);
      formatSz = `${cSz} GB`;
    } else if (size >= megaBytes) {
      const cSz = parseFloat(size / megaBytes).toPrecision(4);
      formatSz = `${cSz} MB`;
    } else if (size >= kiloBytes) {
      const cSz = parseFloat(size / kiloBytes).toPrecision(4);
      formatSz = `${cSz} KB`;
    } else {
      formatSz = `${size} Bytes`;
    }
    return formatSz;
  }
}

export { OlabUtils };

import { OlabUtils } from "./olabutils.js";

class OlabGene {
  constructor(gene) {
    if (gene == null) {
      this.initGene();
    } else {
      let hp = Object.prototype.hasOwnProperty;
      this.olab_id = hp.call(gene, "olab_id") ? gene.olab_id : null;
      this.olab_type = hp.call(gene, "olab_type") ? gene.olab_type : "gene";
      this.name = hp.call(gene, "name") ? gene.name : null;
      this.synonym_name = hp.call(gene, "synonym_name")
        ? gene.synonym_name
        : null;
      this.synonym_name2 = hp.call(gene, "synonym_name2")
        ? gene.synonym_name2
        : null;
      this.locus_tag = hp.call(gene, "locus_tag") ? gene.locus_tag : null;
      this.old_locus_tag = hp.call(gene, "old_locus_tag")
        ? gene.old_locus_tag
        : null;
      this.product = hp.call(gene, "product") ? gene.product : null;
      this.desc = hp.call(gene, "desc") ? gene.desc : null;
      let gStats = hp.call(gene, "gene_stats") ? gene.gene_stats : null;
      this.gene_stats = {
        len: 0,
        num_a: 0,
        num_t: 0,
        num_c: 0,
        num_g: 0,
        short_seq: null
      };
      if (gStats) {
        this.gene_stats.len = hp.call(gStats, "len") ? gStats.len : 0;
        this.gene_stats.num_a = hp.call(gStats, "num_a") ? gStats.num_a : 0;
        this.gene_stats.num_t = hp.call(gStats, "num_t") ? gStats.num_t : 0;
        this.gene_stats.num_c = hp.call(gStats, "num_c") ? gStats.num_c : 0;
        this.gene_stats.num_g = hp.call(gStats, "num_g") ? gStats.num_g : 0;
        this.gene_stats.short_seq = hp.call(gStats, "short_seq")
          ? gStats.short_seq
          : null;
      }

      let gSeq = hp.call(gene, "sequence") ? gene.sequence : null;
      this.sequence = {
        type: null,
        from: 0,
        to: 0,
        locations: [],
        comp: false,
        seq: null
      };
      if (gSeq) {
        this.sequence.type = hp.call(gSeq, "type") ? gSeq.type : null;
        this.sequence.from = hp.call(gSeq, "from") ? gSeq.from : 0;
        this.sequence.to = hp.call(gSeq, "to") ? gSeq.to : 0;
        this.sequence.locations = hp.call(gSeq, "locations")
          ? gSeq.locations
          : [];
        this.sequence.comp = hp.call(gSeq, "comp") ? gSeq.comp : false;
        this.sequence.seq = hp.call(gSeq, "seq") ? gSeq.seq : null;
      }
      let gAux = hp.call(gene, "aux") ? gene.aux : null;
      this.aux = {
        strain_name: null
      };
      if (gAux) {
        this.aux.strain_name = hp.call(gAux, "strain_name")
          ? gAux.strain_name
          : null;
      }
    }
  }

  initGene() {
    OlabUtils.infoLog("OlabGene.initGene() ...");

    this.olab_id = null;
    this.olab_type = "gene";
    this.name = null;
    this.synonym_name = null;
    this.synonym_name2 = null;
    this.locus_tag = null;
    this.old_locus_tag = null;
    this.product = null;
    this.desc = null;
    this.gene_stats = null;
    this.sequence = null;
    this.aux = null;
  }

  set(gene) {
    this.olab_id = gene.olab_id;
    this.olab_type = gene.olab_type;
    this.name = gene.name;
    this.synonym_name = gene.synonym_name;
    this.synonym_name2 = gene.synonym_name2;
    this.locus_tag = gene.locus_tag;
    this.old_locus_tag = gene.old_locus_tag;
    this.product = gene.product;
    this.desc = gene.desc;
    // Note: Shallow set (only set reference)
    this.gene_stats = gene.gene_stats;
    this.sequence = gene.sequence;
    this.aux = gene.aux;
  }

  // Gene's sequence is joined
  isJoined() {
    return (
      this.sequence &&
      this.sequence.locations &&
      this.sequence.locations.length > 0
    );
  }

  rangeIncludeIntrons() {
    let range = 0;
    if (this.sequence && this.sequence.seq) {
      range = this.sequence.seq.length;
      if (this.isJoined()) {
        const locs = this.sequence.locations;
        if (locs && locs.length > 0) {
          const start = locs[0].from;
          const end = locs[locs.length - 1].to;
          range = end - start + 1;
        }
      }
    }
    return range;
  }

  static getGeneDisplayName(gn) {
    let dName = "";
    if (gn) {
      if (gn.name) {
        dName = gn.name;
      } else if (gn.synonym_name) {
        dName = gn.synonym_name;
      } else if (gn.synonym_name2) {
        dName = gn.synonym_name2;
      } else if (gn.locus_tag) {
        dName = gn.locus_tag;
      } else if (gn.old_locus_tag) {
        dName = gn.old_locus_tag;
      }
    }
    return dName;
  }

  // static TEST_SEQ = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  // computeTestDisplaySeq(dStart, dRange) {
  //   console.log(
  //     "computeTestDisplaySeq: dStart =",
  //     dStart,
  //     ", dRange =",
  //     dRange
  //   );
  //   let appendSeq = null;
  //   let computedSeq = this.sequence.seq;
  //   if (dStart < 0) {
  //     const appendLen = dStart * -1;
  //     const appendTestSeqStartPos = OlabGene.TEST_SEQ.length - appendLen;
  //     appendSeq = OlabGene.TEST_SEQ.substring(
  //       appendTestSeqStartPos,
  //       OlabGene.TEST_SEQ.length
  //     );
  //     // console.log("** appendLen =", appendLen, " appendSeq =", appendSeq);
  //   } else {
  //     computedSeq = this.sequence.seq.substring(
  //       dStart,
  //       this.sequence.seq.length
  //     );
  //   }
  //   computedSeq = appendSeq ? appendSeq + computedSeq : computedSeq;

  //   if (computedSeq.length > dRange) {
  //     computedSeq = computedSeq.substring(0, dRange);
  //   } else {
  //     const len = dRange - computedSeq.length;
  //     computedSeq = computedSeq + OlabGene.TEST_SEQ.substring(0, len);
  //   }
  //   // console.log("computedSeq =", computedSeq);
  //   const res = {
  //     status: "success",
  //     message: "",
  //     seqFrag: computedSeq
  //   };
  //   return res;
  // }

  // async computeDisplaySeq(dStart, dRange, seqData) {
  //   // console.log(
  //   //   "computeDisplaySeq: dStart =",
  //   //   dStart,
  //   //   ", dRange =",
  //   //   dRange,
  //   //   ", seqData.displaySeq =",
  //   //   seqData.displaySeq
  //   // );

  //   const res = this.computeTestDisplaySeq(dStart, dRange);
  //   if (res.status === "success") {
  //     seqData.displaySeq = res.seqFrag;
  //   }
  //   console.log("seqData.displaySeq =", seqData.displaySeq);
  // }
}

export { OlabGene };

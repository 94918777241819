<template>
  <div>
    <div v-if="user" id="compdb">
      <nav class="navbar sticky-top navbar-light bg-light">
        <div class="input-group my-2">
          <!-- Search UI Group -->
          <div class="libraries-group-btn row mr-0">
            <button
              type="button"
              class="ml-3 btn btn-info dropdown-toggle"
              data-flip="false"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <font-awesome-icon icon="hand-pointer" />
              <span class="caret"></span>
            </button>
            <div class="dropdown-menu">
              <a
                class="dropdown-item"
                href="#"
                id="project"
                v-on:click.prevent="requestTypeChange('project')"
              >
                Project
                <span v-if="selectedType === 'project'">&#x2713;</span>
              </a>
              <a
                class="dropdown-item"
                href="#"
                id="cart"
                v-on:click.prevent="requestTypeChange('cart')"
              >
                Cart
                <span v-if="selectedType === 'cart'">&#x2713;</span>
              </a>
              <a
                class="dropdown-item"
                href="#"
                id="package"
                v-on:click.prevent="requestTypeChange('package')"
              >
                Package
                <span v-if="selectedType === 'package'">&#x2713;</span>
              </a>
              <a
                class="dropdown-item"
                href="#"
                id="strain"
                v-on:click.prevent="requestTypeChange('strain')"
              >
                Gene (Strain)
                <span v-if="selectedType === 'strain'">&#x2713;</span>
              </a>
              <a
                class="dropdown-item"
                href="#"
                id="plasmid"
                v-on:click.prevent="requestTypeChange('plasmid')"
              >
                Gene (Plasmid)
                <span v-if="selectedType === 'plasmid'">&#x2713;</span>
              </a>
              <a
                class="dropdown-item"
                href="#"
                id="library_sequence"
                v-on:click.prevent="requestTypeChange('library_sequence')"
              >
                Sequence
                <span v-if="selectedType === 'library_sequence'">&#x2713;</span>
              </a>
            </div>
            <!-- .dropdown-menu -->
          </div>
          <!-- .libraries-group-btn -->

          <div
            v-if="
              (selectedType === 'project' && user.role === 'admin') ||
                (selectedProject && selectedType === 'cart') ||
                (selectedProject && selectedType === 'library_sequence') ||
                (selectedProject && !selectedCart && selectedType === 'package')
            "
            class="add-btn ml-1"
          >
            <button
              type="button"
              class="btn btn-info"
              data-toggle="modal"
              data-target="#addNewItem"
              v-on:click="prepAddItem(item)"
              data-backdrop="static"
              data-keyboard="false"
            >
              <font-awesome-icon icon="plus" />
            </button>
          </div>
          <input
            id="searchTerm"
            placeholder="Search"
            type="text"
            class="form-control ml-1"
            aria-label="Search"
            v-model="searchTerm"
          />

          <div class="search-group-btn">
            <button
              type="button"
              class="ml-1 btn btn-info dropdown-toggle"
              data-flip="false"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <font-awesome-icon icon="search" />
              <span class="small mt-2 ml-1">{{
                filterKey | searchFilter
              }}</span>
              <span class="caret"></span>
            </button>

            <div class="dropdown-menu dropdown-menu-right">
              <a
                class="dropdown-item"
                href="#"
                id="merge"
                v-on:click.prevent="requestKeyChange('merge')"
              >
                Merge
                <span v-if="filterKey === 'merge'">&#x2713;</span>
              </a>

              <a
                class="dropdown-item"
                href="#"
                id="olab_id"
                v-on:click.prevent="requestKeyChange('olab_id')"
              >
                OLAB ID
                <span v-if="filterKey === 'olab_id'">&#x2713;</span>
              </a>

              <a
                class="dropdown-item"
                href="#"
                id="name"
                v-on:click.prevent="requestKeyChange('name')"
              >
                Name
                <span v-if="filterKey === 'name'">&#x2713;</span>
              </a>

              <a
                v-if="!selectedType === 'strain' || !selectedType === 'plasmid'"
                class="dropdown-item"
                href="#"
                id="creator"
                v-on:click.prevent="requestKeyChange('creator')"
              >
                Creator
                <span v-if="filterKey === 'creator'">&#x2713;</span>
              </a>

              <a
                v-if="selectedType === 'strain' || selectedType === 'plasmid'"
                class="dropdown-item"
                href="#"
                id="locus_tag"
                v-on:click.prevent="requestKeyChange('locus_tag')"
              >
                Locus Tag
                <span v-if="filterKey === 'locus_tag'">&#x2713;</span>
              </a>

              <a
                v-if="selectedType === 'strain' || selectedType === 'plasmid'"
                class="dropdown-item"
                href="#"
                id="old_locus_tag"
                v-on:click.prevent="requestKeyChange('old_locus_tag')"
              >
                Old Locus Tag
                <span v-if="filterKey === 'old_locus_tag'">&#x2713;</span>
              </a>

              <a
                v-if="
                  selectedType === 'strain' ||
                    selectedType === 'plasmid' ||
                    selectedType === 'library_sequence'
                "
                class="dropdown-item"
                href="#"
                id="seq"
                v-on:click.prevent="requestKeyChange('seq')"
              >
                Sequence
                <span v-if="filterKey === 'seq'">&#x2713;</span>
              </a>
            </div>
            <!-- .dropdown-menu -->
          </div>
          <!-- search-group-btn -->
        </div>
        <!-- input-group my-2 -->

        <div class="navbar-brand text-truncate">
          <!-- Placeholder for user profile-->
          <button
            type="button"
            class="btn btn-outline-danger ml-1"
            data-toggle="modal"
            data-target="#userProfile"
            data-backdrop="static"
            data-keyboard="false"
          >
            <font-awesome-icon icon="id-badge" />
          </button>
          <button
            type="button"
            class="btn btn-outline-success ml-1"
            data-toggle="modal"
            data-target="#infoProfile"
            data-backdrop="static"
            data-keyboard="false"
          >
            <font-awesome-icon icon="info" />
          </button>
          <span class="h4 text-success text-capitalize ml-2"
            >{{ selectedTypeName }} Information System</span
          >
        </div>
        <!-- navbar-brand text-truncate -->

        <div class="navbar-text ml-auto d-flex">
          <!-- Project profile button -->
          <button
            v-if="selectedProject"
            type="button"
            class="btn btn-outline-success ml-1"
            data-toggle="modal"
            data-target="#projectProfile"
            data-backdrop="static"
            data-keyboard="false"
          >
            <font-awesome-icon icon="project-diagram" />
          </button>

          <!-- cart button -->
          <div v-if="selectedCart" class="ml-1">
            <button
              type="button"
              class="btn btn-outline-success ml-1"
              data-toggle="modal"
              data-target="#cartProfile"
              data-backdrop="static"
              data-keyboard="false"
            >
              <span v-if="!cartIsFull" class="badge badge-pill badge-light">{{
                cartQty
              }}</span>
              <span v-if="cartIsFull" class="badge badge-pill badge-danger">{{
                cartQty
              }}</span>
              <font-awesome-icon icon="shopping-cart" />
            </button>
          </div>
          <!-- package button -->
          <div v-else-if="selectedPackage" class="ml-1">
            <button
              type="button"
              class="btn btn-outline-success ml-1"
              data-toggle="modal"
              data-target="#packageProfile"
              data-backdrop="static"
              data-keyboard="false"
            >
              <span
                v-if="!packageIsFull"
                class="badge badge-pill badge-light"
                >{{ packageQty }}</span
              >
              <span
                v-if="packageIsFull"
                class="badge badge-pill badge-danger"
                >{{ packageQty }}</span
              >
              <font-awesome-icon icon="box-open" />
            </button>
          </div>
        </div>
        <!-- .navbar-text ml-auto d-flex -->

        <div
          v-if="errorObj.message"
          class="col-12 alert alert-danger text-center px-3"
        >
          {{ errorObj.message }}
          <div class="text-center mt-2">
            <button
              type="button"
              class="btn btn-danger"
              v-on:click="closeError()"
            >
              OK
            </button>
          </div>
        </div>
        <div
          v-if="statusObj.message"
          class="col-12 alert alert-info fade show px-3"
        >
          <span>{{ statusObj.message }}</span>
          <span
            v-if="statusObj.progress"
            class="mx-2 spinner-grow"
            role="status"
          >
            <span class="sr-only">Status information</span>
          </span>
          <div class="text-center mt-2">
            <button
              type="button"
              class="btn btn-primary"
              v-on:click="closeStatus()"
            >
              OK
            </button>
          </div>
        </div>
      </nav>
      <!-- sticky-top -->

      <!-- userProfile -->
      <OlabUserProfile :user="user" />

      <!-- infoProfile -->
      <OlabInfoProfile
        :user="user"
        :selectedTypeName="selectedTypeName"
        :selectedCart="selectedCart"
        :selectedPackage="selectedPackage"
      />

      <!-- projectProfile -->
      <OlabProjectProfile :user="user" :project="selectedProject" />

      <!-- cartProfile -->
      <OlabCartProfile
        :user="user"
        :selectedProject="selectedProject"
        :selectedCart="selectedCart"
        :statusObj="statusObj"
        :errorObj="errorObj"
      />

      <!-- packageProfile -->
      <OlabPackageProfile
        :user="user"
        :selectedPackage="selectedPackage"
        :statusObj="statusObj"
        :errorObj="errorObj"
      />

      <div class="modal fade" id="addNewItem">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">
                Add:
                <span class="text-dark text-truncate">
                  {{ selectedTypeName }}
                </span>
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <!-- .modal-header -->
            <div
              v-if="dbInfo.db_configs && dbInfo.db_configs.seq_origins"
              class="modal-body"
            >
              <form v-on:submit.prevent="onSubmit">
                <fieldset class="form-group">
                  <!-- <legend>{{ selectedType }} Info</legend> -->
                  <div v-if="selectedType === 'package'" class="form-group">
                    <label class="form-control-label">
                      <span>Package Type:</span>
                    </label>
                    <div class="my-1">
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          value="library_sequence"
                          v-model="item.package_seq_type"
                        />
                        <label class="form-check-label">
                          Library Sequence
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          value="genome"
                          v-model="item.package_seq_type"
                        />
                        <label class="form-check-label">
                          Genome
                        </label>
                      </div>
                    </div>
                    <div v-if="item.package_seq_type === 'library_sequence'">
                      <select
                        class="form-control"
                        v-bind:class="item.getValidState(item.package_type)"
                        v-model="item.package_type"
                      >
                        <option disabled value="null">Please select one</option>
                        <option value="gene">LG - Gene Library</option>
                        <option value="arm">LH - Homology Arm Library</option>
                        <option value="promoter">LP - Promoter Library</option>
                        <option value="rbs">LR - RBS Library</option>
                        <option value="terminator"
                          >LT - Terminator Library</option
                        >
                        <option value="pgst">LW - PGsT Library</option>
                      </select>
                    </div>
                    <div v-else>
                      <select
                        class="form-control"
                        v-bind:class="item.getValidState(item.package_type)"
                        v-model="item.package_type"
                      >
                        <option disabled value="null">Please select one</option>
                        <option value="gene">Gene</option>
                        <option value="promoter">Promoter</option>
                        <option value="terminator">Terminator</option>
                      </select>
                    </div>
                  </div>
                  <div
                    v-show="
                      selectedType === 'library_sequence' ||
                        (selectedType === 'package' &&
                          item.package_seq_type === 'library_sequence')
                    "
                    class="form-group"
                  >
                    <button
                      class="btn btn-primary"
                      type="button"
                      data-toggle="collapse"
                      data-target="#bulkUploadPanel"
                      aria-expanded="false"
                      aria-controls="bulkUploadPanel"
                      v-on:click="bulkUploadState = !bulkUploadState"
                    >
                      Bulk Upload
                    </button>
                    <div class="collapse" id="bulkUploadPanel">
                      <div class="card card-body">
                        <div class="text-center">
                          <span class="px-3">Download:</span>
                          <a
                            href="templates/libseqs_template.csv"
                            target="_blank"
                            class="card-link"
                            download
                            >CSV template for bulk upload Library Sequences</a
                          >
                        </div>
                      </div>
                      <div
                        class="card-footer text-center bg-warning bulk-upload-note"
                      >
                        <div class="text-center h6">Note</div>
                        <div
                          v-if="selectedType === 'library_sequence'"
                          class="px-2 text-left"
                        >
                          1) Duplicate sequence found within the project and of
                          the same library will not be added to the library.
                        </div>
                        <div v-else class="px-2 text-left">
                          1) Duplicate sequence found within the project and of
                          the same library will not be added to the library but
                          will be marked in the package's comps listing.
                        </div>
                        <div class="px-2 text-left">
                          2) Matched deleted sequence will be undeleted and the
                          new sequence will be treated as duplicate.
                          <br />
                          3) Unresolved Sequence_Origin will be classified as
                          Undefined.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="
                      (selectedType === 'library_sequence' &&
                        !bulkUploadState) ||
                        selectedType !== 'library_sequence'
                    "
                    class="form-group"
                  >
                    <label class="form-control-label">
                      <span>Name:</span>
                    </label>
                    <input
                      class="form-control"
                      v-bind:class="item.getValidState(item.name)"
                      type="text"
                      v-model="item.name"
                    />
                    <!-- <div class="invalid-feedback">{{ invalidFB.name }}</div> -->
                  </div>
                  <div
                    v-if="
                      selectedProject &&
                        (selectedType === 'cart' ||
                          selectedType === 'package' ||
                          (selectedType === 'library_sequence' &&
                            !bulkUploadState))
                    "
                    class="form-group"
                  >
                    <label class="form-control-label">Project ID:</label>
                    <span class="ml-1">{{ selectedProjectInfo }}</span>
                  </div>
                  <div
                    v-if="
                      !bulkUploadState && selectedType === 'library_sequence'
                    "
                    class="form-group"
                  >
                    <label class="form-control-label">
                      <span>Sequence Origin:</span>
                    </label>
                    <div
                      v-bind:class="item.getValidState(item.seq_origin)"
                      v-bind:style="seqOriginStyle"
                    >
                      <treeselect
                        class="vue-treeselect"
                        v-model="seqOriginID"
                        :multiple="false"
                        :disableFuzzyMatching="true"
                        :disable-branch-nodes="true"
                        :options="dbInfo.db_configs.seq_origins"
                        @select="seqOriginSelectChange"
                        placeholder="Select a Sequence Origin ..."
                      />
                    </div>
                    <div class="invalid-feedback">
                      Sequence Origin is a required field
                    </div>
                    <!-- <span>selected = {{ item.seq_origin }}</span> -->
                  </div>
                  <div v-if="selectedType === 'project'" class="form-group">
                    <label class="form-control-label">
                      <span>Start Date:</span>
                    </label>
                    <input
                      class="form-control"
                      v-bind:class="item.getValidState(item.start_date)"
                      type="date"
                      v-model="item.start_date"
                    />
                    <!-- <div class="invalid-feedback">{{ invalidFB.startDate }}</div> -->
                  </div>
                  <div v-if="selectedType === 'project'" class="form-group">
                    <label class="form-control-label">End Date:</label>
                    <input
                      class="form-control"
                      type="date"
                      v-model="item.end_date"
                    />
                  </div>
                  <div
                    v-if="
                      !bulkUploadState &&
                        selectedType === 'library_sequence' &&
                        dbInfo &&
                        dbInfo.db_configs &&
                        dbInfo.db_configs.libraries
                    "
                    class="form-group"
                  >
                    <label class="form-control-label">
                      <span>Library ID:</span>
                    </label>
                    <select
                      class="form-control"
                      v-bind:class="item.getValidState(item.library_id)"
                      v-model="item.library_id"
                    >
                      <option disabled value="null">Please select one</option>
                      <option
                        v-for="lll in dbInfo.db_configs.libraries"
                        :key="lll.olab_id"
                        >{{ lll.olab_id }} - {{ lll.name }}</option
                      >
                    </select>
                    <!-- <div class="invalid-feedback">{{ invalidFB.libraryID }}</div> -->
                    <!-- <span>selected = {{item.library_id}}</span> -->
                  </div>
                  <div
                    v-if="
                      !bulkUploadState && selectedType === 'library_sequence'
                    "
                    class="form-group"
                  >
                    <label class="form-control-label">Source:</label>
                    <select
                      class="form-control"
                      v-bind:class="item.getValidState(item.source)"
                      v-model="item.source"
                    >
                      <option disabled value="null">Please select one</option>
                      <option value="inhouse">inhouse</option>
                      <option value="public">public</option>
                    </select>
                    <!-- <span>selected = {{item.source}}</span> -->
                  </div>
                  <div
                    v-if="
                      !bulkUploadState && selectedType === 'library_sequence'
                    "
                    class="form-group"
                  >
                    <label for="seq" class="form-control-label">
                      <span>Sequence:</span>
                    </label>
                    <textarea
                      class="form-control"
                      v-bind:class="item.getSeqValidState()"
                      id="seq"
                      v-model="item.seq"
                    ></textarea>
                    <div class="invalid-feedback">
                      {{ item.invalidSeqFeedback }}
                    </div>
                    <small class="form-text text-muted" id="seq_help"
                      >Note: Sequence cannot be modified once it is
                      created.</small
                    >
                  </div>
                  <div
                    v-if="
                      !bulkUploadState && selectedType === 'library_sequence'
                    "
                    class="form-group"
                  >
                    <span>Length:</span>
                    <span class="h5 text-dark">{{ itemSeqLen }}</span>
                  </div>
                  <div class="form-group">
                    <label class="form-control-label">Creator:</label>
                    <span class="ml-1">{{ item.creator }}</span>
                  </div>
                  <div
                    v-if="selectedType === 'project' && item.ra_locks"
                    class="form-group"
                  >
                    <label class="form-control-label">
                      <span>Public Access:</span>
                    </label>
                    <div class="form-check form-check-inline mx-2">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        v-model="item.ra_locks.public"
                      />
                    </div>
                  </div>
                  <div
                    v-if="
                      (selectedType === 'library_sequence' &&
                        bulkUploadState) ||
                        (selectedType === 'package' &&
                          item.package_seq_type === 'library_sequence' &&
                          bulkUploadState)
                    "
                    class="form-group"
                  >
                    <div class="mb-2">
                      <label class="form-control-label mr-2"
                        >Library Sequences data (csv):</label
                      >
                      <input
                        id="inputCSVFile"
                        type="file"
                        accept=".csv"
                        v-on:change="changeFile($event, item, 'CSV')"
                      />
                    </div>
                  </div>
                  <div
                    v-if="
                      (selectedType === 'library_sequence' &&
                        !bulkUploadState) ||
                        selectedType !== 'library_sequence'
                    "
                    class="form-group"
                  >
                    <label class="form-control-label">Description:</label>
                    <textarea
                      class="form-control"
                      v-model="item.desc"
                    ></textarea>
                  </div>
                </fieldset>
                <!-- .form-group -->
              </form>
            </div>
            <!-- .modal-body -->

            <div class="modal-footer">
              <button
                type="button"
                v-bind:disabled="itemAddIsDisabled == true"
                class="btn btn-primary"
                v-on:click="handleAddItem(item)"
              >
                <span
                  v-if="statusObj.progress"
                  class="spinner-border spinner-border-sm text-light"
                ></span>
                Add
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
            <!-- .modal-footer -->
          </div>
          <!-- .modal-content -->
        </div>
        <!-- .modal-dialog .modal-lg-->
      </div>
      <!-- .modal fade addNewItem -->

      <div v-if="selectedType === 'strain' || selectedType === 'plasmid'">
        <CompDBGeneList
          v-if="user"
          :user="user"
          :dbInfo="dbInfo"
          :genes="selected.genes"
          :saveStatus="saveStatus"
          :statusObj="statusObj"
          :errorObj="errorObj"
          :selectedType="selectedType"
          :selectedProject="selectedProject"
          :selectedCart="selectedCart"
          :selectedPackage="selectedPackage"
          :cartIsFull="cartIsFull"
          :packageIsFull="packageIsFull"
          @doAddToComps="doAddToComps"
        />
      </div>
      <div v-else>
        <CompDBItemList
          v-if="user"
          :user="user"
          :dbInfo="dbInfo"
          :statusObj="statusObj"
          :errorObj="errorObj"
          :selectedItems="selected.items"
          :selectedType="selectedType"
          :selectedTypeName="selectedTypeName"
          :item="item"
          :selectedProject="selectedProject"
          :selectedCart="selectedCart"
          :selectedPackage="selectedPackage"
          :cartIsFull="cartIsFull"
          :packageIsFull="packageIsFull"
          @doSelectProject="doSelectProject"
          @doSelectCart="doSelectCart"
          @doAddToComps="doAddToComps"
          @doSelectPackage="doSelectPackage"
        />
      </div>
      <div class="my-5">
        <nav aria-label="Page navigation">
          <ul class="pagination justify-content-center">
            <li class="page-item">
              <button
                type="button"
                v-bind:disabled="selected.prevIsDisabled == true"
                class="btn btn-primary"
                v-on:click="handlePrev()"
              >
                &lt;
              </button>
            </li>
            <li class="mx-3">{{ selected.pageNumber }}</li>
            <li class="page-item">
              <button
                type="button"
                v-bind:disabled="selected.nextIsDisabled == true"
                class="btn btn-primary"
                v-on:click="handleNext()"
              >
                &gt;
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div v-else class="mt-5">
      <div class="text-secondary text-center">
        <div class="h2">Inactivity Time Out</div>
        <router-link
          class="btn btn-outline-primary mt-2"
          to="/login"
          v-if="!user"
          >Log in</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import CompDBGeneList from "@/components/CompDBGeneList";
import CompDBItemList from "@/components/CompDBItemList";
import OlabUserProfile from "@/components/OlabUserProfile";
import OlabInfoProfile from "@/components/OlabInfoProfile";
import OlabProjectProfile from "@/components/OlabProjectProfile";
import OlabCartProfile from "@/components/OlabCartProfile";
import OlabPackageProfile from "@/components/OlabPackageProfile";
import { OlabAxios } from "../olab/olabaxios.js";
import { OlabItem } from "../olab/olabitem.js";
import { OlabUtils } from "../olab/olabutils.js";
import { OlabSearch } from "../olab/olabsearch.js";
import $ from "jquery";
// import treeselect component
import Treeselect from "@riophae/vue-treeselect";
// import treeselect CSS styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  name: "compdb",
  data: function() {
    return {
      title: "Component",
      searchTerm: "",
      filterKey: "merge",
      filterDir: "asc",
      selectedType: "strain",
      lastSelectedType: null,
      selectedTypeName: "Gene (Strain)",
      includeDeleted: false,
      item: new OlabItem(),
      selected: {
        genes: null,
        items: null,
        sortBy: "olab_id",
        pageNumber: 1,
        nextIsDisabled: true,
        prevIsDisabled: true
      },
      cartItems: [],
      bulkUploadState: false,
      seqOriginID: null,
      seqOriginStyle: {
        borderStyle: "none none solid none",
        borderColor: "#d9534f"
      }
    };
  },
  components: {
    Treeselect,
    FontAwesomeIcon,
    CompDBGeneList,
    CompDBItemList,
    OlabUserProfile,
    OlabInfoProfile,
    OlabProjectProfile,
    OlabCartProfile,
    OlabPackageProfile
  },
  props: [
    "user",
    "dbInfo",
    "saveStatus",
    "selectedProject",
    "selectedCart",
    "selectedPackage",
    "statusObj",
    "errorObj"
  ],
  mounted: function() {
    OlabUtils.infoLog("CompDB mounted ...");
    this.loadGenomes();
  },
  computed: {
    itemAddIsDisabled: function() {
      return !this.item.isItemValid(this.selectedType, true);
    },
    itemSeqLen: function() {
      return this.item.seq ? this.item.seq.length : "--";
    },
    cartTotal: function() {
      let sum = 0;
      if (this.selectedCart != null && this.selectedCart.comps != null) {
        for (let idx in this.selectedCart.comps) {
          sum =
            sum +
            this.selectedCart.comps[idx].length *
              this.selectedCart.comps[idx].qty;
        }
      }
      return sum;
    },
    cartQty: function() {
      let qty = 0;
      if (this.selectedCart != null && this.selectedCart.comps != null) {
        for (let idx in this.selectedCart.comps) {
          qty = qty + this.selectedCart.comps[idx].qty;
        }
        if (qty >= OlabUtils.MAX_ITEMS_PER_CART) {
          return "FULL";
        }
      }
      return qty;
    },
    cartIsFull: function() {
      return this.cartQty === "FULL" ? true : false;
    },
    packageTotal: function() {
      let sum = 0;
      if (this.selectedPackage != null && this.selectedPackage.comps != null) {
        for (let idx in this.selectedPackage.comps) {
          sum =
            sum +
            this.selectedPackage.comps[idx].length *
              this.selectedPackage.comps[idx].qty;
        }
      }
      return sum;
    },
    packageQty: function() {
      let qty = 0;
      if (this.selectedPackage != null && this.selectedPackage.comps != null) {
        for (let idx in this.selectedPackage.comps) {
          qty = qty + this.selectedPackage.comps[idx].qty;
        }
        if (qty >= OlabUtils.MAX_ITEMS_PER_PACKAGE) {
          return "FULL";
        }
      }
      return qty;
    },
    packageIsFull: function() {
      return this.packageQty === "FULL" ? true : false;
    },
    selectedProjectInfo: function() {
      return `${this.selectedProject.olab_id} - ${this.selectedProject.name}`;
    }
  },
  filters: {
    searchFilter: function(value) {
      switch (value) {
        case "merge":
          return "Merge";
        case "olab_id":
          return "OLAB ID";
        case "name":
          return "Name";
        case "creator":
          return "Creator";
        case "locus_tag":
          return "Locus";
        case "old_locus_tag":
          return "Old Locus";
        case "seq":
          return "Sequence";
      }
    },
    convertToK: function(value) {
      if (value > 999) {
        value = Number.parseFloat(value / 1000).toFixed(2) + "K";
      }
      return value;
    }
  },
  methods: {
    seqOriginSelectChange: function() {
      // console.log("seqOriginSelectChange: seqOriginID =", this.seqOriginID);
    },
    handlePrev: function() {
      // console.log("handlePrev: selectedType =", this.selectedType);
      const searchGenes =
        this.selectedType === "strain" || this.selectedType === "plasmid"
          ? true
          : false;
      OlabSearch.doPrev(
        searchGenes,
        this.selectedType,
        this.searchTerm,
        this.includeDeleted,
        null,
        this.selected
      );
    },
    handleNext: function() {
      // console.log("handleNext: selectedType =", this.selectedType);
      const searchGenes =
        this.selectedType === "strain" || this.selectedType === "plasmid"
          ? true
          : false;
      OlabSearch.doNext(
        searchGenes,
        this.selectedType,
        this.searchTerm,
        this.includeDeleted,
        null,
        this.selected
      );
    },
    updateSelectedPackage: async function() {
      // console.log(
      //   "CompDB.updateSelectedPackage = ",
      //   this.selectedPackage.olab_id
      // );
      this.$emit("setSaveStatus", true);
      await OlabAxios.savePackage(
        this.selectedPackage,
        this.statusObj,
        this.errorObj
      );
      this.$emit("setSaveStatus", false);
    },
    deletePackageComp: function(idx) {
      if (this.selectedPackage.comps[idx].qty > 1) {
        this.selectedPackage.comps[idx].qty--;
      } else {
        this.selectedPackage.comps.splice(idx, 1);
      }
      this.updateSelectedPackage();
    },
    requestTypeChange: function(value) {
      this.selectedType = value;
      OlabUtils.infoLog("requestTypeChange " + this.selectedType);
      if (this.selectedType !== this.lastSelectedType) {
        // Reset searchTerm and bulkUploadState when type changed
        this.searchTerm = "";
        this.bulkUploadState = false;

        OlabUtils.infoLog("Watch - selectedType = " + this.selectedType);

        switch (this.selectedType) {
          case "project":
            this.selectedTypeName = "Project";
            this.searchTerm = "";
            this.doSearchTerm(this.searchTerm, this.selectedType);
            break;
          case "cart":
            this.selectedTypeName = "Cart";
            this.searchTerm = "";
            this.doSearchTerm(this.searchTerm, this.selectedType);
            break;
          case "package":
            this.selectedTypeName = "Package";
            this.searchTerm = "";
            this.doSearchTerm(this.searchTerm, this.selectedType);
            break;
          case "strain":
            this.selectedTypeName = "Gene (Strain)";
            this.searchTerm = "";
            this.doSearchTerm(this.searchTerm, this.selectedType);
            break;
          case "plasmid":
            this.selectedTypeName = "Gene (Plasmid)";
            this.searchTerm = "";
            this.doSearchTerm(this.searchTerm, this.selectedType);
            break;
          case "library_sequence":
            this.selectedTypeName = "Sequence";
            this.searchTerm = "";
            this.doSearchTerm(this.searchTerm, this.selectedType);
            break;
        }
        this.lastSelectedType = this.selectedType;
      }
    },
    requestKeyChange: function(value) {
      this.filterKey = value;
      this.doSearchTerm(this.searchTerm, this.selectedType);
    },
    loadGenomes: function() {
      // Load selected.genes if it is null
      if (this.selected.genes == null) {
        this.searchTerm = "";
        this.selectedType = "strain";
        this.doSearchTerm(this.searchTerm, this.selectedType);
      }
    },
    changeFile: function(e, item, fileType) {
      const selectedFile = e.target.files[0];
      if (fileType === "CSV") {
        item.csv_file = selectedFile ? selectedFile : null;
      }
      console.log(
        "changeFile - selectedFile =",
        selectedFile,
        ", fileType =",
        fileType,
        ", item =",
        item
      );
    },
    prepAddItem: function(item) {
      // console.log("prepAddItem");
      // Initialize and reset states
      item.initItem();
      this.seqOriginID = null;
      // initialize creator with user's email
      item.creator = this.user.email;
      if (
        this.selectedProject &&
        (this.selectedType === "cart" ||
          this.selectedType === "package" ||
          this.selectedType === "library_sequence")
      ) {
        item.project_id = this.selectedProject.olab_id;
      }

      if (
        this.selectedProject &&
        (this.selectedType === "package" ||
          this.selectedType === "library_sequence")
      ) {
        const csvFileHandle = document.getElementById("inputCSVFile");
        if (csvFileHandle) {
          csvFileHandle.value = "";
        }
      }
    },
    handleAddItem: async function(item) {
      // console.log("Add item = ", item, ", selectedType = ", this.selectedType);

      const cacheLibID = item.library_id;
      if (this.selectedType == "library_sequence") {
        if (this.bulkUploadState) {
          // console.log("bulkUploadState: item.csv_file =", item.csv_file);
        } else {
          // Remove library name from id field
          OlabUtils.infoLog("item.library_id = " + item.library_id);
          var libraryID = OlabUtils.subStrB4Space(item.library_id);
          OlabUtils.infoLog("libraryID = " + libraryID);
          item.library_id = libraryID;

          // Compute seq's gene_stats
          item.computeGeneStats();
        }
      }

      // Set progress message and status indicator
      this.statusObj.progress = true;
      const res = await OlabAxios.addItem(
        item,
        this.selectedType,
        this.selected.items,
        this.statusObj,
        this.errorObj
      );
      if (res && res.status === "duplicate") {
        // console.log(res);
        // Restore item's library_id
        item.library_id = cacheLibID;
        item.setSeqExisted(true, res.message);
      } else {
        $("#addNewItem").modal("hide");
      }
    },
    doSelectProject: function(proj) {
      OlabUtils.infoLog("doSelectProject proj = " + proj);
      this.$emit("selectProject", proj);
    },
    doSelectCart: function(cart) {
      OlabUtils.infoLog("doSelectCart cart = " + cart);
      this.$emit("selectCart", cart);
    },
    doAddToComps: function({ item, useSeqAs }) {
      // console.log("doAddToComps item = ", item, ", useSeqAs = ", useSeqAs);

      let comps;
      if (this.selectedCart) {
        comps = this.selectedCart.comps;
      } else if (this.selectedPackage) {
        comps = this.selectedPackage.comps;
      } else {
        OlabUtils.infoLog(
          "doAddToComps: Error! No selectedCart nor selectedPackage"
        );
      }

      // OlabUtils.infoLog("doAddToComps: comps.length = " + comps.length);
      // comps.forEach(ele => {
      //   OlabUtils.infoLog("  olab_id = " + ele.olab_id + ", useSeqAs = " + ele.use_seq_as + ", qty = " + ele.qty);
      // });
      let foundIndex = 0;
      for (; foundIndex < comps.length; foundIndex++) {
        if (
          comps[foundIndex].olab_id == item.olab_id &&
          comps[foundIndex].use_seq_as.match(useSeqAs)
        ) {
          // foundIndex is the index to the item in comps
          // console.log("Found ", foundIndex);
          break;
        }
      }

      // console.log("foundIndex = ", foundIndex);
      if (foundIndex < comps.length) {
        if (this.selectedCart) {
          // console.log(" comp.olab_id = " + comps[foundIndex].olab_id);
          comps[foundIndex].qty++;
          comps[foundIndex].available_count++;
        } else if (this.selectedPackage) {
          // TODO: Copy Undelete dialog approach to inform user of duplicate item
          // TODO: Warning user and stop processing since item already in comps
          alert(
            `Item found in package: ${comps[foundIndex].olab_id}! Cannot add the same item in the package`
          );
          return;
        }
      } else {
        // console.log("Create new comp");
        let cObj = null;
        if (this.selectedCart) {
          // console.log("Create new comp for cart");
          cObj = {
            olab_id: item.olab_id,
            olab_type: item.olab_type,
            name: item.name,
            locus_tag: item.locus_tag ? item.locus_tag : null,
            old_locus_tag: item.old_locus_tag ? item.old_locus_tag : null,
            length: item.length ? item.length : null,
            seq: null,
            use_seq_as: useSeqAs,
            qty: 1,
            available_count: 1,
            used_count: 0
          };
        } else {
          // console.log("Create new comp for package");
          cObj = {
            olab_id: item.olab_id,
            olab_type: item.olab_type,
            name: item.name,
            locus_tag: item.locus_tag ? item.locus_tag : null,
            old_locus_tag: item.old_locus_tag ? item.old_locus_tag : null,
            length: item.length ? item.length : null,
            seq: item.olab_type == "strain" ? item.sequence.seq : item.seq,
            use_seq_as: useSeqAs,
            qty: 1
          };
          if (!item.length) {
            cObj.length = cObj.seq.length;
          }
        }
        // console.log("cObj =", cObj);
        comps.push(cObj);
      }
      if (this.selectedCart) {
        OlabAxios.saveCart(this.selectedCart, this.statusObj, this.errorObj);
      } else if (this.selectedPackage) {
        this.updateSelectedPackage();
      }
    },
    doSelectPackage: function(pkg) {
      OlabUtils.infoLog("doSelectPackage pkg = " + pkg);
      this.$emit("selectPackage", pkg);
    },
    doSearchTerm: function(sTerm, sType) {
      const searchGenes =
        sType === "strain" || sType === "plasmid" ? true : false;

      OlabSearch.doSearchTerm(
        searchGenes,
        sTerm,
        sType,
        this.includeDeleted,
        this.filterKey,
        null,
        this.selected
      );
    },
    closeStatus: function() {
      // console.log("Close Status Alert ...");
      this.statusObj.message = null;
      this.statusObj.progress = false;
    },
    closeError: function() {
      // console.log("Close Error Alert ...");
      this.errorObj.message = null;
    },
    onSubmit() {
      // This is a NOP to prevent page reload on return key
      // console.log("After Submit");
    }
  },
  watch: {
    searchTerm: function() {
      // console.log("Watch - searchTerm = ", this.searchTerm);
      this.searchTerm = OlabUtils.cleanSearchTem(this.searchTerm);
      this.doSearchTerm(this.searchTerm, this.selectedType);
    },
    seqOriginID: function() {
      // console.log("watch: seqOriginID =", this.seqOriginID);
      let tmpSO = null;
      this.seqOriginStyle.borderColor = "#d9534f";
      for (const so of this.dbInfo.db_configs.seq_origins) {
        if (so.id == this.seqOriginID) {
          tmpSO = so.label;
          this.seqOriginStyle.borderColor = "#5cb85c";
        }
      }
      this.item.seq_origin = tmpSO;
    }
  }
};
</script>

<style scoped>
.bulk-upload-note {
  /* background-color: rgb(236, 149, 171); */
  font-size: 0.875rem;
  line-height: 1.25rem;
}
</style>

<template>
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <g transform="translate(100,100)">
        <path
          :fill="computedFill"
          transform="scale(0.5)"
          d="M528.12 301.319l47.273-208C578.806 78.301 567.391 64 551.99 64H159.208l-9.166-44.81C147.758 8.021 137.93 0 126.529 0H24C10.745 0 0 10.745 0 24v16c0 13.255 10.745 24 24 24h69.883l70.248 343.435C147.325 417.1 136 435.222 136 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-15.674-6.447-29.835-16.824-40h209.647C430.447 426.165 424 440.326 424 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-22.172-12.888-41.332-31.579-50.405l5.517-24.276c3.413-15.018-8.002-29.319-23.403-29.319H218.117l-6.545-32h293.145c11.206 0 20.92-7.754 23.403-18.681z"
        ></path>
        <text
          :x="computedTextPosX"
          :y="textPosY"
          fill="#f80"
          style="font-size:100px;font-weight:normal;text-align:start;text-anchor:start;fill-opacity:1;stroke:none;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;font-family:Arial"
          id="olab-text"
          xml:space="preserve"
        >
          {{ computedQty }}
        </text>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "olabcartsvggen",
  data: function() {
    return {
      title: "OlabCartSVGGen",
      textPosX: 140,
      textPosY: 140
    };
  },
  props: ["comps", "fillColor"],
  computed: {
    computedFill: function() {
      switch (this.fillColor) {
        case "compdb":
          return "#ffeedd";
        default:
          return "#eeeeee";
      }
    },
    computedTextPosX: function() {
      if (this.computedQty > 9) {
        return this.textPosX - 25;
      }
      return this.textPosX;
    },
    computedQty: function() {
      let total = 0;
      if (this.comps) {
        for (let i = 0; i < this.comps.length; i++) {
          total += this.comps[i].qty;
        }
      }
      return total;
    }
  }
};
</script>

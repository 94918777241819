// This static helper class for handling backbone map
// This map is to highlight the portion of backbone selected
class BackboneMap {
  static createBBRegionArcs(bbRegionArr) {
    const arcs = [];
    bbRegionArr.forEach(bbRObj => {
      const bbRArc = {
        start: bbRObj.arcFrom,
        end: bbRObj.arcTo,
        len: bbRObj.arcTo - bbRObj.arcFrom + 1,
        name: bbRObj.name,
        fill: bbRObj.name === "_NotAGene" ? "#fbfafc" : "#90EE90"
      };
      arcs.push(bbRArc);
    });
    return arcs;
  }

  static computeBBRegion(mapThis, re1, re2, dir, chrmLen) {
    // console.log("BackboneMap: re1CS =", re1.cutSite, ", re2CS =", re2.cutSite);
    // console.log("BackboneMap: dir =", dir, ", chrmLen =", chrmLen);
    const bbRegionArr = [];
    if (re1 && re2) {
      const re1CS = re1.cutSite;
      const re2CS = re2.cutSite;
      if (dir === "clockwise") {
        // console.log("BackboneMap.computeBBRegion - dir = CW");
        if (re1CS > 1) {
          bbRegionArr.push({
            olab_id: "",
            name: "_NotAGene", // TODO: Change to _NotDisplay
            arcFrom: 1,
            arcTo: re1CS - 1
          });
        }
        bbRegionArr.push({
          olab_id: "BBR-1",
          name: "Backbone Region",
          arcFrom: re1CS,
          arcTo: re2CS
        });
        if (re2CS < chrmLen) {
          bbRegionArr.push({
            olab_id: "",
            name: "_NotAGene", // TODO: Change to _NotDisplay
            arcFrom: re2CS + 1,
            arcTo: chrmLen
          });
        }
      } else {
        // "counter-clockwise"
        // console.log("BackboneMap.computeBBRegion - dir = CCW");
        if (re1CS > 1) {
          bbRegionArr.push({
            olab_id: "BBR-1",
            name: "Backbone Region",
            arcFrom: 1,
            arcTo: re1CS
          });
        }
        bbRegionArr.push({
          olab_id: "",
          name: "_NotAGene", // TODO: Change to _NotDisplay
          arcFrom: re1CS + 1,
          arcTo: re2CS - 1
        });
        bbRegionArr.push({
          olab_id: "BBR-2",
          name: "Backbone Region",
          arcFrom: re2CS,
          arcTo: chrmLen
        });
      }
    }
    return bbRegionArr;
  }
}

export { BackboneMap };

// import { OlabItem } from "../olab/olabitem.js";
// import { OlabGene } from "../olab/olabgene.js";
import { OlabUtils } from "./olabutils.js";

class OlabSearch {
  // 20 counts per page
  static cntPerPage = 20;
  static maxCount = 2000;

  constructor() {
    OlabUtils.infoLog("OlabSearch.constructor() ...");
    this.ref = null;
    this.lastStopRec = null;
  }

  static initSelected(selected) {
    // selected.genes = null;
    // selected.items = null;
    selected.sortBy = "olab_id";
    selected.pageNumber = 1;
    selected.nextIsDisabled = true;
    selected.prevIsDisabled = true;
  }

  static createGeneSearchBody(sString, sField, gRef, page, limit) {
    return {
      searchType: "gene",
      searchString: sString ? sString : "",
      searchField: sField ? sField : "olab_id",
      genesRef: gRef ? gRef : null,
      page: page * 1 || 1,
      limit: limit * 1 || OlabSearch.cntPerPage
    };
  }

  static createItemSearchBody(
    sType,
    sString,
    sField,
    includeDeleted,
    auxID,
    page,
    limit
  ) {
    return {
      searchType: sType ? sType : "project",
      searchString: sString ? sString : "",
      searchField: sField ? sField : "olab_id",
      includeDeleted: includeDeleted ? includeDeleted : false,
      auxID: auxID ? auxID : null,
      page: page * 1 || 1,
      limit: limit * 1 || OlabSearch.cntPerPage
    };
  }

  static async doPage(sGenes, sType, sTerm, includeDeleted, idRef, selected) {
    // console.log(
    //   "doPage: sType =",
    //   sType,
    //   ", sTerm = ",
    //   sTerm,
    //   ", includeDeleted =",
    //   includeDeleted,
    //   ", idRef = ",
    //   idRef
    // );
    // console.log("selected = ", selected);

    const sFilter = selected.sortBy ? selected.sortBy : "olab_id";
    if (sGenes) {
      let gnRef = idRef;
      if (idRef === null && sType === "strain") {
        gnRef = "strain";
      } else if (idRef === null && sType === "plasmid") {
        gnRef = "plasmid";
      }
      const searchBody = OlabSearch.createGeneSearchBody(
        sTerm,
        sFilter,
        gnRef,
        selected.pageNumber,
        OlabSearch.cntPerPage
      );
      // console.log("doPage: sFilter =", sFilter, ", searchBody =", searchBody);
      const genes = await OlabUtils.getGenes(searchBody);

      if (genes.length >= OlabSearch.cntPerPage) {
        selected.sortBy = sFilter;
        selected.nextIsDisabled = false;
      } else {
        // No more data for next page
        selected.nextIsDisabled = true;
      }
      // console.log("genes.len = " + genes.length);
      selected.genes = genes;
    } else {
      const searchBody = OlabSearch.createItemSearchBody(
        sType,
        sTerm,
        sFilter,
        includeDeleted,
        idRef,
        selected.pageNumber,
        OlabSearch.cntPerPage
      );

      const items = await OlabUtils.getItems(sType, searchBody);
      if (items.length >= OlabSearch.cntPerPage) {
        selected.sortBy = sFilter;
        selected.nextIsDisabled = false;
      } else {
        // No more data for next page
        selected.nextIsDisabled = true;
      }
      OlabUtils.infoLog("items.len = " + items.length);
      selected.items = items;
    }
  }

  static async doPrev(sGenes, sType, sTerm, includeDeleted, idRef, selected) {
    OlabUtils.infoLog("** doPrev - sType = " + sType);

    selected.pageNumber--;
    if (selected.pageNumber <= 1) {
      selected.prevIsDisabled = true;
    }
    await OlabSearch.doPage(
      sGenes,
      sType,
      sTerm,
      includeDeleted,
      idRef,
      selected
    );
  }

  static async doNext(sGenes, sType, sTerm, includeDeleted, idRef, selected) {
    OlabUtils.infoLog("** doNext - sType = " + sType);

    selected.pageNumber++;
    selected.prevIsDisabled = false;
    await OlabSearch.doPage(
      sGenes,
      sType,
      sTerm,
      includeDeleted,
      idRef,
      selected
    );
  }

  static doSearchTerm(
    searchGenes,
    sTerm,
    sType,
    includeDeleted,
    filterKey,
    idRef,
    selected
  ) {
    // console.log("includeDelete =", includeDeleted);
    // Initialize selected at the beginning of a new sTerm
    OlabSearch.initSelected(selected);

    // Plasmid needs to support for tag query that requires the removal
    // of sTerm's limit
    if (sType !== "plasmid") {
      // Limit sTerm to Firebase limit of about 1400 chars
      if (sTerm && sTerm.length > 1400) {
        sTerm = sTerm.substr(0, 1400);
      }
    }

    if (searchGenes) {
      if (sType === "genome") {
        OlabSearch.searchGenes(sTerm, filterKey, idRef, selected);
      } else if (sType === "strain") {
        const gnRef = idRef === null ? "strain" : idRef;
        OlabSearch.searchGenes(sTerm, filterKey, gnRef, selected);
      } else if (sType === "plasmid") {
        const gnRef = idRef === null ? "plasmid" : idRef;
        OlabSearch.searchGenes(sTerm, filterKey, gnRef, selected);
      }
    } else {
      OlabSearch.searchItems(
        sTerm,
        sType,
        includeDeleted,
        filterKey,
        idRef,
        selected
      );
    }
  }

  static async searchGenes(sTerm, sFilter, genesRef, selected) {
    // console.log("searchGenes - sTerm = ", sTerm);
    // console.log("sFilter =", sFilter, ", genesRef =", genesRef);
    if (sTerm == "") {
      sFilter = "olab_id";
    }

    if (sFilter === "seq") {
      // Note: seq for gene search is sequence.seq
      sFilter = "sequence.seq";
    }
    const searchBody = OlabSearch.createGeneSearchBody(
      sTerm,
      sFilter,
      genesRef,
      1,
      OlabSearch.cntPerPage
    );
    const genes = await OlabUtils.getGenes(searchBody);

    if (genes.length >= OlabSearch.cntPerPage) {
      selected.sortBy = sFilter;
      selected.nextIsDisabled = false;
    }
    OlabUtils.infoLog("genes.len = " + genes.length);
    selected.genes = genes;
  }

  static async searchItems(
    sTerm,
    sType,
    includeDeleted,
    sFilter,
    auxID,
    selected
  ) {
    // console.log(
    //   `searchItems : sTerm = ${sTerm}, includeDelete = ${includeDeleted}, sFilter = ${sFilter}, sType = ${sType}`
    // );
    const searchBody = OlabSearch.createItemSearchBody(
      sType,
      sTerm,
      sFilter,
      includeDeleted,
      auxID,
      1,
      OlabSearch.cntPerPage
    );
    const items = await OlabUtils.getItems(sType, searchBody);
    // const items = await OlabUtils.getProjects(searchBody);
    if (items.length >= OlabSearch.cntPerPage) {
      selected.sortBy = sFilter;
      selected.nextIsDisabled = false;
    }
    OlabUtils.infoLog("items.len = " + items.length);
    selected.items = items;
  }
}

export { OlabSearch };

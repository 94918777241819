import { OlabPrimer } from "./olabprimer.js";
import { OlabAATable } from "./olabaatable.js";
import { OlabUtils } from "./olabutils.js";

class OlabSNPSwap {
  constructor(gene) {
    OlabUtils.infoLog("OlabSNPSwap.constructor() ...");
    this.gene = gene;
    this.displayName = null;
    this.setDisplayName();
    this.position = 20;
    this.replaceWith = null;
    if (gene != null && gene.sequence != null) {
      this.replaceWith = this.gene.sequence.seq[20];
    }
    // set default size to 100
    this.armSize = 40;
    this.pmr3Pad = null;
    this.pmr5Pad = null;
    this.blockSeq = this.gene.sequence.seq.substr(0, 80);
    this.blockSeqPos = 20;
    this.aaSeqBefore = this.getAASeq(
      this.blockSeq,
      this.blockSeqPos,
      this.position,
      null
    );
    this.aaBefore = OlabAATable.lookupAA(this.aaSeqBefore);
    this.aaSeqAfter = this.getAASeq(
      this.blockSeq,
      this.blockSeqPos,
      this.position,
      this.replaceWith
    );
    this.aaAfter = OlabAATable.lookupAA(this.aaSeqAfter);
    this.aaSeqBefore1Color = "text-dark";
    this.aaSeqBefore2Color = "text-dark";
    this.aaSeqBefore3Color = "text-danger";
    this.aaSeqAfter1Color = "text-dark";
    this.aaSeqAfter2Color = "text-dark";
    this.aaSeqAfter3Color = "text-success";
    this.boxUS = {
      display_name: "U_" + this.displayName,
      seq: this.gene.sequence.seq.substr(0, 20),
      display_seq: "..." + this.gene.sequence.seq.substr(6, 14),
      pmrFwd: null,
      pmrRev: null
    };
    this.boxDS = {
      display_name: "D_" + this.displayName,
      seq: this.gene.sequence.seq.substr(20, 20),
      display_seq: this.gene.sequence.seq.substr(20, 14) + "...",
      pmrFwd: null,
      pmrRev: null
    };
    this.boxUS.pmrFwd = OlabPrimer.createFwdHalf(
      this.boxUS,
      this.displayName + "-01",
      20
    );
    this.boxUS.pmrRev = OlabPrimer.createRevFull(
      this.boxUS,
      this.boxDS,
      this.displayName + "-02",
      20,
      20
    );
    this.boxDS.pmrFwd = OlabPrimer.createFwdFull(
      this.boxDS,
      this.boxUS,
      this.displayName + "-03",
      20,
      20
    );
    this.boxDS.pmrRev = OlabPrimer.createRevHalf(
      this.boxDS,
      this.displayName + "-04",
      20
    );
  }

  setDisplayName() {
    this.displayName = null;
    if (this.gene != null) {
      this.displayName = this.gene.name;
      if (this.displayName == null || this.displayName == "") {
        this.displayName = this.gene.old_locus_tag;
      }
      if (this.displayName == null || this.displayName == "") {
        this.displayName = this.gene.locus_tag;
      }
    }
  }

  set(snp) {
    this.gene = snp.gene;
    this.position = snp.position;
    this.replaceWith = snp.replaceWith;
    this.armSize = snp.armSize;
    this.pmr3Pad = snp.pmr3Pad;
    this.pmr5Pad = snp.pmr5Pad;
    this.aaBefore = snp.aaBefore;
    this.aaAfter = snp.aaAfter;
    this.blockSeq = snp.blockSeq;
    this.blockSeqPos = snp.blockSeqPos;
    this.boxUS = snp.boxUS;
    this.boxDS = snp.boxDS;
  }

  isSwapped() {
    let snpChar = this.blockSeq.substr(this.blockSeqPos, 1);
    // OlabUtils.infoLog("isSwapped: snpChar = " + snpChar + ", replaceWith = " + this.replaceWith);
    return snpChar == this.replaceWith ? false : true;
  }

  setSeq(seq) {
    this.blockSeq = seq;
    this.blockSeqPos = seq.length / 2;
    // console.log(
    //   "setSeq: blockSeq = ",
    //   this.blockSeq,
    //   ", blockSeq.length = ",
    //   this.blockSeq.length,
    //   ", blockSeqPos = ",
    //   this.blockSeqPos,
    //   ", position = ",
    //   this.position
    // );

    this.aaSeqBefore = this.getAASeq(
      this.blockSeq,
      this.blockSeqPos,
      this.position,
      null
    );
    this.aaBefore = OlabAATable.lookupAA(this.aaSeqBefore);
    // console.log(
    //   "setSeq: aaSeqBefore = ",
    //   this.aaSeqBefore,
    //   ", aaBefore = ",
    //   this.aaBefore
    // );

    this.aaSeqAfter = this.getAASeq(
      this.blockSeq,
      this.blockSeqPos,
      this.position,
      this.replaceWith
    );
    this.aaAfter = OlabAATable.lookupAA(this.aaSeqAfter);
    // console.log(
    //   "setSeq: aaSeqAfter = ",
    //   this.aaSeqAfter,
    //   ", aaAfter = ",
    //   this.aaAfter
    // );

    this.boxUS.seq = this.blockSeq.substr(0, this.blockSeqPos);
    this.boxDS.seq =
      this.replaceWith +
      this.blockSeq.substr(this.blockSeqPos + 1, this.blockSeq.length);
    this.boxUS.display_seq =
      "..." + this.boxUS.seq.substr(this.boxUS.seq.length - 14, 14);
    this.boxDS.display_seq = this.boxDS.seq.substr(0, 14) + "...";

    this.boxUS.pmrFwd = OlabPrimer.createFwdHalf(
      this.boxUS,
      this.displayName + "-01",
      20
    );
    this.boxUS.pmrRev = OlabPrimer.createRevFull(
      this.boxUS,
      this.boxDS,
      this.displayName + "-02",
      20,
      20
    );
    this.boxDS.pmrFwd = OlabPrimer.createFwdFull(
      this.boxDS,
      this.boxUS,
      this.displayName + "-03",
      20,
      20
    );
    this.boxDS.pmrRev = OlabPrimer.createRevHalf(
      this.boxDS,
      this.displayName + "-04",
      20
    );

    // Handle Padding
    if (this.pmr3Pad) {
      this.boxDS.pmrRev.setPmrPad(this.pmr3Pad);
    }
    if (this.pmr5Pad) {
      this.boxUS.pmrFwd.setPmrPad(this.pmr5Pad);
    }
  }

  setPmr3Pad(pad) {
    OlabUtils.infoLog("OlabSNPSwap - setPmr3Pad: pad = " + pad);
    this.pmr3Pad = pad;
    this.boxDS.pmrRev.setPmrPad(pad);
  }

  setPmr5Pad(pad) {
    OlabUtils.infoLog("OlabSNPSwap - setPmr5Pad: pad = " + pad);
    this.pmr5Pad = pad;
    this.boxUS.pmrFwd.setPmrPad(pad);
  }

  getFullPrimers() {
    // box_name,box_size,
    // primer_name_fwd,primer_sequence_fwd,primer_length_fwd,primer_tm_fwd,
    // primer_name_rev,primer_sequence_rev,primer_length_rev,primer_tm_rev
    const resPmrs = [];
    const usFwd = this.boxUS.pmrFwd;
    const usRev = this.boxUS.pmrRev;
    const usPmr = {
      primer_tm_rev: parseFloat(usRev.tmWithPad.toFixed(2)),
      primer_length_rev: usRev.seqWithPad.length,
      primer_sequence_rev: usRev.seqWithPad,
      primer_name_rev: usRev.name,
      primer_tm_fwd: parseFloat(usFwd.tmWithPad.toFixed(2)),
      primer_length_fwd: usFwd.seqWithPad.length,
      primer_sequence_fwd: usFwd.seqWithPad,
      primer_name_fwd: usFwd.name,
      box_size: this.boxUS.seq.length,
      box_name: this.boxUS.display_name
    };
    resPmrs.push(usPmr);
    const dsFwd = this.boxDS.pmrFwd;
    const dsRev = this.boxDS.pmrRev;
    const dsPmr = {
      primer_tm_rev: parseFloat(dsRev.tmWithPad.toFixed(2)),
      primer_length_rev: dsRev.seqWithPad.length,
      primer_sequence_rev: dsRev.seqWithPad,
      primer_name_rev: dsRev.name,
      primer_tm_fwd: parseFloat(dsFwd.tmWithPad.toFixed(2)),
      primer_length_fwd: dsFwd.seqWithPad.length,
      primer_sequence_fwd: dsFwd.seqWithPad,
      primer_name_fwd: dsFwd.name,
      box_size: this.boxDS.seq.length,
      box_name: this.boxDS.display_name
    };
    resPmrs.push(dsPmr);
    return resPmrs;
  }

  getOrderPrimers() {
    // Oligo Name, Scale, Purification, Sequence (5' -> 3')
    const resPmrs = [];
    const usPmrFwd = {
      "Sequence (5' -> 3')": this.boxUS.pmrFwd.seqWithPad,
      Purification: "",
      Scale: "",
      "Oligo Name": this.boxUS.pmrFwd.name
    };
    resPmrs.push(usPmrFwd);
    const usPmrRev = {
      "Sequence (5' -> 3')": this.boxUS.pmrRev.seqWithPad,
      Purification: "",
      Scale: "",
      "Oligo Name": this.boxUS.pmrRev.name
    };
    resPmrs.push(usPmrRev);
    const dsPmrFwd = {
      "Sequence (5' -> 3')": this.boxDS.pmrFwd.seqWithPad,
      Purification: "",
      Scale: "",
      "Oligo Name": this.boxDS.pmrFwd.name
    };
    resPmrs.push(dsPmrFwd);
    const dsPmrRev = {
      "Sequence (5' -> 3')": this.boxDS.pmrRev.seqWithPad,
      Purification: "",
      Scale: "",
      "Oligo Name": this.boxDS.pmrRev.name
    };
    resPmrs.push(dsPmrRev);
    return resPmrs;
  }

  updateAASeq(aaSeq, replaceWith, pos, aaPos) {
    let aaNew = "";
    if (replaceWith) {
      const offset = pos - aaPos;
      if (offset >= 0 && offset < 3) {
        // using side effect to set color for aaSeq
        this.aaSeqBefore1Color = "text-dark";
        this.aaSeqBefore2Color = "text-dark";
        this.aaSeqBefore3Color = "text-dark";
        this.aaSeqAfter1Color = "text-dark";
        this.aaSeqAfter2Color = "text-dark";
        this.aaSeqAfter3Color = "text-dark";

        switch (offset) {
          case 0:
            aaNew = replaceWith + aaSeq.substr(1, 2);
            this.aaSeqBefore1Color = "text-danger";
            this.aaSeqAfter1Color = "text-success";
            break;
          case 1:
            aaNew = aaSeq.substr(0, 1) + replaceWith + aaSeq.substr(2, 1);
            this.aaSeqBefore2Color = "text-danger";
            this.aaSeqAfter2Color = "text-success";
            break;
          case 2:
            aaNew = aaSeq.substr(0, 2) + replaceWith;
            this.aaSeqBefore3Color = "text-danger";
            this.aaSeqAfter3Color = "text-success";
            break;
        }
      } else {
        OlabUtils.warnLog(
          "updateAASeq: Problem!!! (offset < 0 or > 2), offset = " + offset
        );
      }
    }
    return aaNew;
  }

  getAASeq(blkSeq, blkSeqPos, pos, replaceWith) {
    let aaSeq = "";
    // console.log(
    //   "getAASeq: blkSeq.length = ",
    //   blkSeq.length,
    //   ", blkSeqPos = ",
    //   blkSeqPos,
    //   ", pos = ",
    //   pos
    // );

    const aaPos = Math.floor(pos / 3) * 3;
    let posOffset = pos - aaPos;
    // console.log("aaPos =", aaPos, ", posOffset =", posOffset);
    aaSeq = blkSeq.substr(blkSeqPos - posOffset, 3);
    if (replaceWith) {
      aaSeq = this.updateAASeq(aaSeq, replaceWith, pos, aaPos);
    }
    // console.log("aaPos = " + aaPos + ", aaSeq = " + aaSeq);

    return aaSeq;
  }

  static infoLog(snpSwap) {
    OlabUtils.infoLog(
      " - snpSwap.gene.olab_id = " + snpSwap.gene ? snpSwap.gene.olab_id : null
    );
    OlabUtils.infoLog(" - snpSwap.position = " + snpSwap.position);
    OlabUtils.infoLog(" - snpSwap.replaceWith = " + snpSwap.replaceWith);
    OlabUtils.infoLog(" - snpSwap.armSize = " + snpSwap.armSize);
    OlabUtils.infoLog(" - snpSwap.pmr3Pad = " + snpSwap.pmr3Pad);
    OlabUtils.infoLog(" - snpSwap.pmr5Pad = " + snpSwap.pmr5Pad);
    OlabUtils.infoLog(" - snpSwap.blockSeq = " + snpSwap.blockSeq);
    OlabUtils.infoLog(" - snpSwap.blockSeqPos = " + snpSwap.blockSeqPos);
  }
}

export { OlabSNPSwap };

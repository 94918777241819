class OlabDesignInfo {
  constructor() {
    this.boxBar = "";
    this.boxBarStyle = "";
    this.boxID = "";
    this.seq = "";
  }

  setBoxBar(boxBar) {
    this.boxBar = boxBar;
  }

  setBoxBarStyle(boxBarStyle) {
    this.boxBarStyle = boxBarStyle;
  }

  setSeq(seq) {
    this.seq = seq;
  }

  set(dsBox) {
    // Set design box's states into info as status
    this.boxID = dsBox.cstb.boxID;
    this.boxBar = dsBox.cstb.display_name;
    this.boxBarStyle = dsBox.cstb.use_seq_as_color;
    this.seq = dsBox.cstb.seq;
    if (dsBox.item && dsBox.item.seq && dsBox.item.seq.olab_type == "package") {
      let comps = dsBox.item.seq.comps;
      if (comps) {
        if (comps.length > 1) {
          this.seq += " with " + comps.length + " items";
        } else {
          this.seq += " with " + comps.length + " item";
        }
      } else {
        this.seq += " with no item";
      }
    }
  }

  reset() {
    // Reset design box's states into info as status
    this.boxID = "";
    this.boxBar = "";
    this.boxBarStyle = "";
    this.seq = "";
  }
}

export { OlabDesignInfo };

<template>
  <div>
    <div v-if="user" id="snpswap">
      <nav class="navbar sticky-top navbar-light bg-light">
        <div class="navbar-brand text-truncate">
          <!-- Placeholder for user profile-->
          <button
            type="button"
            class="btn btn-outline-danger ml-1"
            data-toggle="modal"
            data-target="#userProfile"
            data-backdrop="static"
            data-keyboard="false"
          >
            <font-awesome-icon icon="id-badge" />
          </button>
          <button
            type="button"
            class="btn btn-outline-success ml-1"
            data-toggle="modal"
            data-target="#infoProfile"
            data-backdrop="static"
            data-keyboard="false"
          >
            <font-awesome-icon icon="info" />
          </button>
          <span class="h4 text-success text-capitalize ml-2"
            >{{ selectedTypeName }} System</span
          >
        </div>
        <div class="navbar-text ml-auto d-flex">
          <!-- Project profile button -->
          <button
            v-if="selectedProject"
            type="button"
            class="btn btn-outline-success ml-1"
            data-toggle="modal"
            data-target="#projectProfile"
            data-backdrop="static"
            data-keyboard="false"
          >
            <font-awesome-icon icon="project-diagram" />
          </button>

          <div v-if="selectedCart">
            <button
              type="button"
              class="btn btn-outline-success ml-1"
              data-toggle="modal"
              data-target="#cartProfile"
              data-backdrop="static"
              data-keyboard="false"
            >
              <span v-if="!cartIsFull" class="badge badge-pill badge-light">{{
                cartQty
              }}</span>
              <span v-if="cartIsFull" class="badge badge-pill badge-danger">{{
                cartQty
              }}</span>
              <font-awesome-icon icon="shopping-cart" />
            </button>
          </div>
        </div>
        <!-- .navbar-text ml-auto d-flex -->

        <div
          v-if="errorObj.message"
          class="col-12 alert alert-danger text-center px-3"
        >
          {{ errorObj.message }}
          <div class="text-center mt-2">
            <button
              type="button"
              class="btn btn-danger"
              v-on:click="closeError()"
            >
              OK
            </button>
          </div>
        </div>
        <div
          v-if="statusObj.message"
          class="col-12 alert alert-info fade show px-3"
        >
          <span>{{ statusObj.message }}</span>
          <span
            v-if="statusObj.progress"
            class="mx-2 spinner-grow"
            role="status"
          >
            <span class="sr-only">Status information</span>
          </span>
          <div class="text-center mt-2">
            <button
              type="button"
              class="btn btn-primary"
              v-on:click="closeStatus()"
            >
              OK
            </button>
          </div>
        </div>
      </nav>
      <!-- sticky top -->

      <!-- userProfile -->
      <OlabUserProfile :user="user" />

      <!-- infoProfile -->
      <OlabInfoProfile
        :user="user"
        :selectedTypeName="selectedTypeName"
        :dsgnBox="snpComp"
      />

      <!-- projectProfile -->
      <OlabProjectProfile :user="user" :project="selectedProject" />

      <!-- cartProfile -->
      <OlabCartProfile
        :user="user"
        :selectedProject="selectedProject"
        :selectedCart="selectedCart"
        :statusObj="statusObj"
        :errorObj="errorObj"
      />
      <article
        v-if="selectedCart"
        class="page-snpswap text-center py-3"
        id="page-snpswap"
      >
        <header class="page-snpswap-header container mb-2">
          <div class="row justify-content-center">
            <div class="col-11">
              <h3>Single Nucleotide Polymorphism Swap</h3>
              <!-- <div>
                <h3 class="text-warning my-3">
                  <font-awesome-icon
                    class="mx-2 text-success"
                    icon="seedling"
                  ></font-awesome-icon>
                  Work in progress ...
                  <font-awesome-icon
                    class="mx-2 text-success"
                    icon="seedling"
                  ></font-awesome-icon>
                </h3>
              </div> -->
              <div v-if="bulkUploadState">
                <form v-on:submit.prevent="onSubmit">
                  <h4 class="mb-3">HTP SNPSwap Form</h4>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <div class="form-row my-3 ml-1">
                          <label for="inputArmSize" class="mr-2"
                            >Arm Size</label
                          >
                          <input
                            id="inputArmSize"
                            type="number"
                            min="40"
                            max="3000"
                            :class="armSizeValidState"
                            v-model="armSize"
                          />
                          <div class="invalid-feedback">
                            <span class="small">[40, </span>
                            <span class="small">3000</span>
                            <span class="small">]</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="form-group">
                        <div class="form-row my-3 ml-1">
                          <label class="form-control-label mr-2"
                            >SNPSwap File (csv):</label
                          >
                          <input
                            id="inputCSVFile"
                            type="file"
                            accept=".csv"
                            v-on:change="changeFile($event, 'CSV')"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="input5Padding" class="mr-2 float-left"
                      >5' Padding</label
                    >
                    <textarea
                      class="form-control"
                      :class="pmr5PadValidState"
                      v-model="pmr5Pad"
                    ></textarea>
                    <div class="invalid-feedback">
                      Sequence has to be [a,c,g,t]
                    </div>
                    <small class="form-text text-muted"
                      >Landing pad sequence</small
                    >
                  </div>
                  <div class="form-group mt-3">
                    <label for="input3Padding" class="mr-2 float-left"
                      >3' Padding</label
                    >
                    <textarea
                      class="form-control"
                      :class="pmr3PadValidState"
                      v-model="pmr3Pad"
                    ></textarea>
                    <div class="invalid-feedback">
                      Sequence has to be [a,c,g,t]
                    </div>
                    <small class="form-text text-muted"
                      >Landing pad sequence</small
                    >
                    <small class="form-text text-danger"
                      >Note: The reverse complement of this padding will be
                      added to the reverse primer
                    </small>
                  </div>
                  <div>
                    <button
                      type="button"
                      class="btn btn-info"
                      v-bind:disabled="submitHTPDisabledState === 'disabled'"
                      v-on:click="submitHTP()"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
              <div v-else class="container text-center">
                <div class="my-2 text-danger">
                  SNP Swap is only applicable to sequences selected from the
                  genome database
                </div>
                <div>
                  <div
                    v-if="initLoading.message"
                    class="col-12 alert alert-info fade show px-3"
                  >
                    <span>{{ initLoading.message }}</span>
                    <span
                      v-if="initLoading.progress"
                      class="mx-2 spinner-grow"
                      role="status"
                    >
                      <span class="sr-only">Loading information</span>
                    </span>
                  </div>
                  <div v-else>
                    <button
                      v-if="manual == false"
                      type="button"
                      class="btn btn-info"
                      v-on:click="loadGenesFromCart()"
                    >
                      Manual
                    </button>
                  </div>
                </div>
              </div>
              <div class="my-3">
                <div class="form-group">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      data-toggle="collapse"
                      data-target="#bulkUploadPanel"
                      aria-expanded="false"
                      aria-controls="bulkUploadPanel"
                      v-on:click="bulkUploadStateChange()"
                      id="bulkUploadCheck"
                    />
                    <label class="form-check-label" for="bulkUploadCheck">
                      HTP SNPSwap Bulk Upload Data
                    </label>
                  </div>
                </div>
                <div class="collapse" id="bulkUploadPanel">
                  <div class="card card-body">
                    <div class="text-center">
                      <span class="px-3">Download:</span>
                      <a
                        href="templates/snpswaps_template.csv"
                        target="_blank"
                        class="card-link"
                        download
                        >CSV template for bulk upload SNPSwap data</a
                      >
                    </div>
                  </div>
                  <!-- <div
                    class="card-footer text-center bg-warning bulk-upload-note"
                  >
                    <div class="text-center h6">Note</div>
                    <div class="px-2 text-left">
                      1) Note One.
                    </div>
                    <div class="px-2 text-left">
                      2) Note Two.
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </header>
        <section v-if="!bulkUploadState">
          <div
            v-if="manual === true && snpComps.length === 0"
            class="alert alert-warning"
            role="alert"
          >
            No gene from genome database found in cart!
            <p class="pt-2 h5">
              <span>Please select a different cart from the</span>
              <span>
                <router-link to="/compdb" v-if="user">
                  Component
                </router-link>
              </span>
              <span>module.</span>
            </p>
          </div>
          <div v-else>
            <SNPSwapList
              v-if="user"
              :user="user"
              :dbInfo="dbInfo"
              :snpSwaps="snpComps"
              :selectedCart="selectedCart"
              :statusObj="statusObj"
              :errorObj="errorObj"
            />
          </div>
        </section>
      </article>
      <article v-else class="page-snpswap text-center py-5" id="page-snpswap">
        <header class="page-snpswap-header container">
          <p class="h3">SNPSwap Module requires a selected Cart to proceed.</p>
          <p class="h5">
            <span>Please select a cart from the</span>
            <span>
              <router-link to="/compdb" v-if="user"> Component </router-link>
            </span>
            <span>module.</span>
          </p>
        </header>
      </article>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import SNPSwapList from "@/components/SNPSwapList";
import OlabUserProfile from "@/components/OlabUserProfile";
import OlabInfoProfile from "@/components/OlabInfoProfile";
import OlabProjectProfile from "@/components/OlabProjectProfile";
import OlabCartProfile from "@/components/OlabCartProfile";
import { OlabComp } from "@/olab/olabcomp.js";
import { OlabPrimer } from "@/olab/olabprimer.js";
import { OlabSNPSwap } from "@/olab/olabsnpswap.js";
// import { OlabBlock } from "@/olab/olabblock.js";
import { OlabUtils } from "@/olab/olabutils.js";
import { OlabAxios } from "@/olab/olabaxios.js";
// import $ from "jquery";
// import _ from "lodash";

export default {
  name: "snpswap",
  data: function() {
    return {
      cartItems: null,
      snpComps: [],
      snpComp: null,
      selectedTypeName: "SNP Swap",
      manual: false,
      initLoading: {
        message: "",
        progress: false
      },
      bulkUploadState: false,
      armSize: 40,
      homologySize: 40,
      pmr3Pad: "",
      pmr5Pad: "",
      armSizeValid: true,
      homologySizeValid: true,
      pmr3PadValid: true,
      pmr5PadValid: true,
      csvFile: null
    };
  },
  components: {
    FontAwesomeIcon,
    SNPSwapList,
    OlabUserProfile,
    OlabInfoProfile,
    OlabProjectProfile,
    OlabCartProfile
  },
  props: [
    "user",
    "dbInfo",
    "selectedProject",
    "selectedCart",
    "selectedPackage",
    "statusObj",
    "errorObj"
  ],
  mounted: function() {
    console.log("SNPSwap mounted ...");
    // Reset status and error
    this.statusObj.message = null;
    this.statusObj.progress = false;
    this.errorObj.message = null;
  },
  computed: {
    cartQty: function() {
      let qty = 0;
      if (this.selectedCart != null && this.selectedCart.comps != null) {
        for (let idx in this.selectedCart.comps) {
          qty = qty + this.selectedCart.comps[idx].qty;
        }
      }
      return qty;
    },
    cartIsFull: function() {
      // Limit to 10 items per cart
      return this.cartQty > 9 ? true : false;
    },
    armSizeValidState: function() {
      return this.armSizeValid ? "is-valid" : "is-invalid";
    },
    homologySizeValidState: function() {
      return this.homologySizeValid ? "is-valid" : "is-invalid";
    },
    pmr3PadValidState: function() {
      return this.pmr3PadValid ? "is-valid" : "is-invalid";
    },
    pmr5PadValidState: function() {
      return this.pmr5PadValid ? "is-valid" : "is-invalid";
    },
    submitHTPDisabledState: function() {
      let state = "disabled";
      if (
        this.csvFile &&
        this.armSizeValid &&
        this.homologySizeValid &&
        this.pmr3PadValid &&
        this.pmr5PadValid &&
        this.statusObj.progress === false
      ) {
        state = "";
      }
      return state;
    }
  },
  methods: {
    delayInSecond: function(sec) {
      return new Promise(resolve => setTimeout(resolve, sec * 1000));
    },
    reloadWorkaround: async function() {
      OlabUtils.infoLog("reloadWorkaround: zero seconds");
      this.counter = 0;
      await this.delayInSecond(3);
      OlabUtils.infoLog("reloadWorkaround: two seconds");
      if (this.selectedCart) {
        // FIX ME: push to Home to reset states for now
        this.$router.push("/");
      }
    },
    initComps: async function() {
      OlabUtils.infoLog("initComps ...");
      // Only do this once when cartItems is null
      if (this.cartItems === null) {
        if (this.selectedCart && this.selectedCart.comps) {
          const ccArr = [];
          const comps = this.selectedCart.comps;
          for (let i = 0; i < comps.length; i++) {
            const cp = comps[i];
            // console.log("cp =", cp);
            // NOTE: Only need to load sequence of strain type
            if (cp.olab_type === "strain") {
              // TODO: Redesign this class and rename as OlabBoxComp
              const cc = new OlabComp(cp);
              // console.log("cc =", cc);
              await cc.loadSeq();
              ccArr.push(cc);
            }
          }
          this.cartItems = ccArr;
          OlabUtils.infoLog("cartItems.length = " + this.cartItems.length);
        }
      } else {
        OlabUtils.infoLog(
          "Nothing done. CartItems was initialized. : length = " +
            this.cartItems.length
        );
      }
    },
    async loadGenesFromCart() {
      // console.log("loadGenesFromCart ...", this.selectedCart);
      if (this.selectedCart) {
        // Load items from cart
        this.message = "Loading cart data, please wait ...";
        this.progress = true;
        await this.initComps();
        this.initLoading.message = "";
        this.initLoading.progress = false;

        if (this.cartItems != null) {
          const snpArr = [];
          for (let i = 0; i < this.cartItems.length; i++) {
            const cp = this.cartItems[i];
            if (cp.comp.olab_type == "strain") {
              const snp = new OlabSNPSwap(cp.seq);
              snpArr.push(snp);
            } else {
              OlabUtils.infoLog(
                "Not strain: cp.comp.olab_type = " + cp.comp.olab_type
              );
            }
          }
          this.snpComps = snpArr;
          OlabUtils.infoLog(
            "loadGenesFromCart: snpComps.length = " + this.snpComps.length
          );
          this.manual = true;
        }
      } else {
        // selectedCart will be null temporarily when a user reload page
        // This reloadWorkaround is an attempt to catch such a situation
        this.reloadWorkaround();
      }
    },
    bulkUploadStateChange: function() {
      this.bulkUploadState = !this.bulkUploadState;
      // Reset
      const csvFileHandle = document.getElementById("inputCSVFile");
      if (csvFileHandle) {
        csvFileHandle.value = "";
      }
      this.csvFile = null;
    },
    changeFile: function(e, fileType) {
      this.csvFile = e.target.files[0];
      console.log(
        "changeFile - csvFile =",
        this.csvFile,
        ", fileType =",
        fileType
      );
    },
    onSubmit() {
      // This is a NOP to prevent page reload on return key
      // console.log("After Submit");
    },
    async submitHTP() {
      // console.log("submitHTP ...");
      // console.log(
      //   "selectedProject =",
      //   this.selectedProject,
      //   "armSize =",
      //   this.armSize,
      //   ", homologySize =",
      //   this.homologySize,
      //   ", pmr3Pad =",
      //   this.pmr3Pad,
      //   ", pmr5Pad =",
      //   this.pmr5Pad
      // );

      // Reverse complement pmr3Pad
      let rcPmr3Pad = "";
      // console.log("rcPmr3Pad =", rcPmr3Pad, ", pmr3Pad =", this.pmr3Pad);
      if (this.pmr3Pad && this.pmr3Pad !== "") {
        rcPmr3Pad = OlabPrimer.complementSeq(this.pmr3Pad);
        rcPmr3Pad = OlabPrimer.reverseSeq(rcPmr3Pad);
      }
      // console.log("rcPmr3Pad =", rcPmr3Pad, ", pmr3Pad =", this.pmr3Pad);
      this.statusObj.message = "Process bulk upload SNP Swap data ...";
      this.statusObj.progress = true;
      // console.log("csvFile =", this.csvFile);
      const snpSwapSubmitObj = {
        project: this.selectedProject,
        armSize: this.armSize,
        homologySize: this.homologySize,
        pmr3Pad: rcPmr3Pad,
        pmr5Pad: this.pmr5Pad,
        csvFile: this.csvFile
      };
      const zipFile = await OlabAxios.htpBulkSNPSwap(
        snpSwapSubmitObj,
        this.statusObj,
        this.errorObj
      );

      // download file to client machine
      // console.log("zipFile =", zipFile);
      if (zipFile) {
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.style.visibility = "hidden";

        // https://developer.mozilla.org/en-US/docs/Glossary/MIME_type
        // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
        const blob = new Blob([zipFile], {
          type: "application/zip"
        });
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        const csvFileName = this.csvFile.name;
        const newFileName =
          csvFileName.substring(0, csvFileName.length - 4) + ".zip";
        a.download = newFileName;
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    },
    closeStatus: function() {
      // console.log("Close Status Alert ...");
      this.statusObj.message = null;
      this.statusObj.progress = false;
    },
    closeError: function() {
      // console.log("Close Error Alert ...");
      this.errorObj.message = null;
    }
  },
  watch: {
    armSize: function() {
      OlabUtils.infoLog("armSize = " + this.armSize);
      this.armSizeValid =
        this.armSize.toString().match(/^[1-9]\d*$/) != null &&
        this.armSize >= 40 &&
        this.armSize <= 3000;
    },
    homologySize: function() {
      OlabUtils.infoLog("homologySize = " + this.homologySize);
      this.homologySizeValid =
        this.homologySize.toString().match(/^[1-9]\d*$/) != null &&
        this.homologySize >= 20 &&
        this.homologySize <= 60;
    },
    pmr3Pad: function() {
      // OlabUtils.infoLog("pmr3Pad = " + this.pmr3Pad);
      if (this.pmr3Pad) {
        this.pmr3Pad = this.pmr3Pad.toUpperCase();
        // only keep a-zA-Z and remove other characters from seq
        this.pmr3Pad = this.pmr3Pad.replace(/[^a-zA-Z]/g, "");
        this.pmr3PadValid = !this.pmr3Pad.match(/[^acgt]/gi);
      }
    },
    pmr5Pad: function() {
      // OlabUtils.infoLog("pmr5Pad = " + this.pmr5Pad);
      if (this.pmr5Pad) {
        this.pmr5Pad = this.pmr5Pad.toUpperCase();
        // only keep a-zA-Z and remove other characters from seq
        this.pmr5Pad = this.pmr5Pad.replace(/[^a-zA-Z]/g, "");
        this.pmr5PadValid = !this.pmr5Pad.match(/[^acgt]/gi);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.page-snpswap {
  background-color: rgb(240, 250, 226);
}
</style>

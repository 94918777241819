import { OlabGene } from "@/olab/olabgene.js";

// This static helper class for handling meta map
class MetaMap {
  static getGeneArc(arcCollection, geneID) {
    // console.log("geneID =", geneID);
    // console.log("arcCollection =", arcCollection);
    let retObj = {
      olab_id: "",
      name: "_NotAGene",
      arcFrom: 0,
      arcTo: 0,
      comp: false
    };

    if (arcCollection) {
      for (let i = 0; i < arcCollection.length; i++) {
        const arc = arcCollection[i];
        // console.log("arc =", arc);
        if (arc.geneID === geneID) {
          retObj = arc;
          break;
        }
      }
    }
    return retObj;
  }

  static createPieArcs(gnArr, frame, isTagFrame, colorTable, vueThis) {
    const arcs = [];
    gnArr.forEach(gnObj => {
      const id = gnObj.olab_id;
      // Do fillColor assignment based on tag type if it is a tagFrame
      let fillColor = "#fbfafc";
      if (isTagFrame && id.length > 10 && id.substr(0, 6) === "Primer") {
        const primerType = id.substr(7, 4);
        // console.log("primerType =", primerType);
        fillColor = primerType === "cPCR" ? "pink" : "lightblue";
      } else if (isTagFrame && id.length > 10 && id.substr(0, 6) === "Region") {
        const regionType = id.substr(7, 4);
        // console.log("regionType =", regionType);
        fillColor = regionType === "HomL" ? "orange" : "lightgreen";
      } else {
        fillColor =
          gnObj.name === "_NotAGene"
            ? fillColor
            : colorTable.getItemColor(gnObj);
      }
      const gnPieArc = {
        frame: frame,
        start: gnObj.arcFrom,
        end: gnObj.arcTo,
        len: gnObj.arcTo - gnObj.arcFrom + 1,
        comp: gnObj.comp,
        name: gnObj.name,
        // For display of gene's from and to in tooltip
        geneFrom: gnObj.geneFrom,
        geneTo: gnObj.geneTo,
        geneID: gnObj.olab_id,
        geneFillColor: fillColor,
        // Add this to the object so that we can access
        // trigger Vue framework inside D3
        vueHandler: vueThis
      };
      arcs.push(gnPieArc);
    });
    return arcs;
  }

  static createDataObj(data) {
    // Clear seq.comp data (convert String to Boolean)
    data.comp = data.comp === true || data.comp === "true" ? true : false;

    // Case 1: Typical + strand sequences
    const dataObj = {
      arcFrom: data.from,
      arcTo: data.to,
      geneFrom: data.from,
      geneTo: data.to,
      comp: data.comp
    };
    // console.log("**** data =", data);
    if (data.locations && data.locations.length > 0) {
      // TODO: Bug - Need to handle crossover and intron cases
      // TODO: Have to use the locations array to reconstruct the
      //       entire sequence.
      // Internally mark these parts a one consecutive sequence.
      console.log("TODO: Need to handle crossover and intron cases");
      // console.log(`data.from = ${data.from}, data.to = ${data.to}`);
      // console.log("data.locations =", data.locations);
    } else if (data.comp && data.to < data.from) {
      // Case 2: Typical - strand sequencs
      dataObj.arcFrom = data.to;
      dataObj.arcTo = data.from;
      dataObj.geneFrom = data.from;
      dataObj.geneTo = data.to;
    } else if (data.comp) {
      // TODO: Remove this workaround to backend fix before db migration
      console.log(
        "TODO: Remove this workaround to backend fix before db migration"
      );
      dataObj.arcFrom = data.from;
      dataObj.arcTo = data.to;
      dataObj.geneFrom = data.to;
      dataObj.geneTo = data.from;
    } else if (!data.comp && data.to < data.from) {
      console.log("TODO: Unknown case: data =", data);
    }

    return dataObj;
  }

  static prepChrmGenes(chrmGenes) {
    const gArr = [];
    chrmGenes.forEach(gn => {
      // console.log(gn.sequence);
      // console.log(gn.gene_stats);
      const seq = gn.sequence;
      const gene_stats = gn.gene_stats;
      const dataObj = MetaMap.createDataObj(seq);

      const gnObj = {
        olab_id: gn.olab_id,
        seq: seq,
        stats: gene_stats,
        geneFrom: dataObj.geneFrom,
        geneTo: dataObj.geneTo,
        len: gene_stats.len,
        comp: dataObj.comp,
        arcFrom: dataObj.arcFrom,
        arcTo: dataObj.arcTo,
        name: OlabGene.getGeneDisplayName(gn)
      };
      gArr.push(gnObj);
    });
    return gArr;
  }

  static overlapSequence(g1, g2) {
    let result = true;
    const g1NoFlip = g1.arcFrom < g1.arcTo;
    const g1Start = g1NoFlip ? g1.arcFrom : g1.arcTo;
    const g1End = g1NoFlip ? g1.arcTo : g1.arcFrom;
    const g2NoFlip = g2.arcFrom < g2.arcTo;
    const g2Start = g2NoFlip ? g2.arcFrom : g2.arcTo;
    const g2End = g2NoFlip ? g2.arcTo : g2.arcFrom;
    if (g1End < g2Start) {
      result = false;
    } else if (g2End < g1Start) {
      result = false;
    }
    // console.log("overlapSequence: result =", result);
    return result;
  }

  static insertGene(gnObj, frame) {
    // compute from offset
    let fromOffset = 1;
    if (frame.length > 0) {
      fromOffset = frame[frame.length - 1].arcTo + 1;
    }

    // Pad in a blank block if gene isn't at the start of chromosome
    if (gnObj.arcFrom > 1) {
      frame.push({
        olab_id: "",
        name: "_NotAGene",
        arcFrom: fromOffset,
        arcTo: gnObj.arcFrom - 1,
        comp: false
      });
    }
    frame.push(gnObj);
  }

  // Find a frame to insert the gene
  static insertToFrame(gnObj, frameArr) {
    let res = false;
    for (let i = 0; i < frameArr.length; i++) {
      const frame = frameArr[i];
      if (frame) {
        for (let j = 0; j < frame.length; j++) {
          res = MetaMap.overlapSequence(frame[j], gnObj);
          if (res) {
            break;
          }
        }
        // Found a frame to insert gene
        if (!res) {
          MetaMap.insertGene(gnObj, frame);
          break;
        }
      }
    }
    // Can't find an existing frame case
    if (res) {
      const newFrame = [];
      MetaMap.insertGene(gnObj, newFrame);
      frameArr.push(newFrame);
    }
  }

  static buildFrames(frameArrs, objArr, chrmLen) {
    // Insert the first empty frame to start
    frameArrs.push([]);
    objArr.map(obj => {
      MetaMap.insertToFrame(obj, frameArrs);
    });

    frameArrs.forEach(frameArr => {
      // console.log("frameArr =", frameArr);
      if (frameArr.length > 0) {
        // console.log("*** total chromsome len =", chrmLen);
        frameArr.push({
          olab_id: "",
          name: "_NotAGene",
          arcFrom: frameArr[frameArr.length - 1].arcTo + 1,
          arcTo: chrmLen,
          comp: false
        });
      }
    });
  }

  // TODO: Bug - Need to handle crossover and intron cases (check locations).
  static compute(chrmGnArr, chrmLen) {
    // A 2 dimensional array of frames (arrays)
    const toRetArr = [];

    if (chrmGnArr) {
      // Need to sort genes first
      // console.log("%%%%% (1) chrmGnArr =", chrmGnArr);
      chrmGnArr.sort((aa, bb) => {
        // return a.sequence.from - b.sequence.from;
        const a = aa.sequence;
        const b = bb.sequence;
        // console.log("a.from =", a.from, ", b.from =", b.from);
        const aR = a.from > a.to ? a.to : a.from;
        const bR = b.from > b.to ? b.to : b.from;
        // console.log("aR =", aR, ", bR =", bR);
        return aR - bR;
      });
      // console.log("%%%%% (2) chrmGnArr =", chrmGnArr);

      const geneArr = MetaMap.prepChrmGenes(chrmGnArr);
      // console.log("** geneArr =", geneArr);

      MetaMap.buildFrames(toRetArr, geneArr, chrmLen);
      // console.log("** toRetArr =", toRetArr);
    }
    return toRetArr;
  }

  static prepChrmTags(chrmTags) {
    const tArr = [];
    chrmTags.forEach(tg => {
      // console.log(tg.seq);
      const seq = tg.seq;

      const dataObj = MetaMap.createDataObj(tg);

      const tgObj = {
        olab_id: `${tg.type}-${tg.name}1${tg.id}`,
        seq: seq,
        geneFrom: dataObj.geneFrom,
        geneTo: dataObj.geneTo,
        len: dataObj.arcTo - dataObj.arcFrom,
        comp: dataObj.comp,
        arcFrom: dataObj.arcFrom,
        arcTo: dataObj.arcTo,
        name: tg.name
      };
      tArr.push(tgObj);
    });
    return tArr;
  }

  // TODO: Bug - Need to handle crossover and intron cases (check locations).
  static computeTagFrames(chrmTagArr, chrmLen) {
    // Create the empty frame to start
    const tagFrames = [];

    if (chrmTagArr) {
      // Need to sort tag first
      // console.log("%%%%% (1) chrmTagArr =", chrmTagArr);
      chrmTagArr.sort((a, b) => {
        // console.log("a.from =", a.from, ", b.from =", b.from);
        const aR = a.from > a.to ? a.to : a.from;
        const bR = b.from > b.to ? b.to : b.from;
        // console.log("aR =", aR, ", bR =", bR);
        return aR - bR;
      });
      // console.log("%%%%% (2) chrmTagArr =", chrmTagArr);

      const tagArr = MetaMap.prepChrmTags(chrmTagArr);
      // console.log("** tagArr =", tagArr);

      MetaMap.buildFrames(tagFrames, tagArr, chrmLen);
      // console.log("** tagFrames =", tagFrames);
    }
    return tagFrames;
  }

  static processMetaMap(plasmGArr, gArr, tArr, useNewVersion) {
    let id = 1;
    for (let i = 0; i < plasmGArr.length; i++) {
      const dName = OlabGene.getGeneDisplayName(plasmGArr[i]);
      const idObj = { ...plasmGArr[i], id: id, d_name: dName };
      // console.log("idObj =", idObj, ", idObj.type =", idObj.type);
      if (
        !useNewVersion ||
        idObj.type === "CDS" ||
        idObj.type === "chromosome" ||
        idObj.olab_type === "gene"
      ) {
        gArr.push(idObj);
      } else if (idObj.type === "CDS_TRUNC") {
        const tmpObj = {
          name: idObj.name,
          comp: idObj.sequence.comp,
          from: idObj.sequence.from,
          len: idObj.gene_stats.len,
          seq: idObj.gene_stats.short_seq,
          to: idObj.sequence.to,
          type: idObj.sequence.type
        };
        tArr.push(tmpObj);
      } else {
        tArr.push(idObj);
      }
      id++;
    }
  }
}

export { MetaMap };

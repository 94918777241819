<template>
  <div>
    <div class="card-body">
      <div class="form-row">
        <div class="form-group col-md-12">
          <span class="mr-1">Total Memory:</span>
          <span class="ml-1">{{ formatMemorySize(system.total_memory) }}</span>
        </div>
      </div>
      <table class="table">
        <thead>
          <tr>
            <th scope="col">Collection</th>
            <th scope="col">Memory</th>
            <th scope="col">Count</th>
            <th scope="col">Deleted</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(collection, index) in system.collections" :key="index">
            <th scope="row">
              {{ collection.name }}
            </th>
            <td>{{ formatMemorySize(collection.memory) }}</td>
            <td>{{ collection.count }}</td>
            <td>{{ collection.deleted }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { OlabUtils } from "@/olab/olabutils.js";

export default {
  name: "OlabSystemTab",
  data() {
    return {};
  },
  props: ["system"],
  methods: {
    formatMemorySize(size) {
      return OlabUtils.formatMemorySize(size);
    }
  }
};
</script>

<template>
  <div>
    <div class="list-group list-group-flush">
      <div
        class="list-group-item d-flex mb-1 align-items-center"
        v-for="sid in selectedItems"
        :key="sid.olab_id"
      >
        <div class="container">
          <div class="row">
            <div class="col-1 m-auto">
              <div v-if="selectedDsgn && sid.olab_id === selectedDsgn.olab_id">
                <button
                  type="button"
                  class="btn btn-success"
                  v-on:click="selectDsgn(sid)"
                >
                  <font-awesome-icon icon="check-square" />
                </button>
              </div>
              <div v-else>
                <button
                  type="button"
                  class="btn btn-info btn-sm"
                  v-on:click="selectDsgn(sid)"
                >
                  <font-awesome-icon icon="square" />
                </button>
              </div>
            </div>

            <div class="col-10 col-sm-4 align-self-center mt-2 mx-2 stats-form">
              <div class="container">
                <div class="row">
                  <div class="col-6 col-sm-12 col-lg-6">
                    <div class="row">
                      <span class="mr-1">Primer Setting:</span>
                      <span>{{ sid.pmr_setting }}</span>
                    </div>
                    <div class="row">
                      <span class="mr-1">3' Length:</span>
                      <span>{{ sid.pmr_3p_len }}</span>
                    </div>
                    <div class="row">
                      <span class="mr-1">5' Length:</span>
                      <span>{{ sid.pmr_5p_len }}</span>
                    </div>
                  </div>
                  <div class="col-6 col-sm-12 col-lg-6">
                    <div class="row">
                      <span class="mr-1">Package:</span>
                      <span>{{ sid.package_present }}</span>
                    </div>
                    <div v-if="sid.stats" class="row">
                      <span class="mr-1">Total Constructs:</span>
                      <span>{{ sid.stats.construct_count }}</span>
                    </div>
                    <div v-if="sid.stats" class="row">
                      <span class="mr-1">Total Primers:</span>
                      <span>{{ sid.stats.comb_pmr_count }}</span>
                    </div>
                    <div v-if="sid.stats" class="row">
                      <span class="mr-1">Unique Primers:</span>
                      <span>{{ sid.stats.uniq_pmr_count }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 col-sm-6 mt-2 align-self-center text-truncate">
              <a
                class="h5 text-info"
                data-toggle="modal"
                href="#viewItem"
                v-on:click="registerDsgn(sid)"
                data-backdrop="static"
                data-keyboard="false"
                >{{ sid.name }}</a
              >

              <div class="h6 mt-2">{{ sid.olab_id }}</div>
              <div class="h6">
                {{ sid.creator }}
              </div>
            </div>

            <!-- Modal: ViewItem -->
            <div v-if="selectedDsgn != null" class="modal fade" id="viewItem">
              <OlabViewItem
                :dbInfo="dbInfo"
                :selectedType="selectedType"
                :item="selectedDsgn"
              />
            </div>
            <!-- .modal fade #viewItem -->
          </div>
          <!-- UI for Input Diagnostic configs -->
          <div
            v-if="selectedDsgn && sid.olab_id === selectedDsgn.olab_id"
            class="row my-2"
          >
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-12 col-lg-6 mb-3">
                  <div class="card">
                    <div class="card-body">
                      <div class="row justify-content-center">
                        <h5>Tm Range (&deg;C)</h5>
                      </div>
                      <div class="form-group row justify-content-center">
                        <label class="col-form-label">Low</label>
                        <div class="px-2">
                          <input
                            class="form-control"
                            type="number"
                            min="50"
                            max="60"
                            step="1"
                            :class="tmLowValidState"
                            v-model="tmLow"
                          />
                          <div class="invalid-feedback">
                            {{ invalidTmMessage }}
                          </div>
                        </div>
                        <label class="col-form-label pl-5">High</label>
                        <div class="px-2">
                          <input
                            class="form-control"
                            type="number"
                            min="50"
                            max="60"
                            step="1"
                            :class="tmHighValidState"
                            v-model="tmHigh"
                          />
                          <div class="invalid-feedback">
                            {{ invalidTmMessage }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-6 mb-3">
                  <div class="card">
                    <div class="card-body">
                      <div class="row justify-content-center">
                        <h5>Primer Type</h5>
                      </div>
                      <div class="row justify-content-center">
                        <div class="col-md-4">
                          <div class="form-group form-check form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="primerTypeRadios"
                              id="SequencingRadio"
                              value="SEQ"
                              v-model="pmrType"
                              v-on:click="processPmrType('SEQ')"
                            />
                            <label
                              class="form-check-label"
                              for="SequencingRadio"
                            >
                              Sequencing
                            </label>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group form-check form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="primerTypeRadios"
                              id="cPCRAutoRadio"
                              value="CPCR_AUTO"
                              v-model="pmrType"
                              v-on:click="processPmrType('CPCR_AUTO')"
                            />
                            <label class="form-check-label" for="cPCRAutoRadio">
                              cPCR (Auto)
                            </label>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group form-check form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="primerTypeRadios"
                              id="cPCREndFixRadio"
                              value="CPCR_END_FIX"
                              v-model="pmrType"
                              v-on:click="processPmrType('CPCR_END_FIX')"
                            />
                            <label
                              class="form-check-label"
                              for="cPCREndFixRadio"
                            >
                              cPCR (End Fix)
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ul class="nav nav-tabs" id="seqTab" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="upstream-seq-tab"
                    data-toggle="tab"
                    href="#upstream-seq"
                    role="tab"
                    aria-controls="upstream-seq"
                    aria-selected="true"
                    >upstream
                    <font-awesome-icon
                      v-if="upstreamSeqValid === false"
                      class="text-danger"
                      icon="exclamation-circle"
                    />
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="downstream-seq-tab"
                    data-toggle="tab"
                    href="#downstream-seq"
                    role="tab"
                    aria-controls="downstream-seq"
                    aria-selected="false"
                    >downstream
                    <font-awesome-icon
                      v-if="downstreamSeqValid === false"
                      class="text-danger"
                      icon="exclamation-circle"
                    />
                  </a>
                </li>
              </ul>
              <div class="tab-content" id="seqTabContent">
                <div
                  class="tab-pane fade show active"
                  id="upstream-seq"
                  role="tabpanel"
                  aria-labelledby="upstream-seq-tab"
                >
                  <div class="form-group mt-3">
                    <textarea
                      class="form-control"
                      :class="upstreamSeqValidState"
                      v-model="upstreamSeq"
                    ></textarea>
                    <div class="invalid-feedback">
                      {{ invalidSeqMessage }}
                    </div>
                    <small class="form-text text-muted"
                      >Upstream sequence</small
                    >
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="downstream-seq"
                  role="tabpanel"
                  aria-labelledby="downstream-seq-tab"
                >
                  <div class="form-group mt-3">
                    <textarea
                      class="form-control"
                      :class="downstreamSeqValidState"
                      v-model="downstreamSeq"
                    ></textarea>
                    <div class="invalid-feedback">
                      {{ invalidSeqMessage }}
                    </div>
                    <small class="form-text text-muted"
                      >Downstream sequence</small
                    >
                  </div>
                </div>
              </div>
              <!-- Buttons -->
              <div v-if="selectedType === 'design'">
                <button
                  type="button"
                  v-bind:disabled="diagPmrIsDisabled === true"
                  v-on:click="downloadPrimers(sid)"
                  class="btn btn-info"
                >
                  <span
                    v-if="
                      sid &&
                        selectedDsgn &&
                        sid.olab_id === selectedDsgn.olab_id &&
                        statusObj.progress
                    "
                    class="spinner-border spinner-border-sm text-light"
                  ></span>
                  Download Primers
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import OlabViewItem from "@/components/OlabViewItem";
import { OlabAxios } from "../olab/olabaxios.js";

export default {
  name: "diagnosticdesignlist",
  data: function() {
    return {
      selectedDsgn: null,
      downstreamSeq: "",
      upstreamSeq: "",
      tmLow: 54,
      tmHigh: 56,
      pmrType: "SEQ",
      downstreamSeqValid: false,
      upstreamSeqValid: false,
      tmLowValid: true,
      tmHighValid: true,
      requiredSeqLen: 70, // Sequencing = 70, cPCR (Auto) = 20, cPCR (EndFix) = 24
      invalidSeqMessage:
        "Sequence (a,c,g,t) has to be (+) strand from 5’ to 3’ with a minimum of 70 bps",
      invalidTmMessage: "Tm has to be between 50 and 60 and Low < High"
    };
  },
  props: [
    "user",
    "dbInfo",
    "statusObj",
    "errorObj",
    "selectedItems",
    "selectedType",
    "item"
  ],
  components: {
    FontAwesomeIcon,
    OlabViewItem
  },
  computed: {
    diagPmrIsDisabled: function() {
      // console.log(
      //   "selectedDsgn =",
      //   this.selectedDsgn,
      //   ", downstreamSeqValid =",
      //   this.downstreamSeqValid,
      //   ", upstreamSeqValid =",
      //   this.upstreamSeqValid
      // );
      if (this.selectedDsgn) {
        if (
          this.downstreamSeqValid &&
          this.upstreamSeqValid &&
          this.tmLowValid &&
          this.tmHighValid
        ) {
          return false;
        }
        return true;
      }
      return true;
    },
    downstreamSeqValidState: function() {
      if (this.selectedDsgn == null) {
        return "";
      }
      return this.downstreamSeqValid ? "is-valid" : "is-invalid";
    },
    upstreamSeqValidState: function() {
      if (this.selectedDsgn == null) {
        return "";
      }
      return this.upstreamSeqValid ? "is-valid" : "is-invalid";
    },
    tmLowValidState: function() {
      if (this.selectedDsgn == null) {
        return "";
      }
      return this.tmLowValid ? "is-valid" : "is-invalid";
    },
    tmHighValidState: function() {
      if (this.selectedDsgn == null) {
        return "";
      }
      return this.tmHighValid ? "is-valid" : "is-invalid";
    }
  },
  methods: {
    selectDsgn: function(dsgn) {
      // console.log(
      //   "selectDsgn: dsgn = ",
      //   dsgn,
      //   ", selectedDsgn =",
      //   this.selectedDsgn
      // );
      if (
        dsgn &&
        this.selectedDsgn &&
        this.selectedDsgn.olab_id === dsgn.olab_id
      ) {
        this.registerDsgn(null);
      } else {
        this.registerDsgn(dsgn);
      }
    },
    registerDsgn: async function(dsgn) {
      // console.log("registerDsgn: dsgn = ", dsgn);
      if (dsgn) {
        this.item.set(dsgn);
        // Don't reset settings if dsgn === selectedDsgn
        if (dsgn === this.selectedDsgn) {
          // console.log("dsgn === this.selectedDsgn");
          return;
        }
        this.selectedDsgn = dsgn;
        // console.log("(1) registerDsgn: dsgn = ", dsgn);
        // Sync up upstream and downstream seqs setting
        this.processPmrType(dsgn.pmr_diag_mode);
      } else {
        this.selectedDsgn = null;
      }
      // console.log("(2) registerDsgn: dsgn = ", dsgn);
    },
    processPmrType: function(pType) {
      // console.log("**** pType =", pType);
      if (pType === "SEQ") {
        // console.log("-- Sequencing", pType);
        this.pmrType = pType;
        this.requiredSeqLen = 70;
        this.upstreamSeq = this.selectedDsgn.diag_upstream_seq;
        this.downstreamSeq = this.selectedDsgn.diag_downstream_seq;
      } else if (pType === "CPCR_AUTO") {
        // console.log("-- cPCR: ", pType);
        this.pmrType = pType;
        this.requiredSeqLen = 20;
        this.upstreamSeq = this.selectedDsgn.cpcr_upstream_seq;
        this.downstreamSeq = this.selectedDsgn.cpcr_downstream_seq;
      } else if (pType === "CPCR_END_FIX") {
        // console.log("-- cPCR: ", pType);
        this.pmrType = pType;
        this.requiredSeqLen = 24;
        this.upstreamSeq = this.selectedDsgn.cpcr_upstream_seq2;
        this.downstreamSeq = this.selectedDsgn.cpcr_downstream_seq2;
      }
      this.invalidSeqMessage = `Sequence (a,c,g,t) has to be (+) strand from 5’ to 3’ with a minimum of ${this.requiredSeqLen} bps`;
    },
    validateSeq: function(seq, minLen) {
      let state = false;
      state = !seq.match(/[^acgt]/gi);
      state = state && seq.length >= minLen;
      return state;
    },
    downloadPrimers: async function(dsgn) {
      if (dsgn !== this.selectedDsgn) {
        console.log(
          "downloadPrimers - Error: dsgn =",
          dsgn,
          " is not equal to selectedDsgn =",
          this.selectedDsgn
        );
      }
      this.statusObj.message = "Generating Diagnostic primers ...";
      this.statusObj.progress = true;
      let pmrsFile = null;
      // console.log("pmrType =", this.pmrType);
      if (this.pmrType === "SEQ") {
        pmrsFile = await OlabAxios.downloadDsgnDiagPmrs(
          this.selectedDsgn,
          this.pmrType,
          this.tmLow,
          this.tmHigh,
          this.statusObj,
          this.errorObj
        );
      } else if (
        this.pmrType === "CPCR_AUTO" ||
        this.pmrType === "CPCR_END_FIX"
      ) {
        pmrsFile = await OlabAxios.downloadDsgnCPCRPmrs(
          this.selectedDsgn,
          this.pmrType,
          this.upstreamSeq,
          this.downstreamSeq,
          this.tmLow,
          this.tmHigh,
          this.statusObj,
          this.errorObj
        );
      }
      if (pmrsFile) {
        // download file to client machine
        // console.log("downloadPrimers: pmrsFile =", pmrsFile);
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.style.visibility = "hidden";

        const blob = new Blob([pmrsFile], { type: "octet/stream" });
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = this.selectedDsgn.olab_id + ".csv";
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    }
  },
  watch: {
    tmLow: async function() {
      this.tmLow = parseInt(this.tmLow);
      // console.log("tmLow = ", this.tmLow);
      this.tmLowValid = this.tmLow > 49;
      this.tmLowValid = this.tmLowValid
        ? this.tmLow < this.tmHigh
        : this.tmLowValid;
    },
    tmHigh: async function() {
      this.tmHigh = parseInt(this.tmHigh);
      // console.log("tmHigh =", this.tmHigh);
      this.tmHighValid = this.tmHigh <= 60;
      this.tmHighValid = this.tmHighValid
        ? this.tmLow < this.tmHigh
        : this.tmHighValid;
    },
    downstreamSeq: function() {
      // console.log("downstreamSeq = " + this.downstreamSeq);
      if (this.selectedDsgn && this.downstreamSeq) {
        this.downstreamSeq = this.downstreamSeq.toUpperCase();
        // only keep a-zA-Z and remove other characters from seq
        this.downstreamSeq = this.downstreamSeq.replace(/[^a-zA-Z]/g, "");

        this.downstreamSeqValid = this.validateSeq(
          this.downstreamSeq,
          this.requiredSeqLen
        );
        if (this.downstreamSeqValid) {
          this.selectedDsgn.setDownstreamSeq(this.downstreamSeq, this.pmrType);
        }
      } else if (this.selectedDsgn && !this.downstreamSeq) {
        this.downstreamSeqValid = false;
      }
    },
    upstreamSeq: function() {
      // console.log("upstreamSeq = " + this.upstreamSeq);
      if (this.selectedDsgn && this.upstreamSeq) {
        this.upstreamSeq = this.upstreamSeq.toUpperCase();
        // only keep a-zA-Z and remove other characters from seq
        this.upstreamSeq = this.upstreamSeq.replace(/[^a-zA-Z]/g, "");

        this.upstreamSeqValid = this.validateSeq(
          this.upstreamSeq,
          this.requiredSeqLen
        );
        if (this.upstreamSeqValid) {
          this.selectedDsgn.setUpstreamSeq(this.upstreamSeq, this.pmrType);
        }
      } else if (this.selectedDsgn && !this.upstreamSeq) {
        this.upstreamSeqValid = false;
      }
    }
  }
};
</script>

<style scoped>
.stats-form {
  /* background-color: #eed; */
  /* border-radius: 0.375rem; */
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;
}
</style>

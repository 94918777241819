<template>
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <g transform="translate(150,150)">
        <g transform="scale(15)" fill="#cccccc">
          <path
            fill="pink"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.84387 4.58993C4.90836 4.61277 4.95135 4.67388 4.95107 4.74229L4.94398 6.44933L4.93962 7.49669L4.9116 14.2387C4.91143 14.2804 4.89508 14.3204 4.86601 14.3503C4.62969 14.5931 4.35372 14.6463 4.11207 14.6105C3.87849 14.576 3.6777 14.4596 3.56054 14.3613C3.52408 14.3308 3.50302 14.2856 3.50302 14.238V7.49669V6.44933V5.54728C3.50302 5.53267 3.50501 5.51812 3.50894 5.50405C3.5211 5.46044 3.5467 5.42571 3.57006 5.40091C3.59538 5.37403 3.6271 5.34923 3.66435 5.32682C3.7388 5.28202 3.84264 5.24235 3.98343 5.21486C4.01866 5.20798 4.06973 5.18615 4.13489 5.14435C4.19822 5.10374 4.26602 5.05004 4.33357 4.98965C4.46883 4.86873 4.59299 4.73012 4.66467 4.64086C4.7075 4.58752 4.77938 4.56709 4.84387 4.58993ZM4.30865 5.41527C4.2282 5.46687 4.13752 5.5127 4.04513 5.53074C3.93068 5.5531 3.86457 5.58198 3.83028 5.60261C3.82836 5.60376 3.82488 5.60595 3.82488 5.60595V14.1579C3.90986 14.2154 4.02973 14.273 4.15916 14.2921C4.29837 14.3127 4.44857 14.2898 4.59003 14.1685L4.62749 5.15587C4.60165 5.18074 4.5751 5.20545 4.54808 5.2296C4.4726 5.29707 4.39094 5.3625 4.30865 5.41527Z"
          />
          <path
            fill="pink"
            d="M4.04513 5.53074C4.13752 5.5127 4.2282 5.46687 4.30865 5.41527C4.39094 5.3625 4.4726 5.29707 4.54808 5.2296C4.5751 5.20545 4.60165 5.18074 4.62749 5.15587L4.59003 14.1685C4.44857 14.2898 4.29837 14.3127 4.15916 14.2921C4.02973 14.273 3.90986 14.2154 3.82488 14.1579V5.60595L3.83028 5.60261C3.86457 5.58198 3.93068 5.5531 4.04513 5.53074Z"
          />
          <path
            fill="green"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.77009 0.783466C6.80663 0.723684 6.87165 0.687225 6.94172 0.687225H9.39232V1.08955H7.27079L7.34955 1.2433V6.44933V7.49669V14.4379C7.35259 14.4559 7.37185 14.5167 7.48164 14.5817C7.604 14.654 7.83297 14.7257 8.23602 14.7257V15.1281C7.79197 15.1281 7.48337 15.0501 7.27682 14.9279C7.06784 14.8043 6.95948 14.6309 6.94772 14.462L6.94723 14.455V7.49669V6.44933V1.34035L6.76268 0.9801C6.73074 0.917739 6.73354 0.843248 6.77009 0.783466Z"
          />
          <path
            fill="green"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.56396 0.783466C9.52741 0.723684 9.46239 0.687225 9.39232 0.687225H6.94172L6.94172 1.08955H9.06326L8.9845 1.2433V6.44933V7.49669V14.4379C8.98146 14.4559 8.96219 14.5167 8.8524 14.5817C8.73004 14.654 8.50108 14.7257 8.09803 14.7257V15.1281C8.54207 15.1281 8.85067 15.0501 9.05723 14.9279C9.26621 14.8043 9.37457 14.6309 9.38633 14.462L9.38682 14.455V7.49669V6.44933V1.34035L9.57136 0.9801C9.60331 0.917739 9.6005 0.843248 9.56396 0.783466Z"
          />
          <path
            fill="yellow"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.8491 7.70434C8.90605 7.73095 8.94229 7.78827 8.94191 7.85112L8.90244 14.3099C8.90212 14.3621 8.8765 14.4109 8.83371 14.4408C8.598 14.6056 8.33431 14.6366 8.11034 14.6141C7.88795 14.5917 7.69244 14.5157 7.57506 14.4487C7.52486 14.4201 7.49386 14.3667 7.49386 14.3089V8.39809C7.49386 8.37715 7.49795 8.35641 7.50589 8.33704C7.52565 8.28886 7.56233 8.25864 7.58383 8.24311C7.61025 8.22404 7.64179 8.20748 7.6772 8.19299C7.74819 8.16394 7.84754 8.13801 7.98393 8.11989C8.07646 8.1076 8.20826 8.04792 8.34806 7.96292C8.48304 7.88085 8.60642 7.78709 8.67698 7.72734C8.72494 7.68672 8.79216 7.67773 8.8491 7.70434ZM8.51527 8.23793C8.36901 8.32686 8.18901 8.41733 8.02631 8.43895C7.92307 8.45266 7.85624 8.47007 7.81572 8.48453V14.2091C7.90074 14.2456 8.01609 14.2811 8.14256 14.2939C8.28975 14.3087 8.44286 14.2919 8.58113 14.2187L8.61808 8.17233C8.58493 8.19446 8.55048 8.21652 8.51527 8.23793Z"
          />
          <path
            fill="yellow"
            d="M8.02631 8.43895C8.18901 8.41733 8.36901 8.32686 8.51527 8.23793C8.55048 8.21652 8.58493 8.19446 8.61808 8.17233L8.58113 14.2187C8.44286 14.2919 8.28975 14.3087 8.14256 14.2939C8.01609 14.2811 7.90074 14.2456 7.81572 14.2091V8.48453C7.85624 8.47007 7.92307 8.45266 8.02631 8.43895Z"
          />
          <path
            fill="#cccccc"
            d="M2.95639 7.49669V6.44933H0.656229C0.567351 6.44933 0.495301 6.52138 0.495301 6.61026L0.4953 7.32343L0.495301 7.33576V15.4264V15.5873V16.1519C0.495301 16.2407 0.567351 16.3128 0.656229 16.3128H11.9658C12.0547 16.3128 12.1267 16.2407 12.1267 16.1519L12.1267 15.4387L12.1267 7.33576L12.1267 7.17484L12.1267 6.61026C12.1267 6.52138 12.0547 6.44933 11.9658 6.44933H9.38682V7.49669H11.0794V15.2654H1.54265L1.54265 7.49669H2.95639Z"
          />
          <path
            fill="none"
            d="M3.35871 6.44933V7.49669H3.50302V6.44933H3.35871Z"
          />
          <path
            fill="#cccccc"
            d="M5.39598 6.44933V7.49669H6.94723V6.44933H5.39598Z"
          />
          <path
            fill="none"
            d="M7.34955 6.44933V7.49669H8.9845V6.44933H7.34955Z"
          />
          <path
            fill="none"
            d="M4.99366 7.49669V6.44933H4.94398L4.93962 7.49669H4.99366Z"
          />
          <path
            fill="red"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.95088 0.687225C2.88081 0.687225 2.81579 0.723684 2.77925 0.783466C2.7427 0.843248 2.7399 0.917739 2.77185 0.9801L2.95639 1.34035V6.44933V7.49669V14.455L2.95688 14.462C2.96864 14.6309 3.077 14.8043 3.28598 14.9279C3.48392 15.045 3.76903 15.1198 4.17589 15.1274C4.5833 15.1198 4.87066 15.0437 5.06639 14.9279C5.27537 14.8043 5.38373 14.6309 5.39549 14.462L5.39598 14.455V7.49669V6.44933V1.34035L5.58052 0.9801C5.61247 0.917739 5.60967 0.843248 5.57312 0.783466C5.53658 0.723684 5.47155 0.687225 5.40148 0.687225H2.95088ZM3.35871 1.2433L3.27995 1.08955H5.07242L4.99366 1.2433V6.44933V7.49669V14.4379C4.99062 14.4559 4.97136 14.5167 4.86156 14.5817C4.74637 14.6498 4.53668 14.7173 4.17619 14.725C4.1986 14.7255 4.22159 14.7257 4.24518 14.7257H4.10719C4.13078 14.7257 4.15377 14.7255 4.17619 14.725C3.81569 14.7173 3.606 14.6498 3.49081 14.5817C3.38101 14.5167 3.36175 14.4559 3.35871 14.4379V7.49669V6.44933V1.2433Z"
          />
        </g>
      </g>
      <text
        :x="textPosX"
        :y="textPosY"
        fill="#77b"
        style="
          font-size: 32px;
          font-weight: bold;
        "
      >
        {{ name }}
      </text>
    </svg>
  </div>
</template>

<script>
export default {
  name: "olabdilutionsvggen",
  props: ["name", "textPosX", "textPosY"]
};
</script>

<template>
  <div>
    <div
      v-if="errorObj.message"
      class="col-12 alert alert-danger text-center px-3"
    >
      {{ errorObj.message }}
      <div class="text-center mt-2">
        <button type="button" class="btn btn-danger" v-on:click="closeError()">
          OK
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data: function() {
    return {};
  },
  props: ["errorObj"],
  async mounted() {
    try {
      // Note: cjworld_id - This name is specifically requested by CJ
      // Blocking call to backend, waiting for response
      const res = await axios({
        method: "POST",
        url: "/api/v1/users/cjlogin",
        data: {
          cjworld_id: this.$route.params.token
        }
      });
      // If response is successful, set user profile and redirect to home page
      if (res && res.data.status === "success") {
        this.$emit("setUser", res.data.data.user);
        setTimeout(() => {
          this.$router.push("/");
        }, 100);
      }
    } catch (err) {
      // In error case, display feedback and then redirect to home page
      // console.log(err.response.data.message);
      this.errorObj.message = "Key Error! Please contact your CJ admin!";
    }
  },
  methods: {
    closeError: function() {
      // console.log("Close Error Alert ...");
      this.errorObj.message = null;
    }
  }
};
</script>

import { OlabUtils } from "./olabutils.js";

class OlabItem {
  constructor(item) {
    if (item == null) {
      this.initItem();
    } else {
      let hp = Object.prototype.hasOwnProperty;
      this.name = hp.call(item, "name") ? item.name : null;
      this.olab_id = hp.call(item, "olab_id") ? item.olab_id : null;
      this.olab_type = hp.call(item, "olab_type") ? item.olab_type : null;
      this.deleted = hp.call(item, "deleted") ? item.deleted : false;
      this.library_id = hp.call(item, "library_id") ? item.library_id : null;
      this.desc = hp.call(item, "desc") ? item.desc : null;
      this.desc_hyperlink = hp.call(item, "desc_hyperlink")
        ? item.desc_hyperlink
        : null;
      this.download_file = hp.call(item, "download_file")
        ? item.download_file
        : null;
      this.source = hp.call(item, "source") ? item.source : null;
      this.creator = hp.call(item, "creator") ? item.creator : null;
      this.seq_origin = hp.call(item, "seq_origin") ? item.seq_origin : null;
      this.seq = hp.call(item, "seq")
        ? item.seq != null
          ? item.seq.toUpperCase()
          : null
        : null;

      // Additional fields for library sequence
      let gStats = hp.call(item, "gene_stats") ? item.gene_stats : null;
      this.gene_stats = {
        len: 0,
        num_a: 0,
        num_t: 0,
        num_c: 0,
        num_g: 0,
        short_seq: null
      };
      if (gStats) {
        this.gene_stats.len = hp.call(gStats, "len") ? gStats.len : 0;
        this.gene_stats.num_a = hp.call(gStats, "num_a") ? gStats.num_a : 0;
        this.gene_stats.num_t = hp.call(gStats, "num_t") ? gStats.num_t : 0;
        this.gene_stats.num_c = hp.call(gStats, "num_c") ? gStats.num_c : 0;
        this.gene_stats.num_g = hp.call(gStats, "num_g") ? gStats.num_g : 0;
        this.gene_stats.short_seq = hp.call(gStats, "short_seq")
          ? gStats.short_seq
          : null;
      }
      // Additional fields for microbe
      this.microbe_type = hp.call(item, "microbe_type")
        ? item.microbe_type
        : null;
      this.chrm_count = hp.call(item, "chrm_count") ? item.chrm_count : 0;
      this.mito_count = hp.call(item, "mito_count") ? item.mito_count : 0;

      // Additional fields for strain
      this.microbe_id = hp.call(item, "microbe_id") ? item.microbe_id : null;
      this.parent_id = hp.call(item, "parent_id") ? item.parent_id : null;
      this.project_id = hp.call(item, "project_id") ? item.project_id : null;
      this.wgs_confirmed = hp.call(item, "wgs_confirmed")
        ? item.wgs_confirmed
        : false;
      this.contig = hp.call(item, "contig") ? item.contig : false;
      this.contig_array = hp.call(item, "contig_array")
        ? item.contig_array
        : [];
      this.chrm_array = hp.call(item, "chrm_array") ? item.chrm_array : [];
      this.mito_array = hp.call(item, "mito_array") ? item.mito_array : [];
      this.plmd_array = hp.call(item, "plmd_array") ? item.plmd_array : [];
      this.csv_file = hp.call(item, "csv_file") ? item.csv_file : null;
      this.fasta_file = hp.call(item, "fasta_file") ? item.fasta_file : null;
      this.genbank_file = hp.call(item, "genbank_file")
        ? item.genbank_file
        : null;

      // Additional fields for plasmid
      this.plasmid_ori = hp.call(item, "plasmid_ori") ? item.plasmid_ori : null;
      this.meta_map = hp.call(item, "meta_map") ? item.meta_map : null;
      this.diag_pmr_exists = hp.call(item, "diag_pmr_exists")
        ? item.diag_pmr_exists
        : false;

      // Additional field for plasmid, project and strain
      let raLocks = hp.call(item, "ra_locks") ? item.ra_locks : null;
      this.ra_locks = {
        public: false,
        members: null
      };
      if (raLocks) {
        this.ra_locks.public = hp.call(raLocks, "public")
          ? raLocks.public
          : false;
        this.ra_locks.members = hp.call(raLocks, "members")
          ? raLocks.members
          : null;
      }
      // Additional fields for strain, plasmid and design
      let stats = hp.call(item, "stats") ? item.stats : null;
      this.stats = {
        total_count: 0,
        total_CDS: 0,
        total_ncRNA: 0,
        total_rRNA: 0,
        total_tmRNA: 0,
        total_tRNA: 0,
        total_config_count: 0,
        total_seq_len: 0,
        construct_count: 0,
        comb_pmr_count: 0,
        uniq_pmr_count: 0
      };
      if (stats) {
        this.stats.total_count = hp.call(stats, "total_count")
          ? stats.total_count
          : 0;
        this.stats.total_CDS = hp.call(stats, "total_CDS")
          ? stats.total_CDS
          : 0;
        this.stats.total_ncRNA = hp.call(stats, "total_ncRNA")
          ? stats.total_ncRNA
          : 0;
        this.stats.total_rRNA = hp.call(stats, "total_rRNA")
          ? stats.total_rRNA
          : 0;
        this.stats.total_tmRNA = hp.call(stats, "total_tmRNA")
          ? stats.total_tmRNA
          : 0;
        this.stats.total_tRNA = hp.call(stats, "total_tRNA")
          ? stats.total_tRNA
          : 0;
        this.stats.total_config_count = hp.call(stats, "total_config_count")
          ? stats.total_config_count
          : 0;
        this.stats.total_seq_len = hp.call(stats, "total_seq_len")
          ? stats.total_seq_len
          : 0;
        this.stats.construct_count = hp.call(stats, "construct_count")
          ? stats.construct_count
          : 0;
        this.stats.comb_pmr_count = hp.call(stats, "comb_pmr_count")
          ? stats.comb_pmr_count
          : 0;
        this.stats.uniq_pmr_count = hp.call(stats, "uniq_pmr_count")
          ? stats.uniq_pmr_count
          : 0;
      }
      this.start_date = hp.call(item, "start_date") ? item.start_date : null;
      this.end_date = hp.call(item, "end_date") ? item.end_date : null;

      // Additional field for package
      this.package_seq_type = hp.call(item, "package_seq_type")
        ? item.package_seq_type
        : "library_sequence";
      this.package_type = hp.call(item, "package_type")
        ? item.package_type
        : null;

      // Additional field for cart and package
      this.comps = hp.call(item, "comps") ? item.comps : [];
      this.cstbs = hp.call(item, "cstbs") ? item.cstbs : [];

      // Additional field for design
      this.dsgn_boxes = hp.call(item, "dsgn_boxes") ? item.dsgn_boxes : [];
      this.configured = hp.call(item, "configured") ? item.configured : false;
      this.pmr_setting = hp.call(item, "pmr_setting")
        ? item.pmr_setting
        : "FULL";
      this.pmr_3p_len = hp.call(item, "pmr_3p_len") ? item.pmr_3p_len : 20;
      this.pmr_5p_len = hp.call(item, "pmr_5p_len") ? item.pmr_5p_len : 20;
      this.package_present = hp.call(item, "package_present")
        ? item.package_present
        : false;
      this.snpswap_pmr_3p_pad = hp.call(item, "snpswap_pmr_3p_pad")
        ? item.snpswap_pmr_3p_pad
        : "";
      this.snpswap_pmr_5p_pad = hp.call(item, "snpswap_pmr_5p_pad")
        ? item.snpswap_pmr_5p_pad
        : "";
      this.snpswap_arm_size = hp.call(item, "snpswap_arm_size")
        ? item.snpswap_arm_size
        : 40;

      // Additional fields for construct only
      this.forwards = hp.call(item, "forwards") ? item.forwards : null;
      this.backwards = hp.call(item, "backwards") ? item.backwards : null;
      this.snpswap_forwards = hp.call(item, "snpswap_forwards")
        ? item.snpswap_forwards
        : null;
      this.snpswap_backwards = hp.call(item, "snpswap_backwards")
        ? item.snpswap_backwards
        : null;
      this.diag_forwards = hp.call(item, "diag_forwards")
        ? item.diag_forwards
        : null;
      this.diag_backwards = hp.call(item, "diag_backwards")
        ? item.diag_backwards
        : null;
      this.diag_upstream_seq = hp.call(item, "diag_upstream_seq")
        ? item.diag_upstream_seq
        : null;
      this.diag_downstream_seq = hp.call(item, "diag_downstream_seq")
        ? item.diag_downstream_seq
        : null;
      this.cpcr_forwards = hp.call(item, "cpcr_forwards")
        ? item.cpcr_forwards
        : null;
      this.cpcr_backwards = hp.call(item, "cpcr_backwards")
        ? item.cpcr_backwards
        : null;
      this.cpcr_upstream_seq = hp.call(item, "cpcr_upstream_seq")
        ? item.cpcr_upstream_seq
        : null;
      this.cpcr_upstream_seq2 = hp.call(item, "cpcr_upstream_seq2")
        ? item.cpcr_upstream_seq2
        : null;
      this.cpcr_downstream_seq = hp.call(item, "cpcr_downstream_seq")
        ? item.cpcr_downstream_seq
        : null;
      this.cpcr_downstream_seq2 = hp.call(item, "cpcr_downstream_seq2")
        ? item.cpcr_downstream_seq2
        : null;
      this.pmr_diag_mode = hp.call(item, "pmr_diag_mode")
        ? item.pmr_diag_mode
        : null;

      // Additional fields for primer only
      this.origin_3p = hp.call(item, "origin_3p") ? item.origin_3p : null;
      this.primer_type = hp.call(item, "primer_type") ? item.primer_type : null;
      this.reverse = hp.call(item, "reverse") ? item.reverse : false;
      this.tm = hp.call(item, "tm") ? item.tm : null;
      this.tm_3p = hp.call(item, "tm_3p") ? item.tm_3p : null;

      // Additional fields for user only
      this.user_id = hp.call(item, "user_id") ? item.user_id : null;
      this.role = hp.call(item, "role") ? item.role : null;
      this.email = hp.call(item, "email") ? item.email : null;
      this.passwd = hp.call(item, "passwd") ? item.passwd : null;
      this.confirmed_passwd = hp.call(item, "confirmed_passwd")
        ? item.confirmed_passwd
        : null;
      this.photo = hp.call(item, "photo") ? item.photo : null;
      let uSel = hp.call(item, "selected") ? item.selected : null;
      this.selected = {
        cart_id: "",
        construct_id: "",
        package_id: "",
        project_id: ""
      };
      if (uSel) {
        this.selected.cart_id = hp.call(uSel, "cart_id") ? uSel.cart_id : "";
        this.selected.construct_id = hp.call(uSel, "construct_id")
          ? uSel.construct_id
          : "";
        this.selected.package_id = hp.call(uSel, "package_id")
          ? uSel.package_id
          : "";
        this.selected.project_id = hp.call(uSel, "project_id")
          ? uSel.project_id
          : "";
      }
      let raKeys = hp.call(item, "ra_keys") ? item.ra_keys : null;
      this.ra_keys = {
        project_ids: null,
        strain_ids: null
      };
      if (raKeys) {
        this.ra_keys.project_ids = hp.call(raKeys, "project_ids")
          ? raKeys.project_ids
          : null;
      }
    }

    // This feedback can change depending of input state
    this.invalidSeqFeedback = "Sequence is a required field";
    this.invalidSeqFeedbackInfo = "";
  }

  static getInvalidFeedbacks() {
    let fbMessage = {
      name: "Name is a required field",
      email: "email format is invalid",
      startDate: "Start date is a required field",
      libraryID: "Library ID is a required field",
      seq_origin: "Sequence Origin is a required field",
      parentID: "Parent ID is a required field",
      password: "password has to be 8 or more characters",
      password_confirmed: "passwords must match"
    };
    return fbMessage;
  }

  initItem() {
    OlabUtils.infoLog("OlabItem.initItem() ...");
    // true if duplicate sequence is found else false
    this.seqExisted = false;
    this.old_seq = null;

    // Common fields: internal use
    this.olab_id = null;
    this.olab_type = null;
    this.deleted = false;

    // Common fields: User settable
    this.name = null;
    this.creator = null;
    this.desc = null;
    this.desc_hyperlink = null;
    this.download_file = null;

    // Additional fields for library_sequence
    this.library_id = null;
    this.seq_origin = null;
    this.seq = null;
    this.gene_stats = null;

    // Additional fields for microbe
    this.microbe_type = null;
    this.chrm_count = 0;
    this.mito_count = 0;

    // Additional fields for strain
    this.microbe_id = null;
    this.parent_id = null;
    this.project_id = null;
    this.source = null;
    this.wgs_confirmed = false;
    this.contig = false;
    this.contig_array = [];
    this.chrm_array = [];
    this.mito_array = [];
    this.plmd_array = [];
    this.csv_file = null;
    this.fasta_file = null;
    this.genbank_file = null;
    this.stats = null;

    // Additional fields for strain
    this.plasmid_ori = null;
    this.meta_map = null;
    this.diag_pmr_exists = false;

    // Additional field for project and strain
    this.ra_locks = {
      public: false,
      members: null
    };
    this.start_date = null;
    this.end_date = null;

    // Additional field for package
    this.package_seq_type = "library_sequence";
    this.package_type = null;

    // Additional field for cart and package
    this.comps = [];
    this.cstbs = [];

    // Additional field for design
    this.dsgn_boxes = [];
    this.configured = false;
    this.pmr_setting = "FULL";
    this.pmr_3p_len = 20;
    this.pmr_5p_len = 20;
    this.package_present = false;
    this.snpswap_pmr_3p_pad = "";
    this.snpswap_pmr_5p_pad = "";
    this.snpswap_arm_size = 40;

    // Additional field for construct
    this.forwards = [];
    this.backwards = [];
    this.snpswap_forwards = [];
    this.snpswap_backwards = [];
    this.diag_forwards = [];
    this.diag_backwards = [];
    this.diag_upstream_seq = null;
    this.diag_downstream_seq = null;
    this.cpcr_forwards = [];
    this.cpcr_backwards = [];
    this.cpcr_upstream_seq = null;
    this.cpcr_upstream_seq2 = null;
    this.cpcr_downstream_seq = null;
    this.cpcr_downstream_seq2 = null;
    this.pmr_diag_mode = null;

    // Additional fields for primer only
    this.origin_3p = null;
    this.primer_type = null;
    this.reverse = false;
    this.tm = null;
    this.tm_3p = null;

    // Additional fields for user only
    this.user_id = null;
    this.role = null;
    this.email = null;
    this.passwd = null;
    this.confirmed_passwd = null;
    this.photo = null;
    this.selected = null;
    this.ra_keys = null;
  }

  set(item) {
    this.seqExisted = item.seqExisted;
    this.old_seq = item.old_seq;

    this.olab_id = item.olab_id;
    this.olab_type = item.olab_type;
    this.deleted = item.deleted;
    this.name = item.name;
    this.library_id = item.library_id;
    this.desc = item.desc;
    this.desc_hyperlink = item.desc_hyperlink;
    this.download_file = item.download_file;
    this.source = item.source;
    this.creator = item.creator;
    this.seq_origin = item.seq_origin;
    this.seq = item.seq;
    // Note: Shallow set (only set reference)
    this.stats = item.stats;
    this.gene_stats = item.gene_stats;
    this.microbe_type = item.microbe_type;
    this.chrm_count = item.chrm_count;
    this.mito_count = item.mito_count;
    this.microbe_id = item.microbe_id;
    this.plasmid_ori = item.plasmid_ori;
    this.meta_map = item.meta_map;
    this.diag_pmr_exists = item.diag_pmr_exists;
    this.parent_id = item.parent_id;
    this.project_id = item.project_id;
    this.wgs_confirmed = item.wgs_confirmed;
    this.contig = item.contig;
    this.contig_array = item.contig_array;
    this.chrm_array = item.chrm_array;
    this.mito_array = item.mito_array;
    this.plmd_array = item.plmd_array;
    this.csv_file = item.csv_file;
    this.fasta_file = item.fasta_file;
    this.genbank_file = item.genbank_file;
    this.start_date = item.start_date;
    this.end_date = item.end_date;
    this.package_seq_type = item.package_seq_type;
    this.package_type = item.package_type;
    this.comps = item.comps;
    this.cstbs = item.cstbs;
    this.dsgn_boxes = item.dsgn_boxes;
    this.configured = item.configured;
    this.pmr_setting = item.pmr_setting;
    this.pmr_3p_len = item.pmr_3p_len;
    this.pmr_5p_len = item.pmr_5p_len;
    this.package_present = item.package_present;
    this.snpswap_pmr_3p_pad = item.snpswap_pmr_3p_pad;
    this.snpswap_pmr_5p_pad = item.snpswap_pmr_5p_pad;
    this.snpswap_arm_size = item.snpswap_arm_size;
    this.forwards = item.forwards;
    this.backwards = item.backwards;
    this.snpswap_forwards = item.snpswap_forwards;
    this.snpswap_backwards = item.snpswap_backwards;
    this.diag_forwards = item.diag_forwards;
    this.diag_backwards = item.diag_backwards;
    this.diag_upstream_seq = item.diag_upstream_seq;
    this.diag_downstream_seq = item.diag_downstream_seq;
    this.cpcr_forwards = item.cpcr_forwards;
    this.cpcr_backwards = item.cpcr_backwards;
    this.cpcr_upstream_seq = item.cpcr_upstream_seq;
    this.cpcr_upstream_seq2 = item.cpcr_upstream_seq2;
    this.cpcr_downstream_seq = item.cpcr_downstream_seq;
    this.cpcr_downstream_seq2 = item.cpcr_downstream_seq2;
    this.pmr_diag_mode = item.pmr_diag_mode;
    this.origin_3p = item.origin_3p;
    this.primer_type = item.primer_type;
    this.reverse = item.reverse;
    this.tm = item.tm;
    this.tm_3p = item.tm_3p;
    this.user_id = item.user_id;
    this.role = item.role;
    this.email = item.email;
    this.passwd = item.passwd;
    this.confirmed_passwd = item.confirmed_passwd;
    this.photo = item.photo;
    this.selected = item.selected;
    this.ra_locks = item.ra_locks;
    this.ra_keys = item.ra_keys;
  }

  // Refactor and move this method to OlabCst
  setUpstreamSeq(upstreamSeq, pmrType) {
    // console.log("OlabItem: setUpstreamSeq: upstreamSeq = " + upstreamSeq);
    // console.log("OlabItem: setUpstreamSeq: pmrType = " + pmrType);
    if (pmrType === "SEQ") {
      this.diag_upstream_seq = upstreamSeq;
    } else if (pmrType === "CPCR_AUTO") {
      this.cpcr_upstream_seq = upstreamSeq;
    } else if (pmrType === "CPCR_END_FIX") {
      this.cpcr_upstream_seq2 = upstreamSeq;
    } else {
      console.log(
        "OlabItem: setUpstreamSeq: No Error unknown pmrType = " + pmrType
      );
    }
  }

  // Refactor and move this method to OlabCst
  setDownstreamSeq(downstreamSeq, pmrType) {
    // console.log("OlabItem: setDownstreamSeq: downstreamSeq = " + downstreamSeq);
    // console.log("OlabItem: setDownstreamSeq: pmrType = " + pmrType);
    if (pmrType === "SEQ") {
      this.diag_downstream_seq = downstreamSeq;
    } else if (pmrType === "CPCR_AUTO") {
      this.cpcr_downstream_seq = downstreamSeq;
    } else if (pmrType === "CPCR_END_FIX") {
      this.cpcr_downstream_seq2 = downstreamSeq;
    } else {
      console.log(
        "OlabItem: setDownstreamSeq: No Error unknown pmrType = " + pmrType
      );
    }
  }

  cloneRaKeysAndLocks() {
    const locks = {
      public: false,
      members: null
    };
    if (this.ra_locks) {
      locks.public = this.ra_locks.public;
      if (this.ra_locks.members) {
        locks.members = this.ra_locks.members.slice();
      }
    }
    const keys = {
      project_ids: null,
      strain_ids: null
    };
    if (this.ra_keys) {
      if (this.ra_keys.project_ids) {
        keys.project_ids = this.ra_keys.project_ids.slice();
      }
      if (this.ra_keys.strain_ids) {
        keys.strain_ids = this.ra_keys.strain_ids.slice();
      }
    }
    this.ra_locks = locks;
    this.ra_keys = keys;
  }

  getSeqValidState() {
    return this.processSeq() ? "is-valid" : "is-invalid";
  }

  setSeqExisted(status, message) {
    this.invalidSeqFeedbackInfo = message;
    if (status) {
      this.seqExisted = true;
      this.getSeqValidState();
    } else {
      this.seqExisted = false;
    }
  }

  computeSeqCount(cType) {
    let cnt = 0;
    if (cType && this.seq) {
      cType = cType.toUpperCase();
      for (let i = 0; i < this.seq.length; i++) {
        if (this.seq[i].toUpperCase() == cType) {
          cnt++;
        }
      }
    }
    return cnt;
  }

  computeGeneStats() {
    // Compute seq's gene_stats
    const numA = this.computeSeqCount("A");
    const numC = this.computeSeqCount("C");
    const numG = this.computeSeqCount("G");
    const numT = this.computeSeqCount("T");
    let shortSeq = this.seq.substring(0, 25);
    if (this.seq.length > 25) {
      shortSeq = shortSeq + "...";
    }
    this.gene_stats = {
      len: this.seq.length,
      num_a: numA,
      num_c: numC,
      num_g: numG,
      num_t: numT,
      short_seq: shortSeq
    };
  }

  processSeq() {
    let seqValid = true;
    let invalidFB = "";

    if (this.seqExisted && this.old_seq !== this.seq) {
      // Reset seqExisted since it is a new seq
      this.seqExisted = false;
    }

    // Preprocess seq
    if (this.seq) {
      this.seq = this.seq.toUpperCase();
      // console.log("==== " + this.seq);
      // only keep a-zA-Z and remove other characters from seq
      this.seq = this.seq.replace(/[^a-zA-Z]/g, "");
      // console.log(">>>> " + this.seq);
    }

    if (!this.library_id) {
      seqValid = false;
      invalidFB = "Need to select a valid library. Library ID can't be null";
    } else if (!this.seq) {
      seqValid = false;
      invalidFB = "Need to specify a sequence";
    } else if (this.seqExisted === true) {
      seqValid = false;
      invalidFB = this.invalidSeqFeedbackInfo;
    } else {
      // console.log("this.library_id = " + this.library_id);
      if (OlabUtils.subStrB4Space(this.library_id) == "LA") {
        // LA: Need to use this characters
        // http://130.88.97.239/bioactivity/aacodefrm.html
        seqValid = !this.seq.match(/[^acdefghiklmnpqrstvwy]/gi);
        invalidFB =
          "Sequence has to be [a,c,d,e,f,g,h,i,k,l,m,n,p,q,r,s,t,v,w,y]";
      } else {
        seqValid = !this.seq.match(/[^acgt]/gi);
        invalidFB = "Sequence has to be [a,c,g,t]";
      }
    }
    // console.log("seq = " + this.seq + ", valid = " + seqValid);
    this.old_seq = this.seq;
    this.invalidSeqFeedback = invalidFB;
    return seqValid;
  }

  _processItemField(fieldName) {
    return fieldName == null || fieldName == "" ? "is-invalid" : "is-valid";
  }

  getValidState(fieldName) {
    // OlabUtils.infoLog("getValidState: fieldName = " + fieldName);
    return this._processItemField(fieldName);
  }

  isValid(fieldName) {
    return this._processItemField(fieldName) == "is-valid";
  }

  getValidPWState(blankOK) {
    // console.log("passwd = " + this.passwd);
    let result = null;
    if (blankOK && !this.passwd) {
      result = "";
    } else if (this.passwd !== null) {
      if (this.passwd.length < 8) {
        result = "is-invalid";
      } else {
        result = "is-valid";
      }
    } else {
      result = "is-invalid";
    }
    return result;
  }

  getValidCPWState(blankOK) {
    // console.log("confirmed_passwd = " + this.confirmed_passwd);
    let result = null;
    if (blankOK && !this.passwd && !this.confirmed_passwd) {
      result = true;
    } else if (this.passwd !== null && this.confirmed_passwd !== null) {
      if (this.passwd !== this.confirmed_passwd) {
        result = "is-invalid";
      } else {
        result = "is-valid";
      }
    } else {
      result = "is-invalid";
    }
    return result;
  }

  isValidPW(pwd, cPwd, createCheck) {
    let result = false;
    if ((!createCheck || (pwd && cPwd && pwd.length >= 8)) && pwd === cPwd) {
      result = true;
    }
    return result;
  }

  isItemValid(selectedType, createCheck) {
    switch (selectedType) {
      case "project":
        return (
          this.isValid(this.name) &&
          this.isValid(this.creator) &&
          this.isValid(this.start_date)
        );
      // break;
      case "cart":
      case "design":
        return (
          this.isValid(this.name) &&
          this.isValid(this.creator) &&
          this.isValid(this.project_id)
        );
      // break;
      case "package":
        return (
          this.isValid(this.name) &&
          this.isValid(this.creator) &&
          this.isValid(this.package_type)
        );
      // break;
      case "plasmid":
        return (
          this.isValid(this.name) &&
          this.isValid(this.plasmid_ori) &&
          this.isValid(this.source) &&
          // this.isValid(this.csv_file) &&
          // this.isValid(this.fasta_file) &&
          // this.isValid(this.genbank_file) &&
          this.isValid(this.creator)
        );
      // break;
      case "microbe":
        return (
          this.isValid(this.name) &&
          this.isValid(this.microbe_type) &&
          this.isValid(this.chrm_count) &&
          this.isValid(this.mito_count) &&
          this.isValid(this.creator)
        );
      // break;
      case "strain":
        return (
          this.isValid(this.name) &&
          // this.isValid(this.microbe_id) &&
          // this.isValid(this.parent_id) &&
          this.isValid(this.source) &&
          // this.isValid(this.csv_file) &&
          // this.isValid(this.fasta_file) &&
          // this.isValid(this.genbank_file) &&
          this.isValid(this.creator)
        );
      // break;
      case "library_sequence":
        return (
          (this.isValid(this.csv_file) ||
            (this.isValid(this.name) &&
              this.isValid(this.library_id) &&
              this.isValid(this.seq_origin) &&
              this.isValid(this.source) &&
              this.processSeq())) &&
          this.isValid(this.creator)
        );
      // break;
      case "user":
        return (
          this.isValid(this.name) &&
          OlabUtils.validateEmail(this.email) &&
          this.isValid(this.role) &&
          this.isValid(this.user_id) &&
          this.isValidPW(this.passwd, this.confirmed_passwd, createCheck)
        );
      // break;
    }
    // console.log("OlabeItem.isItemValid: No selectedType found!!!");
    return false;
  }
}

export { OlabItem };

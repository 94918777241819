<template>
  <div>
    <div class="list-group list-group-flush">
      <div
        class="list-group-item d-flex mb-1 align-items-center"
        v-for="sid in selectedItems"
        :key="sid.olab_id"
      >
        <div class="container">
          <div class="row">
            <div
              v-if="selectedType === 'project'"
              class="col-1 align-self-center"
            >
              <div
                v-if="
                  selectedProject && sid.olab_id === selectedProject.olab_id
                "
              >
                <button
                  type="button"
                  class="btn btn-success btn"
                  v-on:click="handleSelectProject(sid)"
                >
                  <font-awesome-icon icon="check-square" />
                </button>
              </div>
              <div v-else>
                <button
                  type="button"
                  class="btn btn-info btn-sm"
                  v-on:click="handleSelectProject(sid)"
                >
                  <font-awesome-icon icon="square" />
                </button>
              </div>
            </div>
            <div
              v-else-if="selectedType === 'cart'"
              class="col-1 align-self-center"
            >
              <div
                v-if="
                  user.email === sid.creator &&
                    selectedCart &&
                    sid.olab_id === selectedCart.olab_id
                "
              >
                <button
                  type="button"
                  class="btn btn-success btn"
                  v-on:click="handleSelectCart(sid)"
                >
                  <font-awesome-icon icon="check-square" />
                </button>
              </div>
              <div v-else-if="user.email === sid.creator">
                <button
                  type="button"
                  class="btn btn-info btn-sm"
                  v-on:click="handleSelectCart(sid)"
                >
                  <font-awesome-icon icon="square" />
                </button>
              </div>
              <div v-else class="col-1 align-self-center">
                <!-- NOP -->
              </div>
            </div>
            <div
              v-else-if="!selectedCart && selectedType === 'package'"
              class="col-1 align-self-center"
            >
              <div
                v-if="
                  user.email === sid.creator &&
                    selectedPackage &&
                    sid.olab_id === selectedPackage.olab_id
                "
              >
                <button
                  type="button"
                  class="btn btn-success btn"
                  v-on:click="handleSelectPackage(sid)"
                >
                  <font-awesome-icon icon="check-square" />
                </button>
              </div>
              <div v-else-if="user.email === sid.creator">
                <button
                  type="button"
                  class="btn btn-info btn-sm"
                  v-on:click="handleSelectPackage(sid)"
                >
                  <font-awesome-icon icon="square" />
                </button>
              </div>
              <div v-else class="col-1 align-self-center">
                <!-- NOP -->
              </div>
            </div>
            <div
              v-else-if="selectedCart && !cartIsFull && selectedType !== 'cart'"
              class="col-1 align-self-center"
            >
              <button
                type="button"
                class="btn btn-info"
                data-toggle="modal"
                data-target="#addSelectItem"
                v-on:click="registerItem(sid)"
                data-backdrop="static"
                data-keyboard="false"
              >
                <font-awesome-icon icon="plus" />
              </button>
            </div>
            <div
              v-else-if="
                enablePackageAdd &&
                  sid.library_id === selectedPackage.library_id &&
                  notInPackage(sid, selectedPackage.comps)
              "
              class="col-1 align-self-center"
            >
              <button
                type="button"
                class="btn btn-info"
                data-toggle="modal"
                data-target="#addSelectItem"
                v-on:click="registerItem(sid)"
                data-backdrop="static"
                data-keyboard="false"
              >
                <font-awesome-icon icon="plus" />
              </button>
            </div>
            <div v-else class="col-1 align-self-center">
              <!-- NOP -->
            </div>

            <div
              v-if="selectedType === 'project'"
              class="col-10 col-md-5 align-self-center"
            >
              <OlabProjectSVGGen
                name="Project"
                textPosX="160"
                textPosY="280"
                :fillColor="'compdb'"
              />
            </div>

            <div
              v-if="selectedType === 'cart'"
              class="col-10 col-md-5 align-self-center"
            >
              <OlabCartSVGGen :comps="sid.comps" :fillColor="'compdb'" />
            </div>

            <div
              v-if="selectedType === 'package'"
              class="col-10 col-md-5 align-self-center"
            >
              <OlabPackageSVGGen
                :type="sid.package_type"
                :seqType="sid.package_seq_type"
                :comps="sid.comps"
                :fillColor="'compdb'"
              />
            </div>

            <div
              v-if="selectedType === 'library_sequence'"
              class="col-10 col-md-5 align-self-center"
            >
              <OlabSequenceSVGGen
                v-if="sid && sid.gene_stats"
                :numA="sid.gene_stats.num_a"
                :numC="sid.gene_stats.num_c"
                :numG="sid.gene_stats.num_g"
                :numT="sid.gene_stats.num_t"
                :shortSeq="sid.gene_stats.short_seq"
                :len="sid.gene_stats.len"
              />
            </div>

            <div class="col-12 col-md-6 mt-2 align-self-center text-truncate">
              <a
                class="h4 text-info"
                data-toggle="modal"
                href="#viewItem"
                v-on:click="registerItem(sid)"
                data-backdrop="static"
                data-keyboard="false"
                >{{ sid.name }}</a
              >

              <div v-if="selectedType === 'library_sequence'" class="h6 mt-2">
                {{ sid.seq_origin }}
              </div>
              <div class="h6 mt-2">{{ sid.olab_id }}</div>
              <div class="h6">{{ sid.creator }}</div>

              <!--  Buttons -->
              <div
                v-if="
                  selectedProject &&
                    (selectedType === 'cart' || selectedType === 'package')
                "
                class="h5"
              >
                <button
                  type="button"
                  class="btn btn-info"
                  data-toggle="modal"
                  data-target="#cloneItem"
                  v-on:click="preCloneItem(sid)"
                  data-backdrop="static"
                  data-keyboard="false"
                >
                  Clone {{ selectedTypeName }}
                </button>
              </div>
            </div>

            <!-- Modal: addSelectItem -->
            <div
              v-if="selectedItem != null && (selectedCart || selectedPackage)"
              class="modal fade"
              id="addSelectItem"
            >
              <OlabAddSelectItem
                :selectedType="selectedType"
                :selectedProject="selectedProject"
                :selectedCart="selectedCart"
                :selectedPackage="selectedPackage"
                :selectedItem="selectedItem"
                :selectedItems="selectedItems"
                :statusObj="statusObj"
                :errorObj="errorObj"
                @handleAddToComps="handleAddToComps"
              />
              <!-- .modal-dialog -->
            </div>
            <!-- .modal fade #addSelectItem -->

            <!-- Modal: viewItem -->
            <div v-if="selectedItem != null" class="modal fade" id="viewItem">
              <OlabViewItem
                :dbInfo="dbInfo"
                :selectedType="selectedType"
                :item="selectedItem"
                :itemProjDetail="itemProjDetail"
                :publicAccess="publicAccess"
                :projMemberDetailArr="projMemberDetailArr"
              />
            </div>
            <!-- .modal fade #viewItem -->
          </div>
        </div>
        <div class="modal fade" id="cloneItem">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Clone: {{ selectedTypeName }}</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <!-- .modal-header -->
              <div class="modal-body">
                <form v-on:submit.prevent="onSubmit">
                  <fieldset class="form-group">
                    <!-- <legend>{{ selectedType }} Info</legend> -->
                    <div class="form-group">
                      <label class="form-control-label">
                        <span>Name:</span>
                        <span class="small text-danger">*</span>
                      </label>
                      <input
                        class="form-control"
                        v-bind:class="item.getValidState(item.name)"
                        type="text"
                        v-model="item.name"
                      />
                    </div>
                    <div class="form-group">
                      <label class="form-control-label">
                        <span>Project ID:</span>
                      </label>
                      <span class="ml-1">{{ item.project_id }}</span>
                    </div>
                    <div class="form-group">
                      <label class="form-control-label">Creator:</label>
                      <span class="ml-1">{{ item.creator }}</span>
                    </div>
                    <div v-if="selectedType === 'package'" class="form-group">
                      <span class="form-control-label mr-1">Package Type:</span>
                      <span class="ml-1">
                        {{ item.package_type }} - {{ item.package_seq_type }}
                      </span>
                    </div>
                    <div class="form-group">
                      <label class="form-control-label">Description:</label>
                      <textarea
                        class="form-control"
                        v-model="item.desc"
                      ></textarea>
                    </div>
                    <div
                      v-if="
                        (selectedType === 'cart' ||
                          selectedType === 'package') &&
                          item.comps
                      "
                    >
                      <OlabCompTable
                        :selectedType="selectedType"
                        :item="item"
                      />
                    </div>
                  </fieldset>
                  <!-- .form-group -->
                </form>
              </div>
              <!-- .modal-body -->

              <div class="modal-footer">
                <button
                  type="button"
                  v-bind:disabled="cloneIsDisabled == true"
                  class="btn btn-primary"
                  data-dismiss="modal"
                  v-on:click="handleCloneItem(item)"
                >
                  <span
                    v-if="statusObj.progress"
                    class="spinner-border spinner-border-sm text-light"
                  ></span>
                  Clone
                </button>
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
              <!-- .modal-footer -->
            </div>
            <!-- .modal-content -->
          </div>
          <!-- .modal-dialog .modal-lg-->
        </div>
        <!-- .modal fade cloneItem -->
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import OlabProjectSVGGen from "@/components/OlabProjectSVGGen";
import OlabCartSVGGen from "@/components/OlabCartSVGGen";
import OlabPackageSVGGen from "@/components/OlabPackageSVGGen";
import OlabSequenceSVGGen from "@/components/OlabSequenceSVGGen";
import OlabViewItem from "@/components/OlabViewItem";
import OlabAddSelectItem from "@/components/OlabAddSelectItem";
import OlabCompTable from "@/components/OlabCompTable";
import { OlabAxios } from "../olab/olabaxios.js";
import { OlabUtils } from "../olab/olabutils.js";
import { OlabSearch } from "../olab/olabsearch";
import _ from "lodash";

export default {
  name: "compdbitemlist",
  data: function() {
    return {
      selectedItem: null,
      itemProjDetail: "",
      publicAccess: false,
      projMemberDetailArr: [],
      userProjectDetailArr: []
    };
  },
  props: [
    "user",
    "dbInfo",
    "statusObj",
    "errorObj",
    "selectedItems",
    "selectedType",
    "selectedTypeName",
    "item",
    "selectedProject",
    "selectedCart",
    "selectedPackage",
    "cartIsFull",
    "packageIsFull"
  ],
  components: {
    FontAwesomeIcon,
    OlabProjectSVGGen,
    OlabCartSVGGen,
    OlabPackageSVGGen,
    OlabSequenceSVGGen,
    OlabViewItem,
    OlabAddSelectItem,
    OlabCompTable
  },
  computed: {
    enablePackageAdd: function() {
      let pkgAddOK =
        !this.selectedCart &&
        this.selectedPackage &&
        this.selectedPackage.package_seq_type == "library_sequence" &&
        !this.packageIsFull &&
        this.selectedType === "library_sequence";
      return pkgAddOK;
    },
    cloneIsDisabled: function() {
      switch (this.selectedType) {
        case "cart":
          return (
            !this.item.isValid(this.item.name) ||
            !this.item.isValid(this.item.creator) ||
            !this.item.isValid(this.item.project_id)
          );
        // break;
        case "package":
          return (
            !this.item.isValid(this.item.name) ||
            !this.item.isValid(this.item.creator)
          );
        // break;
      }
      return false;
    }
  },
  filters: {
    convertCompToStrand: function(value) {
      // console.log("convertCompToStrand: value = " + value);
      return value === true ? "-" : "+";
    }
  },
  methods: {
    notInPackage(sid, comps) {
      let result = true;
      OlabUtils.infoLog("sid.olab_id = " + sid.olab_id);
      OlabUtils.infoLog("comps.length = " + comps.length);
      comps.forEach(comp => {
        if (sid.olab_id == comp.olab_id) {
          OlabUtils.infoLog("sid == comp = " + (sid == comp));
          result = false;
        }
      });
      OlabUtils.infoLog("**** result = " + result);
      return result;
    },
    appendNameToLibrary: function(id, library) {
      if (library != null) {
        for (let i = 0; i < library.length; i++) {
          if (library[i].olab_id == id) {
            return library[i].olab_id + " - " + library[i].name;
          }
        }
      }
      return "";
    },
    preCloneItem: function(sid) {
      OlabUtils.infoLog("preCloneItem");
      this.selectedItem = sid;
      this.item.set(sid);
      if (sid.comps) {
        // Do a shallow clone of sid's comps
        this.item.comps = _.clone(sid.comps);
      }
      // initialize creator with user's email
      this.item.creator = this.user.email;
      // Add project id and name
      this.item.project_id = `${this.selectedProject.olab_id} - ${this.selectedProject.name}`;

      if (sid.olab_type === "cart") {
        // Clean used count and set available count for each comp
        this.item.comps.forEach(comp => {
          comp.used_count = 0;
          comp.available_count = comp.qty;
        });
        // Clone doesn't copy construct box array
        this.item.cstbs = [];
      }

      // Reset name and olab_id
      this.item.name = "";
      this.item.olab_id = "";
    },
    handleCloneItem: async function(item) {
      OlabUtils.infoLog(
        "handleCloneItem: item = " +
          item.olab_id +
          ", name = " +
          item.name +
          ", olab_type = " +
          item.olab_type
      );
      // Remove project name from item.project_id
      item.project_id = OlabUtils.subStrB4Space(item.project_id);

      // Set progress message and status indicator
      this.statusObj.progress = true;
      await OlabAxios.addItem(
        item,
        this.selectedType,
        this.selectedItems,
        this.statusObj,
        this.errorObj
      );
    },
    registerItem: async function(item) {
      this.selectedItem = item;
      // OlabUtils.infoLog("registerItem: item = " + item);
      // Copy data to this.item so that v-model for edit item.
      this.item.set(item);
      try {
        if (this.selectedType === "project") {
          if (this.item.ra_locks) {
            const raLocks = this.item.ra_locks;
            this.publicAccess = raLocks.public;
            if (raLocks.members) {
              // Get members info (user name)
              this.projMemberDetailArr = await OlabUtils.getProjectMembers(
                this.item.olab_id
              );
            }
          }
        } else if (this.selectedType === "user") {
          // Get projects info (project name)
          this.userProjectDetailArr = await OlabUtils.getUserProjects(
            this.item.email
          );
        } else {
          // (this.selectedType !== "user" && this.selectedType !== "project")
          // Load project from item.project_id
          const searchBody = OlabSearch.createItemSearchBody(
            "project",
            this.item.project_id,
            "olab_id",
            false,
            null,
            1,
            1
          );
          const projectArr = await OlabUtils.getProjects(searchBody);
          const proj = projectArr ? projectArr[0] : null;
          if (proj) {
            this.itemProjDetail = `${proj.olab_id} - ${proj.name}`;
          } else {
            OlabUtils.infoLog("No such project document!");
          }
        }
      } catch (err) {
        OlabUtils.infoLog("Error writing document: " + err);
        this.errorObj.message = err;
      }
    },
    handleSelectProject: function(pj) {
      OlabUtils.infoLog("handleSelectProject: pj = " + pj.olab_id);
      this.$emit("doSelectProject", pj);
    },
    handleSelectCart: function(ct) {
      OlabUtils.infoLog("handleSelectCart: ct = " + ct.olab_id);
      this.$emit("doSelectCart", ct);
    },
    handleAddToComps: function({ item, type }) {
      // console.log("handleAddToComps: item = ", item, ", type = ", type);

      let useSeqAs;
      if (type === "library_sequence") {
        // Add the follow field for comps use
        item.length = item.seq.length;

        switch (item.library_id) {
          case "LG":
            useSeqAs = "gene";
            break;
          case "LH":
            useSeqAs = "arm";
            break;
          case "LP":
            useSeqAs = "promoter";
            break;
          case "LR":
            useSeqAs = "rbs";
            break;
          case "LT":
            useSeqAs = "terminator";
            break;
          case "LV":
            useSeqAs = "plasmid";
            break;
          case "LW":
            useSeqAs = "pgst";
            break;
          default:
            // Set default as "gene"
            useSeqAs = "gene";
            break;
        }
      } else if (type === "package") {
        // Add the follow field for comps use
        useSeqAs = item.package_type;
      } else {
        useSeqAs = "UNKNOWN";
      }
      // console.log(
      //   "handleAddToComps: item.library_id =",
      //   item.library_id,
      //   ", useSeqAs =" + useSeqAs
      // );
      this.$emit("doAddToComps", { item: item, useSeqAs: useSeqAs });
    },
    handleSelectPackage: function(pkg) {
      OlabUtils.infoLog("handleSelectPackage: pkg = " + pkg.olab_id);
      this.$emit("doSelectPackage", pkg);
    },
    onSubmit() {
      // This is a NOP to prevent page reload on return key
      // console.log("After Submit");
    }
  }
};
</script>

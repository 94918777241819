<template>
  <div v-if="cstObj">
    <legend class="pt-3">Primers</legend>
    <div class="py-2 list-group list-group-flush">
      <div
        class="list-group-item mb-1"
        v-for="(cstb, index) in cstObj.cstbs"
        :key="index"
      >
        <div v-bind:class="cstb.use_seq_as_color">
          {{ cstb.display_name }}
        </div>
        <div
          v-if="cstObj.forwards[index].olab_id"
          class="form-text text-left text-dark bg-light"
        >
          <div class="mb-1">
            <span class="small text-secondary mr-1">Name:</span>
            <span class="text-dark mr-2"
              >{{ cstObj.forwards[index].name }}
            </span>
            <span class="small text-secondary mr-1">ID:</span>
            <span class="text-dark mr-2"
              >{{ cstObj.forwards[index].olab_id }}
            </span>
            <span class="small text-secondary mr-1">Length:</span>
            <span class="text-dark mr-2"
              >{{ cstObj.forwards[index].seq.length }}
            </span>
            <span class="small text-secondary mr-1">Tm Full:</span>
            <span class="text-dark mr-2"
              >{{ cstObj.forwards[index].tm.toFixed(1) }}&#8451;
            </span>
            <span class="small text-secondary mr-1">Tm 3':</span>
            <span v-if="cstObj.forwards[index].tm3p" class="text-dark mr-2"
              >{{ cstObj.forwards[index].tm3p.toFixed(1) }}&#8451;
            </span>
            <span v-else class="text-dark mr-2"
              >{{ cstObj.forwards[index].tm_3p.toFixed(1) }}&#8451;
            </span>
          </div>
          <small>
            <span class="text-primary">
              <font-awesome-icon icon="long-arrow-alt-right" />
            </span>
            <span class="text-monospace text-primary ml-1"
              >{{ cstObj.forwards[index].seq }}
            </span>
          </small>
        </div>
        <div v-else class="form-text text-left text-dark bg-light">
          <div class="mb-1">
            <span class="small text-secondary mr-1">No forward primer</span>
          </div>
        </div>
        <div
          v-if="cstObj.backwards[index].olab_id"
          class="form-text text-left text-dark bg-light"
        >
          <div class="mb-1">
            <span class="small text-secondary mr-1">Name:</span>
            <span class="text-dark mr-2"
              >{{ cstObj.backwards[index].name }}
            </span>
            <span class="small text-secondary mr-1">ID:</span>
            <span class="text-dark mr-2"
              >{{ cstObj.backwards[index].olab_id }}
            </span>
            <span class="small text-secondary mr-1">Length:</span>
            <span class="text-dark mr-2"
              >{{ cstObj.backwards[index].seq.length }}
            </span>
            <span class="small text-secondary mr-1">Tm Full:</span>
            <span class="text-dark mr-2"
              >{{ cstObj.backwards[index].tm.toFixed(1) }}&#8451;
            </span>
            <span class="small text-secondary mr-1">Tm 3':</span>
            <span v-if="cstObj.backwards[index].tm3p" class="text-dark mr-2"
              >{{ cstObj.backwards[index].tm3p.toFixed(1) }}&#8451;
            </span>
            <span v-else class="text-dark mr-2"
              >{{ cstObj.backwards[index].tm_3p.toFixed(1) }}&#8451;
            </span>
          </div>
          <small>
            <span class="text-danger">
              <font-awesome-icon icon="long-arrow-alt-left" />
            </span>
            <span class="text-monospace text-danger ml-1"
              >{{ cstObj.backwards[index].seq }}
            </span>
          </small>
        </div>
        <div v-else class="form-text text-left text-dark bg-light">
          <div class="mb-1">
            <span class="small text-secondary mr-1">No reverse primer</span>
          </div>
        </div>
      </div>
    </div>
    <legend class="pt-3">Construct</legend>
    <div class="py-2 text-left text-monospace small">
      <span
        style="word-break: break-all"
        v-for="(cstb, index) in cstObj.cstbs"
        :key="index"
      >
        <span v-bind:class="cstb.use_seq_as_color">{{ cstb.seq }}</span>
      </span>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "olabconstruct",
  props: ["cstObj"],
  components: {
    FontAwesomeIcon
  }
};
</script>

import { OlabUtils } from "./olabutils.js";

class OlabDownload {
  // box_id,box_name,box_size,
  // primer_name_fwd,primer_sequence_fwd,primer_length_fwd,primer_tm_fwd,
  // primer_name_rev,primer_sequence_rev,primer_length_rev,primer_tm_rev,
  // design_id,construct_id

  constructor(dl) {
    OlabUtils.infoLog("OlabDownload.constructor() ...");
    this.olab_id = dl.olab_id;
    this.name = null;
  }

  static obj2csv(obj, opt) {
    if (typeof obj !== "object") return null;
    opt = opt || {};
    let scopechar = opt.scopechar || "/";
    let delimeter = opt.delimeter || ",";
    let hp = Object.prototype.hasOwnProperty;
    if (Array.isArray(obj) === false) obj = [obj];
    let curs,
      name,
      rownum,
      key,
      queue,
      values = [],
      rows = [],
      headers = {},
      headersArr = [];
    for (rownum = 0; rownum < obj.length; rownum++) {
      queue = [obj[rownum], ""];
      rows[rownum] = {};
      while (queue.length > 0) {
        name = queue.pop();
        curs = queue.pop();
        if (curs !== null && typeof curs === "object") {
          for (key in curs) {
            if (hp.call(curs, key)) {
              queue.push(curs[key]);
              queue.push(name + (name ? scopechar : "") + key);
            }
          }
        } else {
          if (headers[name] === undefined) headers[name] = true;
          rows[rownum][name] = curs;
        }
      }
      values[rownum] = [];
    }
    // create csv text
    for (key in headers) {
      if (hp.call(headers, key)) {
        headersArr.push(key);
        for (rownum = 0; rownum < obj.length; rownum++) {
          values[rownum].push(
            rows[rownum][key] === undefined
              ? ""
              : JSON.stringify(rows[rownum][key])
          );
        }
      }
    }
    for (rownum = 0; rownum < obj.length; rownum++) {
      values[rownum] = values[rownum].join(delimeter);
    }
    return (
      '"' + headersArr.join('"' + delimeter + '"') + '"\n' + values.join("\n")
    );
  }

  static infoLog(dl) {
    OlabUtils.infoLog(" - dl.olab_id = " + dl.olab_id);
    OlabUtils.infoLog(" - dl.name = " + dl.name);
  }
}

export { OlabDownload };

<template>
  <div v-if="user">
    <!-- <button
      type="button"
      class="btn btn-outline-success ml-1"
      data-toggle="modal"
      data-target="#infoProfile"
      data-backdrop="static"
      data-keyboard="false"
    >
      <font-awesome-icon icon="info" />
    </button> -->
    <div class="modal fade" id="infoProfile">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Information</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <!-- .modal-header -->
          <div class="modal-body">
            <div v-if="selectedTypeName" class="pt-2">
              <span>Name:</span>
              <span class="h5 text-dark ml-1">{{ selectedTypeName }}</span>
            </div>

            <div class="py-1">
              <div class="card">
                <div class="card-header text-dark">Description</div>
                <div class="card-body">
                  <div class="py-1 text-dark">
                    This Contextual Box Gene Design System powered by OLAB
                    Bioscience Informatics
                  </div>
                </div>
                <!-- .card-body -->
              </div>
              <!-- .card -->
            </div>
            <div v-if="selectedCart" class="py-1">
              <div class="card">
                <div class="card-header text-dark">
                  Cart: {{ selectedCart.olab_id }} - {{ selectedCart.name }}
                </div>
                <div class="card-body">
                  <div v-if="selectedCart.comps">
                    <div class="list-group list-group-flush">
                      <div
                        class="list-group-item d-flex mb-1 align-items-center"
                        v-for="(comp, index) in selectedCart.comps"
                        :key="index"
                      >
                        <div class="col-8 text-left text-dark">
                          <div class="my-1">
                            <span class="text-secondary mr-1">OLAB ID:</span>
                            <span>{{ comp.olab_id }}</span>
                            <span v-if="comp.length" class="ml-3">
                              <span class="text-secondary mr-1">Length:</span>
                              <span>{{ comp.length }}</span>
                            </span>
                          </div>
                          <div class="my-1">
                            <span v-if="comp.name">
                              <span class="text-secondary mr-1">Name:</span>
                              <span class="mr-3">{{ comp.name }}</span>
                            </span>
                            <span v-if="comp.use_seq_as">
                              <span class="text-secondary mr-1">Use As:</span>
                              <span>{{ comp.use_seq_as }}</span>
                            </span>
                          </div>
                          <div class="my-1">
                            <span v-if="comp.old_locus_tag">
                              <span class="text-secondary mr-1"
                                >Old Locus Tag:</span
                              >
                              <span>{{ comp.old_locus_tag }}</span>
                            </span>
                            <span v-if="comp.locus_tag" class="ml-3">
                              <span class="text-secondary mr-1"
                                >Locus Tag:</span
                              >
                              <span>{{ comp.locus_tag }}</span>
                            </span>
                          </div>
                        </div>
                        <div class="col-4 text-right">
                          <div class="my-1">
                            <span class="text-secondary small mr-1"
                              >Quantity:</span
                            >
                            <span class="h4 mr-1">
                              {{ comp.qty }}
                            </span>
                          </div>
                          <div class="my-1">
                            <span class="text-secondary small mr-1"
                              >Available:</span
                            >
                            <span class="h4 mr-1">
                              {{ comp.available_count }}
                            </span>
                          </div>
                          <div class="my-1">
                            <span class="text-secondary small mr-1">Used:</span>
                            <span class="h4 mr-1">
                              {{ comp.used_count }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="py-1 text-right mr-5">
                      <span class="mr-2 text-secondary">Total Quantity:</span>
                      <span class="h3">{{ cartQty }}</span>
                    </div>
                  </div>
                </div>
                <!-- .card-body -->
              </div>
              <!-- .card -->
            </div>
            <div v-else-if="selectedPackage" class="py-1">
              <div class="card">
                <div class="card-header text-dark">
                  <span>
                    Package: {{ selectedPackage.olab_id }} -
                    {{ selectedPackage.name }}
                  </span>
                  <span class="ml-2">
                    ({{ selectedPackage.package_type }} -
                    {{ selectedPackage.package_seq_type }})
                  </span>
                </div>
                <div class="card-body">
                  <div v-if="selectedPackage.comps">
                    <div class="list-group list-group-flush">
                      <div
                        class="list-group-item d-flex mb-1 align-items-center"
                        v-for="(comp, index) in selectedPackage.comps"
                        :key="index"
                      >
                        <div class="col-6 text-left small text-dark">
                          <div class="my-1">
                            <span class="text-secondary mr-1">OLAB ID:</span>
                            <span>{{ comp.olab_id }}</span>
                            <span v-if="comp.length" class="ml-3">
                              <span class="text-secondary mr-1">Length:</span>
                              <span>{{ comp.length }}</span>
                            </span>
                          </div>
                          <div class="my-1">
                            <span v-if="comp.name">
                              <span class="text-secondary mr-1">Name:</span>
                              <span class="mr-3">{{ comp.name }}</span>
                            </span>
                            <span v-if="comp.use_seq_as">
                              <span class="text-secondary mr-1">Use As:</span>
                              <span>{{ comp.use_seq_as }}</span>
                            </span>
                          </div>
                          <div class="my-1">
                            <span v-if="comp.old_locus_tag">
                              <span class="text-secondary mr-1"
                                >Old Locus Tag:</span
                              >
                              <span>{{ comp.old_locus_tag }}</span>
                            </span>
                            <span v-if="comp.locus_tag" class="ml-3">
                              <span class="text-secondary mr-1"
                                >Locus Tag:</span
                              >
                              <span>{{ comp.locus_tag }}</span>
                            </span>
                          </div>
                        </div>

                        <div class="col-6 text-right">
                          <span class="h3 mr-2">
                            {{ comp.qty }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="py-1 text-right mr-5">
                      <span class="mr-2 text-secondary">Total Quantity:</span>
                      <span class="h3">{{ packageQty }}</span>
                    </div>
                  </div>
                </div>
                <!-- .card-body -->
              </div>
              <!-- .card -->
            </div>

            <div v-if="dsgnBox" class="py-1">
              <div class="card">
                <div class="card-header text-dark">
                  Box: {{ dsgnBox.cstb.box_id }}
                </div>
                <div class="py-2 card card-body text-left">
                  <div class="py-2">
                    <span class="mr-1">Name:</span>
                    <span class="h5 text-dark mr-3">{{
                      dsgnBox.cstb.display_name
                    }}</span>
                    <span class="mr-1">Use As:</span>
                    <span
                      class="h5 mr-3"
                      v-bind:class="dsgnBox.cstb.use_seq_as_color"
                      >{{ dsgnBox.cstb.box_type }}</span
                    >
                  </div>
                  <div
                    v-if="
                      dsgnBox.item && dsgnBox.item.comp.olab_type == 'package'
                    "
                  >
                    <span class="mr-1">Package Type:</span>
                    <span class="h5 text-dark"
                      >{{ dsgnBox.item.seq.package_type }} -
                      {{ dsgnBox.item.seq.package_seq_type }}</span
                    >
                    <span class="ml-3 mr-1">Item Count:</span>
                    <span class="h5 text-dark">{{
                      dsgnBox.item.seq.comps.length
                    }}</span>
                  </div>
                  <div
                    v-if="
                      dsgnBox.item && dsgnBox.item.comp.olab_type == 'package'
                    "
                  >
                    <div class="list-group list-group-flush">
                      <div
                        class="list-group-item mb-1 align-items-center"
                        v-for="(comp, index) in dsgnBox.item.seq.comps"
                        :key="index"
                      >
                        <div class="text-left">
                          <div class="my-1">
                            <span class="text-secondary mr-1">OLAB ID:</span>
                            <span class="text-dark mr-3">{{
                              comp.olab_id
                            }}</span>
                            <span v-if="comp.name">
                              <span class="text-secondary mr-1">Name:</span>
                              <span class="text-dark mr-3">{{
                                comp.name
                              }}</span>
                            </span>
                            <span v-else-if="comp.old_locus_tag">
                              <span class="text-secondary mr-1"
                                >Old Locus Tag:</span
                              >
                              <span class="text-dark mr-3">{{
                                comp.old_locus_tag
                              }}</span>
                            </span>
                            <span v-else-if="comp.locus_tag">
                              <span class="text-secondary mr-1"
                                >Locus Tag:</span
                              >
                              <span class="text-dark mr-3">{{
                                comp.locus_tag
                              }}</span>
                            </span>
                            <span v-if="comp.length">
                              <span class="text-secondary mr-1">Length:</span>
                              <span class="text-dark">{{ comp.length }}</span>
                            </span>
                          </div>
                          <div class="my-1">
                            <span v-if="comp.seq">
                              <span class="text-secondary mr-1">Sequence:</span>
                              <span class="text-monospace text-dark">{{
                                comp.seq
                              }}</span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div v-if="dsgnBox.cstb.seq" class="py-2">
                      <span class="mr-1">Start:</span>
                      <span class="h5 text-dark mr-3">{{
                        dsgnBox.cstb.start
                      }}</span>
                      <span class="mr-1">Range:</span>
                      <span class="h5 text-dark mr-3">{{
                        dsgnBox.cstb.range
                      }}</span>
                    </div>
                    <div v-if="dsgnBox.cstb.seq" class="py-2">
                      <span class="mr-1">Seq:</span>
                      <span class="text-monospace text-dark">{{
                        dsgnBox.cstb.seq
                      }}</span>
                    </div>
                    <div v-if="dsgnBox.cstb.seq" class="py-2">
                      <span class="mr-1">Length:</span>
                      <span class="h5 text-dark mr-3">{{
                        dsgnBox.cstb.seq.length
                      }}</span>
                    </div>
                  </div>
                </div>
                <div
                  v-if="dsgnBox.item && dsgnBox.item.seq"
                  class="card card-body text-left"
                >
                  <div class="py-2">
                    <span class="h5 text-dark">Cart Component Used:</span>
                  </div>
                  <div class="py-2">
                    <span class="mr-1">OLAB ID:</span>
                    <span class="h5 text-dark">{{
                      dsgnBox.item.seq.olab_id
                    }}</span>
                  </div>
                  <div v-if="dsgnBox.item.seq.name" class="py-2">
                    <span class="mr-1">Name:</span>
                    <span class="h5 text-dark">{{
                      dsgnBox.item.seq.name
                    }}</span>
                  </div>
                  <div v-if="dsgnBox.item.seq.locus_tag" class="py-2">
                    <span class="mr-1">Locus Tag:</span>
                    <span class="h5 text-dark">{{
                      dsgnBox.item.seq.locus_tag
                    }}</span>
                  </div>
                  <div v-if="dsgnBox.item.comp" class="py-2">
                    <span class="mr-1">Type:</span>
                    <span class="h5 text-dark mr-3">{{
                      dsgnBox.item.comp.olab_type
                    }}</span>
                    <span class="mr-1">Use Sequence As:</span>
                    <span class="h5">{{ dsgnBox.item.comp.use_seq_as }}</span>
                  </div>
                  <div v-if="getSeqLength(dsgnBox.item) != 0" class="py-2">
                    <span class="mr-1">Length:</span>
                    <span class="h5 text-dark mr-3">{{
                      getSeqLength(dsgnBox.item)
                    }}</span>
                    <span v-if="dsgnBox.item.seq.product">
                      <span class="mr-1">Product:</span>
                      <span class="h5 text-dark">{{
                        dsgnBox.item.seq.product
                      }}</span>
                    </span>
                  </div>
                  <div v-if="dsgnBox.item.seq.desc" class="py-2">
                    <span class="mr-1">Description:</span>
                    <span class="h5 text-dark">{{
                      dsgnBox.item.seq.desc
                    }}</span>
                  </div>
                </div>
                <!-- .card-body -->
              </div>
              <!-- .card -->
            </div>
          </div>
          <!-- .modal-body -->

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
          <!-- .modal-footer -->
        </div>
        <!-- .modal-content -->
      </div>
      <!-- .modal-dialog .modal-lg-->
    </div>
    <!-- .modal fade -- End of infoProfile -->
  </div>
</template>

<script>
// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "olabinfoprofile",
  props: [
    "user",
    "selectedTypeName",
    "selectedCart",
    "selectedPackage",
    "dsgnBox"
  ],
  components: {
    // FontAwesomeIcon
  },
  computed: {
    cartQty: function() {
      let qty = 0;
      if (this.selectedCart != null && this.selectedCart.comps != null) {
        for (let idx in this.selectedCart.comps) {
          qty = qty + this.selectedCart.comps[idx].qty;
        }
      }
      return qty;
    },
    packageQty: function() {
      let qty = 0;
      if (this.selectedPackage != null && this.selectedPackage.comps != null) {
        for (let idx in this.selectedPackage.comps) {
          qty = qty + this.selectedPackage.comps[idx].qty;
        }
      }
      return qty;
    }
  },
  methods: {
    getSeqLength: function(item) {
      let len = 0;
      if (item) {
        len = item.getSeqLength();
      }
      return len;
    }
  }
};
</script>

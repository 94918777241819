<template>
  <div>
    <div class="list-group list-group-flush">
      <div
        class="list-group-item d-flex mb-1 align-items-center"
        v-for="gn in genes"
        :key="gn.olab_id"
      >
        <div class="container">
          <div class="row">
            <div
              v-if="selectedCart && !cartIsFull"
              class="col-1 align-self-center mx-2"
            >
              <button
                type="button"
                class="btn btn-info"
                data-toggle="modal"
                data-target="#selectAddGene"
                v-on:click="registerGene(gn)"
                data-backdrop="static"
                data-keyboard="false"
              >
                <font-awesome-icon icon="plus" />
              </button>
            </div>
            <div
              v-else-if="
                enablePackageAdd &&
                  isLibseqPackage &&
                  notInPackage(gn, selectedPackage.comps)
              "
              class="col-1 align-self-center mx-2"
            >
              <button
                type="button"
                class="btn btn-info"
                data-toggle="modal"
                data-target="#selectAddGene"
                v-on:click="registerGene(gn)"
                data-backdrop="static"
                data-keyboard="false"
              >
                <font-awesome-icon icon="plus" />
              </button>
            </div>
            <div
              v-else-if="
                enablePackageAdd && notInPackage(gn, selectedPackage.comps)
              "
              class="col-1 align-self-center mx-2"
            >
              <button
                type="button"
                class="btn btn-info"
                v-on:click="addToPackageComps(gn)"
              >
                <font-awesome-icon icon="plus" />
              </button>
            </div>
            <div
              v-else-if="selectedGene == gn && saveStatus"
              class="col-1 align-self-center mx-2"
            >
              <button type="button" class="btn btn-info">
                <span
                  class="spinner-border spinner-border-sm text-light"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </span>
              </button>
            </div>
            <div v-else class="col-1 align-self-center mx-2">
              <!-- NOP -->
            </div>

            <div class="col-10 col-md-5 align-self-center">
              <OlabSequenceSVGGen
                :numA="gn.gene_stats.num_a"
                :numC="gn.gene_stats.num_c"
                :numG="gn.gene_stats.num_g"
                :numT="gn.gene_stats.num_t"
                :shortSeq="gn.gene_stats.short_seq"
                :len="gn.gene_stats.len"
              />
            </div>

            <div class="col-12 col-md-5 mt-2 align-self-center text-wrap">
              <a
                class="h5 text-info"
                data-toggle="modal"
                href="#geneDetailInfo"
                v-on:click="registerGene(gn)"
                data-backdrop="static"
                data-keyboard="false"
              >
                <span v-if="gn.name">{{ gn.name }}</span>
                <span v-else-if="gn.synonym_name">{{ gn.synonym_name }}</span>
                <span v-else-if="gn.synonym_name2">{{ gn.synonym_name2 }}</span>
                <span v-else-if="gn.old_locus_tag">{{ gn.old_locus_tag }}</span>
                <span v-else-if="gn.locus_tag">{{ gn.locus_tag }}</span>
                <span v-else>{{ gn.olab_id }}</span></a
              >

              <div
                v-if="gn.aux && gn.aux.strain_name"
                class="h6 mt-2 text-truncate"
              >
                {{ gn.aux.strain_name }}
              </div>
              <div class="h6 mt-2 text-truncate">
                <span v-if="gn.old_locus_tag" class="mr-3">{{
                  gn.old_locus_tag
                }}</span>
                <span v-if="gn.locus_tag">{{ gn.locus_tag }}</span>
              </div>
              <div class="h6">
                <span class="mr-5">{{ gn.gene_stats.len }}</span>
                <span>
                  {{ gn.sequence.comp | convertCompToStrand }}
                </span>
              </div>
              <div class="h6 mt-2">
                {{ gn.olab_id }}
              </div>
              <div
                v-if="gn.product"
                class="h6 text-secondary mt-2 text-truncate"
              >
                {{ gn.product }}
              </div>
              <div v-if="gn.desc" class="h6 text-secondary mt-2 text-truncate">
                {{ gn.desc }}
              </div>
            </div>

            <!-- Modal: selectAddGene -->
            <div
              v-if="selectedGene != null"
              class="modal fade"
              id="selectAddGene"
            >
              <div class="modal-dialog modal-lg">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">
                      OLAB ID:
                      <span class="text-dark">{{ selectedGene.olab_id }}</span>
                    </h5>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      v-on:click="clearNewItem()"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <!-- .modal-header -->
                  <div class="modal-body">
                    <div class="text-wrap">
                      <span v-if="selectedGene.name">
                        <span class="mr-1">Name:</span>
                        <span class="h5 text-dark mr-5">{{
                          selectedGene.name
                        }}</span>
                      </span>
                      <span v-if="selectedGene.synonym_name">
                        <span class="mr-1">Synonym Name:</span>
                        <span
                          v-if="selectedGene.synonym_name"
                          class="h5 text-dark"
                          >{{ selectedGene.synonym_name }}</span
                        >
                        <span
                          v-if="selectedGene.synonym_name2"
                          class="h5 text-dark"
                          >; {{ selectedGene.synonym_name2 }}</span
                        >
                      </span>
                    </div>
                    <div
                      v-if="selectedGene.aux && selectedGene.aux.strain_name"
                      class="py-1 text-wrap"
                    >
                      <span class="mr-1">Strain Name:</span>
                      <span class="h5 text-dark">{{
                        selectedGene.aux.strain_name
                      }}</span>
                    </div>
                    <div v-if="selectedGene.product" class="py-1 text-wrap">
                      <span class="mr-1">Product:</span>
                      <span class="h5 text-dark">{{
                        selectedGene.product
                      }}</span>
                    </div>
                    <div
                      v-if="
                        selectedGene.locus_tag || selectedGene.old_locus_tag
                      "
                      class="my-2 card"
                    >
                      <div class="card-header text-dark">Locus Tag</div>
                      <div class="card-body text-wrap">
                        <span class="mr-1">Old:</span>
                        <span class="h5 text-dark">{{
                          selectedGene.old_locus_tag
                        }}</span>

                        <span class="ml-3 mr-1">New:</span>
                        <span class="h5 text-dark">{{
                          selectedGene.locus_tag
                        }}</span>
                      </div>
                      <!-- .card-body -->
                    </div>
                    <div
                      v-if="selectedCart || (!selectedCart && isLibseqPackage)"
                      class="py-2 form-check"
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="createNewSeq"
                        v-on:click="handleCreateNewSeq()"
                      />
                      <label class="form-check-label">
                        Create New Sequence
                      </label>
                      <div
                        v-if="selectedGene.isJoined()"
                        class="small text-danger"
                      >
                        Create new sequence will include the intron(s) of this
                        gene
                      </div>
                    </div>
                    <div v-if="createNewSeq && newItem" class="py-1">
                      <div class="card">
                        <div class="card-header text-dark">
                          Create New Sequence
                        </div>
                        <div class="card-body">
                          <div class="py-1 text-wrap">
                            <span class="mr-1">Project:</span>
                            <span class="h5 text-dark">{{
                              newItem.project_id
                            }}</span>
                          </div>
                          <div class="py-1 text-wrap">
                            <span class="mr-1">Sequence Origin:</span>
                            <span class="h5 text-dark">{{
                              newItem.seq_origin
                            }}</span>
                          </div>
                          <div class="form-group mt-1">
                            <label class="form-control-label">
                              <span>Name:</span>
                            </label>
                            <input
                              class="form-control"
                              v-bind:class="newItem.getValidState(newItem.name)"
                              type="text"
                              v-model="newItem.name"
                            />
                          </div>
                          <div class="form-group">
                            <label class="form-control-label"
                              >Description:</label
                            >
                            <textarea
                              class="form-control"
                              v-model="newItem.desc"
                            ></textarea>
                          </div>
                          <div class="row justify-content-center">
                            <div class="col-12 col-lg-6 mb-3">
                              <div class="card">
                                <div class="card-header text-dark">
                                  Display
                                </div>
                                <div class="card-body">
                                  <div class="row justify-content-center">
                                    <div class="col-12 col-md-6">
                                      <div class="form-group">
                                        <div class="mr-1">
                                          Start
                                        </div>
                                        <input
                                          class="form-control"
                                          type="number"
                                          step="1"
                                          v-bind:class="displayStartValidState"
                                          v-model="displayData.start"
                                        />
                                        <div class="invalid-feedback">
                                          {{ displayData.startInvalidFeedback }}
                                        </div>
                                      </div>
                                      <!-- .form-group -->
                                    </div>
                                    <div class="col-12 col-md-6">
                                      <div class="form-group">
                                        <!-- Range is renamed as Length -->
                                        <div class="mr-1">
                                          Length
                                        </div>
                                        <input
                                          class="form-control"
                                          type="number"
                                          min="1"
                                          max="5000"
                                          step="1"
                                          v-bind:class="displayRangeValidState"
                                          v-model="displayData.range"
                                        />
                                        <div class="invalid-feedback">
                                          {{ displayData.rangeInvalidFeedback }}
                                        </div>
                                      </div>
                                      <!-- .form-group -->
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 col-lg-6 mb-3">
                              <div class="card">
                                <div class="card-header text-dark">
                                  Select
                                </div>
                                <div class="card-body">
                                  <div class="row justify-content-center">
                                    <div class="col-12 col-md-6">
                                      <div class="form-group">
                                        <div class="mr-1">
                                          Start
                                        </div>
                                        <input
                                          class="form-control"
                                          type="number"
                                          step="1"
                                          v-bind:class="selectStartValidState"
                                          v-model="selectData.start"
                                        />
                                        <div class="invalid-feedback">
                                          {{ selectData.startInvalidFeedback }}
                                        </div>
                                      </div>
                                      <!-- .form-group -->
                                    </div>
                                    <div class="col-12 col-md-6">
                                      <div class="form-group">
                                        <!-- Range is renamed as Length -->
                                        <div class="mr-1">
                                          Length
                                        </div>
                                        <input
                                          class="form-control"
                                          type="number"
                                          min="1"
                                          max="5000"
                                          step="1"
                                          v-bind:class="selectRangeValidState"
                                          v-model="selectData.range"
                                        />
                                        <div class="invalid-feedback">
                                          {{ selectData.rangeInvalidFeedback }}
                                        </div>
                                      </div>
                                      <!-- .form-group -->
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div v-if="showSelectSeq">
                            <div
                              v-if="seqData"
                              class="py-1 bg-light small text-monospace text-dark"
                            >
                              <span class="text-light bg-danger">{{
                                seqData.preBeforeGene
                              }}</span>
                              <span class="text-light bg-warning">{{
                                seqData.beforeGeneSelect
                              }}</span>
                              <span class="text-light bg-danger">{{
                                seqData.postBeforeGene
                              }}</span>

                              <span>{{ seqData.preGene }}</span>
                              <span class="text-light bg-warning">{{
                                seqData.geneSelect
                              }}</span>
                              <span>{{ seqData.postGene }}</span>

                              <span class="text-light bg-danger">{{
                                seqData.preAfterGene
                              }}</span>
                              <span class="text-light bg-warning">{{
                                seqData.afterGeneSelect
                              }}</span>
                              <span class="text-light bg-danger">{{
                                seqData.postAfterGene
                              }}</span>
                            </div>
                          </div>
                          <div v-else>
                            <div
                              v-if="seqDataNoSelect"
                              class="py-1 bg-light small text-monospace text-dark"
                            >
                              <span class="text-light bg-danger">{{
                                seqDataNoSelect.beforeGene
                              }}</span>
                              <span>{{ seqDataNoSelect.gene }}</span>
                              <span class="text-light bg-danger">{{
                                seqDataNoSelect.afterGene
                              }}</span>
                            </div>
                          </div>
                          <div v-if="seqData.selectSeq" class="py-1 text-dark">
                            <span>
                              {{ seqData.selectSeq.length }}
                            </span>
                            <span class="float-right">
                              <div class="form-group form-check">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  v-model="showSelectSeq"
                                />
                                <label class="form-check-label"
                                  >Show Select Sequence</label
                                >
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div class="py-1">
                        <div class="card">
                          <div class="card-header text-dark">Sequence</div>
                          <div class="card-body">
                            <span class="mr-1">Type:</span>
                            <span class="h5 text-dark">{{
                              selectedGene.sequence.type
                            }}</span>
                            <div class="pt-2">
                              <span class="mr-1">Range:</span>
                              <span class="h5 text-dark">{{
                                computedRange
                              }}</span>
                              <span class="ml-5 mr-1">Strand:</span>
                              <span class="h5 text-dark">{{
                                selectedGene.sequence.comp | convertCompToStrand
                              }}</span>
                            </div>
                            <div class="py-2">
                              <span class="mr-1">Length:</span>
                              <span class="h5 text-dark">{{
                                selectedGene.gene_stats.len
                              }}</span>
                            </div>
                            <div class="py-1">
                              <span class="mr-1"># A:</span>
                              <span class="h5 text-dark">{{
                                selectedGene.gene_stats.num_a
                              }}</span>
                              <span class="ml-3 mr-1"># C:</span>
                              <span class="h5 text-dark">{{
                                selectedGene.gene_stats.num_c
                              }}</span>
                              <span class="ml-3 mr-1"># G:</span>
                              <span class="h5 text-dark">{{
                                selectedGene.gene_stats.num_g
                              }}</span>
                              <span class="ml-3 mr-1"># T:</span>
                              <span class="h5 text-dark">{{
                                selectedGene.gene_stats.num_t
                              }}</span>
                            </div>

                            <div
                              class="py-2 small text-monospace text-dark text-wrap"
                            >
                              {{ selectedGene.sequence.seq }}
                            </div>
                          </div>
                          <!-- .card-body -->
                        </div>
                        <!-- .card -->
                      </div>
                      <div class="my-2 card">
                        <div class="card-header text-dark">Description</div>
                        <div class="card-body">
                          <div class="py-1 small text-dark text-wrap">
                            {{ selectedGene.desc }}
                          </div>
                        </div>
                        <!-- .card-body -->
                      </div>
                      <!-- .card -->
                    </div>
                    <div class="form-group">
                      <div v-if="selectedCart || !isLibseqPackage">
                        <label class="form-control-label mt-2" for="useSeqAs"
                          >Use As:</label
                        >
                        <select
                          class="form-control"
                          id="useSeqAs"
                          v-model="selectedUseSeqAs"
                        >
                          <option disabled value="">Please select one</option>
                          <option value="gene">Gene</option>
                          <option v-if="createNewSeq && newItem" value="pgst"
                            >PGsT</option
                          >
                          <option value="promoter">Promoter</option>
                          <option value="terminator">Terminator</option>
                        </select>
                        <!-- <span>selected = {{selectedUseSeqAs}}</span> -->
                      </div>
                      <div v-else>
                        <label class="form-control-label mt-2" for="useSeqAs"
                          >Use As: {{ selectedUseSeqAs }}</label
                        >
                      </div>
                    </div>
                    <!-- .form-group -->
                  </div>
                  <!-- .modal-body -->
                  <div class="modal-footer">
                    <button
                      type="button"
                      v-bind:disabled="itemAddIsDisabled == true"
                      class="btn btn-primary"
                      data-dismiss="modal"
                      v-on:click="
                        handleAddToComps(selectedGene, selectedUseSeqAs)
                      "
                    >
                      Add
                    </button>
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-dismiss="modal"
                      v-on:click="clearNewItem()"
                    >
                      Back
                    </button>
                  </div>
                  <!-- .modal-footer -->
                </div>
                <!-- .modal-content -->
              </div>
              <!-- .modal-dialog -->
            </div>
            <!-- .modal fade #selectAddGene -->

            <!-- Modal: geneDetailInfo -->
            <div
              v-if="selectedGene != null"
              class="modal fade"
              id="geneDetailInfo"
            >
              <div class="modal-dialog modal-lg">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">
                      OLAB ID:
                      <span class="h5 text-dark">{{
                        selectedGene.olab_id
                      }}</span>
                    </h5>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <!-- .modal-header -->
                  <div class="modal-body">
                    <div class="text-wrap">
                      <span v-if="selectedGene.name">
                        <span class="mr-1">Name:</span>
                        <span class="h5 text-dark mr-5">{{
                          selectedGene.name
                        }}</span>
                      </span>
                      <span v-if="selectedGene.synonym_name">
                        <span class="mr-1">Synonym Name:</span>
                        <span
                          v-if="selectedGene.synonym_name"
                          class="h5 text-dark"
                          >{{ selectedGene.synonym_name }}</span
                        >
                        <span
                          v-if="selectedGene.synonym_name2"
                          class="h5 text-dark"
                          >; {{ selectedGene.synonym_name2 }}</span
                        >
                      </span>
                    </div>
                    <div
                      v-if="selectedGene.aux && selectedGene.aux.strain_name"
                      class="py-1 text-wrap"
                    >
                      <span class="mr-1">Strain Name:</span>
                      <span class="h5 text-dark">{{
                        selectedGene.aux.strain_name
                      }}</span>
                    </div>
                    <div v-if="selectedGene.product" class="py-1 text-wrap">
                      <span class="mr-1">Product:</span>
                      <span class="h5 text-dark">{{
                        selectedGene.product
                      }}</span>
                    </div>
                    <div
                      v-if="
                        selectedGene.locus_tag || selectedGene.old_locus_tag
                      "
                      class="my-2 card"
                    >
                      <div class="card-header text-dark">Locus Tag</div>
                      <div class="card-body text-wrap">
                        <span class="mr-1">Old:</span>
                        <span class="h5 text-dark">{{
                          selectedGene.old_locus_tag
                        }}</span>

                        <span class="ml-3 mr-1">New:</span>
                        <span class="h5 text-dark">{{
                          selectedGene.locus_tag
                        }}</span>
                      </div>
                      <!-- .card-body -->
                    </div>
                    <div class="my-2 card">
                      <div class="card-header text-dark">Description</div>
                      <div class="card-body">
                        <div class="py-1 small text-dark text-wrap">
                          {{ selectedGene.desc }}
                        </div>
                      </div>
                      <!-- .card-body -->
                    </div>
                    <!-- .card -->
                    <div v-if="selectedGene.sequence != null" class="card">
                      <div class="card-header text-dark">Sequence</div>
                      <div class="card-body">
                        <span class="mr-1">Type:</span>
                        <span class="h5 text-dark">{{
                          selectedGene.sequence.type
                        }}</span>
                        <div class="pt-2">
                          <span class="mr-1">Range:</span>
                          <span class="h5 text-dark">{{ computedRange }}</span>
                          <span class="ml-5 mr-1">Strand:</span>
                          <span class="h5 text-dark">{{
                            selectedGene.sequence.comp | convertCompToStrand
                          }}</span>
                        </div>
                        <div class="py-2">
                          <span class="mr-1">Length:</span>
                          <span class="h5 text-dark">{{
                            selectedGene.gene_stats.len
                          }}</span>
                        </div>
                        <div class="py-1">
                          <span class="mr-1"># A:</span>
                          <span class="h5 text-dark">{{
                            selectedGene.gene_stats.num_a
                          }}</span>
                          <span class="ml-3 mr-1"># C:</span>
                          <span class="h5 text-dark">{{
                            selectedGene.gene_stats.num_c
                          }}</span>
                          <span class="ml-3 mr-1"># G:</span>
                          <span class="h5 text-dark">{{
                            selectedGene.gene_stats.num_g
                          }}</span>
                          <span class="ml-3 mr-1"># T:</span>
                          <span class="h5 text-dark">{{
                            selectedGene.gene_stats.num_t
                          }}</span>
                        </div>

                        <div
                          class="py-2 small text-monospace text-dark text-wrap"
                        >
                          {{ selectedGene.sequence.seq }}
                        </div>
                        <!-- <img class="card-img-bottom img-fluid" v-bind:alt="selectedGene.name" v-bind:src="printGeneImageLink"> -->
                      </div>
                      <!-- .card-body -->
                    </div>
                    <!-- .card -->
                  </div>
                  <!-- .modal-body -->
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                  <!-- .modal-footer -->
                </div>
                <!-- .modal-content -->
              </div>
              <!-- .modal-dialog .modal-lg-->
            </div>
            <!-- .modal fade #geneDetailInfo -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import OlabSequenceSVGGen from "@/components/OlabSequenceSVGGen";
import { OlabAxios } from "../olab/olabaxios.js";
import { OlabBlock } from "../olab/olabblock.js";
import { OlabItem } from "../olab/olabitem.js";
import { OlabGene } from "../olab/olabgene.js";
import { OlabUtils } from "../olab/olabutils.js";
import $ from "jquery";

export default {
  name: "compdbgenelist",
  data: function() {
    return {
      showSelectSeq: true,
      createNewSeq: false,
      newItem: null,
      selectedGene: null,
      selectedUseSeqAs: "",
      gene: new OlabGene(),
      // A cached gene's seq use to handle joined sequence case
      computedNewSeq: null,
      seqDataNoSelect: {
        beforeGene: "",
        gene: "",
        afterGene: ""
      },
      seqData: {
        displaySeq: "",
        selectSeq: "",
        preBeforeGene: "",
        beforeGeneSelect: "",
        postBeforeGene: "",
        preGene: "",
        geneSelect: "",
        postGene: "",
        preAfterGene: "",
        afterGeneSelect: "",
        postAfterGene: ""
      },
      selectData: {
        start: "0",
        numStart: 0,
        startValid: true,
        startDisabledState: "disabled",
        startInvalidFeedback: "Start has to be an integer",
        range: "1",
        numRange: 1,
        rangeValid: true,
        rangeDisabledState: "disabled",
        rangeInvalidFeedback: "Length has to be a positive integer (>0)"
      },
      displayData: {
        start: "0",
        numStart: 0,
        startValid: true,
        startDisabledState: "disabled",
        startInvalidFeedback: "Start has to be an integer",
        range: "1",
        numRange: 1,
        rangeValid: true,
        rangeDisabledState: "disabled",
        rangeInvalidFeedback: "Length has to be a positive integer (>0)"
      }
    };
  },
  props: [
    "user",
    "dbInfo",
    "genes",
    "saveStatus",
    "statusObj",
    "errorObj",
    "selectedType",
    "selectedProject",
    "selectedCart",
    "selectedPackage",
    "cartIsFull",
    "packageIsFull"
  ],
  components: {
    FontAwesomeIcon,
    OlabSequenceSVGGen
  },
  computed: {
    computedRange: function() {
      let range = "[]";
      const locs = this.selectedGene.sequence.locations;
      if (locs && locs.length > 0) {
        let joins = "";
        for (let i = 0; i < locs.length; i++) {
          // console.log("locs =", locs[i]);
          const loc = locs[i];
          // console.log("loc =", loc);
          if (i > 0) {
            joins = joins.concat(",");
          }
          joins = joins.concat(`${loc.from},..,${loc.to}`);
          // console.log("joins =", joins);
        }
        range = `[ ${joins} ]`;
      } else {
        range = `[ ${this.selectedGene.sequence.from},..,${this.selectedGene.sequence.to} ]`;
      }
      return range;
    },
    enablePackageAdd: function() {
      let pkgAddOK =
        !this.selectedCart &&
        this.selectedPackage &&
        !this.packageIsFull &&
        (this.selectedType === "strain" || this.selectedType === "plasmid");
      return pkgAddOK;
    },
    isLibseqPackage: function() {
      let libSeqPackage =
        this.selectedPackage &&
        this.selectedPackage.package_seq_type === "library_sequence";
      return libSeqPackage;
    },
    itemAddIsDisabled: function() {
      if (
        this.createNewSeq &&
        this.newItem &&
        this.newItem.name &&
        this.selectData.startValid &&
        this.selectData.rangeValid &&
        this.selectedUseSeqAs
      ) {
        return false;
      } else if (!this.createNewSeq && this.selectedUseSeqAs) {
        return false;
      }
      return true;
    },
    displayStartValidState: function() {
      return this.displayData.startValid ? "is-valid" : "is-invalid";
    },
    displayRangeValidState: function() {
      return this.displayData.rangeValid ? "is-valid" : "is-invalid";
    },
    selectStartValidState: function() {
      return this.selectData.startValid ? "is-valid" : "is-invalid";
    },
    selectRangeValidState: function() {
      return this.selectData.rangeValid ? "is-valid" : "is-invalid";
    }
  },
  filters: {
    convertCompToStrand: function(value) {
      // console.log("convertCompToStrand: value = " + value);
      return value === true ? "-" : "+";
    }
  },
  methods: {
    doComputeNewSeq: async function() {
      this.computedNewSeq = this.selectedGene.sequence.seq;
      // Handle the joined sequence gene case
      if (this.selectedGene.isJoined()) {
        const range = this.selectedGene.rangeIncludeIntrons();
        await this.computeDisplaySeq(0, range);
        this.computedNewSeq = this.seqData.displaySeq;
        // console.log("rangeIncludeIntrons = ", this.computedNewSeq.length);
      }
      return this.computedNewSeq;
    },
    handleCreateNewSeq: async function() {
      // toggle createNewSeq
      this.createNewSeq = !this.createNewSeq;
      // console.log("handleCreateNewSeq: createNewSeq =", this.createNewSeq);
      // console.log("selectedGene =", this.selectedGene);
      // Compute new length if gene is a jointed sequence gene
      if (this.createNewSeq) {
        this.newItem = new OlabItem();
        this.newItem.olab_id = "";
        this.newItem.name = "";
        this.newItem.olab_type = "library_sequence";
        this.newItem.source = "inhouse";
        this.newItem.creator = this.user.email;
        this.newItem.seq_origin = OlabUtils.getStrainID(
          this.selectedGene.olab_id
        );
        this.newItem.seq = await this.doComputeNewSeq();
        this.newItem.project_id = this.selectedProject
          ? this.selectedProject.olab_id
          : this.newItem.project_id;
        this.displayData.start = 0;
        this.displayData.range = this.newItem.seq.length;
        this.selectData.start = 0;
        this.selectData.range = this.newItem.seq.length;
        this.setDisplayStart(this.displayData.start);
        this.setDisplayRange(this.displayData.range);
        this.setSelectStart(this.selectData.start);
        this.setSelectRange(this.selectData.range);
        this.seqData.displaySeq = this.newItem.seq;
        this.seqData.selectSeq = this.newItem.seq;
      } else {
        this.newItem = null;
        this.showSelectSeq = true;
        this.displayData.start = 0;
        this.displayData.range = 1;
        this.selectData.start = 0;
        this.selectData.range = 1;
        this.seqData.displaySeq = "";
        this.seqData.selectSeq = "";
      }
    },
    clearNewItem: function() {
      // Reset internal state data
      this.showSelectSeq = true;
      this.displayData.numStart = 0;
      this.displayData.numRange = 1;
      this.selectData.numStart = 0;
      this.selectData.numRange = 1;
      this.createNewSeq = false;
      $("#createNewSeq").prop("checked", false);
      this.newItem = null;
    },
    clearBeforeGeneNoSelect: function() {
      this.seqDataNoSelect.beforeGene = "";
    },
    clearGeneNoSelect: function() {
      this.seqDataNoSelect.gene = "";
    },
    clearAfterGeneNoSelect: function() {
      this.seqDataNoSelect.afterGene = "";
    },
    clearBeforeGene: function() {
      this.seqData.preBeforeGene = "";
      this.seqData.beforeGeneSelect = "";
      this.seqData.postBeforeGene = "";
    },
    clearGene: function() {
      this.seqData.preGene = "";
      this.seqData.geneSelect = "";
      this.seqData.postGene = "";
    },
    clearAfterGene: function() {
      this.seqData.preAfterGene = "";
      this.seqData.afterGeneSelect = "";
      this.seqData.postAfterGene = "";
    },
    computeSeqDataBefore: function(dStart, dRange, dSgSDist, dSeq) {
      // This method is coded to handle negative dStart only
      if (dStart >= 0) return -1;
      let remaining = 0;
      if (dSgSDist > dRange) {
        this.seqDataNoSelect.beforeGene = dSeq.substring(0, dRange);
        remaining = 0;
      } else {
        this.seqDataNoSelect.beforeGene = dSeq.substring(0, dSgSDist);
        remaining = dRange - dSgSDist;
      }
      return remaining;
    },
    computeSeqDataGene: function(
      dStart,
      dRange,
      dSgSDist,
      remaining,
      gSeq,
      dSeq
    ) {
      if (dStart < 0) {
        if (remaining < gSeq.length) {
          this.seqDataNoSelect.gene = dSeq.substring(
            dSgSDist,
            dSgSDist + remaining
          );
          remaining = 0;
        } else {
          this.seqDataNoSelect.gene = dSeq.substring(
            dSgSDist,
            dSgSDist + gSeq.length
          );
          remaining = remaining - gSeq.length;
        }
      } else {
        // dStart >= 0 case
        if (dStart < gSeq.length) {
          const startPlusRange = dStart + dRange;
          if (startPlusRange > gSeq.length) {
            this.seqDataNoSelect.gene = dSeq.substring(0, gSeq.length - dStart);
            remaining = startPlusRange - gSeq.length;
          } else {
            this.seqDataNoSelect.gene = dSeq.substring(0, dRange);
            remaining = 0;
          }
        }
      }
      return remaining;
    },
    computeSeqDataAfter: function(
      dStart,
      dRange,
      dSgSDist,
      remaining,
      gSeq,
      dSeq
    ) {
      if (dStart < 0) {
        this.seqDataNoSelect.afterGene = dSeq.substring(
          dSgSDist + gSeq.length,
          dRange
        );
        remaining = 0;
      } else if (dStart < gSeq.length) {
        this.seqDataNoSelect.afterGene = dSeq.substring(
          gSeq.length - dStart,
          dRange
        );
        remaining = 0;
      } else {
        this.seqDataNoSelect.afterGene = dSeq.substring(0, dRange);
        remaining = 0;
      }
      return remaining;
    },
    computeSeqDataBefore2: function(
      dStart,
      dRange,
      dSgSDist,
      sStart,
      sRange,
      sSgSDist,
      dSeq
    ) {
      // This method is coded to handle negative dStart only
      if (dStart >= 0) return -1;

      const gSLen = dSgSDist;
      const dRLen = dRange;
      let remaining = dRLen;
      if (sStart < 0) {
        const sSLen = gSLen - sSgSDist;
        const sRLen = sSLen + sRange;
        if (gSLen > dRLen) {
          // Case 5
          this.seqData.preBeforeGene = dSeq.substring(0, sSLen);
          this.seqData.beforeGeneSelect = dSeq.substring(sSLen, sRLen);
          this.seqData.postBeforeGene = dSeq.substring(sRLen, dRLen);
          remaining = 0;
        } else if (gSLen > sRLen) {
          // Case 7, 1a
          this.seqData.preBeforeGene = dSeq.substring(0, sSLen);
          this.seqData.beforeGeneSelect = dSeq.substring(sSLen, sRLen);
          this.seqData.postBeforeGene = dSeq.substring(sRLen, gSLen);
          remaining = dRLen - gSLen;
        } else {
          // Case 1, 6 and 6a
          this.seqData.preBeforeGene = dSeq.substring(0, sSLen);
          this.seqData.beforeGeneSelect = dSeq.substring(sSLen, sRLen);
          this.seqData.postBeforeGene = "";
          remaining = dRLen - sRLen;
        }
      } else {
        // sStart > 0
        // Case 2, 3, 4 and 8
        this.seqData.preBeforeGene = dSeq.substring(0, gSLen);
        this.seqData.beforeGeneSelect = "";
        this.seqData.postBeforeGene = "";
        remaining = dRLen - gSLen;
      }
      return remaining;
    },
    computeSeqDataGene2: function(
      dStart,
      dRange,
      dSgSDist,
      sStart,
      sRange,
      sSgSDist,
      remaining,
      gSeq,
      dSeq
    ) {
      const dRLen = dRange;
      if (dStart < 0) {
        const gSLen = dSgSDist;
        if (sStart < 0) {
          const sSLen = gSLen - sSgSDist;
          const sRLen = sSLen + sRange;
          const gRLen = gSLen + gSeq.length;
          if (gSLen > sRLen && dRLen > gRLen) {
            // Case 1a
            this.seqData.preGene = "";
            this.seqData.geneSelect = "";
            this.seqData.postGene = dSeq.substring(gSLen, gRLen);
            remaining = dRLen - gRLen;
          } else if (gSLen > sRLen && dRLen < gRLen) {
            // Case 7
            this.seqData.preGene = "";
            this.seqData.geneSelect = "";
            this.seqData.postGene = dSeq.substring(gSLen, dRLen);
            remaining = 0;
          } else if (gRLen > dRLen) {
            // Case 6
            this.seqData.preGene = "";
            this.seqData.geneSelect = "";
            this.seqData.postGene = dSeq.substring(sRLen, dRLen);
            remaining = 0;
          } else if (gRLen > sRLen) {
            // Case 6a
            this.seqData.preGene = "";
            this.seqData.geneSelect = "";
            this.seqData.postGene = dSeq.substring(sRLen, gRLen);
            remaining = dRLen - gRLen;
          } else {
            // Case 1
            this.seqData.preGene = "";
            this.seqData.geneSelect = "";
            this.seqData.postGene = "";
          }
        } else {
          // sStart >= 0
          const sSLen = gSLen + sStart;
          const sRLen = sSLen + sRange;
          const gRLen = gSLen + gSeq.length;
          if (gRLen > dRLen) {
            // Case 8
            this.seqData.preGene = dSeq.substring(gSLen, sSLen);
            this.seqData.geneSelect = dSeq.substring(sSLen, sRLen);
            this.seqData.postGene = dSeq.substring(sRLen, dRLen);
            remaining = 0;
          } else if (gRLen > sRLen) {
            // Case 4
            this.seqData.preGene = dSeq.substring(gSLen, sSLen);
            this.seqData.geneSelect = dSeq.substring(sSLen, sRLen);
            this.seqData.postGene = dSeq.substring(sRLen, gRLen);
            remaining = dRLen - gRLen;
          } else if (gRLen > sSLen) {
            // Case 2
            this.seqData.preGene = dSeq.substring(gSLen, sSLen);
            this.seqData.geneSelect = dSeq.substring(sSLen, sRLen);
            this.seqData.postGene = "";
            remaining = dRLen - sRLen;
          } else {
            // Case 3
            this.seqData.preGene = "";
            this.seqData.geneSelect = "";
            this.seqData.postGene = dSeq.substring(gSLen, gRLen);
            remaining = dRLen - gRLen;
          }
        }
      } else {
        // dStart >= 0
        const gRange = gSeq.length;
        if (dStart < gRange) {
          const sSsR = sStart + sRange;
          const dSdR = dStart + dRange;
          const sSIndS = sStart - dStart;
          const sSsRIndS = sSsR - dStart;
          const dSdRIndS = dSdR - dStart;
          const gRIndS = gRange - dStart;
          if (dSdR < gRange) {
            // Case 9
            this.seqData.preGene = dSeq.substring(0, sSIndS);
            this.seqData.geneSelect = dSeq.substring(sSIndS, sSsRIndS);
            this.seqData.postGene = dSeq.substring(sSsRIndS, dSdRIndS);
            remaining = 0;
          } else if (sSsR < gRange) {
            // Case 10
            this.seqData.preGene = dSeq.substring(0, sSIndS);
            this.seqData.geneSelect = dSeq.substring(sSIndS, sSsRIndS);
            this.seqData.postGene = dSeq.substring(sSsRIndS, gRIndS);
            remaining = dSdRIndS - gRIndS;
          } else if (sStart < gRange) {
            // Case 11
            this.seqData.preGene = dSeq.substring(0, sSIndS);
            this.seqData.geneSelect = dSeq.substring(sSIndS, sSsRIndS);
            this.seqData.postGene = "";
            remaining = dSdRIndS - sSsRIndS;
          } else if (dStart < gRange) {
            // Case 12
            this.seqData.preGene = dSeq.substring(0, gRIndS);
            this.seqData.geneSelect = "";
            this.seqData.postGene = "";
            remaining = dSdRIndS - gRIndS;
          } else {
            // Case 13
            this.seqData.preGene = "";
            this.seqData.geneSelect = "";
            this.seqData.postGene = "";
          }
        }
      }
      return remaining;
    },
    computeSeqDataAfter2: function(
      dStart,
      dRange,
      dSgSDist,
      sStart,
      sRange,
      sSgSDist,
      remaining,
      gSeq,
      dSeq
    ) {
      const dRLen = dRange;
      if (dStart < 0) {
        const gSLen = dSgSDist;
        if (sStart < 0) {
          const sSLen = gSLen - sSgSDist;
          const sRLen = sSLen + sRange;
          const gRLen = gSLen + gSeq.length;
          if (sRLen < gRLen) {
            // Case 6a, 1a
            this.seqData.preAfterGene = "";
            this.seqData.afterGeneSelect = "";
            this.seqData.postAfterGene = dSeq.substring(gRLen, dRLen);
          } else {
            // Case 1
            this.seqData.preAfterGene = "";
            this.seqData.afterGeneSelect = "";
            this.seqData.postAfterGene = dSeq.substring(sRLen, dRLen);
          }
        } else {
          // sStart >= 0
          const sSLen = gSLen + sStart;
          const sRLen = sSLen + sRange;
          const gRLen = gSLen + gSeq.length;
          if (sRLen < gRLen) {
            // Case 4
            this.seqData.preAfterGene = "";
            this.seqData.afterGeneSelect = "";
            this.seqData.postAfterGene = dSeq.substring(gRLen, dRLen);
          } else if (sSLen < gRLen) {
            // Case 2
            this.seqData.preAfterGene = "";
            this.seqData.afterGeneSelect = "";
            this.seqData.postAfterGene = dSeq.substring(sRLen, dRLen);
          } else {
            // Case 3 (sSLen >= gRLen)
            this.seqData.preAfterGene = dSeq.substring(gRLen, sSLen);
            this.seqData.afterGeneSelect = dSeq.substring(sSLen, sRLen);
            this.seqData.postAfterGene = dSeq.substring(sRLen, dRLen);
          }
        }
        remaining = 0;
      } else if (dStart < gSeq.length) {
        // dStart >= 0 && dStart < gSeq's length
        const gRange = gSeq.length;
        const sSsR = sStart + sRange;
        const dSdR = dStart + dRange;
        const sSIndS = sStart - dStart;
        const sSsRIndS = sSsR - dStart;
        const dSdRIndS = dSdR - dStart;
        const gRIndS = gRange - dStart;

        // const dSdR = dStart + dRange;
        if (sSsR < gRange) {
          // Case 10
          this.seqData.preAfterGene = "";
          this.seqData.afterGeneSelect = "";
          this.seqData.postAfterGene = dSeq.substring(gRIndS, dSdRIndS);
        } else if (sStart < gRange) {
          // Case 11
          this.seqData.preAfterGene = "";
          this.seqData.afterGeneSelect = "";
          this.seqData.postAfterGene = dSeq.substring(sSsRIndS, dSdRIndS);
        } else {
          // Case 12
          this.seqData.preAfterGene = dSeq.substring(gRIndS, sSIndS);
          this.seqData.afterGeneSelect = dSeq.substring(sSIndS, sSsRIndS);
          this.seqData.postAfterGene = dSeq.substring(sSsRIndS, dSdRIndS);
        }
        remaining = 0;
      } else {
        // dStart >= gSeq's length
        // Case 13
        const sSsR = sStart + sRange;
        const dSdR = dStart + dRange;
        const sSIndS = sStart - dStart;
        const sSsRIndS = sSsR - dStart;
        const dSdRIndS = dSdR - dStart;
        this.seqData.preAfterGene = dSeq.substring(0, sSIndS);
        this.seqData.afterGeneSelect = dSeq.substring(sSIndS, sSsRIndS);
        this.seqData.postAfterGene = dSeq.substring(sSsRIndS, dSdRIndS);
        remaining = 0;
      }
      return remaining;
    },
    updateSeqData: function() {
      // NOP if this.seqData is null
      if (!this.seqData || !this.seqData.displaySeq) return;

      const dStart = this.displayData.numStart;
      const dRange = this.displayData.numRange;
      const sStart = this.selectData.numStart;
      const sRange = this.selectData.numRange;
      const gSeq = this.computedNewSeq;
      const dSeq = this.seqData.displaySeq;

      // eslint-disable-next-line prettier/prettier
      let dSgSDist = dStart < 0 ? dStart * -1 : dStart;
      let sSgSDist = sStart < 0 ? sStart * -1 : sStart;
      let remaining = dRange;
      if (dStart < 0) {
        remaining = this.computeSeqDataBefore2(
          dStart,
          dRange,
          dSgSDist,
          sStart,
          sRange,
          sSgSDist,
          dSeq
        );
      } else {
        this.clearBeforeGene();
      }
      if (remaining > 0 && dStart < gSeq.length) {
        remaining = this.computeSeqDataGene2(
          dStart,
          dRange,
          dSgSDist,
          sStart,
          sRange,
          sSgSDist,
          remaining,
          gSeq,
          dSeq
        );
      } else {
        this.clearGene();
      }
      if (remaining > 0) {
        remaining = this.computeSeqDataAfter2(
          dStart,
          dRange,
          dSgSDist,
          sStart,
          sRange,
          sSgSDist,
          remaining,
          gSeq,
          dSeq
        );
      } else {
        this.clearAfterGene();
      }
    },
    updateSeqDataNoSelect: function() {
      // NOP if this.seqDataNoSelect is null
      if (!this.seqDataNoSelect) return;

      const dStart = this.displayData.numStart;
      const dRange = this.displayData.numRange;
      const gSeq = this.computedNewSeq;
      const dSeq = this.seqData.displaySeq;

      // eslint-disable-next-line prettier/prettier
      let dSgSDist = dStart < 0 ? dStart * -1 : dStart;

      let remainingNoSelect = dRange;
      if (dStart < 0) {
        remainingNoSelect = this.computeSeqDataBefore(
          dStart,
          dRange,
          dSgSDist,
          dSeq
        );
      } else {
        this.clearBeforeGeneNoSelect();
      }
      if (remainingNoSelect > 0 && dStart < gSeq.length) {
        remainingNoSelect = this.computeSeqDataGene(
          dStart,
          dRange,
          dSgSDist,
          remainingNoSelect,
          gSeq,
          dSeq
        );
      } else {
        this.clearGeneNoSelect();
      }
      if (remainingNoSelect > 0) {
        remainingNoSelect = this.computeSeqDataAfter(
          dStart,
          dRange,
          dSgSDist,
          remainingNoSelect,
          gSeq,
          dSeq
        );
      } else {
        this.clearAfterGeneNoSelect();
      }
    },
    computeSelectSeq: async function() {
      // await loading of possibly adjusted sequence
      this.setDisplayStart(this.displayData.start);
      this.setDisplayRange(this.displayData.range);
      await this.computeDisplaySeq(
        this.displayData.numStart,
        this.displayData.numRange
      );

      const sData = this.seqData;
      if (sData) {
        if (sData.beforeGeneSelect && sData.beforeGeneSelect.length > 0) {
          sData.selectSeq = this.seqData.beforeGeneSelect;
        } else if (sData.geneSelect && sData.geneSelect.length > 0) {
          sData.selectSeq = this.seqData.geneSelect;
        } else if (sData.afterGeneSelect && sData.afterGeneSelect.length > 0) {
          sData.selectSeq = this.seqData.afterGeneSelect;
        }
      }
      this.newItem.seq = sData.selectSeq;
    },
    computeDisplaySeq: async function(start, range) {
      const res = await OlabBlock.computeSequence(
        this.selectedGene,
        start,
        range,
        this.statusObj,
        this.errorObj
      );
      if (res.status === "success") {
        this.seqData.displaySeq = res.seqFrag;
        // console.log("seqData.displaySeq =", this.seqData.displaySeq);
        this.updateSeqData();
        this.updateSeqDataNoSelect();
      } else {
        // console.log(res);
        if (res.failType === "start") {
          this.displayData.startValid = false;
          this.displayData.startInvalidFeedback = res.message;
        } else {
          // "range" case
          this.displayData.rangeValid = false;
          this.displayData.rangeInvalidFeedback = res.message;
        }
      }
    },
    setDisplayStart: function(start) {
      this.displayData.startValid =
        start.toString().match(/^[-+]?(0|[1-9]\d*)$/) != null;
      if (this.displayData.startValid) {
        const tmpStart = parseInt(start);
        if (tmpStart > this.selectData.numStart) {
          this.displayData.startValid = false;
          this.displayData.startInvalidFeedback =
            "Start can not be greater than Select's start";
        } else {
          const dRPos = this.displayData.numRange + tmpStart;
          const sRPos = this.selectData.numRange + this.selectData.numStart;
          if (dRPos < sRPos) {
            // Adjust range to include select region
            this.displayData.range = sRPos - tmpStart;
          }
          this.displayData.numStart = tmpStart;
        }
      } else {
        this.displayData.startInvalidFeedback = "Start has to be an integer";
      }
    },
    setDisplayRange: function(range) {
      this.displayData.rangeValid =
        range.toString().match(/^[1-9]\d*$/) != null;
      if (this.displayData.rangeValid) {
        const tmpRange = parseInt(range);
        if (tmpRange > 5000) {
          this.displayData.rangeValid = false;
          this.displayData.rangeInvalidFeedback =
            "Length can not be greater than 5000";
        } else {
          const dRPos = tmpRange + this.displayData.numStart;
          const sRPos = this.selectData.numRange + this.selectData.numStart;
          if (dRPos < sRPos) {
            this.displayData.rangeValid = false;
            this.displayData.rangeInvalidFeedback =
              "Length is too small for valid Select's values";
          } else {
            this.displayData.numRange = tmpRange;
          }
        }
      } else {
        this.displayData.rangeInvalidFeedback =
          "Length has to be a positive integer (>0)";
      }
    },
    setSelectStart: function(start) {
      this.selectData.startValid =
        start.toString().match(/^[-+]?(0|[1-9]\d*)$/) != null;
      if (this.selectData.startValid) {
        const tmpStart = parseInt(start);
        if (tmpStart >= this.displayData.numStart) {
          const dRPos = this.displayData.numRange + this.displayData.numStart;
          const sRPos = this.selectData.numRange + tmpStart;
          if (dRPos < sRPos) {
            // Adjust display's range to include select region
            this.displayData.range = sRPos - this.displayData.numStart;
          }
        } else {
          // Adjust display's start to fix
          this.displayData.start = tmpStart;
        }
        this.selectData.numStart = tmpStart;
      } else {
        this.selectData.startInvalidFeedback = "Start has to be an integer";
      }
    },
    setSelectRange: function(range) {
      this.selectData.rangeValid = range.toString().match(/^[1-9]\d*$/) != null;
      if (this.selectData.rangeValid) {
        const tmpRange = parseInt(range);
        if (tmpRange > 5000) {
          this.selectData.rangeValid = false;
          this.selectData.rangeInvalidFeedback =
            "Length can not be greater than 5000";
        } else {
          const dRPos = this.displayData.numRange + this.displayData.numStart;
          const sRPos = tmpRange + this.selectData.numStart;
          if (sRPos > dRPos) {
            // Adjust display's range to include select region
            this.displayData.range = sRPos - this.displayData.numStart;
          }
          this.selectData.numRange = tmpRange;
        }
      } else {
        this.selectData.rangeInvalidFeedback =
          "Length has to be a positive integer (>0)";
      }
    },
    notInPackage(gn, comps) {
      let result = true;
      OlabUtils.infoLog("gn.olab_id = " + gn.olab_id);
      OlabUtils.infoLog("comps.length = " + comps.length);
      comps.forEach(comp => {
        if (gn.olab_id == comp.olab_id) {
          OlabUtils.infoLog("gn == comp = " + (gn == comp));
          result = false;
        }
      });
      OlabUtils.infoLog("**** result = " + result);
      return result;
    },
    registerGene: function(gn) {
      this.selectedGene = gn;
      if (!this.selectedCart && this.isLibseqPackage) {
        this.selectedUseSeqAs = this.selectedPackage.package_type;
      } else {
        this.selectedUseSeqAs = "";
      }
      // OlabUtils.infoLog("registerGene: gene = " + gn);
      this.gene.set(gn);
    },
    addToPackageComps: function(gn) {
      // console.log("addToPackageComps: gene = ", gn);
      this.registerGene(gn);
      const useSeqAs = this.selectedPackage.package_type;
      // console.log("addToPackageComps: useSeqAs = ", useSeqAs);
      this.handleAddToComps(gn, useSeqAs);
    },
    handleAddToComps: async function(gn, useSeqAs) {
      // console.log("handleAddToComps: gn = ", gn, ", useSeqAs =", useSeqAs);
      if (this.createNewSeq) {
        // Set progress message and status indicator
        this.statusObj.progress = true;
        let res = null;
        if (this.selectedCart) {
          // console.log("New Add Comp to Cart - newItem =", this.newItem);
          // Set newItem's library_id
          this.newItem.library_id = OlabUtils.getLibraryType(useSeqAs);
          res = await OlabAxios.addCompToCart(
            this.newItem,
            "library_sequence",
            this.selectedCart,
            this.statusObj,
            this.errorObj
          );
        } else if (this.selectedPackage) {
          // console.log("New Add Comp to Package - newItem =", this.newItem);
          // Set newItem's library_id
          this.newItem.library_id = this.selectedPackage.library_id;
          res = await OlabAxios.addCompToPackage(
            this.newItem,
            "library_sequence",
            this.selectedPackage,
            this.statusObj,
            this.errorObj
          );
        }

        if (res && res.status === "success") {
          // console.log(res.message);
        } else {
          console.log(
            "Unexpect status =",
            res.status,
            ", message =",
            res.message
          );
        }
        this.clearNewItem();
      } else {
        // Add the follow fields for cart's comps use
        gn.length = gn.gene_stats.len;
        gn.olab_type = "strain";

        this.$emit("doAddToComps", { item: gn, useSeqAs: useSeqAs });
      }
    }
  },
  watch: {
    "displayData.start": async function() {
      if (!this.newItem) return;
      this.setDisplayStart(this.displayData.start);
      await this.computeDisplaySeq(
        this.displayData.numStart,
        this.displayData.numRange
      );
    },
    "displayData.range": async function() {
      if (!this.newItem) return;
      this.setDisplayRange(this.displayData.range);
      await this.computeDisplaySeq(
        this.displayData.numStart,
        this.displayData.numRange
      );
    },
    "selectData.start": async function() {
      if (!this.newItem) return;
      this.setSelectStart(this.selectData.start);
      await this.computeSelectSeq();
    },
    "selectData.range": async function() {
      if (!this.newItem) return;
      this.setSelectRange(this.selectData.range);
      await this.computeSelectSeq();
    }
  }
};
</script>

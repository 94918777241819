<template>
  <div>
    <div class="list-group list-group-flush">
      <div
        class="list-group-item d-flex mb-1 align-items-center"
        v-for="sid in selectedItems"
        :key="sid.olab_id"
      >
        <div class="container">
          <div class="row">
            <div
              v-if="
                (user.email === sid.creator ||
                  user.email === sid.email ||
                  (user.role && user.role === 'admin')) &&
                  (selectedType !== 'design' ||
                    (selectedType === 'design' && !sid.deleted)) &&
                  selectedType !== 'construct' &&
                  selectedType !== 'primer'
              "
              class="col-1 align-self-center mx-2"
            >
              <button
                type="button"
                class="btn btn-info"
                data-toggle="modal"
                data-target="#selectEditItem"
                v-on:click="registerItem(sid)"
                data-backdrop="static"
                data-keyboard="false"
              >
                <font-awesome-icon icon="edit" />
              </button>
            </div>
            <div v-else class="col-1 mx-2">
              <!-- NOP -->
            </div>

            <div
              v-if="selectedType === 'project'"
              class="col-10 col-sm-4 align-self-center mx-2"
            >
              <OlabProjectSVGGen name="Project" textPosX="160" textPosY="280" />
            </div>
            <div
              v-else-if="selectedType === 'cart'"
              class="col-10 col-sm-4 align-self-center mx-2"
            >
              <OlabCartSVGGen :comps="sid.comps" />
            </div>
            <div
              v-else-if="selectedType === 'construct'"
              class="col-10 col-sm-4 align-self-center mt-2 mx-2 stats-form"
            >
              <div class="container">
                <div class="row">
                  <div class="col-6 col-sm-12 col-lg-6">
                    <div v-if="sid.cstbs" class="row">
                      <span class="mr-1"># boxes:</span>
                      <span>{{ sid.cstbs.length }}</span>
                    </div>
                    <div v-if="sid.seq" class="row">
                      <span class="mr-1">Length:</span>
                      <span>{{ sid.seq.length }}</span>
                    </div>
                  </div>
                  <div class="col-6 col-sm-12 col-lg-6">
                    <div v-if="sid.forwards && sid.backwards" class="row">
                      <span class="mr-1"># dsgn pmrs:</span>
                      <span>{{
                        sid.forwards.length + sid.backwards.length
                      }}</span>
                    </div>
                    <div
                      v-if="sid.cpcr_forwards && sid.cpcr_backwards"
                      class="row"
                    >
                      <span class="mr-1"># cPCR pmrs:</span>
                      <span>{{
                        sid.cpcr_forwards.length + sid.cpcr_backwards.length
                      }}</span>
                    </div>
                    <div
                      v-if="sid.diag_forwards && sid.diag_backwards"
                      class="row"
                    >
                      <span class="mr-1"># seq pmrs:</span>
                      <span>{{
                        sid.diag_forwards.length + sid.diag_backwards.length
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else-if="selectedType === 'design'"
              class="col-10 col-sm-4 align-self-center mt-2 mx-2 stats-form"
            >
              <div class="container">
                <div class="row">
                  <div class="col-6 col-sm-12 col-lg-6">
                    <div class="row">
                      <span class="mr-1">Primer Setting:</span>
                      <span>{{ sid.pmr_setting }}</span>
                    </div>
                    <div class="row">
                      <span class="mr-1">3' Length:</span>
                      <span>{{ sid.pmr_3p_len }}</span>
                    </div>
                    <div class="row">
                      <span class="mr-1">5' Length:</span>
                      <span>{{ sid.pmr_5p_len }}</span>
                    </div>
                  </div>
                  <div class="col-6 col-sm-12 col-lg-6">
                    <div class="row">
                      <span class="mr-1">Package:</span>
                      <span>{{ sid.package_present }}</span>
                    </div>
                    <div v-if="sid.stats" class="row">
                      <span class="mr-1">Total Constructs:</span>
                      <span>{{ sid.stats.construct_count }}</span>
                    </div>
                    <div v-if="sid.stats" class="row">
                      <span class="mr-1">Total Primers:</span>
                      <span>{{ sid.stats.comb_pmr_count }}</span>
                    </div>
                    <div v-if="sid.stats" class="row">
                      <span class="mr-1">Unique Primers:</span>
                      <span>{{ sid.stats.uniq_pmr_count }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else-if="selectedType === 'package'"
              class="col-10 col-sm-4 align-self-center mx-2"
            >
              <OlabPackageSVGGen
                :type="sid.package_type"
                :seqType="sid.package_seq_type"
                :comps="sid.comps"
              />
            </div>
            <div
              v-else-if="selectedType === 'library_sequence'"
              class="col-10 col-sm-4 align-self-center mx-2"
            >
              <OlabDnaSVGGen name="Sequence" textPosX="230" textPosY="390" />
            </div>
            <div
              v-else-if="selectedType === 'user'"
              class="col-10 col-sm-4 align-self-center mx-2"
            >
              <OlabUserSVGGen :usr="sid" />
            </div>
            <div
              v-else-if="selectedType === 'primer'"
              class="col-10 col-sm-4 align-self-center mt-2 mx-2 stats-form"
            >
              <div class="container">
                <div class="row">
                  <div class="col-6 col-sm-12 col-lg-6">
                    <div v-if="sid.primer_type" class="row">
                      <span class="mr-1">Type:</span>
                      <span>{{ sid.primer_type }}</span>
                    </div>
                    <div v-if="sid.origin_3p" class="row">
                      <span class="mr-1">origin_3p:</span>
                      <span>{{ sid.origin_3p }}</span>
                    </div>
                    <div v-if="sid.primer_type === 'design'" class="row">
                      <span class="mr-1">Reverse:</span>
                      <span>{{ sid.reverse }}</span>
                    </div>
                    <div v-if="sid.tm" class="row">
                      <span class="mr-1">tm:</span>
                      <span>{{ sid.tm }}</span>
                    </div>
                    <div v-if="sid.tm_3p" class="row">
                      <span class="mr-1">tm_3p:</span>
                      <span>{{ sid.tm_3p }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else-if="selectedType === 'strain'"
              class="col-10 col-sm-4 align-self-center mt-2 mx-2 stats-form"
            >
              <div class="container">
                <div class="row">
                  <div class="col-6 col-sm-12 col-lg-6">
                    <div class="row">
                      <span class="mr-1">Chromosome:</span>
                      <span v-if="!sid.chrm_array">0</span>
                      <span v-else>{{ sid.chrm_array.length }}</span>
                    </div>
                    <div
                      v-if="sid.contig_array && sid.contig_array.length > 0"
                      class="row"
                    >
                      <span class="mr-1">Contig:</span>
                      <span>{{ sid.contig_array.length }}</span>
                    </div>
                    <div class="row">
                      <span class="mr-1">Length:</span>
                      <span>{{ sid.stats.total_seq_len }}</span>
                    </div>
                    <div class="row my-3">
                      <span class="mr-1">Plasmid:</span>
                      <span v-if="!sid.plmd_array">0</span>
                      <span v-else> {{ sid.plmd_array.length }} </span>
                    </div>
                  </div>
                  <div class="col-6 col-sm-12 col-lg-6">
                    <div class="row">
                      <span class="mr-1">CDS:</span>
                      <span>{{ sid.stats.total_CDS }}</span>
                    </div>
                    <div class="row">
                      <span class="mr-1">ncRNA:</span>
                      <span>{{ sid.stats.total_ncRNA }}</span>
                    </div>
                    <div class="row">
                      <span class="mr-1">rRNA:</span>
                      <span>{{ sid.stats.total_rRNA }}</span>
                    </div>
                    <div class="row">
                      <span class="mr-1">tmRNA:</span>
                      <span>{{ sid.stats.total_tmRNA }}</span>
                    </div>
                    <div class="row">
                      <span class="mr-1">tRNA:</span>
                      <span>{{ sid.stats.total_tRNA }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else-if="selectedType === 'plasmid'"
              class="col-10 col-sm-4 align-self-center mt-2 mx-2 stats-form"
            >
              <div class="container">
                <div class="row">
                  <div class="col-6 col-sm-12 col-lg-6">
                    <div class="row">
                      <span class="mr-1 mb-3">Length:</span>
                      <span>{{ sid.stats.total_seq_len }}</span>
                    </div>
                  </div>
                  <div class="col-6 col-sm-12 col-lg-6">
                    <div class="row">
                      <span class="mr-1">CDS:</span>
                      <span>{{ sid.stats.total_CDS }}</span>
                    </div>
                    <div v-if="sid.stats.total_ncRNA > 0" class="row">
                      <span class="mr-1">ncRNA:</span>
                      <span>{{ sid.stats.total_ncRNA }}</span>
                    </div>
                    <div v-if="sid.stats.total_rRNA > 0" class="row">
                      <span class="mr-1">rRNA:</span>
                      <span>{{ sid.stats.total_rRNA }}</span>
                    </div>
                    <div v-if="sid.stats.total_tmRNA > 0" class="row">
                      <span class="mr-1">tmRNA:</span>
                      <span>{{ sid.stats.total_tmRNA }}</span>
                    </div>
                    <div v-if="sid.stats.total_tRNA > 0" class="row">
                      <span class="mr-1">tRNA:</span>
                      <span>{{ sid.stats.total_tRNA }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 col-sm-6 mt-2 align-self-center text-truncate">
              <a
                class="h4 text-info"
                data-toggle="modal"
                href="#viewItem"
                v-on:click="registerItem(sid)"
                data-backdrop="static"
                data-keyboard="false"
                >{{ sid.name }}</a
              >

              <div v-if="selectedType === 'library_sequence'" class="h6 mt-2">
                <div>
                  {{ sid.seq_origin }}
                </div>
              </div>
              <div
                v-if="
                  (selectedType === 'library_sequence' ||
                    selectedType === 'construct' ||
                    selectedType === 'primer') &&
                    sid.seq
                "
                class="h6"
              >
                <div class="mb-0 text-monospace text-truncate mt-2">
                  {{ sid.seq }}
                </div>
                <div class="h6">
                  {{ sid.seq.length }}
                </div>
              </div>
              <div v-if="selectedType === 'plasmid'" class="h6 mt-2">
                {{ sid.plasmid_ori }}
              </div>
              <div class="h6 mt-2">
                <span>{{ sid.olab_id }}</span>
                <span v-if="sid.deleted" class="text-light bg-danger ml-2"
                  >DELETED</span
                >
              </div>
              <div
                v-if="selectedType === 'design' && sid.deleted"
                class="text-primary"
              >
                <span class="text-light bg-primary mr-1">Note:</span>
                <span class="text-primary"
                  >Deleted design can only be undeleted via its cart</span
                >
              </div>
              <div v-if="selectedType === 'user'" class="h6">
                {{ sid.email }}
              </div>
              <div class="h6">
                {{ sid.creator }}
              </div>
              <div
                v-if="
                  (selectedType === 'project' ||
                    selectedType === 'strain' ||
                    selectedType === 'plasmid') &&
                    sid.ra_locks
                "
              >
                <div v-if="sid.ra_locks.public === true" class="h6">
                  Public
                </div>
                <div v-else class="h6">
                  Member&nbsp;Only
                </div>
              </div>

              <!-- Buttons -->
              <div
                v-if="selectedType === 'plasmid' || selectedType === 'strain'"
              >
                <div class="row justify-content-left">
                  <div v-if="isGeneViewable(sid)" class="col-12 col-lg-6 mb-2">
                    <span class="mr-1">
                      <button
                        type="button"
                        v-on:click="handleSwitchToGene(sid)"
                        class="btn btn-info"
                      >
                        View&nbsp;Genes
                      </button>
                    </span>
                    <span v-if="selectedType === 'plasmid'" class="mx-1">
                      <button
                        type="button"
                        v-on:click="handlePlasmidDownload(sid)"
                        class="btn btn-info"
                      >
                        <span
                          v-if="statusObj.progress && downloadItem === sid"
                          class="spinner-border spinner-border-sm text-light"
                        ></span>
                        Download
                      </button>
                    </span>
                  </div>
                  <div
                    v-if="
                      user.role === 'admin' &&
                        sid.ra_locks &&
                        !sid.ra_locks.public
                    "
                    class="col-12 col-lg-6 mb-2"
                  >
                    <button
                      type="button"
                      v-on:click="handleSwitchToProject(sid)"
                      class="btn btn-info"
                    >
                      Manage&nbsp;Projects
                    </button>
                  </div>
                </div>
              </div>
              <div
                v-else-if="
                  selectedType === 'project' &&
                    (user.role === 'admin' || user.email === sid.creator) &&
                    sid.ra_locks &&
                    !sid.ra_locks.public
                "
              >
                <button
                  type="button"
                  v-on:click="handleSwitchToMember(sid)"
                  class="btn btn-info"
                >
                  Manage&nbsp;Members
                </button>
              </div>
              <div v-else-if="selectedType === 'design'">
                <div class="row">
                  <div class="col col-md-6 mt-2">
                    <button
                      type="button"
                      v-on:click="handleSwitchToConstruct(sid)"
                      class="btn btn-info"
                    >
                      View&nbsp;Constructs
                    </button>
                  </div>
                  <div class="col col-md-6 mt-2">
                    <button
                      type="button"
                      class="btn btn-info"
                      data-toggle="modal"
                      data-target="#downloadItem"
                      v-on:click="registerItem(sid)"
                      data-backdrop="static"
                      data-keyboard="false"
                    >
                      Download
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!-- Modal: EditItem -->
            <div
              v-if="selectedItem != null"
              class="modal fade"
              id="selectEditItem"
            >
              <OlabEditItem
                :user="user"
                :dbInfo="dbInfo"
                :selectedType="selectedType"
                :item="item"
                :itemProjDetail="itemProjDetail"
                :availPlasmidArr="availPlasmidArr"
                @handleSaveItem="handleSaveItem"
                @handleDeleteItem="handleDeleteItem"
                @updatePlasmidArrs="updatePlasmidArrs"
              />
            </div>
            <!-- .modal fade #selectEditItem -->

            <!-- Modal: ViewItem -->
            <div v-if="selectedItem != null" class="modal fade" id="viewItem">
              <OlabViewItem
                :dbInfo="dbInfo"
                :selectedType="selectedType"
                :item="selectedItem"
                :plasmidPrimers="plasmidPrimers"
                :itemProjDetail="itemProjDetail"
                :publicAccess="publicAccess"
                :memberDetailArr="memberDetailArr"
                :userProjectDetailArr="userProjectDetailArr"
                :projStrainDetailArr="projStrainDetailArr"
              />
            </div>
            <!-- .modal fade #viewItem -->

            <!-- Modal: DownloadItem -->
            <div
              v-if="selectedItem != null"
              class="modal fade"
              id="downloadItem"
            >
              <OlabDownloadItem
                :user="user"
                :dbInfo="dbInfo"
                :selectedType="selectedType"
                :item="item"
                :itemProjDetail="itemProjDetail"
                :cstPrimers="true"
                :statusObj="statusObj"
                :errorObj="errorObj"
              />
            </div>
            <!-- .modal fade #downloadItem -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import OlabCartSVGGen from "@/components/OlabCartSVGGen";
import OlabPackageSVGGen from "@/components/OlabPackageSVGGen";
import OlabProjectSVGGen from "@/components/OlabProjectSVGGen";
import OlabDnaSVGGen from "@/components/OlabDnaSVGGen";
import OlabUserSVGGen from "@/components/OlabUserSVGGen";
import OlabViewItem from "@/components/OlabViewItem";
import OlabEditItem from "@/components/OlabEditItem";
import OlabDownloadItem from "@/components/OlabDownloadItem";
import { OlabAxios } from "@/olab/olabaxios.js";
import { OlabUtils } from "@/olab/olabutils.js";
import { OlabSearch } from "@/olab/olabsearch";

export default {
  name: "databaseitemlist",
  data: function() {
    return {
      selectedItem: null,
      downloadItem: null,
      itemProjDetail: "",
      publicAccess: false,
      memberDetailArr: [],
      userProjectDetailArr: [],
      projStrainDetailArr: [],
      allPlasmidArr: [],
      availPlasmidArr: [],
      plasmidPrimers: []
    };
  },
  props: [
    "user",
    "dbInfo",
    "statusObj",
    "errorObj",
    "selectedItems",
    "selectedType",
    "item"
  ],
  components: {
    FontAwesomeIcon,
    OlabCartSVGGen,
    OlabPackageSVGGen,
    OlabProjectSVGGen,
    OlabDnaSVGGen,
    OlabUserSVGGen,
    OlabViewItem,
    OlabEditItem,
    OlabDownloadItem
  },
  methods: {
    registerItem: async function(item) {
      this.selectedItem = item;
      // console.log("registerItem: item = ", item);
      // Copy data to this.item so that v-model for edit item.
      this.item.set(item);
      this.item.cloneRaKeysAndLocks();
      try {
        if (this.selectedType === "project") {
          // Get project's strains and plasmids
          this.projStrainDetailArr = await OlabUtils.getProjStrains(
            this.item.olab_id
          );
          if (this.item.ra_locks) {
            const raLocks = this.item.ra_locks;
            this.publicAccess = raLocks.public;
            if (raLocks.members) {
              // Get members info (user name)
              this.memberDetailArr = await OlabUtils.getProjectMembers(
                this.item.olab_id
              );
            }
          }
        } else if (this.selectedType === "user") {
          // Get projects info (project name)
          this.userProjectDetailArr = await OlabUtils.getUserProjects(
            this.item.email
          );
        } else if (this.selectedType === "strain") {
          if (this.item.ra_locks) {
            const raLocks = this.item.ra_locks;
            this.publicAccess = raLocks.public;
            if (raLocks.members) {
              // Get members info for strain's projects
              this.memberDetailArr = await OlabUtils.getStrainMembers(
                this.item.olab_id
              );
            }
          }
          // TODO: Need to introduce a better ALL than OlabSearch.maxCount
          // Get all plasmids and compute available plasmids
          const searchBody = OlabSearch.createItemSearchBody(
            "plasmid",
            "",
            "olab_id",
            false,
            null,
            1,
            OlabSearch.maxCount
          );
          this.allPlasmidArr = await OlabUtils.getPlasmids(searchBody);
          this.availPlasmidArr = this.removeSelectedPlasmids(
            this.allPlasmidArr,
            this.item
          );
          // console.log(this.allPlasmidArr);
          // console.log(this.availPlasmidArr);
        } else if (this.selectedType === "plasmid") {
          if (this.item.ra_locks) {
            const raLocks = this.item.ra_locks;
            this.publicAccess = raLocks.public;
            if (raLocks.members && raLocks.members.length > 0) {
              // Get members info for plasmid's projects
              this.memberDetailArr = await OlabUtils.getPlasmidMembers(
                this.item.olab_id
              );
            }
          }
          // Process meta_map for plasmid's primers
          if (this.item.meta_map) {
            const metaMap = this.item.meta_map;
            // Reset this.plasmidPrimers
            this.plasmidPrimers.length = 0;
            metaMap.map(meta => {
              if (meta.type === "Primer") {
                this.plasmidPrimers.push(meta);
              }
            });
            this.plasmidPrimers.sort((a, b) => {
              const nameA = a.name.toUpperCase();
              const nameB = b.name.toUpperCase();
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              // names must be equal
              return 0;
            });
          } else {
            // clear the existing array
            this.plasmidPrimers.length = 0;
          }
          // console.log("plasmidPrimers =", this.plasmidPrimers);
        } else {
          // (this.selectedType !== "user" && this.selectedType !== "project")
          // Load project from item.project_id
          const searchBody = OlabSearch.createItemSearchBody(
            "project",
            this.item.project_id,
            "olab_id",
            false,
            null,
            1,
            1
          );
          const projectArr = await OlabUtils.getProjects(searchBody);
          const proj = projectArr ? projectArr[0] : null;
          if (proj) {
            this.itemProjDetail = `${proj.olab_id} - ${proj.name}`;
          } else {
            OlabUtils.infoLog("No such project document!");
          }
        }
      } catch (err) {
        OlabUtils.infoLog("Error writing document: " + err);
        this.errorObj.message = err;
      }
    },
    isGeneViewable: function(sid) {
      if (sid.olab_type !== "strain" && sid.olab_type !== "plasmid") {
        return false;
      }
      // console.log("sid.ra_locks:", sid.ra_locks);
      // console.log("user.selected.project_id:", this.user.selected.project_id);

      // If strain or plasmid has no lock or is public accessible
      if (!sid.ra_locks || (sid.ra_locks && sid.ra_locks.public)) {
        return true;
      }

      // User has no selected project
      if (!this.user.selected || !this.user.selected.project_id) {
        return false;
      }
      // Return true if project id is in strain's or plasmid's member list
      const projID = this.user.selected.project_id;
      const memIDs = sid.ra_locks.members ? sid.ra_locks.members : [];
      for (let i = 0; i < memIDs.length; i++) {
        if (memIDs[i] === projID) {
          return true;
        }
      }
      return false;
    },
    handleDeleteItem: function() {
      // console.log("handleDeleteItem: selectedType = ", this.selectedType);
      // Add additional field before emit
      this.item.olab_type = this.selectedType;

      this.$emit("doDeleteItem", {
        item: this.item,
        items: this.selectedItems
      });
    },
    handleSaveItem: function() {
      // console.log("handleSaveItem: selectedType = ", this.selectedType);
      // Add additional field before emit
      this.item.olab_type = this.selectedType;

      this.$emit("doSaveItem", {
        item: this.item,
        items: this.selectedItems
      });

      // Update selectedItem
      this.registerItem(this.item);
    },
    handlePlasmidDownload: async function(item) {
      // console.log("handlePlasmidDownload: item = " + item);
      this.downloadItem = item;
      const genbankFile = await OlabAxios.downloadPlmdGenbank(
        item,
        this.statusObj,
        this.errorObj
      );
      this.downloadItem = null;
      // console.log("genbankFile =", genbankFile);
      // download file to client machine
      if (genbankFile) {
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.style.visibility = "hidden";

        const blob = new Blob([genbankFile], {
          type: "octet/stream"
        });
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = item.olab_id + "-genbank.gb";
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    },
    handleSwitchToGene: function(item) {
      OlabUtils.infoLog(
        "handleSwitchToGene: selectedType = " + this.selectedType
      );
      this.$emit("doSwitchToGene", item);
    },
    handleSwitchToConstruct: function(item) {
      OlabUtils.infoLog(
        `handleSwitchToConstruct: selectedType = ${this.selectedType}`
      );
      this.$emit("doSwitchToConstruct", item);
    },
    handleSwitchToMember: function(item) {
      OlabUtils.infoLog(
        "handleSwitchToMember: selectedType = " + this.selectedType
      );
      this.$emit("doSwitchToMember", item);
    },
    handleSwitchToProject: function(item) {
      OlabUtils.infoLog(
        "handleSwitchToProject: selectedType = " + this.selectedType
      );
      this.$emit("doSwitchToProject", item);
    },
    removeSelectedPlasmids: function(plasmids, item) {
      const plmds = item.plmd_array;
      // console.log("(1) plasmids =", plasmids);
      plasmids = plmds
        ? plasmids.filter(el => !plmds.includes(el.olab_id))
        : [];
      // console.log("(2) plasmids =", plasmids);
      return plasmids;
    },
    updatePlasmidArrs: function({ mode, id }) {
      // console.log("updatePlasmidArrs ... mode =", mode, ", id =", id);
      // console.log("(1) ...", this.availPlasmidArr);
      if (mode === "ADDED") {
        this.availPlasmidArr = this.availPlasmidArr.filter(
          el => el.olab_id != id
        );
      } else if (mode === "REMOVED") {
        const plmd = this.allPlasmidArr.find(el => el.olab_id === id);
        // console.log("plmd = ", plmd);
        if (plmd) {
          this.availPlasmidArr.push(plmd);
        }
      }
      // console.log("(2) ...", this.availPlasmidArr);
    }
  }
};
</script>

<style scoped>
.stats-form {
  /* background-color: #eed; */
  /* border-radius: 0.375rem; */
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;
}
</style>

<template>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          OLAB ID:
          <span class="text-dark">{{ item.olab_id }}</span>
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          v-on:click="reset()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <!-- .modal-header -->
      <div class="modal-body">
        <div class="py-1 text-wrap">
          <span class="mr-1">Name:</span>
          <span class="ml-1">{{ item.name }}</span>
        </div>
        <div v-if="selectedType !== 'user'" class="py-1 text-wrap">
          <span class="mr-1">Creator:</span>
          <span class="ml-1">{{ item.creator }}</span>
        </div>
        <div
          v-if="
            selectedType === 'cart' ||
              selectedType === 'design' ||
              selectedType === 'package' ||
              selectedType === 'library_sequence'
          "
          class="py-1 text-wrap"
        >
          <span class="mr-1">Project:</span>
          <span class="ml-1">{{ itemProjDetail }}</span>
        </div>
        <form>
          <fieldset class="form-group py-2">
            <div v-if="selectedType === 'design'" class="py-1 form-group">
              <div class="card">
                <div class="h5 card-header">Downloadable Data</div>
                <div class="card-body">
                  <div class="py-1">
                    <h5>Construct</h5>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="dlGenbank"
                      />
                      <label class="form-check-label">Genbank</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="dlFasta"
                      />
                      <label class="form-check-label">Fasta</label>
                    </div>
                  </div>
                  <hr />
                  <div class="py-1">
                    <h5>Plate</h5>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="dl96WellPlates"
                      />
                      <label class="form-check-label">96 Well Plates</label>
                    </div>
                  </div>
                  <hr />
                  <div class="py-1">
                    <h5>Primer</h5>
                    <div class="py-1">
                      <div
                        v-if="cstPrimers"
                        class="form-check form-check-inline"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          v-model="dlConstructPrimers"
                        />
                        <label class="form-check-label">Construct</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          v-model="dlFullPrimers"
                        />
                        <label class="form-check-label">Full</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          v-model="dlOrderPrimers"
                        />
                        <label class="form-check-label">Order</label>
                      </div>
                    </div>
                    <div v-if="cstPrimers">
                      <div class="h6 pt-3">
                        Diagnotic
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          v-model="dlDiagSeqPrimers"
                        />
                        <label class="form-check-label">Sequencing</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          v-model="dlDiagCPCRPrimers"
                        />
                        <label class="form-check-label">cPCR</label>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- .card-body -->
              </div>
              <!-- .card -->
            </div>
          </fieldset>
          <!-- .form-group -->
        </form>
      </div>
      <!-- .modal-body -->
      <div class="modal-footer">
        <button
          type="button"
          v-bind:disabled="itemDownloadIsDisabled === true"
          class="btn btn-primary"
          data-dismiss="modal"
          v-on:click="callDownloadItem()"
        >
          <span
            v-if="statusObj.progress"
            class="spinner-border spinner-border-sm text-light"
          ></span>
          Download
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          data-dismiss="modal"
          v-on:click="reset()"
        >
          Cancel
        </button>
      </div>
      <!-- .modal-footer -->
    </div>
    <!-- .modal-content -->
  </div>
  <!-- .modal-dialog .modal-lg-->
</template>

<script>
import { OlabItem } from "../olab/olabitem.js";
import { OlabDesign } from "../olab/olabdesign.js";
import $ from "jquery";

export default {
  name: "olabdownloaditem",
  data: function() {
    return {
      dlGenbank: false,
      dlFasta: false,
      dlFullPrimers: false,
      dlOrderPrimers: false,
      dlConstructPrimers: false,
      dlDiagSeqPrimers: false,
      dlDiagCPCRPrimers: false,
      dl96WellPlates: false,
      invalidFB: OlabItem.getInvalidFeedbacks()
    };
  },
  props: [
    "user",
    "dbInfo",
    "selectedType",
    "item",
    "itemProjDetail",
    "cstPrimers",
    "statusObj",
    "errorObj"
  ],
  computed: {
    itemDownloadIsDisabled: function() {
      const res = !(
        this.dlGenbank ||
        this.dlFasta ||
        this.dl96WellPlates ||
        this.dlFullPrimers ||
        this.dlOrderPrimers ||
        this.dlConstructPrimers ||
        this.dlDiagSeqPrimers ||
        this.dlDiagCPCRPrimers
      );
      // console.log(res);
      return res;
    }
  },
  methods: {
    callDownloadItem: async function() {
      // console.log(
      //   `Download Item: ${this.dlGenbank}, ${this.dlFasta}, ${this.dl96WellPlates} ${this.dlFullPrimers} ${this.dlOrderPrimers} ${this.dlConstructPrimers}`
      // );
      // console.log("Call item =", this.item);
      // console.log(
      //   "Call item is instanceof OlabDesign",
      //   this.item instanceof OlabDesign
      // );

      let dsgn = null;
      if (this.item instanceof OlabDesign) {
        dsgn = this.item;
      } else {
        dsgn = new OlabDesign(this.item);
      }
      // console.log("Call dsgn =", dsgn);

      this.statusObj.progress = true;
      if (this.dlGenbank) {
        await dsgn.downloadGenbank(this.statusObj, this.errorObj);
      }
      if (this.dlFasta) {
        await dsgn.downloadFasta(this.statusObj, this.errorObj);
      }
      if (this.dlFullPrimers) {
        await dsgn.downloadPrimersFile("full", this.statusObj, this.errorObj);
      }
      if (this.dlOrderPrimers) {
        await dsgn.downloadPrimersFile("order", this.statusObj, this.errorObj);
      }
      if (this.dlConstructPrimers) {
        await dsgn.downloadPrimersFile(
          "construct",
          this.statusObj,
          this.errorObj
        );
      }
      if (this.dlDiagSeqPrimers) {
        await dsgn.downloadPrimersFile(
          "diagseq",
          this.statusObj,
          this.errorObj
        );
      }
      if (this.dlDiagCPCRPrimers) {
        await dsgn.downloadPrimersFile(
          "diagcpcr",
          this.statusObj,
          this.errorObj
        );
      }
      if (this.dl96WellPlates) {
        await dsgn.download96WellPlateFile(this.statusObj, this.errorObj);
      }
      this.statusObj.progress = false;

      // Done and reset
      this.reset();
      $("#downloadItem").modal("hide");
    },
    reset: function() {
      this.dlGenbank = false;
      this.dlFasta = false;
      this.dl96WellPlates = false;
      this.dlFullPrimers = false;
      this.dlOrderPrimers = false;
      this.dlConstructPrimers = false;
      this.dlDiagSeqPrimers = false;
      this.dlDiagCPCRPrimers = false;
    }
  }
};
</script>

import { OlabPrimer } from "./olabprimer.js";
import { OlabUtils } from "./olabutils.js";

class OlabBlock {
  constructor(gnBlock) {
    // console.log("OlabBlock.constructor() ... gnBlock =", gnBlock);
    this.strain_id = gnBlock.strain_id;
    this.seq = gnBlock.seq;
    this.block = gnBlock.block;
    this.start = gnBlock.start;
    this.range = gnBlock.range;
    this.next_block = gnBlock.next_block;
    this.prev_block = gnBlock.prev_block;
  }

  static async _getConfigBlock(id, errorObj) {
    const firstDashIndex = id.indexOf("-");
    const microbeID = id.substring(0, firstDashIndex);
    // Strain ID = MMM-SSSSSS
    let strainID = id.substring(0, 10);
    // Parent ID can be either MMM-SSSSSS-CCC or MMM-SSSSSS-CCC-TTTT
    // Subtract 5 from length is to account for dash and gene id
    let parentID = id.substring(0, id.length - 5);
    // console.log(`id = ${id}, microbeID = ${microbeID}`);
    // console.log(`strainID = ${strainID}, parentID = ${parentID}`);
    return await OlabBlock._getConfigBlock2(
      microbeID,
      strainID,
      parentID,
      errorObj
    );
  }

  static async _getConfigBlock2(microbeID, strainID, parentID, errorObj) {
    // console.log(`${microbeID}, ${strainID}, ${parentID}`);
    const gbConfig = await OlabUtils.getGeneBlockConfig(parentID, errorObj);
    if (gbConfig) {
      gbConfig.microbeID = microbeID;
      gbConfig.strainID = strainID;
      gbConfig.parentID = parentID;
      // console.log("gbConfig =", gbConfig);
      return gbConfig;
    }
    return null;
  }

  // TODO: Move this method to backend if possible
  static async computeSequence(seq, start, range, statusObj, errorObj) {
    // console.log("OlabBlock.computeSequence: seq =", seq);
    // console.log("start = ", start, ", range = ", range);
    // console.log(`start = ${isNaN(start)}, range = ${isNaN(range)}`);
    if (isNaN(start) || isNaN(range)) {
      errorObj.message = `Cannot process NaN in start (${start}) or range ( ${range})`;
      return;
    }

    // Create a return response object
    const res = {
      status: "success", // or fail
      failType: "start", // or range
      message: "",
      seqFrag: ""
    };

    const comp = seq.sequence.comp === true;
    // TODO: Need to handle crossover cases
    const seqFrom = comp ? Number(seq.sequence.to) : Number(seq.sequence.from);
    const seqTo = comp ? Number(seq.sequence.from) : Number(seq.sequence.to);
    const seqLen = seqTo - seqFrom + 1;
    // comp is the complement info
    const contig = seq.olab_id ? seq.olab_id.substring(3, 6) === "-CT" : false;

    // console.log("seq.olab_id =", seq.olab_id);
    // console.log("seq.sequence.comp = ", seq.sequence.comp);
    // console.log("seqFrom = ", seqFrom, ", seqTo = ", seqTo);
    // console.log("seqLen = ", seqLen, ", comp = ", comp, ", contig = ", contig);

    // TODO: Move this to backend server
    const blockConfig = await OlabBlock._getConfigBlock(seq.olab_id, errorObj);

    const TOTAL_SEQ_SIZE = blockConfig.total_seq_size;
    // const LAST_BLOCK_SIZE = blockConfig.last_block_size;
    const BLOCK_SIZE = blockConfig.block_size;
    const MAX_BLOCK = blockConfig.max_block;
    // console.log("** TOTAL_SEQ_SIZE = ", TOTAL_SEQ_SIZE);
    // console.log("** LAST_BLOCK_SIZE = ", LAST_BLOCK_SIZE);
    // console.log("** BLOCK_SIZE = ", BLOCK_SIZE);
    // console.log("** MAX_BLOCK = ", MAX_BLOCK);

    if (contig && seqLen >= TOTAL_SEQ_SIZE) {
      if (contig) {
        res.status = "fail";
        res.failType = "range";
        res.message = `Request sequence exceeded contig's range. sequence'slength = ${seqLen}, contig's range = ${TOTAL_SEQ_SIZE}`;
        res.seqFrag = "";
        return res;
      }
    }
    let blockID = 0;
    let indexInBlock = 0;

    let seqOffset = seqFrom - 1 + start;
    // console.log("** seqOffset = ", seqOffset);

    // Handle complement case here.
    // console.log("** comp = ", comp);
    if (comp) {
      // Reverse complement case
      seqOffset = seqTo - start;
      seqOffset -= range;
      // console.log("** comp case: seqOffset = ", seqOffset);
      // console.log("** comp case: seqTo = ", seqTo, ", seqFrom = ", seqFrom);
    }

    // Handle positive seqOffset case
    if (seqOffset >= 0) {
      if (contig && seqOffset + range > TOTAL_SEQ_SIZE) {
        res.status = "fail";
        res.failType = "range";
        res.message = `Request sequence exceeded contig's range. Start (offset) = ${seqOffset}, range = ${range}, contig's range = ${TOTAL_SEQ_SIZE}`;
        res.seqFrag = "";
        return res;
      }
      // Wrap seqOffset if it great than TOTAL_SEQ_SIZE
      if (seqOffset >= TOTAL_SEQ_SIZE) {
        // console.log("** seqOffset >= TOTAL_SEQ_SIZE: " + seqOffset);
        if (contig) {
          res.status = "fail";
          res.failType = "start";
          res.message = `Sequence's start is outside of contig's range. Start (offset) = ${seqOffset}, contig's range = ${TOTAL_SEQ_SIZE}`;
          res.seqFrag = "";
          return res;
        }
        seqOffset = seqOffset % TOTAL_SEQ_SIZE;
        // console.log("** Wrapped seqOffset = " + seqOffset);
      }

      blockID = Math.floor(seqOffset / BLOCK_SIZE);
      indexInBlock = seqOffset % BLOCK_SIZE;
    } else {
      if (contig) {
        res.status = "fail";
        res.failType = "start";
        res.message = `Sequence's start is outside of contig's range. Start (offset) = ${seqOffset}, contig's range = ${TOTAL_SEQ_SIZE}`;
        res.seqFrag = "";
        return res;
      }
      // Wrap -seqOffset if it lesser than -TOTAL_SEQ_SIZE
      if (seqOffset <= -TOTAL_SEQ_SIZE) {
        // OlabUtils.infoLog("** seqOffset <= TOTAL_SEQ_SIZE: " + seqOffset);
        seqOffset = seqOffset % TOTAL_SEQ_SIZE;
        // OlabUtils.infoLog("** Wrapped seqOffset = " + seqOffset);
      }
      const temp = TOTAL_SEQ_SIZE + seqOffset;
      blockID = Math.floor(temp / BLOCK_SIZE);
      indexInBlock = temp % BLOCK_SIZE;
    }

    // OlabUtils.infoLog("** blockID = " + blockID);
    // OlabUtils.infoLog("indexInBlock = " + indexInBlock + ", BLOCK_SIZE = " + BLOCK_SIZE);

    let numOfBlocks = Math.floor((indexInBlock + range) / BLOCK_SIZE) + 1;
    // OlabUtils.infoLog("** numOfBlocks = " + numOfBlocks + ", MAX_BLOCK = " + MAX_BLOCK);

    // Pack in an extra block incase of wrapping
    if (blockID + numOfBlocks >= MAX_BLOCK) {
      numOfBlocks++;
      // console.log("Add one more block for wrapping: " + numOfBlocks);
    }
    if (numOfBlocks > MAX_BLOCK) {
      OlabUtils.warnLog(
        "numOfBlocks >= MAX_BLOCK: " +
          numOfBlocks +
          ". CLAMP numOfBlocks to MAX_BLOCK"
      );
      numOfBlocks = MAX_BLOCK;
    }
    // OlabUtils.infoLog("** numOfBlocks needed = " + numOfBlocks);
    const blockOrderArr = [];
    blockOrderArr.push(blockID);
    if (numOfBlocks > 1) {
      // OlabUtils.infoLog("... more numOfBlocks case = " + numOfBlocks);
      if (blockID == MAX_BLOCK - 1) {
        let index = 0;
        for (let i = 1; i < numOfBlocks; i++) {
          blockOrderArr.push(index);
          index++;
        }
      } else {
        let index = blockID + 1;
        for (let i = 1; i < numOfBlocks; i++) {
          if (index >= MAX_BLOCK) {
            index = 0;
          }
          blockOrderArr.push(index);
          index++;
        }
      }
    }

    // console.log("blockOrderArr = ", blockOrderArr);
    // console.log("blockConfig = ", blockConfig);

    let bigSeq = "";
    for (let i = 0; i < blockOrderArr.length; i++) {
      const gbArr = await OlabUtils.getGeneBlocks(
        {
          params: {
            parent_id: blockConfig.parentID,
            block: blockOrderArr[i]
          }
        },
        statusObj,
        errorObj
      );
      if (gbArr.length == 0) {
        console.log(
          "Error! No block found! parent_id = ",
          blockConfig.parentID,
          ", block = ",
          blockOrderArr[i]
        );
        continue;
      }
      // console.log("gbArr[0].seq =", gbArr[0].seq);
      bigSeq += gbArr[0].seq;
    }

    // console.log("bigSeq = ", bigSeq);
    let seqFrag = bigSeq.substring(indexInBlock, indexInBlock + range);
    // console.log("seqFrag = ", seqFrag);

    // Fill the remainding from the beginning
    if (seqFrag.length < range) {
      seqFrag = seqFrag + bigSeq.substring(0, range - seqFrag.length);
    }

    if (comp) {
      seqFrag = OlabPrimer.complementSeq(seqFrag);
      // console.log("Complemented seqFrag = ", seqFrag);
      seqFrag = OlabPrimer.reverseSeq(seqFrag);
      // console.log("reversed = ", seqFrag);
    }
    // console.log("seqFrag =", seqFrag);
    res.status = "success";
    res.message = "";
    res.seqFrag = seqFrag;
    return res;
  }

  // TODO: Move this method to backend if possible
  // Note: This method assume sequence in clockwise (ie comp === false)
  static async computeSequenceForHTP_SNPSwap(
    strainID,
    chrmNo,
    contigNo,
    position,
    armSize,
    statusObj,
    errorObj
  ) {
    if (isNaN(chrmNo)) {
      errorObj.message = `Cannot process NaN in chrmNo: (${chrmNo})`;
      return;
    }
    const contigPresent = isNaN(contigNo) ? false : true;

    // Strain ID = MMM-SSSSSS
    const firstDashIndex = strainID.indexOf("-");
    const microbeID = strainID.substring(0, firstDashIndex);
    // Parent ID can be either MMM-SSSSSS-CCC or MMM-SSSSSS-CCC-TTTT
    let parentID = `${strainID}-${chrmNo}`;
    parentID = contigPresent ? `${parentID}-${contigNo}` : parentID;
    // console.log(`microbeID = ${microbeID}`);
    // console.log(`strainID = ${strainID}, parentID = ${parentID}`);

    let seqOffset = position;
    const seqLen = armSize + armSize;

    // Create a return response object
    const res = {
      status: "success", // or fail
      failType: "start", // or range
      message: "",
      seqFrag: ""
    };

    // TODO: Move this to backend server
    const blockConfig = await OlabBlock._getConfigBlock2(
      microbeID,
      strainID,
      parentID,
      errorObj
    );

    const TOTAL_SEQ_SIZE = blockConfig.total_seq_size;
    const BLOCK_SIZE = blockConfig.block_size;
    const MAX_BLOCK = blockConfig.max_block;
    console.log("** TOTAL_SEQ_SIZE = ", TOTAL_SEQ_SIZE);
    console.log("** BLOCK_SIZE = ", BLOCK_SIZE);
    console.log("** MAX_BLOCK = ", MAX_BLOCK);

    const contig = strainID ? strainID.substring(3, 6) === "-CT" : false;
    if (contig && seqLen >= TOTAL_SEQ_SIZE) {
      if (contig) {
        res.status = "fail";
        res.failType = "range";
        res.message = `Request sequence exceeded contig's range. sequence'slength = ${seqLen}, contig's range = ${TOTAL_SEQ_SIZE}`;
        res.seqFrag = "";
        return res;
      }
    }
    let blockID = 0;
    let indexInBlock = 0;

    // console.log("** seqOffset = ", seqOffset);
    const range = seqLen;
    // Handle positive seqOffset case
    if (seqOffset >= 0) {
      if (contig && seqOffset + range > TOTAL_SEQ_SIZE) {
        res.status = "fail";
        res.failType = "range";
        res.message = `Request sequence exceeded contig's range. Start (offset) = ${seqOffset}, range = ${range}, contig's range = ${TOTAL_SEQ_SIZE}`;
        res.seqFrag = "";
        return res;
      }
      // Wrap seqOffset if it great than TOTAL_SEQ_SIZE
      if (seqOffset >= TOTAL_SEQ_SIZE) {
        // console.log("** seqOffset >= TOTAL_SEQ_SIZE: " + seqOffset);
        if (contig) {
          res.status = "fail";
          res.failType = "start";
          res.message = `Sequence's start is outside of contig's range. Start (offset) = ${seqOffset}, contig's range = ${TOTAL_SEQ_SIZE}`;
          res.seqFrag = "";
          return res;
        }
        seqOffset = seqOffset % TOTAL_SEQ_SIZE;
        // console.log("** Wrapped seqOffset = " + seqOffset);
      }

      blockID = Math.floor(seqOffset / BLOCK_SIZE);
      indexInBlock = seqOffset % BLOCK_SIZE;
    } else {
      if (contig) {
        res.status = "fail";
        res.failType = "start";
        res.message = `Sequence's start is outside of contig's range. Start (offset) = ${seqOffset}, contig's range = ${TOTAL_SEQ_SIZE}`;
        res.seqFrag = "";
        return res;
      }
      // Wrap -seqOffset if it lesser than -TOTAL_SEQ_SIZE
      if (seqOffset <= -TOTAL_SEQ_SIZE) {
        // OlabUtils.infoLog("** seqOffset <= TOTAL_SEQ_SIZE: " + seqOffset);
        seqOffset = seqOffset % TOTAL_SEQ_SIZE;
        // OlabUtils.infoLog("** Wrapped seqOffset = " + seqOffset);
      }
      const temp = TOTAL_SEQ_SIZE + seqOffset;
      blockID = Math.floor(temp / BLOCK_SIZE);
      indexInBlock = temp % BLOCK_SIZE;
    }

    // OlabUtils.infoLog("** blockID = " + blockID);
    // OlabUtils.infoLog("indexInBlock = " + indexInBlock + ", BLOCK_SIZE = " + BLOCK_SIZE);

    let numOfBlocks = Math.floor((indexInBlock + range) / BLOCK_SIZE) + 1;
    // OlabUtils.infoLog("** numOfBlocks = " + numOfBlocks + ", MAX_BLOCK = " + MAX_BLOCK);

    // Pack in an extra block incase of wrapping
    if (blockID + numOfBlocks >= MAX_BLOCK) {
      numOfBlocks++;
      // console.log("Add one more block for wrapping: " + numOfBlocks);
    }
    if (numOfBlocks > MAX_BLOCK) {
      OlabUtils.warnLog(
        "numOfBlocks >= MAX_BLOCK: " +
          numOfBlocks +
          ". CLAMP numOfBlocks to MAX_BLOCK"
      );
      numOfBlocks = MAX_BLOCK;
    }
    // OlabUtils.infoLog("** numOfBlocks needed = " + numOfBlocks);
    const blockOrderArr = [];
    blockOrderArr.push(blockID);
    if (numOfBlocks > 1) {
      // OlabUtils.infoLog("... more numOfBlocks case = " + numOfBlocks);
      if (blockID == MAX_BLOCK - 1) {
        let index = 0;
        for (let i = 1; i < numOfBlocks; i++) {
          blockOrderArr.push(index);
          index++;
        }
      } else {
        let index = blockID + 1;
        for (let i = 1; i < numOfBlocks; i++) {
          if (index >= MAX_BLOCK) {
            index = 0;
          }
          blockOrderArr.push(index);
          index++;
        }
      }
    }

    // console.log("blockOrderArr = ", blockOrderArr);
    // console.log("blockConfig = ", blockConfig);

    let bigSeq = "";
    for (let i = 0; i < blockOrderArr.length; i++) {
      const gbArr = await OlabUtils.getGeneBlocks(
        {
          params: {
            parent_id: blockConfig.parentID,
            block: blockOrderArr[i]
          }
        },
        statusObj,
        errorObj
      );
      if (gbArr.length == 0) {
        console.log(
          "Error! No block found! parent_id = ",
          blockConfig.parentID,
          ", block = ",
          blockOrderArr[i]
        );
        continue;
      }
      // console.log("gbArr[0].seq =", gbArr[0].seq);
      bigSeq += gbArr[0].seq;
    }

    // console.log("bigSeq = ", bigSeq);
    let seqFrag = bigSeq.substring(indexInBlock, indexInBlock + range);
    // console.log("seqFrag = ", seqFrag);

    // Fill the remainding from the beginning
    if (seqFrag.length < range) {
      seqFrag = seqFrag + bigSeq.substring(0, range - seqFrag.length);
    }

    // console.log("seqFrag =", seqFrag);
    res.status = "success";
    res.message = "";
    res.seqFrag = seqFrag;
    return res;
  }

  static infoLog(blk) {
    OlabUtils.infoLog(" - blk.olab_id = " + blk.olab_id);
    OlabUtils.infoLog(" - blk.seq = " + blk.seq);
    OlabUtils.infoLog(" - blk.block = " + blk.block);
    OlabUtils.infoLog(" - blk.start = " + blk.start);
    OlabUtils.infoLog(" - blk.range = " + blk.range);
    OlabUtils.infoLog(" - blk.next_block = " + blk.next_block);
    OlabUtils.infoLog(" - blk.prev_block = " + blk.prev_block);
  }
}

export { OlabBlock };

// This static helper class for handling restriction enzyme map
class ResEnzMap {
  static createEnzArcs(enzArr) {
    const arcs = [];
    enzArr.forEach(enzObj => {
      const enzArc = {
        start: enzObj.arcFrom,
        end: enzObj.arcTo,
        len: enzObj.arcTo - enzObj.arcFrom + 1,
        name: enzObj.name,
        fill: enzObj.name === "_NotAGene" ? "#fbfafc" : enzObj.fill
      };
      arcs.push(enzArc);
    });
    return arcs;
  }

  static _insertEnzToFrame(enzObj, frame) {
    // compute from offset
    let fromOffset = 1;
    if (frame.length > 0) {
      fromOffset = frame[frame.length - 1].arcTo + 1;
    }

    // Pad in a blank block if enzyme isn't at the start of chromosome
    if (enzObj.arcFrom > 1) {
      frame.push({
        olab_id: "",
        name: "_NotAGene", // TODO: Change to _NotDisplay
        arcFrom: fromOffset,
        arcTo: enzObj.arcFrom - 1
      });
    }
    frame.push(enzObj);
  }

  static computeEnzymeCS(mapThis, resEnzymeNames, cutSites, chrmLen) {
    // console.log("computeEnzymeCS resEnzymeNames =", resEnzymeNames);
    const resEnzCSArr = [];
    let resEnzCount = 0;
    // Reset _colorEnzymeIndex
    mapThis._colorEnzymeIndex = 0;
    resEnzymeNames.map(ez => {
      // console.log(ez + ", ", cutSites[ez].length);
      const fillColor = mapThis.colorEnzyme.getColor();
      const csArr = cutSites[ez];
      csArr.map(cs => {
        resEnzCSArr.push({
          id: ++resEnzCount,
          arcFrom: cs,
          arcTo: cs,
          name: ez,
          fill: fillColor
        });
      });
    });
    // sort resEnzCSArr
    resEnzCSArr.sort((a, b) => {
      // console.log("a =", a, ", b =", b);
      return a.arcFrom - b.arcFrom;
    });
    // console.log("%%%%% resEnzCSArr =", resEnzCSArr);

    // Create a circlar frame of enzyme data
    const resEnzCSFrame = [];
    resEnzCSArr.map(enzObj => {
      ResEnzMap._insertEnzToFrame(enzObj, resEnzCSFrame);
    });
    // Pad in the last empty arc
    if (resEnzCSFrame.length > 0) {
      resEnzCSFrame.push({
        olab_id: "",
        name: "_NotAGene",
        arcFrom: resEnzCSFrame[resEnzCSFrame.length - 1].arcTo + 1,
        arcTo: chrmLen
      });
    }

    // console.log("%%%%% resEnzCSFrame =", resEnzCSFrame);
    return resEnzCSFrame;
  }

  static removeFrameEnzRing(mapThis) {
    if (mapThis.frameEnzRing) {
      mapThis.frameEnzRing.selectAll("path").remove();
    }
  }

  static removeFrameEnzLabel(mapThis) {
    if (mapThis.frameEnzLabel) {
      mapThis.frameEnzLabel.selectAll("text").remove();
    }
  }
}

export { ResEnzMap };

<template>
  <div>
    <form class="mt-3" @submit.prevent="register">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <div class="card bg-light">
              <div class="card-body">
                <h3 class="font-weight-light mb-3">Register</h3>
                <div class="form-row">
                  <div
                    v-if="errorObj.message"
                    class="col-12 alert alert-danger text-center px-3"
                  >
                    {{ errorObj.message }}
                    <div class="text-center mt-2">
                      <button
                        type="button"
                        class="btn btn-danger"
                        v-on:click="closeError()"
                      >
                        OK
                      </button>
                    </div>
                  </div>
                  <section class="col-sm-8 form-group">
                    <label class="form-control-label sr-only" for="displayName"
                      >Name</label
                    >
                    <input
                      class="form-control"
                      type="text"
                      id="displayName"
                      placeholder="Name"
                      name="displayName"
                      required
                      v-model="displayName"
                    />
                  </section>

                  <section class="col-sm-4 form-group">
                    <label class="form-control-label sr-only" for="pin"
                      >Pin</label
                    >
                    <input
                      class="form-control"
                      v-bind:class="validPinState()"
                      type="text"
                      id="pin"
                      placeholder="Pin"
                      required
                      name="pin"
                      v-model="pin"
                    />
                    <div class="invalid-feedback">{{ invalidFB.pin }}</div>
                  </section>
                </div>
                <section class="form-group">
                  <label class="form-control-label sr-only" for="email"
                    >Email</label
                  >
                  <input
                    class="form-control"
                    v-bind:class="validEmailState(email)"
                    type="email"
                    id="email"
                    placeholder="Email Address"
                    required
                    name="email"
                    v-model="email"
                  />
                  <div class="invalid-feedback">{{ invalidFB.email }}</div>
                </section>
                <div class="form-row">
                  <section class="col-sm-6 form-group">
                    <input
                      class="form-control"
                      v-bind:class="validPassOneState()"
                      type="password"
                      required
                      placeholder="Password"
                      v-model="passOne"
                    />
                    <div class="invalid-feedback">{{ invalidFB.passOne }}</div>
                  </section>
                  <section class="col-sm-6 form-group">
                    <input
                      class="form-control"
                      v-bind:class="validPassTwoState()"
                      type="password"
                      required
                      placeholder="Repeat Password"
                      v-model="passTwo"
                    />
                    <div class="invalid-feedback">{{ invalidFB.passTwo }}</div>
                  </section>
                </div>
                <div class="form-group text-right mb-0">
                  <button
                    v-bind:disabled="submitDisabled == true"
                    class="btn btn-primary"
                    type="submit"
                  >
                    Register
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <p class="text-center mt-2">
      or
      <router-link to="/login">login</router-link>
    </p>
  </div>
</template>

<script>
import axios from "axios";
import { OlabUtils } from "../olab/olabutils";

export default {
  name: "register",
  data: function() {
    return {
      displayName: null,
      email: null,
      pin: null,
      passOne: null,
      passTwo: null,
      submitDisabled: false,
      invalidFB: {
        pin: "pin is invalid",
        email: "email format is invalid",
        passOne: "password has to be 8 or more characters",
        passTwo: "passwords must match"
      },
      lastEmail: null,
      lastPin: null,
      checkEmail: false,
      emailTaken: false,
      pinPass: true
    };
  },
  props: ["errorObj"],
  methods: {
    validPinState: function() {
      console.log("validPinState: pin = " + this.pin);
      let result = null;
      result = this.pinPass ? null : "is-invalid";
      return result;
    },
    validEmailState: function(em) {
      console.log("validEmailState: email = " + em);
      let result = null;
      // Order matter: emailTaken check has to be before checkEmail
      if (this.emailTaken) {
        console.log("validEmailState: emailTaken = " + this.emailTaken);
        result = "is-invalid";
      } else if (this.checkEmail) {
        result = this.validateEmail(em) ? null : "is-invalid";
      }
      return result;
    },
    validateEmail: function(em) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(em).toLowerCase());
    },
    validPassOneState: function() {
      console.log("validPassOneState: passOne = " + this.passOne);
      let result = null;
      if (this.passOne !== null) {
        if (this.passOne.length < 8) {
          result = "is-invalid";
          this.submitDisabled = this.passTwo !== null ? true : false;
        } else {
          result = "is-valid";
        }
      }
      return result;
    },
    validPassTwoState: function() {
      console.log("validPassTwoState: passTwo = " + this.passTwo);
      let result = null;
      if (this.passOne !== null && this.passTwo !== null) {
        if (this.passOne !== this.passTwo) {
          result = "is-invalid";
          this.submitDisabled = true;
        } else {
          result = "is-valid";
          this.submitDisabled = this.passOne.length < 8 ? true : false;
        }
      }
      return result;
    },
    register: async function() {
      this.checkEmail = true;

      // Check to make sure email is of correct format
      if (!this.validateEmail(this.email)) {
        this.passOne = null;
        this.passTwo = null;
      } else {
        console.log(
          "Register: Check to make sure pin matches email before registation"
        );
        const info = {
          name: this.displayName,
          email: this.email,
          pin: this.pin,
          passOne: this.passOne,
          passTwo: this.passTwo
        };
        try {
          const res = await axios({
            method: "POST",
            url: "/api/v1/users/signup",
            data: {
              name: info.name,
              email: info.email,
              pin: info.pin,
              password: info.passOne,
              passwordConfirm: info.passTwo
            }
          });
          console.log(res.data.data.user);
          if (res.data.status === "success") {
            this.$emit("setUser", res.data.data.user);
            setTimeout(() => {
              this.$router.push("/");
            }, 100);
          }
        } catch (err) {
          // OlabUtils.errorLog(err.response.data.message, err);
          this.errorObj.message = OlabUtils.getErrorMessage(err);
        }
      }
    },
    closeError: function() {
      // console.log("Close Error Alert ...");
      this.errorObj.message = null;
      this.passOne = null;
      this.passTwo = null;
    }
  },
  watch: {
    pin: function() {
      console.log("pin = " + this.email);
      if (this.pin !== this.lastPin) {
        this.pinPass = true;
      }
      this.lastPin = this.pin;
    },
    email: function() {
      console.log("email = " + this.email);
      if (this.email !== this.lastEmail) {
        if (this.emailTaken) {
          this.emailTaken = false;
          // Restore email message
          this.invalidFB.email = "email format is invalid";
        } else if (this.checkEmail) {
          this.checkEmail = false;
        }
      }
      this.lastEmail = this.email;
    },
    passOne: function() {
      console.log("passOne = " + this.passOne);
    }
  }
};
</script>

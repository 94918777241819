<template>
  <div>
    <form class="mt-3" @submit.prevent="reset">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <div class="card bg-light">
              <div class="card-body">
                <h3 class="font-weight-light mb-3">Forgot Password</h3>
                <section class="form-group">
                  <div
                    v-if="errorObj.message"
                    class="col-12 alert alert-danger text-center px-3"
                  >
                    {{ errorObj.message }}
                    <div class="text-center mt-2">
                      <button
                        type="button"
                        class="btn btn-danger"
                        v-on:click="closeError()"
                      >
                        OK
                      </button>
                    </div>
                  </div>
                  <div class="col-12 alert alert-success px-3" v-if="message">
                    {{ message }}
                  </div>
                  <label class="form-control-label sr-only" for="Email"
                    >Email</label
                  >
                  <input
                    class="form-control"
                    v-bind:class="validEmailState()"
                    type="email"
                    id="email"
                    placeholder="Email Address"
                    required
                    v-model="email"
                  />
                  <div class="invalid-feedback">{{ invalidFB.email }}</div>
                </section>
                <section class="form-group">
                  <input
                    class="form-control"
                    v-bind:class="validPinState()"
                    type="password"
                    required
                    placeholder="Pin"
                    v-model="pin"
                  />
                  <div class="invalid-feedback">
                    {{ invalidFB.pin }}
                  </div>
                </section>
                <div class="form-group text-right mb-0">
                  <button class="btn btn-primary" type="submit">
                    Reset Password
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <p class="text-center mt-2">
      <span>
        <router-link to="/login">login</router-link>
        or
        <router-link to="/register">register</router-link>
        or contact
        <a
          href="mailto:admin@olabbio.com?subject=Required Action: Forgot Password"
          >admin</a
        >
        for assistance
      </span>
    </p>
  </div>
</template>
<script>
import axios from "axios";
import { OlabUtils } from "../olab/olabutils";

export default {
  data: function() {
    return {
      email: "",
      pin: "",
      wrongEmail: false,
      wrongPin: false,
      invalidFB: {
        email: "User email not found.",
        pin: "Wrong pin. Try again or contact admin for assistance."
      },
      message: null
    };
  },
  props: ["errorObj"],
  methods: {
    validEmailState: function() {
      let result = null;
      result = this.wrongEmail ? "is-invalid" : null;
      return result;
    },
    validPinState: function() {
      let result = null;
      result = this.wrongPin ? "is-invalid" : null;
      return result;
    },
    reset: async function() {
      const info = {
        email: this.email,
        pin: this.pin
      };

      // Check email match with pin before send reset request
      console.log("ForgotPassword: Check email match with pin", info);
      try {
        const res = await axios({
          method: "POST",
          url: "/api/v1/users/forgotpassword",
          data: {
            email: info.email,
            pin: info.pin
          }
        });
        if (res.data.status === "success") {
          console.log("Sent " + info.email + "reset password email");
          this.message = "Password reset request sent. " + res.data.message;
          setTimeout(() => {
            this.$router.push("/");
          }, 3000);
        }
      } catch (err) {
        // OlabUtils.errorLog(err.response.data.message, err);
        this.errorObj.message = OlabUtils.getErrorMessage(err);
      }
    }
  },
  watch: {
    email: function() {
      // OlabUtils.infoLog("email = " + this.email);
      // Reset
      if (this.wrongEmail) {
        this.wrongEmail = false;
      }
    },
    pin: function() {
      // OlabUtils.infoLog("pin = " + this.pin);
      // Reset
      if (this.wrongPin) {
        this.wrongPin = false;
      }
    },
    closeError: function() {
      // console.log("Close Error Alert ...");
      this.errorObj.message = null;
    }
  }
};
</script>

import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Database from "../views/Database.vue";
import CompDB from "../views/CompDB.vue";
import Design from "../views/Design.vue";
import SNPSwap from "../views/SNPSwap.vue";
import Diagnostic from "../views/Diagnostic.vue";
import BioCalculators from "../views/BioCalculators.vue";
import Login from "../views/Login.vue";
import CJLogin from "../views/CJLogin.vue";
import Register from "../views/Register.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import ResetPassword from "../views/ResetPassword.vue";
import Molarity from "../views/Molarity.vue";
import Dilution from "../views/Dilution.vue";
import MediumBuffer from "../views/MediumBuffer.vue";
import Quick from "../views/Quick.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/database",
    name: "database",
    component: Database
  },
  {
    path: "/compdb",
    name: "compdb",
    component: CompDB
  },
  {
    path: "/design",
    name: "design",
    component: Design
  },
  {
    path: "/snpswap",
    name: "snpswap",
    component: SNPSwap
  },
  {
    path: "/diagnostic",
    name: "diagnostic",
    component: Diagnostic
  },
  {
    path: "/biocalculators",
    name: "biocalculators",
    component: BioCalculators
  },
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/cjlogin/:token",
    name: "cjlogin",
    component: CJLogin
  },
  {
    path: "/register",
    name: "register",
    component: Register
  },
  {
    path: "/forgotpassword",
    name: "forgotpassword",
    component: ForgotPassword
  },
  {
    path: "/resetpassword/:token",
    name: "resetpassword",
    component: ResetPassword
  },
  {
    path: "/molarity",
    name: "molarity",
    component: Molarity
  },
  {
    path: "/dilution",
    name: "dilution",
    component: Dilution
  },
  {
    path: "/mediumbuffer",
    name: "MediumBuffer",
    component: MediumBuffer
  },
  {
    path: "/quick",
    name: "quick",
    component: Quick
  },
  {
    path: "*",
    redirect: "/"
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;

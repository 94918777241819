import * as d3 from "d3";

class OlabLinearMap {
  static MAX_CHARS_PER_MAP = 5000; // 5000 characters
  static MAX_CHARS_PER_LINE = 50; // 50 characterss
  static MAX_LINES = 100; // 40 lines X 50 characters = MAX_CHARS_PER_MAP

  constructor() {
    this._init();
  }

  _init() {
    // sequence data handlers
    this.seqBlock = null;
    this.seqLines = null;
    this.searchStrArr = [];
    this.vueThis = null;
    this.searchStr = null;

    // D3 support structures
    this.svg = null;
    this.ctr = null;
    this.seqGroup = null;
    this.seqLineArr = null;
    this.labelGroup = null;
    this.dimensions = {
      // Default dimension to cover MAX_CHARS_PER_MAP
      width: 600,
      height: 2000,
      margins: 10,
      ctrWidth: 0,
      ctrHeight: 0
    };
  }

  _breakSeqToLines(seqBlk) {
    // console.log("seqBlk =", seqBlk);
    const CNT_PER_LINE = OlabLinearMap.MAX_CHARS_PER_LINE;
    const seqArr = [];
    for (let lnIdx = 0; lnIdx < seqBlk.length / CNT_PER_LINE; lnIdx++) {
      const lineSeq = seqBlk.slice(
        CNT_PER_LINE * lnIdx,
        CNT_PER_LINE * (lnIdx + 1)
      );
      seqArr.push(lineSeq);
    }
    return seqArr;
  }

  // Do case insensitive search of seqBlock
  _getIndicesOf(searchStr) {
    const searchStrLen = searchStr.length;
    if (searchStrLen === 0) {
      return [];
    }

    // make case insensitive
    const seqBlk = this.seqBlock.toLowerCase();
    searchStr = searchStr.toLowerCase();

    let startIndex = 0,
      index,
      indices = [];
    while ((index = seqBlk.indexOf(searchStr, startIndex)) > -1) {
      indices.push(index);
      startIndex = index + searchStrLen;
    }
    // console.log("indices =", indices);
    return indices;
  }

  setDimensions(dims) {
    this.dimensions = dims;
  }

  reset() {
    // this.svg = d3.selectAll("#d3svgLinearMap.svg").remove();
    this._init();
  }

  setup() {
    // console.log("setup linearMap =", this);
    this.svg = d3
      .select("#d3svgLinearMap")
      .append("svg")
      .attr("width", this.dimensions.width)
      .attr("height", this.dimensions.height);
    const dimensions = this.dimensions;
    dimensions.ctrWidth = dimensions.width - dimensions.margins * 2;
    dimensions.ctrHeight = dimensions.height - dimensions.margins * 2;
    this.ctr = this.svg
      .append("g") // <g>
      .attr(
        "transform",
        `translate(${dimensions.margins}, ${dimensions.margins})`
      );
    // Sequence
    this.seqGroup = this.ctr.append("g");

    // Add lineShapeArr to seqGroup
    this.seqLineArr = [];
    for (let i = 0; i < OlabLinearMap.MAX_LINES; i++) {
      this.seqLineArr[i] = this.seqGroup.append("g");
    }

    // console.log("Done: setup linearMap =", this);
  }

  // Highlight searched strings
  searchSeqBlock(searchStr) {
    const searchIndices = this._getIndicesOf(searchStr);
    // console.log("searchSeqBlock: searchIndices =", searchIndices);
    // Reset hitObjArr
    this.searchStrArr.length = 0;
    const MAX_CHARS_PER_LINE = OlabLinearMap.MAX_CHARS_PER_LINE;
    const range = searchStr.length;
    searchIndices.forEach(hPos => {
      // console.log("hit Pos =", hPos);
      const line = Math.floor(hPos / MAX_CHARS_PER_LINE);
      const start = hPos % MAX_CHARS_PER_LINE;
      const end = start + range;
      // Simple case
      if (end < MAX_CHARS_PER_LINE) {
        const hitObj = {
          line: line,
          start: start,
          end: end
        };
        this.searchStrArr.push(hitObj);
      } else {
        // Complex case
        const numSrchLns = Math.floor(end / MAX_CHARS_PER_LINE);
        const linePlusNumSrchLns = line + numSrchLns;
        let lineLp = line;
        let endLp = MAX_CHARS_PER_LINE;
        let hitObj = {
          line: lineLp++,
          start: start,
          end: endLp
        };
        this.searchStrArr.push(hitObj);

        for (let i = lineLp; i < linePlusNumSrchLns; i++) {
          hitObj = {
            line: lineLp++,
            start: 0,
            end: MAX_CHARS_PER_LINE
          };
          this.searchStrArr.push(hitObj);
          endLp += MAX_CHARS_PER_LINE;
        }

        const end2 = end % MAX_CHARS_PER_LINE;
        hitObj = {
          line: lineLp,
          start: 0,
          end: end2
        };
        this.searchStrArr.push(hitObj);
      }
    });
    this._doD3Draw();

    // Cache searchStr
    this.searchStr = searchStr;
    return searchIndices;
  }

  _doD3Draw() {
    // remove all previous shapes
    for (let i = 0; i < OlabLinearMap.MAX_LINES; i++) {
      this.seqLineArr[i].selectAll("rect").remove();
      this.seqLineArr[i].selectAll("text").remove();
    }

    // Show Linear Map
    d3.select("#d3svgLinearMap").style("display", "block");

    let index = 0;
    let xOffset = 25;
    let yOffset = 0;

    const debugGetSeqColor = false;
    const gene = this.vueThis.selectedGene;
    const startInGnSpace =
      gene && this.vueThis.displayData
        ? this.vueThis.displayData.numStart
        : null;
    const rangeInGnSpace =
      gene && this.vueThis.displayData
        ? this.vueThis.displayData.numRange
        : null;
    const arcCollection =
      gene && this.vueThis.circularMap
        ? this.vueThis.circularMap.arcCollection
        : null;
    const totalLen =
      this.vueThis.plasmid && this.vueThis.plasmid.stats
        ? this.vueThis.plasmid.stats.total_seq_len
        : 0;

    // Implement search text hit background color
    const hitObjArr = this.searchStrArr;
    const getRectColor = (d, i) => {
      let rectColor = "White";
      // console.log("** hitObjArr =", hitObjArr);
      hitObjArr.forEach(hit => {
        if (index === hit.line && i >= hit.start && i < hit.end) {
          // console.log("line =", hit.line, ", index =", index);
          // console.log("start =", hit.start, ", end =", hit.end, ", i =", i);
          rectColor = "Gold";
        }
      });
      return rectColor;
    };

    const getSeqColor = (d, i) => {
      if (!(gene && gene.sequence)) {
        // console.log("gene =", gene, "d =", d, ", i =", i);
        // Return not gene color
        return "black";
      }
      if (debugGetSeqColor) {
        console.log("debugGetSeqColor: d =", d, ", i =", i);
        console.log("gene =", gene);
        console.log("startInGnSpace =", startInGnSpace);
        console.log("rangeInGnSpace =", rangeInGnSpace);
        console.log("arcCollection =", arcCollection);
      }

      const comp = gene.sequence.comp;
      // console.log("comp =", comp);
      const seqFrom = comp ? gene.sequence.to : gene.sequence.from;
      // const seqTo = comp ? gene.sequence.from : gene.sequence.to;
      // const seqLen = seqTo - seqFrom + 1;
      // console.log(`seqFrom = ${seqFrom}, seqTo = ${seqTo}, seqLen = ${seqLen}`);
      // console.log(`(${startInGnSpace}, ${rangeInGnSpace + startInGnSpace})`);
      // const endInGnSpace = rangeInGnSpace + startInGnSpace;
      const newFrom = seqFrom + startInGnSpace;
      // const newTo = seqFrom + endInGnSpace - 1;
      // console.log(`New = (${newFrom}, ${newTo})`);
      // console.log("d =", d, ", i =", i, ", index = ", index);
      // console.log("totalLen =", totalLen);
      let newIndex = newFrom + i + index * OlabLinearMap.MAX_CHARS_PER_LINE;
      // console.log("(1) newIndex =", newIndex);
      newIndex = newIndex % totalLen;
      // console.log("(2) newIndex =", newIndex);

      let fillColor = "black";
      if (arcCollection) {
        let geneFrame = 0;
        for (let i = 0; i < arcCollection.length; i++) {
          const arc = arcCollection[i];
          if (arc.name === gene.name) {
            geneFrame = arc.frame;
            break;
          }
        }
        for (let i = 0; i < arcCollection.length; i++) {
          const arc = arcCollection[i];
          if (
            geneFrame === arc.frame &&
            newIndex >= arc.start &&
            newIndex <= arc.end
          ) {
            fillColor =
              arc.geneFillColor === "#fbfafc" ? "black" : arc.geneFillColor;
            break;
          }
        }
      }

      return fillColor;
    };

    // Setting for base unit dimensions
    const unitWidth = 10;
    const unitHeight = 10;
    const unitHeightWithPad = unitHeight + 2;
    const unitHeightWithOffset = unitHeightWithPad + 6;

    // Add the rect elements, these are placeholders
    this.seqLines.forEach(seqLine => {
      this.seqLineArr[index]
        .selectAll("rect")
        .data(seqLine)
        .join(
          enter => enter.append("rect"),
          update => update,
          exit => exit.remove()
        )
        .attr("width", unitWidth)
        .attr("height", unitHeightWithPad)
        .attr("transform", (d, i) => {
          return `translate( ${xOffset + i * unitWidth}, ${yOffset -
            unitHeight})`;
        })
        .style("fill", (d, i) => getRectColor(d, i));

      // Add the text
      this.seqLineArr[index]
        .selectAll("text")
        .data(seqLine)
        .join(
          enter => enter.append("text"),
          update => update,
          exit => exit.remove()
        )
        .style("font-family", "monospace")
        .style("fill", (d, i) => getSeqColor(d, i))
        .attr("x", (d, i) => {
          const xPos = xOffset + i * unitWidth;
          // console.log("xOffset =", xOffset, "xPos =", xPos);
          return xPos;
        })
        .attr("y", yOffset)
        .text(d => d);

      index++;
      yOffset += unitHeightWithOffset;
    });
  }

  draw(seqBlk, vueThis) {
    // console.log("vueThis =", vueThis);
    this.vueThis = vueThis;
    // Re-compute seqBlock
    this.seqBlock = seqBlk;
    this.seqLines = this._breakSeqToLines(seqBlk);
    let searchIndices = [];
    if (this.searchStr) {
      // searchSeqBlock will make an explicit _doD3Draw call internally
      searchIndices = this.searchSeqBlock(this.searchStr);
    } else {
      this._doD3Draw();
    }
    return searchIndices;
  }

  removeSeqGroup() {
    if (this.seqGroup) {
      this.seqGroup.selectAll("rect").remove();
      this.seqGroup.selectAll("text").remove();
    }
    // Hide Linear Map
    d3.select("#d3svgLinearMap").style("display", "none");
  }
}

export { OlabLinearMap };

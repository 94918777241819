import axios from "axios";
import { OlabUtils } from "./olabutils.js";
import { OlabItem } from "./olabitem.js";
import { OlabCart } from "./olabcart.js";
import { OlabPackage } from "./olabpackage.js";
import { OlabSearch } from "../olab/olabsearch.js";

class OlabAxios {
  constructor() {
    OlabUtils.infoLog("OlabAxios.constructor() ...");
  }

  static addItemToItems(type, item, items) {
    // Add item to the begining of items
    switch (type) {
      case "cart":
        items.unshift(Object.assign(new OlabCart(), item));
        break;
      case "package":
        items.unshift(Object.assign(new OlabPackage(), item));
        break;
      default:
        items.unshift(Object.assign(new OlabItem(), item));
    }
  }

  static async saveAnnouncement(dbInfo, statusObj, errorObj) {
    try {
      const res = await axios({
        method: "PATCH",
        url: `/api/v1/olabserver/${dbInfo.id}`,
        data: {
          // name: dbInfo.name,
          message: dbInfo.message
        }
      });
      // console.log(res.data.data.dbInfo);
      if (res.data.status === "success") {
        OlabUtils.infoLog("saveOlabServer: Document successfully written!");
      }
    } catch (err) {
      errorObj.message = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset progress status
    statusObj.message = null;
    statusObj.progress = false;
  }

  // TODO: ONLY support Plasmid host for now
  static async getEnzymeCutSites(
    host,
    type,
    enzymeArr,
    homLSeq,
    homRSeq,
    statusObj,
    errorObj
  ) {
    // console.log(
    //   "getEnzymeCutSite: host.olab_type = ",
    //   host.olab_type,
    //   " ,host.olab_id = ",
    //   host.olab_id
    // );
    // console.log("enzymeArr =", enzymeArr);
    // console.log("type =", type);
    // console.log("homLSeq =", homLSeq);
    // console.log("homRSeq =", homRSeq);

    let cutSites = null;
    try {
      const res = await axios({
        method: "POST",
        url: "/api/v1/resenzymes/getplasmidcutsites",
        data: {
          olab_id: host.olab_id,
          type: type,
          enzymes: enzymeArr,
          homl_seq: homLSeq,
          homr_seq: homRSeq
        }
      });
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.enzymes
      ) {
        // console.log("enzymes = ", res.data.data.enzymes);
        cutSites = res.data.data.enzymes;
      } else {
        // console.log("cutSites is null");
      }
    } catch (err) {
      errorObj.message = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset progress status
    statusObj.message = null;
    statusObj.progress = false;
    return cutSites;
  }

  static async getPlasmidMetaMap(plasmid, statusObj, errorObj) {
    // console.log("getPlasmidMetaMap: plasmid = ", plasmid);

    // Load metaMap
    let metaMap = null;
    try {
      const res = await axios.get(
        `/api/v1/plasmids/getMetaMap/${plasmid.olab_id}`
      );
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.metaMap
      ) {
        // console.log("data.results = ", res.data.results);
        // console.log("metaMap = ", res.data.data.metaMap);
        metaMap = res.data.data.metaMap;
      } else {
        console.log("metaMap is null");
      }
    } catch (err) {
      errorObj.message = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset progress status
    statusObj.message = null;
    statusObj.progress = false;
    return metaMap;
  }

  static async getPlasmidGenes(plasmid, statusObj, errorObj) {
    // console.log("getPlasmidGenes: plasmid = ", plasmid);

    // Load genes
    let genes = null;
    try {
      const res = await axios.get(
        `/api/v1/plasmids/getGenesMap/${plasmid.olab_id}`
      );
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.genes
      ) {
        // console.log("data.results = ", res.data.results);
        // console.log("genes = ", res.data.data.genes);
        genes = res.data.data.genes;
      } else {
        console.log("genes is null");
      }
    } catch (err) {
      errorObj.message = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset progress status
    statusObj.message = null;
    statusObj.progress = false;
    return genes;
  }

  static async getStrainGenes(strain, chromosomeID, statusObj, errorObj) {
    console.log(
      "getStrainGenes: strain = ",
      strain,
      ", chromosomeID =",
      chromosomeID
    );

    // Load genes
    let genes = null;
    const searchStr = `${strain.olab_id}-${chromosomeID}`;
    // console.log("searchStr = ", searchStr);
    try {
      const res = await axios.get(`/api/v1/strains/getGenesMap/${searchStr}`);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.genes
      ) {
        // console.log("data.results = ", res.data.results);
        // console.log("genes = ", res.data.data.genes);
        genes = res.data.data.genes;
      } else {
        console.log("genes is null");
      }
    } catch (err) {
      errorObj.message = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset progress status
    statusObj.message = null;
    statusObj.progress = false;
    return genes;
  }

  static async addCompToPackage(item, type, pkg, statusObj, errorObj) {
    // console.log(
    //   "OlabAxios.addCompToPackage: item =",
    //   item,
    //   " type =",
    //   type,
    //   " package =",
    //   pkg
    // );

    // Status data
    let sMessage = null;
    let sProgress = false;

    // Set additional fields
    item.olab_type = type;
    try {
      const res = await axios({
        method: "POST",
        url: "/api/v1/packages/addNewComp",
        data: {
          olab_id: pkg.olab_id,
          comp: item
        }
      });
      // console.log("res.data.data =", res.data.data);
      if (res.data.status === "success") {
        // console.log("Document successfully written!");
        // console.log("package = ", res.data.data.package);
        // console.log("libseq = ", res.data.data.libseq);
        // console.log("message = ", res.data.data.message);

        // Update cart's comps and message
        pkg.comps = res.data.data.package.comps;
        sMessage = res.data.data.message;
      }
    } catch (err) {
      errorObj.message = OlabUtils.getErrorMessage(err);
    }

    // Done! Reset progress status
    statusObj.message = sMessage;
    statusObj.progress = sProgress;

    return {
      status: "success",
      message: sMessage
    };
  }

  static async addCompToCart(item, type, cart, statusObj, errorObj) {
    // console.log(
    //   "OlabAxios.addCompToCart: item =",
    //   item,
    //   " type =",
    //   type,
    //   " cart =",
    //   cart
    // );

    // Status data
    let sMessage = null;
    let sProgress = false;

    // Set additional fields
    item.olab_type = type;
    try {
      const res = await axios({
        method: "POST",
        url: "/api/v1/carts/addNewComp",
        data: {
          olab_id: cart.olab_id,
          comp: item
        }
      });
      // console.log("res.data.data =", res.data.data);
      if (res.data.status === "success") {
        // console.log("Document successfully written!");
        // console.log("cart = ", res.data.data.cart);
        // console.log("libseq = ", res.data.data.libseq);
        // console.log("message = ", res.data.data.message);

        // Update cart's comps and message
        cart.comps = res.data.data.cart.comps;
        sMessage = res.data.data.message;
      }
    } catch (err) {
      errorObj.message = OlabUtils.getErrorMessage(err);
    }

    // Done! Reset progress status
    statusObj.message = sMessage;
    statusObj.progress = sProgress;

    return {
      status: "success",
      message: sMessage
    };
  }

  static async addItem(item, type, items, statusObj, errorObj) {
    // console.log(
    //   `OlabAxios.addItem: item = ${item}, type = ${type}, items = ${items}`
    // );

    // Status data
    let sMessage = null;
    let sProgress = false;

    // Set additional fields
    item.olab_type = type;

    // Set library_id if this item is a package item
    if (item.package_type) {
      item.library_id = OlabPackage.pkgTypeToLibraryID(item.package_type);
    }

    switch (type) {
      case "project":
        try {
          const res = await axios({
            method: "POST",
            url: "/api/v1/projects",
            data: {
              olab_type: item.olab_type,
              name: item.name,
              creator: item.creator,
              ra_locks: item.ra_locks,
              desc: item.desc,
              start_date: item.start_date,
              end_date: item.end_date
            }
          });
          // console.log(res.data.data.project);
          if (res.data.status === "success") {
            // console.log("Document successfully written!");
            // copy item to a new object and add that to the computed list
            item.olab_id = res.data.data.project.olab_id;
            this.addItemToItems(type, item, items);
          }
        } catch (err) {
          // console.error("Error writing document: ", err);
          errorObj.message = OlabUtils.getErrorMessage(err);
        }
        break;
      case "cart":
        try {
          const res = await axios({
            method: "POST",
            url: "/api/v1/carts",
            data: {
              olab_type: item.olab_type,
              name: item.name,
              creator: item.creator,
              project_id: item.project_id,
              desc: item.desc,
              comps: item.comps,
              cstbs: item.cstbs
            }
          });
          // console.log(res.data.data.cart);
          if (res.data.status === "success") {
            // console.log("Document successfully written!");
            // copy item to a new object and add that to the computed list
            item.olab_id = res.data.data.cart.olab_id;
            this.addItemToItems(type, item, items);
          }
        } catch (err) {
          // console.error("Error writing document: ", err);
          errorObj.message = OlabUtils.getErrorMessage(err);
        }
        break;
      case "package":
        try {
          const formData = new FormData();
          formData.append("name", item.name);
          formData.append("olab_type", item.olab_type);
          formData.append("creator", item.creator);
          formData.append("project_id", item.project_id);
          formData.append("desc", item.desc);
          formData.append("package_seq_type", item.package_seq_type);
          formData.append("package_type", item.package_type);
          formData.append(
            "library_id",
            OlabPackage.pkgTypeToLibraryID(item.package_type)
          );
          formData.append("csv_file", item.csv_file);
          formData.append("comps", JSON.stringify(item.comps));
          const res = await axios({
            method: "POST",
            url: "/api/v1/packages",
            data: formData
          });
          // console.log(res.data.data.package);
          if (res.data.status === "success") {
            // console.log("Document successfully written =", res.data);
            // console.log(
            //   "csv_file_result.data =",
            //   res.data.data.csv_file_result.data
            // );
            // copy item to a new object and add that to the computed list
            item.olab_id = res.data.data.package.olab_id;
            item.comps = res.data.data.package.comps;
            this.addItemToItems(type, item, items);
            if (item.comps && item.comps.length > 0) {
              const result = res.data.data.csv_file_result.data;
              // console.log("Total entries processed", result.total_seq_processed);
              // console.log("Total Undefined entries", result.undefined_count);
              const numSeqProcessed = `Total ${result.total_seq_processed} sequences processed: (`;
              const numNewSeq = `${result.total_new_seq} new, `;
              const numDupSeq = `${result.total_duplicate_seq} duplicate, `;
              const numDelSeq = `${result.total_undeleted_seq} undeleted, `;
              const undefinedSeqOrig = `${result.undefined_count} Undefined origin)`;
              sMessage =
                numSeqProcessed +
                numNewSeq +
                numDupSeq +
                numDelSeq +
                undefinedSeqOrig;
              sProgress = false;
            }
          }
        } catch (err) {
          // console.error("Error writing document: ", err);
          errorObj.message = OlabUtils.getErrorMessage(err);
        }
        break;
      case "plasmid":
        // console.log("(1) item.plasmid_ori = " + item.plasmid_ori);
        // console.log("(2) item.csv_file =", item.csv_file);
        // console.log("(3) item.fasta_file =", item.fasta_file);
        // console.log("(4) item.genbank_file =", item.genbank_file);
        // console.log("(5) item =", item);
        try {
          const formData = new FormData();
          formData.append("name", item.name);
          formData.append("olab_type", item.olab_type);
          formData.append("creator", item.creator);
          formData.append("plasmid_ori", item.plasmid_ori);
          formData.append("desc", item.desc);
          formData.append("source", item.source);
          formData.append("wgs_confirmed", item.wgs_confirmed);
          formData.append("ra_locks", JSON.stringify(item.ra_locks));
          formData.append("csv_file", item.csv_file);
          formData.append("fasta_file", item.fasta_file);
          formData.append("genbank_file", item.genbank_file);
          const res = await axios({
            method: "POST",
            url: "/api/v1/plasmids",
            data: formData
          });
          // console.log(res.data.data.plasmid);
          if (res.data.status === "success") {
            // console.log("Document successfully written!");
            // copy item to a new object and add that to the computed list
            item.olab_id = res.data.data.plasmid.olab_id;
            item.stats = res.data.data.plasmid.stats;
            item.meta_map = res.data.data.plasmid.meta_map;
            this.addItemToItems(type, item, items);
          }
        } catch (err) {
          // console.error("Error writing document: ", err);
          errorObj.message = OlabUtils.getErrorMessage(err);
        }
        break;
      case "strain":
        // console.log("(1) item.microbe_id = " + item.microbe_id);
        // console.log("(2) item.parent_id = " + item.parent_id);
        // console.log("(3) item.csv_file =", item.csv_file);
        // console.log("(4) item.fasta_file =", item.fasta_file);
        // console.log("(5) item.genbank_file =", item.genbank_file);
        // console.log("(6) item =", item);
        try {
          const formData = new FormData();
          formData.append("name", item.name);
          formData.append("olab_type", item.olab_type);
          formData.append("creator", item.creator);
          formData.append("microbe_id", item.microbe_id);
          formData.append("desc", item.desc);
          formData.append("source", item.source);
          formData.append("contig", item.contig);
          formData.append("wgs_confirmed", item.wgs_confirmed);
          formData.append("ra_locks", JSON.stringify(item.ra_locks));
          formData.append("csv_file", item.csv_file);
          formData.append("fasta_file", item.fasta_file);
          formData.append("genbank_file", item.genbank_file);
          const res = await axios({
            method: "POST",
            url: "/api/v1/strains",
            data: formData
          });
          // console.log(res.data.data.strain);
          if (res.data.status === "success") {
            // console.log("Document successfully written!");
            // copy item to a new object and add that to the computed list
            item.olab_id = res.data.data.strain.olab_id;
            item.stats = res.data.data.strain.stats;
            item.chrm_array = res.data.data.strain.chrm_array;
            this.addItemToItems(type, item, items);
          }
        } catch (err) {
          // console.error("Error writing document: ", err);
          errorObj.message = OlabUtils.getErrorMessage(err);
        }
        break;
      case "library_sequence":
        // console.log("library_sequence Add: csv_file =", item.csv_file);
        if (item.csv_file) {
          // console.log("(1) item.creator = " + item.creator);
          // console.log("(2) item.csv_file =", item.csv_file);
          // console.log("(3) item.project_id =", item.project_id);
          // console.log("(4) item =", item);
          try {
            const formData = new FormData();
            formData.append("creator", item.creator);
            formData.append("project_id", item.project_id);
            formData.append("csv_file", item.csv_file);
            const res = await axios({
              method: "POST",
              url: "/api/v1/libseqs/bulkUpload",
              data: formData
            });
            // console.log(res.data.data.libseqs);
            if (res.data.status === "success") {
              // console.log("Document successfully written!");
              const result = res.data.data.libseqs;
              // console.log("result", result);
              // console.log("Total entries processed", result.library_count);
              // console.log("Total Undefined entries", result.undefined_count);
              // console.log("Total failed import entries", result.total_failed_import);
              const numSeqProcessed = `Total ${result.library_count} sequences processed: (`;
              const numNewSeq = `${result.total_new_seq} new, `;
              const numDupSeq = `${result.total_duplicate_seq} duplicate, `;
              const numDelSeq = `${result.total_undeleted_seq} undeleted, `;
              const numFailedSeq = `${result.total_failed_import -
                result.total_duplicate_seq} failed, `;
              const undefinedSeqOrig = `${result.undefined_count} Undefined origin)`;
              sMessage =
                numSeqProcessed +
                numNewSeq +
                numDupSeq +
                numDelSeq +
                numFailedSeq +
                undefinedSeqOrig;
              sProgress = false;
              // Search by using creator and project_id
              const searchBody = OlabSearch.createItemSearchBody(
                "libseq",
                item.creator,
                "creator",
                false,
                item.project_id,
                1,
                OlabSearch.cntPerPage
              );
              const successData = await OlabUtils.getLibseqs(searchBody);
              // console.log(successData);
              items.length = 0;
              items.push(...successData);
            }
          } catch (err) {
            // console.error("Error writing document: ", err);
            // Reset status before emitting error message
            sMessage = null;
            sProgress = false;
            errorObj.message = OlabUtils.getErrorMessage(err);
          }
        } else {
          try {
            const res = await axios({
              method: "POST",
              url: "/api/v1/libseqs",
              data: {
                olab_type: item.olab_type,
                name: item.name,
                creator: item.creator,
                project_id: item.project_id,
                desc: item.desc,
                source: item.source,
                library_id: item.library_id,
                seq: item.seq,
                seq_origin: item.seq_origin,
                gene_stats: item.gene_stats
              }
            });
            // console.log(res.data.data.libseq);
            if (res.data.status === "success") {
              // console.log("Document successfully written!");
              // copy item to a new object and add that to the computed list
              item.olab_id = res.data.data.libseq.olab_id;
              this.addItemToItems(type, item, items);
            } else if (res.data.status === "accepted") {
              // This is server informing a duplicate is found
              // Done! Reset progress status
              statusObj.message = sMessage;
              statusObj.progress = sProgress;
              return {
                status: "duplicate",
                libseq: res.data.data.libseq,
                message: res.data.data.message
              };
            }
          } catch (err) {
            errorObj.message = OlabUtils.getErrorMessage(err);
          }
        }
        break;
      case "user":
        OlabUtils.infoLog("item.olab_id = " + item.olab_id);
        try {
          const res = await axios({
            method: "POST",
            url: "/api/v1/users",
            data: {
              olab_type: item.olab_type,
              name: item.name,
              email: item.email,
              password: item.passwd,
              passwordConfirm: item.confirmed_passwd,
              role: item.role,
              user_id: item.user_id
            }
          });
          // console.log(res.data.data.user);
          if (res.data.status === "success") {
            // console.log("Document successfully written!");
            // Clear passwd and confirmed_passwd
            item.passwd = null;
            item.confirmed_passwd = null;
            // copy item to a new object and add that to the computed list
            item.olab_id = res.data.data.user.olab_id;
            this.addItemToItems(type, item, items);
          }
        } catch (err) {
          // console.error("Error writing document: ", err);
          errorObj.message = OlabUtils.getErrorMessage(err);
        }
        break;
      default:
        OlabUtils.infoLog("OlabAxios.addItem: unknown type = " + type);
    }
    // Done! Reset progress status
    statusObj.message = sMessage;
    statusObj.progress = sProgress;

    return {
      status: "success",
      message: null
    };
  }

  static async saveCart(cart, statusObj, errorObj) {
    try {
      const res = await axios({
        method: "PATCH",
        url: `/api/v1/carts/${cart.olab_id}`,
        data: {
          name: cart.name,
          desc: cart.desc,
          comps: cart.comps,
          cstbs: cart.cstbs
        }
      });
      // console.log(res.data.data.cart);
      if (res.data.status === "success") {
        OlabUtils.infoLog("saveCart: Document successfully written!");
      }
    } catch (err) {
      errorObj.message = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset progress status
    statusObj.message = null;
    statusObj.progress = false;
  }

  static async savePackage(pkg, statusObj, errorObj) {
    try {
      const res = await axios({
        method: "PATCH",
        url: `/api/v1/packages/${pkg.olab_id}`,
        data: {
          name: pkg.name,
          desc: pkg.desc,
          comps: pkg.comps
        }
      });
      // console.log(res.data.data.package);
      if (res.data.status === "success") {
        OlabUtils.infoLog("savePackage: Document successfully written!");
      }
    } catch (err) {
      errorObj.message = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset progress status
    statusObj.message = null;
    statusObj.progress = false;
  }

  static async saveDsgnCstsPmrs(dsgn, dsgnBoxes, statusObj, errorObj) {
    // console.log("OlabAxios.saveDsgnCstsPmrs: dsgn =", dsgn);
    // console.log("OlabAxios.saveDsgnCstsPmrs: dsgnBoxes =", dsgnBoxes);

    try {
      const res = await axios({
        method: "POST",
        url: `/api/v1/designs/savecstspmrs`,
        data: {
          olab_id: dsgn.olab_id,
          olab_type: dsgn.olab_type,
          name: dsgn.name,
          creator: dsgn.creator,
          project_id: dsgn.project_id,
          cart_id: dsgn.cart_id,
          dsgn_boxes: dsgnBoxes,
          desc: dsgn.desc,
          configured: dsgn.configured,
          pmr_setting: dsgn.pmr_setting,
          pmr_3p_len: dsgn.pmr_3p_len,
          pmr_5p_len: dsgn.pmr_5p_len,
          package_present: dsgn.package_present,
          // Clear previous diagnostic data (if exists)
          diag_downstream_seq: null,
          diag_upstream_seq: null,
          cpcr_downstream_seq: null,
          cpcr_downstream_seq2: null,
          cpcr_upstream_seq: null,
          cpcr_upstream_seq2: null
        }
      });
      // console.log(res.data.data);
      if (res.data.status === "success") {
        // console.log("saveDsgnCstsPmrs: Document successfully written!!");
        // console.log("Res' data: ", res.data.data);
      }
    } catch (err) {
      // console.log("saveDsgnCstsPmrs: Error writing document: " + err);
      errorObj.message = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset progress status
    statusObj.message = null;
    statusObj.progress = false;
  }

  static async generateDiagPmrs(dsgn, tmLow, tmHigh, statusObj, errorObj) {
    // console.log("OlabAxios.generateDiagPmrs: olab_id =", dsgn.olab_id);
    // console.log("tmLow =", tmLow, ", tmHigh =", tmHigh);
    const tm = Math.floor((tmLow + tmHigh) / 2);
    let tmTol = Math.floor((tmHigh - tmLow) / 2);
    tmTol = tmTol === 0 ? 1 : tmTol;
    // console.log("tm =", tm, ", tmTol =", tmTol);

    let stats = null;
    try {
      const res = await axios({
        method: "POST",
        url: `/api/v1/designs/generatediagpmrs`,
        data: {
          olab_id: dsgn.olab_id,
          // Note: preview: true to inform backend to save the
          // diagnostic primers
          preview: true,
          type: "plasmid",
          seq_pmrs: {
            up_stream_seq: "",
            down_stream_seq: "",
            mode: "SEQ",
            pmr_length: 20,
            pmr_length_tol: 6,
            // pmr_length: [18, 30],
            pmr_cycle_len: 700,
            pmr_melting_temp: tm,
            pmr_melting_temp_tol: tmTol,
            // pmr_melting_temp: [50, 60]
            fwd_bwd_pmr_distance: [300, 500]
          },
          cpcr_pmrs: {
            up_stream_seq: "",
            down_stream_seq: "",
            mode: "CPCR_END_FIX",
            pmr_length: 20,
            pmr_length_tol: 6,
            // pmr_length: [18, 30],
            pmr_melting_temp: tm,
            pmr_melting_temp_tol: tmTol
            // pmr_melting_temp: [50, 60]
          }
        }
      });
      // console.log(res.data.data);
      if (res.data.status === "success") {
        // console.log("generateDiagPmrs: Document successfully written!!");
        // console.log("Res' data: ", res.data.data);
        stats = res.data.data;
      }
    } catch (err) {
      // console.log("generateDiagPmrs: Error writing document: " + err);
      errorObj.message = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset progress status
    statusObj.message = null;
    statusObj.progress = false;
    return stats;
  }

  static async createDesign(dsgn, statusObj, errorObj) {
    try {
      const res = await axios({
        method: "POST",
        url: "/api/v1/designs",
        data: {
          olab_type: dsgn.olab_type,
          name: dsgn.name,
          creator: dsgn.creator,
          project_id: dsgn.project_id,
          cart_id: dsgn.cart_id,
          desc: dsgn.desc
        }
      });
      // console.log(res.data.data.design);
      if (res.data.status === "success") {
        // console.log("Document successfully written!");
        dsgn.olab_id = res.data.data.design.olab_id;
      }
    } catch (err) {
      // console.error("Error writing document: ", err);
      errorObj.message = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset progress status
    statusObj.message = null;
    statusObj.progress = false;
  }

  static async previewDesign(dsgn, backbone, dsgnBoxes, statusObj, errorObj) {
    // console.log("OlabAxios.previewDesign: dsgn =", dsgn);
    // console.log("OlabAxios.previewDesign: dsgnBoxes =", dsgnBoxes);
    // console.log("OlabAxios.previewDesign: backbone =", backbone);
    let res = null;
    try {
      // console.log("dsgn.olab_id =", dsgn.olab_id);
      const preview = {
        configured: dsgn.configured,
        pmr_setting: dsgn.pmr_setting,
        pmr_3p_len: dsgn.pmr_3p_len,
        pmr_5p_len: dsgn.pmr_5p_len,
        package_present: dsgn.package_present,
        dsgn_boxes: dsgnBoxes
      };
      // Only assign backbone if we have valid backbone plasmid
      if (backbone.olab_id) {
        preview.backbone = backbone;
      } else {
        preview.backbone = null;
      }
      res = await axios({
        method: "POST",
        url: "/api/v1/designs/previewcstspmrs",
        data: {
          olab_id: dsgn.olab_id,
          preview: preview
        }
      });
      // console.log(res.data.data);
      if (res.data.status === "success") {
        // console.log("Document successfully written!");
        // console.log(res.data.data);
      }
    } catch (err) {
      // console.error("Error writing document: ", err);
      errorObj.message = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset progress status
    statusObj.message = null;
    statusObj.progress = false;
    return res;
  }

  static async saveDsgnPlasmids(dsgn, statusObj, errorObj) {
    // console.log("OlabAxios.saveDsgnPlasmid: dsgn =", dsgn);
    let res = null;
    try {
      res = await axios({
        method: "POST",
        url: `/api/v1/designs/saveplasmids`,
        data: {
          olab_id: dsgn.olab_id
        }
      });
      // console.log(res.data.data);
      if (res.data.status === "success") {
        // console.log("saveDsgnPlasmid: Document successfully written!!");
        // console.log("Res' data: ", res.data.data);
      }
    } catch (err) {
      // console.log("saveDsgnPlasmid: Error writing document: ", err);
      errorObj.message = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset progress status
    statusObj.message = null;
    statusObj.progress = false;
    return res;
  }

  static async getOrCreateNewDesign(dsgn, statusObj, errorObj) {
    // Load design
    let design = null;
    try {
      const res = await axios.get(`/api/v1/designs/${dsgn.olab_id}`);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.design
      ) {
        // console.log("design = ", res.data.data.design);
        design = res.data.data.design;
      } else {
        // console.log("design is null");
      }
    } catch (err) {
      if (err.response.statusText === "Not Found") {
        // Create new design ...
        await this.createDesign(dsgn, statusObj, errorObj);
        // console.log("create new design ...");
      } else {
        errorObj.message = OlabUtils.getErrorMessage(err);
      }
    }
    // Done! Reset progress status
    statusObj.message = null;
    statusObj.progress = false;
    return design;
  }

  static async downloadPlmdGenbank(plmd, statusObj, errorObj) {
    // console.log("downloadPlmdGenbank: plasmid.olab_id =", plmd.olab_id);
    statusObj.progress = true;
    let genbankFile = null;
    try {
      const res = await axios({
        method: "POST",
        url: "/api/v1/plasmids/genbank",
        data: {
          olab_id: plmd.olab_id
        }
      });
      // console.log(res);
      // console.log();
      // console.log(res.data);
      if (res.statusText === "OK" && res.data) {
        // console.log(res.data);
        genbankFile = res.data;
      } else {
        // console.log("Fail in bio server - no genbank file");
        errorObj.message = "Fail in bio server - no genbank file";
      }
    } catch (err) {
      errorObj.message = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset progress status
    statusObj.message = null;
    statusObj.progress = false;
    return genbankFile;
  }

  static async downloadDsgnGenbank(dsgn, statusObj, errorObj) {
    let genbankFile = null;
    try {
      const res = await axios({
        method: "POST",
        url: "/api/v1/designs/genbank",
        responseType: "blob", //important
        data: {
          olab_id: dsgn.olab_id
        }
      });
      // console.log(res);
      // console.log();
      // console.log(res.data);
      if (res.statusText === "OK" && res.data) {
        // console.log(res.data);
        genbankFile = res.data;
      } else {
        // console.log("Fail in bio server - no genbank file");
        errorObj.message = "Fail in bio server - no genbank file";
      }
    } catch (err) {
      errorObj.message = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset progress status
    statusObj.message = null;
    statusObj.progress = false;
    return genbankFile;
  }

  static async downloadDsgnFasta(dsgn, statusObj, errorObj) {
    let fastaFile = null;
    try {
      const res = await axios({
        method: "POST",
        url: "/api/v1/designs/fasta",
        responseType: "blob", //important
        data: {
          olab_id: dsgn.olab_id
        }
      });
      // console.log(res);
      // console.log();
      // console.log(res.data);
      if (res.statusText === "OK" && res.data) {
        // console.log(res.data);
        fastaFile = res.data;
      } else {
        // console.log("Fail in bio server - no fasta file");
        errorObj.message = "Fail in bio server - no fasta file";
      }
    } catch (err) {
      errorObj.message = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset progress status
    statusObj.message = null;
    statusObj.progress = false;
    return fastaFile;
  }

  static async downloadCstFile(dsgn, cst, fileType, statusObj, errorObj) {
    // console.log("OlabAxios: downloadCstFile - fileType =", fileType);
    const urlEndPoint = "/api/v1/constructs/" + fileType;
    // console.log("urlEndPoint =", urlEndPoint);
    let cstFile = null;
    try {
      const res = await axios({
        method: "POST",
        url: urlEndPoint,
        data: {
          olab_id: cst.olab_id,
          design_id: dsgn.olab_id
        }
      });
      // console.log(res);
      // console.log();
      // console.log(res.data);
      if (res.statusText === "OK" && res.data) {
        // console.log(res.data);
        cstFile = res.data;
      } else {
        const errMsg = `Fail in bio server - no ${fileType} file`;
        // console.log(errMsg);
        errorObj.message = errMsg;
      }
    } catch (err) {
      errorObj.message = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset progress status
    statusObj.message = null;
    statusObj.progress = false;
    return cstFile;
  }

  static async downloadFullPrimersFile(dsgnBody, statusObj, errorObj) {
    // console.log("dsgnBody =", dsgnBody);
    let pmrsFile = null;
    try {
      const res = await axios({
        method: "POST",
        url: "/api/v1/designs/fullpmrs",
        data: dsgnBody
      });
      // console.log(res);
      // console.log();
      // console.log(res.data);
      if (res.statusText === "OK" && res.data) {
        // console.log(res.data);
        pmrsFile = res.data;
      } else {
        // console.log("Fail in bio server - no full primers file");
        errorObj.message = "Fail in bio server - no full primers file";
      }
    } catch (err) {
      errorObj.message = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset progress status
    statusObj.message = null;
    statusObj.progress = false;
    return pmrsFile;
  }

  static async downloadOrderPrimersFile(dsgnBody, statusObj, errorObj) {
    // console.log("dsgnBody =", dsgnBody);
    let pmrsFile = null;
    try {
      const res = await axios({
        method: "POST",
        url: "/api/v1/designs/orderpmrs",
        data: dsgnBody
      });
      // console.log(res);
      // console.log();
      // console.log(res.data);
      if (res.statusText === "OK" && res.data) {
        // console.log(res.data);
        pmrsFile = res.data;
      } else {
        // console.log("Fail in bio server - no order primers file");
        errorObj.message = "Fail in bio server - no order primers file";
      }
    } catch (err) {
      errorObj.message = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset progress status
    statusObj.message = null;
    statusObj.progress = false;
    return pmrsFile;
  }

  static async downloadConstructPrimersFile(dsgnBody, statusObj, errorObj) {
    // console.log("dsgnBody =", dsgnBody);
    let pmrsFile = null;
    try {
      const res = await axios({
        method: "POST",
        url: "/api/v1/designs/cstpmrs",
        data: dsgnBody
      });
      // console.log(res);
      // console.log();
      // console.log(res.data);
      if (res.statusText === "OK" && res.data) {
        // console.log(res.data);
        pmrsFile = res.data;
      } else {
        // console.log("Fail in bio server - no construct primers file");
        errorObj.message = "Fail in bio server - no construct primers file";
      }
    } catch (err) {
      errorObj.message = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset progress status
    statusObj.message = null;
    statusObj.progress = false;
    return pmrsFile;
  }

  static async downloadDiagCPCRPrimersFile(dsgnBody, statusObj, errorObj) {
    // console.log("dsgnBody =", dsgnBody);
    let pmrsFile = null;
    try {
      const res = await axios({
        method: "POST",
        url: "/api/v1/designs/dlcpcrpmrs",
        data: dsgnBody
      });
      // console.log(res);
      // console.log();
      // console.log(res.data);
      if (res.statusText === "OK" && res.data) {
        // console.log(res.data);
        pmrsFile = res.data;
      } else {
        // console.log("Fail in bio server - no cPCR primers file");
        errorObj.message = "Fail in bio server - no cPCR primers file";
      }
    } catch (err) {
      errorObj.message = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset progress status
    statusObj.message = null;
    statusObj.progress = false;
    return pmrsFile;
  }

  static async downloadDiagSeqPrimersFile(dsgnBody, statusObj, errorObj) {
    // console.log("** dsgnBody =", dsgnBody);
    let pmrsFile = null;
    try {
      const res = await axios({
        method: "POST",
        url: "/api/v1/designs/dlseqpmrs",
        data: dsgnBody
      });
      // console.log(res);
      // console.log();
      // console.log(res.data);
      if (res.statusText === "OK" && res.data) {
        // console.log(res.data);
        pmrsFile = res.data;
      } else {
        // console.log("Fail in bio server - no sequencing primers file");
        errorObj.message = "Fail in bio server - no sequencing primers file";
      }
    } catch (err) {
      errorObj.message = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset progress status
    statusObj.message = null;
    statusObj.progress = false;
    return pmrsFile;
  }

  static async download96WPMFile(dsgnBody, statusObj, errorObj) {
    // console.log("dsgnBody.olab_id =", dsgnBody.olab_id);
    let wpmFile = null;
    try {
      const res = await axios({
        method: "POST",
        url: "/api/v1/designs/96wellplates",
        data: dsgnBody,
        responseType: "blob"
      });
      // console.log(res);
      // console.log();
      // console.log(res.data);
      if (res.statusText === "OK" && res.data) {
        // console.log(res.data);
        wpmFile = res.data;
      } else {
        // console.log("Fail in bio server - no 96 well-plates file");
        errorObj.message = "Fail in bio server - no 96 well-plate file";
      }
    } catch (err) {
      errorObj.message = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset progress status
    statusObj.message = null;
    statusObj.progress = false;
    return wpmFile;
  }

  static async downloadCstCPCRPmrs(
    cst,
    mode,
    upstreamSeq,
    downstreamSeq,
    tmLow,
    tmHigh,
    statusObj,
    errorObj
  ) {
    // console.log("cst.olab_id =", cst.olab_id);
    const tm = Math.floor((tmLow + tmHigh) / 2);
    let tmTol = Math.floor((tmHigh - tmLow) / 2);
    tmTol = tmTol === 0 ? 1 : tmTol;
    // console.log("mode =", mode);
    // console.log("tm =", tm, ", tmTol =", tmTol);

    let cPCRPmrFile = null;
    try {
      const res = await axios({
        method: "POST",
        url: "/api/v1/constructs/cpcrpmrs",
        data: {
          olab_id: cst.olab_id,
          up_stream_seq: upstreamSeq,
          down_stream_seq: downstreamSeq,
          mode: mode,
          pmr_length: 20,
          pmr_length_tol: 6,
          // pmr_length: [18, 30],
          pmr_melting_temp: tm,
          pmr_melting_temp_tol: tmTol // set a tighter limit, maximum is 5
          // pmr_melting_temp: [50, 60]
        }
      });
      // console.log(res);
      // console.log();
      // console.log(res.data);
      if (res.statusText === "OK" && res.data) {
        // console.log(res.data);
        cPCRPmrFile = res.data;
        // Update mode field
        cst.pmr_diag_mode = mode;
      } else {
        // console.log(
        //   "Fail in bio server - cannot generate diagnostic cPCR primers file"
        // );
        errorObj.message =
          "Fail in bio server - cannot generate diagnostic cPCR primers file";
      }
    } catch (err) {
      errorObj.message = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset progress status
    statusObj.message = null;
    statusObj.progress = false;
    return cPCRPmrFile;
  }

  static async downloadCstDiagPmrs(
    cst,
    mode,
    tmLow,
    tmHigh,
    statusObj,
    errorObj
  ) {
    // console.log("cst.olab_id =", cst.olab_id);
    const tm = Math.floor((tmLow + tmHigh) / 2);
    let tmTol = Math.floor((tmHigh - tmLow) / 2);
    tmTol = tmTol === 0 ? 1 : tmTol;
    // console.log("tm =", tm, ", tmTol =", tmTol);

    let diagPmrFile = null;
    try {
      const res = await axios({
        method: "POST",
        url: "/api/v1/constructs/diagpmrs",
        data: {
          olab_id: cst.olab_id,
          mode: mode,
          up_stream_seq: cst.diag_upstream_seq,
          down_stream_seq: cst.diag_downstream_seq,
          pmr_length: 20,
          pmr_length_tol: 6,
          // pmr_length: [18, 30],
          pmr_cycle_len: 700,
          pmr_melting_temp: tm,
          pmr_melting_temp_tol: tmTol, // set a tighter limit, maximum is 5
          // pmr_melting_temp: [50, 60],
          fwd_bwd_pmr_distance: [300, 500]
        }
      });
      // console.log(res);
      // console.log();
      // console.log(res.data);
      if (res.statusText === "OK" && res.data) {
        // console.log(res.data);
        diagPmrFile = res.data;
        // Update mode field
        cst.pmr_diag_mode = mode;
      } else {
        // console.log(
        //   "Fail in bio server - cannot generate diagnostic primers file"
        // );
        errorObj.message =
          "Fail in bio server - cannot generate diagnostic primers file";
      }
    } catch (err) {
      errorObj.message = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset progress status
    statusObj.message = null;
    statusObj.progress = false;
    return diagPmrFile;
  }

  static async downloadDsgnCPCRPmrs(
    dsgn,
    mode,
    upstreamSeq,
    downstreamSeq,
    tmLow,
    tmHigh,
    statusObj,
    errorObj
  ) {
    // console.log("dsgn.olab_id =", dsgn.olab_id);
    const tm = Math.floor((tmLow + tmHigh) / 2);
    let tmTol = Math.floor((tmHigh - tmLow) / 2);
    tmTol = tmTol === 0 ? 1 : tmTol;
    // console.log("mode =", mode);
    // console.log("tm =", tm, ", tmTol =", tmTol);

    let cPCRPmrFile = null;
    try {
      const res = await axios({
        method: "POST",
        url: "/api/v1/designs/cpcrpmrs",
        data: {
          olab_id: dsgn.olab_id,
          up_stream_seq: upstreamSeq,
          down_stream_seq: downstreamSeq,
          mode: mode,
          pmr_length: 20,
          pmr_length_tol: 6,
          // pmr_length: [18, 30],
          pmr_melting_temp: tm,
          pmr_melting_temp_tol: tmTol
          // pmr_melting_temp: [50, 60]
        }
      });
      // console.log(res);
      // console.log();
      // console.log(res.data);
      if (res.statusText === "OK" && res.data) {
        // console.log(res.data);
        cPCRPmrFile = res.data;
        // Update mode field
        dsgn.pmr_diag_mode = mode;
      } else {
        // console.log(
        //   "Fail in bio server - cannot generate diagnostic cPCR primers file"
        // );
        errorObj.message =
          "Fail in bio server - cannot generate diagnostic cPCR primers file";
      }
    } catch (err) {
      errorObj.message = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset progress status
    statusObj.message = null;
    statusObj.progress = false;
    return cPCRPmrFile;
  }

  static async downloadDsgnDiagPmrs(
    dsgn,
    mode,
    tmLow,
    tmHigh,
    statusObj,
    errorObj
  ) {
    // console.log("dsgn.olab_id =", dsgn.olab_id);
    const tm = Math.floor((tmLow + tmHigh) / 2);
    let tmTol = Math.floor((tmHigh - tmLow) / 2);
    tmTol = tmTol === 0 ? 1 : tmTol;

    // console.log("tm =", tm, ", tmTol =", tmTol);

    let diagPmrFile = null;
    try {
      const res = await axios({
        method: "POST",
        url: "/api/v1/designs/diagpmrs",
        data: {
          olab_id: dsgn.olab_id,
          mode: mode,
          up_stream_seq: dsgn.diag_upstream_seq,
          down_stream_seq: dsgn.diag_downstream_seq,
          pmr_length: 20,
          pmr_length_tol: 6,
          // pmr_length: [18, 30],
          pmr_cycle_len: 700,
          pmr_melting_temp: tm,
          pmr_melting_temp_tol: tmTol,
          // pmr_melting_temp: [50, 60],
          fwd_bwd_pmr_distance: [300, 500]
        }
      });
      // console.log(res);
      // console.log();
      // console.log(res.data);
      if (res.statusText === "OK" && res.data) {
        // console.log(res.data);
        diagPmrFile = res.data;
        // Update mode field
        dsgn.pmr_diag_mode = mode;
      } else {
        // console.log(
        //   "Fail in bio server - cannot generate diagnostic primers file"
        // );
        errorObj.message =
          "Fail in bio server - cannot generate diagnostic primers file";
      }
    } catch (err) {
      errorObj.message = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset progress status
    statusObj.message = null;
    statusObj.progress = false;
    return diagPmrFile;
  }

  static async htpBulkSNPSwap(ssObj, statusObj, errorObj) {
    // console.log("htpBulkSNPSwap ...");
    // console.log(
    //   "project.olab_id =",
    //   ssObj.project.olab_id,
    //   "armSize =",
    //   ssObj.armSize,
    //   ", homologySize =",
    //   ssObj.homologySize,
    //   ", pmr3Pad =",
    //   ssObj.pmr3Pad,
    //   ", pmr5Pad =",
    //   ssObj.pmr5Pad
    // );
    // console.log("csvFile =", ssObj.csvFile);
    // console.log("statusObj =", statusObj, ", errorObj =", errorObj);

    if (!ssObj.csvFile) {
      errorObj.message = "Error: No csv file found";
      return;
    }

    let zipFile = null;
    try {
      const formData = new FormData();
      formData.append("project_id", ssObj.project.olab_id);
      formData.append("arm_size", ssObj.armSize);
      formData.append("homology_size", ssObj.homologySize);
      formData.append("pmr_3p", ssObj.pmr3Pad);
      formData.append("pmr_5p", ssObj.pmr5Pad);
      formData.append("csv_file", ssObj.csvFile);
      const res = await axios({
        method: "POST",
        url: "/api/v1/snpswaps/bulkUpload",
        responseType: "blob", //important
        data: formData
      });
      // console.log(res);
      // console.log();
      // console.log(res.data);
      if (res.statusText === "OK" && res.data) {
        // console.log(res.data);
        zipFile = res.data;
      } else {
        errorObj.message = "Fail in bio server - no zip file";
      }
    } catch (err) {
      errorObj.message = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset progress status
    statusObj.message = null;
    statusObj.progress = false;
    return zipFile;
  }

  static async getSystemStats(statusObj, errorObj) {
    // Load sysStats
    let sysStats = null;
    try {
      const res = await axios.get("/api/v1/stats/systemstats");
      if (res.data.status === "success" && res.data.data) {
        // console.log("data.results = ", res.data.results);
        // console.log("system's stats = ", res.data.data);
        sysStats = res.data.data;
      } else {
        console.log("System's stats is null");
      }
    } catch (err) {
      errorObj.message = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset progress status
    statusObj.message = null;
    statusObj.progress = false;
    return sysStats;
  }

  static async getProjectStats(projID, fromDate, toDate, statusObj, errorObj) {
    // Load projStats
    let projStats = null;
    try {
      const dates = {
        params: {
          from_date: fromDate,
          to_date: toDate
        }
      };
      const res = await axios.get(
        `/api/v1/stats/projectstats/${projID}`,
        dates
      );
      if (res.data.status === "success" && res.data.data) {
        // console.log("data.results = ", res.data.results);
        // console.log("project's stats = ", res.data.data);
        projStats = res.data.data;
      } else {
        console.log("Project's stats is null");
      }
    } catch (err) {
      errorObj.message = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset progress status
    statusObj.message = null;
    statusObj.progress = false;
    return projStats;
  }

  static async getUserStats(userID, fromDate, toDate, statusObj, errorObj) {
    // Load userStats
    let userStats = null;
    try {
      const dates = {
        params: {
          from_date: fromDate,
          to_date: toDate
        }
      };
      const res = await axios.get(`/api/v1/stats/userstats/${userID}`, dates);
      if (res.data.status === "success" && res.data.data) {
        // console.log("data.results = ", res.data.results);
        // console.log("user's stats = ", res.data.data);
        userStats = res.data.data;
      } else {
        console.log("User's stats is null");
      }
    } catch (err) {
      errorObj.message = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset progress status
    statusObj.message = null;
    statusObj.progress = false;
    return userStats;
  }
}

export { OlabAxios };

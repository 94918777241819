<template>
  <div>
    <div v-if="user" id="diagnostic">
      <nav class="navbar sticky-top navbar-light bg-light">
        <div class="input-group my-2">
          <!-- Search UI Group -->
          <div class="row mr-0">
            <div class="selection-group-btn">
              <button
                type="button"
                class="ml-3 btn btn-info dropdown-toggle"
                data-flip="false"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <font-awesome-icon icon="hand-pointer" />
                <span class="caret"></span>
              </button>
              <div class="dropdown-menu">
                <a
                  class="dropdown-item"
                  href="#"
                  id="design"
                  v-on:click.prevent="requestTypeChange('design')"
                >
                  Design
                  <span v-if="selectedType === 'design'">&#x2713;</span>
                </a>
                <a
                  class="dropdown-item"
                  href="#"
                  id="construct"
                  v-on:click.prevent="requestTypeChange('construct')"
                >
                  Construct
                  <span v-if="selectedType === 'construct'">&#x2713;</span>
                </a>
              </div>
              <!-- .dropdown-menu -->
            </div>
            <!-- .selection-group-btn -->
          </div>
          <input
            id="searchTerm"
            placeholder="Search"
            type="text"
            class="form-control ml-1"
            aria-label="Search"
            v-model="searchTerm"
          />

          <div class="search-group-btn">
            <button
              type="button"
              class="ml-1 btn btn-info dropdown-toggle"
              data-flip="false"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <font-awesome-icon icon="search" />
              <span class="small mt-2 ml-1">{{
                filterKey | searchFilter
              }}</span>
              <span class="caret"></span>
            </button>

            <div class="dropdown-menu dropdown-menu-right">
              <a
                class="dropdown-item"
                href="#"
                id="merge"
                v-on:click.prevent="requestKeyChange('merge')"
              >
                Merge
                <span v-if="filterKey === 'merge'">&#x2713;</span>
              </a>

              <a
                class="dropdown-item"
                href="#"
                id="olab_id"
                v-on:click.prevent="requestKeyChange('olab_id')"
              >
                OLAB ID
                <span v-if="filterKey === 'olab_id'">&#x2713;</span>
              </a>

              <a
                class="dropdown-item"
                href="#"
                id="name"
                v-on:click.prevent="requestKeyChange('name')"
              >
                Name
                <span v-if="filterKey === 'name'">&#x2713;</span>
              </a>

              <a
                class="dropdown-item"
                href="#"
                id="creator"
                v-on:click.prevent="requestKeyChange('creator')"
              >
                Creator
                <span v-if="filterKey === 'creator'">&#x2713;</span>
              </a>

              <a
                v-if="selectedType === 'construct'"
                class="dropdown-item"
                href="#"
                id="seq"
                v-on:click.prevent="requestKeyChange('seq')"
              >
                Sequence
                <span v-if="filterKey === 'seq'">&#x2713;</span>
              </a>
            </div>
            <!-- .dropdown-menu -->
          </div>
          <!-- search-group-btn -->
        </div>
        <!-- input-group my-2 -->

        <div class="navbar-brand text-truncate">
          <!-- Placeholder for user profile-->
          <button
            type="button"
            class="btn btn-outline-danger ml-1"
            data-toggle="modal"
            data-target="#userProfile"
            data-backdrop="static"
            data-keyboard="false"
          >
            <font-awesome-icon icon="id-badge" />
          </button>
          <button
            type="button"
            class="btn btn-outline-success ml-1"
            data-toggle="modal"
            data-target="#infoProfile"
            data-backdrop="static"
            data-keyboard="false"
          >
            <font-awesome-icon icon="info" />
          </button>
          <span class="h4 text-success text-capitalize ml-2"
            >{{ selectedTypeName }} Diagnostic System</span
          >
        </div>
        <!-- navbar-brand text-truncate -->

        <div
          v-if="errorObj.message"
          class="col-12 alert alert-danger text-center px-3"
        >
          {{ errorObj.message }}
          <div class="text-center mt-2">
            <button
              type="button"
              class="btn btn-danger"
              v-on:click="closeError()"
            >
              OK
            </button>
          </div>
        </div>
        <div
          v-if="statusObj.message"
          class="col-12 alert alert-info fade show px-3"
        >
          <span>{{ statusObj.message }}</span>
          <span
            v-if="statusObj.progress"
            class="mx-2 spinner-grow"
            role="status"
          >
            <span class="sr-only">Status information</span>
          </span>
          <div class="text-center mt-2">
            <button
              type="button"
              class="btn btn-primary"
              v-on:click="closeStatus()"
            >
              OK
            </button>
          </div>
        </div>
      </nav>
      <!-- sticky-top -->

      <!-- userProfile -->
      <OlabUserProfile :user="user" />

      <!-- infoProfile -->
      <OlabInfoProfile :user="user" :selectedTypeName="selectedTypeName" />

      <div v-if="selectedType === 'construct'">
        <DiagnosticConstructList
          v-if="user"
          :user="user"
          :constructs="selected.items"
          :statusObj="statusObj"
          :errorObj="errorObj"
          :selectedType="selectedType"
          :item="item"
        />
      </div>
      <div v-else-if="selectedType === 'design'">
        <DiagnosticDesignList
          v-if="user"
          :user="user"
          :dbInfo="dbInfo"
          :statusObj="statusObj"
          :errorObj="errorObj"
          :selectedItems="selected.items"
          :selectedType="selectedType"
          :item="item"
        />
      </div>

      <div class="my-5">
        <nav aria-label="Page navigation">
          <ul class="pagination justify-content-center">
            <li class="page-item">
              <button
                type="button"
                v-bind:disabled="selected.prevIsDisabled == true"
                class="btn btn-primary"
                v-on:click="handlePrev()"
              >
                &lt;
              </button>
            </li>
            <li class="mx-3">{{ selected.pageNumber }}</li>
            <li class="page-item">
              <button
                type="button"
                v-bind:disabled="selected.nextIsDisabled == true"
                class="btn btn-primary"
                v-on:click="handleNext()"
              >
                &gt;
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div v-else class="mt-5">
      <div class="text-secondary text-center">
        <div class="h2">
          Inactivity Time Out
        </div>
        <router-link
          class="btn btn-outline-primary mt-2"
          to="/login"
          v-if="!user"
          >Log in</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import DiagnosticDesignList from "@/components/DiagnosticDesignList";
import DiagnosticConstructList from "@/components/DiagnosticConstructList";
import OlabUserProfile from "@/components/OlabUserProfile";
import OlabInfoProfile from "@/components/OlabInfoProfile";
import { OlabItem } from "../olab/olabitem.js";
import { OlabUtils } from "../olab/olabutils.js";
import { OlabSearch } from "../olab/olabsearch.js";

export default {
  name: "diagnostic",
  data: function() {
    return {
      title: "Diagnostic",
      searchTerm: "",
      filterKey: "merge",
      filterDir: "asc",
      selectedType: "design",
      lastSelectedType: null,
      selectedTypeName: "Design",
      selectedItem: null,
      includeDeleted: false,
      item: new OlabItem(),
      selected: {
        genes: null,
        items: null,
        sortBy: "olab_id",
        pageNumber: 1,
        nextIsDisabled: true,
        prevIsDisabled: true
      }
    };
  },
  components: {
    FontAwesomeIcon,
    DiagnosticDesignList,
    DiagnosticConstructList,
    OlabUserProfile,
    OlabInfoProfile
  },
  props: ["user", "dbInfo", "statusObj", "errorObj"],
  mounted: async function() {
    OlabUtils.infoLog("Diagnostic mounted ...");
    // Reset status and error
    this.statusObj.message = null;
    this.statusObj.progress = false;
    this.errorObj.message = null;

    try {
      const searchBody = OlabSearch.createItemSearchBody(
        "design",
        "",
        "olab_id",
        false,
        null,
        1,
        OlabSearch.cntPerPage
      );
      const items = await OlabUtils.getDesigns(searchBody);
      if (items.length >= OlabSearch.cntPerPage) {
        this.selected.sortBy = "olab_id";
        this.selected.nextIsDisabled = false;
      }
      this.selected.items = items;
    } catch (err) {
      OlabUtils.infoLog(err);
      this.errorObj.message = OlabUtils.getErrorMessage(err);
    }
  },
  filters: {
    searchFilter: function(value) {
      switch (value) {
        case "merge":
          return "Merge";
        case "olab_id":
          return "OLAB ID";
        case "name":
          return "Name";
        case "creator":
          return "Creator";
        case "seq":
          return "Sequence";
      }
    }
  },
  methods: {
    handlePrev: function() {
      let sType = this.selectedType;
      // console.log("handlePrev ... sType = " + sType);
      OlabSearch.doPrev(
        false, // geneSearch is false here
        sType,
        this.searchTerm,
        this.includeDeleted,
        null,
        this.selected
      );
    },
    handleNext: function() {
      let sType = this.selectedType;
      // console.log("handleNext ... sType = " + sType);
      OlabSearch.doNext(
        false, // geneSearch is false here
        sType,
        this.searchTerm,
        this.includeDeleted,
        null,
        this.selected
      );
    },
    requestTypeChange: function(value) {
      this.selectedType = value;
      // console.log("requestTypeChange ", this.selectedType);
      if (this.selectedType !== this.lastSelectedType) {
        // Reset searchTerm when type changed
        this.searchTerm = "";

        // console.log("Watch - selectedType = ", this.selectedType);
        switch (this.selectedType) {
          case "construct":
            this.selectedTypeName = "Construct";
            this.searchTerm = "";
            this.doSearchTerm(this.searchTerm, this.selectedType);
            break;
          case "design":
            this.selectedTypeName = "Design";
            this.searchTerm = "";
            this.doSearchTerm(this.searchTerm, this.selectedType);
            break;
        }
        this.lastSelectedType = this.selectedType;
      }
    },
    requestKeyChange: function(value) {
      this.filterKey = value;
      this.doSearchTerm(this.searchTerm, this.selectedType);
    },
    doSearchTerm: function(sTerm, sType) {
      OlabSearch.doSearchTerm(
        false, // geneSearch is false here
        sTerm,
        sType,
        this.includeDeleted,
        this.filterKey,
        null,
        this.selected
      );
    },
    closeStatus: function() {
      // console.log("Close Status Alert ...");
      this.statusObj.message = null;
      this.statusObj.progress = false;
    },
    closeError: function() {
      // console.log("Close Error Alert ...");
      this.errorObj.message = null;
    }
  },
  watch: {
    searchTerm: function() {
      // console.log("Watch - searchTerm = ", this.searchTerm);
      this.searchTerm = OlabUtils.cleanSearchTem(this.searchTerm);
      this.doSearchTerm(this.searchTerm, this.selectedType);
    }
  }
};
</script>

<template>
  <div v-if="user">
    <!-- Project profile button -->
    <!-- <button
      v-if="project"
      type="button"
      class="btn btn-outline-success ml-1"
      data-toggle="modal"
      data-target="#projectProfile"
    >
      <font-awesome-icon icon="project-diagram" />
    </button> -->
    <div v-if="project" class="modal fade" id="projectProfile">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Project ID:
              <span class="text-dark text-truncate">{{ project.olab_id }}</span>
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <!-- .modal-header -->
          <div class="modal-body">
            <div class="pt-2">
              <span>Name:</span>
              <span class="h5 text-dark ml-1">{{ project.name }}</span>
            </div>
            <div class="pt-2">
              <span>Creator:</span>
              <span class="h5 text-dark ml-1">{{ project.creator }}</span>
            </div>
            <div class="pt-2">
              <span>Start Date:</span>
              <span class="h5 text-dark ml-1">{{ project.start_date }}</span>
            </div>
            <div class="pt-2">
              <span>End Date:</span>
              <span class="h5 text-dark ml-1">{{ project.end_date }}</span>
            </div>

            <div class="py-1"></div>

            <div class="card">
              <div class="card-header text-dark">Description</div>
              <div class="card-body">
                <div class="py-1 small text-dark">
                  {{ project.desc }}
                </div>
              </div>
              <!-- .card-body -->
            </div>
            <!-- .card -->
            <div class="py-1"></div>
          </div>
          <!-- .modal-body -->

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
          <!-- .modal-footer -->
        </div>
        <!-- .modal-content -->
      </div>
      <!-- .modal-dialog -->
    </div>
    <!-- .modal fade -- End of projectProfile -->
  </div>
</template>

<script>
// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "olabprojectprofile",
  props: ["user", "project"],
  components: {
    // FontAwesomeIcon
  }
};
</script>

<template>
  <div>
    <svg
      id="olab-sequence-shape"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 50"
    >
      <g transform="translate(5,5)" id="group-1">
        <text
          x="5"
          y="10"
          style="font-size:5px;font-style:monospace;font-weight:normal;text-align:start;text-anchor:start;fill:#666666;fill-opacity:1;stroke:none;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;font-family:Arial"
          id="text-A"
          xml:space="preserve"
        >
          A
        </text>
        <rect
          v-bind:width="normComp(numA)"
          height="5"
          x="10"
          y="5"
          style="opacity:1;fill:#88ee88;fill-opacity:1;fill-rule:evenodd"
          id="rect-A"
        />
        <text
          v-if="numA > 0"
          v-bind:x="compPosX(numA)"
          y="9"
          style="font-size:5px;font-weight:normal;text-align:start;text-anchor:start;fill:#000000;fill-opacity:1;stroke:none;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;font-family:Arial"
          id="text-A-num"
          xml:space="preserve"
        >
          {{ numA }}
        </text>

        <text
          x="5"
          y="15"
          style="font-size:5px;font-style:monospace;font-weight:normal;text-align:start;text-anchor:start;fill:#666666;fill-opacity:1;stroke:none;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;font-family:Arial"
          id="text-T"
          xml:space="preserve"
        >
          T
        </text>
        <rect
          v-bind:width="normComp(numT)"
          height="5"
          x="10"
          y="10"
          style="fill:#ff9999;fill-opacity:1;fill-rule:evenodd"
          id="rect-T"
        />
        <text
          v-if="numT > 0"
          v-bind:x="compPosX(numT)"
          y="14"
          style="font-size:5px;font-weight:normal;text-align:start;text-anchor:start;fill:#000000;fill-opacity:1;stroke:none;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;font-family:Arial"
          id="text-T-num"
          xml:space="preserve"
        >
          {{ numT }}
        </text>

        <text
          x="5"
          y="20"
          style="font-size:5px;font-style:monospace;font-weight:normal;text-align:start;text-anchor:start;fill:#666666;fill-opacity:1;stroke:none;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;font-family:Arial"
          id="text-G"
          xml:space="preserve"
        >
          G
        </text>
        <rect
          v-bind:width="normComp(numG)"
          height="5"
          x="10"
          y="15"
          style="fill:#888888;fill-opacity:1;fill-rule:evenodd"
          id="rect-G"
        />
        <text
          v-if="numG > 0"
          v-bind:x="compPosX(numG)"
          y="19"
          style="font-size:5px;font-weight:normal;text-align:start;text-anchor:start;fill:#eeeeee;fill-opacity:1;stroke:none;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;font-family:Arial"
          id="text-G-num"
          xml:space="preserve"
        >
          {{ numG }}
        </text>

        <text
          x="5"
          y="25"
          style="font-size:5px;font-style:monospace;font-weight:normal;text-align:start;text-anchor:start;fill:#666666;fill-opacity:1;stroke:none;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;font-family:Arial"
          id="text-C"
          xml:space="preserve"
        >
          C
        </text>
        <rect
          v-bind:width="normComp(numC)"
          height="5"
          x="10"
          y="20"
          style="opacity:1;fill:#7777ff;fill-opacity:1;fill-rule:evenodd"
          id="rect-C"
        />
        <text
          v-if="numC > 0"
          v-bind:x="compPosX(numC)"
          y="24"
          style="font-size:5px;font-weight:normal;text-align:start;text-anchor:start;fill:#eeeeee;fill-opacity:1;stroke:none;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;font-family:Arial"
          id="text-C-num"
          xml:space="preserve"
        >
          {{ numC }}
        </text>

        <text
          x="5"
          y="40"
          style="font-size:6px;font-style:monospace;font-weight:normal;text-align:start;text-anchor:start;fill:#000000;fill-opacity:1;stroke:none;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;font-family:Arial"
          id="text-seq"
          xml:space="preserve"
        >
          {{ shortSeq }}
        </text>

        <text
          x="120"
          y="40"
          style="font-size:6px;font-weight:normal;text-align:start;text-anchor:start;fill:#000000;fill-opacity:1;stroke:none;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;font-family:Arial"
          id="text-num"
          xml:space="preserve"
        >
          {{ len }}
        </text>
      </g>
      <g transform="translate(120,0)" id="group-2">
        <rect
          width="20"
          height="20"
          x="10"
          y="15"
          style="fill:lightblue;fill-opacity:1;fill-rule:evenodd;stroke:#000000;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1"
          id="rect-sample1"
        />
        <text
          x="12"
          y="28"
          style="font-size:8px;font-weight:normal;text-align:start;text-anchor:start;fill:#333333;fill-opacity:1;stroke:none;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;font-family:Arial"
          id="text-sample"
          xml:space="preserve"
        >
          {{ cgPercent }}
        </text>
        <rect
          width="20"
          height="20"
          x="30"
          y="15"
          style="fill:lightgrey;fill-opacity:1;fill-rule:evenodd;stroke:#000000;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1"
          id="rect-sample2"
        />
        <text
          x="36"
          y="28"
          style="font-size:8px;font-weight:normal;text-align:start;text-anchor:start;fill:#333333;fill-opacity:1;stroke:none;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;font-family:Arial"
          id="text-sample"
          xml:space="preserve"
        >
          {{ cgLevel }}
        </text>
        <rect
          width="20"
          height="20"
          x="50"
          y="15"
          style="fill:yellowgreen;fill-opacity:1;fill-rule:evenodd;stroke:#000000;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1"
          id="rect-sample3"
        />
        <text
          x="54"
          y="28"
          style="font-size:8px;font-weight:normal;text-align:start;text-anchor:start;fill:#333333;fill-opacity:1;stroke:none;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;font-family:Arial"
          id="text-sample"
          xml:space="preserve"
        >
          {{ cgNumber }}
        </text>
      </g>
    </svg>
  </div>
</template>

<script>
// Hacked weight for binding simulator
const cgWeigth = 1.2;

export default {
  name: "olabsequencesvggen",
  props: ["numA", "numC", "numG", "numT", "shortSeq", "len"],
  computed: {
    // Simulated data to illustrate configurable blocks
    cgPercent: function() {
      let value = 0;
      if (this.len > 0) {
        value = (((this.numC + this.numG) * cgWeigth) / this.len) * 100;
      }
      return value.toFixed(0) + "%";
    },
    cgLevel: function() {
      let value = 0;
      if (this.len > 0) {
        value = (((this.numC + this.numG) * cgWeigth) / this.len) * 100;
      }
      if (value < 30) {
        return "L";
      } else if (value < 65) {
        return "M";
      }
      return "H";
    },
    cgNumber: function() {
      let value = 0;
      if (this.len > 0) {
        value = ((this.numC + this.numG) / this.len) * 100;
      }
      return value.toFixed(0);
    }
  },
  methods: {
    compPosX: function(comp) {
      const posX = this.normComp(comp);
      return posX < 10 ? 10 : posX;
    },
    normComp: function(comp) {
      const max = this.maxComp();
      if (isNaN(max) || max === 0 || isNaN(comp)) {
        return 0;
      }
      return (comp / this.maxComp()) * 100;
    },
    maxComp: function() {
      let max = this.numA > this.numC ? this.numA : this.numC;
      max = max > this.numG ? max : this.numG;
      max = max > this.numT ? max : this.numT;
      return max;
    }
  }
};
</script>

<template>
  <div>
    <div class="list-group list-group-flush">
      <div
        class="list-group-item d-flex align-items-center"
        v-for="cst in constructs"
        :key="cst.olab_id"
      >
        <div class="container">
          <div class="row">
            <div class="col-1 m-auto">
              <div v-if="selectedCst && cst.olab_id === selectedCst.olab_id">
                <button
                  type="button"
                  class="btn btn-success"
                  v-on:click="selectCst(cst)"
                >
                  <font-awesome-icon icon="check-square" />
                </button>
              </div>
              <div v-else>
                <button
                  type="button"
                  class="btn btn-info btn-sm"
                  v-on:click="selectCst(cst)"
                >
                  <font-awesome-icon icon="square" />
                </button>
              </div>
            </div>

            <div class="col-11 col-sm-10 mt-2 align-self-center text-wrap">
              <a
                class="h5 text-info"
                data-toggle="modal"
                href="#constructInfo"
                v-on:click="registerCst(cst)"
                data-backdrop="static"
                data-keyboard="false"
              >
                {{ cst.olab_id }}</a
              >
              <div class="pt-1 h6">
                {{ cst.creator }}
              </div>
              <div class="py-2">
                <div>
                  <span v-for="(cstb, index) in cst.cstbs" :key="index">
                    <span v-bind:class="cstb.use_seq_as_color">
                      {{ cstb.display_name }}
                    </span>
                  </span>
                </div>
              </div>
            </div>

            <!-- Modal: constructInfo -->
            <div
              v-if="selectedCst != null"
              class="modal fade"
              id="constructInfo"
            >
              <div class="modal-dialog modal-lg">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">
                      OLAB ID:
                      <span class="h5 text-dark">{{
                        selectedCst.olab_id
                      }}</span>
                    </h5>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <!-- .modal-header -->
                  <div class="modal-body">
                    <!-- construct -->
                    <OlabConstruct :pmrObj="selectedCst" />
                  </div>
                  <!-- .modal-body -->
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                  <!-- .modal-footer -->
                </div>
                <!-- .modal-content -->
              </div>
              <!-- .modal-dialog .modal-lg-->
            </div>
            <!-- .modal fade #geneDetailInfo -->
          </div>
          <!-- UI for Input Diagnostic configs -->
          <div
            v-if="selectedCst && cst.olab_id === selectedCst.olab_id"
            class="row my-2"
          >
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-12 col-lg-6 mb-3">
                  <div class="card">
                    <div class="card-body">
                      <div class="row justify-content-center">
                        <h5>Tm Range (&deg;C)</h5>
                      </div>
                      <div class="form-group row justify-content-center">
                        <label class="col-form-label">Low</label>
                        <div class="px-2">
                          <input
                            class="form-control"
                            type="number"
                            min="50"
                            max="60"
                            step="1"
                            :class="tmLowValidState"
                            v-model="tmLow"
                          />
                          <div class="invalid-feedback">
                            {{ invalidTmMessage }}
                          </div>
                        </div>
                        <label class="col-form-label pl-5">High</label>
                        <div class="px-2">
                          <input
                            class="form-control"
                            type="number"
                            min="50"
                            max="60"
                            step="1"
                            :class="tmHighValidState"
                            v-model="tmHigh"
                          />
                          <div class="invalid-feedback">
                            {{ invalidTmMessage }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-6 mb-3">
                  <div class="card">
                    <div class="card-body">
                      <div class="row justify-content-center">
                        <h5>Primer Type</h5>
                      </div>
                      <div class="row justify-content-center">
                        <div class="col-md-4">
                          <div class="form-group form-check form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="primerTypeRadios"
                              id="SequencingRadio"
                              value="SEQ"
                              v-model="pmrType"
                              v-on:click="processPmrType('SEQ')"
                            />
                            <label
                              class="form-check-label"
                              for="SequencingRadio"
                            >
                              Sequencing
                            </label>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group form-check form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="primerTypeRadios"
                              id="cPCRAutoRadio"
                              value="CPCR_AUTO"
                              v-model="pmrType"
                              v-on:click="processPmrType('CPCR_AUTO')"
                            />
                            <label class="form-check-label" for="cPCRAutoRadio">
                              cPCR (Auto)
                            </label>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group form-check form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="primerTypeRadios"
                              id="cPCREndFixRadio"
                              value="CPCR_END_FIX"
                              v-model="pmrType"
                              v-on:click="processPmrType('CPCR_END_FIX')"
                            />
                            <label
                              class="form-check-label"
                              for="cPCREndFixRadio"
                            >
                              cPCR (End Fix)
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ul class="nav nav-tabs" id="seqTab" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="upstream-seq-tab"
                    data-toggle="tab"
                    href="#upstream-seq"
                    role="tab"
                    aria-controls="upstream-seq"
                    aria-selected="true"
                    >Upstream
                    <font-awesome-icon
                      v-if="upstreamSeqValid === false"
                      class="text-danger"
                      icon="exclamation-circle"
                    />
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="downstream-seq-tab"
                    data-toggle="tab"
                    href="#downstream-seq"
                    role="tab"
                    aria-controls="downstream-seq"
                    aria-selected="false"
                    >Downstream
                    <font-awesome-icon
                      v-if="downstreamSeqValid === false"
                      class="text-danger"
                      icon="exclamation-circle"
                    />
                  </a>
                </li>
              </ul>
              <div class="tab-content" id="seqTabContent">
                <div
                  class="tab-pane fade show active"
                  id="upstream-seq"
                  role="tabpanel"
                  aria-labelledby="upstream-seq-tab"
                >
                  <div class="form-group mt-3">
                    <textarea
                      class="form-control"
                      :class="upstreamSeqValidState"
                      v-model="upstreamSeq"
                    ></textarea>
                    <div class="invalid-feedback">
                      {{ invalidSeqMessage }}
                    </div>
                    <small class="form-text text-muted"
                      >Upstream sequence</small
                    >
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="downstream-seq"
                  role="tabpanel"
                  aria-labelledby="downstream-seq-tab"
                >
                  <div class="form-group mt-3">
                    <textarea
                      class="form-control"
                      :class="downstreamSeqValidState"
                      v-model="downstreamSeq"
                    ></textarea>
                    <div class="invalid-feedback">
                      {{ invalidSeqMessage }}
                    </div>
                    <small class="form-text text-muted"
                      >Downstream sequence</small
                    >
                  </div>
                </div>
              </div>
              <!-- Buttons -->
              <div v-if="selectedType === 'construct'">
                <button
                  type="button"
                  v-bind:disabled="diagPmrIsDisabled === true"
                  v-on:click="downloadPrimers(cst)"
                  class="btn btn-info"
                >
                  <span
                    v-if="
                      cst &&
                        selectedCst &&
                        cst.olab_id === selectedCst.olab_id &&
                        statusObj.progress
                    "
                    class="spinner-border spinner-border-sm text-light"
                  ></span>
                  Download Primers
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import OlabConstruct from "@/components/OlabConstruct";
import { OlabAxios } from "../olab/olabaxios.js";

export default {
  name: "diagnosticconstructlist",
  data: function() {
    return {
      selectedCst: null,
      downstreamSeq: "",
      upstreamSeq: "",
      tmLow: 54,
      tmHigh: 56,
      pmrType: "SEQ",
      downstreamSeqValid: false,
      upstreamSeqValid: false,
      tmLowValid: true,
      tmHighValid: true,
      requiredSeqLen: 70, // Sequencing = 70, cPCR (Auto) = 20, cPCR (EndFix) = 24
      invalidSeqMessage:
        "Sequence (a,c,g,t) has to be (+) strand from 5’ to 3’ with a minimum of 70 bps",
      invalidTmMessage: "Tm has to be between 50 and 60 and Low < High"
    };
  },
  props: [
    "user",
    "constructs",
    "statusObj",
    "errorObj",
    "selectedType",
    "item"
  ],
  components: {
    FontAwesomeIcon,
    OlabConstruct
  },
  computed: {
    diagPmrIsDisabled: function() {
      if (this.selectedCst && this.selectedCst.seq) {
        if (
          this.downstreamSeqValid &&
          this.upstreamSeqValid &&
          this.tmLowValid &&
          this.tmHighValid
        ) {
          return false;
        }
        return true;
      }
      return true;
    },
    downstreamSeqValidState: function() {
      if (this.selectedCst == null) {
        return "";
      }
      return this.downstreamSeqValid ? "is-valid" : "is-invalid";
    },
    upstreamSeqValidState: function() {
      if (this.selectedCst == null) {
        return "";
      }
      return this.upstreamSeqValid ? "is-valid" : "is-invalid";
    },
    tmLowValidState: function() {
      if (this.selectedCst == null) {
        return "";
      }
      return this.tmLowValid ? "is-valid" : "is-invalid";
    },
    tmHighValidState: function() {
      if (this.selectedCst == null) {
        return "";
      }
      return this.tmHighValid ? "is-valid" : "is-invalid";
    }
  },
  methods: {
    selectCst: function(cst) {
      // console.log(
      //   "selectCst: cst = ",
      //   cst,
      //   ", selectedCst =",
      //   this.selectedCst
      // );
      if (cst && this.selectedCst && this.selectedCst.olab_id === cst.olab_id) {
        this.registerCst(null);
      } else {
        this.registerCst(cst);
      }
    },
    registerCst: function(cst) {
      if (cst) {
        this.item.set(cst);
        // Don't reset settings if cst === selectedCst
        if (cst === this.selectedCst) {
          // console.log("cst === this.selectedCst");
          return;
        }
        this.selectedCst = cst;
        // console.log("(1) registerCst: cst = ", cst);
        // Sync up upstream and downstream seqs setting
        this.processPmrType(cst.pmr_diag_mode);
      } else {
        this.selectedCst = null;
      }
      // console.log("(2) registerCst: cst = ", cst);
    },
    processPmrType: function(pType) {
      // console.log("**** pType =", pType);
      if (pType === "SEQ") {
        // console.log("-- Sequencing", pType);
        this.pmrType = pType;
        this.requiredSeqLen = 70;
        this.upstreamSeq = this.selectedCst.diag_upstream_seq;
        this.downstreamSeq = this.selectedCst.diag_downstream_seq;
      } else if (pType === "CPCR_AUTO") {
        // console.log("-- cPCR: ", pType);
        this.pmrType = pType;
        this.requiredSeqLen = 20;
        this.upstreamSeq = this.selectedCst.cpcr_upstream_seq;
        this.downstreamSeq = this.selectedCst.cpcr_downstream_seq;
      } else if (pType === "CPCR_END_FIX") {
        // console.log("-- cPCR: ", pType);
        this.pmrType = pType;
        this.requiredSeqLen = 24;
        this.upstreamSeq = this.selectedCst.cpcr_upstream_seq2;
        this.downstreamSeq = this.selectedCst.cpcr_downstream_seq2;
      }
      this.invalidSeqMessage = `Sequence (a,c,g,t) has to be (+) strand from 5’ to 3’ with a minimum of ${this.requiredSeqLen} bps`;
    },
    validateSeq: function(seq, minLen) {
      let state = false;
      state = !seq.match(/[^acgt]/gi);
      state = state && seq.length >= minLen;
      return state;
    },
    async downloadPrimers(cst) {
      if (cst !== this.selectedCst) {
        console.log(
          "downloadPrimers - Error: cst =",
          cst,
          " is not equal to selectedCst =",
          this.selectedCst
        );
      }
      this.statusObj.message = "Generating Diagnostic primers ...";
      this.statusObj.progress = true;
      let pmrsFile = null;
      // console.log("pmrType =", this.pmrType);
      if (this.pmrType === "SEQ") {
        pmrsFile = await OlabAxios.downloadCstDiagPmrs(
          this.selectedCst,
          this.pmrType,
          this.tmLow,
          this.tmHigh,
          this.statusObj,
          this.errorObj
        );
      } else if (
        this.pmrType === "CPCR_AUTO" ||
        this.pmrType === "CPCR_END_FIX"
      ) {
        pmrsFile = await OlabAxios.downloadCstCPCRPmrs(
          this.selectedCst,
          this.pmrType,
          this.upstreamSeq,
          this.downstreamSeq,
          this.tmLow,
          this.tmHigh,
          this.statusObj,
          this.errorObj
        );
      }
      if (pmrsFile) {
        // download file to client machine
        // console.log("downloadPrimers: pmrsFile =", pmrsFile);
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.style.visibility = "hidden";

        const blob = new Blob([pmrsFile], { type: "octet/stream" });
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = this.selectedCst.olab_id + ".csv";
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    }
  },
  watch: {
    tmLow: async function() {
      this.tmLow = parseInt(this.tmLow);
      // console.log("tmLow = ", this.tmLow);
      this.tmLowValid = this.tmLow > 49;
      this.tmLowValid = this.tmLowValid
        ? this.tmLow < this.tmHigh
        : this.tmLowValid;
    },
    tmHigh: async function() {
      this.tmHigh = parseInt(this.tmHigh);
      // console.log("tmHigh =", this.tmHigh);
      this.tmHighValid = this.tmHigh <= 60;
      this.tmHighValid = this.tmHighValid
        ? this.tmLow < this.tmHigh
        : this.tmHighValid;
    },
    downstreamSeq: function() {
      // console.log("downstreamSeq = " + this.downstreamSeq);
      if (this.selectedCst && this.downstreamSeq) {
        this.downstreamSeq = this.downstreamSeq.toUpperCase();
        // only keep a-zA-Z and remove other characters from seq
        this.downstreamSeq = this.downstreamSeq.replace(/[^a-zA-Z]/g, "");

        this.downstreamSeqValid = this.validateSeq(
          this.downstreamSeq,
          this.requiredSeqLen
        );
        if (this.downstreamSeqValid) {
          this.selectedCst.setDownstreamSeq(this.downstreamSeq, this.pmrType);
        }
      } else if (this.selectedCst && !this.downstreamSeq) {
        this.downstreamSeqValid = false;
      }
    },
    upstreamSeq: function() {
      // console.log("upstreamSeq = " + this.upstreamSeq);
      if (this.selectedCst && this.upstreamSeq) {
        this.upstreamSeq = this.upstreamSeq.toUpperCase();
        // only keep a-zA-Z and remove other characters from seq
        this.upstreamSeq = this.upstreamSeq.replace(/[^a-zA-Z]/g, "");
        this.upstreamSeqValid = this.validateSeq(
          this.upstreamSeq,
          this.requiredSeqLen
        );
        if (this.upstreamSeqValid) {
          this.selectedCst.setUpstreamSeq(this.upstreamSeq, this.pmrType);
        }
      } else if (this.selectedCst && !this.upstreamSeq) {
        this.upstreamSeqValid = false;
      }
    }
  }
};
</script>

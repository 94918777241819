<template>
  <div class="container">
    <div class="mt-4">
      <h3>Stats</h3>
      <ul class="nav nav-tabs" id="statsTab" role="tablist">
        <li class="nav-item">
          <a
            class="nav-link active"
            id="olab-stats-system-tab"
            data-toggle="tab"
            href="#olab-stats-system"
            role="tab"
            aria-controls="olab-stats-system"
            aria-selected="true"
            v-on:click="loadSystemStats()"
            >System
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            id="olab-stats-project-tab"
            data-toggle="tab"
            href="#olab-stats-project"
            role="tab"
            aria-controls="olab-stats-project"
            aria-selected="false"
            >Project
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            id="olab-stats-user-tab"
            data-toggle="tab"
            href="#olab-stats-user"
            role="tab"
            aria-controls="olab-stats-user"
            aria-selected="false"
            >User
          </a>
        </li>
      </ul>
      <div
        v-if="errorObj.message"
        class="col-12 alert alert-danger text-center px-3"
      >
        {{ errorObj.message }}
        <div class="text-center mt-2">
          <button
            type="button"
            class="btn btn-danger"
            v-on:click="closeError()"
          >
            OK
          </button>
        </div>
      </div>
      <div
        v-if="statusObj.message"
        class="col-12 alert alert-info fade show px-3"
      >
        <span>{{ statusObj.message }}</span>
        <span v-if="statusObj.progress" class="mx-2 spinner-grow" role="status">
          <span class="sr-only">Status information</span>
        </span>
        <div class="text-center mt-2">
          <button
            type="button"
            class="btn btn-primary"
            v-on:click="closeStatus()"
          >
            OK
          </button>
        </div>
      </div>
      <div class="tab-content" id="statsTabContent">
        <div
          class="tab-pane fade show active"
          id="olab-stats-system"
          role="tabpanel"
          aria-labelledby="olab-stats-system-tab"
        >
          <div v-if="system" class="form-group mt-3">
            <OlabSystemTab :system="system" />
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="olab-stats-project"
          role="tabpanel"
          aria-labelledby="olab-stats-project-tab"
        >
          <div class="form-group mt-3">
            <OlabProjectTab
              :user="user"
              :dbInfo="dbInfo"
              :statusObj="statusObj"
              :errorObj="errorObj"
            />
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="olab-stats-user"
          role="tabpanel"
          aria-labelledby="olab-stats-user-tab"
        >
          <div class="form-group mt-3">
            <OlabUserTab
              :user="user"
              :dbInfo="dbInfo"
              :statusObj="statusObj"
              :errorObj="errorObj"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { OlabAxios } from "@/olab/olabaxios.js";
import OlabSystemTab from "@/components/stats/OlabSystemTab";
import OlabProjectTab from "@/components/stats/OlabProjectTab";
import OlabUserTab from "@/components/stats/OlabUserTab";

export default {
  name: "OlabStats",
  data() {
    return {
      system: null
    };
  },
  components: {
    OlabSystemTab,
    OlabProjectTab,
    OlabUserTab
  },
  props: ["user", "dbInfo", "statusObj", "errorObj"],
  mounted() {
    this.loadSystemStats();
  },
  methods: {
    async loadSystemStats() {
      // console.log("loadSystemStats");
      this.system = await OlabAxios.getSystemStats(
        this.statusObj,
        this.errorObj
      );
    },
    closeStatus: function() {
      // console.log("Close Status Alert ...");
      this.statusObj.message = null;
      this.statusObj.progress = false;
    },
    closeError: function() {
      // console.log("Close Error Alert ...");
      this.errorObj.message = null;
    }
  }
};
</script>

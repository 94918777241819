<template>
  <div>
    <h3>Status</h3>
    <ul class="nav nav-tabs" id="statusTab" role="tablist">
      <li class="nav-item">
        <a
          class="nav-link active"
          id="updates-tab"
          data-toggle="tab"
          href="#updates"
          role="tab"
          aria-controls="updates"
          aria-selected="true"
          >Updates
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          id="year-2023-tab"
          data-toggle="tab"
          href="#year-2023"
          role="tab"
          aria-controls="year-2023"
          aria-selected="true"
          >2023
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          id="year-2022-tab"
          data-toggle="tab"
          href="#year-2022"
          role="tab"
          aria-controls="year-2022"
          aria-selected="false"
          >2022
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          id="prep-2022-tab"
          data-toggle="tab"
          href="#prep-2022"
          role="tab"
          aria-controls="prep-2022"
          aria-selected="false"
          >Prep 2022
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          id="phase-II-2021-tab"
          data-toggle="tab"
          href="#phase-II-2021"
          role="tab"
          aria-controls="phase-II-2021"
          aria-selected="false"
          >Phase II (2021)
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          id="phase-I-2021-tab"
          data-toggle="tab"
          href="#phase-I-2021"
          role="tab"
          aria-controls="phase-I-2021"
          aria-selected="false"
          >Phase I (2021)
        </a>
      </li>
    </ul>
    <div class="tab-content" id="statusTabContent">
      <div
        class="tab-pane fade show active"
        id="updates"
        role="tabpanel"
        aria-labelledby="updates-tab"
      >
        <div class="form-group mt-3">
          <OlabUpdates />
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="year-2023"
        role="tabpanel"
        aria-labelledby="year-2023-tab"
      >
        <div class="form-group mt-3">
          <Olab2023 />
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="year-2022"
        role="tabpanel"
        aria-labelledby="year-2022-tab"
      >
        <div class="form-group mt-3">
          <Olab2022 />
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="prep-2022"
        role="tabpanel"
        aria-labelledby="prep-2022-tab"
      >
        <div class="form-group mt-3">
          <OlabPrep2022 />
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="phase-II-2021"
        role="tabpanel"
        aria-labelledby="phase-II-2021-tab"
      >
        <div class="form-group mt-3">
          <Olab2021Phase2 />
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="phase-I-2021"
        role="tabpanel"
        aria-labelledby="phase-I-2021-tab"
      >
        <div class="form-group mt-3">
          <Olab2021Phase1 />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Olab2021Phase1 from "@/components/status/Olab2021Phase1";
import Olab2021Phase2 from "@/components/status/Olab2021Phase2";
import OlabPrep2022 from "@/components/status/OlabPrep2022";
import Olab2022 from "@/components/status/Olab2022";
import Olab2023 from "@/components/status/Olab2023";
import OlabUpdates from "@/components/status/OlabUpdates";

export default {
  name: "olabstatus",
  components: {
    // FontAwesomeIcon
    Olab2021Phase1,
    Olab2021Phase2,
    OlabPrep2022,
    Olab2022,
    Olab2023,
    OlabUpdates
  }
};
</script>

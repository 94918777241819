<template>
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <g transform="translate(100,180)">
        <path
          :fill="computedFill"
          transform="scale(0.5)"
          d="M425.7 256c-16.9 0-32.8-9-41.4-23.4L320 126l-64.2 106.6c-8.7 14.5-24.6 23.5-41.5 23.5-4.5 0-9-.6-13.3-1.9L64 215v178c0 14.7 10 27.5 24.2 31l216.2 54.1c10.2 2.5 20.9 2.5 31 0L551.8 424c14.2-3.6 24.2-16.4 24.2-31V215l-137 39.1c-4.3 1.3-8.8 1.9-13.3 1.9zm212.6-112.2L586.8 41c-3.1-6.2-9.8-9.8-16.7-8.9L320 64l91.7 152.1c3.8 6.3 11.4 9.3 18.5 7.3l197.9-56.5c9.9-2.9 14.7-13.9 10.2-23.1zM53.2 41L1.7 143.8c-4.6 9.2.3 20.2 10.1 23l197.9 56.5c7.1 2 14.7-1 18.5-7.3L320 64 69.8 32.1c-6.9-.8-13.5 2.7-16.6 8.9z"
        >
          />
        </path>
        <text
          :x="computedPkgQtyPosX"
          :y="packageQtyPosY"
          fill="#f80"
          style="font-size:100px;font-weight:normal;text-align:start;text-anchor:start;fill-opacity:1;stroke:none;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;font-family:Arial"
          id="olab-text"
          xml:space="preserve"
        >
          {{ computedQty }}
        </text>
        <text
          :x="computedTypePosX"
          :y="typePosY"
          fill="#f80"
          style="font-size:50px;font-weight:normal;text-align:start;text-anchor:start;fill-opacity:1;stroke:none;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;font-family:Arial"
          id="olab-text"
          xml:space="preserve"
        >
          {{ type }}
        </text>
        <text
          :x="computedSeqTypePosX"
          :y="seqTypePosY"
          fill="#f80"
          style="font-size:30px;font-weight:normal;text-align:start;text-anchor:start;fill-opacity:1;stroke:none;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;font-family:Arial"
          id="olab-text"
          xml:space="preserve"
        >
          {{ seqType }}
        </text>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "olabpackagesvggen",
  data: function() {
    return {
      title: "OlabPackageSVGGen",
      typePosX: 0,
      typePosY: 165,
      seqTypePosX: 45,
      seqTypePosY: 205,
      packageQtyPosX: 130,
      packageQtyPosY: 15
    };
  },
  props: ["type", "seqType", "comps", "fillColor"],
  computed: {
    computedFill: function() {
      switch (this.fillColor) {
        case "compdb":
          return "#ffeedd";
        default:
          return "#eeeeee";
      }
    },
    computedTypePosX: function() {
      let xPos = this.typePosX;
      switch (this.type) {
        case "arm":
          xPos += 118;
          break;
        case "gene":
          xPos += 100;
          break;
        case "pgst":
          xPos += 110;
          break;
        case "promoter":
          xPos += 60;
          break;
        case "rbs":
          xPos += 125;
          break;
        case "terminator":
          xPos += 45;
          break;
        default:
      }
      return xPos;
    },
    computedSeqTypePosX: function() {
      let xPos = this.seqTypePosX;
      switch (this.seqType) {
        case "genome":
          xPos += 55;
          break;
        case "library_sequence":
          xPos += 0;
          break;
        default:
      }
      return xPos;
    },
    computedPkgQtyPosX: function() {
      if (this.computedQty > 99) {
        return this.packageQtyPosX - 50;
      } else if (this.computedQty > 9) {
        return this.packageQtyPosX - 25;
      }
      return this.packageQtyPosX;
    },
    computedQty: function() {
      let total = 0;
      if (this.comps) {
        for (let i = 0; i < this.comps.length; i++) {
          total += this.comps[i].qty;
        }
      }
      return total;
    }
  }
};
</script>

import { OlabUtils } from "./olabutils.js";

class OlabAATable {
  constructor() {
    OlabUtils.infoLog("OlabAATable.constructor() ...");
    this.name = "Amino Acid Lookup Table";
  }

  static lookupAA(seq) {
    let aa = "";
    if (seq) {
      switch (seq) {
        case "TTT":
          aa = " F ";
          break;
        case "TTC":
          aa = " F ";
          break;
        case "TTA":
          aa = " L ";
          break;
        case "TTG":
          aa = " L ";
          break;
        case "CTT":
          aa = " L ";
          break;
        case "CTC":
          aa = " L ";
          break;
        case "CTA":
          aa = " L ";
          break;
        case "CTG":
          aa = " L ";
          break;

        case "ATT":
          aa = " I ";
          break;
        case "ATC":
          aa = " I ";
          break;
        case "ATA":
          aa = " I ";
          break;
        case "ATG":
          aa = " M ";
          break;

        case "GTT":
          aa = " V ";
          break;
        case "GTC":
          aa = " V ";
          break;
        case "GTA":
          aa = " V ";
          break;
        case "GTG":
          aa = " V ";
          break;

        case "TCT":
          aa = " S ";
          break;
        case "TCC":
          aa = " S ";
          break;
        case "TCA":
          aa = " S ";
          break;
        case "TCG":
          aa = " S ";
          break;

        case "CCT":
          aa = " P ";
          break;
        case "CCC":
          aa = " P ";
          break;
        case "CCA":
          aa = " P ";
          break;
        case "CCG":
          aa = " P ";
          break;

        case "ACT":
          aa = " T ";
          break;
        case "ACC":
          aa = " T ";
          break;
        case "ACA":
          aa = " T ";
          break;
        case "ACG":
          aa = " T ";
          break;

        case "GCT":
          aa = " A ";
          break;
        case "GCC":
          aa = " A ";
          break;
        case "GCA":
          aa = " A ";
          break;
        case "GCG":
          aa = " A ";
          break;

        case "TAT":
          aa = " Y ";
          break;
        case "TAC":
          aa = " Y ";
          break;
        case "TAA":
          aa = " * ";
          break;
        case "TAG":
          aa = " * ";
          break;

        case "CAT":
          aa = " H ";
          break;
        case "CAC":
          aa = " H ";
          break;
        case "CAA":
          aa = " Q ";
          break;
        case "CAG":
          aa = " Q ";
          break;

        case "AAT":
          aa = " N ";
          break;
        case "AAC":
          aa = " N ";
          break;
        case "AAA":
          aa = " K ";
          break;
        case "AAG":
          aa = " K ";
          break;

        case "GAT":
          aa = " D ";
          break;
        case "GAC":
          aa = " D ";
          break;
        case "GAA":
          aa = " E ";
          break;
        case "GAG":
          aa = " E ";
          break;

        case "TGT":
          aa = " C ";
          break;
        case "TGC":
          aa = " C ";
          break;
        case "TGA":
          aa = " * ";
          break;
        case "TGG":
          aa = " W ";
          break;

        case "CGT":
          aa = " R ";
          break;
        case "CGC":
          aa = " R ";
          break;
        case "CGA":
          aa = " R ";
          break;
        case "CGG":
          aa = " R ";
          break;

        case "AGT":
          aa = " S ";
          break;
        case "AGC":
          aa = " S ";
          break;
        case "AGA":
          aa = " R ";
          break;
        case "AGG":
          aa = " R ";
          break;

        case "GGT":
          aa = " G ";
          break;
        case "GGC":
          aa = " G ";
          break;
        case "GGA":
          aa = " G ";
          break;
        case "GGG":
          aa = " G ";
          break;
        default:
          aa = " - ";
      }
    }
    return aa;
  }

  static infoLog(aa) {
    OlabUtils.infoLog(" - aa.name = " + aa.name);
  }
}

export { OlabAATable };

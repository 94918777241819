import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Clipboard from "v-clipboard";
import "bootstrap";

import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faArrowsAltH,
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faBoxes,
  faBoxOpen,
  faCog,
  faSquare,
  faCheckSquare,
  faTrash,
  faTrashAlt,
  faLink,
  faListUl,
  faRandom,
  faUndo,
  faStar,
  faEnvelope,
  faDna,
  faSeedling,
  faPlus,
  faPlusSquare,
  faMinus,
  faInfo,
  faHandPointer,
  faIdBadge,
  faBlog,
  faProjectDiagram,
  faEdit,
  faSearch,
  faShoppingCart,
  faAddressBook,
  faQuestion,
  faQuestionCircle,
  faSignOutAlt,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faBullhorn,
  faLock,
  faLockOpen
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faArrowsAltH,
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faBoxes,
  faBoxOpen,
  faCog,
  faSquare,
  faCheckSquare,
  faTrash,
  faTrashAlt,
  faLink,
  faListUl,
  faRandom,
  faUndo,
  faStar,
  faPlus,
  faPlusSquare,
  faMinus,
  faEnvelope,
  faDna,
  faSeedling,
  faInfo,
  faHandPointer,
  faIdBadge,
  faBlog,
  faProjectDiagram,
  faEdit,
  faSearch,
  faShoppingCart,
  faAddressBook,
  faQuestion,
  faQuestionCircle,
  faSignOutAlt,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faBullhorn,
  faLock,
  faLockOpen
);
Vue.config.productionTip = false;
Vue.use(Clipboard);

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");

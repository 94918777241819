<template>
  <div>
    <div class="list-group list-group-flush">
      <div
        class="list-group-item d-flex mb-1 align-items-center"
        v-for="snp in snpSwaps"
        :key="snp.index"
      >
        <div class="container">
          <div class="row">
            <div class="col-12 text-monospace text-truncate">
              <span class="small">...</span>
              <span class="small">{{
                snp.blockSeq.substr(
                  snp.blockSeqPos - 20 >= 0 ? snp.blockSeqPos - 20 : 0,
                  snp.blockSeqPos >= 20 ? 20 : snp.blockSeqPos
                )
              }}</span>
              <span class="h5 text-danger">{{
                snp.blockSeq.substr(snp.blockSeqPos, 1)
              }}</span>
              <span class="small">{{
                snp.blockSeq.substr(snp.blockSeqPos + 1, 20)
              }}</span>
              <span class="small">...</span>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-monospace text-truncate">
              <span class="small">...</span>
              <span class="small">{{
                snp.blockSeq.substr(
                  snp.blockSeqPos - 20 >= 0 ? snp.blockSeqPos - 20 : 0,
                  snp.blockSeqPos >= 20 ? 20 : snp.blockSeqPos
                )
              }}</span>
              <span class="h5 text-success">{{ snp.replaceWith }}</span>
              <span class="small">{{
                snp.blockSeq.substr(snp.blockSeqPos + 1, 20)
              }}</span>
              <span class="small">...</span>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-1 m-auto">
              <div
                v-if="
                  snpSwap &&
                    snpSwap.gene &&
                    snp.gene.olab_id === snpSwap.gene.olab_id
                "
              >
                <button
                  type="button"
                  class="btn btn-success"
                  v-on:click="selectSNPSwap(snp)"
                >
                  <font-awesome-icon icon="check-square" />
                </button>
              </div>
              <div v-else>
                <button
                  type="button"
                  class="btn btn-info btn-sm"
                  v-on:click="selectSNPSwap(snp)"
                >
                  <font-awesome-icon icon="square" />
                </button>
              </div>
            </div>
            <div class="col-11 col-md-3 mt-2">
              <a
                class="text-info"
                data-toggle="modal"
                href="#geneDetailInfo"
                v-on:click="registerSNPSwap(snp)"
                data-backdrop="static"
                data-keyboard="false"
                >{{ snp.gene.olab_id }}</a
              >

              <div
                v-if="snpSwap && snpSwap.gene != null"
                class="modal fade"
                id="geneDetailInfo"
              >
                <div class="modal-dialog modal-lg">
                  <div class="modal-content text-left">
                    <div class="modal-header">
                      <h5 class="modal-title">
                        OLAB ID:
                        <span class="h5 text-dark">{{
                          snpSwap.gene.olab_id
                        }}</span>
                      </h5>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <!-- .modal-header -->
                    <div class="modal-body">
                      <div class="text-wrap">
                        <span v-if="snpSwap.gene.name">
                          <span class="mr-1">Name:</span>
                          <span class="h5 text-dark mr-5">{{
                            snpSwap.gene.name
                          }}</span>
                        </span>
                        <span v-if="snpSwap.gene.synonym_name">
                          <span class="mr-1">Synonym Name:</span>
                          <span
                            v-if="snpSwap.gene.synonym_name"
                            class="h5 text-dark"
                            >{{ snpSwap.gene.synonym_name }}</span
                          >
                          <span
                            v-if="snpSwap.gene.synonym_name2"
                            class="h5 text-dark"
                            >; {{ snpSwap.gene.synonym_name2 }}</span
                          >
                        </span>
                      </div>
                      <div v-if="snpSwap.gene.product" class="py-1 text-wrap">
                        <span class="mr-1">Product:</span>
                        <span class="h5 text-dark">{{
                          snpSwap.gene.product
                        }}</span>
                      </div>
                      <div
                        v-if="
                          snpSwap.gene.locus_tag || snpSwap.gene.old_locus_tag
                        "
                        class="my-2 card"
                      >
                        <div class="card-header text-dark">Locus Tag</div>
                        <div class="card-body text-wrap">
                          <span class="mr-1">Old:</span>
                          <span class="h5 text-dark">{{
                            snpSwap.gene.old_locus_tag
                          }}</span>

                          <span class="ml-3 mr-1">New:</span>
                          <span class="h5 text-dark">{{
                            snpSwap.gene.locus_tag
                          }}</span>
                        </div>
                        <!-- .card-body -->
                      </div>
                      <div class="my-2 card">
                        <div class="card-header text-dark">Description</div>
                        <div class="card-body">
                          <div class="py-1 small text-dark text-wrap">
                            {{ snpSwap.gene.desc }}
                          </div>
                        </div>
                        <!-- .card-body -->
                      </div>
                      <!-- .card -->
                      <div v-if="snpSwap.gene.sequence != null" class="card">
                        <div class="card-header text-dark">Sequence</div>
                        <div class="card-body">
                          <span class="mr-1">Type:</span>
                          <span class="h5 text-dark">{{
                            snpSwap.gene.sequence.type
                          }}</span>
                          <div class="pt-2">
                            <span class="mr-1">Range:</span>
                            <span class="h5 text-dark"
                              >[ {{ snpSwap.gene.sequence.from }},</span
                            >
                            <span class="h5">..,</span>
                            <span class="h5 text-dark"
                              >{{ snpSwap.gene.sequence.to }} ]</span
                            >
                            <span class="ml-5 mr-1">Strand:</span>
                            <span class="h5 text-dark">{{
                              snpSwap.gene.sequence.comp | convertCompToStrand
                            }}</span>
                          </div>
                          <div class="py-2">
                            <span class="mr-1">Length:</span>
                            <span class="h5 text-dark">{{
                              snpSwap.gene.gene_stats.len
                            }}</span>
                          </div>
                          <div class="py-1">
                            <span class="mr-1"># A:</span>
                            <span class="h5 text-dark">{{
                              snpSwap.gene.gene_stats.num_a
                            }}</span>
                            <span class="ml-3 mr-1"># C:</span>
                            <span class="h5 text-dark">{{
                              snpSwap.gene.gene_stats.num_c
                            }}</span>
                            <span class="ml-3 mr-1"># G:</span>
                            <span class="h5 text-dark">{{
                              snpSwap.gene.gene_stats.num_g
                            }}</span>
                            <span class="ml-3 mr-1"># T:</span>
                            <span class="h5 text-dark">{{
                              snpSwap.gene.gene_stats.num_t
                            }}</span>
                          </div>

                          <div
                            class="py-2 small text-monospace text-dark text-wrap"
                          >
                            {{ snpSwap.gene.sequence.seq }}
                          </div>
                          <!-- <img class="card-img-bottom img-fluid" v-bind:alt="snpSwap.gene.name" v-bind:src="printGeneImageLink"> -->
                        </div>
                        <!-- .card-body -->
                      </div>
                      <!-- .card -->
                    </div>
                    <!-- .modal-body -->
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-secondary"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                    </div>
                    <!-- .modal-footer -->
                  </div>
                  <!-- .modal-content -->
                </div>
                <!-- .modal-dialog .modal-lg-->
              </div>
              <!-- .modal fade #geneDetailInfo -->

              <div class="h5 mt-2">
                <span v-if="snp.displayName" class="mr-2">{{
                  snp.displayName
                }}</span>
              </div>
            </div>
            <div class="col-3 col-md-2 mt-2">
              <div>
                Position
              </div>
              <div class="h5 mt-2">
                <div
                  v-if="
                    snpSwap &&
                      snpSwap.gene &&
                      snp.gene.olab_id === snpSwap.gene.olab_id
                  "
                >
                  <input
                    class="form-control"
                    type="text"
                    maxlength="5"
                    :class="positionValidState"
                    v-model="position"
                  />
                  <div class="invalid-feedback">
                    <span class="small">
                      {{ positionInvalidFeedback }}
                    </span>
                  </div>
                </div>
                <div v-else>
                  {{ snp.position }}
                </div>
              </div>
            </div>
            <div class="col-3 col-md-2 mt-2">
              <div>
                Amino Acid
              </div>
              <div class="mt-0">
                <div class="row">
                  <div class="col-6 text-danger">
                    {{ snp.aaBefore }}
                  </div>
                  <div class="col-6 text-success">
                    {{ snp.aaAfter }}
                  </div>
                </div>
                <div class="row small">
                  <div class="col-6 text-danger">
                    <span :class="snp.aaSeqBefore1Color">{{
                      snp.aaSeqBefore.substr(0, 1)
                    }}</span>
                    <span :class="snp.aaSeqBefore2Color">{{
                      snp.aaSeqBefore.substr(1, 1)
                    }}</span>
                    <span :class="snp.aaSeqBefore3Color">{{
                      snp.aaSeqBefore.substr(2, 1)
                    }}</span>
                  </div>
                  <div class="col-6 text-success">
                    <span :class="snp.aaSeqAfter1Color">{{
                      snp.aaSeqAfter.substr(0, 1)
                    }}</span>
                    <span :class="snp.aaSeqAfter2Color">{{
                      snp.aaSeqAfter.substr(1, 1)
                    }}</span>
                    <span :class="snp.aaSeqAfter3Color">{{
                      snp.aaSeqAfter.substr(2, 1)
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-3 col-md-2 mt-2">
              <div>
                After
              </div>
              <div class="h5 mt-2">
                <div
                  v-if="
                    snpSwap &&
                      snpSwap.gene &&
                      snp.gene.olab_id === snpSwap.gene.olab_id
                  "
                >
                  <select class="form-control" v-model="replaceWith">
                    <option value="A">A</option>
                    <option value="C">C</option>
                    <option value="G">G</option>
                    <option value="T">T</option>
                  </select>
                </div>
                <div v-else class="text-success">
                  {{ snp.replaceWith }}
                </div>
              </div>
            </div>
            <div class="col-3 col-md-2 mt-2">
              <div>
                Arm Size
              </div>
              <div class="h5 mt-2">
                <div
                  v-if="
                    snpSwap &&
                      snpSwap.gene &&
                      snp.gene.olab_id === snpSwap.gene.olab_id
                  "
                >
                  <input
                    class="form-control"
                    type="text"
                    maxlength="4"
                    :class="armSizeValidState"
                    v-model="armSize"
                  />
                  <div class="invalid-feedback">
                    <span class="small">[40, </span>
                    <span class="small">3000</span>
                    <span class="small">]</span>
                  </div>
                </div>
                <div v-else>
                  {{ snp.armSize }}
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="
              snpSwap &&
                snpSwap.gene &&
                snp.gene.olab_id === snpSwap.gene.olab_id
            "
            class="row my-2"
          >
            <div class="col-1"></div>
            <div class="col-10 container">
              <ul class="nav nav-tabs" id="padTab" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="pmr-5-pad-tab"
                    data-toggle="tab"
                    href="#pmr-5-pad"
                    role="tab"
                    aria-controls="pmr-5-pad"
                    aria-selected="true"
                    >5' padding</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="pmr-3-pad-tab"
                    data-toggle="tab"
                    href="#pmr-3-pad"
                    role="tab"
                    aria-controls="pmr-3-pad"
                    aria-selected="false"
                    >3' padding</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="preview-tab"
                    data-toggle="tab"
                    href="#preview"
                    role="tab"
                    v-on:click="doPreview(snpSwap)"
                    aria-controls="preview"
                    aria-selected="false"
                    >Preview</a
                  >
                </li>
              </ul>
              <div class="tab-content" id="padTabContent">
                <div
                  class="tab-pane fade show active"
                  id="pmr-5-pad"
                  role="tabpanel"
                  aria-labelledby="pmr-5-pad-tab"
                >
                  <div class="form-group mt-3">
                    <textarea
                      class="form-control"
                      :class="pmr5PadValidState"
                      v-model="pmr5Pad"
                    ></textarea>
                    <div class="invalid-feedback">
                      Sequence has to be [a,c,g,t]
                    </div>
                    <small class="form-text text-muted"
                      >Landing pad sequence</small
                    >
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="pmr-3-pad"
                  role="tabpanel"
                  aria-labelledby="pmr-3-pad-tab"
                >
                  <div class="form-group mt-3">
                    <textarea
                      class="form-control"
                      :class="pmr3PadValidState"
                      v-model="pmr3Pad"
                    ></textarea>
                    <div class="invalid-feedback">
                      Sequence has to be [a,c,g,t]
                    </div>
                    <small class="form-text text-muted"
                      >Landing pad sequence</small
                    >
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="preview"
                  role="tabpanel"
                  aria-labelledby="preview-tab"
                >
                  <div class="form-group mt-3">
                    <div class="row justify-content-center">
                      <button
                        class="btn mb-2 upstream-color-md text-truncate"
                        v-on:click="showBoxSeq('US')"
                        type="button"
                      >
                        <span v-if="showSeqUS == false">
                          {{ snpSwap.boxUS.display_name }}
                        </span>
                        <span v-else>
                          {{ snpSwap.boxUS.display_seq }}
                        </span>
                      </button>
                      <button
                        class="btn mb-2 downstream-color-md text-truncate"
                        v-on:click="showBoxSeq('DS')"
                        type="button"
                      >
                        <span v-if="showSeqDS == false">
                          {{ snpSwap.boxDS.display_name }}
                        </span>
                        <span v-else>
                          {{ snpSwap.boxDS.display_seq }}
                        </span>
                      </button>
                    </div>
                    <!-- .row -->
                  </div>
                  <div class="row justify-content-center">
                    <legend class="pt-2">Primers</legend>
                    <div class="py-1 list-group list-group-flush">
                      <div class="list-group-item mb-1">
                        <div class="text-light upstream-color">
                          U_{{ snpSwap.displayName }}
                        </div>
                        <div
                          v-if="snpSwap.boxUS.pmrFwd.olab_id"
                          class="form-text text-left text-dark bg-light"
                        >
                          <div class="mb-1">
                            <span class="small text-secondary mr-1">Name:</span>
                            <span class="text-dark mr-2"
                              >{{ snpSwap.boxUS.pmrFwd.name }}
                            </span>
                            <span class="small text-secondary mr-1">ID:</span>
                            <span class="text-dark mr-2"
                              >{{ snpSwap.boxUS.pmrFwd.olab_id }}
                            </span>
                            <span class="small text-secondary mr-1"
                              >Length:</span
                            >
                            <span class="text-dark mr-2"
                              >{{ snpSwap.boxUS.pmrFwd.seqWithPad.length }}
                            </span>
                            <span class="small text-secondary mr-1"
                              >Tm Full:</span
                            >
                            <span class="text-dark mr-2"
                              >{{
                                snpSwap.boxUS.pmrFwd.tmWithPad.toFixed(1)
                              }}&#8451;
                            </span>
                            <span class="small text-secondary mr-1"
                              >Tm 3':</span
                            >
                            <span class="text-dark mr-2"
                              >{{ snpSwap.boxUS.pmrFwd.tm3p.toFixed(1) }}&#8451;
                            </span>
                          </div>
                          <small>
                            <span class="text-primary">
                              <font-awesome-icon icon="long-arrow-alt-right" />
                            </span>
                            <span class="text-monospace text-primary ml-1"
                              >{{ snpSwap.boxUS.pmrFwd.seqWithPad }}
                            </span>
                          </small>
                        </div>
                        <div
                          v-else
                          class="form-text text-left text-dark bg-light"
                        >
                          <div class="mb-1">
                            <span class="small text-secondary mr-1"
                              >No forward primer</span
                            >
                          </div>
                        </div>
                        <div
                          v-if="snpSwap.boxUS.pmrRev.olab_id"
                          class="form-text text-left text-dark bg-light"
                        >
                          <div class="mb-1">
                            <span class="small text-secondary mr-1">Name:</span>
                            <span class="text-dark mr-2"
                              >{{ snpSwap.boxUS.pmrRev.name }}
                            </span>
                            <span class="small text-secondary mr-1">ID:</span>
                            <span class="text-dark mr-2"
                              >{{ snpSwap.boxUS.pmrRev.olab_id }}
                            </span>
                            <span class="small text-secondary mr-1"
                              >Length:</span
                            >
                            <span class="text-dark mr-2"
                              >{{ snpSwap.boxUS.pmrRev.seq.length }}
                            </span>
                            <span class="small text-secondary mr-1"
                              >Tm Full:</span
                            >
                            <span class="text-dark mr-2"
                              >{{ snpSwap.boxUS.pmrRev.tm.toFixed(1) }}&#8451;
                            </span>
                            <span class="small text-secondary mr-1"
                              >Tm 3':</span
                            >
                            <span class="text-dark mr-2"
                              >{{ snpSwap.boxUS.pmrRev.tm3p.toFixed(1) }}&#8451;
                            </span>
                          </div>
                          <small>
                            <span class="text-danger">
                              <font-awesome-icon icon="long-arrow-alt-left" />
                            </span>
                            <span class="text-monospace text-danger ml-1"
                              >{{ snpSwap.boxUS.pmrRev.seq }}
                            </span>
                          </small>
                        </div>
                        <div
                          v-else
                          class="form-text text-left text-dark bg-light"
                        >
                          <div class="mb-1">
                            <span class="small text-secondary mr-1"
                              >No reverse primer</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="list-group-item mb-1">
                        <div class="text-light downstream-color">
                          D_{{ snpSwap.displayName }}
                        </div>
                        <div
                          v-if="snpSwap.boxDS.pmrFwd.olab_id"
                          class="form-text text-left text-dark bg-light"
                        >
                          <div class="mb-1">
                            <span class="small text-secondary mr-1">Name:</span>
                            <span class="text-dark mr-2"
                              >{{ snpSwap.boxDS.pmrFwd.name }}
                            </span>
                            <span class="small text-secondary mr-1">ID:</span>
                            <span class="text-dark mr-2"
                              >{{ snpSwap.boxDS.pmrFwd.olab_id }}
                            </span>
                            <span class="small text-secondary mr-1"
                              >Length:</span
                            >
                            <span class="text-dark mr-2"
                              >{{ snpSwap.boxDS.pmrFwd.seq.length }}
                            </span>
                            <span class="small text-secondary mr-1"
                              >Tm Full:</span
                            >
                            <span class="text-dark mr-2"
                              >{{ snpSwap.boxDS.pmrFwd.tm.toFixed(1) }}&#8451;
                            </span>
                            <span class="small text-secondary mr-1"
                              >Tm 3':</span
                            >
                            <span class="text-dark mr-2"
                              >{{ snpSwap.boxDS.pmrFwd.tm3p.toFixed(1) }}&#8451;
                            </span>
                          </div>
                          <small>
                            <span class="text-primary">
                              <font-awesome-icon icon="long-arrow-alt-right" />
                            </span>
                            <span class="text-monospace text-primary ml-1"
                              >{{ snpSwap.boxDS.pmrFwd.seq }}
                            </span>
                          </small>
                        </div>
                        <div
                          v-else
                          class="form-text text-left text-dark bg-light"
                        >
                          <div class="mb-1">
                            <span class="small text-secondary mr-1"
                              >No forward primer</span
                            >
                          </div>
                        </div>
                        <div
                          v-if="snpSwap.boxDS.pmrRev.olab_id"
                          class="form-text text-left text-dark bg-light"
                        >
                          <div class="mb-1">
                            <span class="small text-secondary mr-1">Name:</span>
                            <span class="text-dark mr-2"
                              >{{ snpSwap.boxDS.pmrRev.name }}
                            </span>
                            <span class="small text-secondary mr-1">ID:</span>
                            <span class="text-dark mr-2"
                              >{{ snpSwap.boxDS.pmrRev.olab_id }}
                            </span>
                            <span class="small text-secondary mr-1"
                              >Length:</span
                            >
                            <span class="text-dark mr-2"
                              >{{ snpSwap.boxDS.pmrRev.seqWithPad.length }}
                            </span>
                            <span class="small text-secondary mr-1"
                              >Tm Full:</span
                            >
                            <span class="text-dark mr-2"
                              >{{
                                snpSwap.boxDS.pmrRev.tmWithPad.toFixed(1)
                              }}&#8451;
                            </span>
                            <span class="small text-secondary mr-1"
                              >Tm 3':</span
                            >
                            <span class="text-dark mr-2"
                              >{{ snpSwap.boxDS.pmrRev.tm3p.toFixed(1) }}&#8451;
                            </span>
                          </div>
                          <small>
                            <span class="text-danger">
                              <font-awesome-icon icon="long-arrow-alt-left" />
                            </span>
                            <span class="text-monospace text-danger ml-1"
                              >{{ snpSwap.boxDS.pmrRev.seqWithPad }}
                            </span>
                          </small>
                        </div>
                        <div
                          v-else
                          class="form-text text-left text-dark bg-light"
                        >
                          <div class="mb-1">
                            <span class="small text-secondary mr-1"
                              >No reverse primer</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- .row -->
                  <div class="row justify-content-center">
                    <legend class="pt-2">Construct</legend>
                    <div class="py-2 text-left text-monospace small">
                      <span style="word-break: break-all">
                        <span class="text-light upstream-color">{{
                          snpSwap.boxUS.seq
                        }}</span>
                      </span>
                      <span style="word-break: break-all">
                        <span class="text-light downstream-color">{{
                          snpSwap.boxDS.seq
                        }}</span>
                      </span>
                    </div>
                  </div>
                  <!-- .row -->
                </div>
              </div>
            </div>
            <div class="col-1"></div>
          </div>
        </div>
      </div>
      <div
        v-if="snpSwaps && snpSwaps.length > 0"
        class="list-group-item d-flex mb-1 align-items-center"
      >
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="my-2 text-dark">
                Download computed primers for SNP Swapped genes
              </div>
              <button
                type="button"
                class="mx-2 my-2 btn btn-info dropdown-toggle"
                v-bind:disabled="downloadDisabledState == 'disabled'"
                data-flip="false"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span
                  v-if="processing == 'download'"
                  class="spinner-border spinner-border-sm text-light"
                ></span>
                Download
                <span class="caret"></span>
              </button>
              <div class="dropdown-menu">
                <a
                  class="dropdown-item"
                  href="#"
                  id="full-download"
                  v-on:click.prevent="doDownload('full')"
                >
                  Full
                  <span v-if="selectedDownload === 'full'">&#x2713;</span>
                </a>
                <a
                  class="dropdown-item"
                  href="#"
                  id="order-download"
                  v-on:click.prevent="doDownload('order')"
                >
                  Order
                  <span v-if="selectedDownload === 'order'">&#x2713;</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { OlabUtils } from "../olab/olabutils.js";
import { OlabBlock } from "../olab/olabblock.js";
import { OlabDownload } from "../olab/olabdownload.js";
// import { OlabSNPSwap } from "../olab/olabsnpswap.js";

export default {
  name: "snpswaplist",
  data: function() {
    return {
      snpSwap: null,
      position: 0,
      replaceWith: "",
      armSize: 0,
      pmr3Pad: "",
      pmr5Pad: "",
      positionValid: true,
      positionInvalidFeedback: "Position has to be an integer",
      armSizeValid: true,
      pmr3PadValid: true,
      pmr5PadValid: true,
      showSeqUS: false,
      showSeqDS: false,
      processing: "",
      selectedDownload: ""
    };
  },
  props: [
    "user",
    "dbInfo",
    "snpSwaps",
    "selectedCart",
    "statusObj",
    "errorObj"
  ],
  components: {
    FontAwesomeIcon
  },
  computed: {
    positionValidState: function() {
      if (this.snpSwap == null) {
        return "";
      }
      return this.positionValid ? "is-valid" : "is-invalid";
    },
    armSizeValidState: function() {
      if (this.snpSwap == null) {
        return "";
      }
      return this.armSizeValid ? "is-valid" : "is-invalid";
    },
    pmr3PadValidState: function() {
      if (this.snpSwap == null) {
        return "";
      }
      return this.pmr3PadValid ? "is-valid" : "is-invalid";
    },
    pmr5PadValidState: function() {
      if (this.snpSwap == null) {
        return "";
      }
      return this.pmr5PadValid ? "is-valid" : "is-invalid";
    },
    downloadDisabledState: function() {
      let state = "disabled";
      if (this.snpSwaps) {
        for (let i = 0; i < this.snpSwaps.length; i++) {
          const snp = this.snpSwaps[i];
          if (snp.isSwapped()) {
            state = "";
            break;
          }
        }
      }
      return state;
    }
  },
  filters: {
    convertCompToStrand: function(value) {
      // console.log("convertCompToStrand: value = " + value);
      return value === true ? "-" : "+";
    }
  },
  methods: {
    selectSNPSwap: function(snpSwap) {
      if (this.snpSwap === snpSwap) {
        this.snpSwap = null;
      } else {
        this.registerSNPSwap(snpSwap);
      }
    },
    registerSNPSwap: function(snpSwap) {
      OlabUtils.infoLog("registerSNPSwap: snpSwap = " + snpSwap);
      this.snpSwap = snpSwap;
      this.position = snpSwap.position;
      this.replaceWith = snpSwap.replaceWith;
      this.armSize = snpSwap.armSize;
      this.pmr3Pad = snpSwap.pmr3Pad;
      this.pmr5Pad = snpSwap.pmr5Pad;
      this.snpSwap = snpSwap;
      this.computeSequence(this.position, this.armSize);
    },
    computeSequence: async function(pos, armSz) {
      let start = pos;
      start = start - armSz;
      let range = armSz + armSz;
      // console.log("start = " + start + ", range = " + range);

      const gene = this.snpSwap.gene;
      const geneSeq = gene.sequence.seq;
      const geneSeqLen = geneSeq.length;
      // console.log("geneSeq =", geneSeq, ", geneSeqLen =", geneSeqLen);

      if (start >= 0 && start + range <= geneSeqLen) {
        const seqFrag = geneSeq.substr(start, range);
        this.snpSwap.setSeq(seqFrag);
        // console.log("seqFrag =", seqFrag, ", seqFrag.length =", seqFrag.length);
      } else {
        const res = await OlabBlock.computeSequence(
          gene,
          start,
          range,
          this.statusObj,
          this.errorObj
        );
        // console.log("SNPSwapList.computeSequence: res =", res);
        if (res.status === "success") {
          this.snpSwap.setSeq(res.seqFrag);
        } else {
          this.positionValid = false;
          this.positionInvalidFeedback = res.message;
        }
      }
    },
    showBoxSeq: function(box) {
      OlabUtils.infoLog("showBoxSeq: box = " + box);
      if (box == "US") {
        this.showSeqUS = this.showSeqUS ? false : true;
      } else if (box == "DS") {
        this.showSeqDS = this.showSeqDS ? false : true;
      }
    },
    doPreview: function(snpSwap) {
      OlabUtils.infoLog("doPreview: snpSwap = " + snpSwap);
    },
    getFullPrimers: function() {
      let resPmrs = [];
      for (let i = 0; i < this.snpSwaps.length; i++) {
        const snp = this.snpSwaps[i];
        if (snp.isSwapped()) {
          resPmrs = resPmrs.concat(snp.getFullPrimers());
        }
      }
      return resPmrs;
    },
    getOrderPrimers: function() {
      // Oligo Name, Scale, Purification, Sequence (5' -> 3')
      let resPmrs = [];
      for (let i = 0; i < this.snpSwaps.length; i++) {
        const snp = this.snpSwaps[i];
        if (snp.isSwapped()) {
          resPmrs = resPmrs.concat(snp.getOrderPrimers());
        }
      }
      return resPmrs;
    },
    doDownload(dlType) {
      this.selectedDownload = dlType;
      OlabUtils.infoLog(
        "doDownload ... selectedDownload = " + this.selectedDownload
      );
      this.processing = "download";
      setTimeout(() => {
        OlabUtils.infoLog("setTimeout to 10ms");
        this.downloadCSV(dlType);
        this.processing = "";
      }, 10);
    },
    downloadCSV(dlType) {
      // Prepare primers for export
      // Construct primer objects for output
      let fileName = this.selectedCart.name;
      let dlPrimerArr = [];
      if (dlType == "full") {
        fileName += "_Full_Primers.csv";
        dlPrimerArr = this.getFullPrimers();
      } else if (dlType == "order") {
        fileName += "_Order_Primers.csv";
        dlPrimerArr = this.getOrderPrimers();
      }

      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.style.visibility = "hidden";

      // const json = JSON.stringify(dlPrimerArr);
      // OlabUtils.infoLog("json = " + json);
      // const blob = new Blob([json], {type: "octet/stream"});

      let opt = "";
      const csv = OlabDownload.obj2csv(dlPrimerArr, opt);
      // OlabUtils.infoLog("csv = " + csv);
      const blob = new Blob([csv], { type: "octet/stream" });
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }
  },
  watch: {
    position: function() {
      OlabUtils.infoLog("position = " + this.position);
      if (this.snpSwap) {
        this.positionValid =
          this.position.toString().match(/^[-+]?(0|[1-9]\d*)$/) != null;
        if (this.positionValid) {
          const pos = this.position == "" ? 0 : parseInt(this.position);
          this.snpSwap.position = pos;
          this.computeSequence(this.snpSwap.position, this.snpSwap.armSize);
        } else {
          this.positionInvalidFeedback = "Position has to be an integer";
        }
      }
    },
    replaceWith: function() {
      OlabUtils.infoLog("replaceWith = " + this.replaceWith);
      if (this.snpSwap) {
        this.snpSwap.replaceWith = this.replaceWith;
        this.snpSwap.setSeq(this.snpSwap.blockSeq);
      }
    },
    armSize: function() {
      OlabUtils.infoLog("armSize = " + this.armSize);
      if (this.snpSwap) {
        this.armSizeValid =
          this.armSize.toString().match(/^[1-9]\d*$/) != null &&
          this.armSize >= 40 &&
          this.armSize <= 3000; // TODO: FIXME - Need to replace 3000
        if (this.armSizeValid) {
          let armSz = this.armSize == "" ? 0 : parseInt(this.armSize);

          // Guard armSize no less than 40
          this.snpSwap.armSize = armSz < 40 ? 40 : armSz;
          this.computeSequence(this.snpSwap.position, this.snpSwap.armSize);
        }
      }
    },
    pmr3Pad: function() {
      // OlabUtils.infoLog("pmr3Pad = " + this.pmr3Pad);
      if (this.snpSwap && this.pmr3Pad) {
        this.pmr3Pad = this.pmr3Pad.toUpperCase();
        // only keep a-zA-Z and remove other characters from seq
        this.pmr3Pad = this.pmr3Pad.replace(/[^a-zA-Z]/g, "");

        this.pmr3PadValid = !this.pmr3Pad.match(/[^acgt]/gi);
        if (this.pmr3PadValid) {
          this.snpSwap.setPmr3Pad(this.pmr3Pad);
        }
      }
    },
    pmr5Pad: function() {
      // OlabUtils.infoLog("pmr5Pad = " + this.pmr5Pad);
      if (this.snpSwap && this.pmr5Pad) {
        this.pmr5Pad = this.pmr5Pad.toUpperCase();
        // only keep a-zA-Z and remove other characters from seq
        this.pmr5Pad = this.pmr5Pad.replace(/[^a-zA-Z]/g, "");

        this.pmr5PadValid = !this.pmr5Pad.match(/[^acgt]/gi);
        if (this.pmr5PadValid) {
          this.snpSwap.setPmr5Pad(this.pmr5Pad);
        }
      }
    }
  }
};
</script>

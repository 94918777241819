<template>
  <div>
    <footer
      v-if="user"
      class="brand-color text-light d-flex justify-content-center"
    >
      <section class="footer-text py-5">
        Copyright &copy; 2024 by OLAB Bioscience, All rights reserved.
      </section>
    </footer>
  </div>
</template>

<script>
export default {
  name: "olabfooter",
  props: ["user"]
};
</script>

<style scoped>
.footer-text {
  font-size: 0.8em;
}
</style>

<template>
  <div class="calulatorface">
    <div class="container text-left mt-3">
      <h1>Solution Dilution Calculator</h1>
      <p>
        This bidirectional solution dilution calculator provides an easy-to-use
        approach to calculate dilution solution.
      </p>
      <p>
        You fill in any 3 of the 4 components and we will compute the remaining
        component.
      </p>
    </div>
    <div class="container">
      <div class="row justify-content-center">
        <div
          v-if="errorObj.message"
          class="col-12 alert alert-danger text-center px-3"
        >
          {{ errorObj.message }}
          <div class="text-center mt-2">
            <button
              type="button"
              class="btn btn-danger"
              v-on:click="closeError()"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="container text-left">
      <div class="row justify-content-center">
        <div class="col-10 col-md-6 col-lg-3 my-2">
          <div class="form-group">
            <legend class="text-center">Stock&nbsp;Concentration</legend>
            <input
              name="Stock_concentration"
              type="number"
              class="form-control"
              placeholder="Stock Concentration"
              v-bind:disabled="calculateField == 'calculateStockConcentration'"
              v-model="stockconcentration"
            />
            <div class="row">
              <div class="col-3"></div>
              <div class="col-9">
                <select
                  class="form-control text-light bg-secondary"
                  v-model="stockconcentrationfactor"
                >
                  <option value="1">molar (M)</option>
                  <option value="0.001">milimolar (mM)</option>
                  <option value="0.000001">micromolar (μM)</option>
                  <option value="0.000000001">nanomolar (nM)</option>
                  <option value="0.000000000001">picomolar (pM)</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="col-10 col-md-6 col-lg-3 my-2">
          <div class="form-group">
            <legend class="text-center">Stock&nbsp;Volume</legend>
            <input
              name="Stock_volume"
              type="number"
              class="form-control"
              placeholder="Stock Volume"
              v-bind:disabled="calculateField == 'calculateStockVolume'"
              v-model="stockvolume"
            />
            <div class="row">
              <div class="col-3"></div>
              <div class="col-9">
                <select
                  class="form-control text-light bg-secondary"
                  v-model="stockvolumefactor"
                >
                  <option value="1">liters (L)</option>
                  <option value="0.001">mililiters (mL)</option>
                  <option value="0.000001">microliters (μl)</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="col-10 col-md-6 col-lg-3 my-2">
          <div class="form-group">
            <legend class="text-center">Desired&nbsp;Volume</legend>
            <input
              name="Desired_volume"
              type="number"
              class="form-control"
              placeholder="Desired Volume"
              v-bind:disabled="calculateField == 'calculateDesiredVolume'"
              v-model="desiredvolume"
            />
            <div class="row">
              <div class="col-3"></div>
              <div class="col-9">
                <select
                  class="form-control text-light bg-secondary"
                  v-model="desiredvolumefactor"
                >
                  <option value="1">liters (L)</option>
                  <option value="0.001">mililiters (mL)</option>
                  <option value="0.000001">microliters (μl)</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="col-10 col-md-6 col-lg-3 my-2">
          <div class="form-group">
            <legend class="text-center">Desired&nbsp;Concentration</legend>
            <input
              name="Desired_concentration"
              type="number"
              class="form-control"
              placeholder="Desired Concentration"
              v-bind:disabled="
                calculateField == 'calculateDesiredConcentration'
              "
              v-model="desiredconcentration"
            />
            <div class="row">
              <div class="col-3"></div>
              <div class="col-9">
                <select
                  class="form-control text-light bg-secondary"
                  v-model="desiredconcentrationfactor"
                >
                  <option value="1">molar (M)</option>
                  <option value="0.001">milimolar (mM)</option>
                  <option value="0.000001">micromolar (μM)</option>
                  <option value="0.000000001">nanomolar (nM)</option>
                  <option value="0.000000000001">picomolar (pM)</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 text-center my-4">
          <button
            class="btn btn-brand mr-2"
            v-on:click="calculate"
            v-bind:disabled="show != 3"
          >
            Calculates
          </button>
          <button class="btn btn-brand mr-2" v-on:click="reset">Reset</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DilutionCalculator",
  data() {
    return {
      intVal: "",
      calculateField: "",
      stockconcentration: "",
      stockconcentrationfactor: 1,
      desiredvolume: "",
      desiredvolumefactor: 1,
      desiredconcentration: "",
      desiredconcentrationfactor: 1,
      stockvolume: "",
      stockvolumefactor: 1
    };
  },
  props: ["errorObj"],
  emits: ["setErrorMessage"],
  methods: {
    emitErrorMessage(errMsg) {
      this.$emit("setErrorMessage", errMsg);
    },
    closeError() {
      // console.log("Close Error Alert ...");
      this.$emit("setErrorMessage", null);
    },
    calculate() {
      let stockconcentration1 =
        this.stockconcentration == "" ? 0 : this.stockconcentration;
      let desiredvolume1 = this.desiredvolume == "" ? 0 : this.desiredvolume;
      let desiredconcentration1 =
        this.desiredconcentration == "" ? 0 : this.desiredconcentration;
      let stockvolume1 = this.stockvolume == "" ? 0 : this.stockvolume;
      console.log("run calculate. calculateField = ", this.calculateField);

      if (stockvolume1 == 0) {
        this.calculateField = "calculateStockVolume";
        this.calculateStockVolume();
      } else if (desiredvolume1 == 0) {
        this.calculateField = "calculateDesiredVolume";
        this.calculateDesiredVolume();
      } else if (desiredconcentration1 == 0) {
        this.calculateField = "calculateDesiredConcentration";
        this.calculateDesiredConcentration();
      } else if (stockconcentration1 == 0) {
        this.calculateField = "calculateStockConcentration";
        this.calculateStockConcentration();
      }
    },
    reset() {
      this.stockconcentration = "";
      this.stockvolume = "";
      this.desiredconcentration = "";
      this.desiredvolume = "";
      this.stockconcentrationfactor = 1;
      this.stockvolumefactor = 1;
      this.desiredconcentrationfactor = 1;
      this.desiredvolumefactor = 1;
      this.calculateField = "";
      console.log("reset");
    },
    calculateStockVolume() {
      if (
        this.stockconcentration > 0 &&
        this.stockconcentration * this.stockconcentrationfactor >=
          this.desiredconcentration * this.desiredconcentrationfactor
      ) {
        this.emitErrorMessage("");
        this.stockvolume =
          (this.desiredconcentration *
            this.desiredconcentrationfactor *
            this.desiredvolume *
            this.desiredvolumefactor) /
          (this.stockconcentration *
            this.stockconcentrationfactor *
            this.stockvolumefactor);
        console.log("calculateStockVolume function run");
      } else {
        this.emitErrorMessage(
          "stock concentration cannot be zero or less than desired concentration"
        );
      }
    },
    calculateDesiredVolume() {
      if (
        this.desiredconcentration > 0 &&
        this.stockconcentration * this.stockconcentrationfactor >=
          this.desiredconcentration * this.desiredconcentrationfactor
      ) {
        this.emitErrorMessage("");
        this.desiredvolume =
          (this.stockconcentration *
            this.stockconcentrationfactor *
            this.stockvolume *
            this.stockvolumefactor) /
          (this.desiredconcentration *
            this.desiredconcentrationfactor *
            this.desiredvolumefactor);
        console.log("calculateDesiredVolume function run");
      } else {
        this.emitErrorMessage(
          "desired concentration cannot be zero or more than stock concentration"
        );
      }
    },
    calculateStockConcentration() {
      if (
        this.stockvolume > 0 &&
        this.stockvolume * this.stockvolumefactor <=
          this.desiredvolume * this.desiredvolumefactor
      ) {
        this.emitErrorMessage("");
        this.stockconcentration =
          (this.desiredconcentration *
            this.desiredconcentrationfactor *
            this.desiredvolume *
            this.desiredvolumefactor) /
          (this.stockvolume *
            this.stockvolumefactor *
            this.stockconcentrationfactor);
        console.log("calculateStockConcentration function run");
      } else {
        this.emitErrorMessage(
          "stock volume cannot be zero or more than desired volume"
        );
      }
    },
    calculateDesiredConcentration() {
      if (
        this.desiredvolume > 0 &&
        this.desiredvolume * this.desiredvolumefactor >=
          this.stockvolume * this.stockvolumefactor
      ) {
        this.emitErrorMessage("");
        this.desiredconcentration =
          (this.stockconcentration *
            this.stockconcentrationfactor *
            this.stockvolume *
            this.stockvolumefactor) /
          (this.desiredvolume *
            this.desiredvolumefactor *
            this.desiredconcentrationfactor);
        console.log("calculateDesiredConcentration function run");
      } else {
        this.emitErrorMessage(
          "desired volume cannot be zero or less than stock volume"
        );
      }
    },
    recalculateField() {
      if (this.calculateField == "") {
        return;
      } else if (this.calculateField == "calculateStockVolume") {
        this.calculateStockVolume();
      } else if (this.calculateField == "calculateDesiredVolume") {
        this.calculateDesiredVolume();
      } else if (this.calculateField == "calculateStockConcentration") {
        this.calculateStockConcentration();
      } else if (this.calculateField == "calculateDesiredConcentration") {
        this.calculateDesiredConcentration();
      }
      console.log("recalculateField run");
      return {};
    }
  },
  computed: {
    show() {
      let show =
        (this.stockconcentration == "" || this.stockconcentration <= 0
          ? 0
          : 1) +
        (this.desiredvolume == "" || this.desiredvolume <= 0 ? 0 : 1) +
        (this.desiredconcentration == "" || this.desiredconcentration <= 0
          ? 0
          : 1) +
        (this.stockvolume == "" || this.stockvolume <= 0 ? 0 : 1);
      console.log("computed show run");
      return show;
    }
  },
  watch: {
    stockconcentration() {
      console.log("watch calling stockconcentration", this.stockconcentration);
      if (this.stockconcentration < 0) {
        this.stockconcentration = 0;
      }
      this.recalculateField();
    },
    stockconcentrationfactor() {
      console.log(
        "watch calling stockconcentractionfactor",
        this.stockconcentrationfactor
      );
      this.recalculateField();
    },
    stockvolume() {
      console.log("watch calling stockvolume", this.stockvolume);
      if (this.stockvolume < 0) {
        this.stockvolume = 0;
      }
      this.recalculateField();
    },
    stockvolumefactor() {
      console.log("watch calling stockvolumefactor", this.stockvolumefactor);
      this.recalculateField();
    },
    desiredconcentration() {
      console.log(
        "watch calling desiredconcentration",
        this.desiredconcentration
      );
      if (this.desiredconcentration < 0) {
        this.desiredconcentration = 0;
      }
      this.recalculateField();
    },
    desiredconcentrationfactor() {
      console.log(
        "watch calling desiredconcentractionfactor",
        this.desiredconcentrationfactor
      );
      this.recalculateField();
    },
    desiredvolume() {
      console.log("watch calling desiredvolume", this.desiredvolume);
      if (this.desiredvolume < 0) {
        this.desiredvolume = 0;
      }
      this.recalculateField();
    },
    desiredvolumefactor() {
      console.log(
        "watch calling desiredvolumefactor",
        this.desiredvolumefactor
      );
      this.recalculateField();
    }
  }
};
</script>

<style scoped>
.calulatorface {
  padding: 50px 0;
  background-color: #fdf2e9;
}
</style>

<template>
  <div>
    <div v-if="user" id="design">
      <nav class="navbar sticky-top navbar-light bg-light">
        <div class="navbar-brand text-truncate">
          <!-- Placeholder for user profile-->
          <button
            type="button"
            class="btn btn-outline-danger ml-1"
            data-toggle="modal"
            data-target="#userProfile"
            data-backdrop="static"
            data-keyboard="false"
          >
            <font-awesome-icon icon="id-badge" />
          </button>
          <button
            type="button"
            class="btn btn-outline-success ml-1"
            data-toggle="modal"
            data-target="#infoProfile"
            data-backdrop="static"
            data-keyboard="false"
          >
            <font-awesome-icon icon="info" />
          </button>
          <span class="h4 text-success text-capitalize ml-2"
            >{{ selectedTypeName }} System</span
          >
          <div class="mt-2 text-sm text-secondary text-truncate">
            <span v-if="info.boxID != ''">
              <span class="h4 text-success">Box {{ info.boxID }}: </span>
              <span class="h4" v-bind:class="info.boxBarStyle">{{
                info.boxBar
              }}</span>
              <span v-if="info.seq" class="mx-1 text-monospace">
                <span>{{ info.seq.substr(0, 40) }}</span>
                <span v-if="info.seq.length > 40">...</span>
              </span>
            </span>
          </div>
        </div>
        <div class="navbar-text ml-auto d-flex">
          <!-- Project profile button -->
          <button
            v-if="selectedProject"
            type="button"
            class="btn btn-outline-success ml-1"
            data-toggle="modal"
            data-target="#projectProfile"
            data-backdrop="static"
            data-keyboard="false"
          >
            <font-awesome-icon icon="project-diagram" />
          </button>

          <div v-if="selectedCart">
            <button
              type="button"
              class="btn btn-outline-success ml-1"
              data-toggle="modal"
              data-target="#cartProfile"
              data-backdrop="static"
              data-keyboard="false"
            >
              <span v-if="!cartIsFull" class="badge badge-pill badge-light">{{
                cartQty
              }}</span>
              <span v-if="cartIsFull" class="badge badge-pill badge-danger">{{
                cartQty
              }}</span>
              <font-awesome-icon icon="shopping-cart" />
            </button>
          </div>
        </div>
        <!-- .navbar-text ml-auto d-flex -->

        <div
          v-if="errorObj.message"
          class="col-12 alert alert-danger text-center px-3"
        >
          {{ errorObj.message }}
          <div class="text-center mt-2">
            <button
              type="button"
              class="btn btn-danger"
              v-on:click="closeError()"
            >
              OK
            </button>
          </div>
        </div>
        <div
          v-if="statusObj.message"
          class="col-12 alert alert-info fade show px-3"
        >
          <span>{{ statusObj.message }}</span>
          <span
            v-if="statusObj.progress"
            class="mx-2 spinner-grow"
            role="status"
          >
            <span class="sr-only">Status information</span>
          </span>
          <div class="text-center mt-2">
            <button
              type="button"
              class="btn btn-primary"
              v-on:click="closeStatus()"
            >
              OK
            </button>
          </div>
        </div>
      </nav>
      <!-- sticky top -->

      <!-- userProfile -->
      <OlabUserProfile :user="user" />

      <!-- infoProfile -->
      <OlabInfoProfile
        :user="user"
        :selectedTypeName="selectedTypeName"
        :dsgnBox="dsgnBox"
      />

      <!-- projectProfile -->
      <OlabProjectProfile :user="user" :project="selectedProject" />

      <!-- cartProfile -->
      <OlabCartProfile
        :user="user"
        :selectedProject="selectedProject"
        :selectedCart="selectedCart"
        :statusObj="statusObj"
        :errorObj="errorObj"
      />

      <article
        v-if="selectedCart"
        class="page-design text-center py-3"
        id="page-design"
      >
        <header class="page-design-header container mb-3">
          <div class="row justify-content-center">
            <div class="col-11">
              <h2>Gene Design</h2>
              <div
                v-if="resetMessage"
                class="col-12 alert alert-danger fade show px-3"
              >
                <span>{{ resetMessage }}</span>
                <div class="text-center mt-2">
                  <span class="mx-2">
                    <button
                      type="button"
                      class="btn btn-danger"
                      v-on:click="resetDesign(true)"
                    >
                      Yes
                    </button>
                  </span>
                  <span>
                    <button
                      type="button"
                      class="btn btn-secondary"
                      v-on:click="resetDesign(false)"
                    >
                      No
                    </button>
                  </span>
                </div>
              </div>
              <div class="form-group">
                <!-- <legend>Box info</legend> -->
                <div class="row">
                  <div class="col-3">
                    <!-- NOP (Spacer) -->
                  </div>
                  <div class="col-6">
                    <select
                      class="form-control"
                      id="numOfBoxes"
                      v-on:click="initComps()"
                      v-model="numOfBoxes"
                      v-bind:disabled="resetMessage"
                    >
                      <option disabled value="0">Select number of boxes</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                      <option value="4">Four</option>
                      <option value="5">Five</option>
                      <option value="6">Six</option>
                      <option value="7">Seven</option>
                      <option value="8">Eight</option>
                      <option value="9">Nine</option>
                      <option value="10">Ten</option>
                    </select>
                  </div>
                  <div class="col-3">
                    <button
                      type="button"
                      class="btn btn-outline-danger"
                      v-on:click="resetRequest()"
                      v-bind:disabled="resetMessage"
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>
              <!-- .form-group -->
              <div class="container">
                <div class="row justify-content-center">
                  <div v-for="(dsb, dsbIndex) in dsgnBoxes" :key="dsbIndex">
                    <div class="mb-2">
                      <button
                        class="btn mb-0 text-truncate"
                        v-bind:class="dsb.cstb.btn_color"
                        v-bind:style="boxStyle"
                        type="button"
                        v-on:click="registerDsgnBox(dsb)"
                        v-bind:disabled="resetMessage"
                      >
                        {{ dsb.cstb.display_name }}
                      </button>
                      <div
                        v-if="
                          dsb.item &&
                            dsb.item.comp &&
                            dsb.item.comp.olab_type == 'package'
                        "
                      >
                        <select
                          v-if="dsb.item.seq"
                          id="packageOption"
                          class="form-control text-truncate"
                          v-bind:style="boxStyle"
                          v-on:click="registerDsgnBox(dsb)"
                          v-model="dsb.cstb.package_op"
                          v-bind:disabled="resetMessage"
                        >
                          <option value="combination">Combination</option>
                          <option value="linear">Linear</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- .row -->
              </div>
              <!-- .container -->
              <!-- <div>
                <h2 class="text-warning mb-2">
                  <font-awesome-icon
                    class="ml-1 text-success"
                    icon="seedling"
                  ></font-awesome-icon>
                  Work in progress ...
                  <font-awesome-icon
                    class="ml-1 text-success"
                    icon="seedling"
                  ></font-awesome-icon>
                </h2>
              </div> -->
            </div>
          </div>
        </header>
        <section>
          <div class="accordion" id="accordion-design">
            <div class="card">
              <div class="card-header" id="heading-construct">
                <h2 class="mb-0">
                  <button
                    class="btn btn-info collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapse-construct"
                    aria-expanded="true"
                    aria-controls="collapse-construct"
                    v-bind:disabled="resetMessage"
                  >
                    Construct
                  </button>
                </h2>
              </div>
              <div
                id="collapse-construct"
                class="collapse show"
                aria-labelledby="heading-construct"
                data-parent="#accordion-design"
              >
                <div class="card-body">
                  <div class="container">
                    <div class="row justify-content-center">
                      <div class="col-10 col-md-6 col-lg-3">
                        <div class="form-group">
                          <legend>Box Type</legend>
                          <select
                            class="form-control"
                            id="selectedBoxType"
                            v-model="selectedBoxType"
                            v-bind:disabled="boxNotSelected"
                            v-on:click="prepBoxTypeSelect"
                          >
                            <option disabled value="">Assign box type</option>
                            <option v-if="availBoxType.us_ds" value="up_stream"
                              >Up Stream</option
                            >
                            <option v-if="availBoxType.rbs" value="rbs"
                              >RBS</option
                            >
                            <option
                              v-if="availBoxType.promoter"
                              value="promoter"
                              >Promoter</option
                            >
                            <option v-if="availBoxType.gene" value="gene"
                              >Gene</option
                            >
                            <option v-if="availBoxType.pgst" value="pgst"
                              >PGsT</option
                            >
                            <option
                              v-if="availBoxType.terminator"
                              value="terminator"
                              >Terminator</option
                            >
                            <option
                              v-if="availBoxType.us_ds"
                              value="down_stream"
                              >Down Stream</option
                            >
                            <option value="unset">Unset</option>
                          </select>
                        </div>
                        <!-- .form-group -->
                      </div>
                      <div class="col-10 col-md-6 col-lg-3">
                        <div class="form-group">
                          <legend>Sequence</legend>
                          <select
                            class="form-control"
                            id="selectedSeqDName"
                            v-model="selectedSeqDName"
                            v-bind:disabled="boxNotSelected"
                            v-on:click="prepSeqSelect"
                          >
                            <option disabled value=""
                              >Assign sequence to box</option
                            >
                            <option
                              v-for="(cit, index) in filteredCartItems"
                              :key="index"
                              >{{ cit.ct_id_name }}</option
                            >
                          </select>
                        </div>
                        <!-- .form-group -->
                        <div
                          v-if="
                            dsgnBox &&
                              dsgnBox.item &&
                              dsgnBox.item.seq &&
                              dsgnBox.item.comp.olab_type === 'strain' &&
                              dsgnBox.item.seq.isJoined()
                          "
                          class="float-left form-check"
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="includeIntronsCheck"
                            v-model="includeIntrons"
                          />
                          <label
                            class="small form-check-label"
                            for="includeIntronsCheck"
                          >
                            Include introns
                          </label>
                        </div>
                      </div>
                      <div class="col-10 col-md-5 col-lg-2">
                        <div class="form-group">
                          <legend>Start</legend>
                          <input
                            class="form-control"
                            type="text"
                            v-bind:class="selectedStartValidState"
                            v-bind:disabled="
                              selectedStartDisabledState === 'disabled'
                            "
                            id="start"
                            v-model="selectedStart"
                          />
                          <small id="start-help" class="form-text text-muted">{{
                            selectedStartHelp
                          }}</small>
                          <div class="invalid-feedback">
                            {{ selectedStartInvalidFeedback }}
                          </div>
                        </div>
                        <!-- .form-group -->
                      </div>
                      <div class="col-10 col-md-5 col-lg-2">
                        <div class="form-group">
                          <!-- TODO: Need to change respective range to length -->
                          <legend>Length</legend>
                          <input
                            class="form-control"
                            type="text"
                            v-bind:class="selectedRangeValidState"
                            v-bind:disabled="
                              selectedRangeDisabledState === 'disabled'
                            "
                            id="range"
                            v-model="selectedRange"
                          />
                          <small id="range-help" class="form-text text-muted">{{
                            selectedRangeHelp
                          }}</small>
                          <div class="invalid-feedback">
                            {{ selectedRangeInvalidFeedback }}
                          </div>
                        </div>
                        <!-- .form-group -->
                      </div>
                      <div
                        v-if="
                          dsgnBox &&
                            dsgnBox.item &&
                            dsgnBox.item.seq &&
                            dsgnBox.item.seq.olab_type == 'package' &&
                            dsgnBox.item.seq.package_seq_type == 'genome'
                        "
                        class="col-10 col-md-2 col-lg-2"
                      >
                        <div class="form-group">
                          <legend>Override</legend>
                          <div class="form-check pt-1">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              v-model="selectedOverride"
                            />
                          </div>
                        </div>
                        <!-- .form-group -->
                      </div>
                    </div>
                    <div v-if="dsgnBox" class="form-check mt-2">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="selectedBoxPmrOverride"
                        id="boxPmrOverrideID"
                      />
                      <label class="form-check-label h4" for="boxPmrOverrideID">
                        Box Primer Length
                      </label>
                    </div>
                    <div v-if="selectedBoxPmrOverride" class="mt-3">
                      <div class="row justify-content-center">
                        <div class="col-1"></div>
                        <div class="col-4">
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="radio"
                              id="primerFullRadio"
                              value="FULL"
                              v-model="primerSetting"
                            />
                            <label
                              class="form-check-label"
                              for="primerFullRadio"
                            >
                              <span>Full</span>
                              <span>
                                <font-awesome-icon icon="arrows-alt-h" />
                              </span>
                            </label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="radio"
                              id="primerFHalfRadio"
                              value="FWD_HALF"
                              v-model="primerSetting"
                            />
                            <label
                              class="form-check-label"
                              for="primerFHalfRadio"
                            >
                              <span>Half</span>
                              <span>
                                <font-awesome-icon
                                  icon="long-arrow-alt-right"
                                />
                              </span>
                            </label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="radio"
                              id="primerRHalfRadio"
                              value="REV_HALF"
                              v-model="primerSetting"
                            />
                            <label
                              class="form-check-label"
                              for="primerRHalfRadio"
                            >
                              <span>Half</span>
                              <span>
                                <font-awesome-icon icon="long-arrow-alt-left" />
                              </span>
                            </label>
                          </div>
                        </div>
                        <div class="col-6 d-flex justify-content-center">
                          <div class="row justify-content-center">
                            <div class="col-10 col-md-6 input-group">
                              <div class="input-group-prepend">
                                <label
                                  class="input-group-text"
                                  for="primer3pLength"
                                  >3'</label
                                >
                              </div>
                              <select
                                class="form-control"
                                id="primer3pLength"
                                v-model="primer3pLen"
                              >
                                <option
                                  v-for="(pmrLen, index) in primerLensOptArr"
                                  :key="index"
                                >
                                  {{ pmrLen }}
                                </option>
                              </select>
                            </div>
                            <div class="col-10 col-md-6 input-group">
                              <div class="input-group-prepend">
                                <label
                                  class="input-group-text"
                                  for="primer5pLength"
                                  >5'</label
                                >
                              </div>
                              <select
                                class="form-control"
                                id="primer5pLength"
                                v-model="primer5pLen"
                              >
                                <option
                                  v-for="(pmrLen, index) in primerLensOptArr"
                                  :key="index"
                                >
                                  {{ pmrLen }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-1"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <DesignBackbone
              v-if="dsgn"
              :user="user"
              :dbInfo="dbInfo"
              :selectedType="'design'"
              :selectedCart="selectedCart"
              :dsgn="dsgn"
              :backbone="backbone"
              :processing="processing"
              :resetMessage="resetMessage"
              :disabledState="disabledState"
              :statusObj="statusObj"
              :errorObj="errorObj"
            />
            <!-- TODO: Move into a separate component like backbone -->
            <div class="card">
              <div class="card-header" id="heading-preview">
                <h2 class="mb-0">
                  <button
                    class="btn btn-info collapsed"
                    v-bind:disabled="
                      resetMessage || disabledState.preview === 'disabled'
                    "
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapse-preview"
                    aria-expanded="false"
                    aria-controls="collapse-preview"
                    v-on:click="doPreview(false)"
                  >
                    <span
                      v-if="dsgn && processing === 'preview'"
                      class="spinner-border spinner-border-sm text-light"
                    ></span>
                    Preview
                  </button>
                </h2>
              </div>
              <div
                id="collapse-preview"
                class="collapse"
                aria-labelledby="heading-preview"
                data-parent="#accordion-design"
              >
                <div
                  v-if="previewReady && primerObjArr && primerObjArr.length > 0"
                  class="card-body"
                >
                  <div class="row justify-content-center">
                    <div class="col-12">
                      <div class="preview-primer">
                        <div class="card mb-2">
                          <div class="card-header text-dark h3">Assembly</div>
                          <div class="card-body">
                            <div class="form-group">
                              <legend>Length</legend>
                              <div class="row justify-content-center">
                                <div class="col-1"></div>
                                <div class="col-4">
                                  <div class="form-check form-check-inline">
                                    <input
                                      class="form-check-input"
                                      type="radio"
                                      id="primerFullRadio"
                                      value="FULL"
                                      v-model="primerSetting"
                                    />
                                    <label
                                      class="form-check-label"
                                      for="primerFullRadio"
                                    >
                                      <span>Full</span>
                                      <span>
                                        <font-awesome-icon
                                          icon="arrows-alt-h"
                                        />
                                      </span>
                                    </label>
                                  </div>
                                  <div class="form-check form-check-inline">
                                    <input
                                      class="form-check-input"
                                      type="radio"
                                      id="primerFHalfRadio"
                                      value="FWD_HALF"
                                      v-model="primerSetting"
                                    />
                                    <label
                                      class="form-check-label"
                                      for="primerFHalfRadio"
                                    >
                                      <span>Half</span>
                                      <span>
                                        <font-awesome-icon
                                          icon="long-arrow-alt-right"
                                        />
                                      </span>
                                    </label>
                                  </div>
                                  <div class="form-check form-check-inline">
                                    <input
                                      class="form-check-input"
                                      type="radio"
                                      id="primerRHalfRadio"
                                      value="REV_HALF"
                                      v-model="primerSetting"
                                    />
                                    <label
                                      class="form-check-label"
                                      for="primerRHalfRadio"
                                    >
                                      <span>Half</span>
                                      <span>
                                        <font-awesome-icon
                                          icon="long-arrow-alt-left"
                                        />
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div
                                  class="col-6 d-flex justify-content-center"
                                >
                                  <div class="row justify-content-center">
                                    <div class="col-10 col-md-6 input-group">
                                      <div class="input-group-prepend">
                                        <label
                                          class="input-group-text"
                                          for="primer3pLength"
                                          >3'</label
                                        >
                                      </div>
                                      <select
                                        class="form-control"
                                        id="primer3pLength"
                                        v-model="primer3pLen"
                                      >
                                        <option
                                          v-for="(pmrLen,
                                          index) in primerLensOptArr"
                                          :key="index"
                                        >
                                          {{ pmrLen }}
                                        </option>
                                      </select>
                                    </div>
                                    <div class="col-10 col-md-6 input-group">
                                      <div class="input-group-prepend">
                                        <label
                                          class="input-group-text"
                                          for="primer5pLength"
                                          >5'</label
                                        >
                                      </div>
                                      <select
                                        class="form-control"
                                        id="primer5pLength"
                                        v-model="primer5pLen"
                                      >
                                        <option
                                          v-for="(pmrLen,
                                          index) in primerLensOptArr"
                                          :key="index"
                                        >
                                          {{ pmrLen }}
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-1"></div>
                              </div>
                              <div class="row justify-content-center">
                                <div class="py-4">
                                  <div>
                                    {{ selectedCstOlabID }}
                                  </div>
                                  <div>
                                    <span
                                      v-for="(cstb, index) in primerObjArr[
                                        selectedCstIndex
                                      ].cstbs"
                                      :key="index"
                                    >
                                      <span
                                        v-bind:class="cstb.use_seq_as_color"
                                      >
                                        {{ cstb.display_name }}
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div v-if="dsgn && dsgn.package_present">
                                <legend class="pt-2">
                                  {{ primerObjArr.length }} constructs generated
                                </legend>
                                <div class="row py-1">
                                  <div
                                    class="col-10 col-md-8 mx-auto input-group"
                                  >
                                    <div class="input-group-prepend">
                                      <label
                                        class="input-group-text"
                                        for="selectConstruct"
                                        >Select a construct to verify</label
                                      >
                                    </div>
                                    <input
                                      id="selectConstruct"
                                      type="number"
                                      min="1"
                                      v-bind:max="primerObjArr.length"
                                      class="form-control"
                                      v-model="selectedCstNum"
                                    />
                                  </div>
                                </div>
                                <!-- construct -->
                                <OlabConstruct
                                  :cstObj="primerObjArr[selectedCstIndex]"
                                />
                              </div>
                              <div v-else>
                                <div
                                  class="py-3"
                                  v-for="(prObj, pindex) in primerObjArr"
                                  :key="pindex"
                                >
                                  <!-- construct -->
                                  <OlabConstruct :cstObj="prObj" />
                                </div>
                              </div>
                            </div>
                            <!-- .form-group -->
                            <div class="small text-secondary">
                              {{ meltTempNote }}
                            </div>
                          </div>
                          <!-- .card-body -->
                        </div>
                        <!-- .card -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <DesignDecision
              v-if="dsgn"
              :user="user"
              :dbInfo="dbInfo"
              :selectedType="'design'"
              :selectedProject="selectedProject"
              :selectedCart="selectedCart"
              :dsgn="dsgn"
              :dsgnBoxes="dsgnBoxes"
              :primerObjArr="primerObjArr"
              :backbone="backbone"
              :previewFirstPlasmid="prevewFirstPlasmidObj"
              :processing="processing"
              :resetMessage="resetMessage"
              :disabledState="disabledState"
              :statusObj="statusObj"
              :errorObj="errorObj"
              @doProcessingStatus="doProcessingStatus"
            />
          </div>
        </section>
      </article>
      <article v-else class="page-design text-center py-5" id="page-design">
        <header class="page-design-header container">
          <p class="h3">Design Module requires a selected Cart to proceed.</p>
          <p class="h5">
            <span>Please select a cart from the</span>
            <span>
              <router-link to="/compdb" v-if="user"> Component </router-link>
            </span>
            <span>module.</span>
          </p>
        </header>
      </article>
    </div>
  </div>
</template>

<script>
import DesignBackbone from "@/components/DesignBackbone";
import DesignDecision from "@/components/DesignDecision";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import OlabConstruct from "@/components/OlabConstruct";
import OlabUserProfile from "@/components/OlabUserProfile";
import OlabInfoProfile from "@/components/OlabInfoProfile";
import OlabProjectProfile from "@/components/OlabProjectProfile";
import OlabCartProfile from "@/components/OlabCartProfile";
import { OlabComp } from "../olab/olabcomp.js";
import { OlabDesign } from "../olab/olabdesign.js";
import { OlabDesignBox } from "../olab/olabdesignbox.js";
import { OlabBlock } from "../olab/olabblock.js";
import { OlabUtils } from "../olab/olabutils.js";
import { OlabAxios } from "../olab/olabaxios.js";
import $ from "jquery";

export default {
  name: "design",
  data: function() {
    return {
      selectedCstOlabID: null,
      selectedCstIndex: 0,
      selectedCstNum: 1,
      primerLensOptArr: [
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40
      ],
      boxStyle: {
        width: "80px",
        fontSize: "0.7em"
      },
      info: OlabDesignBox.info,
      boxNotSelected: true,
      availBoxType: OlabComp.availBoxType,
      filteredCartItems: null,
      cartItems: null,
      dsgn: null,
      // backbone object that will be configured in DesignBackbone
      backbone: {
        olab_id: "",
        type: "plasmid",
        res_enzyme1: "",
        res_enzyme2: "",
        cut_method: "single",
        cut_dir_cw: true,
        flip_box_design: false,
        enzyme_preserve: false,
        // ONLY For GIBSON case
        homl_seq: "",
        cut_site1: 0,
        homr_seq: "",
        cut_site2: 0
      },
      // This is an array of {cstb, item}
      dsgnBoxes: [],
      dsgnBox: null,
      lastDsgnBox: null,
      primer3pLen: 20,
      primer5pLen: 20,
      primerSetting: "FULL",
      primerObjArr: [],
      // Preview data (computed csts and pmrs)from backend
      previewResult: null,
      previewCstObj: null,
      prevewFirstPlasmidObj: null,
      previewReady: false,
      disabledState: {
        preview: "disabled",
        backbone: "disabled",
        decision: "disabled",
        save: "disabled",
        download: "disabled"
      },
      noSeqEval: false,
      noBoxTypeEval: false,
      noStartEval: false,
      noRangeEval: false,
      noOverrideEval: false,
      noIncludeIntronsEval: false,
      numOfBoxes: 0,
      lastNumOfBoxes: 0,
      selectedBox: 0,
      selectedBoxType: "",
      lastSelectedBoxType: null,
      selectedSeqDName: "",
      selectedSeq: "",
      includeIntrons: false,
      selectedStart: "--",
      selectedStartValid: true,
      selectedStartInvalidFeedback: "Start has to be an integer",
      selectedStartDisabledState: "disabled",
      selectedStartHelp: "",
      selectedRange: "--",
      selectedRangeValid: true,
      selectedRangeInvalidFeedback: "Length has to be a positive integer (>0)",
      selectedRangeDisabledState: "disabled",
      selectedRangeHelp: "",
      oldSelectedStart: "",
      oldSelectedRange: "",
      oldFailType: "",
      selectedOverride: false,
      selectedBoxPmrOverride: false,
      selectedTypeName: "Box Design",
      selectedDownload: "",
      processing: "",
      resetMessage: null,
      meltTempNote:
        "Note: Melting temperatures (Tm) above assume that the annealing occurs under the standard conditions of 50 nM primer, 50 mM Na+, and pH 7.0."
    };
  },
  components: {
    DesignBackbone,
    DesignDecision,
    FontAwesomeIcon,
    OlabConstruct,
    OlabUserProfile,
    OlabInfoProfile,
    OlabProjectProfile,
    OlabCartProfile
  },
  props: [
    "user",
    "dbInfo",
    "selectedProject",
    "selectedCart",
    "selectedPackage",
    "statusObj",
    "errorObj"
  ],
  mounted: async function() {
    // Reset status and error
    this.statusObj.message = null;
    this.statusObj.progress = false;
    this.errorObj.message = null;
    this.boxNotSelected = true;

    // console.log("Design mounted ... boxNotSelected =", this.boxNotSelected);
    OlabDesignBox.resetInfoBox();
    if (this.selectedCart) {
      this.dsgn = new OlabDesign();
      await this.dsgn.setCart(
        this.selectedCart,
        "box_design",
        this.statusObj,
        this.errorObj
      );
      // Set design config values
      this.primer3pLen = this.dsgn.pmr_3p_len;
      this.primer5pLen = this.dsgn.pmr_5p_len;
      this.primerSetting = this.dsgn.pmr_setting;
      this.loadCstbs(this.selectedCart.cstbs);
    } else {
      // selectedCart will be null temporarily when a user reload page
      // This reloadWorkaround is an attempt to catch such a situation
      OlabUtils.infoLog("selectedCart is null");
      this.reloadWorkaround();
    }
  },
  watch: {
    selectedBoxPmrOverride: function() {
      // console.log("selectedBoxPmrOverride =", this.selectedBoxPmrOverride);
      if (this.dsgnBox) {
        if (this.selectedBoxPmrOverride && !this.dsgnBox.getPmrConfig()) {
          this.dsgnBox.initPmrConfig();
        }
        if (this.selectedBoxPmrOverride) {
          this.primer3pLen = this.dsgnBox.getPmrConfig3pLen();
          this.primer5pLen = this.dsgnBox.getPmrConfig5pLen();
          this.primerSetting = this.dsgnBox.getPmrConfigSetting();
        }
        this.dsgnBox.setPmrConfigOverride(this.selectedBoxPmrOverride);
        this.saveCstbs();
      }
    },
    primer3pLen: function() {
      if (this.processing === "preview") {
        // console.log("busy: suppress multiple send requests ...");
        return;
      }
      // console.log("primer3pLen =", this.primer3pLen);
      if (this.dsgnBox) {
        if (this.selectedBoxPmrOverride) {
          this.dsgnBox.setPmrConfig3pLen(parseInt(this.primer3pLen));
          this.saveCstbs();
        }
      } else if (this.disabledState.preview !== "disabled") {
        this.dsgn.pmr_3p_len = parseInt(this.primer3pLen);
        this.doPreview(true);
      }
    },
    primer5pLen: function() {
      if (this.processing === "preview") {
        // console.log("busy: suppress multiple send requests ...");
        return;
      }
      // console.log("primer5pLen =", this.primer5pLen);
      if (this.dsgnBox) {
        if (this.selectedBoxPmrOverride) {
          this.dsgnBox.setPmrConfig5pLen(parseInt(this.primer5pLen));
          this.saveCstbs();
        }
      } else if (this.disabledState.preview !== "disabled") {
        this.dsgn.pmr_5p_len = parseInt(this.primer5pLen);
        this.doPreview(true);
      }
    },
    primerSetting: function() {
      if (this.processing === "preview") {
        // console.log("busy: suppress multiple send requests ...");
        return;
      }
      // console.log("this.primerSetting =", this.primerSetting);
      // console.log("this.disabledState.preview =", this.disabledState.preview);
      if (this.dsgnBox) {
        if (this.selectedBoxPmrOverride) {
          this.dsgnBox.setPmrConfigSetting(this.primerSetting);
          this.saveCstbs();
        }
      } else if (this.disabledState.preview !== "disabled") {
        this.dsgn.pmr_setting = this.primerSetting;
        this.doPreview(true);
      }
    },
    selectedOverride: function() {
      if (this.noOverrideEval) {
        this.noOverrideEval = false;
        return;
      }
      if (
        this.dsgnBox.item &&
        this.dsgnBox.item.comp.olab_type == "package" &&
        this.dsgnBox.item.seq &&
        this.dsgnBox.item.seq.package_seq_type == "genome"
      ) {
        OlabUtils.infoLog(
          "selectedOverride: override = " + this.selectedOverride
        );
        if (this.selectedOverride) {
          this.dsgnBox.item.seq.package_override = true;
          if (
            this.dsgnBox.cstb.start == "--" ||
            this.dsgnBox.cstb.range == "--"
          ) {
            this.dsgnBox.cstb.start = 0;
            this.dsgnBox.cstb.range = 100;
          }
          this.selectedStart = this.dsgnBox.cstb.start;
          this.selectedRange = this.dsgnBox.cstb.range;
        } else {
          this.dsgnBox.item.seq.package_override = false;
          this.dsgnBox.cstb.start = "--";
          this.dsgnBox.cstb.range = "--";
          // Restore seq back to gene's seq
          if (this.dsgnBox && this.dsgnBox.item) {
            OlabComp.restoreSeqGene(this.dsgnBox.item.seq);
          }
        }
        this.dsgnBox.cstb.package_override = this.dsgnBox.item.seq.package_override;
        this.setupStartAndRange(this.dsgnBox.item.comp, this.dsgnBox.item.seq);
      }
    },
    selectedStart: async function() {
      if (this.noStartEval) {
        this.noStartEval = false;
        return;
      }
      if (this.dsgnBox === null || this.dsgnBox.item === null) {
        // console.log("dsgnBox =", this.dsgnBox);
        // console.log("lastDsgnBox =", this.lastDsgnBox);
        // Reassign back dsgnBox from lastDsgnBox
        if (this.lastDsgnBox !== null) {
          this.dsgnBox = this.lastDsgnBox;
        } else {
          // console.log(
          //   "selectStart: dsgnBox or item === null, no start setting is needed"
          // );
          return;
        }
      }
      if (this.selectedSeq == "") {
        this.resetStartAndRange();
        return;
      }
      this.selectedStartValid =
        this.selectedStart.toString().match(/^[-+]?(0|[1-9]\d*)$/) != null;
      if (this.selectedStartValid) {
        let ciIndex = this.getCartItemIndex(this.selectedSeq);
        let item = this.cartItems[ciIndex];
        if (item != null) {
          this.dsgnBox.cstb.start = parseInt(this.selectedStart);

          if (this.dsgnBox.item.comp.olab_type == "strain") {
            await this.computeSequence(
              this.dsgnBox.item.seq,
              parseInt(this.selectedStart),
              parseInt(this.selectedRange),
              this.dsgnBox,
              null
            );
          } else if (this.dsgnBox.item.comp.olab_type == "library_sequence") {
            this.computeLibrarySequence(
              parseInt(this.selectedStart),
              parseInt(this.selectedRange)
            );
          } else if (
            this.dsgnBox.item.comp.olab_type == "package" &&
            this.dsgnBox.item.seq.package_seq_type == "genome"
          ) {
            if (this.dsgnBox.item.seq && this.dsgnBox.item.seq.comps) {
              const comps = this.dsgnBox.item.seq.comps;
              for (let i = 0; i < comps.length; i++) {
                // Do not need to await for this for-loop
                this.computeSequence(
                  comps[i].seqGene,
                  parseInt(this.selectedStart),
                  parseInt(this.selectedRange),
                  null,
                  comps[i]
                );
              }
            }
          }

          // Set box's states into info as status
          OlabDesignBox.setInfoBox(this.dsgnBox);
          this.saveCstbs();
        }
      } else {
        this.selectedStartInvalidFeedback = "Start has to be an integer";
      }
    },
    selectedRange: async function() {
      if (this.noRangeEval) {
        this.noRangeEval = false;
        return;
      }
      if (this.dsgnBox === null || this.dsgnBox.item === null) {
        // console.log("dsgnBox =", this.dsgnBox);
        // console.log("lastDsgnBox =", this.lastDsgnBox);
        // Reassign back dsgnBox from lastDsgnBox
        if (this.lastDsgnBox !== null) {
          this.dsgnBox = this.lastDsgnBox;
        } else {
          console.log(
            "selectedRange: dsgnBox or item === null, no range setting is needed"
          );
          return;
        }
      }
      if (this.selectedSeq == "") {
        this.resetStartAndRange();
        return;
      }
      // console.log("selectedRange = " + this.selectedRange);
      this.selectedRangeValid =
        this.selectedRange.toString().match(/^[1-9]\d*$/) != null;
      // console.log("selectedRangeValid = " + this.selectedRangeValid);

      const maxRangeLimit = 20000;
      if (parseInt(this.selectedRange) > maxRangeLimit) {
        // console.log(
        //   `Length exceeded max limit. CLAMP to max limit: ${maxRangeLimit}`
        // );
        this.selectedRange = maxRangeLimit;
      }

      if (this.selectedRangeValid) {
        let ciIndex = this.getCartItemIndex(this.selectedSeq);
        let item = this.cartItems[ciIndex];
        if (item != null) {
          this.dsgnBox.cstb.range = parseInt(this.selectedRange);

          if (this.dsgnBox.item.comp.olab_type == "strain") {
            await this.computeSequence(
              this.dsgnBox.item.seq,
              parseInt(this.selectedStart),
              parseInt(this.selectedRange),
              this.dsgnBox,
              null
            );
          } else if (this.dsgnBox.item.comp.olab_type == "library_sequence") {
            this.computeLibrarySequence(
              parseInt(this.selectedStart),
              parseInt(this.selectedRange)
            );
          } else if (
            this.dsgnBox.item.comp.olab_type == "package" &&
            this.dsgnBox.item.seq.package_seq_type == "genome"
          ) {
            if (this.dsgnBox.item.seq && this.dsgnBox.item.seq.comps) {
              const comps = this.dsgnBox.item.seq.comps;
              for (let i = 0; i < comps.length; i++) {
                // Do not need to await for this for-loop
                this.computeSequence(
                  comps[i].seqGene,
                  parseInt(this.selectedStart),
                  parseInt(this.selectedRange),
                  null,
                  comps[i]
                );
              }
            }
          } else {
            // console.log("selectedRange: XXXX found none");
          }

          // Set box's states into info as status
          OlabDesignBox.setInfoBox(this.dsgnBox);
          this.saveCstbs();
        }
      } else {
        this.selectedRangeInvalidFeedback =
          "Length has to be a positive integer (>0)";
      }
    },
    includeIntrons: function() {
      // console.log("watch: includeIntrons =", this.includeIntrons);
      if (this.noIncludeIntronsEval || this.dsgnBox.item === null) {
        this.noIncludeIntronsEval = false;
        return;
      }
      if (this.dsgnBox.item.seq.olab_type !== "gene") {
        // Guard against logic bug. ONLY Make sense if this is a gene type
        this.errorObj.message = "Error: Selected component is not a gene";
        return;
      }
      this.dsgnBox.cstb.include_introns = this.includeIntrons;
      // Compute the start and range of gene's sequence
      // console.log("this.dsgnBox =", this.dsgnBox);
      // console.log("this.dsgnBox.item.seq =", this.dsgnBox.item.seq);
      if (this.includeIntrons) {
        this.selectedStart = 0;
        this.selectedRange = this.dsgnBox.item.seq.rangeIncludeIntrons();
      } else {
        this.selectedStart = 0;
        this.selectedRange = this.dsgnBox.item.seq.sequence.seq.length;
      }
      // No need to saveCstbs() because selectedStart or selectRange will handle it.
    },
    selectedSeqDName: function() {
      // console.log(
      //   "selectedSeqDName = " +
      //     this.selectedSeqDName +
      //     ", selectedBox = " +
      //     this.selectedBox
      // );

      if (this.noSeqEval) {
        this.noSeqEval = false;
        return;
      }
      const isVisible = $("#collapse-preview").is(":visible");
      // console.log("selectedSeqDName: isVisible = " + isVisible);
      if (isVisible) {
        $("#collapse-preview").collapse("toggle");
      }

      if (this.selectedBoxType == "" && this.selectedSeqDName == "") {
        // console.log("** New or unset dsgnBox selected **");
        return;
      }
      if (this.selectedSeqDName == "") {
        this.resetStartAndRange();
        return;
      }
      this.computeSelectedSeq(this.selectedSeqDName);
    },
    selectedBoxType: function() {
      // console.log("XXXX (1) selectedBoxType = ", this.selectedBoxType);
      if (this.noBoxTypeEval) {
        this.noBoxTypeEval = false;
        return;
      }
      // console.log("XXXX (2) selectedBoxType = ", this.selectedBoxType);
      const isVisible = $("#collapse-preview").is(":visible");
      // console.log("selectedBoxType: isVisible = " + isVisible);
      if (isVisible) {
        $("#collapse-preview").collapse("toggle");
      }
      if (this.selectedBoxType === this.lastSelectedBoxType) {
        // console.log("return because selectedBoxType === lastSelectedBoxType");
        return;
      }

      this.dsgnBox.setBoxType(this.selectedBoxType, true);
      // Reset primerObjArr since box may have changed
      this.primerObjArr = [];
      // Reset seq input
      this.selectedSeqDName = "";
      this.selectedSeq = "";
      // Reset start and range inputs
      this.setupStartAndRange(null, null);

      // Cleanup and return item to cart
      if (this.selectedBoxType == "unset") {
        // console.log("unset === Clean up and return");
        this.dsgnBox.unsetItem(this.cartItems);
        this.filteredCartItems = [];
        this.saveCstbs();
        this.dsgnBoxes[this.dsgnBox.cstb.box_id - 1].cstb.btn_color =
          "selected-color";
        this.lastSelectedBoxType = null;
        return;
      }

      // Set selectable sequences
      this.filteredCartItems = OlabComp.filterCartItems(
        this.dsgnBox.item,
        this.selectedBoxType,
        this.cartItems
      );
      this.lastSelectedBoxType = this.selectedBoxType;
    },
    numOfBoxes: function() {
      let remainingBoxes = this.numOfBoxes - this.dsgnBoxes.length;
      if (remainingBoxes == 0) {
        // console.log(
        //   "numOfBoxes: remaining Boxes = ",
        //   remainingBoxes,
        //   ", numOfBoxes = ",
        //   this.numOfBoxes
        // );
        return;
      } else if (remainingBoxes < 0) {
        // console.log(
        //   "Report warning: remaining Boxes = ",
        //     remainingBoxes,
        //     ", numOfBoxes = ",
        //     this.numOfBoxes
        // );
        if (this.dsgnBoxes[this.numOfBoxes].cstb.configured) {
          // console.log("XXXX Report error: restored ....");
          this.numOfBoxes = this.lastNumOfBoxes;
          this.setBoxStyle(this.numOfBoxes);
          return;
        }
        // Resize unused boxes
        let tmpDsBxes = [];
        for (let i = 0; i < this.numOfBoxes; i++) {
          tmpDsBxes.push(this.dsgnBoxes[i]);
        }
        this.dsgnBoxes = tmpDsBxes;
        this.lastNumOfBoxes = this.numOfBoxes;
        this.setBoxStyle(this.numOfBoxes);
        this.saveCstbs();
        this.dsgnBox = this.dsgnBoxes[0];
        this.selectedBox = this.dsgnBox.cstb.box_id;
        OlabDesignBox.setInfoBox(this.dsgnBox);
        return;
      }
      this.doFillDsgnBoxes(this.dsgnBoxes.length, this.numOfBoxes, null);
      // console.log("this.dsgnBoxes = ", this.dsgnBoxes);
      this.dsgnBox = this.dsgnBoxes[0];
      this.lastNumOfBoxes = this.numOfBoxes;
      this.setBoxStyle(this.numOfBoxes);
      this.saveCstbs();
    },
    selectedCstNum: function() {
      let newCstNum = this.selectedCstNum;
      if (this.selectedCstNum < 1) {
        newCstNum = 1;
      } else if (this.selectedCstNum > this.primerObjArr.length) {
        newCstNum = this.primerObjArr.length;
      }
      this.selectedCstIndex = newCstNum - 1;
      this.selectedCstOlabID = this.primerObjArr[this.selectedCstIndex].olab_id;
      this.selectedCstNum = newCstNum;
    },
    previewCstObj: function() {
      // console.log("watch: previewCstObj =", this.previewCstObj);
      this.updateFirstConstruct();
    },
    primerObjArr: function() {
      // console.log("watch: primerObjArr =", this.primerObjArr);
      this.updateFirstConstruct();
    }
  },
  computed: {
    cartQty: function() {
      let qty = 0;
      if (this.selectedCart != null && this.selectedCart.comps != null) {
        for (let idx in this.selectedCart.comps) {
          qty = qty + this.selectedCart.comps[idx].qty;
        }
      }
      return qty;
    },
    cartIsFull: function() {
      return this.cartQty >= OlabUtils.MAX_ITEMS_PER_CART ? true : false;
    },
    selectedStartValidState: function() {
      if (
        this.dsgnBox == null ||
        !this.dsgnBox.cstb.configured ||
        this.selectedSeq == ""
      ) {
        return "";
      }
      return this.selectedStartValid ? "is-valid" : "is-invalid";
    },
    selectedRangeValidState: function() {
      if (
        this.dsgnBox == null ||
        !this.dsgnBox.cstb.configured ||
        this.selectedSeq == ""
      ) {
        return "";
      }
      return this.selectedRangeValid ? "is-valid" : "is-invalid";
    }
  },
  methods: {
    updateFirstConstruct() {
      if (this.primerObjArr.length > 0 && this.previewCstObj) {
        // console.log("primerObjArr.length =", this.primerObjArr.length);
        this.primerObjArr[0].forwards = this.previewCstObj.forwards;
        this.primerObjArr[0].backwards = this.previewCstObj.backwards;
        this.primerObjArr[0].cstbs = this.previewCstObj.cstbs;
        this.disabledState.decision = "";
      } else {
        // console.log("Not Yet Ready");
        this.disabledState.decision = "disabled";
      }
    },
    delayInSecond: function(sec) {
      return new Promise(resolve => setTimeout(resolve, sec * 1000));
    },
    reloadWorkaround: async function() {
      // console.log("reloadWorkaround: zero seconds");
      await this.delayInSecond(1);
      // console.log("reloadWorkaround: two seconds");
      if (this.selectedCart) {
        // FIX ME: push to Home to reset states for now
        this.$router.push("/");
      }
    },
    resetRequest: function() {
      this.resetMessage =
        "Are you sure you want to reset existing design configuration?";
    },
    resetDesign: function(confirmed) {
      if (confirmed) {
        // console.log("reset design: dsgnBoxes =", this.dsgnBoxes);
        // console.log("dsgnBox =", this.dsgnBox);
        this.dsgnBoxes.forEach(dBox => {
          dBox.unsetItem(this.cartItems);
        });

        // A force reset of cart's comps
        // (in case of there is an error in accounting)
        const cartComps = this.selectedCart.comps;
        if (cartComps && cartComps.length > 0) {
          cartComps.forEach(c => {
            c.availabe_count = c.qty;
            c.used_count = 0;
          });
        }

        this.filteredCartItems = [];
        this.saveCstbs();
        this.lastSelectedBoxType = null;

        // Reset states
        this.selectedStart = "--";
        this.selectedStartDisabledState = "disabled";
        this.selectedRange = "--";
        this.selectedRangeDisabledState = "disabled";
        this.oldSelectedStart = "";
        this.oldSelectedRange = "";
        this.boxNotSelected = true;
        OlabDesignBox.resetInfoBox();
        this.dsgnBox = null;
        this.lastDsgnBox = null;
      }
      this.resetMessage = null;
    },
    prepBoxTypeSelect: function() {
      // Reassign dsgnBox if it is null
      this.dsgnBox = this.dsgnBox === null ? this.lastDsgnBox : this.dsgnBox;
      if (this.dsgnBox === null) {
        return;
      }
      // Set selectable box types
      OlabComp.computeAvailBoxType(this.dsgnBox.cstb.box_type, this.cartItems);
    },
    prepSeqSelect: function() {
      // Reassign dsgnBox if it is null
      this.dsgnBox = this.dsgnBox === null ? this.lastDsgnBox : this.dsgnBox;
      if (this.dsgnBox === null) {
        return;
      }
      // Set selectable box types
      OlabComp.computeAvailBoxType(this.dsgnBox.cstb.box_type, this.cartItems);
      // Set selectable sequences
      this.filteredCartItems = OlabComp.filterCartItems(
        this.dsgnBox.item,
        this.selectedBoxType,
        this.cartItems
      );
    },
    setBoxStyle: function(numBoxes) {
      if (numBoxes < 4) {
        // 3 or less
        this.boxStyle = {
          width: "180px",
          fontSize: "1em"
        };
      } else if (numBoxes < 6) {
        // between 4 and 5
        this.boxStyle = {
          width: "150px",
          fontSize: "0.9em"
        };
      } else if (numBoxes < 8) {
        // between 6 and 7
        this.boxStyle = {
          width: "110px",
          fontSize: "0.8em"
        };
      } else if (numBoxes < 10) {
        // between 8 and 9
        this.boxStyle = {
          width: "90px",
          fontSize: "0.7em"
        };
      } else {
        // 10
        this.boxStyle = {
          width: "80px",
          fontSize: "0.7em"
        };
      }
    },
    validateDsgnBoxes: function() {
      let result = false;
      let index = 0;
      let firstPass = false;
      let unconfigAftPass = false;
      let breakInConfig = false;
      // Only preview properly configured construct
      this.dsgnBoxes.forEach(dsb => {
        if (dsb.cstb.configured) {
          if (index === 0) {
            firstPass = true;
          } else if (unconfigAftPass) {
            breakInConfig = true;
          }
        } else {
          if (firstPass) {
            unconfigAftPass = true;
          }
        }
        index++;
      });
      if (firstPass && !breakInConfig) {
        result = true;
      }

      const isVisible = $("#collapse-preview").is(":visible");
      // console.log("validateDsgnBoxes: isVisible = " + isVisible);

      if (!result && isVisible) {
        $("#collapse-preview").collapse("toggle");
      }
      this.disabledState.backbone = result ? "" : "disabled";

      // Reset primerObjArr since box may have changed
      this.primerObjArr = [];
    },
    loadCstbs: async function(cstbs) {
      if (!cstbs) return;
      // Reset dsgnBox and dsgnBoxes
      this.dsgnBoxes = [];
      this.dsgnBox = null;
      await this.initComps();
      this.doFillDsgnBoxes(0, cstbs.length, cstbs);
      this.numOfBoxes = cstbs.length;
      this.setBoxStyle(this.numOfBoxes);
      this.validateDsgnBoxes();
      // Special handling for genome package
      await this.checkAndProcessGP(this.dsgnBoxes);
    },
    checkAndProcessGP: async function(dsgnBoxes) {
      if (dsgnBoxes) {
        for (let j = 0; j < dsgnBoxes.length; j++) {
          // Only do for genome package and override is true
          const dsb = dsgnBoxes[j];
          // console.log(
          //   "dsb.cstb.configured = ",
          //     dsb.cstb.configured,
          //     ", dsb.cstb.package_override = ",
          //     dsb.cstb.package_override
          // );

          if (dsb.cstb.configured && dsb.cstb.package_override) {
            if (dsb.item.seq && dsb.item.seq.comps) {
              const comps = dsb.item.seq.comps;
              for (let i = 0; i < comps.length; i++) {
                const st = dsb.cstb.start;
                const rg = dsb.cstb.range;
                await this.computeSequence(
                  comps[i].seqGene,
                  st,
                  rg,
                  null,
                  comps[i]
                );
              }
            } else {
              // console.log("NOT READY (2) = checkAndProcessGP ....");
            }
          }
        }
      } else {
        // console.log("NOT READY (1) = checkAndProcessGP ....");
      }
      // console.log("checkAndProcessGP .... done!!!");
    },
    saveCstbs: function() {
      // console.log("saveCstbs: dsgnBoxes =", this.dsgnBoxes);
      // Clear cstbs array
      this.selectedCart.cstbs.length = 0;
      for (let i = 0; i < this.dsgnBoxes.length; i++) {
        const cstb = this.dsgnBoxes[i].cstb;
        if (!cstb.configured) {
          cstb.btn_color = "unset-color";
        }
        this.selectedCart.cstbs.push(cstb);
      }
      this.validateDsgnBoxes();
      OlabAxios.saveCart(this.selectedCart, this.statusObj, this.errorObj);
    },
    initComps: async function() {
      // Only do this once when cartItems is null
      if (this.cartItems === null) {
        if (this.selectedCart && this.selectedCart.comps) {
          const ccArr = [];
          const comps = this.selectedCart.comps;
          for (let i = 0; i < comps.length; i++) {
            const cp = comps[i];
            // TODO: Redesign this class and rename as OlabBoxComp
            const cc = new OlabComp(cp);
            // Do not do await to block the init operation.
            // load sequence can be done in parallel.
            cc.loadSeq();
            // Create unique id for item selection in cart
            cc.ct_id_name = `${cc.display_name} - ${cc.comp.olab_id}`;
            // console.log("initComps: ct_id_name = ", cc.ct_id_name);
            ccArr.push(cc);
          }
          this.cartItems = ccArr;
          // console.log("cartItems.length = ", this.cartItems.length);
        }
      } else {
        // console.log(
        //   "Nothing done. CartItems was initialized. : length = ",
        //     this.cartItems.length
        // );
      }
    },
    getSeqLength: function(item) {
      let len = 0;
      if (item) {
        len = item.getSeqLength();
      }
      return len;
    },
    getCartItemIndex: function(seqItem) {
      // console.log("getCartItemIndex: seqItem = ", seqItem);
      let index = 0;
      if (seqItem && this.cartItems) {
        const id = seqItem;
        // console.log("getCartItemIndex: id = ", id);
        for (let i = 0; i < this.cartItems.length; i++) {
          if (this.cartItems[i].comp.olab_id == id) {
            index = i;
            break;
          }
        }
      }
      // console.log("**** getCartItemIndex: index = ", index);
      return index;
    },
    resetStartAndRange: function() {
      this.setupStartAndRange(null, null);
    },
    computeSelectedSeq: function(dName) {
      if (dName && this.cartItems) {
        for (let i = 0; i < this.cartItems.length; i++) {
          if (this.cartItems[i].ct_id_name === dName) {
            this.selectedSeq = this.cartItems[i].comp.olab_id;
            break;
          }
        }
      }

      let ciIndex = this.getCartItemIndex(this.selectedSeq);
      this.dsgnBox.updateCartItem(ciIndex, this.cartItems);

      switch (this.dsgnBox.item.comp.olab_type) {
        case "strain" /* gene */:
          // console.log(
          //   "selectedSeq: gene type: Extracted seq = ",
          //   this.dsgnBox.item.seq
          // );
          // Set start to the begin of selected sequence
          this.selectedStart = 0;
          // console.log("selectedStart = ", this.selectedStart);

          // Set range to the length of selected sequence
          this.selectedRange = this.getSeqLength(this.dsgnBox.item);
          // console.log("selectedRange = ", this.selectedRange);

          this.computeSequence(
            this.dsgnBox.item.seq,
            parseInt(this.selectedStart),
            parseInt(this.selectedRange),
            this.dsgnBox,
            null
          );
          this.setupStartAndRange(this.dsgnBox.item.comp, null);
          break;
        case "library_sequence" /* library_sequence */:
          OlabUtils.infoLog(
            "selectedSeq: library_sequence type: Extracted seq olab id = " +
              this.dsgnBox.item.seq.olab_id
          );
          // Set start to the begin of selected sequence
          this.selectedStart = 0;
          OlabUtils.infoLog("selectedStart = " + this.selectedStart);

          // Set range to the length of selected sequence
          this.selectedRange = this.getSeqLength(this.dsgnBox.item);
          OlabUtils.infoLog("selectedRange = " + this.selectedRange);

          this.dsgnBox.setSeq(this.dsgnBox.item.seq.seq);
          this.setupStartAndRange(this.dsgnBox.item.comp, null);
          break;
        case "package" /* package */:
          OlabUtils.infoLog(
            "selectedSeq: package type: Extracted seq olab id = " +
              this.dsgnBox.item.seq.olab_id +
              " package_seq_type = " +
              this.dsgnBox.item.seq.package_seq_type
          );

          this.dsgnBox.setSeq(this.dsgnBox.item.seq.seq);

          if (this.dsgnBox.item.seq.package_seq_type == "genome") {
            OlabUtils.infoLog("genome case");
            // Reset package_override
            this.selectedOverride = false;
            this.dsgnBox.cstb.package_override = false;
            this.dsgnBox.item.seq.package_override = false;
            this.dsgnBox.cstb.start = "--";
            this.dsgnBox.cstb.range = "--";
            // Restore seq back to gene's seq
            if (this.dsgnBox && this.dsgnBox.item) {
              OlabComp.restoreSeqGene(this.dsgnBox.item.seq);
            }
          } else {
            // library_sequence case
            OlabUtils.infoLog("library_sequence case");
          }
          this.setupStartAndRange(
            this.dsgnBox.item.comp,
            this.dsgnBox.item.seq
          );
          break;
        default:
          OlabUtils.infoLog(
            "selectedSeq: unknown type: Extracted seq id = " +
              this.dsgnBox.item.comp.olab_id +
              ", olab_type = " +
              this.dsgnBox.item.comp.olab_type +
              "****"
          );
          // Error!
          this.dsgnBox.setSeq("Unknown sequence type");

          // Disable setting for start and range
          this.selectedStartDisabledState = "disabled";
          this.selectedRangeDisabledState = "disabled";
      }

      // Set box's states into info as status
      OlabDesignBox.setInfoBox(this.dsgnBox);
      this.saveCstbs();
    },
    computeLibrarySequence: function(start, range) {
      // console.log("start = ", start, ", range = ", range);
      // console.log("seq = ", this.dsgnBox.item.seq.seq);
      let subSeq = this.dsgnBox.item.seq.seq.substr(start, range);
      // console.log("subSeq = ", subSeq);

      this.dsgnBox.setSeq(subSeq);
    },
    computeSequence: async function(seq, start, range, dsb, cmp) {
      // console.log("seq = ", seq, "start = ", start, ", range = ", range);
      // console.log("dsb = ", dsb, ", cmp = ", cmp);
      const boxSeq = seq.sequence.seq;
      const boxSeqLen = boxSeq.length;
      // console.log("boxSeq = ", boxSeq, ", boxSeqLen = ", boxSeqLen);
      if (!this.includeIntrons && start >= 0 && start + range <= boxSeqLen) {
        // Handle simple case where seq can be extracted from boxSeq
        const seqFrag = boxSeq.substr(start, range);
        // console.log("Case 1: seqFrag = ", seqFrag);
        if (dsb) {
          dsb.setSeq(seqFrag);
        } else {
          cmp.seq = seqFrag;
        }

        // Store the old values for recovery use
        this.oldSelectedStart = this.selectedStart;
        this.oldSelectedRange = this.selectedRange;
      } else {
        // console.log("Case 2: calling OlabBlock.computeSequence");
        const res = await OlabBlock.computeSequence(
          seq,
          start,
          range,
          this.statusObj,
          this.errorObj
        );
        // console.log("Case 2: Done OlabBlock.computeSequence: res =", res);
        if (res.status === "success") {
          if (dsb) {
            dsb.setSeq(res.seqFrag);
          } else if (cmp) {
            cmp.seq = res.seqFrag;
          }

          // Store the old values for recovery use
          this.oldSelectedStart = this.selectedStart;
          this.oldSelectedRange = this.selectedRange;
        } else {
          if (res.failType === "start") {
            this.selectedStartValid = false;
            this.selectedStartInvalidFeedback = "Start exceeds contig's range";
          } else {
            // "range" case
            this.selectedRangeValid = false;
            this.selectedRangeInvalidFeedback = "Length exceeds contig's range";
          }
          this.errorObj.message = res.message;
          this.oldFailType = res.failType;
        }
      }
    },
    doFillDsgnBoxes: function(start, end, cstbs) {
      for (let i = start; i < end; i++) {
        const dsBox = new OlabDesignBox(i + 1);
        if (cstbs && cstbs.length > i) {
          // console.log("cstbs[", i, "].configured", cstbs[i].configured);
          if (cstbs[i] && cstbs[i].configured) {
            dsBox.cstb = cstbs[i];
          }

          // Set dsBox's item
          dsBox.setItem(this.cartItems);
        }
        this.dsgnBoxes.push(dsBox);
        // console.log("doFillDsgnBoxes: dsBox ", dsBox);
      }
    },
    setupStartAndRange: function(comp, itemSeq) {
      let olabType = "";
      if (comp) {
        olabType = comp.olab_type;
      }
      if (olabType == "") {
        // Reset and disable start and range
        this.selectedStartDisabledState = "disabled";
        this.selectedStartHelp = "";
        this.selectedRangeDisabledState = "disabled";
        this.selectedRangeHelp = "";
        if (this.selectedStart != "--") {
          this.selectedStart = "--";
          this.noStartEval = true;
        }
        if (this.selectedRange != "--") {
          this.selectedRange = "--";
          this.noRangeEval = true;
        }
      } else if (olabType == "strain") {
        this.selectedStartDisabledState = "";
        this.selectedStartHelp = "0 is the begining of the gene sequence";
        this.selectedRangeDisabledState = "";
        this.selectedRangeHelp = "Length is limited to 20000.";
      } else if (olabType == "library_sequence") {
        // Disable setting for start and range
        this.selectedStartDisabledState = "disabled";
        this.selectedRangeDisabledState = "disabled";
        this.selectedStartHelp = "Setting is N/A for Library sequence";
        this.selectedRangeHelp = "Setting is N/A for Library sequence";
      } else if (
        olabType == "package" &&
        itemSeq &&
        itemSeq.package_seq_type == "library_sequence"
      ) {
        // Disable setting for start and range
        this.selectedStartDisabledState = "disabled";
        this.selectedRangeDisabledState = "disabled";
        this.selectedStartHelp = "N/A for Library Sequence Package";
        this.selectedRangeHelp = "N/A for Library Sequence Package";
        if (this.selectedStart != "--") {
          this.selectedStart = "--";
          this.noStartEval = true;
        }
        if (this.selectedRange != "--") {
          this.selectedRange = "--";
          this.noRangeEval = true;
        }
      } else if (
        olabType == "package" &&
        itemSeq &&
        itemSeq.package_seq_type == "genome"
      ) {
        if (this.selectedOverride) {
          this.selectedStartDisabledState = "";
          this.selectedStartHelp = "0 is the begining of the gene sequence";
          this.selectedRangeDisabledState = "";
          this.selectedRangeHelp = "Length is limited to 20000.";
          // Reset noStartEval and noRangeEval
          this.noStartEval = false;
          this.noRangeEval = false;
        } else {
          this.selectedStartDisabledState = "disabled";
          this.selectedRangeDisabledState = "disabled";
          this.selectedStartHelp = "Use respective gene start";
          this.selectedRangeHelp = "Use respective gene length";
          if (this.selectedStart != "--") {
            this.selectedStart = "--";
            this.noStartEval = true;
          }
          if (this.selectedRange != "--") {
            this.selectedRange = "--";
            this.noRangeEval = true;
          }
        }
      } else {
        // Disable setting for start and range
        this.selectedStartDisabledState = "disabled";
        this.selectedStartHelp = "Setting is unsupported for this sequence";
        this.selectedRangeDisabledState = "disabled";
        this.selectedRangeHelp = "Setting is unsupported for this sequence";
      }
      // Copy start and range to cstb
      this.dsgnBox.cstb.start = parseInt(this.selectedStart);
      this.dsgnBox.cstb.range = parseInt(this.selectedRange);
    },
    resetNoEvalFlags: function() {
      this.noBoxTypeEval = false;
      this.noSeqEval = false;
      this.noStartEval = false;
      this.noRangeEval = false;
      this.noOverrideEval = false;
    },
    registerDsgnBox: function(dsbx) {
      // console.log("registerDsgnBox: dsbx = ", dsbx);
      const isHidden = $("#collapse-construct").is(":hidden");
      if (isHidden) {
        $("#collapse-construct").collapse("toggle");
      }
      // console.log("registerDsgnBox: isHidden = " + isHidden);

      this.boxNotSelected = false;
      this.resetNoEvalFlags();
      this.dsgnBox = dsbx;
      this.dsgnBox.touchInDesign = true;
      this.selectedBox = this.dsgnBox.cstb.box_id;
      this.selectedBoxType = this.dsgnBox.cstb.box_type;
      // console.log("selectedBox = ", this.selectedBox);
      // console.log("selectedBoxType = ", this.selectedBoxType);

      if (dsbx === this.lastDsgnBox) {
        // console.log("registerDsgnBox: dsbx === this.lastDsgnBox");
        // console.log(
        //   "selectedBoxType",
        //   this.selectedBoxType,
        //   ", ",
        //   this.lastSelectedBoxType
        // );
        return;
      }

      // Reset last Box that didn't complete the configuration steps
      if (
        this.dsgnBox != this.lastDsgnBox &&
        this.lastDsgnBox &&
        this.lastDsgnBox.cstb &&
        this.lastDsgnBox.cstb.configured != true
      ) {
        this.lastDsgnBox.cstb.box_type = "";
        this.lastDsgnBox.cstb.btn_color = "unset-color";
        this.lastDsgnBox.cstb.display_name =
          "Box " + this.lastDsgnBox.cstb.box_id;
      }
      // console.log("** dsgnBox configured = ", this.dsgnBox.cstb.configured);

      if (this.dsgnBox.cstb.configured == false) {
        this.dsgnBox.cstb.btn_color = "selected-color";
        // Reset Inputs
        this.selectedBoxPmrOverride = false;
        this.selectedBoxType = "";
        this.selectedSeqDName = "";
        this.selectedSeq = "";
        this.filteredCartItems = [];
        this.includeIntrons = false;
        this.setupStartAndRange(null, null);
      } else {
        // Set Inputs and eval flags
        if (this.selectedBoxType != this.dsgnBox.cstb.box_type) {
          this.noBoxTypeEval = true;
          this.selectedBoxType = this.dsgnBox.cstb.box_type;
        }
        // Set selectable sequences
        this.filteredCartItems = OlabComp.filterCartItems(
          this.dsgnBox.item,
          this.selectedBoxType,
          this.cartItems
        );
        if (this.includeIntrons != this.dsgnBox.cstb.include_introns) {
          // console.log("include_introns: ", this.dsgnBox.cstb.include_introns);
          this.noIncludeIntronsEval = true;
          this.includeIntrons = this.dsgnBox.cstb.include_introns
            ? this.dsgnBox.cstb.include_introns
            : false;
        }
        if (this.selectedSeq != this.dsgnBox.cstb.comp_olab_id) {
          this.noSeqEval = true;
          this.selectedSeq = this.dsgnBox.cstb.comp_olab_id;
          this.selectedSeqDName = this.dsgnBox.item.ct_id_name;
        }
        if (this.selectedStart != this.dsgnBox.cstb.start) {
          this.noStartEval = true;
          this.selectedStart = this.dsgnBox.cstb.start;
        }
        if (this.selectedRange != this.dsgnBox.cstb.range) {
          this.noRangeEval = true;
          this.selectedRange = this.dsgnBox.cstb.range;
        }
        if (this.selectedOverride != this.dsgnBox.cstb.package_override) {
          this.noOverrideEval = true;
          this.selectedOverride = this.dsgnBox.cstb.package_override;
        }
        this.setupStartAndRange(this.dsgnBox.item.comp, this.dsgnBox.item.seq);

        this.selectedBoxPmrOverride = this.dsgnBox.getPmrConfigOverride();
        if (this.selectedBoxPmrOverride) {
          this.primerSetting = this.dsgnBox.getPmrConfigSetting();
          this.primer3pLen = this.dsgnBox.getPmrConfig3pLen();
          this.primer5pLen = this.dsgnBox.getPmrConfig5pLen();
        } else {
          // Reset
          this.primerSetting = "FULL";
          this.primer3pLen = 20;
          this.primer5pLen = 20;
        }
      }

      // Set box's states into info as status
      OlabDesignBox.setInfoBox(this.dsgnBox);
      // this.saveCstbs();

      // Set selectable box types
      OlabComp.computeAvailBoxType(this.dsgnBox.cstb.box_type, this.cartItems);

      this.lastSelectedBoxType = this.selectedBoxType;
      this.lastDsgnBox = this.dsgnBox;
    },
    // Note: This method is to address some seq.length bugs in packages
    configDsgnBoxes: function() {
      console.log("this.dsgnBoxes =", this.dsgnBoxes);
      console.log("this.dsgn.dsgn_boxes =", this.dsgn.dsgn_boxes);
      const cstbLen = Math.min(
        this.dsgnBoxes.length,
        this.dsgn.dsgn_boxes.length
      );

      for (let i = 0; i < cstbLen; i += 1) {
        const box = this.dsgnBoxes[i];
        if (
          box.cstb.package_override == true &&
          box.item.comp.olab_type == "package"
        ) {
          if (!box.touchInDesign) {
            const bxItem = box.item;
            const newBxItem = this.dsgn.dsgn_boxes[i].item;
            const comps = bxItem.seq.comps;
            for (let j = 0; j < comps.length; j += 1) {
              comps[j].seq = newBxItem.seq.comps[j].seq;
              comps[j].length = comps[j].seq.length;
              // Note: This is to fix an old bug in saved design that didn't
              // compute seq.length
              newBxItem.seq.comps[j].length = comps[j].length;
            }
          } else {
            // Note: This is to fix an existing bug where seq.length isn't updated
            const bxItem = box.item;
            const comps = bxItem.seq.comps;
            for (let j = 0; j < comps.length; j += 1) {
              comps[j].length = comps[j].seq.length;
            }
          }
        }
      }
    },
    validatePackageConfig: function(dsgnBoxes, errorObj) {
      if (!dsgnBoxes) {
        errorObj.message = "Can not preview empty design";
        return false;
      }
      // Collect all packages in design and add them into a new array
      const pkgArr = [];
      const linearPkgArr = [];
      for (let i = 0; i < dsgnBoxes.length; i++) {
        // console.log("dsgnBoxes[", i, "] is ", dsgnBoxes[i]);
        if (dsgnBoxes[i].cstb && dsgnBoxes[i].item) {
          if (dsgnBoxes[i].item.comp.olab_type === "package") {
            pkgArr.push({
              index: i,
              linear: dsgnBoxes[i].cstb.package_op === "linear",
              pkgLen: dsgnBoxes[i].item.seq.comps.length
            });
            if (dsgnBoxes[i].cstb.package_op === "linear") {
              linearPkgArr.push({
                index: i,
                pkgLen: dsgnBoxes[i].item.seq.comps.length
              });
            }
          }
        } else {
          const errMsg = "Design has unassigned box";
          errorObj.message = errMsg;
          return false;
        }
      }
      // console.log("pkgArr =", pkgArr);
      // console.log("linearPkgArr =", linearPkgArr);
      let linearLen = 1;
      // If linear package is present
      if (linearPkgArr.length > 0) {
        // Enforce linear packages flank the combination packages
        if (!pkgArr[0].linear || !pkgArr[pkgArr.length - 1].linear) {
          const errMsg = "Design has to be flanked by Linear packages";
          errorObj.message = errMsg;
          return false;
        }
        // Check for linear packages and ensure they are of equal length
        linearLen = linearPkgArr[0].pkgLen;
        for (let i = 1; i < linearPkgArr.length; i++) {
          if (linearPkgArr[i].pkgLen !== linearLen) {
            const errMsg = "All Linear packages must of the same size";
            errorObj.message = errMsg;
            return false;
          }
        }
      }
      // console.log("linearLen =", linearLen);
      // Enforce limit of constructs to 9999
      let totalCsts = linearLen;
      // console.log("totalCsts = ", totalCsts);
      for (let i = 0; i < pkgArr.length; i++) {
        if (pkgArr[i].linear === false) {
          totalCsts *= pkgArr[i].pkgLen;
        }
      }
      // console.log("totalCsts = ", totalCsts);
      if (totalCsts > 9999) {
        const errMsg = `Design exceeds maxmium number of constructs (9999) permitted: ${totalCsts}`;
        errorObj.message = errMsg;
        return false;
      }
      return true;
    },
    doPreview: function(force) {
      // console.log("doPreview: force =", force, ", dsgnBox = ", this.dsgnBox);
      // Validate dsgnBoxes' package configurations
      const passStatus = this.validatePackageConfig(
        this.dsgnBoxes,
        this.errorObj
      );
      // Return if validation failed
      if (passStatus === false) {
        return;
      }

      // Reset dsgnBox to null and preset primer settings
      this.dsgnBox = null;
      this.selectedBoxPmrOverride = false;
      if (this.dsgn) {
        this.primer3pLen = this.dsgn.pmr_3p_len;
        this.primer5pLen = this.dsgn.pmr_5p_len;
        this.primerSetting = this.dsgn.pmr_setting;
      } else {
        this.primer3pLen = 20;
        this.primer5pLen = 20;
        this.primerSetting = "FULL";
      }
      let prObjArr = [];
      // Get the right seq info from either this.dsgnBoxes or this.dsgn.dsgn_boxes
      this.configDsgnBoxes();

      this.doPreviewDesign();

      if (!force) {
        const isVisible = $("#collapse-preview").is(":visible");
        console.log("doPreview: isVisible = " + isVisible);
        if (isVisible) {
          // No computation is needed since it is already visible
          return;
        }
        this.processing = "preview";
        this.previewReady = false;
        this.dsgn.computeConstruct(this.dsgnBoxes, prObjArr, this.errorObj);
        this.processing = "";
        this.selectedCstOlabID =
          prObjArr.length > 0 ? prObjArr[0].olab_id : null;
        if (this.errorObj.message) {
          setTimeout(() => {
            $("#collapse-construct").collapse("toggle");
            this.disabledState.preview = "disabled";
            this.disabledState.save = "disabled";
          }, 500);
        } else {
          this.previewReady = true;
        }
      } else {
        this.dsgn.computeConstruct(this.dsgnBoxes, prObjArr, this.errorObj);
      }

      this.primerObjArr = prObjArr;

      this.disabledState.save = "";
      this.disabledState.download = "disabled";
    },
    doProcessingStatus(status) {
      this.processing = status;
    },
    async doPreviewDesign() {
      // console.log("doPreviewDesign ... ");
      this.processing = "preview";
      // Reset this.previewCstObj to null before REST call
      this.previewCstObj = null;
      // Save design configuration boxes for preview computation
      this.dsgn.cstbs = this.selectedCart.cstbs;
      // Preview design's computed preview data
      const stats = await this.dsgn.previewDesign(
        this.backbone,
        this.dsgnBoxes,
        this.statusObj,
        this.errorObj
      );
      this.processing = "";
      if (stats && stats.data) {
        // console.log("stats.data = ", stats.data);
        this.previewResult = stats.data;
        this.prevewFirstPlasmidObj = stats.data.data.first_plasmid;
        this.previewCstObj = stats.data.data.first_construct;
      }
    },
    closeStatus: function() {
      // console.log("Close Status Alert ...");
      this.statusObj.message = null;
      this.statusObj.progress = false;
    },
    closeError: function() {
      // console.log("Close Error Alert ...");
      this.errorObj.message = null;
      if (this.oldFailType === "start") {
        this.selectedStart = this.oldSelectedStart;
      } else {
        this.selectedRange = this.oldSelectedRange;
      }
      this.oldFailType = "";
    }
  }
};
</script>

<style lang="scss" scoped>
.page-design {
  background-color: lightcyan;
}
</style>

<template>
  <!-- <div v-if="item != null" class="modal fade" id="viewItem"> -->
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          OLAB ID:
          <span class="h5 text-dark">{{ item.olab_id }}</span>
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          v-on:click="resetStates(true)"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <!-- .modal-header -->
      <div class="modal-body">
        <div class="py-1 text-wrap">
          <span class="mr-1">Name:</span>
          <span class="ml-1">{{ item.name }}</span>
        </div>
        <div v-if="selectedType === 'user'" class="py-1 text-wrap">
          <span class="mr-1">Email:</span>
          <span class="ml-1">{{ item.email }}</span>
        </div>
        <div v-if="selectedType === 'user'" class="py-1 text-wrap">
          <span class="mr-1">System Role:</span>
          <span class="ml-1">{{ item.role }}</span>
        </div>
        <div v-if="selectedType === 'user'" class="py-1 text-wrap">
          <span class="mr-1">Employee ID:</span>
          <span class="ml-1">{{ item.user_id }}</span>
        </div>
        <div
          v-if="
            selectedType === 'user' &&
              userProjectDetailArr &&
              userProjectDetailArr.length > 0
          "
          class="py-1 text-wrap"
        >
          <div class="card">
            <div class="card-header text-dark">Projects</div>
            <div class="card-body">
              <div
                class="my-1"
                v-for="proj in userProjectDetailArr"
                :key="proj.olab_id"
              >
                {{ proj.olab_id }} - {{ proj.name }}
              </div>
            </div>
            <!-- .card-body -->
          </div>
          <!-- .card -->
        </div>
        <div v-if="selectedType !== 'user'" class="py-1 text-wrap">
          <span class="mr-1">Creator:</span>
          <span class="ml-1">{{ item.creator }}</span>
        </div>
        <div
          v-if="
            selectedType === 'cart' ||
              selectedType === 'design' ||
              selectedType === 'package' ||
              selectedType === 'library_sequence'
          "
          class="py-1 text-wrap"
        >
          <span class="mr-1">Project:</span>
          <span class="ml-1">{{ itemProjDetail }}</span>
        </div>
        <div v-if="selectedType === 'project'" class="py-1 text-wrap">
          <span class="mr-1">Start Date:</span>
          <span class="ml-1">{{ item.start_date }}</span>
        </div>
        <div v-if="selectedType === 'project'" class="py-1 text-wrap">
          <span class="mr-1">End Date:</span>
          <span class="ml-1">{{ item.end_date }}</span>
        </div>
        <div v-if="selectedType === 'library_sequence'" class="py-1 text-wrap">
          <span class="mr-1">Sequence Origin:</span>
          <span class="ml-1">{{ item.seq_origin }}</span>
        </div>
        <div v-if="selectedType === 'plasmid'" class="py-1 text-wrap">
          <span class="mr-1">Ori:</span>
          <span class="ml-1">{{ item.plasmid_ori }}</span>
        </div>
        <div class="row py-1">
          <div
            v-if="
              selectedType === 'plasmid' ||
                selectedType === 'strain' ||
                selectedType === 'library_sequence'
            "
            class="col-lg-4 text-wrap"
          >
            <span class="mr-1">Source:</span>
            <span class="ml-1">{{ item.source }}</span>
          </div>
          <div
            v-if="
              (selectedType === 'plasmid' ||
                selectedType === 'project' ||
                selectedType === 'strain') &&
                item.ra_locks
            "
            class="col-lg-4 text-wrap"
          >
            <span class="mr-1">Public Access:</span>
            <span class="ml-1">{{ publicAccess }}</span>
          </div>
        </div>
        <div class="row py-1">
          <div v-if="selectedType === 'strain'" class="col-lg-4 text-wrap">
            <span class="mr-1">Contig:</span>
            <span class="ml-1">{{
              item.contig_array && item.contig_array.length > 0
            }}</span>
          </div>
          <div
            v-if="selectedType === 'plasmid' || selectedType === 'strain'"
            class="col-lg-4 text-wrap"
          >
            <span class="mr-1">WGS Confirmed:</span>
            <span class="mr-1">{{ item.wgs_confirmed }}</span>
          </div>
        </div>
        <div class="py-1">
          <div
            v-if="
              selectedType === 'plasmid' &&
                plasmidPrimers &&
                plasmidPrimers.length > 0
            "
            class="card"
          >
            <div class="card-header text-dark">Diagnostic Primers</div>
            <div class="card-body">
              <ul class="list-group list-group-flush">
                <li
                  class="list-group-item"
                  v-for="pmr in plasmidPrimers"
                  :key="pmr.name"
                >
                  <div class="row">
                    <div class="col">
                      <span class="mr-1">Name:</span>
                      <span class="mr-1">{{ pmr.name }}</span>
                    </div>
                    <div class="col">
                      <span class="mr-1">Length:</span>
                      <span class="mr-1">{{ pmr.len }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <span class="mr-1">Range:</span>
                      <span class="mr-1">[ {{ pmr.from }}, {{ pmr.to }} ]</span>
                    </div>
                    <div class="col">
                      <span class="mr-1">Strand:</span>
                      <span v-if="pmr.comp === 'true'" class="mr-1">-</span>
                      <span v-else class="mr-1">+</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col my-2">
                      <span class="text-monospace text-dark text-wrap">
                        {{ pmr.seq }}
                      </span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <!-- .card-body -->
          </div>
          <!-- .card -->
        </div>
        <div class="py-1">
          <div v-if="selectedType === 'plasmid'">
            <OlabPlasmidMap
              :dbInfo="dbInfo"
              :selectedType="selectedType"
              :item="item"
              :resetMap="resetPlasmidMap"
              @resetStates="resetStates"
            />
          </div>
        </div>
        <div
          v-if="
            (selectedType === 'plasmid' ||
              selectedType === 'project' ||
              selectedType === 'strain') &&
              memberDetailArr &&
              !publicAccess
          "
          class="py-1 text-wrap"
        >
          <div class="card">
            <div class="card-header text-dark">Members</div>
            <div class="card-body">
              <div v-if="memberDetailArr">
                <div
                  class="my-1"
                  v-for="raMember in memberDetailArr"
                  :key="raMember.olab_id"
                >
                  <span v-if="selectedType === 'project'">{{
                    raMember.email
                  }}</span>
                  <span v-else>{{ raMember.olab_id }}</span>
                  <span> - {{ raMember.name }}</span>
                </div>
              </div>
            </div>
            <!-- .card-body -->
          </div>
          <!-- .card -->
        </div>
        <div
          v-if="
            selectedType === 'project' &&
              projStrainDetailArr &&
              projStrainDetailArr.length > 0
          "
          class="py-1 text-wrap"
        >
          <div class="card">
            <div class="card-header text-dark">Strains</div>
            <div class="card-body">
              <div
                class="my-1"
                v-for="strn in projStrainDetailArr"
                :key="strn.olab_id"
              >
                {{ strn.olab_id }} - {{ strn.name }}
              </div>
            </div>
            <!-- .card-body -->
          </div>
          <!-- .card -->
        </div>
        <div v-if="selectedType === 'library_sequence'" class="py-1 text-wrap">
          <span class="mr-1">Library ID:</span>
          <span class="ml-1">{{ printLibraryInfo }}</span>
        </div>
        <div v-if="selectedType === 'package'" class="py-1 text-wrap">
          <span class="mr-1">Package Type:</span>
          <span class="ml-1"
            >{{ item.package_type }} - {{ item.package_seq_type }}</span
          >
        </div>
        <div
          v-if="
            selectedType === 'library_sequence' ||
              selectedType === 'construct' ||
              selectedType === 'primer'
          "
          class="py-1"
        >
          <div class="card">
            <div class="card-header text-dark">Sequence</div>
            <div v-if="item.seq" class="card-body">
              <div class="py-1 small text-monospace mr-1 text-dark text-wrap">
                {{ item.seq }}
              </div>
              <div v-if="item.seq" class="py-1">
                <span class="small mr-1">Length:</span>
                <span class="h5 small text-dark">{{ item.seq.length }}</span>
              </div>
            </div>
            <!-- .card-body -->
          </div>
          <!-- .card -->
        </div>
        <div v-if="selectedType === 'design'" class="py-1">
          <div>
            <span class="mr-1">Package Present:</span>
            <span class="text-dark">{{ item.package_present }}</span>
          </div>
          <div class="py-2">
            <div class="card">
              <div class="card-header text-dark">Primer</div>
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <span class="small mr-1">Setting:</span>
                    <span class="h5 small text-dark">{{
                      item.pmr_setting
                    }}</span>
                  </div>
                  <div class="col">
                    <span class="small mr-1">3' Length:</span>
                    <span class="h5 small text-dark">{{
                      item.pmr_3p_len
                    }}</span>
                  </div>
                  <div class="col">
                    <span class="small mr-1">5' Length:</span>
                    <span class="h5 small text-dark">{{
                      item.pmr_5p_len
                    }}</span>
                  </div>
                </div>
              </div>
              <!-- .card-body -->
            </div>
            <!-- .card -->
          </div>
          <div class="py-1">
            <div class="card">
              <div class="card-header text-dark">Boxes</div>
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table table-striped table-sm">
                    <thead>
                      <tr>
                        <th class="small" scope="col">#</th>
                        <th class="small" scope="col">OLAB ID</th>
                        <th class="small" scope="col">Display Name</th>
                        <th class="small" scope="col">Box Type</th>
                        <th class="small" scope="col">Start</th>
                        <th class="small" scope="col">Range</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(box, index) in item.dsgn_boxes" :key="index">
                        <th class="small" scope="row">{{ index + 1 }}</th>
                        <td class="small">{{ box.cstb.comp_olab_id }}</td>
                        <td class="small">{{ box.cstb.display_name }}</td>
                        <td class="small">{{ box.cstb.box_type }}</td>
                        <td class="small">{{ box.cstb.start }}</td>
                        <td class="small">{{ box.cstb.range }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- .card-body -->
            </div>
            <!-- .card -->
          </div>
        </div>
        <div
          v-if="
            selectedType !== 'user' &&
              selectedType !== 'construct' &&
              selectedType !== 'primer'
          "
          class="py-1"
        >
          <div class="card">
            <div class="card-header text-dark">Description</div>
            <div class="card-body">
              <div class="py-1 small text-dark text-wrap">
                {{ item.desc }}
              </div>
            </div>
            <div v-if="item.desc_hyperlink" class="card-footer text-center">
              <span class="px-3">Go to:</span>
              <a :href="item.desc_hyperlink" target="_blank" class="card-link"
                >NCBI
              </a>
            </div>
            <div v-if="item.download_file" class="card-footer text-center">
              <span class="px-3">Download:</span>
              <a
                :href="item.download_file"
                target="_blank"
                class="card-link"
                download
                >{{ item.download_file }}</a
              >
            </div>
            <!-- .card-body -->
          </div>
          <!-- .card -->
        </div>
        <div
          v-if="
            (selectedType === 'cart' || selectedType === 'package') &&
              item.comps
          "
          class="py-1"
        >
          <OlabCompTable :selectedType="selectedType" :item="item" />
        </div>
      </div>
      <!-- .modal-body -->
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          data-dismiss="modal"
          v-on:click="resetStates(true)"
        >
          Close
        </button>
      </div>
      <!-- .modal-footer -->
    </div>
    <!-- .modal-content -->
  </div>
  <!-- .modal-dialog .modal-lg-->
  <!-- </div> -->
  <!-- .modal fade #viewItem -->
</template>

<script>
import OlabCompTable from "@/components/OlabCompTable";
import OlabPlasmidMap from "@/components/OlabPlasmidMap";

export default {
  name: "olabviewitem",
  data() {
    return {
      resetPlasmidMap: true
    };
  },
  components: {
    OlabCompTable,
    OlabPlasmidMap
  },
  props: [
    "dbInfo",
    "selectedType",
    "item",
    "plasmidPrimers",
    "itemProjDetail",
    "publicAccess",
    "memberDetailArr",
    "userProjectDetailArr",
    "projStrainDetailArr"
  ],
  async mounted() {
    // console.log("plasmidPrimers =", this.plasmidPrimers);
  },
  computed: {
    printLibraryInfo: function() {
      if (
        this.dbInfo &&
        this.dbInfo.db_configs &&
        this.dbInfo.db_configs.libraries
      ) {
        const libs = this.dbInfo.db_configs.libraries;
        for (let i = 0; i < libs.length; i++) {
          if (libs[i].olab_id == this.item.library_id) {
            return libs[i].olab_id + " - " + libs[i].name;
          }
        }
      }
      return "";
    }
  },
  methods: {
    resetStates(state) {
      this.resetPlasmidMap = state;
    }
  },
  watch: {}
};
</script>

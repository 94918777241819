<template>
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <g transform="translate(150,150)">
        <g transform="scale(15)" fill="#cccccc">
          <path
            fill="#eeeeee"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.622009 2.21375H10.2303V13.8114C9.09828 14.9478 6.97753 15.1036 5.32453 14.9478C4.49133 14.8693 3.70621 14.7043 3.07202 14.511C2.44877 14.321 1.93963 14.0942 1.68489 13.8761L1.60637 13.8089V3.7373L0.622009 2.21375ZM1.45349 2.66332L2.06281 3.60639V13.5963C2.27251 13.74 2.66839 13.9173 3.20698 14.0815C3.81178 14.2659 4.56627 14.4247 5.36802 14.5003C6.94627 14.6491 8.81103 14.4192 9.77391 13.6952V2.66332H1.45349Z"
          />
          <path
            fill="pink"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.71655 12.9641L3.18239 12.3311L3.53131 12.0367L4.06546 12.6697L3.71655 12.9641Z"
          />
          <path
            fill="pink"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.66855 12.9543L4.99591 13.35L4.76444 12.9565L5.43708 12.5609L5.66855 12.9543Z"
          />
          <path
            fill="pink"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.077 11.9426L4.28566 11.6458L4.44596 11.2184L5.2373 11.5151L5.077 11.9426Z"
          />
          <path
            fill="pink"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.37836 12.9641L8.91252 12.3311L8.5636 12.0367L8.02945 12.6697L8.37836 12.9641Z"
          />
          <path
            fill="pink"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.42636 12.9543L7.099 13.35L7.33047 12.9565L6.65783 12.5609L6.42636 12.9543Z"
          />
          <path
            fill="pink"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.0179 11.9426L7.80924 11.6458L7.64895 11.2184L6.85761 11.5151L7.0179 11.9426Z"
          />
          <path
            fill="#f80"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.34503 5.71527H2.57329C2.63067 5.71527 2.67825 5.73626 2.69277 5.74266C2.71583 5.75281 2.74026 5.76582 2.76182 5.7781C2.80544 5.80294 2.85969 5.83719 2.92026 5.87634C2.96098 5.90265 3.00543 5.93174 3.05381 5.9634C3.15396 6.02893 3.27096 6.1055 3.40663 6.19135C3.80571 6.4439 4.34574 6.76341 4.96873 7.03878C5.02089 7.06184 5.07355 7.08455 5.12667 7.10686C6.30172 7.60023 7.70508 7.89574 9.00039 7.35617C9.05521 7.33333 9.10985 7.309 9.16426 7.28312L9.49056 7.12794V7.14079V13.6432C8.53227 14.1331 5.74406 14.796 2.48834 13.4906L2.34503 13.4331V5.71527ZM2.80154 6.34391C2.90268 6.41008 3.02517 6.49021 3.16251 6.57712C3.57114 6.83571 4.13236 7.16822 4.78417 7.45633C5.99034 7.98949 7.54898 8.38889 9.03404 7.8337V13.3594C8.10543 13.772 5.73019 14.2518 2.80154 13.1232V6.34391Z"
          />
          <path
            fill="#f80"
            d="M5.12667 7.10686C6.30172 7.60023 7.70508 7.89574 9.00039 7.35617C8.98653 7.35191 8.97172 7.34766 8.95591 7.34345C8.82665 7.309 8.65322 7.28268 8.43987 7.2627C8.14123 7.23472 7.78373 7.22071 7.39141 7.20931C7.27587 7.20596 7.15731 7.20283 7.03635 7.19963L6.86946 7.1952C6.7616 7.19229 6.65228 7.18924 6.54204 7.18583C6.07266 7.17131 5.58669 7.15032 5.12667 7.10686Z"
          />
          <path
            fill="#cccccc"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.68036 0.222089C9.88627 0.302193 9.98826 0.534051 9.90815 0.73996L7.56701 6.75793L7.39141 7.20931L6.08326 10.572C6.00315 10.7779 5.77129 10.8799 5.56539 10.7997C5.35948 10.7196 5.25749 10.4878 5.3376 10.2819L6.54204 7.18583L6.71769 6.73431L9.16249 0.449879C9.2426 0.243971 9.47445 0.141986 9.68036 0.222089Z"
          />
          <path
            fill="#f80"
            d="M3.40663 6.19135C3.80571 6.4439 4.34574 6.76341 4.96873 7.03878C5.02089 7.06184 5.07355 7.08455 5.12667 7.10686C5.58669 7.15032 6.07266 7.17131 6.54204 7.18583L6.71769 6.73431C6.17506 6.71886 5.61554 6.69742 5.09743 6.64533C4.52347 6.58761 3.77255 6.34568 3.40663 6.19135Z"
          />
          <path
            fill="#f80"
            d="M9.16426 7.28312L9.49056 7.12794V7.14079L9.49953 7.1333C9.39584 7.00894 9.22839 6.94361 9.07347 6.90233C8.90801 6.85823 8.70537 6.82905 8.48245 6.80817C8.21388 6.78301 7.90153 6.76876 7.56701 6.75793L7.39141 7.20931C7.78373 7.22071 8.14123 7.23472 8.43987 7.2627C8.65322 7.28268 8.82665 7.309 8.95591 7.34345C8.97172 7.34766 8.98653 7.35191 9.00039 7.35617C9.05521 7.33333 9.10985 7.309 9.16426 7.28312Z"
          />
        </g>
      </g>
      <text
        :x="textPosX"
        :y="textPosY"
        fill="#77b"
        style="
          font-size: 32px;
          font-weight: bold;
        "
      >
        {{ name }}
      </text>
    </svg>
  </div>
</template>

<script>
export default {
  name: "olabmediumsvggen",
  props: ["name", "textPosX", "textPosY", "status"]
};
</script>

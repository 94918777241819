<template>
  <div>
    <div v-if="selectedType === 'plasmid'">
      <div class="row my-2">
        <div class="container">
          <button
            type="button"
            class="btn btn-primary"
            v-on:click="viewGenesMap()"
          >
            View Gene Map
          </button>
          <div v-if="filteredGenes" class="row my-2">
            <div class="col-10 col-md-6 my-2">
              <div class="form-group">
                <label class="form-control-label">
                  Gene [{{ filteredGenes.length }} counts]
                </label>
                <select
                  class="form-control"
                  id="selectedGeneDName"
                  v-model="selectedGeneDName"
                >
                  <option disabled value="">Select a gene</option>
                  <option v-for="gn in filteredGenes" v-bind:key="gn.olab_id">
                    {{ gn.d_name }}
                  </option>
                </select>
                <!-- <div>{{ selectedGeneDName }}</div> -->
              </div>
            </div>
            <div class="col-10 col-md-6 my-2">
              <label class="form-control-label">
                Restriction Enzyme [{{ filteredResEnzymes.length }}
                counts]
              </label>
              <div v-bind:style="resEnzymeStyle">
                <treeselect
                  class="vue-treeselect"
                  v-model="resEnzymeArr"
                  :multiple="true"
                  :disableFuzzyMatching="true"
                  :disable-branch-nodes="true"
                  :clear-on-select="true"
                  :close-on-select="true"
                  :options="filteredResEnzymes"
                  @select="resEnzymeSelectChange"
                  placeholder="Select a restriction enzyme ..."
                />
              </div>
              <!-- <span>selected = {{ resEnzymeArr }}</span> -->
              <div class="panel">
                <label class="form-check-label mr-2"># cut sites:</label>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="inlineRadioCSAny"
                    value="any_cs"
                    v-model="csFilterType"
                  />
                  <label class="form-check-label" for="inlineRadioCSAny"
                    >Any</label
                  >
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="inlineRadioCS1"
                    value="one_cs"
                    v-model="csFilterType"
                  />
                  <label class="form-check-label" for="inlineRadioCS1">1</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="inlineRadioCS2"
                    value="two_cs"
                    v-model="csFilterType"
                  />
                  <label class="form-check-label" for="inlineRadioCS2">2</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="filteredGenes" class="my-3 overflow-auto">
        <div id="d3svgCircularMapOPM">
          <div id="tooltipOPM" class="text-info text-left">
            <div class="display-name"></div>
            <div class="display-start">Start: <span></span></div>
            <div class="display-end">End: <span></span></div>
            <div class="display-length">Length: <span></span></div>
            <div class="display-strand">Strand: <span></span></div>
          </div>
          <div id="enzymeTooltip" class="text-info text-left">
            <div class="display-name"></div>
            <div class="display-cutsite">Cut Site: <span></span></div>
          </div>
        </div>

        <div class="row justify-content-left">
          <div class="col-10 col-md-6 my-2">
            <button
              class="btn btn-primary"
              type="button"
              data-toggle="collapse"
              data-target="#d3LinearMap"
              aria-expanded="false"
              aria-controls="d3LinearMap"
              @click="setViewSequence()"
            >
              View Sequence
            </button>
          </div>
          <div class="col-10 col-md-6 my-2">
            <div class="form-group">
              <!-- <label class="form-control-label">
                Gene [{{ filteredGenes.length }} counts]
              </label> -->
              <div class="row">
                <span>
                  <label class="h5 form-control-label mx-2 mt-1">
                    Gene :
                  </label>
                </span>
                <span>
                  <select
                    class="form-control"
                    id="selectedGeneDName"
                    v-model="selectedGeneDName"
                  >
                    <option disabled value="">Select a gene</option>
                    <option v-for="gn in filteredGenes" v-bind:key="gn.olab_id">
                      {{ gn.d_name }}
                    </option>
                  </select>
                </span>
                <span class="mx-2 mt-1">
                  [{{ filteredGenes.length }} counts]
                </span>

                <!-- <div>{{ selectedGeneDName }}</div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="overflow-auto collapse" id="d3LinearMap">
              <div
                class="card-header text-light"
                v-bind:style="linearMapHeaderStyle"
              >
                {{ d3GeneID }}
              </div>
              <div class="card-body">
                <div v-if="selectedD3GeneData" class="row">
                  <div class="col">
                    <span class="ml-2">Name:</span>
                    <span class="ml-1">{{ selectedD3GeneData.name }}</span>
                  </div>
                </div>
                <div v-if="selectedD3GeneData" class="row">
                  <div class="col">
                    <span class="ml-2">Start (in plasmid):</span>
                    <span class="ml-1">{{ selectedD3GeneData.geneFrom }}</span>
                  </div>
                  <div class="col">
                    <span class="ml-2">End (in plasmid):</span>
                    <span class="ml-1">{{ selectedD3GeneData.geneTo }}</span>
                  </div>
                </div>
                <div v-if="selectedD3GeneData" class="row">
                  <div class="col">
                    <span class="ml-2">Length:</span>
                    <span class="ml-1">{{ selectedD3GeneData.len }}</span>
                  </div>
                  <div class="col">
                    <span class="ml-2">Strand:</span>
                    <span class="ml-1">{{ d3GeneStrand }}</span>
                  </div>
                </div>
                <div v-if="selectedD3GeneData" class="row">
                  <div
                    v-if="
                      geneNotFound &&
                        plasmid &&
                        plasmid.ra_locks &&
                        plasmid.ra_locks.public === false
                    "
                    class="my-5 h5 col text-center"
                  >
                    <span class="ml-2">Sequence Access Denied:</span>
                    <span class="text-danger ml-1">Member Only</span>
                  </div>
                </div>
                <div class="row justify-content-center">
                  <div class="col-12 mt-3 mb-3">
                    <div class="card">
                      <div class="card-header text-dark">
                        Display
                      </div>
                      <div class="card-body">
                        <div class="row justify-content-center">
                          <div class="col-12 col-md-6">
                            <div class="form-group">
                              <div class="mr-1">
                                Start (in gene)
                              </div>
                              <input
                                class="form-control"
                                type="number"
                                step="1"
                                v-bind:class="displayStartValidState"
                                v-model="displayData.start"
                              />
                              <div class="invalid-feedback">
                                {{ displayData.startInvalidFeedback }}
                              </div>
                            </div>
                            <!-- .form-group -->
                          </div>
                          <div class="col-12 col-md-6">
                            <div class="form-group">
                              <!-- Range is renamed as Length -->
                              <div class="mr-1">
                                Length
                              </div>
                              <input
                                class="form-control"
                                type="number"
                                min="1"
                                max="5000"
                                step="1"
                                v-bind:class="displayRangeValidState"
                                v-model="displayData.range"
                              />
                              <div class="invalid-feedback">
                                {{ displayData.rangeInvalidFeedback }}
                              </div>
                            </div>
                            <!-- .form-group -->
                          </div>
                        </div>
                        <div class="input-group flex-nowrap">
                          <div class="input-group-prepend">
                            <span class="input-group-text" id="search-wrapping"
                              >Search</span
                            >
                          </div>
                          <input
                            type="text"
                            class="form-control"
                            v-model="searchStr"
                            placeholder="Search sequence"
                            aria-label="Search"
                            aria-describedby="search-wrapping"
                          />
                          <span
                            v-if="searchStrHitArr && searchStrHitArr.length > 0"
                            class="ml-2 text-info text-sm"
                          >
                            {{ searchStrHitArr.length }}
                          </span>
                        </div>
                        <div class="mt-2 float-right">
                          <button
                            type="button"
                            class="btn btn-primary"
                            @click="copySelectSeq"
                          >
                            Copy
                          </button>
                        </div>
                        <div
                          id="d3svgLinearMap"
                          style="width: 600px; height: 600px; overflow-y: scroll"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { OlabAxios } from "@/olab/olabaxios.js";
import { OlabUtils } from "@/olab/olabutils.js";
import { OlabSearch } from "@/olab/olabsearch.js";
import { OlabBlock } from "@/olab/olabblock.js";
import { MetaMap } from "@/olab/d3/metaMap.js";
import { OlabCircularMap } from "@/olab/d3/olabCircularMap";
import { OlabLinearMap } from "@/olab/d3/olabLinearMap";
// Access treeselect component
import Treeselect from "@riophae/vue-treeselect";
// Access treeselect CSS styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { Clipboard } from "v-clipboard";
import $ from "jquery";

export default {
  name: "olabplasmidmap",
  data() {
    return {
      plasmid: null,
      filteredGenes: null,
      filteredTags: null,
      selectedD3GeneData: null,
      selectedGeneDName: "",
      selectedGene: null,
      selectedSeq: null,
      circularMap: new OlabCircularMap("d3svgCircularMapOPM", "tooltipOPM"),
      linearMap: new OlabLinearMap(),
      searchStr: "",
      searchStrHitArr: null,
      geneNotFound: false,
      linearMapHeaderStyle: {
        "background-color": "0x0eee"
      },
      // TODO: Prep display of localStatusObj
      localStatusObj: {
        message: null,
        progress: false
      },
      // TODO: Prep display of localErrorObj
      localErrorObj: {
        message: null
      },
      resEnzymeArr: null,
      resEnzymeStyle: {
        borderStyle: "none none solid none",
        borderColor: "#d9534f"
      },
      displayData: {
        start: "0",
        numStart: 0,
        startValid: true,
        startDisabledState: "disabled",
        startInvalidFeedback: "Start has to be an integer",
        range: "1",
        numRange: 1,
        rangeValid: true,
        rangeDisabledState: "disabled",
        rangeInvalidFeedback: "Length has to be a positive integer (>0)"
      },
      oneCSREnzArr: null,
      twoCSREnzArr: null,
      oneOrMoreCSREnzArr: null,
      csFilterType: "any_cs"
    };
  },
  components: {
    Treeselect
  },
  props: ["dbInfo", "selectedType", "item", "resetMap"],
  emits: ["resetStates"],
  async mounted() {},
  computed: {
    filteredResEnzymes() {
      let fREArr = [];
      if (this.csFilterType === "any_cs" && this.oneOrMoreCSREnzArr) {
        fREArr = this.oneOrMoreCSREnzArr;
      } else if (this.csFilterType === "one_cs" && this.oneCSREnzArr) {
        fREArr = this.oneCSREnzArr;
      } else if (this.csFilterType === "two_cs" && this.twoCSREnzArr) {
        fREArr = this.twoCSREnzArr;
      }
      return fREArr;
    },
    d3GeneID() {
      let id = "None";
      if (this.selectedD3GeneData) {
        id = this.selectedD3GeneData.geneID;
      }
      return id;
    },
    d3GeneStrand() {
      let strand = "";
      if (this.selectedD3GeneData) {
        strand = this.selectedD3GeneData.comp === true ? "-" : "+";
      }
      return strand;
    },
    displayStartValidState: function() {
      return this.displayData.startValid ? "is-valid" : "is-invalid";
    },
    displayRangeValidState: function() {
      return this.displayData.rangeValid ? "is-valid" : "is-invalid";
    },
    selectStartValidState: function() {
      return this.selectData.startValid ? "is-valid" : "is-invalid";
    },
    selectRangeValidState: function() {
      return this.selectData.rangeValid ? "is-valid" : "is-invalid";
    }
  },
  methods: {
    resEnzymeSelectChange: function() {
      // NOTE: resEnzymeArr isn't the latest so we need to use watch for the
      //       latest resEnzymeArr's values
      // console.log("resEnzymeSelectChange: resEnzymeArr =", this.resEnzymeArr);
    },
    async prepCutSites() {
      const fullResEnzNameArr = [];
      this.dbInfo.db_configs.res_enzymes.map(rE => {
        fullResEnzNameArr.push(rE.name);
      });
      const fullCutSiteArr = await this.getEnzymeCS(fullResEnzNameArr);
      // console.log("fullCutSiteArr =", fullCutSiteArr);
      this.oneCSREnzArr = [];
      this.twoCSREnzArr = [];
      this.oneOrMoreCSREnzArr = [];
      this.dbInfo.db_configs.res_enzymes.map(rE => {
        const csName = rE.name;
        const cs = fullCutSiteArr[csName];
        if (cs.length > 0) {
          this.oneOrMoreCSREnzArr.push(rE);
        }
        if (cs.length === 1) {
          this.oneCSREnzArr.push(rE);
        }
        if (cs.length === 2) {
          this.twoCSREnzArr.push(rE);
        }
      });
      // console.log("oneCSREnzArr =", this.oneCSREnzArr);
      // console.log("twoCSREnzArr =", this.twoCSREnzArr);
      // console.log("oneOrMoreCSREnzArr =", this.oneOrMoreCSREnzArr);
    },
    async viewGenesMap() {
      // Set plasmid to item so that it will be reference correctly
      // in OlabLinearMap
      this.plasmid = this.item;

      if (this.filteredGenes) {
        this.resetMapStates();
      } else {
        // Setup cutSite Arrays
        this.prepCutSites();

        // this.filteredGenes will contain the return genes
        // this.filteredTags will contain the return tags
        await this.getMetaMap(true);
        if (
          this.filteredGenes &&
          this.filteredGenes.length === 0 &&
          this.filteredTags &&
          this.filteredTags.length === 0
        ) {
          // Try to use the older version for backward compatibility
          await this.getMetaMap(false);
        }
        this.circularMap.setup();
        // console.log("this.filteredGenes =", this.filteredGenes);
        // console.log("this.filteredTags =", this.filteredTags);

        const frameArr = this.circularMap.compute(
          this.filteredGenes,
          this.plasmid.stats.total_seq_len
        );
        // console.log("(1) frameArr =", frameArr);

        if (this.filteredTags && this.filteredTags.length > 0) {
          const tagFrameArr = this.circularMap.computeTagFrames(
            this.filteredTags,
            this.plasmid.stats.total_seq_len
          );
          // console.log("tagFrameArr =", tagFrameArr);
          // Insert tagFrameArr's array(s) to the front of frameArr
          frameArr.unshift(...tagFrameArr);
          // console.log("(2) frameArr = ", frameArr);
        }
        this.circularMap.draw(frameArr, this);

        // Setup LinearMap
        this.linearMap.setup();

        this.$emit("resetStates", false);
      }
    },
    // Note: Split metaMap into filteredGenes and filteredTags
    async getMetaMap(useNewVersion) {
      if (this.plasmid) {
        let plasmGArr = null;
        if (useNewVersion) {
          plasmGArr = await OlabAxios.getPlasmidMetaMap(
            this.plasmid,
            this.localStatusObj,
            this.localErrorObj
          );
        } else {
          plasmGArr = await OlabAxios.getPlasmidGenes(
            this.plasmid,
            this.localStatusObj,
            this.localErrorObj
          );
        }

        // console.log("useNewVersion", useNewVersion, "plasmGArr =", plasmGArr);
        const gArr = [];
        const tArr = [];
        MetaMap.processMetaMap(plasmGArr, gArr, tArr, useNewVersion);
        // console.log("gArr =", gArr, ", tArr =", tArr);
        this.filteredGenes = gArr;
        this.filteredTags = tArr;
      }
    },
    async getEnzymeCS(resEnzArr) {
      // console.log("resEnzArr =", resEnzArr);
      // console.log("plasmid =", this.plasmid);
      const cutSites = await OlabAxios.getEnzymeCutSites(
        this.plasmid,
        "res_enzyme",
        resEnzArr,
        null,
        null,
        this.localStatusObj,
        this.localErrorObj
      );
      // console.log("cuteSites =", cutSites);
      return cutSites;
    },
    async processResEnzymeArr(resEnzymeArr) {
      const cutSites = await this.getEnzymeCS(resEnzymeArr);
      const resEnzCSFrame = this.circularMap.computeEnzymeCS(
        resEnzymeArr,
        cutSites,
        this.plasmid.stats.total_seq_len
      );
      // console.log("resEnzCSFrame =", resEnzCSFrame);
      this.circularMap.drawEnzymeCS(resEnzCSFrame);
    },
    async processSelectedGene(geneID) {
      console.log("processSelectedGene: geneID =", geneID);
      if (geneID) {
        const searchBody = OlabSearch.createGeneSearchBody(
          geneID,
          "olab_id",
          null,
          1,
          1
        );
        const geneArr = await OlabUtils.getGenes(searchBody);
        let gn = null;
        if (geneArr.length > 0) {
          this.geneNotFound = false;
          gn = geneArr[0];
          // console.log("gn =", gn);
          // TODO: Need to handle gene.isJoined === true case
          const gnSeq = gn.sequence.seq;
          this.searchStrHitArr = this.linearMap.draw(gnSeq, this);
        } else {
          console.log(
            "Cannot access gene or gene not found: olab_id = ",
            geneID
          );
          this.geneNotFound = true;
        }
        return gn;
      }
    },
    async processGeneD3Data(setGeneName) {
      const isVisible = $("#d3LinearMap").is(":visible");
      // console.log("d3LinearMap: isVisible = " + isVisible);
      if (this.selectedD3GeneData) {
        const gnSeq = await this.processSelectedGene(
          this.selectedD3GeneData.geneID
        );
        if (this.selectedGene) {
          // Setup selectedSeq and display data
          // Set gene's seqeunce and selectedSeq
          // console.log("selectedGene =", this.selectedGene);
          this.selectedGene.sequence.seq = gnSeq.sequence.seq;
          this.selectedSeq = this.selectedGene.sequence.seq;
          // console.log("selectedSeq =", this.selectedSeq);
          this.displayData.start = 0;
          this.displayData.range = this.selectedSeq.length;
          this.setDisplayStart(this.displayData.start);
          this.setDisplayRange(this.displayData.range);
        }
        this.linearMapHeaderStyle = {
          "background-color": this.selectedD3GeneData.geneFillColor
        };
        if (setGeneName) {
          // Set gene's data on UI
          this.selectedGeneDName = this.selectedD3GeneData.name;
        }
        if (!isVisible) {
          $("#d3LinearMap").collapse("toggle");
        }
      } else {
        this.selectedGene = null;
        this.linearMap.removeSeqGroup();
        if (isVisible) {
          $("#d3LinearMap").collapse("toggle");
        }
      }
    },
    getSelectedGene(dName) {
      if (dName && this.filteredGenes) {
        for (let i = 0; i < this.filteredGenes.length; i++) {
          if (this.filteredGenes[i].d_name === dName) {
            this.selectedGene = this.filteredGenes[i];
            console.log("Gene found! selectedGene =", this.selectedGene);
            break;
          }
        }
      }
    },
    // Function to get the Selected Text
    _getHLSeq() {
      let hlSeq = "";

      // window.getSelection
      if (window.getSelection) {
        hlSeq = window.getSelection();
        // console.log("(1) hlSeq =", hlSeq);
      }
      // document.getSelection
      else if (document.getSelection) {
        hlSeq = document.getSelection();
        // console.log("(2) hlSeq =", hlSeq);
      }
      // document.selection
      else if (document.selection) {
        hlSeq = document.selection.createRange().text;
        // console.log("(3) hlSeq =", hlSeq);
      } else return;
      // Remove spaces from the selected text before return
      const seqStr = hlSeq.toString();
      return seqStr.replace(/\s/g, "");
    },
    copySelectSeq() {
      const clipboardText = this._getHLSeq();
      // console.log("clipboardText =", clipboardText);
      Clipboard.copy(clipboardText);
    },
    computeDisplaySeq: async function(start, range) {
      const res = await OlabBlock.computeSequence(
        this.selectedGene,
        start,
        range,
        this.localStatusObj,
        this.localErrorObj
      );
      if (res.status === "success") {
        this.selectedSeq = res.seqFrag;
        // console.log("** selectedSeq =", this.selectedSeq);
        this.searchStrHitArr = this.linearMap.draw(this.selectedSeq, this);
      } else {
        // console.log(res);
        if (res.failType === "start") {
          this.displayData.startValid = false;
          this.displayData.startInvalidFeedback = res.message;
        } else {
          // "range" case
          this.displayData.rangeValid = false;
          this.displayData.rangeInvalidFeedback = res.message;
        }
      }
    },
    setDisplayStart: function(start) {
      const numType = typeof start === "number";
      this.displayData.startValid = numType
        ? true
        : start.toString().match(/^[-+]?(0|[1-9]\d*)$/) != null;
      if (this.displayData.startValid) {
        this.displayData.numStart = parseInt(start);
      } else {
        this.displayData.startInvalidFeedback = "Start has to be an integer";
      }
    },
    setDisplayRange: function(range) {
      const numType = typeof range === "number";
      this.displayData.rangeValid = numType
        ? true
        : range.toString().match(/^[1-9]\d*$/) != null;
      if (this.displayData.rangeValid) {
        const tmpRange = parseInt(range);
        if (tmpRange > this.plasmid.stats.total_seq_len) {
          this.displayData.rangeValid = false;
          this.displayData.rangeInvalidFeedback = `Length can not be greater than plasmid's length: ${this.plasmid.stats.total_seq_len}`;
        } else if (tmpRange > OlabLinearMap.MAX_CHARS_PER_MAP) {
          this.displayData.rangeValid = false;
          this.displayData.rangeInvalidFeedback = `Length can not be greater than ${OlabLinearMap.MAX_CHARS_PER_MAP}`;
        } else {
          this.displayData.numRange = tmpRange;
        }
      } else {
        this.displayData.rangeInvalidFeedback =
          "Length has to be a positive integer (>0)";
      }
    },
    resetMapStates: function() {
      if (this.selectedType === "plasmid") {
        // console.log("Calling resetMapStates ...", this.plasmid);
        // Reset selected fields
        this.selectedGeneDName = "";
        this.filteredGenes = null;
        this.filteredTags = null;
        this.selectedD3GeneData = null;
        this.resEnzymeArr = null;
        this.circularMap.reset();
        this.geneNotFound = false;
        this.oneCSREnzArr = null;
        this.twoCSREnzArr = null;
        this.oneOrMoreCSREnzArr = null;
        this.csFilterType = "any_cs";
      }
    },
    setViewSequence() {
      // Set default sequence if selected gene isn't selected
      if (this.selectedGeneDName === "" && this.filteredGenes) {
        this.selectedGeneDName = this.filteredGenes[0].d_name;
      }
    }
  },
  watch: {
    resetMap() {
      // console.log("resetMap =", this.resetMap);
      if (this.resetMap) {
        this.resetMapStates();
      }
    },
    resEnzymeArr() {
      // console.log("watch: resEnzymeArr =", this.resEnzymeArr);
      this.resEnzymeStyle.borderColor = "#d9534f";
      if (this.resEnzymeArr && this.resEnzymeArr.length > 0) {
        this.resEnzymeStyle.borderColor = "#5cb85c";
        this.processResEnzymeArr(this.resEnzymeArr);
      } else {
        // remove all previous enzyme arcs and labels
        this.circularMap.removeFrameEnzRing();
        this.circularMap.removeFrameEnzLabel();
      }
    },
    selectedD3GeneData() {
      // set gene name in this call
      this.processGeneD3Data(true);
    },
    selectedGeneDName() {
      console.log("Watch: selectedGeneDName =", this.selectedGeneDName);
      if (this.selectedGeneDName == "") {
        return;
      }
      this.getSelectedGene(this.selectedGeneDName);

      // Show linear sequence map
      this.selectedD3GeneData = this.circularMap.getGeneArc(
        this.selectedGene.olab_id
      );
      // Do not set gene name in this call
      this.processGeneD3Data(false);
    },
    "displayData.start": async function() {
      if (!this.selectedD3GeneData) return;
      this.setDisplayStart(this.displayData.start);
      await this.computeDisplaySeq(
        this.displayData.numStart,
        this.displayData.numRange
      );
    },
    "displayData.range": async function() {
      if (!this.selectedD3GeneData) return;
      this.setDisplayRange(this.displayData.range);
      await this.computeDisplaySeq(
        this.displayData.numStart,
        this.displayData.numRange
      );
    },
    searchStr() {
      // Remove all space characters from search string
      this.searchStr = this.searchStr.replace(/\s/g, "");

      this.searchStrHitArr = this.linearMap.searchSeqBlock(
        this.searchStr,
        false
      );
      // console.log("searchStrHitArr =", this.searchStrHitArr);
    }
  }
};
</script>

<style lang="scss" scoped>
#d3svgCircularMapOPM {
  margin: 15px auto;
  width: 500px;
  position: relative;
}

#d3svgLinearMap {
  margin: 25px auto;
  width: 600px;
  position: relative;
  display: none;
}

.labels {
  font-family: sans-serif;
  font-size: 12px;
  text-anchor: middle;
}

#tooltipOPM {
  top: 200px;
  left: 210px;
  text-align: left;
  font-size: 12px;
  // border: 1px dashed #5bc0de;
  position: absolute;
  padding: 10px;
  background-color: #efc;
  display: none;
}

#tooltipOPM .display-name {
  text-decoration: underline;
}

#enzymeTooltip {
  top: 240px;
  left: 250px;
  text-align: left;
  // border: 1px dashed #5bc0de;
  position: absolute;
  padding: 10px;
  background-color: #efc;
  display: none;
}

#enzymeTooltip .display-name {
  text-decoration: underline;
}
</style>

class OlabCst {
  constructor(cst) {
    // console.log("OlabCst.constructor() ...");
    if (cst == null) {
      this._initCst();
    } else {
      this.olab_id = cst.olab_id;
      this.cstbs = cst.cstbs;
      this.seq = cst.seq;
      this.backwards = cst.backwards;
      this.forwards = cst.forwards;
      this.diag_forwards = cst.diag_forwards;
      this.diag_backwards = cst.diag_backwards;
      this.diag_upstream_seq = cst.diag_upstream_seq;
      this.diag_downstream_seq = cst.diag_downstream_seq;
      this.cpcr_forwards = cst.cpcr_forwards;
      this.cpcr_backwards = cst.cpcr_backwards;
      this.cpcr_upstream_seq = cst.cpcr_upstream_seq;
      this.cpcr_upstream_seq2 = cst.cpcr_upstream_seq2;
      this.cpcr_downstream_seq = cst.cpcr_downstream_seq;
      this.cpcr_downstream_seq2 = cst.cpcr_downstream_seq2;
      this.pmr_diag_mode = cst.pmr_diag_mode;
    }
  }

  _initCst() {
    // console.log("OlabCst._initCst() ...");
    this.olab_id = null;
    this.cstbs = null;
    this.seq = null;
    this.backwards = null;
    this.forwards = null;
    this.diag_forwards = null;
    this.diag_backwards = null;
    this.diag_upstream_seq = null;
    this.diag_downstream_seq = null;
    this.cpcr_forwards = null;
    this.cpcr_backwards = null;
    this.cpcr_upstream_seq = null;
    this.cpcr_upstream_seq2 = null;
    this.cpcr_downstream_seq = null;
    this.cpcr_downstream_seq2 = null;
    this.pmr_diag_mode = null;
  }

  set(cst) {
    // console.log("OlabCst.set() cst =", cst);
    if (!cst) {
      this._initCst();
    } else {
      this.olab_id = cst.olab_id;
      this.cstbs = cst.cstbs;
      this.seq = cst.seq;
      this.backwards = cst.backwards;
      this.forwards = cst.forwards;
      this.diag_forwards = cst.diag_forwards;
      this.diag_backwards = cst.diag_backwards;
      this.diag_upstream_seq = cst.diag_upstream_seq;
      this.diag_downstream_seq = cst.diag_downstream_seq;
      this.cpcr_forwards = cst.cpcr_forwards;
      this.cpcr_backwards = cst.cpcr_backwards;
      this.cpcr_upstream_seq = cst.cpcr_upstream_seq;
      this.cpcr_upstream_seq2 = cst.cpcr_upstream_seq2;
      this.cpcr_downstream_seq = cst.cpcr_downstream_seq;
      this.cpcr_downstream_seq2 = cst.cpcr_downstream_seq2;
      this.pmr_diag_mode = cst.pmr_diag_mode;
    }
  }
}

export { OlabCst };

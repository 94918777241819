<template>
  <div>
    <div class="list-group list-group-flush">
      <div
        class="list-group-item d-flex mb-1 alimem-items-center"
        v-for="proj in projs"
        :key="proj.olab_id"
      >
        <div class="container">
          <div class="row">
            <div class="col-1 align-self-center">
              <button
                type="button"
                class="btn btn-info"
                data-toggle="modal"
                data-target="#selectEditItem"
                v-on:click="registerProj(proj)"
                data-backdrop="static"
                data-keyboard="false"
              >
                <font-awesome-icon icon="edit" />
              </button>
            </div>

            <div class="col-6 col-sm-4 align-self-center">
              <OlabProjectSVGGen :strain="selectedStrain" :proj="proj" />
            </div>

            <div class="col-12 col-sm-6 mt-2 align-self-center text-wrap">
              <a
                class="h5 text-info"
                data-toggle="modal"
                href="#strainProjDetailInfo"
                v-on:click="registerProj(proj)"
                data-backdrop="static"
                data-keyboard="false"
                >{{ proj.name }}</a
              >

              <div class="h6 mt-2">
                <span>{{ proj.olab_id }}</span>
              </div>
              <div class="h6 mt-2">
                <span>{{ proj.creator }}</span>
              </div>
            </div>

            <!-- Modal: strainProjDetailInfo -->
            <div
              v-if="selectedProj != null"
              class="modal fade"
              id="strainProjDetailInfo"
            >
              <div class="modal-dialog modal-lg">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">
                      OLAB ID:
                      <span class="h5 text-dark">{{
                        selectedProj.olab_id
                      }}</span>
                    </h5>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <!-- .modal-header -->
                  <div class="modal-body">
                    <div class="text-wrap">
                      <span class="mr-1">Name:</span>
                      <span class="h5 text-dark mr-5">{{
                        selectedProj.name
                      }}</span>
                    </div>
                    <div v-if="selectedProj.creator" class="py-1 text-wrap">
                      <span class="mr-1">Creator:</span>
                      <span class="h5 text-dark">{{
                        selectedProj.creator
                      }}</span>
                    </div>
                  </div>
                  <!-- .modal-body -->
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                  <!-- .modal-footer -->
                </div>
                <!-- .modal-content -->
              </div>
              <!-- .modal-dialog .modal-lg-->
            </div>
            <!-- .modal fade #strainProjDetailInfo -->
            <!-- Modal: selectEditItem -->
            <div
              v-if="selectedProj != null"
              class="modal fade"
              id="selectEditItem"
            >
              <div class="modal-dialog modal-lg">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">
                      OLAB ID:
                      <span class="text-dark">{{ selectedProj.olab_id }}</span>
                    </h5>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <!-- .modal-header -->
                  <div class="modal-body">
                    <form>
                      <fieldset class="form-group">
                        <!-- <legend>{{selectedType}} Info</legend> -->
                        <div class="text-wrap">
                          <label class="form-control-label">
                            Name:
                          </label>
                          <span class="ml-1">{{ regProj.name }}</span>
                        </div>

                        <div class="text-wrap">
                          <label class="form-control-label">Creator:</label>
                          <span class="ml-1">{{ regProj.creator }}</span>
                        </div>
                        <div>
                          <label class="form-control-label">Access Role:</label>
                          <select
                            class="form-control"
                            v-model="regProj.strainRole"
                          >
                            <option disabled value="null"
                              >Please select one</option
                            >
                            <option value="member">Member</option>
                            <option value="blocked">Blocked</option>
                          </select>
                          <!-- <span>selected = {{ item.role }}</span> -->
                        </div>
                      </fieldset>
                      <!-- .form-group -->
                    </form>
                  </div>
                  <!-- .modal-body -->
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-primary"
                      data-dismiss="modal"
                      v-on:click="handleSaveStrainRole(regProj)"
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                  <!-- .modal-footer -->
                </div>
                <!-- .modal-content -->
              </div>
              <!-- .modal-dialog .modal-lg-->
            </div>
            <!-- .modal fade #selectEditItem -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import OlabProjectSVGGen from "@/components/OlabProjectSVGGen";
import { OlabItem } from "../olab/olabitem.js";
import { OlabUtils } from "../olab/olabutils.js";

export default {
  name: "databasestrainprojlist",
  data: function() {
    return {
      selectedProj: null,
      regProj: new OlabItem()
    };
  },
  components: {
    FontAwesomeIcon,
    OlabProjectSVGGen
  },
  props: [
    "user",
    "selectedStrain",
    "projs",
    "statusObj",
    "errorObj",
    "selectedType"
  ],
  // mounted: function() {
  //   OlabUtils.infoLog(
  //     "DatabaseProjUserList mounted ... selectedStrain = " + this.selectedStrain
  //   );
  // },
  methods: {
    registerProj: function(proj) {
      this.selectedProj = proj;
      OlabUtils.infoLog("registerProj: regProj = " + proj);
      this.regProj.set(proj);
      this.regProj.strainRole = OlabUtils.getStrainRole(
        this.regProj,
        this.selectedStrain
      );
    },
    handleSaveStrainRole: function(proj) {
      OlabUtils.infoLog(
        "handleSaveStrainRole: proj.strainRole = " + proj.strainRole
      );
      const addMem = proj.strainRole === "member" ? true : false;
      this.$emit("doSaveStrainRole", {
        proj: proj,
        addMem: addMem,
        strain: this.selectedStrain
      });
    }
  }
};
</script>

import _ from "lodash";
import { OlabDesignBox } from "./olabdesignbox.js";
import { OlabPrimer } from "./olabprimer.js";
import { OlabUtils } from "./olabutils.js";
import { OlabAxios } from "./olabaxios.js";

class OlabDesign {
  constructor(dsgn) {
    // OlabUtils.infoLog("OlabDesign.constructor() ...");
    if (dsgn == null) {
      this._initDesign();
    } else {
      let hp = Object.prototype.hasOwnProperty;
      this.olab_id = hp.call(dsgn, "olab_id") ? dsgn.olab_id : null;
      this.olab_type = hp.call(dsgn, "olab_type") ? dsgn.olab_type : null;
      this.name = hp.call(dsgn, "name") ? dsgn.name : null;
      this.creator = hp.call(dsgn, "creator") ? dsgn.creator : null;
      this.desc = hp.call(dsgn, "desc") ? dsgn.desc : null;
      this.project_id = hp.call(dsgn, "project_id") ? dsgn.project_id : null;
      this.cart_id = hp.call(dsgn, "cart_id") ? dsgn.cart_id : null;
      this.dsgn_boxes = hp.call(dsgn, "dsgn_boxes") ? dsgn.dsgn_boxes : [];
      this.configured = hp.call(dsgn, "configured") ? dsgn.configured : false;
      this.pmr_setting = hp.call(dsgn, "pmr_setting")
        ? dsgn.pmr_setting
        : "FULL";
      this.pmr_3p_len = hp.call(dsgn, "pmr_3p_len") ? dsgn.pmr_3p_len : 20;
      this.pmr_5p_len = hp.call(dsgn, "pmr_5p_len") ? dsgn.pmr_5p_len : 20;
      this.package_present = hp.call(dsgn, "package_present")
        ? dsgn.package_present
        : false;
      this.constructs = hp.call(dsgn, "constructs") ? dsgn.constructs : [];
      this.comb_pmrs = hp.call(dsgn, "comb_pmrs") ? dsgn.comb_pmrs : [];
      this.uniq_pmrs = hp.call(dsgn, "uniq_pmrs") ? dsgn.uniq_pmrs : [];
    }
  }

  // Protected methods
  _initDesign() {
    OlabUtils.infoLog("OlabDesign._initDesign() ...");
    // Common fields: internal use
    this.olab_id = null;
    this.olab_type = null;

    this.name = null;
    this.desc = null;
    this.project_id = null;
    this.cart_id = null;
    this.dsgn_boxes = [];
    this.configured = false;
    this.pmr_setting = "FULL";
    this.pmr_3p_len = 20;
    this.pmr_5p_len = 20;
    this.package_present = false;
    this.constructs = null;
    this.comb_pmrs = null;
    this.uniq_pmrs = null;
  }

  _set(dsgn) {
    this.olab_id = dsgn.olab_id;
    this.olab_type = dsgn.olab_type;
    this.name = dsgn.name;
    this.creator = dsgn.creator;
    this.project_id = dsgn.project_id;
    this.cart_id = dsgn.cart_id;
    this.dsgn_boxes = dsgn.dsgn_boxes;
    this.desc = dsgn.desc;
    this.configured = dsgn.configured;
    this.pmr_setting = dsgn.pmr_setting;
    this.pmr_3p_len = dsgn.pmr_3p_len;
    this.pmr_5p_len = dsgn.pmr_5p_len;
    this.package_present = dsgn.package_present;
    this.constructs = dsgn.constructs;
    this.comb_pmrs = dsgn.comb_pmrs;
    this.uniq_pmrs = dsgn.uniq_pmrs;
  }

  _processItemField(fieldName) {
    return fieldName == null || fieldName == "" ? "is-invalid" : "is-valid";
  }

  getValidState(fieldName) {
    // OlabUtils.infoLog("getValidState: fieldName = " + fieldName);
    return this._processItemField(fieldName);
  }

  // Public methods
  async setCart(cart, type, statusObj, errorObj) {
    let designID = cart.olab_id;
    designID = designID.substr(3, designID.length);
    designID = "DS-" + designID;
    this.olab_id = designID;
    this.name = "Design using " + cart.name;
    this.desc = null;
    this.creator = cart.creator;
    this.olab_type = type;
    this.project_id = cart.project_id;
    this.cart_id = cart.olab_id;
    this.dsgn_boxes = [];
    const dsgn = await OlabAxios.getOrCreateNewDesign(
      this,
      statusObj,
      errorObj
    );
    if (dsgn) {
      // Replace name and desc (dsgn might have previously saved)
      if (this.olab_id != dsgn.olab_id) {
        OlabUtils.infoLog("Error! Return dsgn.olab_id should match existing.");
      }
      this.name = dsgn.name;
      this.desc = dsgn.desc;
      this.configured = dsgn.configured;
      this.pmr_setting = dsgn.pmr_setting;
      this.pmr_3p_len = dsgn.pmr_3p_len;
      this.pmr_5p_len = dsgn.pmr_5p_len;
      this.package_present = dsgn.package_present;
      this.dsgn_boxes = dsgn.dsgn_boxes;
    }
    // Reset the rest
    this.constructs = null;
    this.comb_pmrs = null;
    this.uniq_pmrs = null;
  }

  async previewDesign(backbone, dsgnBoxes, statusObj, errorObj) {
    // console.log(
    //   "previewDesign: olab_id =",
    //   this.olab_id,
    //   ", backbone =",
    //   backbone,
    //   ", dsgnBoxes =",
    //   dsgnBoxes
    // );
    return await OlabAxios.previewDesign(
      this,
      backbone,
      dsgnBoxes,
      statusObj,
      errorObj
    );
  }

  async saveDsgnPlasmids(statusObj, errorObj) {
    // console.log("saveDsgnPlasmids: olab_id =", this.olab_id);
    return await OlabAxios.saveDsgnPlasmids(this, statusObj, errorObj);
  }

  async saveDesign(dsgnBoxes, statusObj, errorObj) {
    await OlabAxios.saveDsgnCstsPmrs(this, dsgnBoxes, statusObj, errorObj);

    // Update this.dsgn_boxes once save dsgnBoxes is done.
    this.dsgn_boxes = dsgnBoxes;
  }

  async generateDiagPmrs(tmLow, tmHigh, statusObj, errorObj) {
    const res = await OlabAxios.generateDiagPmrs(
      this,
      tmLow,
      tmHigh,
      statusObj,
      errorObj
    );
    // console.log("res =", res);
    return res;
  }

  _buildCstbs(box) {
    let tmpCstbs = [];
    if (box.item.comp.olab_type == "package") {
      this.package_present = true;
      // console.log("_buildCstbs: box =", box);
      // console.log("_buildCstbs: box.item =", box.item);
      // console.log("_buildCstbs: box.item.seq =", box.item.seq);
      // comps is the list of items in the package
      const comps = box.item.seq.comps;
      for (const comp of comps) {
        // Create a cstb for each comp
        tmpCstbs.push(OlabDesignBox.createCstb(box, comp));
      }
    } else {
      tmpCstbs.push(box.cstb);
    }
    return tmpCstbs;
  }

  _buildPrimerObjs(box, primerObjArr) {
    // console.log(
    //   "_buildPrimerObjs: box = ",
    //   box,
    //   ", primerObjArr.length = ",
    //   primerObjArr.length
    // );
    if (box && primerObjArr) {
      if (box.item.comp.olab_type == "package") {
        this.package_present = true;
        // comps is the list of items in the package
        const comps = box.item.seq.comps;
        for (const comp of comps) {
          const cstbs = [];
          // Create a cstb for each comp
          cstbs.push(OlabDesignBox.createCstb(box, comp));
          primerObjArr.push({
            cstbs: cstbs,
            forwards: [],
            backwards: []
          });
        }
      } else {
        const cstbs = [];
        cstbs.push(box.cstb);
        primerObjArr.push({
          cstbs: cstbs,
          forwards: [],
          backwards: []
        });
      }
      return primerObjArr.length;
    } else {
      OlabUtils.infoLog(
        "_buildPrimerObjs - box is null or primerObjArr is null!"
      );
    }
    return 0;
  }

  _removeNullPrimers(pmrs) {
    const resultPmrs = [];
    for (let i = 0; i < pmrs.length; i++) {
      if (pmrs[i].seq) {
        resultPmrs.push(pmrs[i]);
      }
    }
    return resultPmrs;
  }

  computeDesignPrimers(primerObjArr) {
    let csts = [];
    let combPmrs = [];
    for (let i = 0; i < primerObjArr.length; i++) {
      csts.push(primerObjArr[i]);
      const backwards = primerObjArr[i].backwards;
      const forwards = primerObjArr[i].forwards;
      // console.log("backwards.length = " + backwards.length + ", forwards.length = " + forwards.length);

      // Add remove null primers as a preprocessing to improve performance
      const bwds = this._removeNullPrimers(backwards);
      const fwds = this._removeNullPrimers(forwards);

      // combPmrs = _.concat(combPmrs, bwds, fwds);
      // Performance fix: push.apply (to merge arrays) is significantly faster than concat here.
      // NOTE: Do not use this method if the second array is very large
      combPmrs.push.apply(combPmrs, bwds);
      combPmrs.push.apply(combPmrs, fwds);
    }

    // Only contains unique seqs
    const uniqPmrArray = _.uniqBy(combPmrs, "seq");

    this.constructs = csts;
    this.comb_pmrs = combPmrs;
    this.uniq_pmrs = uniqPmrArray;
    // console.log("constructs.length = " + this.constructs.length);
    // console.log("uniq_pmrs.length = " + this.uniq_pmrs.length);
    // console.log("comb_pmrs.length = " + this.comb_pmrs.length);
  }

  computeConstruct(dsgnBoxes, primerObjArr, errorObj) {
    // console.log("computeConstruct: dsgnBoxes =", dsgnBoxes);
    // console.log("primerObjArr.length =", primerObjArr.length);
    // console.log("computeConstruct: dsgn_boxes =", this.dsgn_boxes);

    this.package_present = false;
    if (dsgnBoxes && dsgnBoxes.length > 0) {
      let createdPrObjCnt = this._buildPrimerObjs(dsgnBoxes[0], primerObjArr);

      for (let index = 1; index < dsgnBoxes.length; index++) {
        if (dsgnBoxes[index].item === null) {
          errorObj.message = "Problem: Design's box has null item";
          return;
        }
        const snapshotPrObjCnt = createdPrObjCnt;
        // console.log("dsgnBoxs[", index, "] =", dsgnBoxes[index]);
        const indexBoxCstbs = this._buildCstbs(dsgnBoxes[index]);
        let cstbsLen = indexBoxCstbs.length;
        // console.log("snapshotPrObjCnt = ", snapshotPrObjCnt);
        // console.log("cstbsLen = ", cstbsLen);
        // console.log("package_op = ", dsgnBoxes[index].cstb.package_op);
        if (dsgnBoxes[index].cstb.package_op === "linear") {
          // console.log("package_op === linear");
          if (cstbsLen > snapshotPrObjCnt) {
            // console.log("** cstbsLen > snapshotPrObjCnt");
            // Copy the last primerObjArr element and duplicate diff times.
            const diff = cstbsLen - snapshotPrObjCnt;
            const lastCstbs = primerObjArr[snapshotPrObjCnt - 1].cstbs.slice();
            for (let i = 0; i < diff; i++) {
              const tmpCstbs = lastCstbs.slice();
              const tmpObj = {
                cstbs: tmpCstbs,
                forwards: [],
                backwards: []
              };
              primerObjArr.push(tmpObj);
            }
          } else if (cstbsLen < snapshotPrObjCnt) {
            // console.log("** ** cstbsLen < snapshotPrObjCnt");
            const diff = snapshotPrObjCnt - cstbsLen;
            const comps = dsgnBoxes[index].item.seq.comps;
            // const mod = diff % comps.length;
            // console.log("module =", mod);
            // assert: module should be 0
            for (let i = 0; i < diff; i += comps.length) {
              for (const comp of comps) {
                // Create a cstb for each comp
                indexBoxCstbs.push(
                  OlabDesignBox.createCstb(dsgnBoxes[index], comp)
                );
              }
            }
            cstbsLen = indexBoxCstbs.length;
          }
          createdPrObjCnt = primerObjArr.length;
          // now we should have cstbsLen == snapshotPrObjCnt
          for (let i = 0; i < cstbsLen; i++) {
            primerObjArr[i].cstbs.push(indexBoxCstbs[i]);
          }
        } else {
          // handle combination or single case
          // Need to multiply for combination case
          if (cstbsLen > 1) {
            const multipler = cstbsLen - 1;
            for (let i = 0; i < multipler; i++) {
              for (let j = 0; j < snapshotPrObjCnt; j++) {
                const tmpCstbs = primerObjArr[j].cstbs.slice();
                const tmpObj = {
                  cstbs: tmpCstbs,
                  forwards: [],
                  backwards: []
                };
                primerObjArr.push(tmpObj);
              }
            }
          }
          createdPrObjCnt = primerObjArr.length;
          for (let i = 0; i < cstbsLen; i++) {
            for (let j = 0; j < snapshotPrObjCnt; j++) {
              const k = i * snapshotPrObjCnt + j;
              primerObjArr[k].cstbs.push(indexBoxCstbs[i]);
            }
          }
        }
      }

      // Classify and compute primers
      // Construct ID has to start from 1 ...
      let cstIDSuffix = 1;
      // Switch "DS" to "CS"
      let cstID = this.olab_id.substr(2);
      cstID = "CS" + cstID;
      for (const prObj of primerObjArr) {
        OlabPrimer.classifyPrimers(prObj, errorObj);
        // Terminate computation if error detected
        if (errorObj.message) {
          return;
        }
        // prObj's olab_id
        prObj.olab_id = cstID + "-" + cstIDSuffix.toString().padStart(4, "0");
        cstIDSuffix++;
        OlabPrimer.computePrimers(
          prObj,
          this.pmr_3p_len,
          this.pmr_5p_len,
          this.pmr_setting
        );
      }
    } else {
      OlabUtils.infoLog(
        "OlabDesign: computeConstruct - dsgnBoxes is null or empty!"
      );
    }
  }

  getAllPrimers() {
    // design_id,construct_id, construct_sequence,
    // box_id,box_name,box_size, box_sequence,
    // primer_name_fwd,primer_sequence_fwd,primer_length_fwd,primer_tm_fwd,
    // primer_name_rev,primer_sequence_rev,primer_length_rev,primer_tm_rev

    const resPmrs = [];
    for (let i = 0; i < this.constructs.length; i++) {
      const cst = this.constructs[i];
      let constructSeq = "";
      for (let j = 0; j < cst.cstbs.length; j++) {
        constructSeq += cst.cstbs[j].seq;
      }
      OlabUtils.infoLog("constructSeq = " + constructSeq);
      for (let j = 0; j < cst.cstbs.length; j++) {
        const cstb = cst.cstbs[j];
        const fwd = cst.forwards[j];
        const fwdLen = fwd.seq ? fwd.seq.length : 0;
        const bwd = cst.backwards[j];
        const bwdLen = bwd.seq ? bwd.seq.length : 0;
        const pmrObj = {
          primer_tm_rev: parseFloat(bwd.tm.toFixed(2)),
          primer_length_rev: bwdLen,
          primer_sequence_rev: bwd.seq,
          primer_name_rev: bwd.name,
          primer_tm_fwd: parseFloat(fwd.tm.toFixed(2)),
          primer_length_fwd: fwdLen,
          primer_sequence_fwd: fwd.seq,
          primer_name_fwd: fwd.name,
          box_sequence: cstb.seq,
          box_size: cstb.range,
          box_name: cstb.display_name,
          box_id: cstb.box_id,
          construct_sequence: constructSeq,
          construct_id: cst.olab_id,
          design_id: this.olab_id
        };
        // Only print constructSeq once per construct
        constructSeq = "";
        resPmrs.push(pmrObj);
      }
    }
    return resPmrs;
  }

  getOrderPrimers() {
    // Oligo Name, Scale, Purification, Sequence (5' -> 3')
    const resPmrs = [];
    const sortedUniqPmrArray = _.sortBy(this.uniq_pmrs, ["olab_id"]);
    for (let i = 0; i < sortedUniqPmrArray.length; i++) {
      const pmr = sortedUniqPmrArray[i];
      const pmrObj = {
        "Sequence (5' -> 3')": pmr.seq,
        Purification: "",
        Scale: "",
        "Oligo Name": pmr.name
      };
      resPmrs.push(pmrObj);
    }
    return resPmrs;
  }

  async downloadGenbank(statusObj, errorObj) {
    // console.log(`downloadGenbank: selectedType = ${this.selectedType}`);
    // console.log("downloadGenbank: dsgn =", dsgn);
    // Set progress message and status indicator
    statusObj.progress = true;

    const dsgnBody = {
      olab_id: this.olab_id
    };
    const genbankFile = await OlabAxios.downloadDsgnGenbank(
      dsgnBody,
      statusObj,
      errorObj
    );
    // download file to client machine
    // console.log("downloadGenbank: genbankFile =", genbankFile);
    if (genbankFile) {
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.style.visibility = "hidden";

      // https://developer.mozilla.org/en-US/docs/Glossary/MIME_type
      // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
      const blob = new Blob([genbankFile], {
        type: "application/zip"
      });
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = this.olab_id + "-genbank.zip";
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }
  }

  async downloadFasta(statusObj, errorObj) {
    // console.log("downloadFasta ...");
    statusObj.progress = true;
    // statusObj.message = "Generating FASTA file ...";

    const dsgnBody = {
      olab_id: this.olab_id
    };
    const fastaFile = await OlabAxios.downloadDsgnFasta(
      dsgnBody,
      statusObj,
      errorObj
    );
    // download file to client machine
    // console.log("downloadFasta =", fastaFile);
    if (fastaFile) {
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.style.visibility = "hidden";

      // https://developer.mozilla.org/en-US/docs/Glossary/MIME_type
      // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
      const blob = new Blob([fastaFile], {
        type: "application/zip"
      });
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = this.olab_id + "-fasta.zip";
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }
  }

  async download96WellPlateFile(statusObj, errorObj) {
    // console.log("download96WellPlateFile ...");
    statusObj.progress = true;
    statusObj.message = "Generating 96 well-plate file ...";

    const dsgnBody = {
      olab_id: this.olab_id
    };
    const wellPlateFile = await OlabAxios.download96WPMFile(
      dsgnBody,
      statusObj,
      errorObj
    );
    // download file to client machine
    // console.log("download96WellPlateFile: wellPlatFile =", wellPlatFile);
    if (wellPlateFile) {
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.style.visibility = "hidden";

      const blob = new Blob([wellPlateFile], {
        type: "application/vnd.ms-excel"
      });
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = this.olab_id + ".xlsx";
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }
  }

  async downloadPrimersFile(dlType, statusObj, errorObj) {
    // console.log("downloadPrimersFile ...");
    statusObj.progress = true;
    // statusObj.message = "Generating primers file ...";

    const customerCode = process.env.VUE_APP_OUTPUT_CODE_TYPE
      ? process.env.VUE_APP_OUTPUT_CODE_TYPE
      : "standard";
    // console.log("customerCode =", customerCode);
    const dsgnBody = {
      olab_id: this.olab_id,
      type: customerCode
    };

    let pmrsFile = null;
    let fileName = this.name;
    if (dlType == "full") {
      fileName += "_Full_Primers.csv";
      pmrsFile = await OlabAxios.downloadFullPrimersFile(
        dsgnBody,
        statusObj,
        errorObj
      );
    } else if (dlType == "order") {
      fileName += "_Order_Primers.csv";
      pmrsFile = await OlabAxios.downloadOrderPrimersFile(
        dsgnBody,
        statusObj,
        errorObj
      );
    } else if (dlType == "construct") {
      fileName += "_Construct_Primers.csv";
      pmrsFile = await OlabAxios.downloadConstructPrimersFile(
        dsgnBody,
        statusObj,
        errorObj
      );
    } else if (dlType == "diagseq") {
      fileName += "_Diag_Seq_Primers.csv";
      pmrsFile = await OlabAxios.downloadDiagSeqPrimersFile(
        dsgnBody,
        statusObj,
        errorObj
      );
    } else if (dlType == "diagcpcr") {
      fileName += "_Diag_cPCR_Primers.csv";
      pmrsFile = await OlabAxios.downloadDiagCPCRPrimersFile(
        dsgnBody,
        statusObj,
        errorObj
      );
    }

    if (pmrsFile) {
      // download file to client machine
      // console.log("Download primers fle: ", fileName, ", pmrsFile =", pmrsFile);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.style.visibility = "hidden";

      const blob = new Blob([pmrsFile], { type: "octet/stream" });
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }
  }
}

export { OlabDesign };

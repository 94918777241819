<template>
  <div>
    <article id="page-multicolumn" class="page-section text-center py-5">
      <header class="page-section-header container">
        <div class="row justify-content-center">
          <div class="col-11 col-sm-10 col-md-8">
            <h2 class="page-section-title">Bio-Calculators</h2>
            <p class="page-section-text">
              A collection of calculators made easy for Life Science
              researchers.
            </p>
          </div>
        </div>
      </header>

      <section class="layout-multicolumn container">
        <div class="row justify-content-center family-sans text-uppercase">
          <section class="mb-5 col-10 col-md-6 col-lg-4 col-xl-3">
            <a href="/molarity">
              <OlabMolaritySVGGen
                name="Molarity"
                textPosX="202"
                textPosY="180"
              />
            </a>
          </section>
          <section class="mb-5 col-10 col-md-6 col-lg-4 col-xl-3">
            <a href="/dilution">
              <OlabDilutionSVGGen
                name="Dilution"
                textPosX="168"
                textPosY="402"
              />
            </a>
          </section>
          <section class="mb-5 col-10 col-md-6 col-lg-4 col-xl-3">
            <a href="/mediumbuffer">
              <OlabMediumSVGGen name="Medium" textPosX="172" textPosY="400" />
            </a>
          </section>
          <section class="mb-5 col-10 col-md-6 col-lg-4 col-xl-3">
            <a href="/quick">
              <OlabMediumSVGGen name="Quick" textPosX="185" textPosY="400" />
            </a>
          </section>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
// @ is an alias to /src
import OlabMolaritySVGGen from "@/components/OlabMolaritySVGGen";
import OlabDilutionSVGGen from "@/components/OlabDilutionSVGGen";
import OlabMediumSVGGen from "@/components/OlabMediumSVGGen";

export default {
  name: "BioCalculators",
  components: {
    OlabMolaritySVGGen,
    OlabDilutionSVGGen,
    OlabMediumSVGGen
  }
};
</script>

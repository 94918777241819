<template>
  <div v-if="user && selectedCart">
    <!-- <button
      type="button"
      class="btn btn-outline-success ml-1"
      data-toggle="modal"
      data-target="#cartProfile"
      data-backdrop="static"
      data-keyboard="false"
    >
      <font-awesome-icon icon="shopping-cart" />
    </button> -->
    <div class="modal fade" id="cartProfile">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">OLAB ID: {{ selectedCart.olab_id }}</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <!-- .modal-header -->
          <div class="modal-body">
            <div v-if="selectedCart.name" class="pt-2">
              <span>Name:</span>
              <span class="h5 text-dark ml-1">{{ selectedCart.name }}</span>
            </div>

            <div class="py-1">
              <div class="card">
                <div class="card-header text-dark">Description</div>
                <div class="card-body">
                  <div class="py-1">
                    <span class="text-dark">{{ selectedCart.desc }}</span>
                  </div>
                </div>
                <!-- .card-body -->
              </div>
              <!-- .card -->
            </div>
            <div v-if="selectedCart" class="py-1">
              <div class="card">
                <div class="card-header text-dark">Components</div>
                <div class="card-body">
                  <div v-if="selectedCart.comps">
                    <div class="list-group list-group-flush">
                      <div
                        class="list-group-item d-flex mb-1 align-items-center"
                        v-for="(comp, index) in selectedCart.comps"
                        :key="index"
                      >
                        <div class="col-8 text-left text-dark">
                          <div class="my-1">
                            <span class="text-secondary mr-1">OLAB ID:</span>
                            <span>{{ comp.olab_id }}</span>
                            <span v-if="comp.length" class="ml-3">
                              <span class="text-secondary mr-1">Length:</span>
                              <span>{{ comp.length }}</span>
                            </span>
                          </div>
                          <div class="my-1">
                            <span v-if="comp.name">
                              <span class="text-secondary mr-1">Name:</span>
                              <span class="mr-3">{{ comp.name }}</span>
                            </span>
                            <span v-if="comp.use_seq_as">
                              <span class="text-secondary mr-1">Use As:</span>
                              <span>{{ comp.use_seq_as }}</span>
                            </span>
                          </div>
                          <div class="my-1">
                            <span v-if="comp.old_locus_tag">
                              <span class="text-secondary mr-1"
                                >Old Locus Tag:</span
                              >
                              <span>{{ comp.old_locus_tag }}</span>
                            </span>
                            <span v-if="comp.locus_tag" class="ml-3">
                              <span class="text-secondary mr-1"
                                >Locus Tag:</span
                              >
                              <span>{{ comp.locus_tag }}</span>
                            </span>
                          </div>
                        </div>
                        <div class="col-4 text-right">
                          <div class="my-1">
                            <span class="text-secondary small mr-1"
                              >Quantity:</span
                            >
                            <span class="h4 mr-2">
                              {{ comp.qty }}
                            </span>
                          </div>
                          <div class="my-1">
                            <span class="text-secondary small mr-1"
                              >Available:</span
                            >
                            <span class="h4 mr-2">
                              {{ comp.available_count }}
                            </span>
                          </div>
                          <div class="my-1">
                            <span class="text-secondary small mr-1">Used:</span>
                            <span class="h4 mr-2">
                              {{ comp.used_count }}
                            </span>
                          </div>
                          <div class="my-2">
                            <span class="mx-1">
                              <button
                                :disabled="cartIsFull"
                                type="button"
                                class="btn btn-primary"
                                v-on:click="incrItem(comp)"
                              >
                                <font-awesome-icon icon="plus" />
                              </button>
                            </span>
                            <span class="mx-1">
                              <button
                                :disabled="disableDelete(comp)"
                                type="button"
                                class="btn btn-danger"
                                v-on:click="decrItem(comp, index)"
                              >
                                <font-awesome-icon icon="minus" />
                              </button>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="py-1 text-right mr-5">
                      <span class="mr-2 text-secondary">Total:</span>
                      <span class="h3">{{ cartQty }}</span>
                    </div>
                  </div>
                </div>
                <!-- .card-body -->
              </div>
              <!-- .card -->
            </div>
          </div>
          <!-- .modal-body -->

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
          <!-- .modal-footer -->
        </div>
        <!-- .modal-content -->
      </div>
      <!-- .modal-dialog .modal-lg-->
    </div>
    <!-- .modal fade -- End of cartProfile -->
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { OlabUtils } from "../olab/olabutils.js";
import { OlabAxios } from "../olab/olabaxios.js";

export default {
  name: "olabcartprofile",
  props: ["user", "selectedProject", "selectedCart", "statusObj", "errorObj"],
  components: {
    FontAwesomeIcon
  },
  computed: {
    cartQty: function() {
      let qty = 0;
      if (this.selectedCart != null && this.selectedCart.comps != null) {
        for (let idx in this.selectedCart.comps) {
          qty = qty + this.selectedCart.comps[idx].qty;
        }
      }
      return qty;
    },
    cartIsFull: function() {
      return this.cartQty >= OlabUtils.MAX_ITEMS_PER_CART ? true : false;
    }
  },
  methods: {
    disableDelete(item) {
      return item.qty < 1 || item.available_count < 1;
    },
    incrItem: function(item) {
      OlabUtils.infoLog("increment item: qty = " + item.qty);
      item.qty++;
      item.available_count++;
      OlabAxios.saveCart(this.selectedCart, this.statusObj, this.errorObj);
    },
    decrItem: function(item, index) {
      OlabUtils.infoLog(
        "decrement item: qty = " + item.qty + ", index = " + index
      );
      if (item.qty > 0 && item.available_count > 0) {
        item.qty--;
        item.available_count--;
      }
      if (item.qty == 0) {
        this.selectedCart.comps.splice(index, 1);
      }
      OlabAxios.saveCart(this.selectedCart, this.statusObj, this.errorObj);
    }
  }
};
</script>

<template>
  <div>
    <div class="list-group list-group-flush">
      <div
        class="list-group-item d-flex align-items-center"
        v-for="gn in genes"
        :key="gn.olab_id"
      >
        <div class="container">
          <div class="row">
            <div class="col-6 col-sm-4 align-self-center">
              <OlabDnaSVGGen name="Gene" textPosX="265" textPosY="395" />
            </div>

            <div class="col-12 col-sm-8 mt-2 align-self-center text-wrap">
              <a
                class="h5 text-info"
                data-toggle="modal"
                href="#geneDetailInfo"
                v-on:click="registerGene(gn)"
                data-backdrop="static"
                data-keyboard="false"
              >
                <span v-if="gn.name">{{ gn.name }}</span>
                <span v-else-if="gn.synonym_name">{{ gn.synonym_name }}</span>
                <span v-else-if="gn.synonym_name2">{{ gn.synonym_name2 }}</span>
                <span v-else-if="gn.old_locus_tag">{{ gn.old_locus_tag }}</span>
                <span v-else-if="gn.locus_tag">{{ gn.locus_tag }}</span>
                <span v-else>{{ gn.olab_id }}</span></a
              >

              <div
                v-if="gn.aux && gn.aux.strain_name"
                class="h6 mt-2 text-truncate"
              >
                {{ gn.aux.strain_name }}
              </div>
              <div class="h6 mt-2 text-truncate">
                <span v-if="gn.old_locus_tag" class="mr-3">{{
                  gn.old_locus_tag
                }}</span>
                <span v-if="gn.locus_tag">{{ gn.locus_tag }}</span>
              </div>
              <div class="mb-0 text-monospace text-truncate">
                {{ gn.gene_stats.short_seq }}
              </div>
              <div class="h6">
                <span class="mr-5">{{ gn.gene_stats.len }}</span>
                <span>
                  {{ gn.sequence.comp | convertCompToStrand }}
                </span>
              </div>
              <div class="h6 mt-2">
                {{ gn.olab_id }}
              </div>
              <div
                v-if="gn.product"
                class="h6 text-secondary mt-2 text-truncate"
              >
                {{ gn.product }}
              </div>
              <div v-if="gn.desc" class="h6 text-secondary mt-2 text-truncate">
                {{ gn.desc }}
              </div>
            </div>

            <!-- Modal: geneDetailInfo -->
            <div
              v-if="selectedGene != null"
              class="modal fade"
              id="geneDetailInfo"
            >
              <div class="modal-dialog modal-lg">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">
                      OLAB ID:
                      <span class="h5 text-dark">{{
                        selectedGene.olab_id
                      }}</span>
                    </h5>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <!-- .modal-header -->
                  <div class="modal-body">
                    <div class="text-wrap">
                      <span v-if="selectedGene.name">
                        <span class="mr-1">Name:</span>
                        <span class="h5 text-dark mr-5">{{
                          selectedGene.name
                        }}</span>
                      </span>
                      <span v-if="selectedGene.synonym_name">
                        <span class="mr-1">Synonym Name:</span>
                        <span
                          v-if="selectedGene.synonym_name"
                          class="h5 text-dark"
                          >{{ selectedGene.synonym_name }}</span
                        >
                        <span
                          v-if="selectedGene.synonym_name2"
                          class="h5 text-dark"
                          >; {{ selectedGene.synonym_name2 }}</span
                        >
                      </span>
                    </div>
                    <div
                      v-if="selectedGene.aux && selectedGene.aux.strain_name"
                      class="py-1 text-wrap"
                    >
                      <span class="mr-1">Strain Name:</span>
                      <span class="h5 text-dark">{{
                        selectedGene.aux.strain_name
                      }}</span>
                    </div>
                    <div v-if="selectedGene.product" class="py-1 text-wrap">
                      <span class="mr-1">Product:</span>
                      <span class="h5 text-dark">{{
                        selectedGene.product
                      }}</span>
                    </div>
                    <div
                      v-if="
                        selectedGene.locus_tag || selectedGene.old_locus_tag
                      "
                      class="my-2 card"
                    >
                      <div class="card-header text-dark">Locus Tag</div>
                      <div class="card-body text-wrap">
                        <span class="mr-1">Old:</span>
                        <span class="h5 text-dark">{{
                          selectedGene.old_locus_tag
                        }}</span>

                        <span class="ml-3 mr-1">New:</span>
                        <span class="h5 text-dark">{{
                          selectedGene.locus_tag
                        }}</span>
                      </div>
                      <!-- .card-body -->
                    </div>
                    <div class="my-2 card">
                      <div class="card-header text-dark">Description</div>
                      <div class="card-body">
                        <div class="py-1 small text-dark text-wrap">
                          {{ selectedGene.desc }}
                        </div>
                      </div>
                      <!-- .card-body -->
                    </div>
                    <!-- .card -->
                    <div v-if="selectedGene.sequence != null" class="card">
                      <div class="card-header text-dark">Sequence</div>
                      <div class="card-body">
                        <span class="mr-1">Type:</span>
                        <span class="h5 text-dark">{{
                          selectedGene.sequence.type
                        }}</span>
                        <div class="pt-2">
                          <span class="mr-1">Range:</span>
                          <span class="h5 text-dark">{{ computedRange }}</span>
                          <span class="ml-5 mr-1">Strand:</span>
                          <span class="h5 text-dark">{{
                            selectedGene.sequence.comp | convertCompToStrand
                          }}</span>
                        </div>
                        <div class="py-2">
                          <span class="mr-1">Length:</span>
                          <span class="h5 text-dark">{{
                            selectedGene.gene_stats.len
                          }}</span>
                        </div>
                        <div class="py-1">
                          <span class="mr-1"># A:</span>
                          <span class="h5 text-dark">{{
                            selectedGene.gene_stats.num_a
                          }}</span>
                          <span class="ml-3 mr-1"># C:</span>
                          <span class="h5 text-dark">{{
                            selectedGene.gene_stats.num_c
                          }}</span>
                          <span class="ml-3 mr-1"># G:</span>
                          <span class="h5 text-dark">{{
                            selectedGene.gene_stats.num_g
                          }}</span>
                          <span class="ml-3 mr-1"># T:</span>
                          <span class="h5 text-dark">{{
                            selectedGene.gene_stats.num_t
                          }}</span>
                        </div>

                        <div
                          class="py-2 small text-monospace text-dark text-wrap"
                        >
                          {{ selectedGene.sequence.seq }}
                        </div>
                        <!-- <img class="card-img-bottom img-fluid" v-bind:alt="selectedGene.name" v-bind:src="printGeneImageLink"> -->
                      </div>
                      <!-- .card-body -->
                    </div>
                    <!-- .card -->
                  </div>
                  <!-- .modal-body -->
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                  <!-- .modal-footer -->
                </div>
                <!-- .modal-content -->
              </div>
              <!-- .modal-dialog .modal-lg-->
            </div>
            <!-- .modal fade #geneDetailInfo -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import OlabDnaSVGGen from "@/components/OlabDnaSVGGen";
import { OlabGene } from "../olab/olabgene.js";
// import { OlabUtils } from "../olab/olabutils.js";

export default {
  name: "databasegenelist",
  data: function() {
    return {
      selectedGene: null,
      gene: new OlabGene()
    };
  },
  props: ["user", "genes", "statusObj", "errorObj", "selectedType"],
  components: {
    // FontAwesomeIcon,
    OlabDnaSVGGen
  },
  filters: {
    convertCompToStrand: function(value) {
      // console.log("convertCompToStrand: value = " + value);
      return value === true ? "-" : "+";
    }
  },
  computed: {
    computedRange: function() {
      let range = "[]";
      const locs = this.selectedGene.sequence.locations;
      if (locs && locs.length > 0) {
        let joins = "";
        for (let i = 0; i < locs.length; i++) {
          // console.log("locs =", locs[i]);
          const loc = locs[i];
          // console.log("loc =", loc);
          if (i > 0) {
            joins = joins.concat(",");
          }
          joins = joins.concat(`${loc.from},..,${loc.to}`);
          // console.log("joins =", joins);
        }
        range = `[ ${joins} ]`;
      } else {
        range = `[ ${this.selectedGene.sequence.from},..,${this.selectedGene.sequence.to} ]`;
      }
      return range;
    }
  },
  methods: {
    registerGene: function(gn) {
      this.selectedGene = gn;
      // OlabUtils.infoLog("registerGene: gene = " + gn);
      this.gene.set(gn);
    }
  }
};
</script>

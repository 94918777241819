<template>
  <div id="app">
    <Navigation
      :user="user"
      :showBioCalculators="showBioCalculators"
      @logout="logout"
    />
    <div
      v-if="user && dbInfo && dbInfo.message"
      class="alert alert-danger text-center"
      role="alert"
    >
      {{ dbInfo.message }}
    </div>
    <router-view
      :user="user"
      :olabServerInfo="olabServerInfo"
      :bioServerInfo="bioServerInfo"
      :dbInfo="dbInfo"
      :statusObj="statusObj"
      :saveStatus="saveStatus"
      :microbes="microbes"
      :selectedProject="selectedProject"
      :selectedCart="selectedCart"
      :selectedPackage="selectedPackage"
      :errorObj="errorObj"
      @setUser="setUser"
      @logout="logout"
      @deleteItem="deleteItem"
      @saveItem="saveItem"
      @selectProject="selectProject"
      @selectCart="selectCart"
      @selectPackage="selectPackage"
      @setSaveStatus="setSaveStatus"
      @reloadCollection="reloadCollection"
      @saveProjectRole="saveProjectRole"
      @saveStrainRole="saveStrainRole"
    />
    <OlabFooter :user="user" />
  </div>
</template>

<script>
// @ is an alias to /src
import Navigation from "@/components/Navigation.vue";
import OlabFooter from "@/components/OlabFooter.vue";
import axios from "axios";
import { OlabUtils } from "./olab/olabutils.js";
import { OlabSearch } from "./olab/olabsearch";
import { OlabPackage } from "./olab/olabpackage.js";

export default {
  name: "app",
  data: function() {
    return {
      showBioCalculators: process.env.VUE_APP_SHOW_BIO_CALCULATORS === "true",
      olabServerInfo: {
        name: "NO NAME",
        owner: "OLAB Bioscience Informatics"
      },
      bioServerInfo: null,
      dbInfo: null,
      user: null,
      saveStatus: false,
      microbes: [],
      selectedProject: null,
      selectedCart: null,
      selectedPackage: null,
      statusObj: {
        message: null,
        progress: false
      },
      errorObj: {
        message: null
      }
    };
  },
  components: {
    Navigation,
    OlabFooter
  },
  async mounted() {
    try {
      const result = await axios.get("/api/v1/olabserver/info");
      const info = result.data;
      if (info) {
        // console.log(info.data.olabServerInfo);
        this.olabServerInfo = info.data.olabServerInfo;
        this.dbInfo = info.data.olabServerInfo.dbInfo;
        if (this.dbInfo && !this.dbInfo.message) {
          // In cases where dbInfo doesn't have message defined
          // console.log("message is undefined .....", this.dbInfo);
          this.dbInfo = { ...this.dbInfo, message: "" };
          // console.log("Add message to dbInfo .....", this.dbInfo);
        }
        // console.log(info.data.bioServerInfo);
        this.bioServerInfo = info.data.bioServerInfo;
      }
      let res = await axios.get("/api/v1/users/reauth");
      // OlabUtils.infoLog("reauth: user = " + res.data.data.user);
      if (res.data.status === "success" && res.data.data) {
        const userData = res.data.data;
        OlabUtils.infoLog(userData.user);
        this.setupLoginState(userData.user);
      } else {
        this.clearLoginState();
      }
    } catch (err) {
      OlabUtils.infoLog(err);
      // console.error(err);
      // console.error(err.name);
      // console.error(err.message);
      // console.error(err.response);
      // console.error(err.response.statusText);
      // console.error(err.response.data.status);
      // console.error(err.response.data.message);
      // console.error(err.response.data.stack);
      this.clearLoginState();
      this.errorObj.message = OlabUtils.getErrorMessage(err);
    }
  },
  methods: {
    reloadCollection: async function(sType) {
      OlabUtils.infoLog("reloadCollection ...");
      switch (sType) {
        case "microbes":
          this.microbes = await OlabUtils.getMicrobes();
          break;
        // default:
        //   OlabUtils.infoLog("getItems: Unknown sType = " + sType);
      }
    },
    deleteItem: async function({ item, items }) {
      // console.log("App.deleteItem (item =", item, ", items = ", items);
      const unknownTypeMsg = `deleteItem error: unknown type = ${item.olab_type}`;
      switch (item.olab_type) {
        case "user":
          try {
            const res = await axios({
              method: "DELETE",
              url: `/api/v1/users/${item.email}`
            });
            // console.log(res.data);
            if (res.data.status === "fail") {
              // console.error("Error writing document: ", res.data.statusCode);
              this.errorObj.message = OlabUtils.getErrorMessage(
                res.data.statusCode
              );
            } else {
              // console.log("Document successfully deleted! item =", item);
              // Delete item from items
              this.updateItems(item, items, true);
            }
          } catch (err) {
            // console.error("Error writing document: ", err);
            this.errorObj.message = OlabUtils.getErrorMessage(err);
          }
          break;
        default:
          // console.error("Error deleting document: ", unknownTypeMsg);
          this.errorObj.message = OlabUtils.getErrorMessage(unknownTypeMsg);
      }
    },
    saveItem: async function({ item, items }) {
      // console.log("App.saveItem (item =", item, ", items = ", items);

      switch (item.olab_type) {
        case "design":
          try {
            const res = await axios({
              method: "PATCH",
              url: `/api/v1/designs/${item.olab_id}`,
              data: {
                name: item.name,
                desc: item.desc
              }
            });
            // console.log(res.data.data.designs);
            if (res.data.status === "success") {
              // OlabUtils.infoLog("Document successfully written!");
              this.updateItems(item, items, false);
            }
          } catch (err) {
            // console.error("Error writing document: ", err);
            this.errorObj.message = OlabUtils.getErrorMessage(err);
          }
          break;
        case "project":
          try {
            const res = await axios({
              method: "PATCH",
              url: `/api/v1/projects/${item.olab_id}`,
              data: {
                name: item.name,
                // ra_keys: item.ra_keys,
                ra_locks: item.ra_locks,
                desc: item.desc,
                start_date: item.start_date,
                end_date: item.end_date
              }
            });
            // console.log(res.data.data.project);
            if (res.data.status === "success") {
              OlabUtils.infoLog("Document successfully written!");
              this.updateItems(item, items, false);
            }
          } catch (err) {
            // console.error("Error writing document: ", err);
            this.errorObj.message = OlabUtils.getErrorMessage(err);
          }
          break;
        case "cart":
          try {
            const res = await axios({
              method: "PATCH",
              url: `/api/v1/carts/${item.olab_id}`,
              data: {
                name: item.name,
                deleted: item.deleted,
                desc: item.desc
              }
            });
            // console.log(res.data.data.cart);
            if (res.data.status === "success") {
              OlabUtils.infoLog("Document successfully written!");
              this.updateItems(item, items, false);
            }
          } catch (err) {
            // console.error("Error writing document: ", err);
            this.errorObj.message = OlabUtils.getErrorMessage(err);
          }
          break;
        case "package":
          // Set library_id if this item is a package item
          if (item.package_type) {
            item.library_id = OlabPackage.pkgTypeToLibraryID(item.package_type);
          }
          try {
            const res = await axios({
              method: "PATCH",
              url: `/api/v1/packages/${item.olab_id}`,
              data: {
                name: item.name,
                deleted: item.deleted,
                desc: item.desc
              }
            });
            // console.log(res.data.data.package);
            if (res.data.status === "success") {
              OlabUtils.infoLog("Document successfully written!");
              this.updateItems(item, items, false);
            }
          } catch (err) {
            // console.error("Error writing document: ", err);
            this.errorObj.message = OlabUtils.getErrorMessage(err);
          }
          break;
        case "plasmid":
          if (this.user.role === "admin") {
            // console.log("App.saveItem: save plasmid");
            // console.log("(1) item.csv_file =", item.csv_file);
            // console.log("(2) item.fasta_file =", item.fasta_file);
            // console.log("(3) item.genbank_file =", item.genbank_file);
            // console.log("(4) item.desc =", item.desc);
            // console.log("(5) item =", item);
            try {
              const formData = new FormData();
              formData.append("name", item.name);
              formData.append("desc", item.desc);
              formData.append("wgs_confirmed", item.wgs_confirmed);
              formData.append("ra_locks", JSON.stringify(item.ra_locks));
              formData.append("csv_file", item.csv_file);
              formData.append("fasta_file", item.fasta_file);
              formData.append("genbank_file", item.genbank_file);
              const res = await axios({
                method: "PATCH",
                url: `/api/v1/plasmids/${item.olab_id}`,
                data: formData
              });
              // console.log(res.data.data.plasmid);
              if (res.data.status === "success") {
                OlabUtils.infoLog("Document successfully written!");
                item.stats = res.data.data.plasmid.stats;
                item.meta_map = res.data.data.plasmid.meta_map;
                this.updateItems(item, items, false);
              }
            } catch (err) {
              // console.error("Error writing document: ", err);
              this.errorObj.message = OlabUtils.getErrorMessage(err);
            }
          } else {
            console.log("App.saveItem plasmid required admin privilige");
          }
          break;
        case "strain":
          if (this.user.role === "admin") {
            // console.log("App.saveItem: add new strain");
            // console.log("(1) item.name =", item.name);
            // console.log("(2) item.desc =", item.desc);
            // console.log("(3) item.selectedChrm =", item.selectedChrm);
            // console.log("(4) item.csv_file =", item.csv_file);
            // console.log("(5) item.fasta_file =", item.fasta_file);
            // console.log("(6) item.genbank_file =", item.genbank_file);
            // console.log("(7) item =", item);
            try {
              const formData = new FormData();
              formData.append("name", item.name);
              formData.append("desc", item.desc);
              formData.append("wgs_confirmed", item.wgs_confirmed);
              formData.append("ra_locks", JSON.stringify(item.ra_locks));
              formData.append("plmd_array", JSON.stringify(item.plmd_array));
              formData.append("selected_chrm", item.selectedChrm);
              formData.append("csv_file", item.csv_file);
              formData.append("fasta_file", item.fasta_file);
              formData.append("genbank_file", item.genbank_file);
              const res = await axios({
                method: "PATCH",
                url: `/api/v1/strains/${item.olab_id}`,
                data: formData
              });
              // console.log(res.data.data.strain);
              if (res.data.status === "success") {
                OlabUtils.infoLog("Document successfully written!");
                item.stats = res.data.data.strain.stats;
                item.chrm_array = res.data.data.strain.chrm_array;
                this.updateItems(item, items, false);
              }
            } catch (err) {
              // console.error("Error writing document: ", err);
              this.errorObj.message = OlabUtils.getErrorMessage(err);
            }
          } else {
            console.log("App.saveItem strain required admin privilige");
          }
          break;
        case "library_sequence":
          // console.log("App.saveItem - item.deleted = " + item.deleted);
          try {
            const res = await axios({
              method: "PATCH",
              url: `/api/v1/libseqs/${item.olab_id}`,
              data: {
                name: item.name,
                deleted: item.deleted,
                desc: item.desc,
                source: item.source,
                seq_origin: item.seq_origin
              }
            });
            // console.log(res.data.data.libseq);
            if (res.data.status === "success") {
              OlabUtils.infoLog("Document successfully written!");
              this.updateItems(item, items, false);
            }
          } catch (err) {
            // console.error("Error writing document: ", err);
            this.errorObj.message = OlabUtils.getErrorMessage(err);
          }
          break;
        case "user":
          try {
            const formData = new FormData();
            formData.append("name", item.name);
            formData.append("email", item.email);
            if (item.passwd && item.confirmed_passwd) {
              formData.append("password", item.passwd);
              formData.append("passwordConfirm", item.confirmed_passwd);
            }
            formData.append("user_id", item.user_id);
            if (item.photo) {
              // olab_id is required for updating photo
              formData.append("olab_id", item.olab_id);
              formData.append("photo", item.photo);
            }
            const patchObj = {
              method: "PATCH",
              data: formData
            };
            if (this.user.role === "admin") {
              // Add role update if it is from admin
              formData.append("role", item.role);
              patchObj.url = `/api/v1/users/${item.email}`;
            } else {
              patchObj.url = "/api/v1/users/updateMe";
            }
            const res = await axios(patchObj);
            // console.log(res.data.data.user);
            if (res.data.status === "success") {
              OlabUtils.infoLog("Document successfully written!");
              // Clear passwd and confirmed_passwd
              item.passwd = null;
              item.confirmed_passwd = null;
              this.updateItems(item, items, false);
              // Update current user data if user has changed.
              if (this.user.email === item.email) {
                this.user.name = item.name;
                this.user.role = item.role;
                // Make a shallow copy of ra_keys
                this.user.ra_keys = { ...item.ra_keys };
                this.user.user_id = item.user_id;
              }
            }
          } catch (err) {
            // console.error("Error writing document: ", err);
            this.errorObj.message = OlabUtils.getErrorMessage(err);
          }
          break;
      }
    },
    saveProjectRole: async function({ usr, addMem, proj }) {
      OlabUtils.infoLog(
        "App.saveProjectRole (usr = " +
          usr +
          ", addMem = " +
          addMem +
          ", proj = " +
          proj
      );
      try {
        const res = await axios({
          method: "PATCH",
          url: "/api/v1/projects/updateMemberRole",
          data: {
            role: {
              project_olab_id: proj.olab_id,
              member_email: usr.email,
              add: addMem
            }
          }
        });
        // console.log(res.data.data.project);
        if (res.data.status === "success") {
          OlabUtils.infoLog("Document successfully written!");
          // console.log("updated Project = ", res.data.data.project);
          const updatedProj = res.data.data.project;
          proj.ra_locks = updatedProj.ra_locks;
        }
      } catch (err) {
        // console.error("Error patching document: ", err);
        this.errorObj.message = OlabUtils.getErrorMessage(err);
      }
    },
    // Note: plasmid is treated as strain
    saveStrainRole: async function({ proj, addMem, strain }) {
      OlabUtils.infoLog(
        "App.saveStrainRole (proj = " +
          proj +
          ", addMem = " +
          addMem +
          ", strain.olab_id = " +
          strain.olab_id
      );
      try {
        const urlTypeStr =
          strain.olab_type === "plasmid" ? "plasmids" : "strains";
        const res = await axios({
          method: "PATCH",
          url: `api/v1/${urlTypeStr}/updateMemberRole`,
          data: {
            role: {
              olab_id: strain.olab_id,
              proj_olab_id: proj.olab_id,
              add: addMem
            }
          }
        });
        // console.log(res.data.data.strain);
        if (res.data.status === "success") {
          OlabUtils.infoLog("Document successfully written!");
          // console.log("updated Strain = ", res.data.data.strain);
          const updateStrain =
            strain.olab_type === "plasmid"
              ? res.data.data.plasmid
              : res.data.data.strain;
          strain.ra_locks = updateStrain.ra_locks;
        }
      } catch (err) {
        // console.error("Error patching document: ", err);
        this.errorObj.message = OlabUtils.getErrorMessage(err);
      }
    },
    updateUserOnChange: async function() {
      OlabUtils.infoLog("calling App.updateUserOnChange ...");
      try {
        const res = await axios({
          method: "PATCH",
          url: "/api/v1/users/updateMe",
          data: {
            name: this.user.name,
            selected: this.user.selected
          }
        });
        // console.log(res.data.data.user);
        if (res.data.status === "success") {
          OlabUtils.infoLog("Document successfully written!");
        }
      } catch (err) {
        // console.error("Error patching document: ", err);
        this.errorObj.message = OlabUtils.getErrorMessage(err);
      }
    },
    selectProject: function(proj) {
      OlabUtils.infoLog("selectProject proj = " + proj);
      if (
        proj &&
        this.selectedProject &&
        proj.olab_id === this.selectedProject.olab_id
      ) {
        this.selectedProject = null;
        this.user.selected.project_id = null;
      } else {
        this.selectedProject = proj;
        this.user.selected.project_id = proj.olab_id;
      }
      // Reset selectedCart and selectedPackage since we switching selectedProject
      this.selectedCart = null;
      this.user.selected.cart_id = null;
      this.selectedPackage = null;
      this.user.selected.package_id = null;
      this.updateUserOnChange();
    },
    selectCart: async function(cart) {
      OlabUtils.infoLog("selectCart cart = " + cart);
      if (
        cart &&
        this.selectedCart &&
        cart.olab_id === this.selectedCart.olab_id
      ) {
        this.selectedCart = null;
        this.user.selected.cart_id = null;
        this.updateUserOnChange();
      } else {
        this.selectedCart = cart;
        this.user.selected.cart_id = cart.olab_id;

        // Load project from cart.project_id
        try {
          const searchBody = OlabSearch.createItemSearchBody(
            "project",
            cart.project_id,
            "olab_id",
            false,
            null,
            1,
            1
          );
          const projectArr = await OlabUtils.getProjects(searchBody);
          this.selectedProject = projectArr ? projectArr[0] : null;
          if (this.selectedProject) {
            this.user.selected.project_id = this.selectedProject.olab_id;
            this.updateUserOnChange();
          } else {
            OlabUtils.infoLog("App.selectCart: No such project document!");
          }
        } catch (err) {
          // console.error("Error reading document: ", err);
          this.errorObj.message = OlabUtils.getErrorMessage(err);
        }
      }
    },
    selectPackage: async function(pkg) {
      OlabUtils.infoLog("selectPackage pkg = " + pkg);
      if (
        pkg &&
        this.selectedPackage &&
        pkg.olab_id === this.selectedPackage.olab_id
      ) {
        this.selectedPackage = null;
        this.user.selected.package_id = null;
        this.updateUserOnChange();
      } else {
        this.selectedPackage = pkg;
        this.user.selected.package_id = pkg.olab_id;

        // Load project from pkg.project_id
        try {
          const searchBody = OlabSearch.createItemSearchBody(
            "project",
            pkg.project_id,
            "olab_id",
            false,
            null,
            1,
            1
          );
          const projectArr = await OlabUtils.getProjects(searchBody);
          this.selectedProject = projectArr ? projectArr[0] : null;
          if (this.selectedProject) {
            this.user.selected.project_id = this.selectedProject.olab_id;
            this.updateUserOnChange();
          } else {
            OlabUtils.infoLog("App.selectPackage: No such project document!");
          }
        } catch (err) {
          // console.error("Error reading document: ", err);
          this.errorObj.message = OlabUtils.getErrorMessage(err);
        }
      }
    },
    updateItems: function(item, items, dFlag) {
      // console.log("** updateItem: item =", item, ". items =", items);
      // Update item in array
      for (let i in items) {
        // console.log("items[", i, "] = ", items[i], ", item = ", item);
        if (items[i].olab_id === item.olab_id) {
          // console.log("Found items[", i, "].olab_id = ", items[i].olab_id);
          if (dFlag === true) {
            // Delete element i from items
            items.splice(i, 1);
          } else {
            items[i].set(item);
          }
          break;
        }
      }
    },
    setSaveStatus: function(status) {
      this.saveStatus = status;
    },
    setupLoginState: async function(user) {
      // reauthenticate user with existing jwt cookie if exist
      try {
        if (user) {
          this.user = user;
          // Load cart, project and package if selected is not null
          if (this.user.selected) {
            // console.log("Loading user selected cart, project and package ...");
            if (this.user.selected.cart_id) {
              const searchBody = OlabSearch.createItemSearchBody(
                "cart",
                this.user.selected.cart_id,
                "olab_id",
                false,
                null,
                1,
                1
              );
              const cartArr = await OlabUtils.getCarts(searchBody);
              this.selectedCart = cartArr ? cartArr[0] : null;
            }
            if (this.user.selected.project_id) {
              const searchBody = OlabSearch.createItemSearchBody(
                "project",
                this.user.selected.project_id,
                "olab_id",
                false,
                null,
                1,
                1
              );
              const projectArr = await OlabUtils.getProjects(searchBody);
              this.selectedProject = projectArr ? projectArr[0] : null;
            }
            if (this.user.selected.package_id) {
              const searchBody = OlabSearch.createItemSearchBody(
                "package",
                this.user.selected.package_id,
                "olab_id",
                false,
                null,
                1,
                1
              );
              const packageArr = await OlabUtils.getPackages(searchBody);
              this.selectedPackage = packageArr ? packageArr[0] : null;
            }
          }
          // console.log("selectedCart =", this.selectedCart);
          // console.log("selectedProject =", this.selectedProject);
          // console.log("selectedPackage =", this.selectedPackage);

          // Setup db_configs data
          this.dbInfo.db_configs = await OlabUtils.getDBConfigs();
          // console.log("dbInfo.db_configs = ", this.dbInfo.db_configs);

          // Load microbes
          this.microbes = await OlabUtils.getMicrobes();
          // console.log("this.microbes: microbes =", this.microbes);
        } else {
          // No user login
          this.clearLoginState();
        }
      } catch (err) {
        OlabUtils.infoLog(err);
        this.errorObj.message = OlabUtils.getErrorMessage(err);
      }
    },
    clearLoginState() {
      this.user = null;
      this.dbInfo.db_configs = null;
      this.microbes = [];
      this.selectedProject = null;
      this.selectedCart = null;
      this.selectedPackage = null;
    },
    setUser: function(loginUser) {
      OlabUtils.infoLog("App: setUser =", loginUser);
      this.setupLoginState(loginUser);
    },
    logout: async function() {
      OlabUtils.infoLog("App: Calling logout ...");
      try {
        const res = await axios({
          method: "GET",
          url: "/api/v1/users/logout"
        });
        if (res.data.status === "success") {
          this.clearLoginState();
          setTimeout(() => {
            // avoid uncaught error if current route is "/"
            this.$router.push("/").catch(() => {});
          }, 100);
        }
      } catch (err) {
        OlabUtils.infoLog(err);
        this.errorObj.message = OlabUtils.getErrorMessage(err);
      }
    }
  },
  watch: {
    statusObj: function() {
      if (this.statusObj && !this.statusObj.progress) {
        setTimeout(() => {
          this.statusObj.message = null;
        }, 5000);
      }
    }
  }
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,300,700,800|Roboto+Slab:400,100,300,700|Gugi");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap");

$primary: rgb(15, 148, 231);
@import "node_modules/bootstrap/scss/bootstrap";

:root {
  /* Override Variables */
  --gray-dark: #404040;

  /* Page Variables */
  --color-text: var(--gray-dark);
  --font-family-gugi: Gugi, serif;
}

.family-gugi {
  font-family: var(--font-family-gugi);
}

.brand-color {
  background-color: #e70;
}

.text-brand-color {
  color: #e70;
}

#app {
  font-family: Inter, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-text);
  font-weight: 200;
}

// Design box styling
// Used by Design, Database, SNPSwapList
.unset-color {
  color: lightgoldenrodyellow;
  font-size: 0.7em;
  width: 80px;
  height: 38px;
  background-color: rgb(160, 160, 160);
}
.selected-color {
  color: lightgoldenrodyellow;
  font-size: 0.7em;
  width: 80px;
  height: 38px;
  background-color: rgb(221, 3, 3);
}
.upstream-color-md {
  color: lightgoldenrodyellow;
  font-size: 1em;
  width: 200px;
  height: 40px;
  background-color: rgb(199, 130, 87);
}
.upstream-color-sm {
  color: lightgoldenrodyellow;
  font-size: 0.7em;
  width: 80px;
  height: 38px;
  background-color: rgb(199, 130, 87);
}
.upstream-color {
  color: lightgoldenrodyellow;
  font-size: 1em;
  background-color: rgb(199, 130, 87);
}
.promoter-color-sm {
  color: lightgoldenrodyellow;
  font-size: 0.7em;
  width: 80px;
  height: 38px;
  background-color: rgb(117, 190, 54);
}
.promoter-color {
  color: lightgoldenrodyellow;
  font-size: 1em;
  background-color: rgb(117, 190, 54);
}
.rbs-color-sm {
  color: lightgoldenrodyellow;
  font-size: 0.7em;
  width: 80px;
  height: 38px;
  background-color: rgb(182, 47, 223);
}
.rbs-color {
  color: lightgoldenrodyellow;
  font-size: 1em;
  background-color: rgb(182, 47, 223);
}
.gene-color-sm {
  color: lightgoldenrodyellow;
  font-size: 0.7em;
  width: 80px;
  height: 38px;
  background-color: rgb(78, 185, 184);
}
.gene-color {
  color: lightgoldenrodyellow;
  font-size: 1em;
  background-color: rgb(78, 185, 184);
}
.pgst-color-sm {
  color: lightgoldenrodyellow;
  font-size: 0.7em;
  width: 80px;
  height: 38px;
  background-color: rgb(75, 100, 180);
}
.pgst-color {
  color: lightgoldenrodyellow;
  font-size: 1em;
  background-color: rgb(75, 100, 180);
}
.terminator-color-sm {
  color: lightgoldenrodyellow;
  font-size: 0.7em;
  width: 80px;
  height: 38px;
  background-color: rgb(129, 75, 190);
}
.terminator-color {
  color: lightgoldenrodyellow;
  font-size: 1em;
  background-color: rgb(129, 75, 190);
}
.downstream-color-md {
  color: lightgoldenrodyellow;
  font-size: 1em;
  width: 200px;
  height: 40px;
  background-color: rgb(197, 74, 74);
}
.downstream-color-sm {
  color: lightgoldenrodyellow;
  font-size: 0.7em;
  width: 80px;
  height: 38px;
  background-color: rgb(197, 74, 74);
}
.downstream-color {
  color: lightgoldenrodyellow;
  font-size: 1em;
  background-color: rgb(197, 74, 74);
}
</style>

<template>
  <div v-if="user && selectedPackage">
    <!-- <button
      type="button"
      class="btn btn-outline-success ml-1"
      data-toggle="modal"
      data-target="#packageProfile"
      data-backdrop="static"
      data-keyboard="false"
    >
      <font-awesome-icon icon="boxes" />
    </button> -->
    <div class="modal fade" id="packageProfile">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">OLAB ID: {{ selectedPackage.olab_id }}</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <!-- .modal-header -->
          <div class="modal-body">
            <div v-if="selectedPackage.name" class="pt-2">
              <span>Name:</span>
              <span class="h5 text-dark ml-1">{{ selectedPackage.name }}</span>
              <span class="ml-2">
                ({{ selectedPackage.package_type }} -
                {{ selectedPackage.package_seq_type }})
              </span>
            </div>

            <div class="py-1">
              <div class="card">
                <div class="card-header text-dark">Description</div>
                <div class="card-body">
                  <div class="py-1">
                    <span class="text-dark">{{ selectedPackage.desc }}</span>
                  </div>
                </div>
                <!-- .card-body -->
              </div>
              <!-- .card -->
            </div>
            <div v-if="selectedPackage" class="py-1">
              <div class="card">
                <div class="card-header text-dark">Components</div>
                <div class="card-body">
                  <div v-if="selectedPackage.package_seq_type === 'genome'">
                    <div class="table-responsive">
                      <table class="table table-striped table-sm">
                        <thead>
                          <tr>
                            <th class="small" scope="col">#</th>
                            <th class="small" scope="col">OLAB ID</th>
                            <th class="small" scope="col">Name</th>
                            <th class="small" scope="col">Locus Tag</th>
                            <th class="small" scope="col">
                              Old Locus Tag
                            </th>
                            <th class="small" scope="col">Use As</th>
                            <th class="small" scope="col">Length</th>
                            <th class="small" scope="col">Remove</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(comp, index) in selectedPackage.comps"
                            :key="index"
                          >
                            <th class="small" scope="row">{{ index + 1 }}</th>
                            <td class="small">{{ comp.olab_id }}</td>
                            <td class="small">{{ comp.name }}</td>
                            <td class="small">{{ comp.locus_tag }}</td>
                            <td class="small">
                              {{ comp.old_locus_tag }}
                            </td>
                            <td class="small">{{ comp.use_seq_as }}</td>
                            <td class="small">{{ comp.length }}</td>
                            <td class="small">
                              <button
                                type="button"
                                class="btn btn-danger btn-sm"
                                v-on:click="decrItem(comp, index)"
                              >
                                <font-awesome-icon icon="minus" />
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div v-else>
                    <div class="table-responsive">
                      <table class="table table-striped table-sm">
                        <thead>
                          <tr>
                            <th class="small" scope="col">#</th>
                            <th class="small" scope="col">OLAB ID</th>
                            <th class="small" scope="col">Name</th>
                            <th class="small" scope="col">Note</th>
                            <th class="small" scope="col">Use As</th>
                            <th class="small" scope="col">Length</th>
                            <th class="small" scope="col">Remove</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(comp, index) in selectedPackage.comps"
                            :key="index"
                          >
                            <th class="small" scope="row">{{ index + 1 }}</th>
                            <td class="small">{{ comp.olab_id }}</td>
                            <td class="small">{{ comp.name }}</td>
                            <td v-if="comp.note" class="small">
                              {{ comp.note }}
                            </td>
                            <td v-else class="small">--</td>
                            <td class="small">{{ comp.use_seq_as }}</td>
                            <td class="small">{{ comp.length }}</td>
                            <td class="small">
                              <button
                                type="button"
                                class="btn btn-danger btn-sm"
                                v-on:click="decrItem(comp, index)"
                              >
                                <font-awesome-icon icon="minus" />
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="py-1 text-right mr-5">
                    <span class="mr-2 text-secondary">Total:</span>
                    <span class="h3">{{ packageQty }}</span>
                  </div>
                </div>
                <!-- .card-body -->
              </div>
              <!-- .card -->
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
            <!-- .modal-footer -->
          </div>
          <!-- .modal-content -->
        </div>
        <!-- .modal-dialog .modal-lg-->
      </div>
      <!-- .modal fade -- End of packageProfile -->
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { OlabUtils } from "../olab/olabutils.js";
import { OlabAxios } from "../olab/olabaxios.js";

export default {
  name: "olabpackageprofile",
  props: ["user", "selectedPackage", "statusObj", "errorObj"],
  components: {
    FontAwesomeIcon
  },
  computed: {
    packageQty: function() {
      let qty = 0;
      if (this.selectedPackage != null && this.selectedPackage.comps != null) {
        for (let idx in this.selectedPackage.comps) {
          qty = qty + this.selectedPackage.comps[idx].qty;
        }
      }
      return qty;
    },
    packageIsFull: function() {
      return this.packageQty >= OlabUtils.MAX_ITEMS_PER_PACKAGE ? true : false;
    }
  },
  methods: {
    incrItem: function(item) {
      OlabUtils.infoLog("increment item: qty = " + item.qty);
      item.qty++;
      OlabAxios.savePackage(
        this.selectedPackage,
        this.statusObj,
        this.errorObj
      );
    },
    decrItem: function(item, index) {
      OlabUtils.infoLog(
        "decrement item: qty = " + item.qty + ", index = " + index
      );
      if (item.qty > 0) {
        item.qty--;
      }
      if (item.qty == 0) {
        this.selectedPackage.comps.splice(index, 1);
      }
      OlabAxios.savePackage(
        this.selectedPackage,
        this.statusObj,
        this.errorObj
      );
    }
  }
};
</script>

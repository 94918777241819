import { OlabDesignInfo } from "./olabdesigninfo";

class OlabDesignBox {
  static info = new OlabDesignInfo();

  constructor(boxID) {
    // OlabUtils.infoLog("OlabDesignBox.constructor() ...");
    this.cstb = this._initCstb(boxID);
    this.item = null;
    // This is to register if the box is touched if not we should
    // the saved box in design for constructs and primers computation
    this.touchInDesign = false;
  }

  _initCstb(id) {
    const cstb = {
      box_id: id,
      display_name: "Box " + id,
      box_type: "",
      prefix: "",
      start: "--",
      range: "--",
      primer_type: "default",
      btn_color: "unset-color",
      use_seq_as_color: "text-light bg-secondary",
      configured: false,
      comp_olab_id: "",
      seq: null,
      package_op: "combination",
      package_override: false,
      // For gene's joined sequence case only
      include_introns: false,
      // Add primer setting support
      _pmr_config_override: false,
      _pmr_config: {
        _pmr_3p_len: 20,
        _pmr_5p_len: 20,
        _pmr_setting: "FULL" // FULL, REV_HALF, FWD_HALF
      }
    };
    return cstb;
  }

  // Return a newly created cstb using a pass in box and comp
  static createCstb(box, comp) {
    if (box && comp) {
      // Create a cstb from box and comp
      const cstb = {
        box_id: box.cstb.box_id,
        display_name: box.cstb.prefix + OlabDesignBox.getDisplayName(comp),
        box_type: box.cstb.box_type,
        prefix: box.cstb.prefix,
        start: 0,
        range: comp.length,
        primer_type: "default",
        btn_color: box.cstb.btn_color,
        use_seq_as_color: box.cstb.use_seq_as_color,
        configured: true,
        comp_olab_id: comp.olab_id,
        seq: comp.seq,
        package_op: box.cstb.package_op ? box.cstb.package_op : "combination",
        package_override: box.cstb.package_override
          ? box.cstb.package_override
          : false,
        include_introns: box.cstb.include_introns
          ? box.cstb.include_introns
          : false,
        // primer config support
        _pmr_config_override: box.cstb._pmr_config_override,
        _pmr_config: box.cstb._pmr_config
      };
      return cstb;
    }
    return null;
  }

  set(dsBox) {
    this.cstb = dsBox.cstb;
    this.item = dsBox.item;
  }

  setItem(cartItems) {
    if (this.cstb.comp_olab_id && cartItems != null) {
      // OlabUtils.infoLog("setItem: cartItems.length = " + cartItems.length);
      for (let i = 0; i < cartItems.length; i++) {
        const id = this.cstb.comp_olab_id;
        // OlabUtils.infoLog("Searching .... com_olab_id = " + id);
        if (id == cartItems[i].comp.olab_id) {
          // OlabUtils.infoLog("Found!!! olab_id = " + id);
          this.item = cartItems[i];
          break;
        }
      }
    }
  }

  unsetItem(cartItems) {
    this.cstb.prefix = "";
    this.cstb.box_type = "";
    this.cstb.btn_color = "unset-color";
    this.cstb.use_seq_as_color = "text-light bg-secondary";
    this.cstb.display_name = "Box " + this.cstb.box_id;
    this.cstb.start = "--";
    this.cstb.range = "--";
    this.cstb.configured = false;
    this.cstb.comp_olab_id = "";
    this.cstb.seq = null;
    this.cstb.package_op = "combination";
    this.cstb.package_override = false;
    this.cstb.primer_type = "default";
    this.cstb.include_introns = false;
    // primer config support
    this.cstb._pmr_config_override = false;
    this.cstb._pmr_config = {
      _pmr_3p_len: 20,
      _pmr_5p_len: 20,
      _pmr_setting: "FULL" // FULL, REV_HALF, FWD_HALF
    };
    // Look for cart item that matches item and restore counter values.
    for (let ii = 0; ii < cartItems.length; ii++) {
      if (cartItems[ii] === this.item) {
        cartItems[ii].comp.available_count++;
        cartItems[ii].comp.used_count--;
      }
    }
    this.item = null;
  }

  updateCartItem(index, cartItems) {
    if (this.item != null) {
      // Look for cart item that matches item and restore counter values.
      for (let ii = 0; ii < cartItems.length; ii++) {
        if (cartItems[ii] === this.item) {
          cartItems[ii].comp.available_count++;
          cartItems[ii].comp.used_count--;
        }
      }
    }
    // Set the newly assigned item
    let newItem = cartItems[index];
    this.cstb.display_name = this.cstb.prefix + newItem.display_name;
    this.cstb.configured = true;
    this.cstb.comp_olab_id = newItem.comp.olab_id;
    // Reset package_op to default. Require state for non package type
    this.cstb.package_op = "combination";
    this.item = newItem;
    cartItems[index].comp.available_count--;
    cartItems[index].comp.used_count++;
  }

  setBoxType(boxType, setName) {
    switch (boxType) {
      case "down_stream" /* Down Stream (D_) */:
        this.cstb.display_name = setName
          ? "Down Stream"
          : this.cstb.display_name;
        this.cstb.box_type = "down_stream";
        this.cstb.prefix = "D_";
        this.cstb.btn_color = "downstream-color-sm";
        this.cstb.use_seq_as_color = "text-light downstream-color";
        break;
      case "gene" /* Gene (G_) */:
        this.cstb.display_name = setName ? "Gene" : this.cstb.display_name;
        this.cstb.box_type = "gene";
        this.cstb.prefix = "G_";
        this.cstb.btn_color = "gene-color-sm";
        this.cstb.use_seq_as_color = "text-light gene-color";
        break;
      case "promoter" /* Promoter (P_) */:
        this.cstb.display_name = setName ? "Promoter" : this.cstb.display_name;
        this.cstb.box_type = "promoter";
        this.cstb.prefix = "P_";
        this.cstb.btn_color = "promoter-color-sm";
        this.cstb.use_seq_as_color = "text-light promoter-color";
        break;
      case "rbs" /* RBS (R_) */:
        this.cstb.display_name = setName ? "RBS" : this.cstb.display_name;
        this.cstb.box_type = "rbs";
        this.cstb.prefix = "R_";
        this.cstb.btn_color = "rbs-color-sm";
        this.cstb.use_seq_as_color = "text-light rbs-color";
        break;
      case "pgst" /* PGsT (W_) */:
        this.cstb.display_name = setName ? "PGsT" : this.cstb.display_name;
        this.cstb.box_type = "pgst";
        this.cstb.prefix = "W_";
        this.cstb.btn_color = "pgst-color-sm";
        this.cstb.use_seq_as_color = "text-light pgst-color";
        break;
      case "terminator" /* Terminator (T_) */:
        this.cstb.display_name = setName
          ? "Terminator"
          : this.cstb.display_name;
        this.cstb.box_type = "terminator";
        this.cstb.prefix = "T_";
        this.cstb.btn_color = "terminator-color-sm";
        this.cstb.use_seq_as_color = "text-light terminator-color";
        break;
      case "up_stream" /* Up Stream (U_) */:
        this.cstb.display_name = setName ? "Up Stream" : this.cstb.display_name;
        this.cstb.box_type = "up_stream";
        this.cstb.prefix = "U_";
        this.cstb.btn_color = "upstream-color-sm";
        this.cstb.use_seq_as_color = "text-light upstream-color";
        break;
      case "unset" /* unset */:
        this.cstb.display_name = "Box " + this.cstb.box_id;
        this.cstb.box_type = "";
        this.cstb.prefix = "";
        this.cstb.btn_color = "unset-color";
        this.cstb.use_seq_as_color = "text-light bg-secondary";
        break;
    }

    // Reset box's configured
    this.cstb.configured = false;

    // Set box's states into info as status
    OlabDesignBox.info.setBoxBar(this.cstb.display_name);
    OlabDesignBox.info.setBoxBarStyle(this.cstb.use_seq_as_color);
    OlabDesignBox.info.setSeq(null);
  }

  setSeq(seq) {
    this.cstb.seq = seq;
    OlabDesignBox.info.setSeq(seq);
  }

  // Get primer config
  getPmrConfig() {
    return this.cstb._pmr_config;
  }

  // Init primer config
  initPmrConfig() {
    this.cstb._pmr_config = {
      _pmr_3p_len: 20,
      _pmr_5p_len: 20,
      _pmr_setting: "FULL" // FULL, REV_HALF, FWD_HALF
    };
  }

  // Get primer config override
  getPmrConfigOverride() {
    return this.cstb._pmr_config_override;
  }

  // Set primer config override
  setPmrConfigOverride(override) {
    this.cstb._pmr_config_override = override;
  }

  // Get primer config 3p len
  getPmrConfig3pLen() {
    return this.cstb._pmr_config._pmr_3p_len;
  }

  // Set primer config 3p len
  setPmrConfig3pLen(pmr3pLen) {
    this.cstb._pmr_config._pmr_3p_len = pmr3pLen;
  }

  // Get primer config 5p len
  getPmrConfig5pLen() {
    return this.cstb._pmr_config._pmr_5p_len;
  }

  // Set primer config 5p len
  setPmrConfig5pLen(pmr5pLen) {
    this.cstb._pmr_config._pmr_5p_len = pmr5pLen;
  }

  // Get primer config prm setting
  getPmrConfigSetting() {
    return this.cstb._pmr_config._pmr_setting;
  }

  // Set primer config prm setting
  setPmrConfigSetting(pmrSetting) {
    this.cstb._pmr_config._pmr_setting = pmrSetting;
  }

  static setInfoBox(dsBox) {
    // Set box's states into info as status
    OlabDesignBox.info.set(dsBox);
  }

  static resetInfoBox() {
    // Reset box's states into info as status
    OlabDesignBox.info.reset();
  }

  static getDisplayName(comp) {
    let name = null;
    if (comp.name) {
      name = comp.name;
    } else if (comp.old_locus_tag) {
      name = comp.old_locus_tag;
    } else if (comp.locus_tag) {
      name = comp.locus_tag;
    } else {
      name = comp.olab_id;
    }
    return name;
  }
}

export { OlabDesignBox };
